define("epcr-uis/helpers/is-pid-correct", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isPidCorrect = isPidCorrect;
  _exports.extractBirthDateFromPID = extractBirthDateFromPID;
  _exports.extractSexFromPID = extractSexFromPID;
  _exports.default = void 0;

  const getDate = pid => {
    const centenaryFactor = pid.charAt(0);
    const date = pid.slice(1, -4).match(/.{1,2}/g).join("-");
    if (centenaryFactor === "1" || centenaryFactor === "2") return `18${date}`;
    if (centenaryFactor === "3" || centenaryFactor === "4") return `19${date}`;
    if (centenaryFactor === "5" || centenaryFactor === "6") return `20${date}`;
    return null;
  };

  function isPidCorrect([pid]) {
    if (!pid) return false;
    pid = pid.toString();
    if (pid.length !== 11) return false;
    const date = getDate(pid);
    if (isNaN(new Date(date).getTime())) return false;
    let i, a, b, c, d, e;
    d = 0;
    e = 0;
    b = 1;
    c = 3;

    for (i = 0; i < 10; i++) {
      a = pid.charAt(i);
      d = d + b * a;
      e = e + c * a;
      b = b + 1;
      if (b === 10) b = 1;
      c = c + 1;
      if (c === 10) c = 1;
    }

    d = d % 11;
    e = e % 11;
    if (d === 10) i = e === 10 ? 0 : e;else i = d;
    return pid.charAt(10) === i.toString();
  }

  function extractBirthDateFromPID([pid]) {
    if (isPidCorrect([pid])) pid = pid.toString();else return null;
    return (0, _moment.default)(getDate(pid));
  }

  function extractSexFromPID([pid]) {
    if (isPidCorrect([pid])) pid = pid.toString();else return null;
    return pid.charAt(0) % 2 === 0 ? "FEMALE" : "MALE";
  }

  var _default = Ember.Helper.helper(isPidCorrect);

  _exports.default = _default;
});