define("epcr-uis/pods/pcr/summary/print/route", ["exports", "epcr-uis/mixins/route-tracker", "epcr-uis/helpers/generate-print-url"], function (_exports, _routeTracker, _generatePrintUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeTracker.default, {
    simpleAuthFetch: Ember.inject.service(),

    model() {
      const {
        simpleAuthFetch
      } = this;
      const pcr = this.modelFor("pcr").pcrDocument;

      const source = format => {
        const pcrData = simpleAuthFetch.getSerializedPcrData(pcr);
        return simpleAuthFetch.fetchRequest((0, _generatePrintUrl.generatePrintUrl)(Ember.get(pcr, "id"), format), "POST", pcrData);
      };

      return {
        source
      };
    }

  });

  _exports.default = _default;
});