define("epcr-uis/pods/components/pcr-document/specialty-patient-forms/event-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0cYYiEdq",
    "block": "{\"symbols\":[\"TextAreaField\",\"@circumstances\"],\"statements\":[[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"side-bar/textarea-popup/textarea\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@value\",\"@class\"],[[23,2,[]],\"event-description\"]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/specialty-patient-forms/event-details/template.hbs"
    }
  });

  _exports.default = _default;
});