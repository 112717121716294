define("epcr-uis/helpers/count-medicaments-elements", ["exports", "epcr-uis/helpers/count-array-elements"], function (_exports, _countArrayElements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countMedicamentsElements = countMedicamentsElements;
  _exports.default = void 0;

  function countMedicamentsElements([ingredient, medicament]) {
    let sum = 0;
    medicament && medicament.types && medicament.types.forEach(type => {
      sum += (0, _countArrayElements.countArrayElements)([ingredient, "medicament.code", type.code]);
    });
    return sum;
  }

  var _default = Ember.Helper.helper(countMedicamentsElements);

  _exports.default = _default;
});