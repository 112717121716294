define("epcr-uis/helpers/is-present", ["exports", "ember-components/helpers/is-present"], function (_exports, _isPresent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isPresent.default;
    }
  });
  Object.defineProperty(_exports, "isPresent", {
    enumerable: true,
    get: function () {
      return _isPresent.isPresent;
    }
  });
});