define("epcr-uis/helpers/get-gcs-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getGcsColor = getGcsColor;
  _exports.default = void 0;

  const getColor = (max, min, gcsSum, color) => gcsSum < max && gcsSum > min ? color : null;

  function getGcsColor([gcsSum]) {
    return getColor(16, 14, gcsSum, "green") || getColor(15, 11, gcsSum, "light-green") || getColor(12, 7, gcsSum, "orange") || getColor(8, 2, gcsSum, "red") || "";
  }

  var _default = Ember.Helper.helper(getGcsColor);

  _exports.default = _default;
});