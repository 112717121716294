define("epcr-uis/pods/pcr/delays/model", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _fragment.default.extend({
    toEventSite: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    toPatient: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    toHospital: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR)
  });

  _exports.default = _default;
});