define("epcr-uis/mirage/factories/cpr-before-arrival-by", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    cprStarted: true,
    minsFromSuddenDeath: 7,
    minsFromCprStart: 8,
    minsFromCprEnd: 5,
    usedAed: true,
    minsFromFirstAed: 11
  });

  _exports.default = _default;
});