define("epcr-uis/pods/not-authenticated/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "brv24PW3",
    "block": "{\"symbols\":[],\"statements\":[[5,\"pcr-error\",[],[[\"@message\",\"@buttonName\",\"@icon\",\"@onNavigationButtonClicked\",\"@onButtonClicked\"],[\"unableToLogin\",[28,\"t\",[\"actions.login\"],null],\"bug icon huge grey\",[28,\"route-action\",[\"transitionToRoute\",\"home\",\"login\"],null],[28,\"route-action\",[\"transitionToRoute\",\"home\",\"login\"],null]]]],[0,\"\\n\"],[5,\"pcr-footer\",[],[[],[]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/not-authenticated/template.hbs"
    }
  });

  _exports.default = _default;
});