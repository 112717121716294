define("epcr-uis/pods/application-version/service", ["exports", "ember-concurrency", "epcr-uis/config/environment", "epcr-uis/helpers/window-wrapper"], function (_exports, _emberConcurrency, _environment, _windowWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    simpleAuthFetch: Ember.inject.service(),
    hasNeverVersion: (currentVersion, serverVersion) => serverVersion !== currentVersion,
    getVersionFromServer: (0, _emberConcurrency.task)(function* () {
      const url = _environment.default.APP.versionCheckUrl;
      const response = yield this.simpleAuthFetch.fetchRequest(url).then(response => response.text());
      this.validateResponse(response);
      return response;
    }),

    checkVersionWithServer() {
      return this.checkVersionWithServerTask.perform();
    },

    validateResponse(version) {
      const format = /\d+\.\d+\.\d+/;
      if (!version || version.length > 20 || !version.match(format)) throw Error(`Invalid epcr-version format: ${version}`);
    },

    checkVersionWithServerTask: (0, _emberConcurrency.task)(function* () {
      try {
        const currentVersion = _environment.default.APP.epcrVersion;
        const serverVersion = yield this.getVersionFromServer.perform();
        return this.hasNeverVersion(currentVersion, serverVersion);
      } catch (e) {
        console.error(e); // eslint-disable-line
      }
    }),
    reloadPage: ignoreCache => (0, _windowWrapper.callMethod)("window.location.reload", ignoreCache),

    updateVersionAndReload() {
      this.checkVersionWithServer().then(hasNewerVersion => this.reloadPage(hasNewerVersion));
    }

  });

  _exports.default = _default;
});