define("epcr-uis/helpers/get-department-color-by-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const codeToColorMap = {
    "1": "red",
    "2": "orange",
    "8": "orange",
    "4": "orange"
  };

  var _default = Ember.Helper.extend({
    codeToColorMap,

    compute(code) {
      const labelCssClass = "ui label";
      const color = this.codeToColorMap[code];
      return color ? `${labelCssClass} ${color}` : "";
    }

  });

  _exports.default = _default;
});