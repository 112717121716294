define("epcr-uis/pods/pcr-header/service", ["exports", "ember-concurrency", "epcr-uis/helpers/can-edit-pcr", "epcr-uis/config/environment", "epcr-uis/mixins/is-station-allowed-to-create-new-pcr"], function (_exports, _emberConcurrency, _canEditPcr, _environment, _isStationAllowedToCreateNewPcr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_isStationAllowedToCreateNewPcr.default, {
    store: Ember.inject.service(),
    isStationAllowedToCreateNewEpcr: Ember.computed({
      get() {
        return this.isStationAllowedToCreateNewPcr();
      }

    }),
    keysForReadOnlyData: ["category", "dispatch", "callInformation.phoneNumber1", "callInformation.phoneNumber2", "callInformation.phoneNumber3", "callInformation.callTakerCode", "callInformation.eventSite.address", "teamActivation.dispatcherCode", "teamActivation.activationProtocol", "teamActivation.team.code", "times.callTime", "times.assignTime", "times.onRouteTime", "times.arrivedTime", "times.registrationTime", "metadata.owner"],
    keysForEditableData: [{
      pcrKey: "times.transportationTime",
      isDeletedKey: "times.transportationTimeDeleted"
    }, {
      pcrKey: "times.atHospitalTime",
      isDeletedKey: "times.atHospitalTimeDeleted"
    }, {
      pcrKey: "times.availableTime",
      isDeletedKey: "times.availableTimeDeleted"
    }, {
      pcrKey: "times.returnedTime",
      isDeletedKey: "times.returnedTimeDeleted"
    }],

    isObjectsNotEqual(pcr, newDataValue, key) {
      const stringify = value => JSON.stringify(value);

      return stringify(Ember.get(pcr, key)) !== stringify(newDataValue);
    },

    updatePcrFields(pcr, newData) {
      this.resetOwnerFragment(pcr, newData);
      this.keysForReadOnlyData.forEach(key => {
        const newDataValue = Ember.get(newData, key);
        if (this.isObjectsNotEqual(pcr, newDataValue, key)) Ember.set(pcr, key, newDataValue);
      });
      this.keysForEditableData.forEach(({
        pcrKey,
        isDeletedKey
      }) => {
        const newDataValue = Ember.get(newData, pcrKey);
        if (newDataValue && !Ember.get(pcr, pcrKey) && !Ember.get(pcr, isDeletedKey)) Ember.set(pcr, pcrKey, newDataValue);
      });
    },

    resetOwnerFragment(pcr, newData) {
      if (Ember.get(newData, "metadata.owner") && newData.metadata.owner.externalId !== pcr.metadata.owner.externalId) pcr.metadata.owner.resetFragment();
    },

    createInterval(pcr, debounce, period) {
      return Ember.run.later(this, () => this.synchronizePcrHeader.perform(pcr, debounce, period), period);
    },

    startPcrHeaderSynchronizer(pcr, debounce, period) {
      if (Ember.isPresent(this.synchronizerIntervalId)) this.cancelPcrHeaderSynchronizer();
      const intervalId = this.createInterval(pcr, debounce, period);
      Ember.set(this, "synchronizerIntervalId", intervalId);
    },

    getPcrHeader: (0, _emberConcurrency.task)(function* (pcrId) {
      if (_environment.default.APP.defaultDebounceMs) yield (0, _emberConcurrency.timeout)(_environment.default.APP.defaultDebounceMs);
      return yield this.store.findRecord("pcr-header", pcrId, {
        reload: true
      });
    }).restartable(),

    async loadHeaderAndUpdatePcrModel(pcr) {
      return this.getPcrHeader.perform(pcr.id).then(data => this.updatePcrFields(pcr, data)).catch(e => {
        if (!(0, _emberConcurrency.didCancel)(e)) throw e;
      });
    },

    synchronizePcrHeader: (0, _emberConcurrency.task)(function* (pcr, debounce, period) {
      try {
        if (debounce) yield (0, _emberConcurrency.timeout)(debounce);
        yield this.loadHeaderAndUpdatePcrModel(pcr);
      } catch (e) {
        throw e;
      } finally {
        const state = Ember.get(this.getPcrHeader, "last.value.metadata.state");
        (0, _canEditPcr.canEditPcr)(state) ? this.runPcrHeaderSynchronizer(pcr, debounce, period) : this.cancelPcrHeaderSynchronizer();
      }
    }).drop(),

    runPcrHeaderSynchronizer(pcr, debounce, period) {
      if (!this.isStationAllowedToCreateNewEpcr) {
        this.loadHeaderAndUpdatePcrModel(pcr);
        return this.startPcrHeaderSynchronizer(pcr, debounce, period);
      }
    },

    cancelPcrHeaderSynchronizer() {
      Ember.run.later(this, () => this.getPcrHeader.cancelAll(), _environment.default.APP.taskCancellationTimeout);
      this.synchronizePcrHeader.cancelAll();
      Ember.run.cancel(this.synchronizerIntervalId);
      Ember.set(this, "synchronizerIntervalId", undefined);
    }

  });

  _exports.default = _default;
});