define("epcr-uis/pods/components/pcr-document/vitals-summary/vital-parameters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oi/Hf37U",
    "block": "{\"symbols\":[\"status\",\"@measure\",\"@className\",\"@data\",\"@icon\",\"@range\",\"@iconColorByValue\",\"&default\"],\"statements\":[[4,\"with\",[[28,\"get-color-from-range\",[[23,6,[\"0\"]],[23,6,[\"1\"]],[23,6,[\"2\"]],[23,6,[\"3\"]],[23,4,[]]],null]],null,{\"statements\":[[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[[23,5,[]],\" \",[28,\"if\",[[23,7,[]],[23,1,[]],\"grey\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[[23,3,[]],\" \",[23,1,[]]]]],[8],[1,[23,4,[]],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"measure\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"measure\"],[8],[1,[22,\"measure\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[14,8],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[[23,5,[]],\" grey\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[28,\"is-empty\",[[23,6,[]]],null],[28,\"is-present\",[[23,4,[]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[[23,3,[]],\" \",[22,\"status\"]]]],[8],[1,[23,4,[]],false],[9],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"measure\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"breakLine\"]]],null,{\"statements\":[[0,\"  \"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/vitals-summary/vital-parameters/template.hbs"
    }
  });

  _exports.default = _default;
});