define("epcr-uis/pods/icd/adapter", ["exports", "epcr-uis/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    urlForFindRecord(id, model) {
      return `${this.mainPath(model)}/${id}`;
    },

    urlForQuery(query, model) {
      return `${this.mainPath(model)}`;
    }

  });

  _exports.default = _default;
});