define("epcr-uis/pods/components/pcr-config/component", ["exports", "epcr-uis/mixins/metrics-tracker"], function (_exports, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    "data-test-id": "pcr-config",
    classNames: ["pcr-config"],
    userPreferences: Ember.inject.service(),
    flashlight: Ember.inject.service(),
    brightness: Ember.inject.service(),
    actions: {
      showScreenBrightnessController() {
        this.trackEvent("Config", "Feature - Brightness Control", "Show Control");
        return this.brightness.showScreenBrightnessController();
      },

      saveToUserPreferences(featureName, enabled) {
        this.userPreferences.updateKeyValue(featureName, enabled);
        this.trackEvent("Config", `Feature - ${featureName}`, `Enabled - ${enabled}`, enabled ? 1 : 0);
      },

      turnOffFlashlightIfOn() {
        this.flashlight.turnOffFlashlightIfOn();
      }

    }
  });

  _exports.default = _default;
});