define("epcr-uis/pods/components/pcr-document/injuries/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qt7bcbO7",
    "block": "{\"symbols\":[\"MultiSelect\",\"key\",\"@injuries\",\"@injuriesKeys\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/multi-select\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"      \"],[6,[23,1,[]],[[12,\"class\",\"twelve wide column\"]],[[\"@header\",\"@items\",\"@selectedItems\",\"@validation\",\"@key\"],[[28,\"t\",[[28,\"concat\",[\"pcr.injuryLocation.\",[23,2,[]]],null]],null],[24,[\"pcrClassificator\",\"bodyPartsInjuries\"]],[28,\"get\",[[23,3,[]],[23,2,[]]],null],[28,\"if\",[[28,\"eq\",[[23,2,[]],\"pain\"],null],[24,[\"pcrValidation\",\"errors\",\"BEFORE_HELP-pain-location\"]],[24,[\"pcrValidation\",\"errors\",\"BEFORE_HELP-pain-site\"]]],null],\"name\"]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/injuries/list/template.hbs"
    }
  });

  _exports.default = _default;
});