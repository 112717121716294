define("epcr-uis/helpers/get-request-time-validation-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRequestTimeValidationType = getRequestTimeValidationType;
  _exports.default = void 0;

  const hasErrorWithCorrectType = (errors, fieldId, key, type) => key.includes(fieldId) && errors[key].type === type;

  function getRequestTimeValidationType(errors, fieldId) {
    if (!errors || !fieldId) return null;
    const keys = Object.keys(errors);
    const hasErrorValidation = keys.find(key => hasErrorWithCorrectType(errors, fieldId, key, "error"));
    if (hasErrorValidation) return {
      type: "error"
    };
    const hasWarningValidation = keys.find(key => hasErrorWithCorrectType(errors, fieldId, key, "warning"));
    return hasWarningValidation ? {
      type: "warning"
    } : null;
  }

  var _default = Ember.Helper.extend({
    compute([errors, fieldId]) {
      return getRequestTimeValidationType(errors, fieldId);
    }

  });

  _exports.default = _default;
});