define("epcr-uis/helpers/generate-attachment-url", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateAttachmentUrl = generateAttachmentUrl;
  _exports.default = void 0;

  function generateAttachmentUrl([pcrId, attachmentId, format, isThumbnail = false, accessToken, domain]) {
    if (!pcrId) return "";
    if (!attachmentId) return _environment.default.APP.pcrAttachmentsUrl.replace(":pcrId", pcrId);

    let url = _environment.default.APP.pcrAttachmentUrl.replace(":pcrId", pcrId).replace(":attachmentId", attachmentId);

    if (format) {
      url += `?format=${format}`;
      if (isThumbnail) url += "&sizeType=thumbnail";
      if (accessToken) url += `&token=${accessToken}`;
      if (domain) url += `&domain=${domain}`;
    }

    return url;
  }

  var _default = Ember.Helper.helper(generateAttachmentUrl);

  _exports.default = _default;
});