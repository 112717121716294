define("epcr-uis/helpers/pretty-bytes", ["exports", "pretty-bytes"], function (_exports, _prettyBytes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prettyBytes = prettyBytes;
  _exports.default = void 0;

  function prettyBytes([number]) {
    return number ? (0, _prettyBytes.default)(number, {
      locale: "lt"
    }) : "";
  }

  var _default = Ember.Helper.helper(prettyBytes);

  _exports.default = _default;
});