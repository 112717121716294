define("epcr-uis/mirage/factories/patient-diagnosis", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const englishDiagnosis = () => ({
    diagnosis: "Was caught in a gun shooting accident",
    notes: "Only scratches",
    icd10Code: "W34.00"
  });

  const lithuanianDiagnosis = () => ({
    diagnosis: "Paslydo ir susilaužė koją...",
    notes: "Sanarių sužeidimas",
    icd10Code: "A66.6"
  });

  var _default = _emberCliMirage.Factory.extend((0, _isEnglish.isEnglish)() ? englishDiagnosis() : lithuanianDiagnosis());

  _exports.default = _default;
});