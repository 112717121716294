define("epcr-uis/pods/pcr/flowchart/recommendation/service", ["exports", "ember-concurrency", "epcr-uis/config/environment", "raven", "epcr-uis/helpers/get-medicament-mediums-by-code"], function (_exports, _emberConcurrency, _environment, _raven, _getMedicamentMediumsByCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    simpleAuthFetch: Ember.inject.service(),
    androidSimpleAuthFetch: Ember.inject.service("simple-auth-fetch/android"),
    android: Ember.inject.service(),
    pcrClassificator: Ember.inject.service(),
    login: Ember.inject.service(),
    pcrService: Ember.inject.service("pcr"),
    store: Ember.inject.service(),
    task: null,
    recommendationsCount: Ember.computed("loadRecommendationsTask.last.isFinished", function () {
      const recommendationsTask = this.loadRecommendationsTask.last;
      return recommendationsTask.isError ? 0 : recommendationsTask.value.length;
    }),

    reloadRecommendations(pcr) {
      return this.loadRecommendationsTask.perform(pcr);
    },

    sendMessageToSentry(type, code) {
      const treatment = type === "M" ? "medicament" : "procedure";

      _raven.default.captureMessage(`unknown recommendation ${treatment} = ${code}, station = ${this.login.getStationId()}`);
    },

    isTreatmentKnown(type, code) {
      let isTreatmentFoundInClassificator = null;

      if (type === "P") {
        const procedures = Ember.get(this.pcrClassificator, "flowchart.procedures");
        isTreatmentFoundInClassificator = procedures.find(proc => proc.code === code);
      } else if (type === "M") {
        const mediums = (0, _getMedicamentMediumsByCode.getMedicamentMediumsByCode)(code, this.pcrClassificator);
        isTreatmentFoundInClassificator = mediums && mediums.types && mediums.types.find(medium => medium.code === code);
      }

      !isTreatmentFoundInClassificator && this.sendMessageToSentry(type, code);
      return isTreatmentFoundInClassificator;
    },

    removeUnknownTreatments(treatments) {
      return treatments.map(treatments => treatments.filter(({
        type,
        code
      }) => this.isTreatmentKnown(type, code))).filter(treatment => treatment.length);
    },

    changeRecommendationsStructure(payload) {
      const filteredRecommendations = [];

      if (Ember.get(payload, "length")) {
        payload.forEach(({
          name,
          treatments
        }) => {
          filteredRecommendations.push({
            name,
            treatments: this.removeUnknownTreatments(treatments)
          });
        });
      }

      return filteredRecommendations;
    },

    loadRecommendationsTask: (0, _emberConcurrency.task)(function* (pcr) {
      const simpleAuthFetch = this.android.isAndroid() ? this.androidSimpleAuthFetch : this.simpleAuthFetch;
      const body = pcr.serialize ? pcr.serialize() : pcr;
      this.pcrService.replacePcrSignatureData(body);
      return yield simpleAuthFetch.fetchRequest(_environment.default.APP.recommendationsUrl, "POST", body).then(response => response.json()).then(payload => this.changeRecommendationsStructure(payload));
    }).restartable()
  });

  _exports.default = _default;
});