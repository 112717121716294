define("epcr-uis/helpers/is-paramedic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isParamedic = void 0;

  const isParamedic = ([teamLeaderType]) => teamLeaderType === "PARAM";

  _exports.isParamedic = isParamedic;

  var _default = Ember.Helper.helper(isParamedic);

  _exports.default = _default;
});