define("epcr-uis/pods/pcr-classificator/calculator/dose/service", ["exports", "epcr-uis/helpers/get-medicament-mediums-by-code"], function (_exports, _getMedicamentMediumsByCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    pcrClassificator: Ember.inject.service(),

    customDoses(weight) {
      const {
        error,
        warning
      } = this;
      const anaphylaxis = warning("Anafilaksijai");
      const errorUpToOneYear = error("Iki 1 m. negalima");
      const errorUpToThreeYear = error("Iki 3 m. negalima");
      const secondDose = "Antra dozė po 4 - 6 val.";
      const repeatPerDay = "Kartoti iki 3 kartų per parą kas 6 val.";
      const epinephrineMessage = "Ištikus anafilaksiniam šokui, dozę didinant galima leisti 3 kartus į raumenis (kas 10 - 20 minučių).";
      const twoPills = "2 tabletės";
      const sixPills = "6 tabletės";
      return [{
        id: 1,
        code: 38,
        // Paracetamolis 500mg
        dose: "0,25 g",
        maxDose: twoPills,
        maxPerDay: sixPills,
        doseWarning: errorUpToThreeYear,
        notes: ["Dozė skirta 3 - 7 m. vaikams.", secondDose],
        available: weight => weight >= 15 && weight <= 25
      }, {
        id: 2,
        code: 38,
        // Paracetamolis 500mg
        dose: "0,25 - 0,5 g",
        maxDose: twoPills,
        maxPerDay: sixPills,
        doseWarning: errorUpToThreeYear,
        notes: ["Dozė skirta 7 - 12 m. vaikams.", secondDose],
        available: weight => weight > 25 && weight <= 40
      }, {
        id: 3,
        code: 38,
        // Paracetamolis 500mg
        dose: "0,5 - 1 g",
        maxDose: twoPills,
        maxPerDay: sixPills,
        doseWarning: errorUpToThreeYear,
        notes: ["Dozė skirta vyresniems kaip 12 m. vaikams.", secondDose],
        available: weight => weight > 40
      }, {
        id: 4,
        // Paracetamolis 250mg žvakutė
        code: 39,
        dose: "1 žvakutė",
        maxDose: "1 žvakutė",
        maxPerDay: "4 žvakutės",
        notes: ["Dozė skirta 1 - 6 m. vaikams.", "Antra dozė po 6 val."],
        available: weight => weight >= 10 && weight <= 22
      }, {
        id: 5,
        // Paracetamolis 150mg žvakutė
        code: 40,
        dose: "1 žvakutė",
        maxDose: "1 žvakutė",
        maxPerDay: "4 žvakutės",
        notes: ["Dozė skirta iki 1 m. vaikams.", "Antra dozė po 6 val."],
        available: weight => weight < 10
      }, {
        id: 6,
        code: 41,
        // Salbutamolis
        dose: "1 - 2 įpurškimai",
        maxDose: "2 įpurškimai",
        maxPerDay: "8 įpurškimai",
        doseWarning: error("Iki 4 m. negalima"),
        notes: ["Astmos, anafilaksijos su bronchospazmu hiperkalemijos atvejais vartojami 4 - 8 įpurškimai kas 20 min., vėliau titruojama pagal efektą.", "Nebulaizeriu skiriama 2,5 mg dozė, vėliau titruoti."],
        available: weight => weight <= 20
      }, {
        id: 45,
        code: 41,
        // Salbutamolis
        dose: "1 - 2 įpurškimai",
        maxDose: "2 įpurškimai",
        maxPerDay: "8 įpurškimai",
        doseWarning: error("Iki 4 m. negalima"),
        notes: ["Astmos, anafilaksijos su bronchospazmu hiperkalemijos atvejais vartojami 4 - 8 įpurškimai kas 20 min., vėliau titruojama pagal efektą.", "Nebulaizeriu skiriama 5 mg dozė, vėliau titruoti."],
        available: weight => weight > 20
      }, {
        id: 7,
        code: 3,
        // Aktyvintoji anglis
        dose: "30 - 50 g",
        notes: ["Vienkartinė antidotinė dozė vaikams sveriantiems virš 20 kg."],
        available: weight => weight >= 20
      }, {
        id: 8,
        code: 208,
        // Diazepamas 5mg rekt. tub
        dose: "5 mg",
        notes: ["Vaistas skiriamas traukulių atveju.", "Jei vaistas po 10 minučių nepradeda veikti, dozė gali būti pakartota."],
        available: weight => weight >= 10 && weight <= 15
      }, {
        id: 9,
        code: 207,
        // Diazepamas 10mg rekt. tub
        dose: "10 mg",
        notes: ["Vaistas skiriamas traukulių atveju.", "Jei vaistas po 10 minučių nepradeda veikti, dozė gali būti pakartota."],
        available: weight => weight > 15
      }, {
        id: 10,
        code: 11,
        // Epinefrinas
        dose: "0,15 mg",
        maxDose: `${0.1 * weight} mg`,
        doseWarning: anaphylaxis,
        notes: ["Dozė skirta 6 mėn. - 6 m. vaikams.", epinephrineMessage],
        available: weight => weight > 8 && weight <= 22
      }, {
        id: 11,
        code: 11,
        // Epinefrinas
        dose: "0,3 mg",
        maxDose: `${0.1 * weight} mg`,
        doseWarning: anaphylaxis,
        notes: ["Dozė skirta 6 - 12 m. vaikams.", epinephrineMessage],
        available: weight => weight > 22 && weight <= 40
      }, {
        id: 12,
        code: 11,
        // Epinefrinas
        dose: "0,5 mg",
        maxDose: `${0.1 * weight} mg`,
        doseWarning: anaphylaxis,
        notes: ["Dozė skirta vyresniems kaip 12 m. vaikams.", epinephrineMessage],
        available: weight => weight > 40
      }, {
        id: 13,
        code: 28,
        // Metoklopramidas
        dose: "1 mg",
        doseWarning: errorUpToOneYear,
        notes: ["Dozė skirta 1 - 3 m. vaikams.", repeatPerDay],
        available: weight => weight >= 10 && weight <= 15
      }, {
        id: 14,
        code: 28,
        // Metoklopramidas
        dose: "2 mg",
        doseWarning: errorUpToOneYear,
        notes: ["Dozė skirta 3 - 5 m. vaikams.", repeatPerDay],
        available: weight => weight > 15 && weight <= 20
      }, {
        id: 15,
        code: 28,
        // Metoklopramidas
        dose: "2,5 mg",
        doseWarning: errorUpToOneYear,
        notes: ["Dozė skirta 5 - 9 m. vaikams.", repeatPerDay],
        available: weight => weight > 20 && weight <= 30
      }, {
        id: 16,
        code: 28,
        // Metoklopramidas
        dose: "5 mg",
        doseWarning: errorUpToOneYear,
        notes: ["Dozė skirta 9 - 18 m. vaikams.", repeatPerDay],
        available: weight => weight > 30 && weight <= 60
      }, {
        id: 17,
        code: 28,
        // Metoklopramidas
        dose: "10 mg",
        doseWarning: errorUpToOneYear,
        notes: ["Dozė skirta 15 - 18 m. vaikams.", repeatPerDay],
        available: weight => weight > 60
      }];
    },

    medDoses(weight) {
      const {
        message,
        warning,
        error
      } = this;
      const errorUpToOneYear = error("Iki 1 m. negalima");
      const errorUpToSixMonths = error("Iki 6 mėn. negalima");
      const infusion = message("Infuzija");
      const penicillinMessage = "Vaistas skiriamas meningokoko atveju.";
      const secondPenicillinMessage = "Skiedžiamas su 0,9 % natrio chlorido injekciniu tirpalu ir leidžiamas į raumenis arba veną, jei būklė labai sunki.";
      return [{
        id: 18,
        code: 2,
        // Adenozinas
        dosePerKg: [0.1],
        secondDose: [0.2],
        measure: "mg",
        maxDose: 12,
        notes: ["Sušvirkščiama į veną greitai, neskiedus.", "Po vaisto skyrimo greita srove suleidžiama 5 ml izotoninio tirpalo.", "Tarp dozių daroma 1 - 2 min. pertraukos. Vaistą galima skirti 3 kartus per parą."]
      }, {
        id: 19,
        code: 3,
        // Aktyvintoji anglis
        dosePerKg: [1],
        measure: "g",
        notes: ["Vienkartinė antidotinė dozė vaikams sveriantiems iki 20 kg."],
        available: weight => weight < 20
      }, {
        id: 20,
        code: 4,
        // Amjodaronas
        dosePerKg: [5],
        measure: "mg",
        maxPerDay: `${15 * weight} mg`,
        doseWarning: error("Iki 3 m. negalima"),
        notes: ["Injekciją į veną arba intrakaulinę injekciją gaivinimo metu skirti po 3 ir po 5 defibriliacijos impulso.", "Injekciją galima kartoti ne anksčiau kaip po 15 min.", "Gydomajam poveikiui palaikyti reikėtų pradėti infuziją.", "Amjodarono injekcinis tirpalas maišomas su 5 % gliukozės infuziniu tirpalu."]
      }, {
        id: 21,
        code: 4,
        // Amjodaronas drip
        dosePerKg: [25],
        secondDose: [5, 15],
        measure: ["μg/kg/min", "μg/min"],
        maxPerDay: "1200 mg",
        doseWarning: infusion,
        notes: ["Amjodarono injekcinis tirpalas maišomas su 250 ml 5 % gliukozės infuziniu tirpalu.", "Įsotinamąją dozę rekomenduojama suleisti į veną per 20 ‑ 120 min. Per 24 val. šią dozę galima kartoti 2 ‑ 3 kartus.", "Pagrindiniai ūminiai vaisto šalutiniai poveikiai yra hipotenzija ir bradikardija."]
      }, {
        id: 22,
        code: 5,
        // Atropino sulfatas
        dosePerKg: [0.02],
        measure: "mg",
        maxDose: 0.5,
        notes: ["Skiriama, jei yra padidėjęs klajoklio nervo tonusas ar yra pirminė atrioventrikulinė blokada.", "Minimali dozė 0,1 mg.", "Mažesnės nei 0,1 mg dozės gali sukelti paradoksinę bradikardiją."]
      }, {
        id: 23,
        code: 6,
        // Benzilpenicilinas
        dosePerKg: [0.05, 0.15],
        measure: "MTV",
        maxPerDay: "20 MTV",
        notes: ["Dozė naujagimiams iki 1 sav. amžiaus.", penicillinMessage, secondPenicillinMessage, "Dozė lygiomis dalimis leidžiama per parą per 2 - 3 kartus."],
        available: weight => weight <= 4
      }, {
        id: 24,
        code: 6,
        // Benzilpenicilinas
        dosePerKg: [0.075, 0.2],
        measure: "MTV",
        maxPerDay: "20 MTV",
        notes: ["Dozė naujagimiams vyresniems kaip 1 sav.", penicillinMessage, secondPenicillinMessage, "Dozė lygiomis dalimis leidžiama per parą per 3 - 4 kartus."],
        available: weight => weight > 4 && weight <= 6
      }, {
        id: 25,
        code: 6,
        // Benzilpenicilinas
        dosePerKg: [0.1, 0.4],
        measure: "MTV",
        maxPerDay: "20 MTV",
        notes: ["Dozė kūdikiams ir vaikams.", penicillinMessage, secondPenicillinMessage, "Dozė lygiomis dalimis leidžiama per parą per 4 kartus."],
        available: weight => weight > 6
      }, {
        id: 26,
        code: 8,
        // Deksametazonas
        dosePerKg: [0.15, 0.6],
        measure: "mg",
        maxDose: 12,
        notes: ["Ūminis laringitas – pradinė dozė 0,6 mg/kg (maks. dozė 12 mg), į raumenis.", "Krupas – 0,6 mg/kg x 1 dozė (maks. dozė 16 mg), į raumenis arba į veną.", "Astma - 0,6 mg/kg kas 24 val. (maks. dozė 16 mg), į raumenis arba į veną.", "Smegenų edema – pradėti nuo 1,5 mg/kg, į veną."]
      }, {
        id: 27,
        code: 9,
        // Diazepamas
        dosePerKg: [0.1, 0.4],
        measure: "mg",
        maxDose: 1,
        maxPerDay: "10 mg",
        doseWarning: warning("Iki 3 m. nepatartina"),
        notes: ["Esant epilepsinei būklei kas 2 - 3 min. leidžiama 1 mg kiekvieniems paciento amžiaus metams kol bus matomas poveikis.", "Vaistinį preparatą po 2 - 4 val. galima leisti kartotinai."]
      }, {
        id: 28,
        code: 10,
        // Dopaminas
        dosePerKg: [1, 20],
        measure: ["μg/kg/min", "μg/min"],
        doseWarning: infusion,
        notes: ["Norint paruošti infuzinį tirpalą, reikia dopaminą praskiesti 250 ml skiedikliu (5 % gliukozės arba 0,9 % " + "natrio chlorido tirpalo). Paruoštą tirpalą reikia vartoti nedelsiant. Jis stabilus išlieka 24 valandas.", "Vartojant šį vaistinį preparatą būtina stebėti AKS, ŠSD ir diurezę."]
      }, {
        id: 29,
        code: 11,
        // Epinefrinas
        dosePerKg: [0.01],
        measure: "mg",
        maxDose: 0.1 * weight,
        doseWarning: warning("Anafilaksijai"),
        notes: ["Dozė skirta iki 6 mėn. vaikams.", "Ištikus anafilaksiniam šokui, dozę didinant galima leisti 3 kartus į raumenis (kas 10 - 20 minučių)."],
        available: weight => weight <= 8
      }, {
        id: 30,
        code: 11,
        // Epinefrinas
        dosePerKg: [0.01],
        measure: "mg",
        maxDose: 1,
        doseWarning: warning("Bradikardijai"),
        notes: ["Skiriama simptominei bradikardijai, kai ŠSD < 60 k./min.", "Kartoti kas 3 - 5 min. į veną."]
      }, {
        id: 31,
        code: 11,
        // Epinefrinas
        dosePerKg: [0.01],
        measure: "mg",
        maxDose: 0.5,
        doseWarning: warning("Vaikų gaivinimui"),
        notes: ["Ampulę epinefrino atskiesti su 9 ml 0,9 % natrio chloridu ir leisti 0,1 ml atskiesto tirpalo.", "Kartoti kas 3 - 5 min. į veną."]
      }, {
        id: 32,
        code: 11,
        // Epinefrinas drip
        dosePerKg: [0.1, 1],
        measure: ["μg/kg/min", "μg/min"],
        doseWarning: infusion,
        notes: ["Maišomas su 0,9 % natrio chlorido infuziniu tirpalu."]
      }, {
        id: 45,
        code: 287,
        // Fentanilis
        dosePerKg: [1, 2],
        measure: "μg",
        doseWarning: error("Iki 2 m. negalima"),
        notes: ["Vaikai gali būti jautresni tiek gydomajam, tiek nepageidaujamam fentanilio poveikiui, ypač kvėpavimo slopinimui."]
      }, {
        id: 46,
        code: 287,
        // Fentanilis
        dosePerKg: [2, 4],
        measure: "μg",
        doseWarning: warning("Sedavimui"),
        notes: ["Dozė skirta vyresniems kaip 2 m. vaikams.", "Vaikai gali būti jautresni tiek gydomajam, tiek nepageidaujamam fentanilio poveikiui, ypač kvėpavimo slopinimui."]
      }, {
        id: 33,
        code: 18,
        // Gliukozė 200mg/ml
        dosePerKg: [0.5, 1],
        measure: "g",
        notes: ["Skiriama hipoglikemijos atveju į veną, kai gliukozės kiekis kraujyje < 3,5 mmol/l.", "Gliukozė skiedžiama su 0,9 % natrio chlorido infuziniu tirpalu.", "Naujagimiams skiriamas 10 %, vaikams - 25 %, paaugliams - 50 % tirpalas."]
      }, {
        id: 34,
        code: 24,
        // Ketorolakas
        dosePerKg: [0.6],
        secondDose: [0.2, 0.4],
        measure: "mg",
        maxDose: 30,
        doseWarning: warning("Iki 16 m. nepatartina"),
        notes: ["Vėlesnės dozės leidžiamos kas 4 - 6 val."]
      }, {
        id: 35,
        code: 25,
        // Klemastinas
        dosePerKg: [0.025],
        measure: "mg",
        maxPerDay: `${parseFloat((0.025 * weight).toFixed(3))} mg`,
        doseWarning: errorUpToOneYear,
        notes: ["Dozė suleidžiama į raumenis per du kartus.", "Tirpalą galima skiesti 0,9 % natrio chlorido arba 5 % gliukozės injekciniu tirpalu santykiu 1:5."]
      }, {
        id: 36,
        code: 247,
        // Lidokainas
        dosePerKg: [1],
        measure: "mg",
        maxDose: 5 * weight,
        doseWarning: warning("Naujagim. nepatartina"),
        notes: ["Ypač atsargiai šį vaistinį preparatą reikia skirti jaunesniems kaip 4 metų vaikams.", "Vaistas naudojamas intubacijos premedikacijai."]
      }, {
        id: 37,
        code: 26,
        // Magnio sulfatas heptahidratas
        dosePerKg: [25, 40],
        measure: "mg",
        maxPerDay: "40 g",
        notes: ["Skiriama, jei yra torsades de pointes (TdP).", "Į veną leidžiamo magnio tirpalo stiprumas turi būti ne didesnis kaip 200 mg/ml magnio sulfato heptahidrato, " + "todėl prieš vartojimą tirpalą būtina atskiesti 250 ml 5 % gliukozės arba 0,9 % natrio chlorido tirpalu.", "Leidimo į veną greitis turi būti ne didesnis kaip 150 mg/min."]
      }, {
        id: 38,
        code: 29,
        // Midazolamas
        dosePerKg: [0.1, 0.2],
        measure: "mg",
        maxDose: 10,
        doseWarning: warning("Iki 6 mėn. nepatartina"),
        notes: ["Jeigu tai jaunesnis kaip 6 mėn. kūdikis, reikia stebėti jo kvėpavimą bei kraujo įsotinimą deguonimi.", "Trumpo veikimo vaistas, vartojamas sedacijai sukelti, šalinantis nerimą ir atpalaiduojantis raumenis."]
      }, {
        id: 39,
        code: 30,
        // Morfino hidrochloridas
        dosePerKg: [0.2],
        measure: "mg",
        maxDose: 10,
        doseWarning: errorUpToSixMonths
      }, {
        id: 40,
        code: 30,
        // Morfino hidrochloridas
        dosePerKg: [0.02, 0.06],
        measure: ["mg/kg/val", "mg/val"],
        doseWarning: infusion,
        notes: ["Skiedžiamas 1 mg/kg 50 ml 5 % gliukozės tirpalu."]
      }, {
        id: 41,
        code: 33,
        // Natrio chloridas 500ml
        dosePerKg: [10],
        measure: "ml",
        doseWarning: infusion,
        notes: ["Skysčio boliuso dozė naujagimiams.", "Natrio kiekiui padidinti maksimalus saugus greitis 0,5 mmol/l/val.", "Apsvarstykite skysčio boliuso skyrimą, kai yra akivaizdus, žinomas širdies veiklos sutrikimas."],
        available: weight => weight < 6
      }, {
        id: 42,
        code: 33,
        // Natrio chloridas 500ml
        dosePerKg: [20],
        measure: "ml",
        doseWarning: infusion,
        notes: ["Skysčio boliuso dozė vaikams.", "Natrio kiekiui padidinti maksimalus saugus greitis 0,5 mmol/l/val.", "Apsvarstykite skysčio boliuso skyrimą, kai yra akivaizdus, žinomas širdies veiklos sutrikimas."],
        available: weight => weight >= 6
      }, {
        id: 43,
        code: 34,
        // Naloksonas
        dosePerKg: [0.01, 0.02],
        measure: "mg",
        notes: ["Prireikus dozę galima kartoti kas 3 - 5 min. tol, kol atsiranda sąmonė ir reguliarus kvėpavimas. ", "Suleistas į veną medikamentas po 30 - 120 sek. sukelia poveikį, kuris trunka 20 - 45 min. Suleisto į " + "raumenis ar po oda preparato poveikis pasireiškia po 2 - 3 min., tačiau trunka ilgiau, t. y. 150 - 180 min."]
      }, {
        id: 44,
        code: 35,
        // Nifedipinas
        dosePerKg: [0.25, 0.5],
        measure: "mg",
        maxDose: 40,
        maxPerDay: "80 mg",
        doseWarning: warning("Iki 18 m. nepatartina"),
        notes: ["Antihipertenzinis vaistinis preparatas."]
      }, {
        id: 47,
        code: 286,
        // Propofolis
        dosePerKg: [1.5, 2.5],
        measure: "mg",
        doseWarning: error("Iki 3 m. negalima"),
        notes: ["Skirti kartu su opioidais, analgetikais ir bendzodiazepinais draudžiama.", "Jei būtina, galima papildomai laipsniškai didinant dozę infuzuoti propofolį po 1 g/kg kūno svorio. "]
      }];
    },

    addMeasureToMaxDoseIfNumber(dose, measure) {
      if (!(typeof dose === "number")) return "";
      const replacedDose = this.replaceNumberDotToComma(parseFloat(dose.toFixed(3)));
      const extractedMeasure = typeof measure === "object" ? measure[1] : measure;
      return `${replacedDose} ${extractedMeasure}`;
    },

    medicamentPresentAndDoseAvailable: (medicament, dose, weight) => medicament && (!dose.available || dose.available(weight)),

    createMedicament(weight, dose) {
      const medicament = (0, _getMedicamentMediumsByCode.getMedicamentMediumsByCode)(dose.code, this.pcrClassificator);

      if (this.medicamentPresentAndDoseAvailable(medicament, dose, weight)) {
        let {
          dosePerKg,
          measure,
          maxDose,
          doseWarning,
          maxPerDay,
          secondDose,
          notes
        } = dose;
        const medType = medicament.types.find(type => type.code === dose.code);
        const maxDosePerDayFormatted = this.replaceNumberDotToComma(maxPerDay);
        return {
          id: dose.id,
          name: medicament.name,
          description: medType.name,
          doseDescription: this.createDoseDescription(dosePerKg, measure),
          dose: weight && this.countDose(dosePerKg, weight, measure, maxDose),
          secondDose: weight && secondDose && this.countDose(secondDose, weight, measure, maxDose),
          secondDoseDescription: weight && secondDose && this.createDoseDescription(secondDose, measure),
          doseWarning,
          medicament: { ...medicament,
            code: dose.code
          },
          maxDose: this.addMeasureToMaxDoseIfNumber(maxDose, measure),
          maxPerDay: maxDosePerDayFormatted,
          notes
        };
      }

      return null;
    },

    createMedicamentWithCustomDose(weight, customDose) {
      const medicament = (0, _getMedicamentMediumsByCode.getMedicamentMediumsByCode)(customDose.code, this.pcrClassificator);

      if (this.medicamentPresentAndDoseAvailable(medicament, customDose, weight)) {
        const {
          dose,
          doseWarning,
          maxDose,
          maxPerDay,
          notes
        } = customDose;
        const medType = medicament.types.find(type => type.code === customDose.code);
        const maxDoseFormatted = this.replaceNumberDotToComma(maxDose);
        return {
          id: customDose.id,
          name: medicament.name,
          description: medType.name,
          dose,
          doseWarning,
          medicament: { ...medicament,
            code: customDose.code
          },
          maxDose: maxDoseFormatted,
          maxPerDay,
          notes
        };
      }

      return null;
    },

    createDoseDescription(dosePerKg, measure) {
      let description = dosePerKg.reduce((description, dose) => description + (description ? " - " : description) + dose, "");
      if (typeof measure === "object") [measure] = measure;else measure += "/kg";
      return `${this.replaceNumberDotToComma(description)} ${measure}`;
    },

    countDose(dosePerKg, weight, measure, maxDose) {
      if (Ember.isNone(weight)) return null;
      let doseName = dosePerKg.reduce((name, dose) => {
        const formattedDose = `${this.formatDose(dose * weight, maxDose)}`;
        return name !== formattedDose ? this.nameWithDose(name, formattedDose) : name;
      }, "");
      if (typeof measure === "object") [, measure] = measure;
      return `${this.replaceNumberDotToComma(doseName)} ${measure}`;
    },

    formatDose: (doseForWeight, maxDose) => maxDose && doseForWeight > maxDose ? maxDose : parseFloat(doseForWeight.toFixed(3)),
    nameWithDose: (name, formattedDose) => name + (name ? " - " : name) + formattedDose,
    replaceNumberDotToComma: num => num == null ? num : `${num}`.split(".").join(","),
    message: message => ({
      message
    }),
    warning: message => ({
      type: "warning",
      message
    }),
    error: message => ({
      type: "error",
      message
    }),

    doses(weight) {
      const medicamentDoses = this.medDoses(weight).map(dose => this.createMedicament(weight, dose));
      const customDoses = this.customDoses(weight).map(dose => this.createMedicamentWithCustomDose(weight, dose));
      return [...customDoses, ...medicamentDoses];
    }

  });

  _exports.default = _default;
});