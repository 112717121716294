define("epcr-uis/pods/pcr/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oPQKECOY",
    "block": "{\"symbols\":[],\"statements\":[[5,\"pcr-error\",[],[[\"@message\",\"@buttonName\",\"@icon\",\"@onButtonClicked\"],[[22,\"model\"],[28,\"t\",[\"actions.reload\"],null],\"warning red sign huge icon\",[28,\"route-action\",[\"goBackToHome\"],null]]]],[0,\"\\n\"],[5,\"pcr-footer\",[],[[],[]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/pcr/error/template.hbs"
    }
  });

  _exports.default = _default;
});