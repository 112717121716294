define("epcr-uis/pods/components/pcr-document/vitals/help/abp-ranges/component", ["exports", "epcr-uis/pods/components/pcr-document/abstract/help-block/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    intl: Ember.inject.service(),
    time: Ember.inject.service(),
    blocks: Ember.computed(function () {
      const {
        time,
        pcr,
        ranges
      } = this;
      const {
        newBorn,
        lessThan1Year,
        between1And2Year,
        between2And5Year,
        between5And10Year,
        between10And12Year,
        moreThan12Year
      } = ranges;

      const createBlock = (range, value) => this.createHelpBlock(time, pcr, "mm/Hg", range, value);

      return [createBlock(newBorn, "60-80"), createBlock(lessThan1Year, "70-90"), createBlock(between1And2Year, "80-95"), createBlock(between2And5Year, "80-100"), createBlock(between5And10Year, "90-110"), createBlock(between10And12Year, "90-120"), createBlock(moreThan12Year, "100-120")];
    })
  });

  _exports.default = _default;
});