define("epcr-uis/pods/components/comments-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    comment: Ember.inject.service("pcr/comment"),
    classNames: ["comments-list"],
    isPreviousCommentHasSameAuthor: (previousComment, comment) => previousComment.userId === comment.userId,
    isFirstIteration: index => index === 0,
    alignCommentTo: (comment, position) => Ember.set(comment, "position", position),
    getAnotherPosition: position => position === "right" ? "left" : "right",
    canSaveComment: Ember.computed("commentValue", function () {
      const {
        commentValue
      } = this;
      return commentValue && !!commentValue.trim();
    }),
    commentsWithPosition: Ember.computed("comment.retrieveCommentsTask.last.value", function () {
      const comments = Ember.get(this, "comment.retrieveCommentsTask.last.value");
      comments && comments.map && comments.map((comment, index) => {
        if (this.isFirstIteration(index)) return this.alignCommentTo(comment, "left");
        const previousComment = comments.objectAt(index - 1);
        return this.alignCommentTo(comment, this.isPreviousCommentHasSameAuthor(previousComment, comment) ? previousComment.position : this.getAnotherPosition(previousComment.position));
      });
      return comments;
    }),
    actions: {
      onCommentSave(comment) {
        this.onCommentSave(comment).then(() => Ember.set(this, "commentValue", null));
      }

    }
  });

  _exports.default = _default;
});