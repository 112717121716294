define("epcr-uis/pods/pcr-classificator/medicaments/mirage", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MIRAGE_MEDICAMENTS = void 0;

  const MIRAGE_MEDICAMENTS = t => [(0, _medicamentsList.MEDICAMENTS)(t)[1], (0, _medicamentsList.MEDICAMENTS)(t)[2], (0, _medicamentsList.MEDICAMENTS)(t)[4], (0, _medicamentsList.MEDICAMENTS)(t)[5], (0, _medicamentsList.MEDICAMENTS)(t)[8], (0, _medicamentsList.MEDICAMENTS)(t)[9], (0, _medicamentsList.MEDICAMENTS)(t)[10], (0, _medicamentsList.MEDICAMENTS)(t)[11], (0, _medicamentsList.MEDICAMENTS)(t)[13], (0, _medicamentsList.MEDICAMENTS)(t)[14], (0, _medicamentsList.MEDICAMENTS)(t)[15], (0, _medicamentsList.MEDICAMENTS)(t)[16], (0, _medicamentsList.MEDICAMENTS)(t)[17], (0, _medicamentsList.MEDICAMENTS)(t)[18], (0, _medicamentsList.MEDICAMENTS)(t)[19], (0, _medicamentsList.MEDICAMENTS)(t)[26], (0, _medicamentsList.MEDICAMENTS)(t)[27], (0, _medicamentsList.MEDICAMENTS)(t)[29], (0, _medicamentsList.MEDICAMENTS)(t)[30], (0, _medicamentsList.MEDICAMENTS)(t)[34], (0, _medicamentsList.MEDICAMENTS)(t)[36], (0, _medicamentsList.MEDICAMENTS)(t)[38], (0, _medicamentsList.MEDICAMENTS)(t)[39], (0, _medicamentsList.MEDICAMENTS)(t)[40], (0, _medicamentsList.MEDICAMENTS)(t)[41]];

  _exports.MIRAGE_MEDICAMENTS = MIRAGE_MEDICAMENTS;
});