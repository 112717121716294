define("epcr-uis/helpers/has-patient", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasPatient = hasPatient;
  _exports.default = void 0;

  function hasPatient([pcr]) {
    if (!pcr) return false;
    const eventCancelReason = Ember.get(pcr, "eventCancelReason.code");
    const noPatientReason = Ember.get(pcr, "noPatientReason");
    return !eventCancelReason && !noPatientReason;
  }

  var _default = Ember.Helper.helper(hasPatient);

  _exports.default = _default;
});