define("epcr-uis/mixins/route-tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    pcrRouteTracker: Ember.inject.service(),
    actions: {
      didTransition() {
        const routeTracker = this.pcrRouteTracker;
        routeTracker.set("currentRoute", this.routeName);
        routeTracker.set("routeArguments", arguments);

        this._super(...arguments);
      }

    }
  });

  _exports.default = _default;
});