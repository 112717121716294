define("epcr-uis/pods/components/pcr-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Zf3bje2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[0,\"© OpenG™\\n\"],[4,\"if\",[[24,[\"features\",\"demo\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"https://epcr.eu/privacy-notice.html\"],[10,\"class\",\"pcr-footer-privacy-notice\"],[8],[0,\"• Privacy notice\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"unless\",[[24,[\"features\",\"demo\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[22,\"version\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-footer/template.hbs"
    }
  });

  _exports.default = _default;
});