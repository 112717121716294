define("epcr-uis/pods/user/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    getUser(externalId) {
      return this.store.findRecord("user", externalId, {
        backgroundReload: false
      });
    }

  });

  _exports.default = _default;
});