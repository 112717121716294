define("epcr-uis/pods/components/pcr-document/flowchart/recommendation-input/component", ["exports", "ember-concurrency", "epcr-uis/helpers/get-classificator-element-by-code", "epcr-uis/helpers/get-medicament-mediums-by-code", "epcr-uis/helpers/create-treatment", "epcr-uis/mixins/metrics-tracker", "epcr-uis/mixins/treatment-observer"], function (_exports, _emberConcurrency, _getClassificatorElementByCode, _getMedicamentMediumsByCode, _createTreatment, _metricsTracker, _treatmentObserver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const buildLabel = treatmentGroup => treatmentGroup.map(t => t.code).join();

  var _default = Ember.Component.extend(_metricsTracker.default, _treatmentObserver.default, {
    pcrTime: Ember.inject.service("pcr/time"),
    store: Ember.inject.service(),
    pcrClassificator: Ember.inject.service(),
    pcrRecommendationTranslator: Ember.inject.service(),
    recommendationService: Ember.inject.service("pcr/flowchart/recommendation"),
    classNames: ["recommendations"],
    onRecommendationsPopupClose: (0, _emberConcurrency.task)(function* () {
      yield this.transitionToFlowchart();
    }),

    getPcrMemberFromMemberIndex(index) {
      if (!index) return null;
      const members = Ember.get(this, "pcr.teamActivation.team.members");

      if (members) {
        const member = members.objectAt(index - 1);
        return member ? {
          externalId: member.externalId
        } : null;
      }

      return null;
    },

    addProcedure(treatment) {
      const procedure = (0, _getClassificatorElementByCode.getClassificatorElementByCode)("flowchart.procedures", treatment.code, this.pcrClassificator);
      const time = this.pcrTime.newTime(this.pcr).forTreatment(treatment.timeOffsetMin);
      const procedureFragment = (0, _createTreatment.createProcedure)([procedure, this.store, time]);
      this.addObserverForProcedure(procedureFragment);
      const procedures = this.pcr.patientTreatment.procedureTreatment;
      this.setProcedureProperties(procedureFragment, treatment);
      procedures.pushObject(procedureFragment);
    },

    setProcedureProperties(procedureFragment, treatment) {
      procedureFragment.setProperties({
        total: treatment.total ? this.pcrClassificator.findElementByCode("totalProcedures", treatment.total) : null,
        notes: treatment.notes,
        member: this.getPcrMemberFromMemberIndex(treatment.memberIndex)
      });
    },

    addMedicament(treatment) {
      const medicament = (0, _getMedicamentMediumsByCode.getMedicamentMediumsByCode)(treatment.code, this.pcrClassificator) || {};
      medicament.useType = this.pcrClassificator.findElementByCode("allMedicamentsUseTypes", treatment.useType);
      const data = this.getMedicamentData(treatment, medicament);
      const medFragment = (0, _createTreatment.addMedicamentToMedicaments)(this.pcr, this.pcrTime, this.store, medicament, data);
      this.addObserverForMedicament(medFragment);
    },

    getMedicamentData(treatment, medicament) {
      return {
        total: treatment.total ? this.pcrClassificator.findElementByCode("totalDoses", treatment.total) : null,
        notes: treatment.notes,
        member: this.getPcrMemberFromMemberIndex(treatment.memberIndex),
        "medicament.code": treatment.code,
        "medicament.medium": {
          code: treatment.code,
          name: medicament.types && medicament.types.findBy("code", treatment.code).name
        }
      };
    },

    actions: {
      updateRecommendations(pcr) {
        this.recommendationService.reloadRecommendations(pcr);
      },

      addRecommendedTreatment(treatmentGroup) {
        treatmentGroup.forEach(treatment => {
          if (treatment.type === "P") this.addProcedure(treatment);else if (treatment.type === "M") this.addMedicament(treatment);
          this.transitionToFlowchart();
        });
        this.trackEvent("Recommendations", "Used recommendation", buildLabel(treatmentGroup), treatmentGroup.length);
      }

    }
  });

  _exports.default = _default;
});