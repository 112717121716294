define("epcr-uis/pods/components/pcr-document/signatures/er-person-signature/component", ["exports", "epcr-uis/helpers/promise-converter"], function (_exports, _promiseConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hospitalDoctorService: Ember.inject.service("pcr/agreements-signatures/hospital-doctor-signature"),
    classNames: ["ui", "grid"],
    scrollToField: Ember.inject.service(),
    erPersonSource: Ember.computed(function () {
      return query => (0, _promiseConverter.promiseToPlainValueConverter)(this.dataSource(query));
    }),

    dataSource(query) {
      const hospitalDepartmentCode = Ember.get(this, "pcr.transportation.hospitalDepartment.code");
      return this.hospitalDoctorService.queryDoctors(hospitalDepartmentCode, query);
    },

    actions: {
      scrollToField(elementSelector) {
        return this.scrollToField.animate($(elementSelector));
      }

    }
  });

  _exports.default = _default;
});