define("epcr-uis/mirage/factories/people", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const lithuanianFirstName = i => i % 2 ? _emberCliMirage.faker.random.arrayElement(["Tomas", "Lukas", "Mantas", "Petras", "Andrius"]) : _emberCliMirage.faker.random.arrayElement(["Ona", "Gegutė", "Lina", "Viktorija", "Rita"]);

  const lithuanianLastName = i => i % 2 ? _emberCliMirage.faker.random.arrayElement(["Pavardenis", "Programavičius", "Katanavičius", "Kolibrius", "Loratavičius"]) : _emberCliMirage.faker.random.arrayElement(["Parvadienytė", "Stabdavičienė", "Geliutytė", "Taikievinė", "Bronė"]);

  var _default = _emberCliMirage.Factory.extend({
    firstName: i => (0, _isEnglish.isEnglish)() ? "" : lithuanianFirstName(i),
    lastName: i => (0, _isEnglish.isEnglish)() ? "" : lithuanianLastName(i),
    code: i => `CODE${i + 1}`,
    id: i => `${i + 1}`,
    positions: ["SLAUG", "GYD", "PARAM"]
  });

  _exports.default = _default;
});