define("epcr-uis/pods/pcr/summary/print/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6x/B+6uz",
    "block": "{\"symbols\":[\"Print\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"pcr-document/print\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@source\"],[[24,[\"model\",\"source\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/pcr/summary/print/template.hbs"
    }
  });

  _exports.default = _default;
});