define("epcr-uis/helpers/is-pcr-locked", ["exports", "moment", "epcr-uis/helpers/can-edit-pcr"], function (_exports, _moment, _canEditPcr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isPcrLocked = void 0;

  const isPcrRecentlySaved = (localLockedPcrList, pcrId) => (0, _moment.default)() - localLockedPcrList[pcrId] < 60000;

  const isPcrLocked = ([state, pcrId, localLockedPcrList]) => isPcrRecentlySaved(localLockedPcrList, pcrId) || !(0, _canEditPcr.canEditPcr)(state);

  _exports.isPcrLocked = isPcrLocked;

  var _default = Ember.Helper.helper(isPcrLocked);

  _exports.default = _default;
});