define("epcr-uis/pods/home/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    features: Ember.inject.service(),

    beforeModel({
      to: {
        queryParams: {
          nextRoute
        }
      }
    }) {
      const {
        androidControls
      } = this; // todo. Knasas sake issitestavo ir viskas veikia. Isbandziaus as - NEVEIKIA. Gal, ant naujausios Android versijos veiks?

      const enabledAndroidHomeButton = Ember.get(this, "features.enabledAndroidHomeButton");
      if (enabledAndroidHomeButton && androidControls && !androidControls.interceptHomeClick()) androidControls.onHomeClick();else this.transitionTo(nextRoute || "list.active-pcrs");
    }

  });

  _exports.default = _default;
});