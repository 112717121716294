define("epcr-uis/mirage/factories/recommendation", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const firstSet = [[{
    code: 1,
    type: "P"
  }], [{
    code: 1,
    type: "M",
    useType: "40_53"
  }, {
    code: 25,
    type: "P"
  }]];
  const secondSet = [[{
    code: 33,
    type: "M",
    useType: "40_52",
    total: 6,
    memberIndex: 1,
    timeOffsetMin: 1,
    notes: "best medicament"
  }, {
    code: 17,
    type: "P",
    total: 4,
    memberIndex: 1,
    timeOffsetMin: 1,
    notes: "best procedure"
  }]];

  var _default = _emberCliMirage.Factory.extend({
    name: i => i % 2 ? "THIN_BLOOD" : "AIRBAG_VENTILATION",
    treatments: i => i % 2 ? firstSet : secondSet
  });

  _exports.default = _default;
});