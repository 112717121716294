define("epcr-uis/pods/pcr/service", ["exports", "epcr-uis/config/environment", "ember-concurrency", "moment", "raven", "epcr-uis/pods/pcr/backend-normalize", "epcr-uis/helpers/can-edit-pcr", "epcr-uis/constants"], function (_exports, _environment, _emberConcurrency, _moment, _raven, _backendNormalize, _canEditPcr, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function reloadPcrIfCreatedNew(pcr) {
    const currentUrl = window.location.href;

    if (currentUrl.includes(_environment.default.APP.unsavedPcrId)) {
      const currentPcrId = `${pcr.eventId}-${pcr.missionId}-${pcr.patientId}`;
      window.location.href = currentUrl.replace(_environment.default.APP.unsavedPcrId, currentPcrId);
    }
  }

  var _default = Ember.Service.extend(Ember.Evented, {
    store: Ember.inject.service(),
    timeService: Ember.inject.service("time"),
    simpleAuthFetch: Ember.inject.service(),
    pcrClassificator: Ember.inject.service(),
    historyService: Ember.inject.service("history"),
    pcrStateService: Ember.inject.service("pcr-state"),
    validation: Ember.inject.service(),
    lockedPcr: Ember.inject.service(),
    pcrRouteTracker: Ember.inject.service(),
    router: Ember.inject.service(),
    pcrSaveActions: (0, _emberConcurrency.taskGroup)(),
    modifiedTime: new Date() * 1,
    pathsForObserver: [],
    tempSaveRetryInterval: null,
    procedureTreatmentPathsForObserver: [],
    inventoryPathsForObserver: [],
    medicamentTreatmentPathsForObserver: [],
    assessmentPathsForObserver: [],

    _removeObserver(model, path) {
      model.forEach ? model.length && model.forEach(m => m.removeObserver(path, this, this.onPcrObserver)) : model.removeObserver(path, this, this.onPcrObserver);
    },

    clearPcrFromStore(id) {
      const pcr = this.store.peekRecord("pcr", id);
      pcr && this.store.unloadRecord(pcr);
    },

    getPCRDocument(id) {
      this.clearPcrFromStore(id);
      return this.store.findRecord("pcr", id);
    },

    editPCR(pcr, adapterOptions = {}) {
      return this.editPcrTask.perform(pcr, adapterOptions);
    },

    savePcrTemporary: (0, _emberConcurrency.task)(function* (pcr, delay) {
      Ember.run.cancel(this.tempSaveRetryInterval);
      const state = Ember.get(pcr, "metadata.state.code");

      if ((0, _canEditPcr.canEditPcr)(state)) {
        yield (0, _emberConcurrency.timeout)(delay);

        const url = _environment.default.APP.pcrTemporaryUrl.replace(":id", Ember.get(pcr, "id"));

        const pcrData = this.simpleAuthFetch.getSerializedPcrData(pcr);
        return yield this.simpleAuthFetch.fetchRequest(url, "PUT", pcrData, "text/plain").then(response => response.text()).catch(e => {
          this.onSaveTemporaryRetry(...arguments);
          return e;
        });
      }

      return null;
    }).restartable(),

    onSaveTemporaryRetry() {
      const interval = Ember.run.later(() => this.savePcrTemporary.perform(...arguments), _environment.default.APP.saveTempRetryTimeIntervalMs);
      return Ember.set(this, "tempSaveRetryInterval", interval);
    },

    deletePcrTemporary: (0, _emberConcurrency.task)(function* (pcr) {
      if ((0, _canEditPcr.canEditPcr)(Ember.get(pcr, "metadata.state.code"))) {
        const url = _environment.default.APP.pcrTemporaryUrl.replace(":id", pcr.id);

        return yield this.simpleAuthFetch.fetchRequest(url, "DELETE").catch(e => e);
      }

      return null;
    }).restartable(),

    createFragment(type, data) {
      return this.store.createFragment(type, data);
    },

    editPcrTask: (0, _emberConcurrency.task)(function* (pcr, adapterOptions) {
      return yield pcr.save({
        adapterOptions
      }).then(() => this.trigger("onSuccessPcrSave"));
    }),
    setPcrSaveTime: (context, id) => Ember.set(context.lockedPcr.locks, id, (0, _moment.default)()),
    verifyPcr: (0, _emberConcurrency.task)(function* (pcr) {
      if (!this.validation.errorCount) {
        yield this.savePcrTask.perform(pcr);
        yield this.pcrStateService.changePcrStateToVerified(pcr).then(() => {
          this.setPcrSaveTime(this, pcr.id);
          this.router.transitionTo(this.pcrRouteTracker.lastVisitedPcrList || "home").then(() => {
            this.historyService.clearBrowserHistory();
          });
        });
      }
    }).group("pcrSaveActions"),
    savePcrTask: (0, _emberConcurrency.task)(function* (pcr) {
      const self = this;
      yield this.pcrStateService.getPcrState(pcr.id).then(async ({
        state
      }) => {
        if ((0, _canEditPcr.canEditPcr)(state)) {
          await self.editPCR(pcr);
          reloadPcrIfCreatedNew(pcr);
        } else {
          throw new Error(`Can not save PCR because form state is ${state}`);
        }
      });
    }).group("pcrSaveActions"),

    pushPathsIntoExistingPathsOrCreateNewVariableForArrayPaths(rootPath, arrayPaths, paths) {
      // array yra deep nested, todel observeris prikabinamas atskirai
      if (rootPath === "patientTreatment.procedureTreatment") return Ember.set(this, "procedureTreatmentPathsForObserver", arrayPaths);
      if (rootPath === "patientTreatment.inventory") return Ember.set(this, "inventoryPathsForObserver", arrayPaths);
      if (rootPath === "patientTreatment.medicamentTreatment") return Ember.set(this, "medicamentTreatmentPathsForObserver", arrayPaths);
      if (rootPath === "patientAssessments") return Ember.set(this, "assessmentPathsForObserver", arrayPaths);
      return arrayPaths.forEach(path => paths.push(`${rootPath}.@each.${path}`));
    },

    getPcrModelPathsForObserver(context, paths, rootPath) {
      const contextValue = rootPath ? context.get(rootPath) : context;

      if (!contextValue) {
        _raven.default.captureMessage(`unknown model field = ${rootPath}`);
      } else if (contextValue.forEach && contextValue.type === _constants.PCR_CLASSIFICATOR) {
        // if classificator array
        paths.push(`${rootPath}.@each.code`);
      } else if (contextValue.forEach && contextValue.type !== _constants.PCR_CLASSIFICATOR) {
        // if array
        const arrayPaths = [];
        this.getPcrModelPathsForObserver(contextValue.firstObject, arrayPaths, "");
        this.pushPathsIntoExistingPathsOrCreateNewVariableForArrayPaths(rootPath, arrayPaths, paths);
        paths.push(`${rootPath}.[]`);
      } else if (contextValue.type === _constants.PCR_CLASSIFICATOR) {
        // if classificator fragment
        paths.push(`${rootPath}.code`);
      } else {
        // if fragment
        contextValue.eachAttribute((name, meta) => {
          const path = rootPath ? `${rootPath}.${name}` : name;
          meta.kind === "attribute" ? paths.push(path) : this.getPcrModelPathsForObserver(context, paths, path);
        });
      }
    },

    normalizePcr(context, classificator, station, teamId, defaultFragmentValue) {
      (0, _backendNormalize.getNormalizedPcr)(context, classificator, station, teamId, defaultFragmentValue);
    },

    getTimestamp() {
      return new Date() * 1;
    },

    createEmptyPcr() {
      const context = {
        id: this.getTimestamp(),
        patientAssessments: [{}],
        patientTreatment: {
          medicamentTreatment: [{}],
          procedureTreatment: [{}],
          inventory: [{}]
        }
      };
      this.normalizePcr(context, this.pcrClassificator, "99", "", {});
      return this.store.createRecord("pcr", context);
    },

    onPcrObserver() {
      Ember.set(this, "modifiedTime", this.getTimestamp());
    },

    _addObserverForModel(model, paths) {
      model && paths.forEach(path => model.addObserver(path, this, this.onPcrObserver));
    },

    addObserverForPcr(pcrDocument) {
      const paths = this.pathsForObserver;

      if (!paths.length) {
        this.getPcrModelPathsForObserver(this.createEmptyPcr(), paths, "");
        Ember.set(this, "pathsForObserver", paths);
      }

      this._addObserverForModel(pcrDocument, paths);
    },

    addObserverForPcrAssessment(assessment) {
      this._addObserverForModel(assessment, this.assessmentPathsForObserver);
    },

    addObserverForPcrMedicament(medicament) {
      this._addObserverForModel(medicament, this.medicamentTreatmentPathsForObserver);
    },

    addObserverForPcrProcedure(procedure) {
      this._addObserverForModel(procedure, this.procedureTreatmentPathsForObserver);
    },

    addObserverForPcrInventory(inventory) {
      this._addObserverForModel(inventory, this.inventoryPathsForObserver);
    },

    _removeObserverForModel(model, paths) {
      model && paths.forEach(path => this._removeObserver(model, path));
    },

    removeObserverForPcr(pcr) {
      this._removeObserverForModel(pcr, this.pathsForObserver);
    },

    removeObserverForPcrAssessment(assessment) {
      this._removeObserverForModel(assessment, this.assessmentPathsForObserver);
    },

    removeObserverForPcrMedicament(medicament) {
      this._removeObserverForModel(medicament, this.medicamentTreatmentPathsForObserver);
    },

    removeObserverForPcrProcedure(procedure) {
      this._removeObserverForModel(procedure, this.procedureTreatmentPathsForObserver);
    },

    removeObserverForPcrInventory(inventory) {
      this._removeObserverForModel(inventory, this.inventoryPathsForObserver);
    },

    cancelPcrTemporarySave() {
      this.savePcrTemporary.cancelAll();
      Ember.run.cancel(this.tempSaveRetryInterval);
    },

    replacePcrSignatureData(pcr) {
      if (pcr.agreementsSignatures) {
        const {
          hospitalDoctorSignature,
          infoShareAgreement,
          medicalHelpAgreement,
          teamLeaderSignature,
          narcoticDrugsVerification
        } = pcr.agreementsSignatures;
        if (hospitalDoctorSignature) pcr.agreementsSignatures.hospitalDoctorSignature.signatureImage = this.replaceSignatureToSimpleString(hospitalDoctorSignature.signatureImage);
        if (medicalHelpAgreement && medicalHelpAgreement.signature) pcr.agreementsSignatures.medicalHelpAgreement.signature.signatureImage = this.replaceSignatureToSimpleString(medicalHelpAgreement.signature.signatureImage);
        if (infoShareAgreement && infoShareAgreement.signature) pcr.agreementsSignatures.infoShareAgreement.signature.signatureImage = this.replaceSignatureToSimpleString(infoShareAgreement.signature.signatureImage);
        if (teamLeaderSignature) pcr.agreementsSignatures.teamLeaderSignature.signatureImage = this.replaceSignatureToSimpleString(teamLeaderSignature.signatureImage);
        if (narcoticDrugsVerification) pcr.agreementsSignatures.narcoticDrugsVerification.signatureImage = this.replaceSignatureToSimpleString(narcoticDrugsVerification.signatureImage);
      }
    },

    replaceSignatureToSimpleString: signature => signature ? "data:image/png;base64,YQ==" : undefined
  });

  _exports.default = _default;
});