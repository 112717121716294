define("epcr-uis/pods/phc/adapter", ["exports", "epcr-uis/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    model: "phcs",

    urlForFindRecord(id) {
      return `${this.mainPath(this.model)}/${id}`;
    },

    urlForQuery() {
      return `${this.mainPath(this.model)}`;
    }

  });

  _exports.default = _default;
});