define("epcr-uis/mirage/serializers/active-pcr", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.RestSerializer.extend({
    embed: true,
    include: ["age"],

    serialize() {
      const json = _emberCliMirage.RestSerializer.prototype.serialize.apply(this, arguments);

      return json.activePcrs;
    }

  });

  _exports.default = _default;
});