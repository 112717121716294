define("epcr-uis/pods/components/config/control-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TQm+qKWV",
    "block": "{\"symbols\":[\"@title\",\"&default\",\"@featureName\",\"@iconOff\",\"@iconOn\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"title-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"button\",true],[11,\"class\",[29,[\"ui \",[28,\"if\",[[28,\"get\",[[24,[\"features\"]],[23,3,[]]],null],\"primary\",\"off\"],null],\" basic icon button\"]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggle\",[23,3,[]]],null]],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[[28,\"if\",[[28,\"get\",[[24,[\"features\"]],[23,3,[]]],null],[23,5,[]],[23,4,[]]],null]]]],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/config/control-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});