define("epcr-uis/pods/print/desktop/service", ["exports", "epcr-uis/pods/print/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    hasPrinterApi: false,

    isNotAllowedPrinting() {
      return Ember.get(this, "fetchTask.isRunning") || Ember.get(this, "printingTask.isRunning");
    },

    isLoading: Ember.computed.alias("isPrintingNotAllowed"),

    sendBase64PdfDataToDevice(base64Data) {
      this.printGeneratedPdf(base64Data);
    },

    printGeneratedPdf(base64Data) {
      const blobUrl = this.generateBlobUrl(base64Data, "application/pdf");
      const iframe = this.createIframeWithBlob(blobUrl);
      iframe.contentWindow.print();
      this.removeIframeWithBlob(iframe, blobUrl);
    },

    removeIframeWithBlob(iframe, blobUrl) {
      const oldOnFocusFunction = document.body.onfocus;

      document.body.onfocus = () => {
        document.body.removeChild(iframe);
        URL.revokeObjectURL(blobUrl);
        document.body.onfocus = oldOnFocusFunction;
      };
    },

    generateBlobUrl(base64Data, type) {
      const blob = this.b64toBlob(base64Data, type);
      return URL.createObjectURL(blob);
    },

    createIframeWithBlob(blobUrl) {
      const iframe = document.createElement("iframe");
      iframe.setAttribute("src", blobUrl);
      iframe.setAttribute("style", "display:none");
      return document.body.appendChild(iframe);
    },

    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data.slice(28));
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = [];

        for (let i = 0; i < slice.length; i++) {
          byteNumbers.push(slice.charCodeAt(i));
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, {
        type: contentType
      });
    }

  });

  _exports.default = _default;
});