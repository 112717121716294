define("epcr-uis/pods/pcr-classificator/medicaments/ukmerge", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UKMERGE_MEDICAMENTS = void 0;

  const UKMERGE_MEDICAMENTS = t => ({
    stations: ["52"],
    medicaments: [(0, _medicamentsList.MEDICAMENTS)(t)[201], (0, _medicamentsList.MEDICAMENTS)(t)[202], (0, _medicamentsList.MEDICAMENTS)(t)[203], (0, _medicamentsList.MEDICAMENTS)(t)[204], (0, _medicamentsList.MEDICAMENTS)(t)[205], (0, _medicamentsList.MEDICAMENTS)(t)[206], (0, _medicamentsList.MEDICAMENTS)(t)[208], (0, _medicamentsList.MEDICAMENTS)(t)[209], (0, _medicamentsList.MEDICAMENTS)(t)[211], (0, _medicamentsList.MEDICAMENTS)(t)[212], (0, _medicamentsList.MEDICAMENTS)(t)[214], (0, _medicamentsList.MEDICAMENTS)(t)[216], (0, _medicamentsList.MEDICAMENTS)(t)[218], (0, _medicamentsList.MEDICAMENTS)(t)[220], (0, _medicamentsList.MEDICAMENTS)(t)[221], (0, _medicamentsList.MEDICAMENTS)(t)[224], (0, _medicamentsList.MEDICAMENTS)(t)[226], (0, _medicamentsList.MEDICAMENTS)(t)[230], (0, _medicamentsList.MEDICAMENTS)(t)[232], (0, _medicamentsList.MEDICAMENTS)(t)[233], (0, _medicamentsList.MEDICAMENTS)(t)[236], (0, _medicamentsList.MEDICAMENTS)(t)[237], (0, _medicamentsList.MEDICAMENTS)(t)[238], (0, _medicamentsList.MEDICAMENTS)(t)[242], (0, _medicamentsList.MEDICAMENTS)(t)[243], (0, _medicamentsList.MEDICAMENTS)(t)[245], (0, _medicamentsList.MEDICAMENTS)(t)[246], (0, _medicamentsList.MEDICAMENTS)(t)[253], (0, _medicamentsList.MEDICAMENTS)(t)[254], (0, _medicamentsList.MEDICAMENTS)(t)[266], (0, _medicamentsList.MEDICAMENTS)(t)[281], (0, _medicamentsList.MEDICAMENTS)(t)[283], (0, _medicamentsList.MEDICAMENTS)(t)[284]]
  });

  _exports.UKMERGE_MEDICAMENTS = UKMERGE_MEDICAMENTS;
});