define("epcr-uis/pods/components/fullscreen-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a9e1D1c6",
    "block": "{\"symbols\":[],\"statements\":[[5,\"ui-loader\",[],[[\"@isLoading\",\"@cssClass\"],[true,\"text\"]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"t\",[\"message.loading\"],null],false],[0,\"...\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/fullscreen-loader/template.hbs"
    }
  });

  _exports.default = _default;
});