define("epcr-uis/pods/components/pcr-document/vitals/help/spo2-ranges/component", ["exports", "epcr-uis/pods/components/pcr-document/abstract/help-block/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    intl: Ember.inject.service(),
    blocks: Ember.computed(function () {
      return [this.createSimpleHelpBlock(this.intl.t("message.spO2.normal"), ">95", "%"), this.createSimpleHelpBlock(this.intl.t("message.spO2.low"), "91-95", "%"), this.createSimpleHelpBlock(this.intl.t("message.spO2.critical"), "<91", "%")];
    })
  });

  _exports.default = _default;
});