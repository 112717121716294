define("epcr-uis/mirage/serializers/birth-criteria", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.RestSerializer.extend({
    embed: true,
    include: ["apgar1Min", "apgar5Min"]
  });

  _exports.default = _default;
});