define("epcr-uis/pods/components/pcr-document/transportation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pOrBwL02",
    "block": "{\"symbols\":[\"patientTransportedTo\",\"@pcr\"],\"statements\":[[4,\"let\",[[28,\"patient-transported-to\",[[23,2,[\"transportationType\",\"code\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"sidebar-switcher-scope\",\"transportation\"],[8],[0,\"\\n    \"],[5,\"show-block\",[],[[\"@pcr\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n      \"],[5,\"section-header\",[[12,\"class\",\"transportation-header\"]],[[\"@title\",\"@icon\"],[[28,\"t\",[\"headings.transportation.transportationTitle\"],null],\"ambulance icon big\"]]],[0,\"\\n      \"],[5,\"pcr-document/transportation/disposition\",[],[[\"@pcr\",\"@patientTransportedTo\"],[[23,2,[]],[23,1,[]]]]],[0,\"\\n\\n\"],[4,\"if\",[[28,\"not-eq\",[[23,2,[\"transportation\",\"route\",\"code\"]],\"HOSPITAL_TO_HOME\"],null]],null,{\"statements\":[[0,\"        \"],[5,\"section-header\",[[12,\"class\",\"destination-header\"]],[[\"@title\",\"@icon\"],[[28,\"t\",[\"headings.transportation.transportedTo\"],null],\"hospital outline icon big\"]]],[0,\"\\n        \"],[5,\"pcr-document/transportation/destination\",[],[[\"@pcr\",\"@patientTransportedTo\"],[[23,2,[]],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"pcr-document/transportation/additional-info\",[],[[\"@pcr\"],[[23,2,[]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/transportation/template.hbs"
    }
  });

  _exports.default = _default;
});