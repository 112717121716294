define("epcr-uis/pods/components/age-with-measure/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yI4OsASm",
    "block": "{\"symbols\":[\"measurment\",\"@age\",\"@measure\"],\"statements\":[[4,\"if\",[[28,\"and\",[[23,2,[]],[23,3,[]]],null]],null,{\"statements\":[[4,\"with\",[[28,\"find-by\",[\"code\",[23,3,[]],[24,[\"pcrClassificator\",\"ageMeasurement\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[23,2,[]],false],[0,\" \"],[1,[23,1,[\"shortName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/age-with-measure/template.hbs"
    }
  });

  _exports.default = _default;
});