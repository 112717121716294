define("epcr-uis/pods/components/pcr-document/incident/times/time/component", ["exports", "ember-components/mixins/validation-class-mixin", "ember-components/components/side-bar/time-picker"], function (_exports, _validationClassMixin, _timePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const editTimesRequestPath = "pcr.metadata.editTimesRequests";

  var _default = _timePicker.default.extend(_validationClassMixin.default, {
    classNames: ["time-block"],
    intl: Ember.inject.service(),

    formatTime(timeInSeconds) {
      if (Ember.isEmpty(timeInSeconds)) return null;
      const isNegative = timeInSeconds < 0;

      const roundedNumber = number => isNegative ? Math.ceil(number) : Math.floor(number);

      const isTimeLessThanMinute = timeInSeconds < 60 && timeInSeconds > -60;
      if (isTimeLessThanMinute) return `${roundedNumber(timeInSeconds)} s`;else return `${roundedNumber(timeInSeconds / 60)} min`;
    },

    getTimeEditRequest(key) {
      return Ember.get(this, `${editTimesRequestPath}.${key}.to`);
    },

    editTime: Ember.computed(`${editTimesRequestPath}.{onRouteTime,arrivedTime}.to`, function () {
      return this.getTimeEditRequest(this.timeKey);
    }),
    timeDuration: Ember.computed("pcr.times.{callTime,registrationTime,assignTime,onRouteTime,arrivedTime,transportationTime," + "atHospitalTime,availableTime,returnedTime}", "editTime", {
      get() {
        const {
          time,
          times,
          timeIndex,
          editTime
        } = this;

        if (time || editTime) {
          const currentTime = editTime || time;

          for (let i = timeIndex - 1; i >= 0; i--) {
            const timeKey = times[i].value;
            const previousTimeValue = times[i].canRequestChange && this.getTimeEditRequest(timeKey) || Ember.get(this, `pcr.times.${timeKey}`);

            if (previousTimeValue) {
              const timeDiffInSeconds = currentTime.diff(previousTimeValue) / 1000;
              return this.formatTime(timeDiffInSeconds);
            }
          }
        }

        return null;
      },

      set: (key, value) => value
    }),
    actions: {
      registerDeletedValue: (pcr, value) => Ember.set(pcr, `times.${value}Deleted`, true)
    }
  });

  _exports.default = _default;
});