define("epcr-uis/pods/pcr/summary/print/adapter", ["exports", "epcr-uis/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    urlForCreateRecord() {
      return this.mainPath("/exports/pcr-pdf");
    },

    urlForFindRecord(id) {
      return this.mainPath(`/pcrs/${id}/exports/pcr-pdf`);
    }

  });

  _exports.default = _default;
});