define("epcr-uis/pods/components/pcr-document/pcr-attachments/component", ["exports", "epcr-uis/mixins/metrics-tracker", "ember-concurrency"], function (_exports, _metricsTracker, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    attachmentService: Ember.inject.service("pcr/attachment"),
    isAddButtonDisabled: false,

    init() {
      this._super(...arguments);

      Ember.set(this.attachmentService, "pcrId", this.pcrId);
      this.loadAttachments();
    },

    loadAttachments() {
      return this.attachmentService.retrieveAttachments.perform();
    },

    actions: {
      uploadPhoto(file) {
        this.trackEvent("Attachment", "Add attachment", file.name);

        if (file.type.includes("image/")) {
          const uploadTaskInstance = this.attachmentService.uploadAttachment.perform(file, this.pcrId);
          return this.attachmentService.addUploadingAttachmentToQueue(uploadTaskInstance);
        } else {
          return Ember.set(this, "attachmentService.lastUploadError", "FILE_TYPE_IS_NOT_SUPPORTED");
        }
      },

      retryLoadAttachments() {
        this.loadAttachments();
      },

      onAttachmentRemove(attachmentId) {
        return this.attachmentService.removeAttachmentTask.perform(attachmentId).then(() => this.loadAttachments());
      },

      disableAddButtonForAWhile() {
        this.toggleUploadButton && this.toggleUploadButton.perform();
      },

      toggleUploadButton: (0, _emberConcurrency.task)(function* () {
        Ember.set(this, "isAddButtonDisabled", true);
        yield (0, _emberConcurrency.timeout)(1000);
        Ember.set(this, "isAddButtonDisabled", false);
      }).drop()
    }
  });

  _exports.default = _default;
});