define("epcr-uis/pods/components/pcr-document/signatures/patient/component", ["exports", "epcr-uis/mixins/signer-data"], function (_exports, _signerData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_signerData.default, {
    classNames: ["ui", "grid"],
    pcrClassificator: Ember.inject.service(),
    isSignatureFormFilled: Ember.computed("medicalAgreement.{confirmationToReceiveMedicalHelp.code,confirmationToHospital.code,infoShareConfirmation.code,signatureType.code}", "medicalAgreement.signature.{signerName,signatureImage}", "infoShareAgreement.confirmation.code", "infoShareAgreement.shareWithPerson", function () {
      return this.isInfoShareSelected() && this.isPatientConfirmationToReceiveMedicalHelpSelected() && this.isPatientGoingToHospitalFieldSelected() && this.isFormSignedBySomeone(this.medicalAgreement);
    }),

    isPatientConfirmationToReceiveMedicalHelpSelected() {
      const patientMedHelp = Ember.get(this, "medicalAgreement.confirmationToReceiveMedicalHelp");
      return Ember.isPresent(patientMedHelp);
    },

    isPatientGoingToHospitalFieldSelected() {
      if (Ember.get(this, "pcr.transportationType.code") === "OTHER" || Ember.get(this, "pcr.deathStatement.deathTime") || Ember.get(this, "pcr.transportationType.code") === "HELP_TO_TEAM") return true;
      const patientToHospital = Ember.get(this, "medicalAgreement.confirmationToHospital");
      return Ember.isPresent(patientToHospital);
    },

    isInfoShareSelected() {
      const infoShareConfirmation = Ember.get(this, "infoShareAgreement.confirmation.code");
      const shareWithPerson = Ember.get(this, "infoShareAgreement.shareWithPerson");
      return infoShareConfirmation && shareWithPerson || infoShareConfirmation === false;
    },

    setSignature(signatureImage) {
      Ember.set(this, "medicalAgreement.signature.signatureImage", signatureImage);
      Ember.set(this, "infoShareAgreement.signature.signatureImage", signatureImage);
    },

    setSigningPerson(signingPerson) {
      Ember.set(this, "medicalAgreement.signatureType", signingPerson);
      Ember.set(this, "infoShareAgreement.signatureType", signingPerson);
    },

    setRepresentativeName(name) {
      Ember.set(this, "medicalAgreement.signature.signerName", name);
      Ember.set(this, "infoShareAgreement.signature.signerName", name);
    },

    actions: {
      onSignatureSubmit(signatureImage) {
        this.setSignature(signatureImage);
      },

      onSignatureClear() {
        this.setSignature(null);
      },

      onSigningPersonSelected(selectedItem) {
        this.setSigningPerson(selectedItem);
      },

      onSigningPersonRemove() {
        this.setSigningPerson(null);
      },

      onRepresentativeNameChange(input) {
        this.setRepresentativeName(input);
      }

    }
  });

  _exports.default = _default;
});