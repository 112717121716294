define("epcr-uis/pods/flashlight/service", ["exports", "ember-concurrency", "epcr-uis/helpers/window-wrapper", "epcr-uis/mixins/metrics-tracker"], function (_exports, _emberConcurrency, _windowWrapper, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_metricsTracker.default, {
    isEnabled: Ember.computed({
      get: () => (0, _windowWrapper.hasMethod)("window.control.isTorchEnabled"),
      set: (key, value) => value
    }),

    turnFlashlightOn() {
      (0, _windowWrapper.callMethod)("window.control.turnTorchOn");
      this.isFlashlightEnabled();
    },

    turnFlashlightOff() {
      (0, _windowWrapper.callMethod)("window.control.turnTorchOff");
      this.isFlashlightEnabled();
    },

    isOn: Ember.computed("isTurnedOn", function () {
      return this.isFlashlightEnabled();
    }),

    isFlashlightEnabled() {
      return Ember.set(this, "isTurnedOn", (0, _windowWrapper.callMethod)("window.control.isTorchEnabled"));
    },

    turnFlashlight() {
      this.trackEvent("Flashlight", `turning ${this.isFlashlightEnabled() ? "off" : "on"}`);
      this.isFlashlightEnabled() ? this.turnFlashlightOff() : this.turnFlashlightOn();
    },

    toggleFlashlight: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500);
      this.turnFlashlight();
    }).drop(),

    turnOffFlashlightIfOn() {
      this.isEnabled && this.isOn && this.turnFlashlightOff();
    }

  });

  _exports.default = _default;
});