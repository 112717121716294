define("epcr-uis/pods/components/save-summary/save-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pHXQajZ7",
    "block": "{\"symbols\":[\"@action\",\"@disabled\",\"@validationError\",\"@isLoading\",\"@buttonName\"],\"statements\":[[7,\"button\",true],[11,\"class\",[29,[\"ui \",[22,\"btnClass\"],\" button pcr-save-button\"]]],[11,\"onclick\",[23,1,[]]],[11,\"disabled\",[28,\"or\",[[23,4,[]],[23,3,[]],[23,2,[]]],null]],[8],[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"    \"],[5,\"ui-loader\",[],[[\"@isLoading\",\"@cssClass\"],[true,\"inline tiny\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[[22,\"btnIcon\"],\" icon\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[1,[23,5,[]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/save-summary/save-button/template.hbs"
    }
  });

  _exports.default = _default;
});