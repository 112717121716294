define("epcr-uis/pods/components/pcr-document/flowchart/medicament-input/edit/component", ["exports", "ember-concurrency", "epcr-uis/mixins/team-member-copy", "epcr-uis/helpers/get-medicament-mediums-by-code", "epcr-uis/pods/pcr-classificator/medicaments/get-medicaments-data", "epcr-uis/helpers/create-treatment", "epcr-uis/helpers/procedure-has-size", "epcr-uis/mixins/metrics-tracker", "epcr-uis/constants"], function (_exports, _emberConcurrency, _teamMemberCopy, _getMedicamentMediumsByCode, _getMedicamentsData, _createTreatment, _procedureHasSize, _metricsTracker, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const dataMedicamentCode = "data.medicament.code";

  var _default = Ember.Component.extend(_metricsTracker.default, _teamMemberCopy.default, {
    pcrClassificator: Ember.inject.service(),
    fastComments: Ember.inject.service("pcr-classificator/fast-comments"),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    flowchartService: Ember.inject.service("pcr/flowchart"),
    store: Ember.inject.service(),
    selectedRelatedProcedure: null,
    helpOptions: Ember.computed(dataMedicamentCode, function () {
      return this.fastComments.medicaments[Ember.get(this, dataMedicamentCode)];
    }),
    member: Ember.computed("data.member.externalId", {
      set(fieldName, member) {
        let properties = null;
        if (member && member.getProperties) properties = Ember.getProperties(member, "externalId", "code", "firstName", "lastName");
        return Ember.set(this.data, "member", member ? this.pcr.createFragment("pcr/team-activation/team/member", properties) : properties);
      },

      get() {
        return Ember.get(this.data, "member.externalId") ? Ember.get(this.data, "member") : null;
      }

    }),

    addProcedureIfSelected() {
      const {
        features,
        selectedRelatedProcedure
      } = this;

      if (Ember.get(features, _constants.FEAT_RELATED_PROCEDURES) && selectedRelatedProcedure) {
        const procedure = (0, _createTreatment.createProcedure)([selectedRelatedProcedure, this.store, this.data.time]);
        const keys = ["member", "conditionChange", "rejected"];
        (0, _procedureHasSize.procedureHasSize)([{
          procedure: selectedRelatedProcedure
        }]) && keys.push("size");
        keys.forEach(key => {
          const dataByKey = this.data[key];
          dataByKey && Ember.set(procedure, key, dataByKey.toJSON ? dataByKey.toJSON() : dataByKey);
        });
        Ember.set(procedure, "total", this.pcrClassificator.findElementByCode("totalProcedures", 1));
        this.flowcharts.procedureTreatment.pushObject(procedure);
        Ember.set(this, "selectedRelatedProcedure", null);
        this.trackEvent("Treatment", "Add medicament related procedure", procedure.procedure && procedure.procedure.name);
      }
    },

    onEditPopupClose: (0, _emberConcurrency.task)(function* () {
      yield Ember.set(this, "isRightPopupOpen", false);
      this.addProcedureIfSelected();
    }),
    useTypes: Ember.computed("data.useType.code", "data.medicament.medium.code", {
      get() {
        const code = Ember.get(this, "data.medicament.medium.code");
        const medium = this.mediums.findBy("code", code);
        return code && medium ? medium.useType : (0, _getMedicamentsData.getAllMedicamentUseTypes)(this.intl.t.bind(this));
      },

      set: (key, value) => value
    }),
    mediums: Ember.computed(dataMedicamentCode, function () {
      const medicamentCode = Ember.get(this, dataMedicamentCode);
      const mediumsByCode = (0, _getMedicamentMediumsByCode.getMedicamentMediumsByCode)(medicamentCode, this.pcrClassificator);
      return medicamentCode && mediumsByCode ? mediumsByCode.types : [];
    }),

    setMedicamentMediumAndCode(data, selectedItem) {
      if (selectedItem) {
        Ember.set(data.medicament, "code", selectedItem.code);
        Ember.set(data.medicament, "medium", selectedItem);
      }
    },

    setMedicamentUseType(data, value) {
      Ember.set(data, "useType", value);
    },

    selectMedicamentTypeIfOnlyHasOneOption(data) {
      const {
        useTypes
      } = this;
      useTypes && useTypes.length === 1 && this.setMedicamentUseType(data, useTypes[0]);
    },

    actions: {
      onMediumSelect(data, currentItem, selectedItem) {
        this.setMedicamentUseType(data, null);
        this.setMedicamentMediumAndCode(data, selectedItem);
        this.selectMedicamentTypeIfOnlyHasOneOption(data);
      },

      onChangedTimeDate() {
        this.trackEvent("Treatment", "Changed medicament time", this.router.currentRoute.name);
      }

    }
  });

  _exports.default = _default;
});