define("epcr-uis/helpers/window-wrapper", ["exports", "ember-window-mock", "raven", "epcr-uis/config/environment"], function (_exports, _emberWindowMock, _raven, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasMethod = _exports.callMethod = void 0;

  function _getContext(namespaces, context) {
    for (let i = 1; i < namespaces.length; i++) {
      const ns = namespaces[i];
      if (!context[ns]) break;
      context = context[ns];
    }

    return context;
  }

  const _extractContextAndMethodName = path => {
    if (path) {
      const namespaces = path.split(".");
      let context = namespaces[0] === "window" ? _emberWindowMock.default : null;

      if (context) {
        const methodName = namespaces.pop();
        context = _getContext(namespaces, context);
        if (context && methodName && typeof context[methodName] === "function") return {
          context,
          methodName
        };
      }
    }

    return null;
  };

  const callMethod = (path, ...args) => {
    const cam = _extractContextAndMethodName(path);

    if (cam) {
      const {
        context,
        methodName
      } = cam;
      return args[0] !== undefined ? context[methodName].apply(context, args) : context[methodName].apply(context);
    } else {
      const message = `Unknown window path = ${path}, argsLength = ${args.length}`;

      _raven.default.captureMessage(message);

      _environment.default.environment !== "test" && console.error(message); // eslint-disable-line

      return null;
    }
  };

  _exports.callMethod = callMethod;

  const hasMethod = path => _extractContextAndMethodName(path) !== null;

  _exports.hasMethod = hasMethod;
});