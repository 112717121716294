define("epcr-uis/pods/components/save-summary/component", ["exports", "epcr-uis/helpers/can-edit-pcr", "epcr-uis/mixins/metrics-tracker"], function (_exports, _canEditPcr, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const pcrSaveActionsLastError = "pcrService.pcrSaveActions.last.error";

  var _default = Ember.Component.extend(_metricsTracker.default, {
    errorTranslation: Ember.inject.service(),
    validation: Ember.inject.service(),
    pcrService: Ember.inject.service("pcr"),
    pcrStateService: Ember.inject.service("pcr-state"),
    android: Ember.inject.service(),
    simpleAuthFetch: Ember.inject.service(),
    lockedPcr: Ember.inject.service(),
    pcrRouteTracker: Ember.inject.service(),
    androidSimpleAuthFetch: Ember.inject.service("simple-auth-fetch/android"),
    classNames: ["pcr-save"],
    validationErrorMessage: {
      title: "message.pcrContainsErrors",
      description: "message.cantFinishOnlySave"
    },

    init() {
      this._super(...arguments);

      this.validatePcr(this.pcr);
    },

    saveSummaryError: Ember.computed("pcrStateService.pcrStateTask.last.error", pcrSaveActionsLastError, function () {
      const stateError = Ember.get(this.pcrStateService, "pcrStateTask.last.error");
      const saveError = Ember.get(this.pcrService, "pcrSaveActions.last.error");
      return saveError || stateError;
    }),
    canSavePcr: Ember.computed("pcrStateService.state", function () {
      return (0, _canEditPcr.canEditPcr)(this.pcrStateService.state);
    }),

    validatePcr(pcr) {
      const simpleAuthFetch = this.android.isAndroid() ? this.androidSimpleAuthFetch : this.simpleAuthFetch;
      return Ember.get(this, "validation.validatePcr").perform(pcr, simpleAuthFetch);
    },

    clearPcrSaveErrors() {
      Ember.get(this, pcrSaveActionsLastError) && Ember.set(this, pcrSaveActionsLastError, null);
      Ember.get(this, "pcrService.pcrSaveActions.last.isError") && Ember.set(this, "pcrService.pcrSaveActions.last.isError", null);
    },

    actions: {
      onStateReload(pcrId) {
        this.clearPcrSaveErrors();
        this.pcrStateService.pcrStateTask.perform(pcrId);
      },

      onSavePcr(pcr) {
        this.trackEvent("Save", "Save PCR", pcr.eventId);
        this.pcrService.savePcrTask.perform(pcr);
      },

      onVerifyPcr(pcr) {
        Ember.set(this, "isVerifyConfirmationOpen", false);
        this.trackEvent("Save", "Save PCR and finish", pcr.eventId, this.pcrRouteTracker.lastVisitedPcrList);
        this.pcrService.verifyPcr.perform(pcr);
      }

    }
  });

  _exports.default = _default;
});