define("epcr-uis/helpers/city-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cityFormatter = cityFormatter;
  _exports.default = void 0;

  const addComma = value => value ? ", " : "";

  function valueWithComma(format, value) {
    if (value) {
      format += addComma(format) + value;
    }

    return format;
  }

  function cityFormatter([address]) {
    let formattedCity = "";

    if (!address) {
      return formattedCity;
    }

    const city = Ember.get(address, "city");
    const eldership = Ember.get(address, "eldership");
    const municipality = Ember.get(address, "municipality");
    formattedCity = valueWithComma(formattedCity, city);
    formattedCity = valueWithComma(formattedCity, eldership);
    formattedCity = valueWithComma(formattedCity, municipality);
    return formattedCity;
  }

  var _default = Ember.Helper.helper(cityFormatter);

  _exports.default = _default;
});