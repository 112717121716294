define("epcr-uis/components/deferred-content/rejected-content", ["exports", "ember-deferred-content/components/deferred-content/rejected-content"], function (_exports, _rejectedContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _rejectedContent.default;
    }
  });
});