define("epcr-uis/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "actions": {
      "add": "Add",
      "addAttachment": "Add attachment",
      "attachments": "Attachments",
      "backToNews": "Back to news",
      "cancel": "Cancel",
      "change": "Change",
      "choosePainLocation": "Pain/injury location",
      "clear": "Clear",
      "close": "Close",
      "copy": "Copy",
      "delete": "Delete",
      "fill": "Fill",
      "fillTeam": "Fill team",
      "filter": "Filter",
      "importData": "Import data",
      "installUpdates": "Update",
      "leavePcr": "Leave form",
      "login": "Log In",
      "logout": "Logout",
      "map": "Map",
      "muteSound": "Mute",
      "no": "No",
      "ok": "Ok",
      "printEmptyPcrPdf": "Not filled form",
      "reload": "Reload",
      "remove": "Remove",
      "retry": "Retry",
      "return": "Return",
      "save": "Save",
      "saveAndExit": "Save and exit",
      "saveAndFinish": "Save and finish",
      "saveComment": "Save comment",
      "saveTeam": "Save team",
      "searchInWholeLithuania": "Search in whole Lithuania",
      "selectUnknown": "Unknown",
      "setLungsNormal": "Normal",
      "setReacts": "Reacts",
      "setSkinNormal": "Normal",
      "settings": "Settings",
      "showNews": "News",
      "support": "Support",
      "tryAgain": "Try again",
      "validate": "Validate",
      "view": "View",
      "yes": "Yes"
    },
    "activationProtocol": {
      "default": {
        "2": "Allergic reactions"
      }
    },
    "components": {
      "copySection": "Copy",
      "dateFormat": "YYYY-MM-DD",
      "enterSearchQuery": "Enter search query",
      "general": {
        "now": "Now",
        "years": "yr."
      },
      "letterFilter": {
        "all": "All"
      },
      "loading": "Loading",
      "medicaments": {
        "other": "Other",
        "unknown": "Unknown"
      },
      "notChosen": "Not chosen",
      "signature": {
        "actions": {
          "clear": "Clear",
          "sign": "Sign",
          "signNew": "Sign again"
        },
        "confirmDelete": "Yes",
        "confirmationToDelete": "Are you sure you want to clear signature?",
        "declineDelete": "No"
      },
      "warnings": {
        "emptyValue": "Empty value",
        "invalidDate": "Invalid date",
        "invalidNumber": "Invalid number",
        "invalidPhoneNumber": "Invalid phone number",
        "invalidTime": "Invalid time",
        "numberTooHigh": "Number is too high",
        "numberWithComma": "Invalid number, use dot instead comma"
      }
    },
    "config": {
      "brightness": "Screen brightness",
      "brightnessExplanation": "Adjust screen brightness",
      "flashlight": "Opportunity to use flashlight",
      "nightMode": "Night mode",
      "nightModeExplanation": "Toggle day / night modes",
      "print": "Printer settings",
      "rapidInputMode": "Automatic transition between fields",
      "rapidInputModeExplanation": "Entering assessment or transportation data fields will automatically move to the next field",
      "relatedProcedures": "Related procedure suggest in medicament",
      "relatedProceduresExplanation": "When adding medicament you can select related procedure",
      "soundOnClick": "Sound on click",
      "soundOnClickExplanation": "Play sound on screen touch",
      "timelines": "Timelines",
      "timelinesExplanation": "Display time ranges in treatment"
    },
    "errors": {
      "ABSENT_CALL_TIME": "Fill call time",
      "ABSENT_CATEGORY": "Choose category",
      "ABSENT_EVENT_ID": "Fill event id",
      "ABSENT_MISSION_ID": "Fill mission number",
      "ABSENT_PATIENT_ID": "Fill patient number",
      "ACCESS_DENIED": "Access to this card is restricted",
      "EMPTY_QUERY": "Enter search query",
      "FILE_TOO_LARGE": "File is too large",
      "FILE_TYPE_IS_NOT_SUPPORTED": "File type is unsupported. Only photos can be uploaded.",
      "INCORRECT_CREDENTIALS": "Incorrect username or password",
      "INSUFFICIENT_PERMISSIONS": "Access to ePCR is restricted",
      "INVALID_EVENT_ID": "Invalid event id",
      "NOT_FOUND": "Entry not found",
      "NO_INTERNET_CONNECTION": "No internet",
      "NO_RESULTS": "No results matches current search query",
      "NO_STREET_IN_COUNTY": "{county} does not have that street",
      "PATIENT_FIRST_NAME_REQUIRED": "Patient first name required",
      "PATIENT_LAST_NAME_REQUIRED": "Patient last name required",
      "PATIENT_WEIGHT_REQUIRED": "Missing patient weight",
      "PCR_DATA_LIMIT_EXCEEDED": "PCR file data limit is reached",
      "PCR_STATE_DOES_NOT_MATCH": "Failed to save, PCR was saved and closed by other user",
      "QUERY_IS_TOO_SHORT": "Please enter longer search query",
      "RESOURCE_ALREADY_EXISTS": "Pcr with this id already exists",
      "SEX_REQUIRED": "Missing patient sex",
      "TOO_MANY_RESULTS": "Too many results. Please specify search query",
      "UNSAVED_PCR": "Pcr has to be saved before exporting",
      "failedToLoadConfig": "Failed to load settings",
      "general": "Error has occurred",
      "loadingUnsuccessful": "Data loading was unsuccessful",
      "loginError": "Log in error",
      "null": "Error has occurred",
      "oldBrowser": "You are using an old version of browser. If you want this system to work correctly, update browser.",
      "patientAssessment": {
        "empty": "Patient assessment is empty"
      },
      "unableToLogin": "Unable to Log In"
    },
    "general": {
      "floatingPartSeperator": ".",
      "hours": "h",
      "kg": "kg",
      "lessThan": "less than",
      "minutes": "min",
      "months": "months",
      "moreThan": "more than",
      "seconds": "s",
      "timesPerMin": "time(s)/min",
      "units": "units",
      "weeks": "week(s)",
      "years": "years"
    },
    "headings": {
      "activePcrs": {
        "changeTeam": {
          "error": {
            "message": "You can change team only once. Please logout and login again, to choose another team.",
            "title": "Cannot change team."
          }
        },
        "chooseTeam": "Select a team",
        "createNewPcr": "Create new ePCR",
        "submit": "Select"
      },
      "attachment": {
        "description": "You can add photos to this PCR",
        "title": "PCR photos"
      },
      "comments": "Comments",
      "diagnosis": {
        "diagnosisTitle": "Diagnosis"
      },
      "event": {
        "additionalInfo": "Additional information",
        "changeTimeTo": "Change to",
        "comment": "Comment",
        "delayReasons": "Delay reasons",
        "pcr": "ePCR",
        "team": "Team",
        "times": "Times"
      },
      "filter": {
        "filterTitle": "Choose team",
        "search": "Search",
        "shiftDuration": "Shift duration",
        "team": "Team"
      },
      "flowchart": {
        "relatedProcedure": "Related procedure"
      },
      "forms": {
        "acuteCoronarySyndrome": "Acute coronary syndrome",
        "autoAccident": "Auto accident",
        "childbirth": "Childbirth",
        "clinicalDeath": "Clinical death",
        "deathStatement": "Death Statement",
        "eventDescription": "Event description",
        "informationTransfer": "Information transfer",
        "stroke": "Stroke",
        "trauma": "Trauma"
      },
      "listMenu": {
        "active": "Active",
        "forms": "PCRs",
        "header": "Selection",
        "unfinished": "Unfinished",
        "usedMedicaments": "Used medicaments",
        "verified": "Finished"
      },
      "login": {
        "password": "Password",
        "username": "Username"
      },
      "news": "News",
      "patientAssessments": {
        "afterTreatment": "After treatment",
        "atER": "At emergency room",
        "avpu": "AVPU",
        "beforeTreatment": "Before treatment",
        "breathing": "Breathing",
        "circulation": "Circulation",
        "consciousness": "Consciousness",
        "deleteConfirmation": "Delete confirmation",
        "ecg": "ECG",
        "eyes": "Eyes",
        "gcs": "GCS",
        "glucose": "Glucose",
        "pain": "Pain",
        "painLocation": "Pain/injury location",
        "secondaryDeleteTitle": "Are you sure you want to delete",
        "skin": "Skin",
        "stSegment": "ST segment",
        "temperature": "Temperature"
      },
      "patientDetails": {
        "absent": "Absent",
        "additionalInformation": "Additional information",
        "helpRejected": "Help rejected",
        "invalidPid": "Invalid national identity number",
        "known": "Known",
        "notFound": "Not found",
        "patientData": "Patient",
        "unknown": "Unknown"
      },
      "print": {
        "emptyForm": "Not filled form printing",
        "form": "ePCR form printing ({pcrId})"
      },
      "signatures": {
        "hospitalDoctor": "Receiving doctor at hospital",
        "narcoticDrugs": "Narcotic drugs giving verification",
        "patient": "Patient",
        "teamLeader": "Team leader"
      },
      "summary": {
        "diagnosis": "Diagnosis",
        "event": "Event",
        "forms": "Forms",
        "injuries": "Injuries",
        "patient": "Patient",
        "signatures": "Signatures",
        "title": "Summary",
        "transportation": "Transportation",
        "treatment": "Treatment",
        "unknown": "Unknown",
        "vitals": "Vitals"
      },
      "tools": {
        "apgarCalc": {
          "title": "APGAR calculator"
        },
        "doseCalc": {
          "selectWeight": "Select weight",
          "title": "Dose calculator"
        },
        "pediCalc": {
          "fillAge": "Select age",
          "title": "Pediatric calculator"
        },
        "title": "Tools"
      },
      "training": {
        "goToTraining": "Add training scenario",
        "scenarios": {
          "1": "Patient not found",
          "2": "Non-cooperative patient",
          "3": "Death statement",
          "4": "Transportation",
          "5": "Acute coronary syndrome",
          "6": "Stroke",
          "7": "Childbirth",
          "8": "Auto accident. Death",
          "9": "Accident"
        },
        "title": "Training mode. Choose scenario to try the system."
      },
      "trainingMode": "DEMONSTRATION MODE",
      "transportation": {
        "additionalInfo": "Additional information",
        "toHospital": "To hospital",
        "transfer": "Transfer",
        "transportationTitle": "Transportation",
        "transportedTo": "Patient transported to"
      },
      "usedMedicaments": {
        "chooseRange": "Choose shift duration",
        "submit": "Choose"
      },
      "warnings": {
        "unsavedPcr": "PCR is not saved. Are you sure you want to leave this page?"
      }
    },
    "message": {
      "abp": {
        "optimal": "Optimal",
        "title": "ABP ranges"
      },
      "addTreatment": "Add medicament or procedure",
      "cannotSavePcr": "PCR cannot be saved",
      "cantChangePcrLater": "You will not be able to edit PCR after saving and finishing filling. Are you sure you want to finish filling and pass it for review?",
      "cantFinishOnlySave": "You can only finish PCR after all errors have been fixed. Now you can only save it.",
      "chooseFieldsToFill": "Choose what to import",
      "currentState": "PCR state is",
      "endOfList": "End of list",
      "finishedPcrs": "You do not have any unfinished PCRs",
      "glucose": {
        "diabetes": "Diabetes",
        "norm": "Glucose ranges",
        "normal": "Normal"
      },
      "lastTimeUpdated": "Last update",
      "loading": "Loading",
      "newsArticleNotFound": "News article not found",
      "newsNotFound": "News not found",
      "noActivePcrs": "No active PCRs present",
      "noErrors": "No errors",
      "noNeedToFill": "No need to fill this part",
      "noPcrsFound": "No PCRs found",
      "noRecommendations": "No recommendations",
      "noTeamMembers": "There are no team members",
      "noUpdatesFound": "No Updates found",
      "noVerifiedPcrs": "No verified PCRs present",
      "normalBodyTemp": "Normal body temperature",
      "patient": {
        "ABSENT": "Absent patient",
        "NOT_FOUND": "Patient not found"
      },
      "pcrContainsErrors": "PCR contains errors",
      "pcrSaved": "PCR saved",
      "pcrsUpdated": "Last time PCR list updated",
      "photoRemovalConfirmation": "Are you sure you want to remove this photo?",
      "popularSelections": "Popular selections",
      "pulseRanges": "Pulse ranges",
      "recommendations": "Recommendations",
      "removalConfirmation": "Are you sure you want to remove this entry?",
      "respiratoryRateRanges": "Respiratory rate ranges",
      "savePcrBeforeClose": "Form has some unsaved changes. Save data before closing form.",
      "spO2": {
        "critical": "Critical",
        "low": "Low",
        "normal": "Normal",
        "title": "SpO₂ ranges"
      },
      "temperatureRanges": "Temperature ranges",
      "unReadNewsDescription": "You have {numUnreadNews} unread news.",
      "updateWarning": "When installing updates, You can not use the software. It takes up to 5 minutes.",
      "weightRanges": "Weight ranges"
    },
    "moment": {
      "relativeTime": {
        "M": "a month",
        "MM": "%d months",
        "d": "a day",
        "dd": "%d days",
        "future": "in %s",
        "h": "an hour",
        "hh": "%d hours",
        "m": "a minute",
        "mm": "%d minutes",
        "past": "%s ago",
        "s": "a few seconds",
        "ss": "%d seconds",
        "y": "a year",
        "yy": "%d years"
      }
    },
    "navigation": {
      "event": "Event",
      "flowchart": "Treatment",
      "forms": "Forms",
      "narrative": "Diagnosis",
      "patient": "Patient",
      "signatures": "Signatures",
      "summary": {
        "errors": "Errors",
        "print": "Printing",
        "save": "Saving"
      },
      "top": {
        "patient": "Patient",
        "unknown": "Unknown"
      },
      "transportation": "Transport",
      "unknown": "Unknown",
      "vitals": "Condition"
    },
    "notifications": {
      "description": {
        "notTransported": "Choose other transportation type if you want to enter hospital"
      },
      "pcrSavedSuccessfully": "Pcr data saved successfully!",
      "title": {
        "notTransported": "Patient was not transported"
      }
    },
    "options": {
      "counties": {
        "alytus": "Alytus county",
        "kaunas": "Kaunas county",
        "klaipeda": "Klaipėda county",
        "marijampole": "Marijampolė county",
        "panevezys": "Panevėžys county",
        "siauliai": "Šiauliai county",
        "taurage": "Tauragė county",
        "telsiai": "Telšiai county",
        "utena": "Utena county",
        "vilnius": "Vilnius county"
      },
      "deathSigns": {
        "bodyFrozen": "Body frozen",
        "deadlyInjuries": "Deadly injuries",
        "deathMarks": "Death marks",
        "decomposition": "Decomposition"
      },
      "delayReasons": {
        "brokenVehicle": "Broken vehicle",
        "crowd": "Crowd",
        "dangerousSiteArea": "Dangerous site area",
        "difficultAccessToBuilding": "Difficult access to building",
        "difficultAccessToSite": "Difficult access to site",
        "dispatcher": "Dispatcher",
        "distanceToPatient": "Distance to patient",
        "elevator": "Elevator",
        "helpToTeam": "Help to team",
        "lockedDoor": "Locked door",
        "rescuing": "Rescuing",
        "traffic": "Traffic",
        "waitingForEscort": "Waiting for escort",
        "weather": "Weather",
        "wrongAddress": "Wrong address"
      },
      "diagnosis": {
        "disorderType": {
          "accident": "Accident",
          "acuteIllness": "Acute illness"
        },
        "helpProtocol": {
          "abdominal": "Abdominal Pain / Problems",
          "aicd": "Heart Problems / AICD",
          "allergies": "Allergies (Reactions) / Envenomations (Stings / Bites)",
          "animal": "Animal Bites / Attacks",
          "assault": "Assault / Sexual Assault",
          "back": "Back Pain (Non-Traumatic or Non-Recent Trauma)",
          "bleeding": "Hemorrhage / Laceration",
          "breathing": "Breathing Problems",
          "burn": "Burns (Scalds) / Explosion (Blast)",
          "chest": "Chest Pain (Non-Traumatic)",
          "choking": "Choking",
          "death": "Cardiac or Respiratory Arrest / Death",
          "diabetic": "Diabetic Problems",
          "drowning": "Drowning (near) / Diving / SCUBA Accident",
          "eye": "Eye Problems / Injuries",
          "fall": "Falls",
          "fluPandemic": "Flu-Like Symptoms (Possible H1N1)",
          "headache": "Headache",
          "heatOrCold": "Heat / Cold Exposure",
          "inhalation": "Carbon Monoxide / Inhalation / HAZMAT / CBRN",
          "lightning": "Electrocution / Lightning",
          "overdose": "Overdose / Poisoning (Ingestion)",
          "penetrating": "Stab / Gunshot / Penetrating Trauma",
          "pregnancy": "Pregnancy / Childbirth / Miscarriage",
          "psychiatric": "Psychiatric / Abnormal Behavior / Suicide Attempt",
          "seizures": "Convulsions / Seizures",
          "shortAbdominal": "Abdominal Pain / Problems",
          "shortAicd": "Heart Problems / AICD",
          "shortAllergies": "Allergies (Reactions) / Envenomations (Stings / Bites)",
          "shortAnimal": "Animal Bites / Attacks",
          "shortAssault": "Assault / Sexual Assault",
          "shortBack": "Back Pain (Non-Traumatic or Non-Recent Trauma)",
          "shortBleeding": "Hemorrhage / Laceration",
          "shortBreathing": "Breathing Problems",
          "shortBurn": "Burns (Scalds) / Explosion (Blast)",
          "shortChest": "Chest Pain (Non-Traumatic)",
          "shortChoking": "Choking",
          "shortDeath": "Cardiac or Respiratory Arrest / Death",
          "shortDiabetic": "Diabetic Problems",
          "shortDrowning": "Drowning (near) / Diving / SCUBA Accident",
          "shortEye": "Eye Problems / Injuries",
          "shortFall": "Falls",
          "shortFluPandemic": "Flu-Like Symptoms (Possible H1N1)",
          "shortHeadache": "Headache",
          "shortHeatOrCold": "Heat / Cold Exposure",
          "shortInhalation": "Carbon Monoxide / Inhalation / HAZMAT / CBRN",
          "shortLightning": "Electrocution / Lightning",
          "shortOverdose": "Overdose / Poisoning (Ingestion)",
          "shortPenetrating": "Stab / Gunshot / Penetrating Trauma",
          "shortPregnancy": "Pregnancy / Childbirth / Miscarriage",
          "shortPsychiatric": "Psychiatric / Abnormal Behavior / Suicide Attempt",
          "shortSeizures": "Convulsions / Seizures",
          "shortSick": "Sick Person (Specific Diagnosis)",
          "shortStroke": "Stroke (CVA) / Transient Ischemic Attack (TIA)",
          "shortTransfer": "Transfer / Interfacility / Palliative Care",
          "shortTransportation": "Traffic / Transportation Incidents",
          "shortTrapped": "Inaccessible Incident / Other Entrapments (Non-Vehicle)",
          "shortTraumatic": "Traumatic Injuries (Specific)",
          "shortUnconscious": "Unconscious / Fainting (Near)",
          "shortUnknown": "Unknown Problem (Man Down)",
          "sick": "Sick Person (Specific Diagnosis)",
          "stroke": "Stroke (CVA) / Transient Ischemic Attack (TIA)",
          "transfer": "Transfer / Interfacility / Palliative Care",
          "transportation": "Traffic / Transportation Incidents",
          "trapped": "Inaccessible Incident / Other Entrapments (Non-Vehicle)",
          "traumatic": "Traumatic Injuries (Specific)",
          "unconscious": "Unconscious / Fainting (Near)",
          "unknown": "Unknown Problem (Man Down)"
        }
      },
      "event": {
        "cancelReason": {
          "declinedByDispatcher": "Answered (not departed)",
          "missionAborted": "Call canceled",
          "patientNotFound": "Patient not found"
        },
        "category": {
          "1": "1 - Urgent",
          "2": "2 - Delayed",
          "3": "3 - Redirected",
          "4": "4 - Advised"
        },
        "deliveryType": {
          "direct": "Direct",
          "viaPhone": "Via phone",
          "viaRadio": "Via radio"
        }
      },
      "forms": {
        "accident": {
          "airbags": {
            "driver": "Driver door",
            "other": "Other",
            "passenger": "Passenger",
            "passengerDoor": "Passenger door",
            "title": "Airbags deployed",
            "wheel": "Steering wheel"
          },
          "seatBelts": {
            "shoulder": "Over shoulder",
            "stomach": "Stomach area",
            "title": "Seat belts"
          },
          "sittingPlace": {
            "back": "Back seat",
            "driver": "Driver seat",
            "passenger": "Passenger seat",
            "title": "Sitting place"
          }
        },
        "childbirth": {
          "apgar1": "APGAR initial",
          "apgar5": "APGAR 5 minutes",
          "baby": "Baby born",
          "birthTime": "Time of newborn arrival",
          "bleeding": "Heavy bleeding",
          "contractions": "Contractios repeating every",
          "duration": "Pregnancy duration",
          "eclampsia": "Eclampsia",
          "pathology": "Postpartum pathology",
          "placenta": "Placenta born",
          "tones": "Fetus heart tones",
          "watersBroken": "Fetus waters broke"
        },
        "clinicalDeath": {
          "aedUsed": "AED used",
          "aedUsedBy": "AED used by",
          "ambulance": "Ambulance",
          "bloodFlowRestored": "Spontaneous blood flow restored by",
          "cprPerformed": "CPR performed",
          "cprPerformedBy": "CPR performed by",
          "family": "Family",
          "fireService": "Fire service",
          "medPersonnel": "Med. personnel",
          "minsSinceCprEnd": "Minutes since CPR end",
          "minsSinceCprStart": "Minutes since CPR start",
          "minsSinceDeath": "Minutes since sudden death",
          "minsSinceInitAed": "Minutes since initial AED impulse",
          "no": "No",
          "officer": "Police officer",
          "other": "Other",
          "time": "Time of clinical death",
          "witness": "Witness",
          "witnesses": "Witnesses",
          "yes": "Yes"
        },
        "coronary": {
          "contraindication": {
            "1": "1. Ischemic stroke during last 6 months",
            "10": "10. Resistant dABP > 110 mmHg",
            "11": "11. Protracted or traumatic resuscitation",
            "12": "12. Pregnancy or week after labor",
            "13": "13. Oral anticoagulants therapy",
            "14": "14. Transitory ischemic attack during last 6 months",
            "15": "15. Infectious endocarditis",
            "16": "16. Active peptic ulcer",
            "17": "17. Advanced liver disease",
            "18": "18. No trombolysis contraindications",
            "19": "19. Unknown (anamensis collection imposable due to condition of patient)",
            "2": "2. Haemorrhagic or unknown etiology stroke",
            "3": "3. Massive trauma/operation/head injury during last 3 weeks",
            "4": "4. Known clotting disorder",
            "5": "5. CNS/tumors/atrioventricular malformation",
            "6": "6. Gastrointestinal bleeding during last month",
            "7": "7. Suspected aortic dissection",
            "8": "8. Not interrupted by pressure possible bleeding site after puncture during last day (ex. liver biopsy, lumbar puncture)",
            "9": "9. Resistant sABP > 180 mmHg",
            "title": "Contraindication"
          },
          "exactTime": "Exact time pain started",
          "lbbb": "LBBB",
          "st": "Raised ST segment",
          "time": "Time pain started"
        },
        "deathStatement": {
          "signs": "Clear signs of death",
          "time": "Time of death"
        },
        "erNotified": "ER notified",
        "informationTransfer": {
          "additional": "Additional information",
          "other": "To other service",
          "phc": "To PHC",
          "police": "To police"
        },
        "stroke": {
          "armWeakness": "Arm(s) weakness",
          "contraindication": {
            "1": "1. Symptoms start time until thrombolysis exceeds 4.5 hour",
            "2": "2. Before stroke patient has been nursed",
            "3": "3. Digestive tract bleeding during last 3 months",
            "4": "4. Urinary tract bleeding during last 3 months",
            "5": "5. High volume chirurgical procedure during last 3 months",
            "6": "6. Intracerebral bruise or heavy head trauma during last 3 months",
            "7": "7. Widespread onkological process with increased bleed risk",
            "8": "8. Liver disease with function failure (HB/HC)",
            "9": "9. Pregnancy or labor during last 10 days",
            "title": "Contraindication"
          },
          "exactTime": "Exact time of symptoms",
          "faceParalysis": "Face paralysis",
          "speechDisorder": "Speech disorder",
          "time": "Time"
        },
        "trauma": {
          "abcd": {
            "bpLt90": "Systolic ABP < 90 mmHg",
            "breathingFreqGt30OrLt8": "Breathing frequency > 30 or < 8 times/min",
            "gcsLt9": "Coma according to GCS score ≤ 90",
            "heartRateGt120": "Heart rate > 120 times/min",
            "spo2Lt90": "SpO₂ < 90% while oxygenated",
            "title": "ABCD evaluation"
          },
          "circumstance": {
            "deadPplInCar": "Dead people in same car",
            "explosionOrFiring": "Explosion or firing",
            "fallOrDiving": "Falling from higher than 3 m or diving",
            "pedestrianOrDriver": "Suffered as pedestrian, cyclist, motorcyclist, cart rider",
            "rescuersAssistance": "Rescuers assistance needed when freeing victim",
            "title": "Trauma circumstances"
          },
          "injury": {
            "amputation": "Amputation above than hand or foot",
            "burn": "Respiratory tract or body burn > 18%",
            "chest": "Unstable chest",
            "fracture": "At least 2 long bones fracture",
            "neurological": "Obvious neurological symptomatology",
            "pelvis": "Unstable pelvis",
            "puncture": "Neck, check or waist puncture",
            "title": "Injuries"
          },
          "safetyMeasures": {
            "clothing": "Safety clothing",
            "equipment": "Safety equipment",
            "eye": "Eye equipment",
            "helmet": "Helmet",
            "title": "Safety equipment"
          }
        }
      },
      "knownDiseases": {
        "allergy": "Allergy",
        "asthma": "Asthma",
        "ca": "Cardiac",
        "copd": "COPD",
        "diabetes": "Diabetes",
        "hypertension": "Hypertension",
        "ihd": "Ischemic heart disease",
        "other": "Other",
        "rhythmDisorder": "Rhythm disorder",
        "tbc": "Tuberculosis"
      },
      "patientAssessments": {
        "avpu": {
          "a": "Vigilant",
          "p": "Reacts to pain",
          "u": "Does not react",
          "v": "Reacts to voice"
        },
        "breathing": {
          "irregular": "Irregular",
          "normal": "Normal",
          "shortness": "Labored",
          "superficial": "Shallow"
        },
        "ecg": {
          "rhythm": {
            "artifact": "Artifact",
            "asystole": "Asystole",
            "atrialFibrillation": "Atrial fibrillation",
            "avBlock": "AV block",
            "emd": "Electromechanical dissociation (EMD)",
            "nsr": "NSR",
            "pacemakerFalse": "Paced rhythm, failure to capture",
            "pacemakerTrue": "Paced rhythm with capture",
            "pvc": "PVC Couplets",
            "sinusBradycardia": "Sinus bradycardia",
            "sinusDysrhythmia": "Sinus dysrhythmia",
            "sinusTachycardia": "Sinus tachycardia",
            "svt": "SVT",
            "vFib": "V Fib",
            "ventricularTachycardia": "Ventricular tachycardia"
          }
        },
        "eyesCondition": {
          "dilated": "Dilated",
          "doesNotRespond": "Unreactive",
          "inert": "Sluggish",
          "responds": "Reacts",
          "shriveled": "Constricted"
        },
        "gcs": {
          "eye": {
            "none": "None",
            "spontaneous": "Spontaneous",
            "toPain": "To pain",
            "toVoice": "To voice"
          },
          "motor": {
            "extensionPain": "Extension (pain)",
            "flexionPain": "Flexion (pain)",
            "localizesPain": "Localizes pain",
            "none": "None",
            "obeysCommands": "Obeys commands",
            "withdrawPain": "Withdraw (pain)"
          },
          "verbal": {
            "confused": "Confused",
            "inappropriate": "Inappropriate words",
            "incomprehensible": "Incomprehensible words",
            "none": "None",
            "oriented": "Oriented"
          }
        },
        "injury": {
          "abdomen": "Abdomen",
          "back": "Back",
          "chest": "Chest",
          "face": "Face",
          "head": "Head",
          "leftArm": "Left arm",
          "leftLeg": "Left leg",
          "neck": "Neck",
          "pelvis": "Pelvis",
          "rightArm": "Right arm",
          "rightLeg": "Right leg"
        },
        "lung": {
          "bronchial": "Bronchial",
          "dry": "Dry",
          "impaired": "Impaired",
          "normal": "Clear",
          "unhearable": "Unhearable",
          "wet": "Wet"
        },
        "meningealSignals": {
          "no": "No",
          "yes": "Yes"
        },
        "radialArteryPulse": {
          "absent": "Absent",
          "present": "Present"
        },
        "skinColor": {
          "cyanotic": "Cyanotic",
          "jaundice": "Jaundice",
          "normal": "Normal",
          "pale": "Pale",
          "redness": "Flush"
        },
        "skinCond": {
          "clammy": "Moist",
          "dry": "Dry",
          "normal": "Normal"
        },
        "skinTemp": {
          "cold": "Cold",
          "cool": "Cool",
          "hot": "Hot",
          "normal": "Normal"
        },
        "stSegment": {
          "drop": "Drop",
          "normal": "Normal(isoline)",
          "rise": "Rise"
        },
        "vitals": {
          "pain": {
            "barelyIgnorable": "Can not be ignored for 30 minutes",
            "distraction": "Annoying enough to be a distraction",
            "hard2concentrate": "Makes it difficult to concentrate",
            "ignorable": "Can be ignored, if very involved, but still distracting",
            "limitedActivity": "Physical activity severely limited",
            "minor": "Minor annoyance",
            "painless": "Pain free",
            "speechless": "Unable to speak, moans or crying out",
            "unconscious": "Unconscious, pain makes you pass out",
            "unignorable": "Can not be ignored for any length of time",
            "veryMinor": "Very minor annoyance"
          }
        }
      },
      "patientDetails": {
        "ageUnit": {
          "days": "Days",
          "daysShort": "D",
          "months": "Months",
          "monthsShort": "M",
          "newborn": "Newborn",
          "years": "Years",
          "yearsShort": "Y"
        },
        "sex": {
          "female": "Female",
          "male": "Male"
        }
      },
      "positions": {
        "doctor": "Doctor",
        "nurse": "Nurse",
        "paramedic": "Paramedic"
      },
      "signatures": {
        "confirmation": {
          "no": "Disagree",
          "yes": "Agree"
        },
        "signatureTypes": {
          "byPatient": "Patient",
          "refusesToSign": "Patient refuses to sign",
          "representative": "Representative",
          "unableToSign": "Patient is unable to sign"
        }
      },
      "teamTypes": {
        "als": "ALS",
        "bls": "BLS"
      },
      "transportation": {
        "additionalInfo": {
          "alvApplied": "ALV was applied to patient at home or transported from PHC facility to home or from home to PHC facility to continue investigate and (or) treat",
          "alvAppliedShort": "Transported after ALV applied",
          "eventCharged": "Event charged"
        },
        "from": {
          "betweenHospitals": "Between hospitals",
          "fromHospital2residence": "From hospital to residence",
          "hospice": "From hospice",
          "incidentLocation": "From incident location",
          "other": "Other",
          "phc": "From phc facility",
          "residence": "From residence"
        },
        "hospitalSelectionCriteria": {
          "nearest": "Nearest hospital",
          "patientOrFamilyPreference": "Patient or family preference",
          "specialized": "Specialized hospital"
        },
        "neighbouringCountry": {
          "border2phc": "From LT border to PHC",
          "phc2border": "From PHC to LT border"
        },
        "patient": "{count} patien{count, plural, =1 {t} other {ts}}",
        "patientPosition": {
          "fixedOnBack": "On back (fixed)",
          "fixedOnLeftSide": "On left side (fixed)",
          "fixedSemiSitting": "Partially sitting (fixed)",
          "fixedSitting": "Sitting (fixed)",
          "onBack": "On back",
          "onLeftSide": "On left side",
          "semiSitting": "Partially sitting",
          "sitting": "Sitting"
        },
        "position": {
          "fixed": "Fixed"
        },
        "toVehicle": {
          "alreadyInSite": "Was already near ambulance",
          "carried": "Carried",
          "himself": "Came by himself",
          "inChair": "In chair",
          "litter": "In litter",
          "lying": "Lying down",
          "stretchers": "In stretchers"
        },
        "type": {
          "dead": "No, patient is dead",
          "help": "No, help to another team",
          "other": "No, other",
          "refused": "No, refused",
          "transferred": "No, transferred to another team",
          "withSirens": "With sirens",
          "withoutSirens": "Without sirens"
        }
      },
      "treatment": {
        "applyTimeEditToOther": "Edit subsequent",
        "condition": {
          "deteriorated": "Deteriorated",
          "improved": "Improved",
          "refused": "Refused",
          "unchanged": "Unchanged"
        },
        "inventory": {
          "disposable": "Disposable",
          "gloves": "Gloves",
          "latex": "Latex",
          "scissors": "Scissors",
          "title": "Consumed inventory"
        },
        "medicamentTypes": {
          "anhydrous": "Anhydrous",
          "candles": "suppository",
          "compressedInhalationSuspension": "compressed inhalation suspension",
          "concentrateForSolutionInfusion": "concentrate for infusion solution",
          "inDose": "dose",
          "injectionEmulsion": "injection emulsion",
          "internationalUnitsShort": "IU",
          "monohydrate": "Monohydrate",
          "other": "Other",
          "pills": "pills",
          "powderAndSolventForSolutionInjection": "powder or solvent for injection solution",
          "solutionInfusion": "infusion solution",
          "solutionInjection": "injection solution",
          "solutionInjectionInfusion": "injection/infusion solution",
          "underTonguePills": "sublingual pills"
        },
        "medicaments": {
          "1": "Medicament1",
          "10": "Medicament10",
          "11": "Medicament11",
          "12": "Medicament12",
          "13": "Medicament13",
          "14": "Medicament14",
          "15": "Medicament15",
          "16": "Medicament16",
          "17": "Medicament17",
          "2": "Medicament2",
          "3": "Medicament3",
          "4": "Medicament4",
          "5": "Medicament5",
          "6": "Medicament6",
          "7": "Medicament7",
          "8": "Medicament8",
          "9": "Medicament9",
          "adenocard": "Adenocard",
          "albuterol": "Albuterol",
          "amiodarone": "Amiodarone",
          "aspirin": "Aspirin",
          "atropine": "Atropine",
          "dexamethasone": "Dexamethasone",
          "dextrose": "Dextrose",
          "diazepam": "Diazepam",
          "dopamine": "Dopamine",
          "epinephrine": "Epinephrine",
          "etomidate": "Etomidate",
          "furosemide": "Furosemide",
          "glucagon": "Glucagon",
          "lidocaine": "Lidocaine",
          "magSulfate": "Mag. sulfate",
          "methylprednisone": "Methylprednisone",
          "midazolam": "Midazolam",
          "morphine": "Morphine",
          "narcan": "Narcan",
          "nitroglycerin": "Nitroglycerin",
          "other": "Other",
          "oxytocin": "Oxytocin",
          "paracetamol": "Paracetamol",
          "restricted": {
            "confirmModalMessage": "The unknown (Other) medicament is only given in exceptional cases. Is the medicament actually not found on the list?",
            "confirmModalTitle": "Are you sure you want to create an unknown (Other) medicament?"
          },
          "title": "Medicaments"
        },
        "procedures": {
          "1": "Procedure1",
          "10": "Procedure10",
          "11": "Combitube",
          "12": "Procedure12",
          "13": "Procedure13",
          "14": "Procedure14",
          "15": "Procedure15",
          "16": "Procedure16",
          "17": "Procedure17",
          "18": "Procedure18",
          "19": "Procedure19",
          "2": "Procedure2",
          "20": "Procedure20",
          "21": "Procedure21",
          "22": "Procedure22",
          "23": "Procedure23",
          "24": "Procedure24",
          "25": "Procedure25",
          "26": "Procedure26",
          "27": "Procedure27",
          "28": "Procedure28",
          "29": "Procedure29",
          "3": "Defibrillation",
          "30": "Procedure30",
          "31": "Procedure31",
          "32": "Procedure32",
          "33": "Procedure33",
          "34": "Procedure34",
          "35": "Procedure35",
          "36": "Procedure36",
          "37": "Procedure37",
          "38": "Procedure38",
          "39": "Procedure39",
          "4": "External pacing",
          "40": "Procedure40",
          "41": "Procedure41",
          "42": "Procedure42",
          "5": "Synchronized cardioversion",
          "6": "Procedure6",
          "7": "Procedure7",
          "8": "Procedure8",
          "9": "Procedure9",
          "all": "All",
          "breathing": "Breathing",
          "infuzotherapy": "Infuzotherapy",
          "injury": "Injury",
          "newborn": "Newborn",
          "other": "Other",
          "resuscitation": "Resuscitation",
          "title": "Procedures"
        },
        "totalDoses": "{count} dos{count, plural, =1 {e} other {es}}",
        "totalProcedures": "{count} procedur{count, plural, =1 {e} other {es}}",
        "useType": {
          "automaticPump": "automatic pump",
          "currentNozzle": "Current nozzle",
          "dropsToVein": "Drops to vein",
          "externally": "Externally",
          "fastInjection": "fast injection",
          "impactDose": "impact dose",
          "intraboneInjection": "Intrabone injection",
          "slowInjection": "slow injection",
          "throughMouth": "Orally",
          "throughNose": "Through nose",
          "throughRectum": "Through rectum",
          "toEndotrachealTube": "To endotracheal tube",
          "toMuscles": "To muscles",
          "toVein": "To vein",
          "underSkin": "Under skin",
          "underTongue": "Under tongue"
        }
      }
    },
    "pcr": {
      "callInformation": {
        "callTakerCode": "Call taker code",
        "eventSite": {
          "address": "Address",
          "fireDepInformed": "Fire department informed",
          "policeInformed": "Police informed"
        },
        "phoneNumber": "Phone number"
      },
      "category": "Category",
      "delays": {
        "toEventSite": "To event site",
        "toHospital": "To hospital",
        "toPatient": "To patient"
      },
      "diagnosis": {
        "helpProtocol": "Help protocol",
        "icd10Code": "ICD 10 code",
        "other": "Other",
        "preliminaryDiagnosis": "Preliminary diagnosis"
      },
      "eventCancelReason": "Event cancellation reason",
      "eventId": "Event id",
      "injuryLocation": {
        "amputation": "Amputation",
        "bleeding": "Bleeding",
        "burn": "Burn",
        "dislocation": "Dislocation",
        "fracture": "Fracture",
        "pain": "Pain",
        "paralysis": "Paralysis/paresis",
        "softTissue": "Soft tissue injury",
        "swelling": "Swelling"
      },
      "metadata": {
        "owner": "Pcr owner (creator)"
      },
      "missionId": "Mission no.",
      "patientAssessments": {
        "auscultationLeft": "Auscultation left",
        "auscultationRight": "Auscultation right",
        "centralPulse": "Central pulse",
        "color": "Color",
        "condition": "Condition",
        "crtLte2Sec": "Capillary refill time",
        "degrees": "Degrees",
        "diabetes": "Diabetes",
        "diastolicBP": "Diastolic BP",
        "eyeResponse": "Eye response",
        "glucose": "Glucose level",
        "heartRhythm": "Heart rhythm",
        "left": "Left",
        "meningealSymptoms": "Meningeal symptoms",
        "motorResponse": "Motor response",
        "pain": "Pain",
        "pulse": "Pulse",
        "quality": "Quality",
        "radialPulse": "Radial a. pulse",
        "rash": "Rash",
        "respiratoryRate": "Respiratory rate",
        "right": "Right",
        "stSegment": "ST segment",
        "systolicBP": "Systolic BP",
        "systolicDiastolicBP": "Systolic / Diastolic BP",
        "temperature": "Temperature",
        "time": "Time",
        "verbalResponse": "Verbal response"
      },
      "patientDetails": {
        "additionalInfo": "Additional information",
        "address": "Patient address",
        "addressMatchesEventSite": "Address matches event site",
        "age": "Age",
        "allergy": "Allergy",
        "birthDate": "Date of birth",
        "city": "City",
        "county": "County",
        "familyDoctor": "Family doctor",
        "firstName": "First name",
        "flatNumber": "Apartment number",
        "helpRejected": "Help rejected",
        "homeless": "Homeless",
        "houseNumber": "House number",
        "inaccurateAddress": "Custom address",
        "inaccurateStreet": "Fill custom street",
        "knownDiseases": "Known diseases",
        "knownDiseasesAllergy": "Known allergies",
        "knownDiseasesOther": "Known other diseases",
        "lastName": "Last name",
        "notWalking": "Not walking",
        "other": "Other",
        "phcFacility": "PHC facility",
        "pid": "National identity number",
        "specialCircumstances": "Special circumstances",
        "street": "Street",
        "units": "Units",
        "usedMedicaments": "Used medicaments",
        "weight": "Weight"
      },
      "patientId": "Patient no.",
      "signatures": {
        "agreementToSharePersonalData": "Information about mine (represented person) health condition may be shared",
        "agreementToSharePersonalDataExtra": "Patient's representative - a representative by law (parents, adoptive parents, carers) or a representative on request. The representative is chosen by the patient according to the order",
        "agreementToSharePersonalData_SHORT": "Information may be shared",
        "confirmationToHospital": "Agreement to go to hospital",
        "confirmationToHospitalExtra": "In my understandable form, I am introduced to the rights, duties and consequences of my (represented person) by refusing to offer the assistance. For me (my representative) the proposed research, treatment, procedures and assistance provided",
        "medicalHelpAgreement": "Introduction to rights and duties",
        "medicalHelpAgreement_SHORT": "Introduction to rights",
        "name": "Name",
        "patient": "Patient",
        "personCredentials": "Person's name",
        "refused": "Refused to sign",
        "representativeCredentials": "Representative's name",
        "signer": "Signer",
        "verificationPersonCredentials": "Verificate by"
      },
      "state": {
        "accounted": "Accounted",
        "approved": "Approved",
        "archived": "Archived",
        "closed": "Closed",
        "filled": "Filled",
        "imported": "Imported",
        "restored": "Restored",
        "scanned": "Scanned",
        "verified": "Verified"
      },
      "teamActivation": {
        "activationProtocol": "Activation protocol",
        "deliveryType": "Delivery type",
        "dispatcherCode": "Dispatcher code",
        "signalsToSite": "Signals to site",
        "team": {
          "clusterTeam": "Cluster",
          "members": {
            "0": "Team leader",
            "1": "Driver",
            "2": "Member #3",
            "3": "Member #4"
          },
          "teamId": "Team code",
          "teamType": "Team type"
        }
      },
      "times": {
        "arrivedTime": "On scene",
        "arrivedTimeTitle": "On scene time",
        "assignTime": "Assignment",
        "atHospitalTime": "At destination",
        "availableTime": "Available",
        "callTime": "Call",
        "notPresent": "None",
        "onRouteTime": "En route",
        "onRouteTimeTitle": "En route time",
        "registrationTime": "Register",
        "returnedTime": "Return",
        "timeChangeRequest": "Time change request",
        "transportationTime": "To destination"
      },
      "transportation": {
        "fromLocation": "From location",
        "hospital": "Hospital",
        "hospitalSelectionCriteria": "Hospital selection",
        "neighbouringCountry": "Neighbouring country",
        "notTransported": "Not transported",
        "notTransportedReason": "Not transported reason",
        "patientCount": "Patient count",
        "patientPosition": "Transport position",
        "patientToVehicle": "Moved to vehicle",
        "teamNumber": "Team number",
        "totalDistance": "Total km",
        "transportationLocation": "Transportation location",
        "transported": "Transported",
        "transportedFrom": "Transported from"
      },
      "treatment": {
        "amount": "Amount",
        "comments": "Comments",
        "condition": "Condition",
        "measure": "Measurement",
        "member": "Member",
        "size": "Size",
        "time": "Time",
        "type": "Type",
        "useType": "Use type",
        "used": "Total used"
      }
    },
    "progress": {
      "error": "Error",
      "idle": "Waiting",
      "retry": "Trying upload again",
      "running": "Uploading"
    },
    "recommendation": {
      "airbagVentilation": "Slow breathing rate",
      "allergicReaction": "Allergic reaction",
      "anaphylacticReaction": "Anaphylactic reaction",
      "glucose": "Low glucose level",
      "glycerol": "Chest pain",
      "heartAttack": "Heart attack",
      "hypotension": "Low blood pressure",
      "painReliever": "Pain",
      "polytrauma": "Polytrauma",
      "resuscitationAsystole": "Resuscitation asystole",
      "resuscitationVentricularFibrillation": "Resuscitation ventricular fibrillation",
      "softTissue": "Soft tissue injury",
      "spO2": "Low SpO₂",
      "stroke": "Stroke",
      "swelling": "Swelling",
      "thinBlood": "Blood thinning"
    },
    "summaryInfo": {
      "comment": "Comment",
      "errors": "Error(s)",
      "footer": "Last time PCR saved",
      "print": "Print",
      "printPreview": "Preview",
      "warnings": "Warning(s)"
    },
    "support": {
      "message": {
        "callUs": "Call us",
        "helpCenter": "IT help",
        "weWantToHearFromYou": "We want to hear you!"
      },
      "questions": {
        "needHelp": "Need help?"
      }
    },
    "time": {
      "ago": "ago"
    },
    "timelines": {
      "AFTER_HELP": "After help",
      "BEFORE_HELP": "Before help",
      "arrivedTime": "Arrived to patient",
      "atHospitalTime": "At hospital",
      "transportationTime": "Transported to hospital"
    },
    "updates": {
      "classificatorsVersion": "Classificators package",
      "rulesVersion": "Rules package",
      "title": "Updates",
      "unknownVersion": "unknown",
      "version": "Version"
    },
    "usedMedicaments": {
      "comments": "Comments",
      "count": "Count",
      "event": "Event",
      "medicamentName": "Medicament",
      "time": "Time",
      "total": "Total"
    }
  };
  _exports.default = _default;
});