define("epcr-uis/pods/components/pcr-document/flowchart/procedure-input/new/component", ["exports", "ember-concurrency", "epcr-uis/helpers/create-treatment", "epcr-uis/mixins/treatment-observer"], function (_exports, _emberConcurrency, _createTreatment, _treatmentObserver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_treatmentObserver.default, {
    pcrClassificator: Ember.inject.service(),
    store: Ember.inject.service(),
    pcrTime: Ember.inject.service("pcr/time"),
    proceduresRecount: Ember.computed.alias("pcr.patientTreatment.procedureTreatment.[]"),
    filteredProcedures: Ember.computed("filteredItems", {
      get() {
        return Ember.get(this, "pcrClassificator.flowchart.procedures");
      },

      set: (key, value) => value
    }),
    onProcedureEditPopupClose: (0, _emberConcurrency.task)(function* () {
      yield Ember.set(this, "showProcedurePopup", false);
    }),
    onProceduresPopupClose: (0, _emberConcurrency.task)(function* () {
      yield this.transitionTo("pcr.flowchart");
    }),

    showPopupAndSetTitle(title) {
      Ember.set(this, "showProcedurePopup", true);
      Ember.set(this, "popupTitle", title);
    },

    createProcedure(procedure) {
      const time = this.pcrTime.newTime(this.pcr).forTreatment();
      const procedureFragment = (0, _createTreatment.createProcedure)([procedure, this.store, time]);
      this.pcr.patientTreatment.procedureTreatment.pushObject(procedureFragment);
      Ember.set(this, "procedure", procedureFragment);
      this.addObserverForProcedure(procedureFragment);
    },

    actions: {
      onProcedureSelect(procedure) {
        this.showPopupAndSetTitle(procedure.name);
        this.createProcedure(procedure);
      },

      onDelete(data) {
        this.pcr.patientTreatment.procedureTreatment.removeObject(data);
      }

    }
  });

  _exports.default = _default;
});