define("epcr-uis/helpers/is-article-not-read", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isArticleNotRead = isArticleNotRead;
  _exports.default = _exports.getThreeMonthsAgo = void 0;

  const getThreeMonthsAgo = () => (0, _moment.default)().subtract(3, "months");

  _exports.getThreeMonthsAgo = getThreeMonthsAgo;

  function isArticleNotRead(readNews, article, oldNewsDate = getThreeMonthsAgo()) {
    if (!readNews || !article) return false;
    if (oldNewsDate && oldNewsDate.isAfter(article.date)) return false;
    return !readNews.find(readArticle => readArticle === article.id);
  }

  var _default = Ember.Helper.extend({
    compute([readNews, article]) {
      return isArticleNotRead(readNews, article, getThreeMonthsAgo());
    }

  });

  _exports.default = _default;
});