define("epcr-uis/mirage/models/clinical-death", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    witnesses: (0, _emberCliMirage.hasMany)("pcr-classificator"),
    restoredBloodCirculationBy: (0, _emberCliMirage.hasMany)("pcr-classificator"),
    cprBeforeArrivalBy: (0, _emberCliMirage.belongsTo)()
  });

  _exports.default = _default;
});