define("epcr-uis/helpers/procedure-has-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.procedureHasSize = void 0;
  const proceduresWithSize = [12, 17, 38];

  const isProcedureWithSize = procedureCode => procedureCode && proceduresWithSize.find(code => code === procedureCode);

  const procedureHasSize = ([procedure]) => !!isProcedureWithSize(procedure && Ember.get(procedure, "procedure.code"));

  _exports.procedureHasSize = procedureHasSize;

  var _default = Ember.Helper.helper(procedureHasSize);

  _exports.default = _default;
});