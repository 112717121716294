define("epcr-uis/pods/list/used-medicaments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WecCK5Xw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"scrollable-content\"],[8],[0,\"\\n  \"],[5,\"used-medicaments\",[],[[],[]]],[0,\"\\n  \"],[5,\"pcr-footer\",[],[[],[]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/list/used-medicaments/template.hbs"
    }
  });

  _exports.default = _default;
});