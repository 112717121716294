define("epcr-uis/pods/components/pcr-document/vitals-summary/filled-assessment/component", ["exports", "epcr-uis/helpers/get-vitals-params-range-by-months", "epcr-uis/helpers/count-age-in-months", "ember-concurrency", "epcr-uis/mixins/metrics-tracker"], function (_exports, _getVitalsParamsRangeByMonths, _countAgeInMonths, _emberConcurrency, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    tagName: "",
    time: Ember.inject.service(),
    getVitalRangesByAge: Ember.computed("patientDetails.birthDate", "patientDetails.age.{approximate,age,measure.code}", function () {
      const monthDiff = (0, _countAgeInMonths.countAgeInMonths)([this.time, this.patientDetails, this.callTime]);
      return (0, _getVitalsParamsRangeByMonths.getVitalsParamsRangeByMonths)([monthDiff]);
    }),

    clearInjuryLocation(assessment, injuryLocation) {
      if (assessment.type.code === "BEFORE_HELP") Ember.setProperties(injuryLocation, {
        amputation: [],
        dislocation: [],
        bleeding: [],
        fracture: [],
        softTissue: [],
        burn: [],
        paralysis: [],
        pain: [],
        swelling: []
      });
    },

    removeVital: (0, _emberConcurrency.task)(function* () {
      const {
        assessment
      } = this;
      const injuryLocation = Ember.get(this, "pcr.injuryLocation");
      yield this.assessments.removeObject(assessment);
      if (injuryLocation) this.clearInjuryLocation(assessment, injuryLocation);
      this.trackEvent("Assessment", "Delete patient assessment", assessment.type.name);
      Ember.set(this, "isDeleteConfirmationOpen", false);
    }),
    actions: {
      transitionToEditPage(code, assessments, index) {
        return this.onEdit(index);
      }

    }
  });

  _exports.default = _default;
});