define("epcr-uis/pods/components/pcr-document/vitals/component", ["exports", "epcr-uis/mixins/copy-assessment-values", "epcr-uis/helpers/get-assessment-by-type", "epcr-uis/helpers/go-to-next-input"], function (_exports, _copyAssessmentValues, _getAssessmentByType, _goToNextInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const A_TYPE_CODE_PATH = "assessment.type.code";

  var _default = Ember.Component.extend(_copyAssessmentValues.default, {
    "sidebar-switcher-scope": "vitals",
    sidebarSwitcherScope: "vitals",
    attributeBindings: ["sidebarSwitcherScope:sidebar-switcher-scope"],
    pcrClassificator: Ember.inject.service(),
    login: Ember.inject.service(),
    features: Ember.inject.service(),
    vitalsInformation: Ember.computed.alias("pcrClassificator.pcrDocumentVitalParams"),
    temperatureFavourites: [{
      value: 36,
      name: "36."
    }, {
      value: 37,
      name: "37."
    }, {
      value: 38,
      name: "38."
    }, {
      value: 39,
      name: "39."
    }],

    isSectionCopyVisible(assessmentType, isAtErFieldsEmpty, isAllFieldsEmpty) {
      const {
        assessment,
        previousAssessment
      } = this;

      if (assessmentType && assessment && previousAssessment) {
        return assessmentType === "AT_ER" ? assessment[isAtErFieldsEmpty] && !previousAssessment[isAtErFieldsEmpty] : assessmentType !== "BEFORE_HELP" && assessment[isAllFieldsEmpty] && !previousAssessment[isAllFieldsEmpty];
      }

      return false;
    },

    isBreathingSectionCopyAvailable: Ember.computed("assessmentType", "assessment.isBreathingAtErFieldsEmpty", function () {
      return this.isSectionCopyVisible(this.assessmentType, "isBreathingAtErFieldsEmpty", "isBreathingFieldsEmpty");
    }),
    isBloodFlowSectionCopyAvailable: Ember.computed("assessmentType", "assessment.isBloodFlowAtErFieldsEmpty", function () {
      return this.isSectionCopyVisible(this.assessmentType, "isBloodFlowAtErFieldsEmpty", "isBloodFlowFieldsEmpty");
    }),
    isPainSectionCopyAvailable: Ember.computed("assessmentType", "assessment.isPainAtErFieldsEmpty", function () {
      return this.isSectionCopyVisible(this.assessmentType, "isPainAtErFieldsEmpty", "isPainFieldsEmpty");
    }),

    getVitalClassificator(suffix) {
      return Ember.get(this.pcrClassificator.pcrDocumentVitalParams.condition, suffix);
    },

    getVitalGcsClassificator(suffix) {
      return Ember.get(this.pcrClassificator.pcrDocumentVitalParams.gcs, suffix);
    },

    showGCSMinMaxButtons: Ember.computed("assessment.consciousness.gcs.isNotFilled", "assessment.consciousness.type", function () {
      const isNotFilled = Ember.get(this, "assessment.consciousness.gcs.isNotFilled");
      return isNotFilled && this.assessment.consciousness.type === "GKS";
    }),

    previousAssessmentCode(assessmentType) {
      if (assessmentType === "AFTER_HELP") return "BEFORE_HELP";
      return assessmentType === "AT_ER" ? "AFTER_HELP" : null;
    },

    previousAssessment: Ember.computed(A_TYPE_CODE_PATH, {
      get() {
        const {
          assessmentType,
          assessments
        } = this;
        if (assessmentType === "BEFORE_HELP") return null;

        if (assessmentType === "AT_ER") {
          const AAAssessment = (0, _getAssessmentByType.getAssessmentByType)(assessments, "AFTER_HELP");
          if (AAAssessment) return AAAssessment;
        }

        return (0, _getAssessmentByType.getAssessmentByType)(assessments, "BEFORE_HELP");
      },

      set: (key, value) => value
    }),
    assessmentType: Ember.computed(A_TYPE_CODE_PATH, function () {
      return Ember.get(this, A_TYPE_CODE_PATH);
    }),
    closeSidebarOnSelect: Ember.computed(function () {
      return !Ember.get(this.features, "rapidInputMode");
    }),
    changeSystolicDiastolicInputOn: inputValue => inputValue > 30,
    valueHasLength: (value, length) => Ember.isPresent(value) && `${value}`.length >= length,

    goToNextInput() {
      (0, _goToNextInput.goToNextInput)(this.features);
    },

    actions: {
      setBreathingNormal() {
        Ember.set(this, "assessment.breathing.quality", this.getVitalClassificator("breathing.0"));
        Ember.set(this, "assessment.breathing.leftLung", [this.getVitalClassificator("lung.0")]);
        Ember.set(this, "assessment.breathing.rightLung", [this.getVitalClassificator("lung.0")]);
      },

      setSkinNormal() {
        Ember.set(this, "assessment.circulation.skinColor", this.getVitalClassificator("circulation.skinColor.0"));
        Ember.set(this, "assessment.circulation.skinTemperature", this.getVitalClassificator("circulation.skinTemp.0"));
        Ember.set(this, "assessment.circulation.skinCondition", this.getVitalClassificator("circulation.skinCondition.0"));
        Ember.set(this, "assessment.circulation.rash", false);
      },

      setConsciousnessNormal() {
        Ember.set(this, "assessment.consciousness.leftEye", [this.getVitalClassificator("consciousness.eyesCondition.0")]);
        Ember.set(this, "assessment.consciousness.rightEye", [this.getVitalClassificator("consciousness.eyesCondition.0")]);
      },

      setGKSMinimum() {
        Ember.set(this, "assessment.consciousness.gcs.eyeResponse", this.getVitalGcsClassificator("eyeResponse.3"));
        Ember.set(this, "assessment.consciousness.gcs.verbalResponse", this.getVitalGcsClassificator("verbalResponse.4"));
        Ember.set(this, "assessment.consciousness.gcs.motorResponse", this.getVitalGcsClassificator("motorResponse.5"));
      },

      setGKSMaximum() {
        Ember.set(this, "assessment.consciousness.gcs.eyeResponse", this.getVitalGcsClassificator("eyeResponse.0"));
        Ember.set(this, "assessment.consciousness.gcs.verbalResponse", this.getVitalGcsClassificator("verbalResponse.0"));
        Ember.set(this, "assessment.consciousness.gcs.motorResponse", this.getVitalGcsClassificator("motorResponse.0"));
      },

      insertBreathingAssessment() {
        this.copyBreathing();
      },

      insertBloodFlowAssessment() {
        this.copyBloodFlow();
      },

      insertEyesAssessment() {
        this.copyEyes();
      },

      insertPainAssessment() {
        this.copyPain();
      },

      insertSkinAssessment() {
        this.copySkin();
      },

      insertGlucoseAssessment() {
        this.copyGlucose();
      },

      insertEcgAssessment() {
        this.copyEcg();
      },

      insertConsciousnessAssessment() {
        this.copyConsciousness();
      },

      onRespiratoryRateChange(value) {
        this.valueHasLength(value, 2) && this.goToNextInput();
      },

      onSpO2Change(value) {
        value > 10 && value <= 100 && this.goToNextInput();
      },

      onBpDiastolicChange(value) {
        this.changeSystolicDiastolicInputOn(value) && this.goToNextInput();
      },

      onPulseChange(value) {
        value >= 25 && this.goToNextInput();
      },

      goToNextInput() {
        this.goToNextInput();
      },

      onMeningealSymptomsSelect(selectedItem) {
        Ember.set(this, "assessment.consciousness.meningealSignals", selectedItem ? selectedItem.code : selectedItem);
        this.goToNextInput();
      },

      closeSidebar() {
        Ember.get(this.features, "rapidInputMode") && document.querySelector(".sidebar:not(.centered) .submit-button").click();
      }

    }
  });

  _exports.default = _default;
});