define("epcr-uis/pods/components/pcr-document/flowchart/time-edit-drill-down/component", ["exports", "moment", "epcr-uis/mixins/metrics-tracker"], function (_exports, _moment, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    tagName: "",
    timeButtons: [{
      value: -5,
      name: "-5"
    }, {
      value: -1,
      name: "-1"
    }, {
      value: 1,
      name: "+1"
    }, {
      value: 5,
      name: "+5"
    }],
    timeCopy: Ember.computed("time", "isVisible", "timeCopy", {
      set: (key, value) => value,

      get() {
        return (0, _moment.default)(this.time);
      }

    }),

    recomputeValue(key, value) {
      Ember.set(this, key, null);
      Ember.set(this, key, value);
    },

    getTimeDiff(newTime, oldTime) {
      return newTime.diff(oldTime);
    },

    timeEditDiff: Ember.computed("time", "timeCopy", function () {
      return this.timeCopy.diff(this.time);
    }),
    actions: {
      addTime(minutes) {
        const newTime = this.timeCopy.add(minutes, "minutes");
        this.recomputeValue("timeCopy", newTime);
        this.onTimeAdded(this.getTimeDiff(newTime, this.time), this.time);
      },

      onConfirm() {
        this.trackEvent("Treatment", "Fast time edit");
        this.onConfirm(this.timeCopy, this.applyTimeEditToOtherRecords);
      }

    }
  });

  _exports.default = _default;
});