define("epcr-uis/pods/user/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    externalId: attr("string"),
    firstName: attr("string"),
    lastName: attr("string")
  });

  _exports.default = _default;
});