define("epcr-uis/pods/components/pcr-document/flowchart/inventory-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jBClhN7w",
    "block": "{\"symbols\":[\"FlowchartInput\",\"FlowchartDelete\",\"Ui\",\"@item\",\"@onDelete\",\"@inventory\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"pcr-document/flowchart/input\"],null],[28,\"component\",[\"pcr-document/flowchart/delete\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@data\",\"@parentId\",\"@title\",\"@isRightPopupOpen\",\"@index\",\"@onDelete\",\"@flowcharts\"],[[23,4,[]],[22,\"elementId\"],[23,4,[\"code\",\"name\"]],[22,\"isRightPopupOpen\"],[22,\"index\"],[23,5,[]],[23,6,[]]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"content inventory \",[22,\"elementId\"]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isRightPopupOpen\"]]],null],true],null]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"condition\"],[8],[0,\"\\n\"],[4,\"if\",[[23,4,[\"total\"]]],null,{\"statements\":[[0,\"          \"],[7,\"a\",true],[10,\"class\",\"ui circular label\"],[8],[0,\"\\n            \"],[1,[23,4,[\"total\"]],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"info\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n          \"],[7,\"div\",true],[8],[1,[23,4,[\"code\",\"name\"]],false],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"inventory-type\"],[8],[1,[23,4,[\"type\",\"name\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[6,[23,3,[\"note\"]],[],[[\"@note\"],[[23,4,[\"notes\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n      \"],[6,[23,2,[]],[],[[\"@data\",\"@onDelete\"],[[23,4,[]],[23,5,[]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[3]}],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/flowchart/inventory-input/template.hbs"
    }
  });

  _exports.default = _default;
});