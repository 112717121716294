define("epcr-uis/pods/pcr-reloader/pcr-header/by-signal/service", ["exports", "epcr-uis/pods/pcr-reloader/pcr-header/by-period/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    pcr: Ember.inject.service(),
    androidSignal: Ember.inject.service(),
    pcrModel: null,
    pcrSavedCounter: 0,
    period: 2 * 60000,

    watch(callback, pcr) {
      this._super(...arguments);

      Ember.set(this, "pcrModel", pcr);
      Ember.set(this, "callback", callback);
      Ember.set(this, "pcrSavedCounter", 0);
      this.pcr.on("onSuccessPcrSave", this, "_onSuccessPcrSave");
      this.androidSignal.watchPcrChangesOnBackend(pcr && pcr.id);
      this.androidSignal.on("onPcrUpdate", this, "_onPcrUpdate");
    },

    stopWatch() {
      this._super(...arguments);

      Ember.set(this, "pcrModel", null);
      Ember.set(this, "callback", null);
      this.androidSignal.stopWatchPcrChangesOnBackend();
      this.androidSignal.off("onPcrUpdate", this, "_onPcrUpdate");
      this.pcr.off("onSuccessPcrSave", this, "_onSuccessPcrSave");
    },

    _onSuccessPcrSave() {
      Ember.set(this, "pcrSavedCounter", this.pcrSavedCounter + 1);
    },

    _onPcrUpdate() {
      return this.pcrSavedCounter ? Ember.set(this, "pcrSavedCounter", this.pcrSavedCounter - 1) : this.callback && this.callback(this.pcrModel, this.debounce, this.period);
    }

  });

  _exports.default = _default;
});