define("epcr-uis/pods/components/pcr-routes/patient/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ABY3Xm4d",
    "block": "{\"symbols\":[\"Patient\",\"@pcr\"],\"statements\":[[5,\"route-content-loader\",[],[[\"@promise\"],[[22,\"patientLoadPromise\"]]],{\"statements\":[[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"pcr-document/patient\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@pcr\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-routes/patient/template.hbs"
    }
  });

  _exports.default = _default;
});