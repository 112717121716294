define("epcr-uis/pods/address/city/serializer", ["exports", "epcr-uis/pods/lazy-fragment/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    setPayload(payload) {
      payload.map((city, index) => city.id = index);
      return payload;
    }

  });

  _exports.default = _default;
});