define("epcr-uis/pods/components/pcr-document/patient/phc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dv0SyBTa",
    "block": "{\"symbols\":[\"SingleInput\",\"@patient\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"eight wide computer eight wide tablet column\"],[8],[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"side-bar/single-input\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[[12,\"id\",\"PHC\"]],[[\"@title\",\"@validation\",\"@data\",\"@source\",\"@debounce\",\"@key\",\"@readOnly\",\"@onSelectedItem\",\"@onSearchListVisible\"],[[28,\"t\",[\"pcr.patientDetails.phcFacility\"],null],[24,[\"pcrValidation\",\"errors\",\"PHC\"]],[23,2,[\"phcFacility\",\"code\"]],[22,\"phcSource\"],500,\"name\",[22,\"isPhcUnknown\"],[28,\"action\",[[23,0,[]],\"onPhcSelected\"],null],[28,\"action\",[[23,0,[]],\"scrollToField\",\"#PHC\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[5,\"checkbox-true-null\",[[12,\"class\",\"four wide computer four wide tablet column\"]],[[\"@title\",\"@checked\",\"@onValueChange\"],[[28,\"t\",[\"actions.selectUnknown\"],null],[22,\"isPhcUnknown\"],[28,\"action\",[[23,0,[]],\"onPhcUnknown\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/patient/phc/template.hbs"
    }
  });

  _exports.default = _default;
});