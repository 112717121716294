define("epcr-uis/mirage/models/pcr", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _emberCliMirage.Model.extend({
    patientDetails: (0, _emberCliMirage.belongsTo)(),
    forms: (0, _emberCliMirage.belongsTo)(),
    strokeCriteria: (0, _emberCliMirage.belongsTo)(),
    traumaCriteria: (0, _emberCliMirage.belongsTo)(),
    patientSafetyEquipment: (0, _emberCliMirage.belongsTo)(),
    heartAttackCriteria: (0, _emberCliMirage.belongsTo)(),
    deathStatement: (0, _emberCliMirage.belongsTo)(),
    clinicalDeath: (0, _emberCliMirage.belongsTo)(),
    birthCriteria: (0, _emberCliMirage.belongsTo)(),
    eventNotifications: (0, _emberCliMirage.belongsTo)(),
    agreementsSignatures: (0, _emberCliMirage.belongsTo)(),
    patientDiagnosis: (0, _emberCliMirage.belongsTo)(),
    patientTreatment: (0, _emberCliMirage.belongsTo)(),
    injuryLocation: (0, _emberCliMirage.belongsTo)(),
    delays: (0, _emberCliMirage.belongsTo)(),
    callInformation: (0, _emberCliMirage.belongsTo)(),
    teamActivation: (0, _emberCliMirage.belongsTo)(),
    eventCancelReason: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR),
    category: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR),
    times: (0, _emberCliMirage.belongsTo)("time"),
    patientAssessments: (0, _emberCliMirage.hasMany)("patient-assessment"),
    transportation: (0, _emberCliMirage.belongsTo)(),
    noTransportation: (0, _emberCliMirage.belongsTo)(),
    transportationType: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR),
    metadata: (0, _emberCliMirage.belongsTo)(),
    billing: (0, _emberCliMirage.belongsTo)(),
    trip: (0, _emberCliMirage.belongsTo)()
  });

  _exports.default = _default;
});