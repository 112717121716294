define("epcr-uis/helpers/street-formatter", ["exports", "epcr-uis/helpers/city-formatter"], function (_exports, _cityFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.streetFormatter = streetFormatter;
  _exports.default = void 0;

  const addComma = value => value ? ", " : "";

  function valueWithComma(format, value) {
    if (value) format += addComma(format) + value;
    return format;
  }

  function streetFormatter([address]) {
    let formattedStreet = "";
    if (!address) return formattedStreet;
    const street = Ember.get(address, "street");
    formattedStreet = valueWithComma(formattedStreet, street);
    formattedStreet = valueWithComma(formattedStreet, (0, _cityFormatter.cityFormatter)([address]));
    return formattedStreet;
  }

  var _default = Ember.Helper.helper(streetFormatter);

  _exports.default = _default;
});