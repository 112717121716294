define("epcr-uis/instance-initializers/moment-intl", ["exports", "epcr-uis/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    const intlService = appInstance.lookup("service:intl");

    if (_environment.default.APP.defaultLocale === "lt-lt" && _environment.default.environment !== "test") {
      const t = key => intlService.t(`moment.relativeTime.${key}`);

      const keys = ["future", "past", "s", "ss", "m", "mm", "h", "hh", "d", "dd", "M", "MM", "y", "yy"];
      const translations = {
        relativeTime: {}
      };
      keys.forEach(key => translations.relativeTime[key] = t(key));

      _moment.default.updateLocale("lt", translations);
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});