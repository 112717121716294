define("epcr-uis/initializers/promise", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _emberWindowMock.default.Promise = Ember.RSVP.Promise;
  }

  var _default = {
    name: "promise",
    initialize
  };
  _exports.default = _default;
});