define("epcr-uis/mirage/serializers/pcr", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.RestSerializer.extend({
    embed: true,
    include: ["category", "patientDetails", "patientAssessments", "agreementsSignatures", "patientDiagnosis", "times", "injuryLocation", "delays", "callInformation", "teamActivation", "eventCancelReason", "patientTreatment", "strokeCriteria", "traumaCriteria", "patientSafetyEquipment", "heartAttackCriteria", "eventNotifications", "birthCriteria", "deathStatement", "clinicalDeath", "transportation", "noTransportation", "transportationType", "metadata", "billing", "trip"]
  });

  _exports.default = _default;
});