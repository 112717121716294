define("epcr-uis/pods/print/android/service", ["exports", "epcr-uis/pods/print/service", "epcr-uis/helpers/window-wrapper"], function (_exports, _service, _windowWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    hasPrinterApi: true,
    printerStatus: null,
    isPrinting: false,

    isPrinterStatusOk() {
      return this.printerStatus === "OK" || this.printerStatus === "PRINT_COMPLETE";
    },

    isNotAllowedPrinting() {
      return this.isLoading || !this.isPrinterStatusOk();
    },

    isLoading: Ember.computed("isPrinting", "fetchTask.isRunning", function () {
      return this.isPrinting || Ember.get(this, "fetchTask.isRunning");
    }),

    sendBase64PdfDataToDevice(base64Data) {
      const context = this;
      const promise = new Ember.RSVP.Promise(resolve => context.set("resolve", resolve));
      (0, _windowWrapper.callMethod)("window.epcr.print", base64Data);
      return promise;
    },

    onPrinterStatusUpdate(message) {
      if (message === "PRINT_COMPLETE") Ember.set(this, "isPrinting", false);
      Ember.set(this, "printerStatus", message);
    },

    resolvePrintAction() {
      const {
        resolve
      } = this;
      return resolve && resolve(true);
    }

  });

  _exports.default = _default;
});