define("epcr-uis/pods/components/comments-list/comment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pboqnj2Y",
    "block": "{\"symbols\":[\"@comment\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"avatar\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui card\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"big user blue icon\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"wrapper\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"author\"],[8],[1,[23,1,[\"firstName\"]],false],[0,\" \"],[1,[23,1,[\"lastName\"]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"metadata\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"date\"],[8],[1,[28,\"moment-format\",[[23,1,[\"date\"]],\"YYYY-MM-DD HH:mm\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"\\n    \"],[1,[23,1,[\"content\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/comments-list/comment/template.hbs"
    }
  });

  _exports.default = _default;
});