define("epcr-uis/mixins/is-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    login: Ember.inject.service(),

    isAdmin() {
      const permissions = Ember.get(this, "login.tokenData.perm");
      return permissions && permissions.includes("epcr.admin") && Ember.get(this, "session.isAuthenticated");
    }

  });

  _exports.default = _default;
});