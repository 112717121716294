define("epcr-uis/instance-initializers/barcode-reader-register", ["exports", "ember-window-mock", "epcr-uis/helpers/window-wrapper"], function (_exports, _emberWindowMock, _windowWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    if ((0, _windowWrapper.hasMethod)("window.barcode.scanPersonalId")) {
      const barcodeService = appInstance.lookup("service:barcode");
      const {
        setPID
      } = barcodeService;
      const boundSetPID = setPID.bind(barcodeService);
      _emberWindowMock.default.epcr.barcode = {
        setPID: id => boundSetPID(id)
      };
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});