define("epcr-uis/mirage/factories/medicament-treatment", ["exports", "ember-cli-mirage", "moment", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _moment, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    medicament: 1,
    time: i => (0, _moment.default)(`2018-05-14 10:${10 + i}:00.000+03:00`),
    rejected: false,
    notes: () => (0, _isEnglish.isEnglish)() ? "Patient is recovering" : "Pacientas atsigauna",
    member: 2,
    size: 3
  });

  _exports.default = _default;
});