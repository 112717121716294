define("epcr-uis/pods/pcr-classificator/medicaments/vilnius", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VLN_MEDICAMENTS = void 0;

  const VLN_MEDICAMENTS = t => ({
    stations: ["56"],
    medicaments: [(0, _medicamentsList.MEDICAMENTS)(t)[206], (0, _medicamentsList.MEDICAMENTS)(t)[230], (0, _medicamentsList.MEDICAMENTS)(t)[237], (0, _medicamentsList.MEDICAMENTS)(t)[238], (0, _medicamentsList.MEDICAMENTS)(t)[239], (0, _medicamentsList.MEDICAMENTS)(t)[240], (0, _medicamentsList.MEDICAMENTS)(t)[242], (0, _medicamentsList.MEDICAMENTS)(t)[243], (0, _medicamentsList.MEDICAMENTS)(t)[244], (0, _medicamentsList.MEDICAMENTS)(t)[245], (0, _medicamentsList.MEDICAMENTS)(t)[246], (0, _medicamentsList.MEDICAMENTS)(t)[247], (0, _medicamentsList.MEDICAMENTS)(t)[249], (0, _medicamentsList.MEDICAMENTS)(t)[250], (0, _medicamentsList.MEDICAMENTS)(t)[251], (0, _medicamentsList.MEDICAMENTS)(t)[285], (0, _medicamentsList.MEDICAMENTS)(t)[286], (0, _medicamentsList.MEDICAMENTS)(t)[287]]
  });

  _exports.VLN_MEDICAMENTS = VLN_MEDICAMENTS;
});