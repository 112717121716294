define("epcr-uis/pods/components/error-summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    validation: Ember.inject.service(),
    classNames: ["error-summary"],
    "data-test-id": "error-summary",
    tabs: [{
      code: "event",
      name: "headings.summary.event",
      icon: "call square icon big"
    }, {
      code: "patient",
      name: "headings.summary.patient",
      icon: "id card outline icon big"
    }, {
      code: "vitals",
      name: "headings.summary.vitals",
      icon: "heartbeat icon big"
    }, {
      code: "injuries",
      name: "headings.summary.injuries",
      icon: "universal access icon big"
    }, {
      code: "narrative",
      name: "headings.summary.diagnosis",
      icon: "doctor icon big"
    }, {
      code: "flowchart",
      name: "headings.summary.treatment",
      icon: "first aid icon big"
    }, {
      code: "forms",
      name: "headings.summary.forms",
      icon: "wpforms icon big"
    }, {
      code: "signatures",
      name: "headings.summary.signatures",
      icon: "write icon big"
    }, {
      code: "transportation",
      name: "headings.summary.transportation",
      icon: "emergency icon big"
    }, {
      code: "unknown",
      name: "headings.summary.unknown",
      icon: "question icon big"
    }]
  });

  _exports.default = _default;
});