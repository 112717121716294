define("epcr-uis/pods/pcr/agreements-signatures/model", ["exports", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment"], function (_exports, _attributes, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    medicalHelpAgreement: (0, _attributes.fragment)("pcr/agreements-signatures/medical-help-agreement"),
    infoShareAgreement: (0, _attributes.fragment)("pcr/agreements-signatures/info-share-agreement"),
    teamLeaderSignature: (0, _attributes.fragment)("pcr/agreements-signatures/team-leader-signature"),
    hospitalDoctorSignature: (0, _attributes.fragment)("pcr/agreements-signatures/hospital-doctor-signature"),
    narcoticDrugsVerification: (0, _attributes.fragment)("pcr/agreements-signatures/narcotic-drugs-verification")
  });

  _exports.default = _default;
});