define("epcr-uis/pods/pcr/patient-assessment/consciousness/gcs/model", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _fragment.default.extend({
    eyeResponse: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    verbalResponse: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    motorResponse: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    isFilled: Ember.computed("eyeResponse.code", "verbalResponse.code", "motorResponse.code", function () {
      const keys = ["eyeResponse", "verbalResponse", "motorResponse"];

      for (let i = 0; i < keys.length; i++) {
        if (this.isFieldFilled(keys[i])) {
          return true;
        }
      }

      return false;
    }),
    isNotFilled: Ember.computed.not("isFilled"),

    isFieldFilled(key) {
      return Boolean(this.get(key));
    }

  });

  _exports.default = _default;
});