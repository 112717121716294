define("epcr-uis/pods/components/pcr-document/patient/birthdate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aNtenJDm",
    "block": "{\"symbols\":[\"SingleSelect\",\"NumericInput\",\"Ui\",\"@patient\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/single-select\"],null],[28,\"component\",[\"side-bar/numeric-input\"],null]],null,{\"statements\":[[4,\"if\",[[24,[\"patient\",\"age\",\"approximate\"]]],null,{\"statements\":[[0,\"    \"],[6,[23,2,[]],[[12,\"id\",\"patient-age\"],[12,\"class\",\"six wide mobile four wide tablet four wide computer column\"]],[[\"@title\",\"@value\",\"@maxLength\",\"@validation\"],[[28,\"t\",[\"pcr.patientDetails.age\"],null],[23,4,[\"age\",\"age\"]],3,[24,[\"pcrValidation\",\"errors\",\"patient-age\"]]]]],[0,\"\\n\\n    \"],[6,[23,1,[]],[[12,\"class\",\"six wide mobile four wide tablet four wide computer column\"],[12,\"id\",\"patient-age-unit\"]],[[\"@header\",\"@items\",\"@key\",\"@validation\",\"@selectedItem\"],[[28,\"t\",[\"pcr.patientDetails.units\"],null],[24,[\"pcrClassificator\",\"ageMeasurement\"]],\"name\",[24,[\"pcrValidation\",\"errors\",\"patient-age-unit\"]],[23,4,[\"age\",\"measure\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,2,[]],[[12,\"id\",\"patient-age\"],[12,\"class\",\"twelve wide mobile eight wide tablet eight wide computer column\"]],[[\"@type\",\"@title\",\"@value\",\"@validation\"],[\"date\",[28,\"t\",[\"pcr.patientDetails.birthDate\"],null],[23,4,[\"birthDate\"]],[24,[\"pcrValidation\",\"errors\",\"patient-birth-date\"]]]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"template\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[6,[23,3,[\"age\"]],[],[[],[]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null],[0,\"\\n\"],[5,\"checkbox-true-null\",[[12,\"class\",\"twelve wide mobile four wide tablet four wide computer column\"]],[[\"@title\",\"@checked\"],[[28,\"t\",[\"actions.selectUnknown\"],null],[23,4,[\"age\",\"approximate\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/patient/birthdate/template.hbs"
    }
  });

  _exports.default = _default;
});