define("epcr-uis/pods/pcr-state/service", ["exports", "ember-concurrency", "epcr-uis/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    simpleAuthFetch: Ember.inject.service(),
    state: Ember.computed("pcrStateTask.last.value.state", function () {
      return Ember.get(this.pcrStateTask, "last.value.state");
    }),
    lastUpdateTime: Ember.computed("pcrStateTask.last.value.lastUpdateTime", function () {
      return Ember.get(this.pcrStateTask, "last.value.lastUpdateTime");
    }),
    pcrStateTask: (0, _emberConcurrency.task)(function* (pcrId) {
      return yield this.store.queryRecord("pcr-state", {
        pcrId
      });
    }).restartable(),

    getPcrState(pcrId) {
      return this.pcrStateTask.perform(pcrId);
    },

    changePcrStateToVerified(pcr) {
      // TODO state turi issinaikinti. Current PcrVersionId ar kazkas pan. tures buti.
      const url = _environment.default.APP.pcrVerifyUrl.replace(":pcrId", Ember.get(pcr, "id"));

      return this.simpleAuthFetch.fetchRequest(url, "POST", {
        state: "VERIFIED"
      });
    }

  });

  _exports.default = _default;
});