define("epcr-uis/pods/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    login: Ember.inject.service(),
    features: Ember.inject.service(),

    onAuthenticated() {
      this.features.isEnabled("load-config") ? this.transitionTo("load-config") : this.transitionTo("home");
    },

    actions: {
      onLogin(username, password) {
        return username && password ? this.login.authenticateByCredentials(username, password).then(() => this.onAuthenticated()) : new Ember.RSVP.Promise((resolve, reject) => reject("INCORRECT_CREDENTIALS"));
      }

    }
  });

  _exports.default = _default;
});