define("epcr-uis/mirage/factories/time-change-request", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    from: () => _emberCliMirage.faker.date.recent(),
    to: () => _emberCliMirage.faker.date.recent(),
    comment: "Buk geras, pakeisk laika"
  });

  _exports.default = _default;
});