define("epcr-uis/pods/fragment/transform", ["exports", "ember-data-model-fragments/transforms/fragment"], function (_exports, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    serialize() {
      const data = this._super(...arguments);

      return this.serializeFragment(data);
    },

    serializeFragment(data) {
      if (Ember.isNone(data)) return undefined;
      const objectKeys = Object.keys(data);
      const hasValue = objectKeys.find(key => {
        const value = Ember.get(data, key);

        if (value != null) {
          const valueType = typeof value;
          const isNumber = valueType === "number";
          const isBoolean = valueType === "boolean";
          const isNotEmptyString = valueType === "string" && value !== "";
          const isNotEmptyObjectOrArray = valueType === "object" && Object.keys(value).length > 0;
          return isNumber || isBoolean || isNotEmptyString || isNotEmptyObjectOrArray;
        }

        return undefined;
      });
      return hasValue ? data : undefined;
    }

  });

  _exports.default = _default;
});