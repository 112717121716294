define("epcr-uis/pods/my-pcr/adapter", ["exports", "epcr-uis/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    model: "my-pcr-list",

    urlForQuery(queryParams) {
      const {
        searchId,
        offset,
        limit
      } = queryParams;
      delete queryParams.searchId;
      queryParams.offset = offset * limit;
      return `${this.mainPath(this.model)}/${searchId}`;
    }

  });

  _exports.default = _default;
});