define("epcr-uis/pods/components/pcr-routes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6/ijugAS",
    "block": "{\"symbols\":[\"route\"],\"statements\":[[4,\"each\",[[24,[\"routes\"]]],null,{\"statements\":[[0,\"  \"],[5,\"lazy-yield\",[],[[\"@visible\",\"@preCreateDom\"],[[28,\"eq\",[[28,\"pcr-route-tracker\",null,null],[23,1,[\"routeName\"]]],null],[23,1,[\"preCreateDom\"]]]],{\"statements\":[[0,\"\\n    \"],[15,[23,1,[\"templateName\"]],[1]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-routes/template.hbs"
    }
  });

  _exports.default = _default;
});