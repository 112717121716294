define("epcr-uis/initialization-error/initialization-error-ember", [], function () {
  "use strict";

  /* eslint-disable */
  if (window.Ember) {
    var onErrorCallback = window.Ember.onerror;

    window.Ember.onerror = function (error) {
      logErrorIfEmberFailedToLoad(error);
      onErrorCallback && onErrorCallback(arguments);
      throw error;
    };
  }
});