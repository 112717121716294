define("epcr-uis/pods/android/service", ["exports", "epcr-uis/helpers/android"], function (_exports, _android) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isAndroid: () => (0, _android.isAndroid)(),
    getAppVersion: () => (0, _android.getAppVersion)(),
    getImei: () => (0, _android.getImei)(),
    isOfflineEnabled: () => (0, _android.isOfflineEnabled)(),
    isAppVersionHigher: version => (0, _android.isAppVersionHigher)(version)
  });

  _exports.default = _default;
});