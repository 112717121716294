define("epcr-uis/pods/list/verified-pcrs/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    verifiedPcrs: Ember.inject.service("list/verified-pcrs"),

    model() {
      this.verifiedPcrs.getVerifiedPcrs.perform();
      return {
        pcrsTask: this.verifiedPcrs.getVerifiedPcrs
      };
    },

    actions: {
      onPcrClicked(pcrId) {
        return this.transitionTo("print.form", pcrId);
      }

    }
  });

  _exports.default = _default;
});