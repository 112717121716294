define("epcr-uis/pods/used-medicament/medicament/adapter", ["exports", "epcr-uis/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    model: "used-medicaments",

    urlForQuery(options) {
      const {
        medicament
      } = options;
      delete options.medicament;
      return this.mainPath(`current-user/${this.model}/${medicament}`);
    }

  });

  _exports.default = _default;
});