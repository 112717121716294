define("epcr-uis/pods/components/error-summary/tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XnMwJ6EM",
    "block": "{\"symbols\":[\"error\",\"@title\",\"@icon\",\"@errorGroup\"],\"statements\":[[5,\"section-header\",[],[[\"@title\",\"@icon\"],[[23,2,[]],[23,3,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"errorCount\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"ui red circular label\"],[8],[1,[22,\"errorCount\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"warningCount\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"ui orange circular label\"],[8],[1,[22,\"warningCount\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"ui-container\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui grid error-grid\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"name\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"message\"]]],null,{\"statements\":[[0,\"            \"],[1,[23,1,[\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[23,1,[\"code\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n          \"],[7,\"div\",true],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[\"type\"]],\"error\"],null],\"ui fluid red button\",\" ui fluid orange button\"],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"transitionToElement\",[23,1,[]]],null]],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"actions.view\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/error-summary/tab/template.hbs"
    }
  });

  _exports.default = _default;
});