define("epcr-uis/pods/pcr-data-change/service", ["exports", "epcr-uis/mixins/is-station-allowed-to-create-new-pcr"], function (_exports, _isStationAllowedToCreateNewPcr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_isStationAllowedToCreateNewPcr.default, {
    "pcr-error-mapper": Ember.inject.service(),
    lastModifiedTime: null,
    validation: Ember.inject.service(),
    pcr: Ember.inject.service(),
    isStationAllowedToCreateNewEpcr: Ember.computed({
      get() {
        return this.isStationAllowedToCreateNewPcr();
      }

    }),

    startCheckingPcrDataChanges(pcr, simpleAuthFetch, validateDelay, autoSaveDelay) {
      const interval = Ember.run.later(this, () => {
        if (!this.isDestroyed) {
          this.checkForPcrDataChanges(pcr, simpleAuthFetch, autoSaveDelay);
          this.startCheckingPcrDataChanges(pcr, simpleAuthFetch, validateDelay, autoSaveDelay);
        }
      }, validateDelay);
      this.set("autoCheckingInterval", interval);
    },

    checkForPcrDataChanges(pcr, simpleAuthFetch, autoSaveDelay) {
      const {
        modifiedTime
      } = this.pcr;

      if (this.lastModifiedTime !== modifiedTime) {
        Ember.set(this, "lastModifiedTime", modifiedTime);
        this.onPcrDataChanged(pcr, simpleAuthFetch, autoSaveDelay);
      }
    },

    requiredPcrFieldsPresent: pcr => pcr.eventId && pcr.times.callTime,

    onPcrDataChanged(pcr, simpleAuthFetch, autoSaveDelay) {
      if (!this.isStationAllowedToCreateNewEpcr || this.requiredPcrFieldsPresent(pcr)) {
        this.validation.validatePcr.perform(pcr, simpleAuthFetch);
        if (pcr.eventId >= 200000000) this.pcr.savePcrTemporary.perform(pcr, autoSaveDelay);
      }
    },

    stopCheckingPcrDataChanges() {
      Ember.run.cancel(this.autoCheckingInterval);
      Ember.set(this, "lastModifiedTime", null);
      this.validation.validatePcr.cancelAll();
      this.pcr.cancelPcrTemporarySave();
    }

  });

  _exports.default = _default;
});