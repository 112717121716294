define("epcr-uis/initialization-error/initialization-error", [], function () {
  "use strict";

  /* eslint-disable */
  function getEnvContent() {
    const envContent = document.getElementsByTagName("meta")["epcr-uis/config/environment"].getAttribute("content");
    return JSON.parse(decodeURIComponent(envContent));
  }

  function getVersion() {
    try {
      return getEnvContent().APP.epcrVersion;
    } catch (e) {
      return "nežinoma";
    }
  }

  function isNotTestEnvironment() {
    return getEnvContent().environment !== "test";
  }

  setTimeout(function () {
    if (!isEmberFullyLoaded() && isNotTestEnvironment()) document.getElementsByTagName("body")[0].classList.add("initialization-error");
  }, 30000);
  window.addEventListener("error", function (error) {
    logErrorIfEmberFailedToLoad(error);
  });

  function logErrorIfEmberFailedToLoad(error) {
    isAndroidLoggerAvailable() && !isEmberFullyLoaded() && window.logger.error(formatErrorMessage(error));
  }

  function isEmberFullyLoaded() {
    return document.getElementsByClassName("ember-view").length;
  }

  function isAndroidLoggerAvailable() {
    return window.logger;
  }

  function formatErrorMessage(error) {
    return "[" + error.type + "] " + error.message + "@" + error.filename + ":" + error.lineno + ":" + error.colno;
  }
});