define("epcr-uis/pods/components/training-blocks/component", ["exports", "ember-concurrency", "epcr-uis/config/environment", "epcr-uis/mixins/metrics-tracker", "epcr-uis/constants"], function (_exports, _emberConcurrency, _environment, _metricsTracker, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const findById = (cases, id) => cases.find(training => training.id === id).translationKey;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    classNames: ["ui container training"],
    router: Ember.inject.service(),
    simpleAuthFetch: Ember.inject.service(),
    store: Ember.inject.service(),
    pcrRouteTracker: Ember.inject.service(),
    userPreferencesService: Ember.inject.service("user-preferences"),
    trainingCases: [{
      id: "1",
      translationKey: "headings.training.scenarios.1"
    }, {
      id: "2",
      translationKey: "headings.training.scenarios.2"
    }, {
      id: "3",
      translationKey: "headings.training.scenarios.3"
    }, {
      id: "4",
      translationKey: "headings.training.scenarios.4"
    }, {
      id: "5",
      translationKey: "headings.training.scenarios.5"
    }, {
      id: "6",
      translationKey: "headings.training.scenarios.6"
    }, {
      id: "7",
      translationKey: "headings.training.scenarios.7"
    }, {
      id: "8",
      translationKey: "headings.training.scenarios.8"
    }, {
      id: "9",
      translationKey: "headings.training.scenarios.9"
    }],
    queryTrainingSituation: (0, _emberConcurrency.task)(function* (trainingId) {
      this.set("errorMessage", null);
      const self = this;
      const teamId = this.userPreferencesService.userPreferences[_constants.SELECTED_TEAM_CODE];

      let requestPath = _environment.default.APP.trainingURL.replace(":id", trainingId);

      if (teamId) requestPath += `?teamId=${teamId}`;
      yield this.simpleAuthFetch.fetchRequest(requestPath, "GET").then(response => response.json()).then(async () => {
        this.trackEvent("Training", findById(this.trainingCases, trainingId), trainingId);
        await (0, _emberConcurrency.timeout)(_environment.default.APP.redirectToTrainingTimeInterval);
        return this.router.transitionTo("/list/active-pcrs");
      }).catch(e => self.set("errorMessage", e));
    })
  });

  _exports.default = _default;
});