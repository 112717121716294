define("epcr-uis/mirage/factories/used-medicament", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    medicamentCode: _emberCliMirage.faker.random.number(),
    total: _emberCliMirage.faker.random.number()
  });

  _exports.default = _default;
});