define("epcr-uis/instance-initializers/sentry", ["exports", "ember-cli-sentry/services/raven", "raven", "epcr-uis/config/environment"], function (_exports, _raven, _raven2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  const setSentryContext = appInstance => {
    const login = appInstance.lookup("service:login");
    const session = appInstance.lookup("service:session");
    const android = appInstance.lookup("service:android");
    session.addObserver("isAuthenticated", void 0, () => {
      const userContext = {
        epcrVersion: _environment.default.APP.epcrVersion,
        station: login.stationId,
        username: Ember.get(login, "tokenData.un"),
        androidVersion: android.getAppVersion()
      };

      _raven2.default.setUserContext(userContext);
    });
  };

  function initialize(appInstance) {
    _raven.default.reopen({
      releaseMetaName: "sentry:revision",
      release: Ember.computed("releaseMetaName", {
        get() {
          return document.querySelector(`meta[name='${Ember.get(this, "releaseMetaName")}']`).content;
        }

      })
    });

    setSentryContext(appInstance);
  }

  var _default = {
    before: "sentry-setup",
    initialize
  };
  _exports.default = _default;
});