define("epcr-uis/pods/components/route-content-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Qo4hiA2",
    "block": "{\"symbols\":[\"d\",\"&default\",\"@promise\"],\"statements\":[[5,\"deferred-content\",[],[[\"@promise\"],[[23,3,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"isPending\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui segment pcr-route-loader\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ui active inverted dimmer\"],[8],[0,\"\\n        \"],[5,\"ui-loader\",[],[[\"@isLoading\",\"@cssClass\"],[true,\"text\"]],{\"statements\":[[1,[28,\"t\",[\"message.loading\"],null],false],[0,\"...\"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[\"isFulfilled\"]]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/route-content-loader/template.hbs"
    }
  });

  _exports.default = _default;
});