define("epcr-uis/pods/components/pcr-document/vitals-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8VGKuN0D",
    "block": "{\"symbols\":[\"assessmentType\",\"index\",\"assessment\",\"FilledAssessment\",\"EmptyAssessment\",\"validationError\",\"@assessments\",\"@pcr\",\"@onNew\",\"@onEdit\",\"@patientDetails\",\"@callTime\"],\"statements\":[[7,\"br\",true],[8],[9],[0,\"\\n\"],[5,\"ui-container\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"allAssessments\"]]],null,{\"statements\":[[4,\"let\",[[28,\"get-assessment-by-type\",[[23,7,[]],[23,1,[\"code\"]]],null],[28,\"component\",[\"pcr-document/vitals-summary/filled-assessment\"],null],[28,\"component\",[\"pcr-document/vitals-summary/empty-assessment\"],null],[28,\"get\",[[24,[\"pcrValidation\",\"errors\"]],[23,1,[\"code\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"        \"],[6,[23,4,[]],[],[[\"@assessment\",\"@onEdit\",\"@pcr\",\"@pcrValidation\",\"@assessmentIndex\",\"@patientDetails\",\"@callTime\",\"@assessmentType\",\"@assessments\",\"@validationError\"],[[23,3,[]],[23,10,[]],[23,8,[]],[22,\"pcrValidation\"],[23,2,[]],[23,11,[]],[23,12,[]],[23,1,[]],[23,7,[]],[23,6,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,[23,5,[]],[],[[\"@assessment\",\"@assessmentType\",\"@assessmentIndex\",\"@pcrTime\",\"@assessments\",\"@pcr\",\"@validationError\",\"@onNew\"],[[23,3,[]],[23,1,[]],[23,2,[]],[22,\"pcrTime\"],[23,7,[]],[23,8,[]],[23,6,[]],[23,9,[]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[3,4,5,6]},null]],\"parameters\":[1,2]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/vitals-summary/template.hbs"
    }
  });

  _exports.default = _default;
});