define("epcr-uis/helpers/ensure-object-structure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ensureObjectStructure = void 0;

  const ensureObjectStructure = (payload, objectPath, value) => {
    // todo. get rid of slice.
    const path = objectPath.slice();

    if (path.length > 1) {
      if (Ember.isNone(payload[path[0]])) {
        payload[path[0]] = {};
      } // todo. need tail optimisation.


      const tmpObj = payload[path[0]];
      path.shift();
      ensureObjectStructure(tmpObj, path, value);
    } else if (Ember.isNone(payload[path[0]])) {
      payload[path[0]] = value;
    }
  };

  _exports.ensureObjectStructure = ensureObjectStructure;
});