define("epcr-uis/mirage/factories/icd", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    code: i => `A66.${i}`,
    name: () => _emberCliMirage.faker.lorem.sentence()
  });

  _exports.default = _default;
});