define("epcr-uis/pods/components/pcr-document/patient/no-patient-block/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    notFound: "NOT_FOUND",
    absent: "ABSENT",

    toggle(value) {
      Ember.set(this, "noPatientReason", this.noPatientReason ? null : value);
    },

    actions: {
      onNotFound() {
        this.toggle(this.notFound);
      },

      onAbsent() {
        this.toggle(this.absent);
      }

    }
  });

  _exports.default = _default;
});