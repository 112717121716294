define("epcr-uis/pods/my-pcr/owner/model", ["exports", "ember-data", "epcr-uis/pods/lazy-fragment/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _model.default.extend({
    externalId: attr("string"),
    firstName: (0, _model.lazyAttr)("firstName"),
    lastName: (0, _model.lazyAttr)("lastName"),
    employee: Ember.inject.service(),

    dataSource() {
      return this.externalId ? this.employee.getEmployee(this.externalId) : null;
    },

    onDataReadSuccess(employee) {
      return employee.getProperties("firstName", "lastName");
    },

    onDataReadError() {
      return {
        firstName: this.externalId,
        lastName: "",
        positions: []
      };
    }

  });

  _exports.default = _default;
});