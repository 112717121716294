define("epcr-uis/instance-initializers/touch-to-click", ["exports", "epcr-uis/config/environment", "epcr-uis/constants", "ember-window-mock"], function (_exports, _environment, _constants, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function SoundPlayer(features, url, volume = 1) {
    let audioData, buffer, context, gainNode;
    const AudioContext = _emberWindowMock.default.AudioContext || false;

    if (AudioContext) {
      context = new AudioContext();
      gainNode = context.createGain();
      gainNode.gain.value = volume;
      gainNode.connect(context.destination);
      const request = new XMLHttpRequest();

      request.onload = () => {
        if (!audioData) {
          // mirage calls onload() twice :(
          audioData = request.response;
          context.decodeAudioData(audioData, data => buffer = data, () => console.error("Error while loading audio file") // eslint-disable-line
          );
        }
      };

      request.responseType = "arraybuffer";
      request.open("get", url, true);
      request.send();
    }

    return {
      play: () => {
        if (buffer && features.get(_constants.FEAT_SOUND_ON_CLICK)) {
          const source = context.createBufferSource();
          source.buffer = buffer;
          source.connect(gainNode);
          source.start(0);
        }
      }
    };
  }

  function imitateClickOnTouch(clickSound, maxOffsetDiff = 20, maxDelay = 100) {
    let startX = 0;
    let startY = 0;
    let startTime = 0;

    const blurElement = el => el && el.blur();

    const isInput = el => {
      if (el) {
        const tag = el.tagName.toLowerCase();
        return tag === "input" || tag === "textarea";
      }

      return false;
    };

    const isClickable = el => el && el.click;

    document.addEventListener("click", e => e.isTrusted && clickSound.play());
    document.addEventListener("touchstart", event => {
      const [{
        clientX,
        clientY
      }] = event.changedTouches;
      startX = clientX;
      startY = clientY;
      startTime = Date.now();
    });
    document.addEventListener("touchend", event => {
      if (Date.now() - startTime < maxDelay) {
        const [{
          clientX,
          clientY
        }] = event.changedTouches;
        const diffX = Math.abs(startX - clientX);
        const diffY = Math.abs(startY - clientY);

        if (diffX > 0 && diffY > 0 && diffX < maxOffsetDiff && diffY < maxOffsetDiff) {
          const elAtXY = document.elementFromPoint(clientX, clientY);

          if (isClickable(elAtXY) && !isInput(elAtXY)) {
            event.preventDefault();
            blurElement(document.activeElement); // nes lieka sufokusuotas inputas ir rodoma klaviatura

            elAtXY.click();
            clickSound.play();
          }
        }
      }
    }, {
      passive: false
    });
  }

  function initialize(appInstance) {
    if (_environment.default.environment !== "test") {
      const features = appInstance.lookup("service:features");
      const clickSound = SoundPlayer(features, "/assets/audio/touch.ogg", .4);
      imitateClickOnTouch(clickSound);
    }
  }

  var _default = {
    name: "touch-to-click",
    initialize
  };
  _exports.default = _default;
});