define("epcr-uis/pods/lazy-fragment/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTSerializer = _emberData.default.RESTSerializer;

  var _default = RESTSerializer.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const modelName = this.getModelName(primaryModelClass);
      payload = this.addHash(payload, modelName);
      payload[modelName] = this.setPayload(payload[modelName], id);
      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    addHash: (payload, modelName) => {
      return {
        [modelName]: payload
      };
    },
    setPayload: (payload, id) => {
      if (Ember.isArray(payload)) payload.map(object => object.id = object.code);else payload.id = id;
      return payload;
    },
    getModelName: primaryModelClass => `${primaryModelClass.modelName}s`
  });

  _exports.default = _default;
});