define("epcr-uis/pods/components/pcr-document/flowchart/input/note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V0xywM0g",
    "block": "{\"symbols\":[\"@note\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"note\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"talk outline icon\"],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/flowchart/input/note/template.hbs"
    }
  });

  _exports.default = _default;
});