define("epcr-uis/initializers/moment-time-zone", ["exports", "moment", "epcr-uis/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _moment.default.tz.setDefault(_environment.default.APP.timeZone);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});