define("epcr-uis/helpers/get-vitals-params-range-by-months", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getVitalsParamsRangeByMonths = getVitalsParamsRangeByMonths;
  _exports.ranges = void 0;
  const spO2Range = [90, 95, 100, 100];
  const temperatureRange = [35, 36.5, 37.4, 38.2];
  const glucoseRange = [2.8, 3.9, 5.5, 7];
  const painRange = [0, 0, 3, 7];
  const ranges = {
    youngerThanMonth: {
      respiratoryRateRange: [25, 30, 60, 65],
      spO2Range,
      bpSystolicRange: [30, 60, 80, 90],
      bpDiastolicRange: [10, 16, 36, 42],
      arterialPressureRange: [16, 30, 50, 58],
      temperatureRange,
      glucoseRange,
      pulseRange: [85, 95, 145, 155],
      painRange
    },
    between1And12Months: {
      respiratoryRateRange: [25, 30, 40, 45],
      spO2Range,
      bpSystolicRange: [55, 70, 90, 115],
      bpDiastolicRange: [27, 37, 56, 66],
      arterialPressureRange: [36, 48, 67, 82],
      temperatureRange,
      glucoseRange,
      pulseRange: [100, 110, 160, 170],
      painRange
    },
    between1And2Years: {
      respiratoryRateRange: [20, 25, 35, 40],
      spO2Range,
      bpSystolicRange: [60, 80, 95, 115],
      bpDiastolicRange: [32, 42, 63, 73],
      arterialPressureRange: [41, 54, 73, 87],
      temperatureRange,
      glucoseRange,
      pulseRange: [90, 100, 150, 160],
      painRange
    },
    between2And5Years: {
      respiratoryRateRange: [20, 25, 30, 35],
      spO2Range,
      bpSystolicRange: [60, 80, 100, 120],
      bpDiastolicRange: [33, 44, 78, 88],
      arterialPressureRange: [42, 56, 85, 98],
      temperatureRange,
      glucoseRange,
      pulseRange: [85, 95, 140, 150],
      painRange
    },
    between5And10Years: {
      respiratoryRateRange: [15, 20, 25, 30],
      spO2Range,
      bpSystolicRange: [70, 90, 110, 130],
      bpDiastolicRange: [47, 57, 76, 86],
      arterialPressureRange: [54, 68, 87, 100],
      temperatureRange,
      glucoseRange,
      pulseRange: [70, 80, 120, 130],
      painRange
    },
    between10And12Years: {
      respiratoryRateRange: [10, 15, 20, 25],
      spO2Range,
      bpSystolicRange: [70, 90, 120, 140],
      bpDiastolicRange: [51, 61, 80, 91],
      arterialPressureRange: [57, 70, 93, 107],
      temperatureRange,
      glucoseRange,
      pulseRange: [50, 60, 110, 120],
      painRange
    },
    olderThan12Years: {
      respiratoryRateRange: [7, 12, 16, 21],
      spO2Range,
      bpSystolicRange: [80, 100, 120, 140],
      bpDiastolicRange: [54, 64, 83, 93],
      arterialPressureRange: [62, 76, 95, 108],
      temperatureRange,
      glucoseRange,
      pulseRange: [50, 60, 100, 110],
      painRange
    }
  };
  _exports.ranges = ranges;

  function getVitalsParamsRangeByMonths([monthsCount]) {
    if (monthsCount === 0) return ranges.youngerThanMonth;
    if (monthsCount >= 1 && monthsCount < 12) return ranges.between1And12Months;
    if (monthsCount >= 12 && monthsCount < 24) return ranges.between1And2Years;
    if (monthsCount >= 24 && monthsCount < 60) return ranges.between2And5Years;
    if (monthsCount >= 60 && monthsCount < 120) return ranges.between5And10Years;
    return monthsCount >= 120 && monthsCount < 148 ? ranges.between10And12Years : ranges.olderThan12Years;
  }
});