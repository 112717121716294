define("epcr-uis/pods/components/pcr-login/users-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V9sRrIZr",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"each\",[[24,[\"users\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"ui small primary button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"loginWithUser\",[23,1,[]]],null]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"name\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,1,[\"username\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-login/users-list/template.hbs"
    }
  });

  _exports.default = _default;
});