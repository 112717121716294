define("epcr-uis/pods/icd/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    getIcd(icd10Code) {
      return this.store.findRecord("icd", icd10Code);
    },

    queryIcd(query, station, teamLeaderType) {
      const type = this.getLeaderType(teamLeaderType);
      return this.store.query("icd", {
        query,
        station,
        type
      });
    },

    getLeaderType: type => type === "GYD" ? "DOCTOR" : "NURSE"
  });

  _exports.default = _default;
});