define("epcr-uis/pods/components/flash-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Mq+nFpG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"message\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"flash-message \",[24,[\"message\",\"type\"]]]]],[10,\"id\",\"flash-message\"],[8],[0,\"\\n    \"],[5,\"ui-container\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"message\",\"type\"]],\"success\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"i\",true],[10,\"class\",\"checkmark large icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[24,[\"message\",\"message\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/flash-message/template.hbs"
    }
  });

  _exports.default = _default;
});