define("epcr-uis/pods/components/pcr-routes/component", ["exports", "epcr-uis/helpers/deferred-content-promise"], function (_exports, _deferredContentPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: ["pcr-routes"],
    eventLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    patientLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    attachmentsLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    vitalsLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    narrativeLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    formsLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    signaturesLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    flowchartLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    transportationLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    toolsLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    pediCalcLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    doseCalcLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    apgarCalcLoadPromise: Ember.computed(function () {
      return (0, _deferredContentPromise.deferredContentPromise)();
    }),
    createRoute: (template, preCreateDom = false, route = template) => ({
      templateName: `components/pcr-routes/${template}`,
      routeName: `pcr.${route}`,
      preCreateDom
    }),
    routes: Ember.computed(function () {
      return [this.createRoute("event"), this.createRoute("patient"), this.createRoute("vitals"), this.createRoute("narrative"), this.createRoute("forms"), this.createRoute("signatures"), this.createRoute("flowchart"), this.createRoute("transportation"), this.createRoute("config"), this.createRoute("attachments"), this.createRoute("tools"), this.createRoute("tools/pediatric-calculator", false, "tools.pediatric-calculator"), this.createRoute("tools/apgar-calculator", false, "tools.apgar-calculator"), this.createRoute("tools/dose-calculator", false, "tools.dose-calculator")];
    })
  });

  _exports.default = _default;
});