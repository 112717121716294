define("epcr-uis/pods/components/pcr-document/vitals/help/abp-ranges/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IRw14qPJ",
    "block": "{\"symbols\":[\"HelpBlock\",\"@pcr\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"pcr-document/abstract/help-block\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@pcr\",\"@blocks\"],[[23,2,[]],[22,\"blocks\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/vitals/help/abp-ranges/template.hbs"
    }
  });

  _exports.default = _default;
});