define("epcr-uis/components/letter-filter", ["exports", "ember-components/components/letter-filter"], function (_exports, _letterFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _letterFilter.default;
    }
  });
});