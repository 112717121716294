define("epcr-uis/pods/patient/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    importPatient(query) {
      return this.store.query("patient", query);
    }

  });

  _exports.default = _default;
});