define("epcr-uis/pods/pcr/patient-details/side-diseases/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    types: (0, _attributes.fragmentArray)("pcr-classificator"),
    allergyNotes: attr("string"),
    otherIllnessDescription: attr("string")
  });

  _exports.default = _default;
});