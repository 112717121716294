define("epcr-uis/pods/components/pcr-document/patient/help/weight-ranges/component", ["exports", "epcr-uis/pods/components/pcr-document/abstract/help-block/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    intl: Ember.inject.service(),
    time: Ember.inject.service(),
    blocks: Ember.computed(function () {
      const {
        time,
        pcr,
        kg,
        moreThan,
        ranges
      } = this;
      const {
        newBorn,
        lessThan1Year,
        between1And2Year,
        between2And5Year,
        between5And10Year,
        between10And12Year,
        moreThan12Year
      } = ranges;

      const createBlock = (range, value) => this.createHelpBlock(time, pcr, kg, range, value);

      return [createBlock(newBorn, "3-4"), createBlock(lessThan1Year, "8-10"), createBlock(between1And2Year, "10-12"), createBlock(between2And5Year, "12-20"), createBlock(between5And10Year, "20-30"), createBlock(between10And12Year, "30-40"), createBlock(moreThan12Year, `${moreThan} 40`)];
    })
  });

  _exports.default = _default;
});