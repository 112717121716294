define("epcr-uis/pods/components/pcr-lists-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const mapIconToRoute = {
    "active-pcrs": "ambulance",
    "my-pcrs": "clipboard outline",
    "verified-pcrs": "hospital outline",
    "used-medicaments": "pills"
  };

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    mapIconToRoute,
    tagName: "",
    activeItemIcon: Ember.computed("router.currentRouteName", function () {
      return mapIconToRoute[this.router.currentRouteName.replace("list.", "")] || "";
    })
  });

  _exports.default = _default;
});