define("epcr-uis/pods/components/pcr-document/injuries/popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H/idmu61",
    "block": "{\"symbols\":[\"RightPopup\",\"Popup\",\"InjuriesList\",\"@injuries\",\"@injuriesKeys\",\"@isRightPopupOpen\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/right-popup\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@fadeBackground\",\"@sidebarSwitcher\",\"@parentClass\",\"@isRightPopupOpen\",\"@task\"],[true,[22,\"sidebarSwitcher\"],\"injuries-modal\",[23,6,[]],[22,\"closeCenterModal\"]]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"header\"]],[],[[\"@title\"],[[28,\"t\",[\"headings.patientAssessments.painLocation\"],null]]]],[0,\"\\n\\n    \"],[6,[23,2,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"pcr-document/injuries/list\"],null]],null,{\"statements\":[[0,\"        \"],[6,[23,3,[]],[],[[\"@injuries\",\"@injuriesKeys\"],[[23,4,[]],[23,5,[]]]]],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/injuries/popup/template.hbs"
    }
  });

  _exports.default = _default;
});