define("epcr-uis/components/navigation-menu", ["exports", "ember-components/components/navigation-menu"], function (_exports, _navigationMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _navigationMenu.default;
    }
  });
});