define("epcr-uis/pods/verified-pcr/adapter", ["exports", "epcr-uis/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    model: "verified-pcrs",

    urlForFindAll() {
      return this.mainPath(this.model);
    }

  });

  _exports.default = _default;
});