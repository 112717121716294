define("epcr-uis/pods/print/service", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fetchTaskIsRunning = "fetchTask.isRunning";

  var _default = Ember.Service.extend({
    simpleAuthFetch: Ember.inject.service(),
    isPrinting: false,
    isPrintingNotAllowed: Ember.computed(fetchTaskIsRunning, "printingTask.isRunning", "isPrinting", "printerStatus", function () {
      return this.isNotAllowedPrinting();
    }),
    isViewNotAllowed: Ember.computed(fetchTaskIsRunning, function () {
      return Ember.get(this, "fetchTask.isRunning");
    }),

    generatePNG(source) {
      return Ember.set(this, "fetchTask", this.fetchBase64FileTask.perform(source, "png"));
    },

    printPDF(source) {
      return this.printingTask.perform(source, "pdf");
    },

    printingTask: (0, _emberConcurrency.task)(function* (source, format) {
      Ember.set(this, "isPrinting", true);
      return yield this.fetchBase64FileTask.perform(source, format).then(base64Data => this.sendBase64PdfDataToDevice(base64Data)).catch(e => this.throwError(e)).finally(() => Ember.set(this, "isPrinting", false));
    }).restartable(),
    fetchBase64FileTask: (0, _emberConcurrency.task)(function* (source, format) {
      this.clearErrors();
      return yield source(format).then(response => response.text()).catch(e => this.throwError(e));
    }).restartable(),

    throwError(e) {
      throw e;
    },

    clearErrors() {
      if (Ember.get(this.printingTask, "last.error")) Ember.set(this.printingTask, "last.error", null);
      if (Ember.get(this, "fetchTask.error")) Ember.set(this.fetchTask, "error", null);
    }

  });

  _exports.default = _default;
});