define("epcr-uis/pods/components/pcr-document/printer-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ot69rW9b",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",true],[11,\"class\",[29,[[24,[\"printerStatusData\",\"iconColor\"]],\" print icon\"]]],[8],[9],[0,\"\\n\"],[7,\"span\",true],[8],[1,[24,[\"printerStatusData\",\"title\"]],false],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[28,\"eq\",[[24,[\"printerStatusData\",\"iconColor\"]],\"red\"],null],\"red\",\"yellow\"],null],\" printer-message\"]]],[8],[0,\"\\n  \"],[1,[24,[\"printerStatusData\",\"message\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/printer-status/template.hbs"
    }
  });

  _exports.default = _default;
});