define("epcr-uis/pods/components/pcr-document/flowchart/recommendation-input/input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    "data-test-id": "input",
    tagName: "span"
  });

  _exports.default = _default;
});