define("epcr-uis/helpers/validate-flowchart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateFlowchart = validateFlowchart;
  _exports.default = void 0;

  function validateFlowchart([flowchartMatch, errors]) {
    const flowchartErrors = {};
    if (!errors || !flowchartMatch) return flowchartErrors;
    const errorsKeys = Object.keys(errors);
    errorsKeys.forEach(key => {
      if (errors[key].match === flowchartMatch) flowchartErrors[key] = errors[key];
    });
    return flowchartErrors;
  }

  var _default = Ember.Helper.helper(validateFlowchart);

  _exports.default = _default;
});