define("epcr-uis/pods/components/pcr-routes/attachments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3mnhsP2L",
    "block": "{\"symbols\":[\"Attachments\"],\"statements\":[[5,\"route-content-loader\",[],[[\"@promise\"],[[22,\"attachmentsLoadPromise\"]]],{\"statements\":[[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"pcr-document/pcr-attachments\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@pcrId\"],[[24,[\"pcr\",\"id\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-routes/attachments/template.hbs"
    }
  });

  _exports.default = _default;
});