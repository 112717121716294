define("epcr-uis/pods/components/pcr-error/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    "data-test-id": "pcr-error",
    classNames: ["pcr-error scrollable-content"],
    router: Ember.inject.service(),
    showTopNavigation: true,
    actions: {
      transitionToRoute(route) {
        return this.router.transitionTo(route);
      }

    }
  });

  _exports.default = _default;
});