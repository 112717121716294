define("epcr-uis/pods/components/pcr-navigation/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCR_VALIDATION_GROUPS = "pcrValidation.groups";

  var _default = Ember.Component.extend({
    "data-test-id": "pcr-navigation-item",
    validationsList: Ember.computed(PCR_VALIDATION_GROUPS, function () {
      const validationGroups = Ember.get(this, PCR_VALIDATION_GROUPS);
      return validationGroups ? validationGroups[this.group] : [];
    }),
    validations: Ember.computed(PCR_VALIDATION_GROUPS, function () {
      const {
        validationsList
      } = this;
      const errorsCount = this.countNotifications(validationsList, "error").length;
      if (errorsCount) return {
        type: "error",
        count: errorsCount
      };
      const warningsCount = this.countNotifications(validationsList, "warning").length;
      if (warningsCount) return {
        type: "warning",
        count: warningsCount
      };
      return null;
    }),

    countNotifications(list, group) {
      return list ? list.filter(element => element.type === group) : [];
    }

  });

  _exports.default = _default;
});