define("epcr-uis/helpers/count-age-in-months", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countAgeInMonths = countAgeInMonths;

  const getAge = age => {
    if (age) {
      const ageUnit = Ember.get(age, "age");
      const measure = Ember.get(age, "measure.code");

      switch (measure) {
        case "YEARS":
          return ageUnit * 12;

        case "MONTHS":
          return ageUnit;

        case "DAYS":
          return Math.floor(ageUnit / 30);
      }
    }

    return null;
  };

  const fromBirthDate = (birthDate, callTime, timeService) => birthDate && callTime && timeService.getTimeDifferenceBetweenDatesInMonth(birthDate, callTime);

  function countAgeInMonths([timeService, patientDetails, callTime]) {
    if (patientDetails) {
      const age = Ember.get(patientDetails, "age");
      return age && Ember.get(age, "approximate") ? getAge(age) : fromBirthDate(Ember.get(patientDetails, "birthDate"), callTime, timeService);
    }

    return null;
  }
});