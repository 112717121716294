define("epcr-uis/pods/pcr-classificator/medicaments/vilkaviskis", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VILKAVISKIS_MEDICAMENTS = void 0;

  const VILKAVISKIS_MEDICAMENTS = t => ({
    stations: ["55"],
    medicaments: [(0, _medicamentsList.MEDICAMENTS)(t)[207], (0, _medicamentsList.MEDICAMENTS)(t)[208], (0, _medicamentsList.MEDICAMENTS)(t)[245], (0, _medicamentsList.MEDICAMENTS)(t)[275], (0, _medicamentsList.MEDICAMENTS)(t)[276], (0, _medicamentsList.MEDICAMENTS)(t)[277], (0, _medicamentsList.MEDICAMENTS)(t)[290]]
  });

  _exports.VILKAVISKIS_MEDICAMENTS = VILKAVISKIS_MEDICAMENTS;
});