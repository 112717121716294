define("epcr-uis/helpers/starts-with", ["exports", "ember-components/helpers/starts-with"], function (_exports, _startsWith) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _startsWith.default;
    }
  });
  Object.defineProperty(_exports, "startsWith", {
    enumerable: true,
    get: function () {
      return _startsWith.startsWith;
    }
  });
});