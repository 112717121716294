define("epcr-uis/pods/components/pcr-document/flowchart/input/condition/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: "condition",
    flowchart: Ember.inject.service("pcr/flowchart"),
    condition: Ember.computed("conditionChange", function () {
      return this.flowchart.conditionMap[this.conditionChange || "empty"];
    })
  });

  _exports.default = _default;
});