define("epcr-uis/mirage/serializers/transportation", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.RestSerializer.extend({
    embed: true,
    include: ["patientToVehicle", "route", "position", "hospitalSelectionCriteria", "hospitalDepartment"]
  });

  _exports.default = _default;
});