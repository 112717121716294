define("epcr-uis/helpers/event-id-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eventIdFormatter = eventIdFormatter;
  _exports.default = void 0;

  const isNull = value => value == null;

  function eventIdFormatter([eventId, missionId, patientId]) {
    if (isNull(eventId) || isNull(missionId) || isNull(patientId)) {
      return "";
    }

    return `(${eventId}-${missionId}-${patientId})`;
  }

  var _default = Ember.Helper.helper(eventIdFormatter);

  _exports.default = _default;
});