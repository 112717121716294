define("epcr-uis/pods/components/gender-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ECjbL527",
    "block": "{\"symbols\":[\"@gender\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],\"MALE\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[29,[\"mars \",[22,\"size\"],\" blue icon\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],\"FEMALE\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[29,[\"venus \",[22,\"size\"],\" pink icon\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/gender-icon/template.hbs"
    }
  });

  _exports.default = _default;
});