define("epcr-uis/pods/pcr/call-information/event-site/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    address: attr("string"),
    addressDetails: (0, _attributes.fragment)("address"),
    policeInformed: attr("boolean"),
    fireDepInformed: attr("boolean"),
    city: attr("boolean")
  });

  _exports.default = _default;
});