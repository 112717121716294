define("epcr-uis/pods/application/route", ["exports", "epcr-uis/mixins/accepting-token-route"], function (_exports, _acceptingTokenRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let isRedirected = false;
  const loadConfig = "load-config";

  var _default = Ember.Route.extend(_acceptingTokenRoute.default, {
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    configsService: Ember.inject.service(loadConfig),
    userPreferences: Ember.inject.service(),

    shouldRedirect() {
      return this.session.isAuthenticated && !isRedirected;
    },

    async afterModel() {
      !this.features.isEnabled(loadConfig) && this.session.isAuthenticated && (await this.userPreferences.loadUserPreferences());
    },

    redirect(model, transition) {
      if (this.features.isEnabled(loadConfig) && this.shouldRedirect()) {
        transition.abort();
        isRedirected = true;
        this.configsService.previousTransition = transition;
        this.transitionTo(loadConfig);
      }
    }

  });

  _exports.default = _default;
});