define("epcr-uis/pods/components/pcr-document/vitals-quick-scroll/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pYM4ci9E",
    "block": "{\"symbols\":[\"buttons\",\"button\",\"@assessmentType\"],\"statements\":[[4,\"with\",[[28,\"hash\",null,[[\"quick\"],[[28,\"if\",[[28,\"eq\",[[23,3,[]],\"AT_ER\"],null],[24,[\"quickButtonsAtEr\"]],[24,[\"quickButtons\"]]],null]]]]],null,{\"statements\":[[4,\"each\",[[23,1,[\"quick\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui basic button quick-scroll-button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"scrollToHeader\",[23,2,[\"headerContainer\"]]],null]],[8],[0,\"\\n      \"],[7,\"i\",true],[11,\"class\",[23,2,[\"iconClass\"]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/vitals-quick-scroll/template.hbs"
    }
  });

  _exports.default = _default;
});