define("epcr-uis/mirage/models/gcs", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _emberCliMirage.Model.extend({
    eyeResponse: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR),
    verbalResponse: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR),
    motorResponse: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR)
  });

  _exports.default = _default;
});