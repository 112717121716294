define("epcr-uis/pods/pcr-classificator/calculator/pediatric/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    cells: {
      precocious: {
        weight: [1, 2, 2],
        systolicBp: "46-64",
        pulse: "120-170",
        respiratoryRate: "40-70",
        etTubeDiameter: "2,5-3,0",
        etTubeLength: "6-8",
        laryngoscopeBlade: "0",
        intubationPresenter: "6",
        ambuBagVolume: "450",
        larynxMask: "1",
        respiratoryVolumeDPV: "16-24",
        suctionCatheter: "6",
        intravenousCatheter: "24",
        oneDefibrillation: "3",
        twoDefibrillation: "6",
        cardioversion: "2",
        resuscitation: "3:1"
      },
      newBorn: {
        weight: [3, 4, 4],
        systolicBp: "60-74",
        pulse: "110-160",
        respiratoryRate: "30-60",
        etTubeDiameter: "3,5",
        etTubeLength: "9-11",
        laryngoscopeBlade: "1",
        intubationPresenter: "6",
        ambuBagVolume: "450",
        larynxMask: "1",
        respiratoryVolumeDPV: "24-36",
        suctionCatheter: "6",
        intravenousCatheter: "22-24",
        oneDefibrillation: "7",
        twoDefibrillation: "14",
        cardioversion: "3",
        resuscitation: "3:1"
      },
      threeMonths: {
        weight: [5, 6, 6],
        systolicBp: "78-102",
        pulse: "100-150",
        respiratoryRate: "30-50",
        etTubeDiameter: "3,5-4,0",
        etTubeLength: "10-12",
        laryngoscopeBlade: "1",
        intubationPresenter: "6",
        ambuBagVolume: "450",
        larynxMask: "1,5",
        respiratoryVolumeDPV: "50-70",
        suctionCatheter: "8",
        intravenousCatheter: "22-24",
        oneDefibrillation: "13",
        twoDefibrillation: "26",
        cardioversion: "6",
        resuscitation: "3:1"
      },
      sixMonths: {
        weight: [7, 8, 8],
        systolicBp: "82-104",
        pulse: "90-130",
        respiratoryRate: "24-36",
        etTubeDiameter: "3,5-4,0",
        etTubeLength: "10-12",
        laryngoscopeBlade: "1",
        intubationPresenter: "6",
        ambuBagVolume: "450",
        larynxMask: "1,5",
        respiratoryVolumeDPV: "60-100",
        suctionCatheter: "8",
        intravenousCatheter: "22-24",
        oneDefibrillation: "15",
        twoDefibrillation: "30",
        cardioversion: "8",
        resuscitation: "3:1"
      },
      nineMonths: {
        weight: [8, 9, 10],
        systolicBp: "82-104",
        pulse: "90-130",
        respiratoryRate: "24-36",
        etTubeDiameter: "4,0",
        etTubeLength: "11-12",
        laryngoscopeBlade: "1",
        intubationPresenter: "6",
        ambuBagVolume: "450",
        larynxMask: "1,5",
        respiratoryVolumeDPV: "70-110",
        suctionCatheter: "8",
        intravenousCatheter: "22-24",
        oneDefibrillation: "18",
        twoDefibrillation: "36",
        cardioversion: "9",
        resuscitation: "3:1"
      },
      twelveMonths: {
        weight: [9, 10, 11],
        systolicBp: "86-104",
        pulse: "90-124",
        respiratoryRate: "20-30",
        etTubeDiameter: "4,0",
        etTubeLength: "11-12",
        laryngoscopeBlade: "1",
        intubationPresenter: "6",
        ambuBagVolume: "450",
        larynxMask: "2",
        respiratoryVolumeDPV: "80-120",
        suctionCatheter: "8-10",
        intravenousCatheter: "20-24",
        oneDefibrillation: "20",
        twoDefibrillation: "40",
        cardioversion: "10",
        resuscitation: "3:1-15:2"
      },
      fifteenMonths: {
        weight: [10, 11, 12],
        systolicBp: "86-104",
        pulse: "90-124",
        respiratoryRate: "20-30",
        etTubeDiameter: "4,0",
        etTubeLength: "12",
        laryngoscopeBlade: "1",
        intubationPresenter: "6",
        ambuBagVolume: "450",
        larynxMask: "2",
        respiratoryVolumeDPV: "90-130",
        suctionCatheter: "8-10",
        intravenousCatheter: "20-24",
        oneDefibrillation: "22",
        twoDefibrillation: "44",
        cardioversion: "11",
        resuscitation: "3:1-15:2"
      },
      eighteenMonths: {
        weight: [11, 11, 12],
        systolicBp: "86-104",
        pulse: "90-124",
        respiratoryRate: "20-30",
        etTubeDiameter: "4,0",
        etTubeLength: "12",
        laryngoscopeBlade: "1",
        intubationPresenter: "6",
        ambuBagVolume: "450",
        larynxMask: "2",
        respiratoryVolumeDPV: "90-140",
        suctionCatheter: "8-10",
        intravenousCatheter: "20-24",
        oneDefibrillation: "23",
        twoDefibrillation: "46",
        cardioversion: "11",
        resuscitation: "3:1-15:2"
      },
      twoYears: {
        weight: [12, 12, 13],
        systolicBp: "88-106",
        pulse: "90-120",
        respiratoryRate: "20-30",
        etTubeDiameter: "4,5",
        etTubeLength: "12-13",
        laryngoscopeBlade: "1-2",
        intubationPresenter: "6",
        ambuBagVolume: "450",
        larynxMask: "2",
        respiratoryVolumeDPV: "100-150",
        suctionCatheter: "10",
        intravenousCatheter: "18-22",
        oneDefibrillation: "25",
        twoDefibrillation: "50",
        cardioversion: "12",
        resuscitation: "15:2"
      },
      threeYears: {
        weight: [13, 14, 15],
        systolicBp: "88-110",
        pulse: "80-120",
        respiratoryRate: "20-30",
        etTubeDiameter: "4,5",
        etTubeLength: "14",
        laryngoscopeBlade: "1-2",
        intubationPresenter: "6",
        ambuBagVolume: "450-750",
        larynxMask: "2",
        respiratoryVolumeDPV: "110-170",
        suctionCatheter: "10",
        intravenousCatheter: "18-22",
        oneDefibrillation: "28",
        twoDefibrillation: "56",
        cardioversion: "14",
        resuscitation: "15:2"
      },
      fourYears: {
        weight: [15, 16, 18],
        systolicBp: "88-110",
        pulse: "80-120",
        respiratoryRate: "20-30",
        etTubeDiameter: "5,0",
        etTubeLength: "15",
        laryngoscopeBlade: "2",
        intubationPresenter: "6",
        ambuBagVolume: "450-750",
        larynxMask: "2",
        respiratoryVolumeDPV: "130-190",
        suctionCatheter: "10",
        intravenousCatheter: "18-22",
        oneDefibrillation: "33",
        twoDefibrillation: "66",
        cardioversion: "16",
        resuscitation: "15:2"
      },
      fiveYears: {
        weight: [17, 18, 20],
        systolicBp: "88-110",
        pulse: "80-120",
        respiratoryRate: "20-30",
        etTubeDiameter: "5,0",
        etTubeLength: "15",
        laryngoscopeBlade: "2",
        intubationPresenter: "14",
        ambuBagVolume: "750-1000",
        larynxMask: "2-2,5",
        respiratoryVolumeDPV: "140-220",
        suctionCatheter: "10",
        intravenousCatheter: "18-20",
        oneDefibrillation: "37",
        twoDefibrillation: "74",
        cardioversion: "18",
        resuscitation: "15:2"
      },
      sixYears: {
        weight: [19, 21, 23],
        systolicBp: "92-114",
        pulse: "70-110",
        respiratoryRate: "16-24",
        etTubeDiameter: "5,5",
        etTubeLength: "16",
        laryngoscopeBlade: "2",
        intubationPresenter: "14",
        ambuBagVolume: "750-1000",
        larynxMask: "2-2,5",
        respiratoryVolumeDPV: "150-230",
        suctionCatheter: "10",
        intravenousCatheter: "18-20",
        oneDefibrillation: "42",
        twoDefibrillation: "84",
        cardioversion: "21",
        resuscitation: "15:2"
      },
      eightYears: {
        weight: [23, 26, 29],
        systolicBp: "96-114",
        pulse: "70-100",
        respiratoryRate: "12-24",
        etTubeDiameter: "6,0",
        etTubeLength: "18",
        laryngoscopeBlade: "2",
        intubationPresenter: "14",
        ambuBagVolume: "750-1000",
        larynxMask: "2,5",
        respiratoryVolumeDPV: "210-310",
        suctionCatheter: "10",
        intravenousCatheter: "18-20",
        oneDefibrillation: "52",
        twoDefibrillation: "104",
        cardioversion: "26",
        resuscitation: "15:2"
      },
      tenYears: {
        weight: [29, 32, 37],
        systolicBp: "96-120",
        pulse: "60-90",
        respiratoryRate: "12-20",
        etTubeDiameter: "6,5",
        etTubeLength: "19",
        laryngoscopeBlade: "2-3",
        intubationPresenter: "14",
        ambuBagVolume: "1000",
        larynxMask: "3",
        respiratoryVolumeDPV: "260-390",
        suctionCatheter: "12",
        intravenousCatheter: "16-20",
        oneDefibrillation: "66",
        twoDefibrillation: "132",
        cardioversion: "32",
        resuscitation: "15:2-30:2"
      },
      twelveYears: {
        weight: [36, 41, 47],
        systolicBp: "100-130",
        pulse: "60-90",
        respiratoryRate: "12-20",
        etTubeDiameter: "7,0",
        etTubeLength: "21",
        laryngoscopeBlade: "2-3",
        intubationPresenter: "14",
        ambuBagVolume: "1000",
        larynxMask: "3",
        respiratoryVolumeDPV: "330-490",
        suctionCatheter: "12",
        intravenousCatheter: "16-20",
        oneDefibrillation: "83",
        twoDefibrillation: "166",
        cardioversion: "41",
        resuscitation: "30:2"
      },
      fourteenYears: {
        weight: [44, 50, 57],
        systolicBp: "110-130",
        pulse: "60-90",
        respiratoryRate: "12-20",
        etTubeDiameter: "7,5",
        etTubeLength: "21",
        laryngoscopeBlade: "3",
        intubationPresenter: "14",
        ambuBagVolume: "1000",
        larynxMask: "3",
        respiratoryVolumeDPV: "400-600",
        suctionCatheter: "12",
        intravenousCatheter: "16-20",
        oneDefibrillation: "101",
        twoDefibrillation: "200",
        cardioversion: "50",
        resuscitation: "30:2"
      },
      sixteenYears: {
        weight: [52, 57, 65],
        systolicBp: "110-130",
        pulse: "60-90",
        respiratoryRate: "12-20",
        etTubeDiameter: "7,5",
        etTubeLength: "22",
        laryngoscopeBlade: "3-4",
        intubationPresenter: "14",
        ambuBagVolume: "1000",
        larynxMask: "4",
        respiratoryVolumeDPV: "460-680",
        suctionCatheter: "12",
        intravenousCatheter: "16-20",
        oneDefibrillation: "117",
        twoDefibrillation: "200",
        cardioversion: "57",
        resuscitation: "30:2"
      }
    }
  });

  _exports.default = _default;
});