define("epcr-uis/pods/components/training-blocks/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DJVxdCSv",
    "block": "{\"symbols\":[\"trainingCase\",\"index\"],\"statements\":[[5,\"message-box\",[[12,\"class\",\"training-message\"]],[[\"@type\",\"@description\"],[\"positive\",[28,\"t\",[\"headings.training.title\"],null]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"  \"],[5,\"message-box\",[],[[\"@type\",\"@description\"],[\"negative\",[28,\"translate-error\",[[24,[\"errorMessage\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[24,[\"queryTrainingSituation\",\"isIdle\"]]],null,{\"statements\":[[0,\"  \"],[5,\"fullscreen-loader\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui stackable three column grid block\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"trainingCases\"]]],null,{\"statements\":[[0,\"      \"],[5,\"side-bar/block-input\",[[12,\"class\",\"column\"]],[[\"@title\",\"@id\",\"@click\"],[[28,\"concat\",[[28,\"inc\",[[23,2,[]]],null],\". \",[28,\"t\",[[23,1,[\"translationKey\"]]],null]],null],[23,1,[\"id\"]],[28,\"action\",[[23,0,[]],[28,\"pipe\",[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"pcrRouteTracker\",\"lastVisitedPcrList\"]]],null],\"training\"],null],[28,\"perform\",[[24,[\"queryTrainingSituation\"]],[23,1,[\"id\"]]],null]],null]],null]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/training-blocks/template.hbs"
    }
  });

  _exports.default = _default;
});