define("epcr-uis/pods/components/pcr-timelines/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "66fQ2mic",
    "block": "{\"symbols\":[\"@timeline\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"timelines\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"redirectTo\",[23,1,[]]],null]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"time\"],[8],[1,[28,\"moment-format\",[[23,1,[\"time\"]],\"HH:mm\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[1,[28,\"t\",[[28,\"concat\",[\"timelines.\",[23,1,[\"name\"]]],null]],null],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-timelines/template.hbs"
    }
  });

  _exports.default = _default;
});