define("epcr-uis/mirage/factories/my-pcr", ["exports", "epcr-uis/mirage/factories/people", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _people, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _people.default.extend({
    pcrId: i => i ? `12345678910-1-${i}` : "1",
    sex: "MALE",
    weight: 55,
    category: 1,
    teamId: 9991,
    address: () => (0, _isEnglish.isEnglish)() ? "Griunvaldo st. 151A-44, Kaunas., Kauno city eldership, Kaunas mun., Angry dog at entrance" : "Griunvaldo g. 151A-44, Kauno m., Kauno m. sen., Kauno m. sav., Yra piktas suo",
    callTime: _emberCliMirage.faker.date.recent(),
    registrationTime: _emberCliMirage.faker.date.recent(),
    owner: 1,
    arrivedTime: i => i % 2 === 0 ? _emberCliMirage.faker.date.recent() : null,
    state: "IMPORTED"
  });

  _exports.default = _default;
});