define("epcr-uis/pods/components/pcr-document/transportation/disposition/component", ["exports", "epcr-uis/helpers/go-to-next-input"], function (_exports, _goToNextInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    "data-test-id": "disposition-block",
    pcrClassificator: Ember.inject.service(),
    features: Ember.inject.service(),
    closeSidebarOnSelect: Ember.computed(function () {
      return !Ember.get(this.features, "rapidInputMode");
    }),
    patientToVehicleFavourites: Ember.computed(function () {
      const items = JSON.parse(JSON.stringify(this.pcrClassificator.patientToVehicle));
      return [{
        name: items[1].name,
        value: [items[1]]
      }, {
        name: "Nuneštas gulintis neštuvais",
        value: [items[2], items[4], items[5]]
      }];
    }),

    openNextElement(nextSelectorsOrder) {
      Ember.get(this.features, "rapidInputMode") && Ember.run.next(() => {
        for (let i = 0; i < nextSelectorsOrder.length; i++) {
          const nextElement = this.element.closest("[sidebar-switcher-scope]").querySelector(nextSelectorsOrder[i]);

          if (nextElement) {
            const sidebarCloseElement = this.element.querySelector(".popup-top .submit-button");

            if (sidebarCloseElement) {
              sidebarCloseElement.click();
              nextElement.click();
            }

            break;
          }
        }
      });
    },

    actions: {
      onTransportedOnSelected() {
        const nextSelectorsOrder = ['[sidebar-index="10"] .input', '[sidebar-index="8"] .hidden-sidebar-opener', '[sidebar-index="11"] .input'];
        this.openNextElement(nextSelectorsOrder);
      },

      goToNextInput() {
        (0, _goToNextInput.goToNextInput)(this.features);
      }

    }
  });

  _exports.default = _default;
});