define("epcr-uis/pods/simple-auth-fetch/service", ["exports", "fetch", "epcr-uis/pods/pcr/backend-serialize", "epcr-uis/config/environment"], function (_exports, _fetch, _backendSerialize, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),

    fetchRequest(url, method, body = undefined, contentType = "application/json", stringifyBody = true) {
      return this._sendRequest(url, method, body, contentType, stringifyBody).then(async response => {
        const {
          statusText
        } = response;

        if (response.status < 200 || response.status > 300) {
          throw await response.json().then(body => body.errors[0].code).catch(() => statusText);
        }

        return response;
      }).catch(e => {
        if (e && e.message === "Network request failed") throw "NO_INTERNET_CONNECTION";else throw e;
      });
    },

    _sendRequest(url, method, body, contentType, stringifyBody) {
      body = stringifyBody ? JSON.stringify(body) : body;
      return (0, _fetch.default)(url, {
        method,
        body,
        headers: this.setupHeader(url, contentType)
      });
    },

    setupHeader(url, contentType) {
      const {
        token
      } = this.get("session.data.authenticated");
      return {
        [_environment.default["ember-simple-auth-token"].authorizationHeaderName]: `Bearer ${token}`,
        "Content-Type": contentType
      };
    },

    getSerializedPcrData(pcr) {
      const pcrData = pcr.toJSON();
      pcrData.id = Ember.get(pcr, "id");
      (0, _backendSerialize.getSerializedPcr)(pcrData);
      return pcrData;
    }

  });

  _exports.default = _default;
});