define("epcr-uis/pods/components/active-pcrs/filter/component", ["exports", "epcr-uis/mixins/metrics-tracker"], function (_exports, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    classNames: ["active-pcr-list-filter"],
    androidSignal: Ember.inject.service(),
    android: Ember.inject.service(),
    team: Ember.inject.service(),
    can: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.android.isAndroid() && this.selectedTeam && this.androidSignal.setTeamId(this.selectedTeam.code);
    },

    actions: {
      onTeamSelected(selectedTeam) {
        const teamCode = selectedTeam && selectedTeam.code;
        this.team.changeTeamAndIncrementOnChange(teamCode);
        Ember.setProperties(this, {
          selectedTeam,
          isFilterPopupOpened: false
        });
        this.filterTask.perform();

        if (this.android.isAndroid()) {
          this.androidSignal.setTeamId(teamCode);
          this.trackEvent("Team", "Set/changed team id", teamCode);
        }
      },

      onFilterClicked() {
        if (this.can.cannot("changeTeam team")) {
          Ember.set(this, "showCannotChangeTeamModal", true);
          this.trackEvent("Team change", "Cannot change team error");
        } else {
          Ember.set(this, "isFilterPopupOpened", true);
        }
      }

    }
  });

  _exports.default = _default;
});