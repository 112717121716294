define("epcr-uis/mirage/serializers/user-preferences", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.RestSerializer.extend({
    serialize() {
      const {
        userPreferences
      } = _emberCliMirage.RestSerializer.prototype.serialize.apply(this, arguments);

      return userPreferences;
    }

  });

  _exports.default = _default;
});