define("epcr-uis/pods/pcr/adapter", ["exports", "epcr-uis/pods/application/adapter", "epcr-uis/config/environment"], function (_exports, _adapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const addQueryParamToUrl = (url, queryParam, adapterOptions) => adapterOptions && adapterOptions[queryParam] ? `${url}?${queryParam}=true` : url;

  const updatePcrUrl = id => _environment.default.APP.updatePcrUrl.replace(":id", id);

  var _default = _adapter.default.extend({
    urlForUpdateRecord: (id, modelName, {
      adapterOptions
    }) => addQueryParamToUrl(updatePcrUrl(id), "temporary", adapterOptions)
  });

  _exports.default = _default;
});