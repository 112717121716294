define("epcr-uis/pods/components/confirm-modal/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fqQO0m8A",
    "block": "{\"symbols\":[\"@className\",\"@action\"],\"statements\":[[7,\"button\",true],[11,\"class\",[29,[\"large ui \",[23,1,[]],\" button\"]]],[11,\"onclick\",[23,2,[]]],[8],[0,\"\\n  \"],[1,[28,\"t\",[[24,[\"textKey\"]]],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/confirm-modal/button/template.hbs"
    }
  });

  _exports.default = _default;
});