define("epcr-uis/pods/pcr/agreements-signatures/hospital-doctor-signature/service", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    simpleAuthFetch: Ember.inject.service(),

    extractHospitalId(hospitalDepartmentCode) {
      return hospitalDepartmentCode.split("-")[0];
    },

    generateUrl(hospitalDepartmentCode, query) {
      return `${_environment.default.APP.hospitalDoctorUrl}?hospitalCode=${this.extractHospitalId(hospitalDepartmentCode)}&query=${query}`;
    },

    queryDoctors(hospitalDepartmentCode, query) {
      if (hospitalDepartmentCode && query) {
        const url = this.generateUrl(hospitalDepartmentCode, query);
        return this.simpleAuthFetch.fetchRequest(url, "GET").then(response => response.json());
      }

      return new Ember.RSVP.Promise(resolve => resolve([]));
    }

  });

  _exports.default = _default;
});