define("epcr-uis/pods/pcr/call-information/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    callTakerCode: attr("string"),
    phoneNumber1: attr("string"),
    phoneNumber2: attr("string"),
    phoneNumber3: attr("string"),
    eventSite: (0, _attributes.fragment)("pcr/call-information/event-site"),
    emergencyCallDetails: attr("string")
  });

  _exports.default = _default;
});