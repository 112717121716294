define("epcr-uis/pods/components/config/control-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5IhCqVN/",
    "block": "{\"symbols\":[\"@description\",\"@title\",\"@onClick\",\"@icon\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"title-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"secondary-text\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"button\",true],[10,\"class\",\"ui primary basic icon button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,3,[]]],null]],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[[23,4,[]],\" icon\"]]],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/config/control-button/template.hbs"
    }
  });

  _exports.default = _default;
});