define("epcr-uis/pods/pcr/patient-assessment/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    time: attr("date"),
    type: (0, _attributes.fragment)("pcr-classificator"),
    vitals: (0, _attributes.fragment)("pcr/patient-assessment/vitals"),
    breathing: (0, _attributes.fragment)("pcr/patient-assessment/breathing"),
    circulation: (0, _attributes.fragment)("pcr/patient-assessment/circulation"),
    consciousness: (0, _attributes.fragment)("pcr/patient-assessment/consciousness"),
    ecg: (0, _attributes.fragment)("pcr/patient-assessment/ecg"),
    isBreathingAtErFieldsEmpty: Ember.computed("vitals.{respiratoryRate,spO2}", function () {
      const {
        respiratoryRate,
        spO2
      } = this.vitals;
      return !respiratoryRate && !spO2;
    }),
    isBreathingFieldsEmpty: Ember.computed("breathing.isNotFilled", "isBreathingAtErFieldsEmpty", function () {
      return this.breathing.isNotFilled && this.isBreathingAtErFieldsEmpty;
    }),
    isBloodFlowAtErFieldsEmpty: Ember.computed("vitals.{bpSystolic,bpDiastolic,pulse,cp}", function () {
      const {
        bpSystolic,
        bpDiastolic,
        pulse,
        cp
      } = this.vitals;
      return !bpSystolic && !bpDiastolic && !pulse && !cp;
    }),
    isBloodFlowFieldsEmpty: Ember.computed("isBloodFlowAtErFieldsEmpty", "circulation.radialArteryPulse", "circulation.crtLte2Sec", function () {
      const {
        radialArteryPulse,
        crtLte2Sec
      } = this.circulation;
      return this.isBloodFlowAtErFieldsEmpty && !radialArteryPulse && !crtLte2Sec;
    }),
    isEyesFieldsEmpty: Ember.computed("consciousness.isNotFilled", function () {
      return this.consciousness.isNotFilled;
    }),
    isPainAtErFieldsEmpty: Ember.computed("vitals.pain", function () {
      return !this.vitals.pain;
    }),
    isPainFieldsEmpty: Ember.computed("isPainAtErFieldsEmpty", "consciousness.meningealSignals", function () {
      return this.isPainAtErFieldsEmpty && !this.consciousness.meningealSignals;
    }),
    isConsciousnessFieldsEmpty: Ember.computed("consciousness.gcs.isFilled", "consciousness.avpu.code", function () {
      return this.consciousness.gcs.isNotFilled && !this.consciousness.avpu;
    }),
    isSkinFieldsEmpty: Ember.computed("circulation.skinColor", "circulation.skinTemperature", "vitals.temperature", "circulation.skinCondition", "circulation.rash", function () {
      const {
        isNotFilled,
        rash
      } = this.circulation;
      return isNotFilled && !rash && !this.vitals.temperature;
    }),
    isGlucoseFieldsEmpty: Ember.computed("vitals.glucose", function () {
      return !this.vitals.glucose;
    }),
    isEcgFieldsEmpty: Ember.computed("ecg.heartRhythm", "ecg.stSegment", function () {
      const {
        heartRhythm,
        stSegment
      } = this.ecg;
      return !heartRhythm && !stSegment;
    }),
    isEmpty: Ember.computed("isBreathingFieldsEmpty", "isBloodFlowFieldsEmpty", "isEyesFieldsEmpty", "isPainFieldsEmpty", "isConsciousnessFieldsEmpty", "isSkinFieldsEmpty", "isGlucoseFieldsEmpty", "isEcgFieldsEmpty", "time", function () {
      return this.isBreathingFieldsEmpty && this.isBloodFlowFieldsEmpty && this.isEyesFieldsEmpty && this.isPainFieldsEmpty && this.isConsciousnessFieldsEmpty && this.isSkinFieldsEmpty && this.isGlucoseFieldsEmpty && this.isEcgFieldsEmpty && !this.time;
    })
  });

  _exports.default = _default;
});