define("epcr-uis/pods/components/used-medicaments/filter/component", ["exports", "epcr-uis/mixins/metrics-tracker", "epcr-uis/constants"], function (_exports, _metricsTracker, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    userPreferences: Ember.inject.service(),
    login: Ember.inject.service(),

    saveFilterOptionsToUserStorage(key, value) {
      this.userPreferences.updateKeyValue(key, value);
    },

    actions: {
      onShiftDurationSelected(shiftDuration) {
        Ember.setProperties(this, {
          selectedShiftDuration: shiftDuration,
          isFilterPopupOpened: false
        });
        const value = shiftDuration && shiftDuration.code;
        value && this.filterTask.perform();
        this.saveFilterOptionsToUserStorage(_constants.SELECTED_SHIFT_DURATION, value);
        const user = Ember.get(this, "login.employeeData.userName");
        this.trackEvent("Used medicaments", value, `Station [${this.login.stationId}] User [${user}]`);
      }

    }
  });

  _exports.default = _default;
});