define("epcr-uis/pods/components/pcr-document/specialty-patient-forms/event-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend();

  _exports.default = _default;
});