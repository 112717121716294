define("epcr-uis/pods/components/pcr-routes/transportation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RADy9zdC",
    "block": "{\"symbols\":[\"Transportation\",\"@pcr\"],\"statements\":[[5,\"route-content-loader\",[],[[\"@promise\"],[[22,\"transportationLoadPromise\"]]],{\"statements\":[[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"pcr-document/transportation\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@pcr\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-routes/transportation/template.hbs"
    }
  });

  _exports.default = _default;
});