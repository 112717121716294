define("epcr-uis/pods/components/pcr-list/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    "data-test-id": "pcr-list",
    classNames: ["pcr-container"],
    classNameBindings: ["isLocked:locked"],
    pcrClassificator: Ember.inject.service(),

    click() {
      this.onPcrClicked(this.pcr.id, this.isLocked);
    }

  });

  _exports.default = _default;
});