define("epcr-uis/mirage/factories/age", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    age: () => _emberCliMirage.faker.random.number(99),
    approximate: true
  });

  _exports.default = _default;
});