define("epcr-uis/pods/pcr-recommendation-translator/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const buildRecommendationPath = recommendation => `recommendation.${recommendation}`;

  var _default = Ember.Service.extend({
    THIN_BLOOD: buildRecommendationPath("thinBlood"),
    PAIN_RELIEVER: buildRecommendationPath("painReliever"),
    HEART_ATTACK: buildRecommendationPath("heartAttack"),
    STROKE: buildRecommendationPath("stroke"),
    POLYTRAUMA: buildRecommendationPath("polytrauma"),
    RESUSCITATION_ASYSTOLE: buildRecommendationPath("resuscitationAsystole"),
    RESUSCITATION_VENTRICULAR_FIBRILLATION: buildRecommendationPath("resuscitationVentricularFibrillation"),
    SOFT_TISSUE_INJURY: buildRecommendationPath("softTissue"),
    SWELLING_INJURY: buildRecommendationPath("swelling"),
    SPO2_DEFICIENCY: buildRecommendationPath("spO2"),
    HYPOTENSION: buildRecommendationPath("hypotension"),
    GLUCOSE_TOO_LOW: buildRecommendationPath("glucose"),
    GLYCEROL_REQUIRED: buildRecommendationPath("glycerol"),
    AIRBAG_VENTILATION: buildRecommendationPath("airbagVentilation"),
    ANAPHYLACTIC_REACTION: buildRecommendationPath("anaphylacticReaction"),
    ALLERGIC_REACTION: buildRecommendationPath("allergicReaction")
  });

  _exports.default = _default;
});