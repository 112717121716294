define("epcr-uis/mirage/serializers/lazy-fragment", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.RestSerializer.extend({
    serialize(object) {
      const modelName = `${object.modelName}s`;

      const json = _emberCliMirage.RestSerializer.prototype.serialize.apply(this, arguments);

      return json[modelName];
    }

  });

  _exports.default = _default;
});