define("epcr-uis/pods/pcr/patient-assessment/consciousness/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _fragment.default.extend({
    leftEye: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    rightEye: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    meningealSignals: attr("boolean"),
    gcs: (0, _attributes.fragment)("pcr/patient-assessment/consciousness/gcs"),
    avpu: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    type: attr("string"),
    isNotFilled: Ember.computed("leftEye.@each.code", "rightEye.@each.code", function () {
      const objects = ["leftEye", "rightEye"];

      for (let i = 0; i < objects.length; i++) {
        if (this.isFieldFilled(objects[i])) return false;
      }

      return true;
    }),

    isFieldFilled(key) {
      const arrayElementsCount = this.get(`${key}.length`);
      const isArray = arrayElementsCount !== undefined;
      return isArray ? arrayElementsCount > 0 : Boolean(this.get(key));
    }

  });

  _exports.default = _default;
});