define("epcr-uis/pods/metrics-context/service", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    login: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.session.addObserver("isAuthenticated");
    },

    observeUserSession: Ember.observer("session", "session.isAuthenticated", function () {
      if (this.session.isAuthenticated) {
        const {
          un: username,
          stat: stationId
        } = this.login.tokenData;
        this.setMetricsContext(username, stationId);
      } else {
        this.setMetricsContext(null, null);
      }
    }),

    setMetricsContext(username, stationId) {
      const {
        context
      } = this.metrics;
      Ember.set(context, "dimension1", stationId);
      Ember.set(context, "dimension2", username);
      Ember.set(context, "dimension3", _environment.default.APP.epcrVersion);
    }

  });

  _exports.default = _default;
});