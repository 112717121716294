define("epcr-uis/mirage/factories/procedure-treatment", ["exports", "ember-cli-mirage", "moment", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _moment, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    time: i => (0, _moment.default)(`2018-05-14 10:${10 + i}:00.000+03:00`),
    rejected: false,
    notes: () => (0, _isEnglish.isEnglish)() ? "notes" : "užrašai",
    size: 3,
    member: 2
  });

  _exports.default = _default;
});