define("epcr-uis/mirage/models/circulation", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _emberCliMirage.Model.extend({
    skinColor: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR),
    skinTemperature: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR),
    skinCondition: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR),
    radialArteryPulse: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR),
    crtLte2Sec: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR)
  });

  _exports.default = _default;
});