define("epcr-uis/pods/pcr-classificator/medicaments/prienai", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PRIENAI_MEDICAMENTS = void 0;

  const PRIENAI_MEDICAMENTS = t => ({
    stations: ["35"],
    medicaments: [(0, _medicamentsList.MEDICAMENTS)(t)[203], (0, _medicamentsList.MEDICAMENTS)(t)[204], (0, _medicamentsList.MEDICAMENTS)(t)[205], (0, _medicamentsList.MEDICAMENTS)(t)[206], (0, _medicamentsList.MEDICAMENTS)(t)[208], // 208 eina pirmiau už 207, kad būtų gražiai išdėliota
    (0, _medicamentsList.MEDICAMENTS)(t)[207], (0, _medicamentsList.MEDICAMENTS)(t)[209], (0, _medicamentsList.MEDICAMENTS)(t)[214], (0, _medicamentsList.MEDICAMENTS)(t)[216], (0, _medicamentsList.MEDICAMENTS)(t)[220], (0, _medicamentsList.MEDICAMENTS)(t)[226], (0, _medicamentsList.MEDICAMENTS)(t)[228], (0, _medicamentsList.MEDICAMENTS)(t)[230], (0, _medicamentsList.MEDICAMENTS)(t)[232], (0, _medicamentsList.MEDICAMENTS)(t)[236], (0, _medicamentsList.MEDICAMENTS)(t)[237], (0, _medicamentsList.MEDICAMENTS)(t)[240], (0, _medicamentsList.MEDICAMENTS)(t)[242], (0, _medicamentsList.MEDICAMENTS)(t)[245], (0, _medicamentsList.MEDICAMENTS)(t)[246], (0, _medicamentsList.MEDICAMENTS)(t)[249], (0, _medicamentsList.MEDICAMENTS)(t)[253], (0, _medicamentsList.MEDICAMENTS)(t)[260], (0, _medicamentsList.MEDICAMENTS)(t)[265], (0, _medicamentsList.MEDICAMENTS)(t)[266], (0, _medicamentsList.MEDICAMENTS)(t)[271], (0, _medicamentsList.MEDICAMENTS)(t)[272]]
  });

  _exports.PRIENAI_MEDICAMENTS = PRIENAI_MEDICAMENTS;
});