define("epcr-uis/pods/pcr/attachments/route", ["exports", "epcr-uis/mixins/route-tracker"], function (_exports, _routeTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeTracker.default, {
    attachmentService: Ember.inject.service("pcr/attachment"),

    ifHasErrorThenRemoveIt(path) {
      if (Ember.get(this, path)) Ember.set(this, path, null);
    },

    clearErrors() {
      Ember.set(this, "attachmentService.lastUploadError", null);
      this.ifHasErrorThenRemoveIt("attachmentService.removeAttachmentTask.last.error");
      this.ifHasErrorThenRemoveIt("attachmentService.retrieveAttachments.last.error");
    },

    deactivate() {
      this.clearErrors();
    }

  });

  _exports.default = _default;
});