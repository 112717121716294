define("epcr-uis/pods/pcr/patient-details/physician/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    queryPhysician(query, station) {
      return this.store.query("pcr/patient-details/physician", {
        query,
        station
      });
    }

  });

  _exports.default = _default;
});