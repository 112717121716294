define("epcr-uis/mixins/leave-confimation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    transitionPause: Ember.inject.service(),
    pcrService: Ember.inject.service("pcr"),

    activate() {
      Ember.set(this, "routeLeaveConfirmed", false);
    },

    setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, "transitionPauseService", this.transitionPause);
      Ember.set(controller, "pcrService", this.pcrService);
    },

    isTransitioningToExternalRoute: () => true,
    isTransitioningToOtherPcr: () => false,

    continueTransition() {
      Ember.set(this, "routeLeaveConfirmed", true);
      this.transitionPause.continueTransition();
    },

    actions: {
      continueTransition() {
        this.continueTransition();
      },

      cancelTransition() {
        this.transitionPause.cancelTransition();
      },

      willTransition(transition) {
        const isTransiting = this.isTransitioningToExternalRoute(transition) || this.isTransitioningToOtherPcr(transition);

        if (this.routeLeaveConfirmed === false && isTransiting) {
          const {
            pcrDocument
          } = this.currentModel;
          if (pcrDocument) this.transitionPause.onRouteLeave(pcrDocument, transition);
        }
      }

    }
  });

  _exports.default = _default;
});