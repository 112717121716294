define("epcr-uis/pods/pcr/team-activation/team/member/model", ["exports", "ember-data", "epcr-uis/pods/lazy-fragment/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _model.default.extend({
    externalId: attr("string"),
    code: (0, _model.lazyAttr)("code"),
    firstName: (0, _model.lazyAttr)("firstName"),
    lastName: (0, _model.lazyAttr)("lastName"),
    positions: (0, _model.lazyAttr)("positions"),
    employee: Ember.inject.service(),

    dataSource() {
      return this.externalId ? this.employee.getEmployee(this.externalId) : null;
    },

    onDataReadSuccess(employee) {
      this.notifyPropertyChange("positions", "code");
      return employee.getProperties("code", "firstName", "lastName", "positions");
    },

    onDataReadError() {
      return {
        code: "",
        firstName: this.externalId,
        lastName: "",
        positions: []
      };
    }

  });

  _exports.default = _default;
});