define("epcr-uis/mirage/factories/physician", ["exports", "epcr-uis/mirage/factories/people"], function (_exports, _people) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _people.default.extend({
    fullName() {
      return `${this.firstName} ${this.lastName}`;
    }

  });

  _exports.default = _default;
});