define("epcr-uis/pods/components/pcr-document/specialty-patient-forms/birth/component", ["exports", "epcr-uis/mixins/metrics-tracker", "epcr-uis/config/environment", "epcr-uis/helpers/android"], function (_exports, _metricsTracker, _environment, _android) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    classNames: ["ui", "grid", "forms-padding"],
    pcrClassificator: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    toolsVisible: Ember.computed(function () {
      const isProduction = _environment.default.environment === "production" && _environment.default.deployTarget === "production";
      return isProduction ? (0, _android.isAndroid)() : this.features.isEnabled("show-tools");
    }),
    actions: {
      redirectToApgarCalculator() {
        if (this.toolsVisible) {
          this.trackEvent("APGAR", "Transition from birth form to apgar");
          this.router.transitionTo("pcr.tools.apgar-calculator");
        }
      }

    }
  });

  _exports.default = _default;
});