define("epcr-uis/pods/pcr-classificator/medicaments/rokiskis", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ROKISKIS_MEDICAMENTS = void 0;

  const ROKISKIS_MEDICAMENTS = t => ({
    stations: ["39"],
    medicaments: [(0, _medicamentsList.MEDICAMENTS)(t)[202], (0, _medicamentsList.MEDICAMENTS)(t)[206], (0, _medicamentsList.MEDICAMENTS)(t)[211], (0, _medicamentsList.MEDICAMENTS)(t)[212], (0, _medicamentsList.MEDICAMENTS)(t)[214], (0, _medicamentsList.MEDICAMENTS)(t)[215], (0, _medicamentsList.MEDICAMENTS)(t)[216], (0, _medicamentsList.MEDICAMENTS)(t)[218], (0, _medicamentsList.MEDICAMENTS)(t)[220], (0, _medicamentsList.MEDICAMENTS)(t)[228], (0, _medicamentsList.MEDICAMENTS)(t)[230], (0, _medicamentsList.MEDICAMENTS)(t)[232], (0, _medicamentsList.MEDICAMENTS)(t)[233], (0, _medicamentsList.MEDICAMENTS)(t)[234], (0, _medicamentsList.MEDICAMENTS)(t)[235], (0, _medicamentsList.MEDICAMENTS)(t)[236], (0, _medicamentsList.MEDICAMENTS)(t)[237], (0, _medicamentsList.MEDICAMENTS)(t)[240], (0, _medicamentsList.MEDICAMENTS)(t)[242], (0, _medicamentsList.MEDICAMENTS)(t)[245], (0, _medicamentsList.MEDICAMENTS)(t)[246], (0, _medicamentsList.MEDICAMENTS)(t)[247], (0, _medicamentsList.MEDICAMENTS)(t)[249], (0, _medicamentsList.MEDICAMENTS)(t)[252], (0, _medicamentsList.MEDICAMENTS)(t)[265], (0, _medicamentsList.MEDICAMENTS)(t)[266], (0, _medicamentsList.MEDICAMENTS)(t)[290], (0, _medicamentsList.MEDICAMENTS)(t)[293], (0, _medicamentsList.MEDICAMENTS)(t)[294]]
  });

  _exports.ROKISKIS_MEDICAMENTS = ROKISKIS_MEDICAMENTS;
});