define("epcr-uis/pods/pcr/comment/service", ["exports", "ember-concurrency", "epcr-uis/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    simpleAuthFetch: Ember.inject.service(),
    user: Ember.inject.service(),

    getComments(pcrId) {
      return this.retrieveCommentsTask.perform(pcrId);
    },

    saveComment: (0, _emberConcurrency.task)(function* (pcrId, comment) {
      const url = _environment.default.APP.commentPostUrl.replace(":pcrId", pcrId);

      return yield this.simpleAuthFetch.fetchRequest(url, "POST", comment.trim(), "application/json", false);
    }),
    retrieveCommentsTask: (0, _emberConcurrency.task)(function* (pcrId) {
      const comments = yield this.store.query("pcr/comment", {
        pcrId
      });

      for (let i = 0; i < comments.length; i++) {
        const comment = comments.objectAt(i);
        const commentAuthor = yield this.user.getUser(comment.userId);
        Ember.set(comment, "firstName", commentAuthor.firstName);
        Ember.set(comment, "lastName", commentAuthor.lastName);
      }

      return comments;
    })
  });

  _exports.default = _default;
});