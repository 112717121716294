define("epcr-uis/mirage/config", ["exports", "ember-cli-mirage", "epcr-uis/config/environment", "epcr-uis/mirage/assets/pdfImgBase64", "epcr-uis/mirage/assets/auth", "epcr-uis/mirage/assets/validation", "epcr-uis/mirage/factories/is-english", "fetch"], function (_exports, _emberCliMirage, _environment, _pdfImgBase, _auth, _validation, _isEnglish, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  const mirageResponse500 = () => new _emberCliMirage.Response(500, {
    "Content-Type": "text/plain"
  }, {
    errors: [{
      status: "500",
      code: "Mirage Error",
      source: {
        pointer: null
      }
    }]
  });

  const createEmberPromise = (method, requestUrl) => new Ember.RSVP.Promise(resolve => resolve((0, _fetch.default)(requestUrl, {
    method: "GET"
  }).then(response => response[method]())));

  const streetInEnglish = () => [{
    street: "J. Tumo-Vaižganto st.",
    city: "Kavarskas",
    eldership: "Kavarskas city eldership",
    municipality: "Anykščiai mun."
  }];

  const streetInLithuanian = () => [{
    street: "J. Tumo-Vaižganto g.",
    city: "Kavarsko m.",
    eldership: "Kavarsko sen.",
    municipality: "Anykščių r. sav."
  }];

  const getStreet = () => (0, _isEnglish.isEnglish)() ? streetInEnglish() : streetInLithuanian();

  const getCities = () => (0, _isEnglish.isEnglish)() ? [{
    city: "Anykščiai",
    eldership: "Anykščiai eldership",
    municipality: "Anykščiai mun."
  }] : [{
    city: "Anykščių m.",
    eldership: "Anykščių sen.",
    municipality: "Anykščių r. sav."
  }];

  const getIcd = () => (0, _isEnglish.isEnglish)() ? {
    code: "W34.00",
    name: "Accidental discharge from unspecified firearms or gun"
  } : {
    code: "A66.6",
    name: "Frambezinis kaulų ir sąnarių pažeidimas"
  };

  const getHospitalMDS = () => (0, _isEnglish.isEnglish)() ? ["Kent Baldwin", "Therese Dollywood", "John Newton"] : ["Bebras Bebrauskas", "Lapė Laputė", "Vilkas Vilkelis"];

  const getPHCS = () => (0, _isEnglish.isEnglish)() ? {
    code: "PHC facility",
    name: "PHC facility",
    custom: true
  } : {
    code: "Gydymo įstaiga",
    name: "Gydymo įstaiga",
    custom: true
  };

  function _default() {
    if (_environment.default.environment !== "test") {
      this.timing = 500;
      this.passthrough("/version.txt");
    }

    this.passthrough("/visual-test/make-screenshot");
    this.passthrough("/write-coverage");
    this.passthrough("/assets/**");
    this.passthrough("https://sentry.io/api/**");
    this.passthrough("http://localhost:4567/api/v1/pcrs/**");
    this.passthrough("http://localhost:4567/api/v1/auth");
    this.namespace = _environment.default.namespace;
    this.passthrough("articles/index.json");
    this.passthrough("articles/**/index.json");
    this.passthrough("pdfs/templates/empty-pcr-lt-v5.base64");
    this.post("pcrs/:id/exports/pdf", () => (0, _pdfImgBase.getBase64)().pcrPdf2);
    this.get("pcrs/:id/exports/pdf", () => (0, _pdfImgBase.getBase64)().pcrPdf2);
    this.post("pcrs/:id/exports/png", () => (0, _pdfImgBase.getBase64)().pcrPng);
    this.get("pcrs/:id/exports/png", () => (0, _pdfImgBase.getBase64)().pcrPng);
    this.post("pcrs/:id/state", () => new _emberCliMirage.Response(201));
    this.get("pcrs/:id/state", ({
      pcrStates
    }) => pcrStates.find(1));
    this.get("hospital-mds", () => getHospitalMDS());
    this.get("pcrs/:id", ({
      pcrs
    }, {
      params: {
        id
      }
    }) => pcrs.find(id) || mirageResponse500());
    this.get("employees", ({
      employees
    }, {
      queryParams: {
        query
      }
    }) => employees.where(employee => employee.firstName.includes(query) || employee.lastName.includes(query)));
    this.get("physicians", ({
      physicians
    }, {
      queryParams: {
        query
      }
    }) => physicians.where(physician => physician.fullName.includes(query)));
    this.put("pcrs/:id", () => new _emberCliMirage.Response(204));
    this.delete("pcrs/:id", () => new _emberCliMirage.Response(204));
    this.get("icd", () => [getIcd()]);
    this.get("phcs", ({
      phcs
    }, {
      queryParams: {
        query
      }
    }) => phcs.where(phc => phc.name.indexOf(query) !== -1));
    this.get("phcs/:id", () => getPHCS());
    this.get("streets", ({
      streets
    }, {
      queryParams: {
        query
      }
    }) => streets.where(street => street.street.indexOf(query) !== -1));
    this.get("icd/:id", () => getIcd());
    this.get("departments", ({
      departments
    }, {
      queryParams: {
        query
      }
    }) => departments.where(department => department.hospitalName.includes(query)));
    this.get("departments/:id", ({
      departments
    }, {
      params: {
        id
      }
    }) => departments.find(id));
    this.get("training/:id", () => ({
      id: 1
    }));
    this.get("address/streets", () => getStreet());
    this.get("address/cities", () => getCities());
    this.get("employees/:id", ({
      employees
    }, {
      params: {
        id
      }
    }) => {
      const {
        code,
        firstName,
        lastName,
        positions
      } = employees.find(id);
      return {
        id,
        code,
        firstName,
        lastName,
        positions
      };
    });
    this.get("users/:id", ({
      employees
    }, {
      params: {
        id
      }
    }) => {
      const {
        firstName,
        lastName
      } = employees.find(id);
      return {
        id,
        firstName,
        lastName
      };
    });
    this.post("auth", (schema, {
      requestBody
    }) => {
      const responses = {
        "Basic dW5kZWZpbmVkOnVuZGVmaW5lZA==": mirageResponse500(),
        "Basic YTph": (0, _auth.getAuthToken)().userAuthTokenWithStation99
      };

      if (_environment.default.deployTarget === "demo") {
        return (0, _auth.getAuthToken)().simpleAuthToken;
      }

      return responses[JSON.parse(requestBody).token] || (0, _auth.getAuthToken)().adminAuthToken;
    });
    this.post("reauth", () => {
      return _environment.default.deployTarget === "demo" ? (0, _auth.getAuthToken)().simpleAuthToken : (0, _auth.getAuthToken)().userAuthTokenWithStation99;
    });
    this.get("my-pcr-list/:searchId", ({
      myPcrs
    }, {
      queryParams: {
        offset
      }
    }) => offset === "30" ? mirageResponse500() : myPcrs.all());
    this.get("verified-pcrs", ({
      verifiedPcrs
    }) => verifiedPcrs.all());
    this.post("active-pcr-list", ({
      activePcrs
    }) => activePcrs.all());
    this.post("event-list", () => JSON.stringify({
      id: "777",
      totalHits: 50
    }));
    this.post("my-pcr-list", () => JSON.stringify({
      id: "888",
      totalHits: 1
    }));
    this.post("validator", (schema, {
      requestBody
    }) => {
      const json = JSON.parse(requestBody);
      const errors = (0, _validation.validate)(json);
      return new _emberCliMirage.Response(204, {
        validation: "validation"
      }, errors);
    });
    this.post("recommendations", ({
      recommendations
    }) => recommendations.all());
    this.get("pcrs/:id/comments", ({
      comments
    }) => comments.all().models);
    this.post("pcrs/:id/comments", ({
      comments,
      employees
    }, {
      requestBody
    }) => {
      const comment = {
        content: requestBody
      };
      const date = new Date();
      const fakeAuthor = employees.find(1);
      comment.id = date.getTime();
      comment.date = date;
      comment.userId = 1;
      comment.firstName = fakeAuthor.firstName;
      comment.lastName = fakeAuthor.lastName;
      return comments.create(comment);
    });
    this.get("patients", ({
      patients
    }) => patients.all());
    this.get("pcr-header/:id");
    this.get("pcrs/:id/attachments", ({
      pcrAttachments
    }) => pcrAttachments.all());
    this.delete("pcrs/:id/attachments/:attId", ({
      db: {
        pcrAttachments
      }
    }, {
      params: {
        attId
      }
    }) => pcrAttachments.remove(attId));
    this.post("pcrs/:id/attachments/", ({
      db: {
        pcrAttachments
      }
    }) => pcrAttachments.insert({
      id: _emberCliMirage.faker.lorem.word(),
      size: 555,
      createdTime: new Date(),
      token: "77AEAF26B4E718FDD956FDBFC0C178F6"
    }));
    this.get("employees/:id/preferences", ({
      userPreferences
    }) => userPreferences.first());
    this.put("employees/:id/preferences", ({
      userPreferences
    }) => userPreferences.first());
    this.get("configs", () => ({
      signatureForNarcoticDrugsRequired: true
    }));
    this.get("articles", () => createEmberPromise("json", "/api/v1/articles/index.json"));
    this.get("articles/:id", (schema, request) => createEmberPromise("json", `/api/v1/articles/${request.params.id}/index.json`));
    this.get("pdfs/templates/:id", (schema, {
      params: {
        id
      }
    }) => createEmberPromise("text", `/api/v1/pdfs/templates/${id}.base64`));
    this.get("current-user/used-medicaments", () => [{
      medicamentCode: 18,
      totalAmount: 6
    }, {
      medicamentCode: 19,
      totalAmount: 6
    }]);
    this.get("current-user/used-medicaments/:id", ({
      usedMedicamentMedicaments
    }) => usedMedicamentMedicaments.all());
  }
});