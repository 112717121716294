define("epcr-uis/pods/components/confirm-modal/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    confirmBtnClass: "red",
    confirmKey: "actions.yes",
    cancelBtnClass: "green",
    cancelKey: "actions.cancel",
    confirmDataTestId: "confirm",
    cancelDataTestId: "cancel",
    closeModal: (0, _emberConcurrency.task)(function* () {
      yield Ember.set(this, "centerModalOpen", false);
    })
  });

  _exports.default = _default;
});