define("epcr-uis/pods/pcr/serializer-backend", ["exports", "ember-data", "epcr-uis/pods/pcr/backend-serialize", "epcr-uis/pods/pcr/backend-normalize"], function (_exports, _emberData, _backendSerialize, _backendNormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTSerializer = _emberData.default.RESTSerializer;

  var _default = RESTSerializer.extend({
    pcrClassificator: Ember.inject.service(),
    login: Ember.inject.service(),
    team: Ember.inject.service(),

    serialize() {
      const payload = this._super(...arguments);

      (0, _backendSerialize.getSerializedPcr)(payload);
      return payload;
    },

    serializeIntoHash(hash, type, record, options) {
      Ember.assign(hash, this.serialize(record, options));
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const {
        modelName
      } = primaryModelClass;
      payload.id = id;
      (0, _backendNormalize.getNormalizedPcr)(payload, this.pcrClassificator, this.login.stationId, this.team.activeTeam);
      return this._super(store, primaryModelClass, {
        [modelName]: payload
      }, id, requestType);
    }

  });

  _exports.default = _default;
});