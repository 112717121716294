define("epcr-uis/pods/components/pcr-document/specialty-patient-forms/clinical-death/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["ui", "grid", "forms-padding"],
    intl: Ember.inject.service(),
    pcrClassificator: Ember.inject.service(),
    sidebarFavouriteOption: Ember.computed("pcr.patientAssessments.@each.time", {
      get() {
        return {
          value: this.getAssessmentBeforeHelpTime(),
          text: `${this.intl.t("headings.patientAssessments.beforeTreatment")} ${this.intl.t("pcr.patientAssessments.time")}`
        };
      },

      set: (key, value) => value
    }),
    suggestedMins: Ember.computed("pcr.times.registrationTime", "pcr.patientAssessments.@each.time", {
      get() {
        const abTime = this.getAssessmentBeforeHelpTime();
        const registrationTime = Ember.get(this, "pcr.times.registrationTime");
        const mins = abTime && registrationTime && abTime.diff(registrationTime, "minutes");
        return mins && mins > 0 && mins < 1000 ? [{
          value: mins,
          name: `${mins} min. nuo registracijos iki MP`
        }] : [];
      },

      set: (key, value) => value
    }),

    getAssessmentBeforeHelpTime() {
      const assessments = Ember.get(this, "pcr.patientAssessments");
      const assessmentBeforeHelp = assessments && assessments.find(assessment => assessment.type.code === "BEFORE_HELP");
      return assessmentBeforeHelp && Ember.get(assessmentBeforeHelp, "time");
    },

    actions: {
      onMinsSinceSuddenDeathValueChanged(value) {
        Ember.set(this, "pcr.clinicalDeath.cprBeforeArrivalBy.minsFromSuddenDeath", value);
      }

    }
  });

  _exports.default = _default;
});