define("epcr-uis/mirage/assets/validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validate = void 0;

  const pushError = (errors, code, type) => errors.push({
    code,
    type
  });

  const validate = ({
    patientDetails
  }) => {
    const errors = [];
    const error = "error";
    if (!patientDetails.firstName || patientDetails.firstName === "") pushError(errors, "PATIENT_FIRST_NAME_REQUIRED", error);
    if (!patientDetails.lastName || patientDetails.lastName === "") pushError(errors, "PATIENT_LAST_NAME_REQUIRED", error);
    if (!patientDetails.weight || patientDetails.weight === "") pushError(errors, "PATIENT_WEIGHT_REQUIRED", error);
    if (!patientDetails.sex || patientDetails.sex === "") pushError(errors, "SEX_REQUIRED", error);
    return errors;
  };

  _exports.validate = validate;
});