define("epcr-uis/helpers/is-nurse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isNurse = void 0;

  const isNurse = ([teamLeaderType]) => teamLeaderType === "SLAUG";

  _exports.isNurse = isNurse;

  var _default = Ember.Helper.helper(isNurse);

  _exports.default = _default;
});