define("epcr-uis/pods/updates/service", ["exports", "ember-concurrency", "epcr-uis/mixins/later-loop", "epcr-uis/helpers/window-wrapper", "ember-window-mock"], function (_exports, _emberConcurrency, _laterLoop, _windowWrapper, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_laterLoop.default, {
    topNavigation: Ember.inject.service(),
    possibleUpdates: ["rulesVersion", "classificatorsVersion"],
    currentUpdateVersions: {},
    availableUpdateVersions: {},
    hasUpdates: false,
    isEnabled: _emberWindowMock.default.update,

    update() {
      return (0, _windowWrapper.callMethod)("window.update.update");
    },

    readCurrentVersions() {
      Ember.set(this, "currentUpdateVersions", JSON.parse((0, _windowWrapper.callMethod)("window.update.getCurrentVersions")));
    },

    updateIfHasUpdates() {
      this.hasUpdates && this.update();
    },

    readAvailableUpdatesFromAndroid: (0, _emberConcurrency.task)(function* () {
      yield (0, _windowWrapper.callMethod)("window.update.getUpdates", "onAvailableUpdates");
    }).drop(),

    onAvailableUpdates(updates) {
      const parsedUpdates = JSON.parse(updates);
      Ember.run.schedule("afterRender", () => {
        const hasUpdates = Object.keys(parsedUpdates).length;
        Ember.set(this, "availableUpdateVersions", parsedUpdates);
        Ember.set(this, "hasUpdates", hasUpdates);
        Ember.set(this, "topNavigation.hasUpdates", hasUpdates);
      });
    },

    readAvailableUpdatesFromAndroidIfFeatureEnabled() {
      this.isEnabled && this.readAvailableUpdatesFromAndroid.perform();
    },

    watchAvailableUpdates(period) {
      this.runLoop(this.readAvailableUpdatesFromAndroidIfFeatureEnabled.bind(this), period);
    },

    stopUpdatesWatcher() {
      this.cancelLoop();
    }

  });

  _exports.default = _default;
});