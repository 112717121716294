define("epcr-uis/pods/backend-config/service", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    simpleAuthFetch: Ember.inject.service(),
    features: Ember.inject.service(),

    enableFeatures(config) {
      config.signatureForNarcoticDrugsRequired && this.features.enable("show-narcotic-drugs-verification");
    },

    loadConfig() {
      return this.simpleAuthFetch.fetchRequest(_environment.default.APP.backendConfigUrl, "GET").then(request => request.json()).then(config => this.enableFeatures(config));
    }

  });

  _exports.default = _default;
});