define("epcr-uis/pods/components/show-block/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    reasonToHideBlock: Ember.computed("pcr.eventCancelReason.code", "pcr.noPatientReason", {
      get() {
        const eventCancelReason = Ember.get(this, "pcr.eventCancelReason");
        const noPatientReason = Ember.get(this, "pcr.noPatientReason");
        if (eventCancelReason && eventCancelReason.code) return eventCancelReason.name;
        if (noPatientReason) return this.intl.t(`message.patient.${this.pcr.noPatientReason}`);
        return "";
      },

      set: (key, value) => value
    })
  });

  _exports.default = _default;
});