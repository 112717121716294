define("epcr-uis/mixins/copy-assessment-values", ["exports", "moment", "epcr-uis/mixins/metrics-tracker"], function (_exports, _moment, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COPY = "Copy";

  var _default = Ember.Mixin.create(_metricsTracker.default, {
    copyAssessmentInput(assessment, key) {
      const fieldValue = Ember.get(assessment, key);
      if (Ember.isNone(fieldValue)) return null;
      if (fieldValue.map) return fieldValue.map(element => element.toJSON ? element.toJSON() : element);
      if (fieldValue instanceof _moment.default) return fieldValue;
      return fieldValue.toJSON ? fieldValue.toJSON() : fieldValue;
    },

    setAssessmentValues(keys) {
      const {
        assessment,
        previousAssessment
      } = this;
      keys.forEach(key => {
        const copiedInput = this.copyAssessmentInput(previousAssessment, key);
        Ember.set(assessment, key, copiedInput);
      });
    },

    _copyBreathing() {
      const breathingSectionFields = this.assessmentType === "AFTER_HELP" ? ["vitals.respiratoryRate", "vitals.spO2", "breathing.quality", "breathing.leftLung", "breathing.rightLung"] : ["vitals.respiratoryRate", "vitals.spO2"];
      this.setAssessmentValues(breathingSectionFields);
    },

    copyBreathing() {
      this._copyBreathing();

      this.trackEvent(COPY, "Copy breathing");
    },

    _copyBloodFlow() {
      const bloodFlowSectionFields = this.assessmentType === "AFTER_HELP" ? ["vitals.bpSystolic", "vitals.bpDiastolic", "vitals.pulse", "vitals.cp", "circulation.radialArteryPulse", "circulation.crtLte2Sec"] : ["vitals.bpSystolic", "vitals.bpDiastolic", "vitals.pulse", "vitals.cp"];
      this.setAssessmentValues(bloodFlowSectionFields);
    },

    copyBloodFlow() {
      this._copyBloodFlow();

      this.trackEvent(COPY, "Copy blood flow");
    },

    _copyEyes() {
      const eyesSectionFields = ["consciousness.leftEye", "consciousness.rightEye"];
      this.setAssessmentValues(eyesSectionFields);
    },

    copyEyes() {
      this._copyEyes();

      this.trackEvent(COPY, "Copy eyes");
    },

    _copyPain() {
      const painSectionFields = this.assessmentType === "AFTER_HELP" ? ["vitals.pain", "consciousness.meningealSignals"] : ["vitals.pain"];
      this.setAssessmentValues(painSectionFields);
    },

    copyPain() {
      this._copyPain();

      this.trackEvent(COPY, "Copy pain");
    },

    _copySkin() {
      const skinSectionFields = ["circulation.skinColor", "circulation.skinTemperature", "circulation.skinCondition", "circulation.rash", "vitals.temperature"];
      this.setAssessmentValues(skinSectionFields);
    },

    copySkin() {
      this._copySkin();

      this.trackEvent(COPY, "Copy skin");
    },

    _copyGlucose() {
      const glucoseSectionFields = ["vitals.glucose"];
      this.setAssessmentValues(glucoseSectionFields);
    },

    copyGlucose() {
      this._copyGlucose();

      this.trackEvent(COPY, "Copy glucose");
    },

    _copyEcg() {
      const ecgSectionFields = ["ecg.heartRhythm", "ecg.stSegment"];
      this.setAssessmentValues(ecgSectionFields);
    },

    copyEcg() {
      this._copyEcg();

      this.trackEvent(COPY, "Copy ecg");
    },

    _copyConsciousness() {
      const con = "consciousness.";
      const gcs = `${con}gcs.`;
      const consciousnessFields = [`${con}type`, `${con}avpu`, `${gcs}eyeResponse`, `${gcs}verbalResponse`, `${gcs}motorResponse`];
      this.setAssessmentValues(consciousnessFields);
    },

    copyConsciousness() {
      this._copyConsciousness();

      this.trackEvent(COPY, "Copy consciousness");
    },

    copyAssessment() {
      this._copyBreathing();

      this._copyBloodFlow();

      this._copyPain();

      if (this.assessmentType !== "AT_ER") {
        this._copyEcg();

        this._copyConsciousness();

        this._copyGlucose();

        this._copySkin();

        this._copyEyes();
      }

      this.trackEvent(COPY, "Copy assessment");
    }

  });

  _exports.default = _default;
});