define("epcr-uis/pods/active-pcr/model", ["exports", "epcr-uis/pods/my-pcr/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend();

  _exports.default = _default;
});