define("epcr-uis/pods/list/verified-pcrs/service", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    getVerifiedPcrs: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll("verified-pcr");
    })
  });

  _exports.default = _default;
});