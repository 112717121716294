define("epcr-uis/pods/components/pcr-document/tools/pediatric-calculator/results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CvsTZjbQ",
    "block": "{\"symbols\":[\"value\",\"i\",\"@result\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[7,\"table\",true],[10,\"class\",\"ui very basic unstackable table result-table\"],[8],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"values\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[10,\"class\",\"six wide tablet column\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"class\",\"three wide tablet column right aligned value\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,2,[]],0],null]],null,{\"statements\":[[0,\"            \"],[7,\"small\",true],[8],[1,[28,\"object-at\",[0,[23,1,[\"value\"]]],null],false],[9],[0,\"-\"],[1,[28,\"object-at\",[1,[23,1,[\"value\"]]],null],false],[0,\"-\"],[7,\"small\",true],[8],[1,[28,\"object-at\",[2,[23,1,[\"value\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[23,1,[\"value\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"class\",\"three wide tablet column\"],[8],[1,[23,1,[\"measure\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/tools/pediatric-calculator/results/template.hbs"
    }
  });

  _exports.default = _default;
});