define("epcr-uis/mirage/factories/vitals", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const generateNumberBetween = (min, max, i = 0) => _emberCliMirage.faker.random.number({
    min,
    max
  }) + i / 10;

  var _default = _emberCliMirage.Factory.extend({
    bpSystolic: () => generateNumberBetween(80, 100),
    bpDiastolic: () => generateNumberBetween(100, 120),
    pulse: () => generateNumberBetween(50, 100),
    respiratoryRate: () => generateNumberBetween(0, 90),
    spO2: () => generateNumberBetween(0, 100),
    temperature: i => generateNumberBetween(36, 37, i + 5),
    glucose: i => generateNumberBetween(0, 90, i + 5),
    cp: true
  });

  _exports.default = _default;
});