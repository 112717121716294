define("epcr-uis/helpers/create-treatment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createInventoryItem = createInventoryItem;
  _exports.createProcedure = createProcedure;
  _exports.createMedicament = createMedicament;
  _exports.addMedicamentToMedicaments = addMedicamentToMedicaments;

  function createInventoryItem([item, store]) {
    const fragment = {
      code: item
    };
    return store.createFragment("pcr/patient-treatment/inventory", fragment);
  }

  function createProcedure([procedure, store, time]) {
    const fragment = {
      procedure,
      time
    };
    return store.createFragment("pcr/patient-treatment/procedure-treatment", fragment);
  }

  function createMedicament([medicament, store, time]) {
    const {
      types
    } = medicament;
    const medicamentType = types.find(type => type.code === medicament.code) || types[0];
    const fragment = {
      medicament: {
        code: medicamentType.code,
        ingredient: medicament.name,
        medium: medicament.medium || {
          name: medicamentType.name,
          code: medicamentType.code
        }
      },
      useType: medicament.useType || null,
      time
    };
    return store.createFragment("pcr/patient-treatment/medicament-treatment", fragment);
  }

  function addMedicamentToMedicaments(pcr, pcrTime, store, medicament, data, type) {
    const medicaments = Ember.get(pcr, "patientTreatment.medicamentTreatment");
    const time = pcrTime.newTime(pcr).forTreatment();
    const medFragment = createMedicament([medicament, store, time, type]);
    Ember.setProperties(medFragment, data);
    medicaments.pushObject(medFragment);
    return medFragment;
  }
});