define("epcr-uis/pods/components/pcr-navigation/component", ["exports", "epcr-uis/mixins/metrics-tracker", "ember-concurrency", "epcr-uis/config/environment", "epcr-uis/helpers/android"], function (_exports, _metricsTracker, _emberConcurrency, _environment, _android) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const createItem = (icon, group) => ({
    icon,
    dataTestId: `pcr-${group}`,
    route: `pcr.${group}`,
    group
  });

  var _default = Ember.Component.extend(_metricsTracker.default, {
    classNames: ["navigation-container"],
    router: Ember.inject.service(),
    flashlight: Ember.inject.service(),
    features: Ember.inject.service(),
    pcrState: Ember.inject.service(),
    pcrService: Ember.inject.service("pcr"),
    transitionPause: Ember.inject.service(),
    isPcrMenuVisible: false,
    items: [createItem("call square", "event"), createItem("id card outline", "patient"), createItem("heartbeat", "vitals"), createItem("doctor", "narrative"), createItem("first aid", "flowchart"), createItem("wpforms", "forms"), createItem("write", "signatures"), createItem("emergency", "transportation")],
    toolsVisible: Ember.computed(function () {
      const isProduction = _environment.default.environment === "production" && _environment.default.deployTarget === "production";
      return isProduction ? (0, _android.isAndroid)() : this.features.isEnabled("show-tools");
    }),

    transitionToRoute(route) {
      return this.router.transitionTo(route);
    },

    savePcrWithQuickButton: (0, _emberConcurrency.task)(function* (pcr) {
      this.trackEvent("Save", "Save with quick button", pcr.eventId);
      return yield this.pcrService.savePcrTask.perform(pcr).catch(() => this.router.transitionTo("pcr.summary.save"));
    }).restartable(),
    actions: {
      transitionToRoute(route) {
        return this.transitionToRoute(route);
      },

      transitionToValidationOrSave() {
        const route = this.pcrValidation.hasValidations ? "pcr.summary.validations" : "pcr.summary.save";
        return this.transitionToRoute(route);
      },

      toggle() {
        this.toggleProperty("isPcrMenuVisible");
      }

    }
  });

  _exports.default = _default;
});