define("epcr-uis/pods/components/pcr-document/print/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V8pSkFHm",
    "block": "{\"symbols\":[\"Print\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"config/control-button\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@title\",\"@icon\",\"@onClick\"],[[28,\"t\",[\"config.print\"],null],\"cog\",[28,\"action\",[[23,0,[]],\"openSettings\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/print/settings/template.hbs"
    }
  });

  _exports.default = _default;
});