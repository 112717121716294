define("epcr-uis/helpers/count-shift-interval-by-shift-type", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countShiftIntervalByShiftType = countShiftIntervalByShiftType;
  _exports.default = void 0;

  const countTimeFrom = (timeNow, shiftDuration) => (0, _moment.default)(timeNow).subtract(shiftDuration + 3, "hours").minute(timeNow.minute() > 30 ? 30 : 0).second(0).millisecond(0);

  function countShiftIntervalByShiftType([timeNow, shiftDuration]) {
    return {
      from: countTimeFrom(timeNow, shiftDuration),
      to: timeNow
    };
  }

  var _default = Ember.Helper.helper(countShiftIntervalByShiftType);

  _exports.default = _default;
});