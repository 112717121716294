define("epcr-uis/pods/pcr/agreements-signatures/medical-help-agreement/model", ["exports", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment"], function (_exports, _attributes, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _fragment.default.extend({
    confirmationToReceiveMedicalHelp: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    confirmationToHospital: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    signatureType: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    signature: (0, _attributes.fragment)("pcr/agreements-signatures/signature"),
    isFilled: Ember.computed("signature.signatureImage", "signatureType.code", "confirmationToReceiveMedicalHelp", "confirmationToHospital", function () {
      const signatureType = this.get("signatureType.code");

      if ((signatureType === "REFUSES_TO_SIGN" || signatureType === "UNABLE_TO_SIGN") && this.confirmationToReceiveMedicalHelp && this.confirmationToHospital) {
        return true;
      }

      return !!this.get("signature.signatureImage");
    })
  });

  _exports.default = _default;
});