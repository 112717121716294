define("epcr-uis/pods/components/pcr-document/specialty-patient-forms/accident/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZcLWA82Q",
    "block": "{\"symbols\":[\"SingleSelect\",\"MultiSelect\",\"@accident\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/single-select\"],null],[28,\"component\",[\"side-bar/multi-select\"],null]],null,{\"statements\":[[0,\"\\n  \"],[6,[23,1,[]],[[12,\"class\",\"twelve wide column\"],[12,\"id\",\"incident-form-seat-belt\"]],[[\"@selectedItem\",\"@header\",\"@key\",\"@validation\",\"@items\",\"@fadeBackground\"],[[23,3,[\"seatBelt\"]],[28,\"t\",[\"options.forms.accident.seatBelts.title\"],null],\"name\",[24,[\"pcrValidation\",\"errors\",\"incident-form-seat-belt\"]],[24,[\"pcrClassificator\",\"forms\",\"accident\",\"seatBelts\"]],false]]],[0,\"\\n\\n  \"],[6,[23,1,[]],[[12,\"class\",\"twelve wide column\"],[12,\"id\",\"incident-form-sitting-place\"]],[[\"@selectedItem\",\"@header\",\"@key\",\"@validation\",\"@items\",\"@fadeBackground\"],[[23,3,[\"sittingPlace\"]],[28,\"t\",[\"options.forms.accident.sittingPlace.title\"],null],\"name\",[24,[\"pcrValidation\",\"errors\",\"incident-form-sitting-place\"]],[24,[\"pcrClassificator\",\"forms\",\"accident\",\"sittingPlaces\"]],false]]],[0,\"\\n\\n  \"],[6,[23,2,[]],[[12,\"id\",\"incident-form-airbags-deployed\"],[12,\"class\",\"twelve wide column\"]],[[\"@header\",\"@items\",\"@selectedItems\",\"@validation\",\"@fadeBackground\"],[[28,\"t\",[\"options.forms.accident.airbags.title\"],null],[24,[\"pcrClassificator\",\"forms\",\"accident\",\"airBags\"]],[23,3,[\"airbagsDeployed\"]],[24,[\"pcrValidation\",\"errors\",\"incident-form-airbags-deployed\"]],false]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/specialty-patient-forms/accident/template.hbs"
    }
  });

  _exports.default = _default;
});