define("epcr-uis/instance-initializers/language", ["exports", "epcr-uis/config/environment", "ember-window-mock"], function (_exports, _environment, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  const isEnglish = searchParams => searchParams.get("language") === "en";

  const setLanguage = (intlService, momentService) => {
    const searchParams = new URLSearchParams(_emberWindowMock.default.location.search);
    intlService.setLocale(isEnglish(searchParams) ? "en-us" : _environment.default.APP.defaultLocale);
    if (_environment.default.APP.defaultLocale === "en-us") momentService.setLocale("en");
    if (isEnglish(searchParams)) _environment.default["g-map"].language = "en";
  };

  function initialize(appInstance) {
    const intlService = appInstance.lookup("service:intl");
    const momentService = appInstance.lookup("service:moment");
    setLanguage(intlService, momentService);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});