define("epcr-uis/pods/components/show-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LQkkXxWB",
    "block": "{\"symbols\":[\"Notification\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/notification\"],null]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"reasonToHideBlock\"]]],null,{\"statements\":[[0,\"    \"],[5,\"ui-container\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,1,[]],[],[[\"@title\"],[[22,\"reasonToHideBlock\"]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"message.noNeedToFill\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/show-block/template.hbs"
    }
  });

  _exports.default = _default;
});