define("epcr-uis/pods/components/pcr-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XsKjFa3K",
    "block": "{\"symbols\":[\"Notification\",\"pcr\",\"pcrForm\",\"ListItem\",\"@noPcrsFoundMessage\",\"&default\",\"@isEmpty\",\"@icon\",\"@isVerifiedPcrsList\",\"@isAlwaysOpen\",\"@pcrs\"],\"statements\":[[4,\"each\",[[23,11,[]]],null,{\"statements\":[[4,\"with\",[[28,\"hash\",null,[[\"isLocked\"],[[28,\"is-pcr-locked\",[[23,2,[\"state\"]],[23,2,[\"id\"]],[24,[\"lockedPcr\",\"locks\"]]],null]]]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"pcr-list/item\"],null]],null,{\"statements\":[[0,\"      \"],[6,[23,4,[]],[],[[\"@pcr\",\"@icon\",\"@isVerifiedPcrsList\",\"@isLocked\",\"@onPcrClicked\"],[[23,2,[]],[23,8,[]],[23,9,[]],[28,\"if\",[[23,10,[]],false,[23,3,[\"isLocked\"]]],null],[28,\"action\",[[23,0,[]],\"onPcrClicked\"],null]]]],[0,\"\\n\"]],\"parameters\":[4]},null]],\"parameters\":[3]},null]],\"parameters\":[2]},{\"statements\":[[4,\"if\",[[23,7,[]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/notification\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@title\"],[[28,\"t\",[[23,5,[]]],null]]],{\"statements\":[[0,\"\\n      \"],[14,6],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"isTransitingToPcr\"]]],null,{\"statements\":[[0,\"  \"],[5,\"fullscreen-loader\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-list/template.hbs"
    }
  });

  _exports.default = _default;
});