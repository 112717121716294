define("epcr-uis/pods/components/attachment-gallery/component", ["exports", "epcr-uis/mixins/metrics-tracker"], function (_exports, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    "data-test-id": "attachment-gallery",
    simpleAuthFetch: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      onAttachmentRemove(attachmentId) {
        Ember.set(this, "isVerifyConfirmationOpen", true);
        Ember.set(this, "attachmentToRemove", attachmentId);
      },

      removeAttachment(attachmentId) {
        this.trackEvent("Attachment", "Remove attachment", `Removed attachment with ID - ${attachmentId}`);
        Ember.set(this, "isVerifyConfirmationOpen", false);
        return this.onPcrAttachmentRemove(attachmentId);
      }

    }
  });

  _exports.default = _default;
});