define("epcr-uis/pods/android-control/service", ["exports", "epcr-uis/helpers/window-wrapper"], function (_exports, _windowWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    onOpenPrinterSettingsClick() {
      (0, _windowWrapper.callMethod)("window.control.onPrinterSettingsClick");
    },

    onHomeClick() {
      (0, _windowWrapper.callMethod)("window.control.onHomeClick");
    },

    interceptHomeClick() {
      return (0, _windowWrapper.callMethod)("window.control.interceptHomeClick");
    }

  });

  _exports.default = _default;
});