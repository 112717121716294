define("epcr-uis/pods/components/pcr-document/incident/delays/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TKhAGjNy",
    "block": "{\"symbols\":[\"MultiSelect\",\"@pcr\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/multi-select\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[[12,\"id\",\"delay-reasons-to-event-site\"],[12,\"class\",\"twelve wide column\"]],[[\"@header\",\"@items\",\"@selectedItems\",\"@validation\"],[[28,\"t\",[\"pcr.delays.toEventSite\"],null],[24,[\"pcrClassificator\",\"delayReasonsToEventSite\"]],[23,2,[\"delays\",\"toEventSite\"]],[24,[\"pcrValidation\",\"errors\",\"delay-reasons-to-event-site\"]]]]],[0,\"\\n\\n  \"],[6,[23,1,[]],[[12,\"id\",\"delayReasonsToPatient\"],[12,\"class\",\"twelve wide column\"]],[[\"@header\",\"@items\",\"@selectedItems\",\"@validation\"],[[28,\"t\",[\"pcr.delays.toPatient\"],null],[24,[\"pcrClassificator\",\"delayReasons\"]],[23,2,[\"delays\",\"toPatient\"]],[24,[\"pcrValidation\",\"errors\",\"delayReasonsToPatient\"]]]]],[0,\"\\n\\n  \"],[6,[23,1,[]],[[12,\"id\",\"delayReasonsToHospital\"],[12,\"class\",\"twelve wide column\"]],[[\"@header\",\"@items\",\"@selectedItems\",\"@validation\"],[[28,\"t\",[\"pcr.delays.toHospital\"],null],[24,[\"pcrClassificator\",\"delayReasons\"]],[23,2,[\"delays\",\"toHospital\"]],[24,[\"pcrValidation\",\"errors\",\"delayReasonsToHospital\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/incident/delays/template.hbs"
    }
  });

  _exports.default = _default;
});