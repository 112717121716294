define("epcr-uis/pods/login/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    androidSignal: Ember.inject.service(),
    pcrReloaderActivePcrs: Ember.inject.service("pcr-reloader/active-pcrs"),
    historyService: Ember.inject.service("history"),
    tokenData: Ember.computed("session.session.content.authenticated", {
      get() {
        const authenticator = Ember.getOwner(this).lookup("authenticator:jwt-custom");
        const currentSession = Ember.get(this, "session.session.content.authenticated");
        if (!this.isCurrentSessionAuthenticatedAndHaveToken(currentSession)) return null;
        return authenticator.getTokenData(currentSession.token);
      },

      set: (key, value) => value
    }),
    isCurrentSessionAuthenticatedAndHaveToken: currentSession => currentSession && currentSession.token && Object.keys(currentSession).length > 0,

    convertUsernamePasswordToToken(username, password) {
      return this.base64EncodeUtf8(`${username}:${password}`);
    },

    base64EncodeUtf8(text) {
      return btoa(encodeURIComponent(text).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16))));
    },

    authenticateByToken(token, domain) {
      return this.session.authenticate("authenticator:jwt-custom", {
        token,
        domain
      });
    },

    authenticateByCredentials(username, password) {
      const token = this.convertUsernamePasswordToToken(username, password);
      return this.authenticateByToken(`Basic ${token}`);
    },

    invalidateSession() {
      return this.session.invalidate().then(async () => {
        this.pcrReloaderActivePcrs.stopWatch();
        await this.router.transitionTo("login");
        return await this.historyService.clearBrowserHistory();
      });
    },

    getStationId() {
      return Ember.get(this, "tokenData.stat");
    },

    getDispatchId() {
      return Ember.get(this, "tokenData.dis");
    },

    employeeData: Ember.computed("tokenData.eId", function () {
      if (this.tokenData) {
        const {
          eId,
          fn,
          ln,
          un
        } = this.tokenData;
        return {
          externalId: eId,
          firstName: fn,
          lastName: ln,
          name: `${fn} ${ln}`,
          username: un
        };
      }

      return null;
    }),
    stationId: Ember.computed("tokenData.stat", {
      get() {
        return this.getStationId();
      },

      set: (key, value) => value
    }),
    dispatchId: Ember.computed("tokenData.dis", {
      get() {
        return this.getDispatchId();
      },

      set: (key, value) => value
    }),
    clearEmberData: Ember.observer("session.isAuthenticated", function () {
      const {
        session,
        store
      } = this;
      if (!session.isAuthenticated) store.unloadAll();
    })
  });

  _exports.default = _default;
});