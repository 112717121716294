define("epcr-uis/pods/validation/service", ["exports", "epcr-uis/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const pcrErrorMapper = "pcr-error-mapper";

  var _default = Ember.Service.extend({
    "pcr-error-mapper": Ember.inject.service(),
    pcr: Ember.inject.service(),
    errors: null,
    errorCount: 0,
    warningCount: 0,
    hasValidations: Ember.computed("errorCount", "warningCount", function () {
      return this.errorCount > 0 || this.warningCount > 0;
    }),

    setErrors(errors) {
      let errorCount = 0;
      let warningCount = 0;
      const errorMapperService = this[pcrErrorMapper];
      const errorsByField = {};
      const errorsByGroup = {};

      if (errors && errors.length) {
        errors.filter(error => !_environment.default.APP.disabledRules.includes(error.code)).forEach(({
          code,
          type,
          match
        }) => {
          if (type === "error") errorCount++;else if (type === "warning") warningCount++;
          const mapping = errorMapperService.getErrorMapping(code, match);
          errorMapperService.addError(errorsByField, mapping, type, match);
          errorMapperService.addGroup(errorsByGroup, mapping, type);
        });
      }

      Ember.set(this, "errors", errorsByField);
      Ember.set(this, "groups", errorsByGroup);
      Ember.set(this, "errorCount", errorCount);
      Ember.set(this, "warningCount", warningCount);
    },

    removeErrors() {
      Ember.set(this, "errors", null);
      Ember.set(this, "groups", null);
      Ember.set(this, "errorCount", null);
      Ember.set(this, "warningCount", null);
    },

    validatePcr: (0, _emberConcurrency.task)(function* (pcrData, simpleAuthFetch) {
      yield (0, _emberConcurrency.timeout)(_environment.default.APP.validatePcrDebounceMs);
      const self = this;
      this[pcrErrorMapper].overridePatientTreatmentErrorMapper(pcrData.patientTreatment);
      this[pcrErrorMapper].overrideCprFormErrorMapper(pcrData.deathStatement);
      const pcrDataSerialized = pcrData.serialize();
      this.pcr.replacePcrSignatureData(pcrDataSerialized);
      return yield simpleAuthFetch.fetchRequest(_environment.default.APP.validateUrl, "POST", pcrDataSerialized).then(response => response.json()).then(json => self.setErrors(json)).catch(e => {
        throw e;
      });
    }).restartable()
  });

  _exports.default = _default;
});