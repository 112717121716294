define("epcr-uis/pods/print/form/route", ["exports", "epcr-uis/helpers/generate-print-url"], function (_exports, _generatePrintUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    simpleAuthFetch: Ember.inject.service(),

    model({
      pcrId
    }) {
      const source = format => this.simpleAuthFetch.fetchRequest((0, _generatePrintUrl.generatePrintUrl)(pcrId, format), "GET");

      return {
        source,
        pcrId
      };
    }

  });

  _exports.default = _default;
});