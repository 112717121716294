define("epcr-uis/pods/pcr/forms/route", ["exports", "epcr-uis/mixins/route-tracker"], function (_exports, _routeTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeTracker.default);

  _exports.default = _default;
});