define("epcr-uis/templates/side-bar/numeric-input/ui-design", ["exports", "ember-components/templates/components/side-bar/numeric-input/ui-design"], function (_exports, _uiDesign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiDesign.default;
    }
  });
});