define("epcr-uis/pods/pcr-classificator/medicaments/telsiai", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TELSIAI_MEDICAMENTS = void 0;

  const TELSIAI_MEDICAMENTS = t => ({
    stations: ["50"],
    medicaments: [(0, _medicamentsList.MEDICAMENTS)(t)[245]]
  });

  _exports.TELSIAI_MEDICAMENTS = TELSIAI_MEDICAMENTS;
});