define("epcr-uis/pods/components/pcr-document/flowchart/procedure-input/edit/component", ["exports", "ember-concurrency", "epcr-uis/mixins/team-member-copy", "epcr-uis/mixins/metrics-tracker"], function (_exports, _emberConcurrency, _teamMemberCopy, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_teamMemberCopy.default, _metricsTracker.default, {
    pcrClassificator: Ember.inject.service(),
    flowchartService: Ember.inject.service("pcr/flowchart"),
    router: Ember.inject.service(),
    fastComments: Ember.inject.service("pcr-classificator/fast-comments"),
    onEditPopupClose: (0, _emberConcurrency.task)(function* () {
      yield Ember.set(this, "isRightPopupOpen", false);
    }),
    helpOptions: Ember.computed("data.procedure.code", function () {
      return this.fastComments.procedures[this.data.procedure.code];
    }),
    member: Ember.computed("data.member", {
      set(fieldName, member) {
        return Ember.set(this.data, "member", member ? this.pcr.createFragment("pcr/team-activation/team/member", member.toJSON()) : null);
      },

      get() {
        return Ember.get(this, "data.member.externalId") ? Ember.get(this, "data.member") : null;
      }

    }),
    actions: {
      onChangedTimeDate() {
        this.trackEvent("Treatment", "Changed procedure time", this.router.currentRoute.name);
      }

    }
  });

  _exports.default = _default;
});