define("epcr-uis/pods/components/pcr-navigation/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KrdR0215",
    "block": "{\"symbols\":[\"CornerLabel\",\"@iconName\",\"@group\"],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"item\",[24,[\"route\"]]]],{\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/block-input/corner-label\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@count\",\"@type\"],[[24,[\"validations\",\"count\"]],[24,[\"validations\",\"type\"]]]]],[0,\"\\n    \"],[7,\"i\",true],[11,\"class\",[29,[[23,2,[]],\" icon\"]]],[8],[9],[0,\"\\n    \"],[1,[28,\"t\",[[28,\"concat\",[\"navigation.\",[23,3,[]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-navigation/item/template.hbs"
    }
  });

  _exports.default = _default;
});