define("epcr-uis/helpers/sort-array-by-order", ["exports", "ember-components/helpers/sort-array-by-order"], function (_exports, _sortArrayByOrder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "sortArrayByOrder", {
    enumerable: true,
    get: function () {
      return _sortArrayByOrder.sortArrayByOrder;
    }
  });
});