define("epcr-uis/pods/components/pcr-document/abstract/help-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ripm7WAz",
    "block": "{\"symbols\":[\"block\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"help-block-ranges\"],[8],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"blocks\"]]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[11,\"class\",[29,[[23,1,[\"class\"]]]]],[8],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[8],[7,\"strong\",true],[8],[1,[23,1,[\"range\"]],false],[9],[0,\" \"],[7,\"span\",true],[10,\"class\",\"measure\"],[8],[1,[23,1,[\"measure\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/abstract/help-block/template.hbs"
    }
  });

  _exports.default = _default;
});