define("epcr-uis/pods/components/pcr-document/transportation/disposition/transportation-type/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isTransported: Ember.computed("transportationType", function () {
      return this.transportationType === "TO_HOSPITAL_NO_LIGHTS" || this.transportationType === "TO_HOSPITAL_WITH_LIGHTS";
    })
  });

  _exports.default = _default;
});