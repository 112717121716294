define("epcr-uis/pods/components/pcr-document/patient/used-medicine/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j64586A2",
    "block": "{\"symbols\":[\"SingleInput\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"eight wide computer eight wide tablet column\"],[8],[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"side-bar/single-input\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[[12,\"id\",\"medicineConsumedByPatient\"]],[[\"@validation\",\"@title\",\"@readOnly\",\"@onSelectedItem\",\"@data\"],[[24,[\"pcrValidation\",\"errors\",\"medicineConsumedByPatient\"]],[28,\"t\",[\"pcr.patientDetails.usedMedicaments\"],null],[22,\"isUsedMedsUnknown\"],[28,\"action\",[[23,0,[]],\"onUsedMedicineFilled\"],null],[24,[\"patient\",\"medicineConsumed\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[5,\"checkbox-true-null\",[[12,\"class\",\"four wide computer four wide tablet column\"]],[[\"@title\",\"@checked\",\"@onValueChange\"],[[28,\"t\",[\"actions.selectUnknown\"],null],[22,\"isUsedMedsUnknown\"],[28,\"action\",[[23,0,[]],\"onUsedMedicineUnknown\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/patient/used-medicine/template.hbs"
    }
  });

  _exports.default = _default;
});