define("epcr-uis/mirage/factories/inventory", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    code: 1,
    type: 1,
    total: 2,
    notes: () => (0, _isEnglish.isEnglish)() ? "accidentally spoiled" : "netyčia sugadinau"
  });

  _exports.default = _default;
});