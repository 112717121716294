define("epcr-uis/pods/pcr/transportation/department/model", ["exports", "epcr-uis/pods/lazy-fragment/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    department: Ember.inject.service("pcr/transportation/department"),
    hospitalName: (0, _model.lazyAttr)("hospitalName"),
    hospitalCode: (0, _model.lazyAttr)("hospitalCode"),
    departmentName: (0, _model.lazyAttr)("departmentName"),
    departmentCode: (0, _model.lazyAttr)("departmentCode"),

    dataSource() {
      return this.department.getDepartment(this.code);
    },

    onDataReadSuccess(department) {
      return department.getProperties("hospitalName", "hospitalCode", "departmentName", "departmentCode");
    },

    onDataReadError() {
      return {
        hospitalCode: this.code,
        hospitalName: "",
        departmentName: ""
      };
    }

  });

  _exports.default = _default;
});