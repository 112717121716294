define("epcr-uis/pods/pcr/ability", ["exports", "ember-can", "epcr-uis/mixins/is-admin"], function (_exports, _emberCan, _isAdmin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IS_AUTHENTICATED_PATH = "session.isAuthenticated";

  var _default = _emberCan.Ability.extend(_isAdmin.default, {
    canWrite: Ember.computed(IS_AUTHENTICATED_PATH, function () {
      return Ember.get(this, IS_AUTHENTICATED_PATH);
    }),
    canRead: Ember.computed(IS_AUTHENTICATED_PATH, function () {
      return Ember.get(this, IS_AUTHENTICATED_PATH);
    }),
    canFillExtraInfo: Ember.computed(IS_AUTHENTICATED_PATH, {
      get() {
        return this.isAdmin();
      },

      set: (key, value) => value
    })
  });

  _exports.default = _default;
});