define("epcr-uis/pods/pcr-classificator/medicaments/siauliai", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SIAULIAI_MEDICAMENTS = void 0;

  const SIAULIAI_MEDICAMENTS = t => ({
    stations: ["44"],
    medicaments: [(0, _medicamentsList.MEDICAMENTS)(t)[201], (0, _medicamentsList.MEDICAMENTS)(t)[202], (0, _medicamentsList.MEDICAMENTS)(t)[203], (0, _medicamentsList.MEDICAMENTS)(t)[204], (0, _medicamentsList.MEDICAMENTS)(t)[206], (0, _medicamentsList.MEDICAMENTS)(t)[208], // 208 eina pirmiau už 207, kad būtų gražiai išdėliota
    (0, _medicamentsList.MEDICAMENTS)(t)[207], (0, _medicamentsList.MEDICAMENTS)(t)[213], (0, _medicamentsList.MEDICAMENTS)(t)[214], (0, _medicamentsList.MEDICAMENTS)(t)[216], (0, _medicamentsList.MEDICAMENTS)(t)[218], (0, _medicamentsList.MEDICAMENTS)(t)[224], (0, _medicamentsList.MEDICAMENTS)(t)[226], (0, _medicamentsList.MEDICAMENTS)(t)[228], (0, _medicamentsList.MEDICAMENTS)(t)[230], (0, _medicamentsList.MEDICAMENTS)(t)[232], (0, _medicamentsList.MEDICAMENTS)(t)[233], (0, _medicamentsList.MEDICAMENTS)(t)[234], (0, _medicamentsList.MEDICAMENTS)(t)[236], (0, _medicamentsList.MEDICAMENTS)(t)[238], (0, _medicamentsList.MEDICAMENTS)(t)[242], (0, _medicamentsList.MEDICAMENTS)(t)[243], (0, _medicamentsList.MEDICAMENTS)(t)[244], (0, _medicamentsList.MEDICAMENTS)(t)[245], (0, _medicamentsList.MEDICAMENTS)(t)[250], (0, _medicamentsList.MEDICAMENTS)(t)[251], (0, _medicamentsList.MEDICAMENTS)(t)[263], (0, _medicamentsList.MEDICAMENTS)(t)[264], (0, _medicamentsList.MEDICAMENTS)(t)[265], (0, _medicamentsList.MEDICAMENTS)(t)[266], (0, _medicamentsList.MEDICAMENTS)(t)[267], (0, _medicamentsList.MEDICAMENTS)(t)[274], (0, _medicamentsList.MEDICAMENTS)(t)[275], (0, _medicamentsList.MEDICAMENTS)(t)[276], (0, _medicamentsList.MEDICAMENTS)(t)[277], (0, _medicamentsList.MEDICAMENTS)(t)[278], (0, _medicamentsList.MEDICAMENTS)(t)[279], (0, _medicamentsList.MEDICAMENTS)(t)[280], (0, _medicamentsList.MEDICAMENTS)(t)[283], (0, _medicamentsList.MEDICAMENTS)(t)[285], (0, _medicamentsList.MEDICAMENTS)(t)[291], (0, _medicamentsList.MEDICAMENTS)(t)[292], (0, _medicamentsList.MEDICAMENTS)(t)[293], (0, _medicamentsList.MEDICAMENTS)(t)[294], (0, _medicamentsList.MEDICAMENTS)(t)[295]]
  });

  _exports.SIAULIAI_MEDICAMENTS = SIAULIAI_MEDICAMENTS;
});