define("epcr-uis/pods/employee/serializer", ["exports", "epcr-uis/pods/lazy-fragment/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    pcrClassificator: Ember.inject.service(),

    setPayload(payload, id) {
      const {
        pcrClassificator
      } = this;

      if (payload) {
        if (Ember.isArray(payload)) {
          payload.map(employee => {
            employee.externalId = employee.id;
            employee.positions = this.changePositionsToFragment(employee.positions, pcrClassificator);
          });
        } else {
          payload.positions = this.changePositionsToFragment(payload.positions, pcrClassificator);
          payload.externalId = id;
        }

        return payload;
      }

      return undefined;
    },

    changePositionsToFragment(positions, classificator) {
      const positionsArray = [];
      positions && positions.forEach(position => {
        const {
          code,
          name
        } = classificator.findElementByCode("allPositions", position);
        positionsArray.push({
          code,
          name
        });
      });
      return positionsArray;
    }

  });

  _exports.default = _default;
});