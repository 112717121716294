define("epcr-uis/pods/pcr-classificator/service", ["exports", "epcr-uis/helpers/get-classificator-element-by-code", "epcr-uis/pods/pcr-classificator/medicaments/get-medicaments-data"], function (_exports, _getClassificatorElementByCode, _getMedicamentsData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const procedureA = "A";
  const procedureB = "B";
  const procedureC = "C";
  const procedureD = "D";
  const procedureE = "E";
  const procedureF = "F";
  const pain = "options.patientAssessments.vitals.pain.";
  const injury = "options.patientAssessments.injury.";
  const condition = "options.treatment.condition.";
  const trauma = "options.forms.trauma.";
  const abcd = `${trauma}abcd.`;
  const traumaInjury = `${trauma}injury.`;
  const circumstance = `${trauma}circumstance.`;
  const accident = "options.forms.accident.";
  const seatBelts = `${accident}seatBelts.`;
  const sittingPlace = `${accident}sittingPlace.`;
  const airBags = `${accident}airbags.`;
  const safetyMeasures = `${trauma}safetyMeasures.`;
  const coronaryContraindication = "options.forms.coronary.contraindication.";
  const clinicalDeath = "options.forms.clinicalDeath.";
  const h = "general.hours";
  const procedure = "options.treatment.procedures.";

  const DOCTOR = t => ({
    code: "GYD",
    name: t("options.positions.doctor")
  });

  const NURSE = t => ({
    code: "SLAUG",
    name: t("options.positions.nurse")
  });

  const PARAMEDIC = t => ({
    code: "PARAM",
    name: t("options.positions.paramedic")
  });

  const allPositions = t => [DOCTOR(t), NURSE(t), PARAMEDIC(t), {
    code: "DISP",
    name: "Dispečeris"
  }, {
    code: "VYRDISP",
    name: "Vyr.dispečeris"
  }, {
    code: "PAGPERS",
    name: "Pagalbinis darbuotojas"
  }, {
    code: "PARVAIR",
    name: "Paramedikas vairuotojas"
  }, {
    code: "SANI",
    name: "Sanitaras"
  }, {
    code: "VAIR",
    name: "Vairuotojas"
  }, {
    code: "VYRGYD",
    name: "Vyr.gydytojas"
  }, {
    code: "IT",
    name: "IT"
  }, {
    code: "DIREKT",
    name: "Direktorius"
  }, {
    code: "PAV",
    name: "Pavaduotoja"
  }, {
    code: "ADMINISTRACIJA",
    name: "Administracija"
  }, {
    code: "ASPI",
    name: "ASPĮ"
  }, {
    code: "STAT",
    name: "Statistikos darbuotojas"
  }, {
    code: "REZ",
    name: "Rezidentas"
  }, {
    code: "SMPPARAM",
    name: "Skubiosios medicininės pagalbos paramedikas"
  }];

  const category1 = t => ({
    code: 1,
    name: t("options.event.category.1")
  });

  const category2 = t => ({
    code: 2,
    name: t("options.event.category.2")
  });

  const stateKey = "pcr.state.";
  const ageUnitKey = "options.patientDetails.ageUnit.";
  const gcsKey = "options.patientAssessments.gcs.";
  const eyeKey = `${gcsKey}eye.`;
  const verbalKey = `${gcsKey}verbal.`;
  const motorKey = `${gcsKey}motor.`;
  const heartRhythmKey = "options.patientAssessments.ecg.rhythm.";
  const proceduresKey = "options.treatment.procedures.";
  const strokeKey = "options.forms.stroke.contraindication.";
  const inventoryKey = "options.treatment.inventory.";

  const delayReasons = t => [{
    code: "TRAFFIC",
    name: t("options.delayReasons.traffic")
  }, {
    code: "WEATHER",
    name: t("options.delayReasons.weather")
  }, {
    code: "CROWD",
    name: t("options.delayReasons.crowd")
  }, {
    code: "DANGEROUS_SITE_AREA",
    name: t("options.delayReasons.dangerousSiteArea")
  }, {
    code: "WRONG_ADDRESS",
    name: t("options.delayReasons.wrongAddress")
  }, {
    code: "DIFFICULT_ACCESS_TO_SITE",
    name: t("options.delayReasons.difficultAccessToSite")
  }, {
    code: "RESCUING",
    name: t("options.delayReasons.rescuing")
  }, {
    code: "ELEVATOR",
    name: t("options.delayReasons.elevator")
  }, {
    code: "DIFFICULT_ACCESS_TO_BUILDING",
    name: t("options.delayReasons.difficultAccessToBuilding")
  }, {
    code: "LOCKED_DOOR",
    name: t("options.delayReasons.lockedDoor")
  }, {
    code: "DISTANCE_TO_PATIENT",
    name: t("options.delayReasons.distanceToPatient")
  }, {
    code: "WAITING_FOR_ESCORT",
    name: t("options.delayReasons.waitingForEscort")
  }, {
    code: "BROKEN_VEHICLE",
    name: t("options.delayReasons.brokenVehicle")
  }];

  const helpProtocol = "options.diagnosis.helpProtocol.";
  const helpProtocolShort = `${helpProtocol}short`;

  const classificatorData = t => ({
    sizeUnits: [{
      code: "MM",
      name: "mm"
    }, {
      code: "UNITS",
      name: t("general.units")
    }, {
      code: "ML",
      name: "ml"
    }, {
      code: "MG",
      name: "mg"
    }],
    ageMeasurement: [{
      code: "YEARS",
      name: t(`${ageUnitKey}years`),
      shortName: t(`${ageUnitKey}yearsShort`),
      favourite: 1
    }, {
      code: "MONTHS",
      name: t(`${ageUnitKey}months`),
      shortName: t(`${ageUnitKey}monthsShort`)
    }, {
      code: "DAYS",
      name: t(`${ageUnitKey}days`),
      shortName: t(`${ageUnitKey}daysShort`)
    }],
    pcrStates: [{
      code: "IMPORTED",
      name: t(`${stateKey}imported`)
    }, {
      code: "RESTORED",
      name: t(`${stateKey}restored`)
    }, {
      code: "SCANNED",
      name: t(`${stateKey}scanned`)
    }, {
      code: "FILLED",
      name: t(`${stateKey}filled`)
    }, {
      code: "VERIFIED",
      name: t(`${stateKey}verified`)
    }, {
      code: "APPROVED",
      name: t(`${stateKey}approved`)
    }, {
      code: "ACCOUNTED",
      name: t(`${stateKey}accounted`)
    }, {
      code: "CLOSED",
      name: t(`${stateKey}closed`)
    }, {
      code: "ARCHIVED",
      name: t(`${stateKey}archived`)
    }],
    allPositions: allPositions(t),
    positions: [DOCTOR(t), NURSE(t), PARAMEDIC(t)],
    categories: [category1(t), category2(t), {
      code: 3,
      name: t("options.event.category.3")
    }, {
      code: 4,
      name: t("options.event.category.4")
    }],
    mainCategories: [category1(t), category2(t)],
    procedureGroups: [{
      code: procedureA,
      name: t(`${proceduresKey}resuscitation`)
    }, {
      code: procedureB,
      name: t(`${proceduresKey}breathing`)
    }, {
      code: procedureC,
      name: t(`${proceduresKey}infuzotherapy`)
    }, {
      code: procedureD,
      name: t(`${proceduresKey}injury`)
    }, {
      code: procedureE,
      name: t(`${proceduresKey}other`)
    }, {
      code: procedureF,
      name: t(`${proceduresKey}newborn`)
    }],
    pcrDocumentVitalParams: {
      assessmentTypes: [{
        code: "BEFORE_HELP",
        name: t("headings.patientAssessments.beforeTreatment"),
        order: 1
      }, {
        code: "AFTER_HELP",
        name: t("headings.patientAssessments.afterTreatment"),
        order: 2
      }, {
        code: "AT_ER",
        name: t("headings.patientAssessments.atER"),
        order: 3
      }],
      gcs: {
        eyeResponse: [{
          code: 4,
          name: t(`${eyeKey}spontaneous`)
        }, {
          code: 3,
          name: t(`${eyeKey}toVoice`)
        }, {
          code: 2,
          name: t(`${eyeKey}toPain`)
        }, {
          code: 1,
          name: t(`${eyeKey}none`)
        }],
        verbalResponse: [{
          code: 5,
          name: t(`${verbalKey}oriented`)
        }, {
          code: 4,
          name: t(`${verbalKey}confused`)
        }, {
          code: 3,
          name: t(`${verbalKey}inappropriate`)
        }, {
          code: 2,
          name: t(`${verbalKey}incomprehensible`)
        }, {
          code: 1,
          name: t(`${verbalKey}none`)
        }],
        motorResponse: [{
          code: 6,
          name: t(`${motorKey}obeysCommands`)
        }, {
          code: 5,
          name: t(`${motorKey}localizesPain`)
        }, {
          code: 4,
          name: t(`${motorKey}withdrawPain`)
        }, {
          code: 3,
          name: t(`${motorKey}flexionPain`)
        }, {
          code: 2,
          name: t(`${motorKey}extensionPain`)
        }, {
          code: 1,
          name: t(`${motorKey}none`)
        }]
      },
      pain: [{
        code: 0,
        name: t(`${pain}painless`),
        favourite: 1
      }, {
        code: 1,
        name: t(`${pain}veryMinor`)
      }, {
        code: 2,
        name: t(`${pain}minor`)
      }, {
        code: 3,
        name: t(`${pain}distraction`)
      }, {
        code: 4,
        name: t(`${pain}ignorable`)
      }, {
        code: 5,
        name: t(`${pain}barelyIgnorable`)
      }, {
        code: 6,
        name: t(`${pain}unignorable`)
      }, {
        code: 7,
        name: t(`${pain}hard2concentrate`)
      }, {
        code: 8,
        name: t(`${pain}limitedActivity`)
      }, {
        code: 9,
        name: t(`${pain}speechless`)
      }, {
        code: 10,
        name: t(`${pain}unconscious`)
      }],
      condition: {
        breathing: [{
          code: "NORMAL",
          name: t("options.patientAssessments.breathing.normal"),
          favourite: 1
        }, {
          code: "SHORTNESS",
          name: t("options.patientAssessments.breathing.shortness"),
          favourite: 2
        }, {
          code: "SUPERFICIAL",
          name: t("options.patientAssessments.breathing.superficial"),
          favourite: 3
        }, {
          code: "IRREGULAR",
          name: t("options.patientAssessments.breathing.irregular")
        }],
        lung: [{
          code: "NORMAL",
          name: t("options.patientAssessments.lung.normal"),
          favourite: 1
        }, {
          code: "DRY",
          name: t("options.patientAssessments.lung.dry"),
          favourite: 2
        }, {
          code: "BRONCHIAL",
          name: t("options.patientAssessments.lung.bronchial"),
          favourite: 3
        }, {
          code: "IMPAIRED",
          name: t("options.patientAssessments.lung.impaired")
        }, {
          code: "UNHEARABLE",
          name: t("options.patientAssessments.lung.unhearable")
        }, {
          code: "WET",
          name: t("options.patientAssessments.lung.wet")
        }],
        circulation: {
          skinColor: [{
            code: "NORMAL",
            name: t("options.patientAssessments.skinColor.normal"),
            favourite: 1
          }, {
            code: "CYANOTIC",
            name: t("options.patientAssessments.skinColor.cyanotic")
          }, {
            code: "PALE",
            name: t("options.patientAssessments.skinColor.pale"),
            favourite: 2
          }, {
            code: "REDNESS",
            name: t("options.patientAssessments.skinColor.redness"),
            favourite: 3
          }, {
            code: "JAUNDICE",
            name: t("options.patientAssessments.skinColor.jaundice"),
            favourite: 4
          }],
          skinTemp: [{
            code: "NORMAL",
            name: t("options.patientAssessments.skinTemp.normal"),
            favourite: 1
          }, {
            code: "HOT",
            name: t("options.patientAssessments.skinTemp.hot"),
            favourite: 2
          }, {
            code: "COOL",
            name: t("options.patientAssessments.skinTemp.cool"),
            favourite: 3
          }, {
            code: "COLD",
            name: t("options.patientAssessments.skinTemp.cold"),
            favourite: 4
          }],
          skinCondition: [{
            code: "NORMAL",
            name: t("options.patientAssessments.skinCond.normal"),
            favourite: 1
          }, {
            code: "CLAMMY",
            name: t("options.patientAssessments.skinCond.clammy"),
            favourite: 2
          }, {
            code: "DRY",
            name: t("options.patientAssessments.skinCond.dry"),
            favourite: 3
          }],
          radialArteryPulse: [{
            code: true,
            name: t("options.patientAssessments.radialArteryPulse.present"),
            favourite: 1
          }, {
            code: false,
            name: t("options.patientAssessments.radialArteryPulse.absent"),
            favourite: 2
          }],
          crtLte2Sec: [{
            code: true,
            name: "≤2s",
            favourite: 1
          }, {
            code: false,
            name: ">2s",
            favourite: 2
          }]
        },
        consciousAVPU: [{
          code: "A",
          name: t("options.patientAssessments.avpu.a"),
          favourite: 1
        }, {
          code: "V",
          name: t("options.patientAssessments.avpu.v"),
          favourite: 2
        }, {
          code: "P",
          name: t("options.patientAssessments.avpu.p"),
          favourite: 3
        }, {
          code: "U",
          name: t("options.patientAssessments.avpu.u"),
          favourite: 4
        }],
        consciousness: {
          eyesCondition: [{
            code: "RESPONDS",
            name: t("options.patientAssessments.eyesCondition.responds"),
            favourite: 1
          }, {
            code: "INERT",
            name: t("options.patientAssessments.eyesCondition.inert")
          }, {
            code: "DOES_NOT_RESPOND",
            name: t("options.patientAssessments.eyesCondition.doesNotRespond"),
            favourite: 2
          }, {
            code: "DILATED",
            name: t("options.patientAssessments.eyesCondition.dilated"),
            favourite: 3
          }, {
            code: "SHRIVELED",
            name: t("options.patientAssessments.eyesCondition.shriveled")
          }],
          meningealSignals: [{
            code: true,
            name: t("options.patientAssessments.meningealSignals.yes")
          }, {
            code: false,
            name: t("options.patientAssessments.meningealSignals.no")
          }]
        }
      },
      ECG: {
        heartRhythm: [{
          code: 10,
          name: t(`${heartRhythmKey}asystole`)
        }, {
          code: 11,
          name: t(`${heartRhythmKey}vFib`)
        }, {
          code: 12,
          name: t(`${heartRhythmKey}emd`)
        }, {
          code: 13,
          name: t(`${heartRhythmKey}nsr`)
        }, {
          code: 14,
          name: t(`${heartRhythmKey}sinusBradycardia`)
        }, {
          code: 15,
          name: t(`${heartRhythmKey}sinusTachycardia`)
        }, {
          code: 16,
          name: t(`${heartRhythmKey}sinusDysrhythmia`)
        }, {
          code: 17,
          name: t(`${heartRhythmKey}pvc`)
        }, {
          code: 18,
          name: t(`${heartRhythmKey}atrialFibrillation`)
        }, {
          code: 19,
          name: t(`${heartRhythmKey}svt`)
        }, {
          code: 20,
          name: t(`${heartRhythmKey}ventricularTachycardia`)
        }, {
          code: 21,
          name: t(`${heartRhythmKey}avBlock`)
        }, {
          code: 22,
          name: t(`${heartRhythmKey}pacemakerTrue`)
        }, {
          code: 23,
          name: t(`${heartRhythmKey}pacemakerFalse`)
        }, {
          code: 24,
          name: t(`${heartRhythmKey}artifact`)
        }],
        stSegment: [{
          code: 30,
          name: t("options.patientAssessments.stSegment.normal")
        }, {
          code: 31,
          name: t("options.patientAssessments.stSegment.rise")
        }, {
          code: 32,
          name: t("options.patientAssessments.stSegment.drop")
        }]
      }
    },
    bodyPartsInjuries: [{
      code: "HEAD",
      name: t(`${injury}head`)
    }, {
      code: "FACE",
      name: t(`${injury}face`)
    }, {
      code: "NECK",
      name: t(`${injury}neck`)
    }, {
      code: "CHEST",
      name: t(`${injury}chest`)
    }, {
      code: "BACK",
      name: t(`${injury}back`)
    }, {
      code: "LEFT_ARM",
      name: t(`${injury}leftArm`)
    }, {
      code: "RIGHT_ARM",
      name: t(`${injury}rightArm`)
    }, {
      code: "ABDOMEN",
      name: t(`${injury}abdomen`)
    }, {
      code: "PELVIS",
      name: t(`${injury}pelvis`)
    }, {
      code: "LEFT_LEG",
      name: t(`${injury}leftLeg`)
    }, {
      code: "RIGHT_LEG",
      name: t(`${injury}rightLeg`)
    }],
    dispositions: [{
      code: "TO_HOSPITAL_NO_LIGHTS",
      name: t("options.transportation.type.withoutSirens"),
      shortName: "Be gars. signalų",
      favourite: 1
    }, {
      code: "TO_HOSPITAL_WITH_LIGHTS",
      name: t("options.transportation.type.withSirens"),
      shortName: "Su gars. signalais",
      favourite: 2
    }, {
      code: "TRANSFERRED_TO_TEAM",
      name: t("options.transportation.type.transferred")
    }, {
      code: "HELP_TO_TEAM",
      name: t("options.transportation.type.help")
    }, {
      code: "DEAD",
      name: t("options.transportation.type.dead")
    }, {
      code: "DECLINED",
      name: t("options.transportation.type.refused")
    }, {
      code: "OTHER",
      name: t("options.transportation.type.other"),
      favourite: 3
    }],
    transportToHospitalDueTo: [{
      code: "NEAREST",
      name: t("options.transportation.hospitalSelectionCriteria.nearest"),
      favourite: 1
    }, {
      code: "CHOSEN_BY_FAMILY",
      name: t("options.transportation.hospitalSelectionCriteria.patientOrFamilyPreference"),
      shortName: "Paciento/šeimos pasirinkimas",
      favourite: 2
    }, {
      code: "SPECIALIZED",
      name: t("options.transportation.hospitalSelectionCriteria.specialized")
    }],
    activationProtocols: {
      default: [{
        code: 1,
        name: "1 - Pilvo skausmas"
      }, {
        code: 2,
        name: `2 - ${t("activationProtocol.default.2")}`
      }, {
        code: 3,
        name: "3 - Gyvunų įkandimai / Užpuolimai"
      }, {
        code: 4,
        name: "4 - Užpuolimas / Seksualinė prievarta"
      }, {
        code: 5,
        name: "5 - Nugaros skausmas (netrauminis arba sena trauma)"
      }, {
        code: 6,
        name: "6 - Kvėpavimo problemos"
      }, {
        code: 7,
        name: "7 - Nudegimai (nu(si)plikimai) / Sprogimai"
      }, {
        code: 8,
        name: "8 - Inhaliacinis apsinuodijimas"
      }, {
        code: 9,
        name: "9 - Širdies ar kvėpavimo sustojimas / Staigi mirtis"
      }, {
        code: 10,
        name: "10 - Krūtinės skausmas (netrauminis)"
      }, {
        code: 11,
        name: "11 - Springimas"
      }, {
        code: 12,
        name: "12 - Traukuliai"
      }, {
        code: 13,
        name: "13 - Diabeto komplikacijos"
      }, {
        code: 14,
        name: "14 - Skendimas (panirimas) / Nėrimas"
      }, {
        code: 15,
        name: "15 - Elektros trauma / Žaibas"
      }, {
        code: 16,
        name: "16 - Akių sužalojimai"
      }, {
        code: 17,
        name: "17 - Kritimas iš aukščio"
      }, {
        code: 18,
        name: "18 - Galvos skausmas"
      }, {
        code: 19,
        name: "19 - Širdies veiklos sutrikimai / IKD"
      }, {
        code: 20,
        name: "20 - Karščio / Šalčio poveikis"
      }, {
        code: 21,
        name: "21 - Kraujavimas / Plėštines žaizdos"
      }, {
        code: 22,
        name: "22 - Nelaimingas atsitikimas neprieinamoje vietoje / Įstrigimas"
      }, {
        code: 23,
        name: "23 - Perdozavimas / Apsinuodijimas (peroralinis)"
      }, {
        code: 24,
        name: "24 - Nėštumas / Gimdymas / Persileidimas"
      }, {
        code: 25,
        name: "25 - Psichikos ir elgesio sutrikimai / Bandymas nusižudyti"
      }, {
        code: 26,
        name: "26 - Negaluojantis žmogus (žinomos diagnozės)"
      }, {
        code: 27,
        name: "27 - Durtiniai / Šautiniai / Kiauryminiai sužalojimai"
      }, {
        code: 28,
        name: "28 - Insultas"
      }, {
        code: 29,
        name: "29 - Eismo / Transporto nelaimės"
      }, {
        code: 30,
        name: "30 - Trauminiai sužalojimai (specifiniai)"
      }, {
        code: 31,
        name: "31 - Sąmonės netekimas / (Pre)sinkopė"
      }, {
        code: 32,
        name: "32 - Neaiški problema (Nukritęs žmogus)"
      }, {
        code: 33,
        name: "33 - Pervežimas"
      }, {
        code: 36,
        name: "36 - Pandemija / Epidemija / Protrūkis"
      }],
      31: [{
        code: 11,
        name: "11 - Ekstremalus įvykis"
      }, {
        code: 12,
        name: "12 - Transporto priemonių avarija"
      }, {
        code: 13,
        name: "13 - Gaisras, kurio metu nukentėjo žmonės"
      }, {
        code: 14,
        name: "14 - Gaisras pastate, nežinoma situacija"
      }, {
        code: 15,
        name: "15 - Įvairios sunkios traumos"
      }, {
        code: 16,
        name: "16 - Kritimai iš aukščio"
      }, {
        code: 17,
        name: "17 - Žaibo ir elektros sukelti pažeidimai"
      }, {
        code: 18,
        name: "18 - Sunkūs nudegimai"
      }, {
        code: 19,
        name: "19 - Nušalimai"
      }, {
        code: 20,
        name: "20 - Nežymūs nušalimai, nudegimai"
      }, {
        code: 21,
        name: "21 - Pasikorimas, pasismaugimas"
      }, {
        code: 22,
        name: "22 - Skendimas"
      }, {
        code: 23,
        name: "23 - Smurtas/prievartavimas"
      }, {
        code: 24,
        name: "24 - Užspringimas"
      }, {
        code: 25,
        name: "25 - Ūmi alerginė reakcija"
      }, {
        code: 26,
        name: "26 - Alerginė reakcija"
      }, {
        code: 27,
        name: "27 - Gyvūnų įkandimas"
      }, {
        code: 28,
        name: "28 - Gyvūnų įkandimai (nežymūs), įdrėskimai, apseilėjimai"
      }, {
        code: 29,
        name: "29 - Ūmus apsinuodijimas"
      }, {
        code: 30,
        name: "30 - Įvairios lokalizacijos svetimkūniai"
      }, {
        code: 31,
        name: "31 - Lengvesnio ir vidutinio sunkumo traumos"
      }, {
        code: 32,
        name: "32 - Saulės, šilumos smūgis"
      }, {
        code: 33,
        name: "33 - Bendras kūno atšalimas"
      }, {
        code: 34,
        name: "34 - Miršta, nekvėpuoja, mėlynuoja"
      }, {
        code: 35,
        name: "35 - Sąmonės netekimas"
      }, {
        code: 36,
        name: "36 - Sąmonės sutrikimas"
      }, {
        code: 37,
        name: "37 - Kraujavimas (netrauminis)"
      }, {
        code: 38,
        name: "38 - Įtariamas vidinis kraujavimas"
      }, {
        code: 39,
        name: "39 - Traukuliai"
      }, {
        code: 40,
        name: "40 - Širdies veiklos sutrikimai"
      }, {
        code: 41,
        name: "41 - Ūmus kraujotakos sutrikimas"
      }, {
        code: 42,
        name: "42 - Dusulys"
      }, {
        code: 43,
        name: "43 - Bandymas nusižudyti /(savižudybės pavojus)"
      }, {
        code: 44,
        name: "44 - Kiti psichikos ir elgesio sutrikimai"
      }, {
        code: 45,
        name: "45 - Šlapimo susilaikymas"
      }, {
        code: 46,
        name: "46 - Gausus vėmimas, viduriavimas"
      }, {
        code: 47,
        name: "47 - Stiprus skausmas"
      }, {
        code: 48,
        name: "48 - Kūno temperatūros pokyčiai"
      }, {
        code: 49,
        name: "49 - Parezė/paralyžius"
      }, {
        code: 50,
        name: "50 - Ūmus regėjimo sutrikimas"
      }, {
        code: 51,
        name: "51 - Lėtinių ligų paūmėjimas"
      }, {
        code: 52,
        name: "52 - Nėštumo komplikacijos"
      }, {
        code: 53,
        name: "53 - Prasidėjęs gimdymas"
      }, {
        code: 54,
        name: "54 - Pagimdė"
      }, {
        code: 55,
        name: "55 - Komplikacijos po gimdymo"
      }, {
        code: 56,
        name: "56 - Kvietimas pas vaiką iki 1 metų amžiaus"
      }, {
        code: 57,
        name: "57 - Pagalba kitoms tarnyboms"
      }, {
        code: 58,
        name: "58 - Gulintis žmogus"
      }, {
        code: 59,
        name: "59 - Mirties konstatavimas"
      }, {
        code: 60,
        name: "60 - Skubus pervežimas"
      }, {
        code: 61,
        name: "61 - Pervežimas"
      }, {
        code: 62,
        name: "62 - Komercinis pervežimas"
      }, {
        code: 63,
        name: "63 - Budėjimas renginyje, laidotuvėse"
      }]
    },
    delayReasons: delayReasons(t),
    delayReasonsToEventSite: delayReasons(t).concat([{
      code: "DISPATCHER",
      name: t("options.delayReasons.dispatcher")
    }, {
      code: "HELP_TO_TEAM",
      name: t("options.delayReasons.helpToTeam")
    }]),
    municipalities: [{
      name: "Druskininkų sav.",
      cities: ["Druskininkų m.", "Viečiūnų k.", "Neravų k."]
    }, {
      name: "Varėnos r. sav.",
      cities: ["Varėnos m.", "Senosios Varėnos k.", "Merkinės mstl.", "Matuizų k."]
    }, {
      name: "Lazdijų r. sav.",
      cities: ["Lazdijų m.", "Kapčiamiesčio mstl."]
    }, {
      name: "Alytaus r. sav.",
      cities: ["Butrimonių mstl.", "Miklusėnų k."]
    }, {
      name: "Alytaus m. sav.",
      cities: ["Alytaus m."]
    }, {
      name: "Jurbarko r. sav.",
      cities: ["Sarapiniškių k.", "Smalininkų m.", "Pašvenčio k."]
    }, {
      name: "Jonavos r. sav.",
      cities: ["Jonavos m."]
    }, {
      name: "Kauno r. sav.",
      cities: ["Garliavos m.", "Domeikavos k.", "Šakių k.", "Raudondvario k."]
    }, {
      name: "Kėdainių r. sav.",
      cities: ["Užupės k.", "Svilių k.", "Aristavos k."]
    }, {
      name: "Kaišiadorių r. sav.",
      cities: ["Žalvarnių k.", "Maisiejūnų k.", "Stabintiškių k."]
    }, {
      name: "Prienų r. sav.",
      cities: ["Tartupio k.", "Anglininkų k.", "Pelekonių k."]
    }, {
      name: "Kauno m. sav.",
      cities: ["Kauno m."]
    }, {
      name: "Raseinių r. sav.",
      cities: ["Bralinskių k.", "Ylių k."]
    }, {
      name: "Vilkaviškio r. sav.",
      cities: ["Stirnėnų k.", "Virbalio Miesto Laukų k.", "Vaišvilų k."]
    }, {
      name: "Birštono sav.",
      cities: ["Geležūnų k.", "Birštono m.", "Jundeliškių k."]
    }, {
      name: "Šakių r. sav.",
      cities: ["Barzdų mstl.", "Gerdžiūnų k.", "Kriūkų mstl."]
    }, {
      name: "Klaipėdos r. sav.",
      cities: ["Jakų k.", "Gindulių k.", "Agluonėnų k."]
    }, {
      name: "Klaipėdos m. sav.",
      cities: ["Klaipėdos m."]
    }, {
      name: "Palangos m. sav.",
      cities: ["Palangos m."]
    }, {
      name: "Šilutės r. sav.",
      cities: ["Šilutės m.", "Degučių k."]
    }, {
      name: "Kretingos r. sav.",
      cities: ["Padvarių k.", "Kretingsodžio k.", "Darbėnų mstl."]
    }, {
      name: "Skuodo r. sav.",
      cities: ["Lenkimų mstl.", "Skuodo m.", "Mosėdžio mstl."]
    }, {
      name: "Neringos sav.",
      cities: ["Neringos m."]
    }, {
      name: "Mažeikių r. sav.",
      cities: ["Sedos m.", "Mažeikių m.", "Balėnų k."]
    }, {
      name: "Pagėgių sav.",
      cities: ["Pagėgių m.", "Trakininkų k.", "Anužių k."]
    }, {
      name: "Plungės r. sav.",
      cities: ["Plungės m.", "Platelių mstl."]
    }, {
      name: "Rietavo sav.",
      cities: ["Rietavo m.", "Vatušių k.", "Bubėnų k."]
    }, {
      name: "Šiaulių r. sav.",
      cities: ["Kurtuvėnų mstl.", "Gilvyčių k."]
    }, {
      name: "Šiaulių m. sav.",
      cities: ["Šiaulių m."]
    }, {
      name: "Akmenės r. sav.",
      cities: ["Akmenės m.", "Naujosios Akmenės m."]
    }, {
      name: "Radviliškio r. sav.",
      cities: ["Grinkiškio mstl.", "Radviliškio m."]
    }, {
      name: "Pakruojo r. sav.",
      cities: ["Pakruojo m.", "Linkuvos m."]
    }, {
      name: "Joniškio r. sav.",
      cities: ["Joniškio m.", "Skaistgirio mstl."]
    }, {
      name: "Kelmės r. sav.",
      cities: ["Kražių mstl.", "Kelmės m."]
    }, {
      name: "Šilalės r. sav.",
      cities: ["Kvėdarnos mstl.", "Laukuvos mstl.", "Šilalės m."]
    }, {
      name: "Tauragės r. sav.",
      cities: ["Tauragės m.", "Taurų k.", "Butkelių k."]
    }, {
      name: "Telšių r. sav.",
      cities: ["Varnių m.", "Buožėnų k.", "Rainių k."]
    }, {
      name: "Vilniaus m. sav.",
      cities: ["Vilniaus m."]
    }, {
      name: "Vilniaus r. sav.",
      cities: ["Dūkštų k.", "Medininkų k."]
    }, {
      name: "Šalčininkų r. sav.",
      cities: ["Šalčininkų m.", "Jašiūnų mstl."]
    }, {
      name: "Elektrėnų sav.",
      cities: ["Medininkų k.", "Elektrėnų m."]
    }, {
      name: "Švenčionių r. sav.",
      cities: ["Kaltanėnų mstl.", "Švenčionėlių m."]
    }, {
      name: "Trakų r. sav.",
      cities: ["Lentvario m.", "Rūdiškių m.", "Trakų m."]
    }, {
      name: "Molėtų r. sav.",
      cities: ["Molėtų m."]
    }, {
      name: "Zarasų r. sav.",
      cities: ["Užtiltės k.", "Zarasų m."]
    }, {
      name: "Ignalinos r. sav.",
      cities: ["Ignalinos m."]
    }, {
      name: "Ukmergės r. sav.",
      cities: ["Sarapų k.", "Jogvilų k.", "Ukmergės m."]
    }, {
      name: "Širvintų r. sav.",
      cities: ["Zibalų mstl.", "Vindeikių k.", "Širvintų m."]
    }, {
      name: "Visagino sav.",
      cities: ["Visagino m.", "Magūnų k."]
    }],
    counties: [{
      code: "Alytaus apskr.",
      name: t("options.counties.alytus"),
      station: ["1", "15", "54"]
    }, {
      code: "Kauno apskr.",
      name: t("options.counties.kaunas"),
      station: ["15", "16", "10", "35", "55"]
    }, {
      code: "Klaipėdos apskr.",
      name: t("options.counties.klaipeda"),
      station: ["20", "21", "27", "41", "7", "46", "60"]
    }, {
      code: "Marijampolės apskr.",
      name: t("options.counties.marijampole"),
      station: ["24"]
    }, {
      code: "Panevėžio apskr.",
      name: t("options.counties.panevezys"),
      station: ["31", "32"]
    }, {
      code: "Tauragės apskr.",
      name: t("options.counties.taurage"),
      station: ["49"]
    }, {
      code: "Telšių apskr.",
      name: t("options.counties.telsiai"),
      station: ["50", "25", "34"]
    }, {
      code: "Utenos apskr.",
      name: t("options.counties.utena"),
      station: ["53", "9", "26"]
    }, {
      code: "Vilniaus apskr.",
      name: t("options.counties.vilnius"),
      station: ["8", "52", "56", "99", "51"]
    }, {
      code: "Šiaulių apskr.",
      name: t("options.counties.siauliai"),
      station: ["44", "45", "36", "29", "2", "11", "19"]
    }],
    patientPositions: [{
      code: "FIXED_ON_BACK",
      name: t("options.transportation.patientPosition.fixedOnBack"),
      favourite: 1
    }, {
      code: "FIXED_SITTING",
      name: t("options.transportation.patientPosition.fixedSitting"),
      favourite: 2
    }, {
      code: "FIXED_SEMI_SITTING",
      name: t("options.transportation.patientPosition.fixedSemiSitting")
    }, {
      code: "FIXED_ON_LEFT_SIDE",
      name: t("options.transportation.patientPosition.fixedOnLeftSide")
    }, {
      code: "ON_BACK",
      name: t("options.transportation.patientPosition.onBack")
    }, {
      code: "SITTING",
      name: t("options.transportation.patientPosition.sitting")
    }, {
      code: "SEMI_SITTING",
      name: t("options.transportation.patientPosition.semiSitting")
    }, {
      code: "ON_LEFT_SIDE",
      name: t("options.transportation.patientPosition.onLeftSide")
    }],
    patientToVehicle: [{
      code: "IN_CHAIR",
      name: t("options.transportation.toVehicle.inChair")
    }, {
      code: "ON_FOOT",
      name: t("options.transportation.toVehicle.himself")
    }, {
      code: "CARRIED",
      name: t("options.transportation.toVehicle.carried")
    }, {
      code: "WITH_STRETCHERS",
      name: t("options.transportation.toVehicle.stretchers")
    }, {
      code: "LYING",
      name: t("options.transportation.toVehicle.lying")
    }, {
      code: "WITH_LITTER",
      name: t("options.transportation.toVehicle.litter")
    }, {
      code: "WAS_NEAR_VEHICLE",
      name: t("options.transportation.toVehicle.alreadyInSite")
    }],
    fromDestination: [{
      code: "LIVING_PLACE",
      name: t("options.transportation.from.residence"),
      favourite: 1
    }, {
      code: "EVENT_SITE",
      name: t("options.transportation.from.incidentLocation"),
      favourite: 2
    }, {
      code: "PHC_FACILITY",
      name: t("options.transportation.from.phc")
    }, {
      code: "HOSPICE",
      name: t("options.transportation.from.hospice")
    }, {
      code: "HOSPITAL_TO_HOSPITAL",
      name: t("options.transportation.from.betweenHospitals")
    }, {
      code: "HOSPITAL_TO_HOME",
      name: t("options.transportation.from.fromHospital2residence")
    }, {
      code: "OTHER",
      name: t("options.transportation.from.other")
    }],
    teamTypes: [{
      code: "ALS",
      name: t("options.teamTypes.als")
    }, {
      code: "BLS",
      name: t("options.teamTypes.bls")
    }],
    eventCancelReasons: [{
      code: "REQUEST_DECLINED_BY_DISPATCHER",
      name: t("options.event.cancelReason.declinedByDispatcher")
    }, {
      code: "MISSION_ABORTED",
      name: t("options.event.cancelReason.missionAborted")
    }],
    forms: {
      trauma: {
        abcdEval: [{
          code: "SPO2_LT90",
          name: t(`${abcd}spo2Lt90`)
        }, {
          code: "BREATHING_FREQ_GT30_OR_LT8",
          name: t(`${abcd}breathingFreqGt30OrLt8`)
        }, {
          code: "HEART_RATE_GT120",
          name: t(`${abcd}heartRateGt120`)
        }, {
          code: "BLOOD_PRESS_LT90",
          name: t(`${abcd}bpLt90`)
        }, {
          code: "GCS_LT9",
          name: t(`${abcd}gcsLt9`)
        }],
        injuries: [{
          code: "PUNCTURE",
          name: t(`${traumaInjury}puncture`)
        }, {
          code: "FRACTURE",
          name: t(`${traumaInjury}fracture`)
        }, {
          code: "AMPUTATION",
          name: t(`${traumaInjury}amputation`)
        }, {
          code: "BURN",
          name: t(`${traumaInjury}burn`)
        }, {
          code: "UNSTABLE_CHEST",
          name: t(`${traumaInjury}chest`)
        }, {
          code: "UNSTABLE_PELVIS",
          name: t(`${traumaInjury}pelvis`)
        }, {
          code: "NEUROLOGICAL_SYMPTOMS",
          name: t(`${traumaInjury}neurological`)
        }],
        background: [{
          code: "PEDESTRIAN_OR_DRIVER",
          name: t(`${circumstance}pedestrianOrDriver`)
        }, {
          code: "EXPLOSION_OR_FIREFIGHT",
          name: t(`${circumstance}explosionOrFiring`)
        }, {
          code: "FALL_OR_DIVING",
          name: t(`${circumstance}fallOrDiving`)
        }, {
          code: "SAVING_INJURED",
          name: t(`${circumstance}rescuersAssistance`)
        }, {
          code: "IN_CAR_WITH_DEAD",
          name: t(`${circumstance}deadPplInCar`)
        }],
        safetyMeasures: [{
          code: "EYE_PROTECTION",
          name: t(`${safetyMeasures}eye`)
        }, {
          code: "HELMET",
          name: t(`${safetyMeasures}helmet`)
        }, {
          code: "PROTECTIVE_CLOTHES",
          name: t(`${safetyMeasures}clothing`)
        }, {
          code: "PROTECTIVE_EQUIPMENT",
          name: t(`${safetyMeasures}equipment`)
        }]
      },
      accident: {
        seatBelts: [{
          code: "PELVIS_PART",
          name: t(`${seatBelts}stomach`)
        }, {
          code: "OVER_SHOULDER",
          name: t(`${seatBelts}shoulder`)
        }],
        sittingPlaces: [{
          code: "DRIVER",
          name: t(`${sittingPlace}driver`)
        }, {
          code: "PASSENGER",
          name: t(`${sittingPlace}passenger`)
        }, {
          code: "BACKSEAT",
          name: t(`${sittingPlace}back`)
        }],
        airBags: [{
          code: "DRIVER",
          name: t(`${airBags}wheel`)
        }, {
          code: "PASSENGER",
          name: t(`${airBags}passenger`)
        }, {
          code: "DRIVE_DOOR",
          name: t(`${airBags}driver`)
        }, {
          code: "PASSENGER_DOOR",
          name: t(`${airBags}passengerDoor`)
        }, {
          code: "OTHER",
          name: t(`${airBags}other`)
        }]
      },
      birth: {
        apgar: [{
          code: 0,
          name: "0"
        }, {
          code: 1,
          name: "1"
        }, {
          code: 2,
          name: "2"
        }, {
          code: 3,
          name: "3"
        }, {
          code: 4,
          name: "4"
        }, {
          code: 5,
          name: "5"
        }, {
          code: 6,
          name: "6"
        }, {
          code: 7,
          name: "7"
        }, {
          code: 8,
          name: "8"
        }, {
          code: 9,
          name: "9"
        }, {
          code: 10,
          name: "10"
        }]
      },
      heartAttack: {
        painStart: [{
          code: true,
          name: `≤12 ${t(`${h}`)}`
        }, {
          code: false,
          name: `>12 ${t(`${h}`)}`
        }]
      },
      stroke: {
        duration: [{
          code: true,
          name: `≤3 ${t(`${h}`)}`
        }, {
          code: false,
          name: `>3 ${t(`${h}`)}`
        }]
      }
    },
    narrative: {
      type: [{
        code: "ACCIDENT",
        name: t("options.diagnosis.disorderType.accident")
      }, {
        code: "ACUTE_ILLNESS",
        name: t("options.diagnosis.disorderType.acuteIllness")
      }]
    },
    signatures: {
      confirmation: [{
        code: true,
        name: t("options.signatures.confirmation.yes"),
        favourite: 1
      }, {
        code: false,
        name: t("options.signatures.confirmation.no"),
        favourite: 2
      }],
      signatureTypes: [{
        code: "SIGNED_BY_PATIENT",
        name: t("options.signatures.signatureTypes.byPatient"),
        favourite: 1
      }, {
        code: "UNABLE_TO_SIGN",
        name: t("options.signatures.signatureTypes.unableToSign")
      }, {
        code: "REFUSES_TO_SIGN",
        name: t("options.signatures.signatureTypes.refusesToSign")
      }, {
        code: "SIGNED_BY_REPRESENTATIVE",
        name: t("options.signatures.signatureTypes.representative"),
        favourite: 2
      }]
    },
    flowchart: {
      inventoryList: [{
        code: 1,
        name: t(`${inventoryKey}gloves`)
      }, {
        code: 2,
        name: t(`${inventoryKey}scissors`)
      }],
      inventoryTypes: [{
        code: 1,
        name: t(`${inventoryKey}latex`)
      }, {
        code: 2,
        name: t(`${inventoryKey}disposable`)
      }],
      conditionChange: [{
        code: "NO_CHANGE",
        name: t(`${condition}unchanged`),
        favourite: 1
      }, {
        code: "POSITIVE_EFFECT",
        name: t(`${condition}improved`),
        favourite: 2
      }, {
        code: "NEGATIVE_EFFECT",
        name: t(`${condition}deteriorated`)
      }, {
        code: "HELP_REJECTED",
        name: t(`${condition}refused`)
      }],
      procedures: [{
        code: 1,
        name: t(`${procedure}1`),
        group: procedureA
      }, {
        code: 2,
        name: t(`${procedure}2`),
        group: procedureA
      }, {
        code: 3,
        name: t(`${procedure}3`),
        group: procedureA
      }, {
        code: 4,
        name: t(`${procedure}4`),
        group: procedureA
      }, {
        code: 5,
        name: t(`${procedure}5`),
        group: procedureA
      }, {
        code: 6,
        name: t(`${procedure}6`),
        group: procedureB
      }, {
        code: 7,
        name: t(`${procedure}7`),
        group: procedureB
      }, {
        code: 8,
        name: t(`${procedure}8`),
        group: procedureB
      }, {
        code: 9,
        name: t(`${procedure}9`),
        group: procedureB
      }, {
        code: 10,
        name: t(`${procedure}10`),
        group: procedureB
      }, {
        code: 11,
        name: t(`${procedure}11`),
        group: procedureB
      }, {
        code: 12,
        name: t(`${procedure}12`),
        group: procedureB
      }, {
        code: 13,
        name: t(`${procedure}13`),
        group: procedureB
      }, {
        code: 14,
        name: t(`${procedure}14`),
        group: procedureB
      }, {
        code: 15,
        name: t(`${procedure}15`),
        group: procedureB
      }, {
        code: 16,
        name: t(`${procedure}16`),
        group: procedureB
      }, {
        code: 17,
        name: t(`${procedure}17`),
        group: procedureC
      }, {
        code: 18,
        name: t(`${procedure}18`),
        group: procedureD
      }, {
        code: 19,
        name: t(`${procedure}19`),
        group: procedureD
      }, {
        code: 20,
        name: t(`${procedure}20`),
        group: procedureD
      }, {
        code: 21,
        name: t(`${procedure}21`),
        group: procedureD
      }, {
        code: 22,
        name: t(`${procedure}22`),
        group: procedureD
      }, {
        code: 23,
        name: t(`${procedure}23`),
        group: procedureD
      }, {
        code: 24,
        name: t(`${procedure}24`),
        group: procedureD
      }, {
        code: 25,
        name: t(`${procedure}25`),
        group: procedureD
      }, {
        code: 26,
        name: t(`${procedure}26`),
        group: procedureD
      }, {
        code: 27,
        name: t(`${procedure}27`),
        group: procedureD
      }, {
        code: 28,
        name: t(`${procedure}28`),
        group: procedureD
      }, {
        code: 29,
        name: t(`${procedure}29`),
        group: procedureD
      }, {
        code: 30,
        name: t(`${procedure}30`),
        group: procedureD
      }, {
        code: 31,
        name: t(`${procedure}31`),
        group: procedureD
      }, {
        code: 32,
        name: t(`${procedure}32`),
        group: procedureE
      }, {
        code: 33,
        name: t(`${procedure}33`),
        group: procedureE
      }, {
        code: 34,
        name: t(`${procedure}34`),
        group: procedureE
      }, {
        code: 35,
        name: t(`${procedure}35`),
        group: procedureE
      }, {
        code: 36,
        name: t(`${procedure}36`),
        group: procedureE
      }, {
        code: 37,
        name: t(`${procedure}37`),
        group: procedureE
      }, {
        code: 38,
        name: t(`${procedure}38`),
        group: procedureE
      }, {
        code: 39,
        name: t(`${procedure}39`),
        group: procedureF
      }, {
        code: 40,
        name: t(`${procedure}40`),
        group: procedureF
      }, {
        code: 41,
        name: t(`${procedure}41`),
        group: procedureF
      }, {
        code: 42,
        name: t(`${procedure}42`),
        group: procedureF
      }]
    },
    death: {
      witnesses: [{
        code: "LAW_ENF",
        name: t(`${clinicalDeath}officer`)
      }, {
        code: "EMS",
        name: t(`${clinicalDeath}ambulance`)
      }, {
        code: "OTHER",
        name: t(`${clinicalDeath}other`)
      }],
      restoredBloodCirculationBy: [{
        code: "LAW_ENF",
        name: t(`${clinicalDeath}officer`)
      }, {
        code: "EMS",
        name: t(`${clinicalDeath}ambulance`)
      }, {
        code: "OTHER",
        name: t(`${clinicalDeath}other`)
      }],
      cprStartedBy: [{
        code: "BYSTANDER",
        name: t(`${clinicalDeath}witness`)
      }, {
        code: "FAMILY",
        name: t(`${clinicalDeath}family`)
      }, {
        code: "LAW_ENF",
        name: t(`${clinicalDeath}officer`)
      }, {
        code: "FIRE_RESCUE",
        name: t(`${clinicalDeath}fireService`)
      }, {
        code: "EMS",
        name: t(`${clinicalDeath}ambulance`)
      }, {
        code: "MEDICAL_STAFF",
        name: t(`${clinicalDeath}medPersonnel`)
      }],
      cprUsedAed: [{
        code: "LAW_ENF",
        name: t(`${clinicalDeath}officer`)
      }, {
        code: "FIRE_RESCUE",
        name: t(`${clinicalDeath}fireService`)
      }, {
        code: "EMS",
        name: t(`${clinicalDeath}ambulance`)
      }, {
        code: "OTHER",
        name: t(`${clinicalDeath}other`)
      }],
      options: [{
        code: true,
        name: t(`${clinicalDeath}yes`)
      }, {
        code: false,
        name: t(`${clinicalDeath}no`)
      }]
    },
    deathSigns: [{
      code: "DECOMPOSITION",
      name: t("options.deathSigns.decomposition")
    }, {
      code: "DEATH_MARKS",
      name: t("options.deathSigns.deathMarks")
    }, {
      code: "BODY_FROZEN",
      name: t("options.deathSigns.bodyFrozen")
    }, {
      code: "DEADLY_INJURIES",
      name: t("options.deathSigns.deadlyInjuries")
    }],
    deliveryType: [{
      code: "DIRECT",
      name: t("options.event.deliveryType.direct")
    }, {
      code: "VIA_PHONE",
      name: t("options.event.deliveryType.viaPhone")
    }, {
      code: "VIA_RADIO",
      name: t("options.event.deliveryType.viaRadio")
    }],
    sideDiseases: [{
      code: "ASTHMA",
      name: t("options.knownDiseases.asthma")
    }, {
      code: "TBC",
      name: t("options.knownDiseases.tbc")
    }, {
      code: "HYPERTENSION",
      name: t("options.knownDiseases.hypertension")
    }, {
      code: "DIABETES",
      name: t("options.knownDiseases.diabetes")
    }, {
      code: "ALLERGY",
      name: t("options.knownDiseases.allergy")
    }, {
      code: "COPD",
      name: t("options.knownDiseases.copd")
    }, {
      code: "IHD",
      name: t("options.knownDiseases.ihd")
    }, {
      code: "RHYTHM_DISORDER",
      name: t("options.knownDiseases.rhythmDisorder")
    }, {
      code: "CA",
      name: t("options.knownDiseases.ca")
    }, {
      code: "OTHER",
      name: t("options.knownDiseases.other")
    }],
    phc: [{
      code: "OTHER",
      name: "Kita"
    }, {
      code: "DAINAVOS_POLIKLINIKA",
      name: "Kauno Dainavos poliklinika"
    }, {
      code: "KALNIECIU_POLIKLINIKA",
      name: "Kauno Kalniečių poliklinika"
    }],
    helpProtocol: [{
      code: 16,
      name: t(`${helpProtocol}eye`),
      shortName: t(`${helpProtocolShort}Eye`)
    }, {
      code: 2,
      name: t(`${helpProtocol}allergies`),
      shortName: t(`${helpProtocolShort}Allergies`)
    }, {
      code: 13,
      name: t(`${helpProtocol}diabetic`),
      shortName: t(`${helpProtocolShort}Diabetic`)
    }, {
      code: 27,
      name: t(`${helpProtocol}penetrating`),
      shortName: t(`${helpProtocolShort}Penetrating`)
    }, {
      code: 29,
      name: t(`${helpProtocol}transportation`),
      shortName: t(`${helpProtocolShort}Transportation`)
    }, {
      code: 15,
      name: t(`${helpProtocol}lightning`),
      shortName: t(`${helpProtocolShort}Lightning`)
    }, {
      code: 18,
      name: t(`${helpProtocol}headache`),
      shortName: t(`${helpProtocolShort}Headache`)
    }, {
      code: 3,
      name: t(`${helpProtocol}animal`),
      shortName: t(`${helpProtocolShort}Animal`)
    }, {
      code: 8,
      name: t(`${helpProtocol}inhalation`),
      shortName: t(`${helpProtocolShort}Inhalation`)
    }, {
      code: 28,
      name: t(`${helpProtocol}stroke`),
      shortName: t(`${helpProtocolShort}Stroke`)
    }, {
      code: 20,
      name: t(`${helpProtocol}heatOrCold`),
      shortName: t(`${helpProtocolShort}HeatOrCold`)
    }, {
      code: 21,
      name: t(`${helpProtocol}bleeding`),
      shortName: t(`${helpProtocolShort}Bleeding`)
    }, {
      code: 17,
      name: t(`${helpProtocol}fall`),
      shortName: t(`${helpProtocolShort}Fall`)
    }, {
      code: 10,
      name: t(`${helpProtocol}chest`),
      shortName: t(`${helpProtocolShort}Chest`)
    }, {
      code: 6,
      name: t(`${helpProtocol}breathing`),
      shortName: t(`${helpProtocolShort}Breathing`)
    }, {
      code: 32,
      name: t(`${helpProtocol}unknown`),
      shortName: t(`${helpProtocolShort}Unknown`)
    }, {
      code: 26,
      name: t(`${helpProtocol}sick`),
      shortName: t(`${helpProtocolShort}Sick`)
    }, {
      code: 22,
      name: t(`${helpProtocol}trapped`),
      shortName: t(`${helpProtocolShort}Trapped`)
    }, {
      code: 24,
      name: t(`${helpProtocol}pregnancy`),
      shortName: t(`${helpProtocolShort}Pregnancy`)
    }, {
      code: 7,
      name: t(`${helpProtocol}burn`),
      shortName: t(`${helpProtocolShort}Burn`)
    }, {
      code: 5,
      name: t(`${helpProtocol}back`),
      shortName: t(`${helpProtocolShort}Back`)
    }, {
      code: 36,
      name: t(`${helpProtocol}fluPandemic`),
      shortName: t(`${helpProtocolShort}FluPandemic`)
    }, {
      code: 23,
      name: t(`${helpProtocol}overdose`),
      shortName: t(`${helpProtocolShort}Overdose`)
    }, {
      code: 33,
      name: t(`${helpProtocol}transfer`),
      shortName: t(`${helpProtocolShort}Transfer`)
    }, {
      code: 1,
      name: t(`${helpProtocol}abdominal`),
      shortName: t(`${helpProtocolShort}Abdominal`)
    }, {
      code: 25,
      name: t(`${helpProtocol}psychiatric`),
      shortName: t(`${helpProtocolShort}Psychiatric`)
    }, {
      code: 31,
      name: t(`${helpProtocol}unconscious`),
      shortName: t(`${helpProtocolShort}Unconscious`)
    }, {
      code: 14,
      name: t(`${helpProtocol}drowning`),
      shortName: t(`${helpProtocolShort}Drowning`)
    }, {
      code: 11,
      name: t(`${helpProtocol}choking`),
      shortName: t(`${helpProtocolShort}Choking`)
    }, {
      code: 9,
      name: t(`${helpProtocol}death`),
      shortName: t(`${helpProtocolShort}Death`)
    }, {
      code: 19,
      name: t(`${helpProtocol}aicd`),
      shortName: t(`${helpProtocolShort}Aicd`)
    }, {
      code: 12,
      name: t(`${helpProtocol}seizures`),
      shortName: t(`${helpProtocolShort}Seizures`)
    }, {
      code: 30,
      name: t(`${helpProtocol}traumatic`),
      shortName: t(`${helpProtocolShort}Traumatic`)
    }, {
      code: 4,
      name: t(`${helpProtocol}assault`),
      shortName: t(`${helpProtocolShort}Assault`)
    }],
    strokeContraindication: [{
      code: 1,
      name: t(`${strokeKey}1`)
    }, {
      code: 2,
      name: t(`${strokeKey}2`)
    }, {
      code: 3,
      name: t(`${strokeKey}3`)
    }, {
      code: 4,
      name: t(`${strokeKey}4`)
    }, {
      code: 5,
      name: t(`${strokeKey}5`)
    }, {
      code: 6,
      name: t(`${strokeKey}6`)
    }, {
      code: 7,
      name: t(`${strokeKey}7`)
    }, {
      code: 8,
      name: t(`${strokeKey}8`)
    }, {
      code: 9,
      name: t(`${strokeKey}9`)
    }],
    heartAttackContraindication: [{
      code: 50,
      name: t(`${coronaryContraindication}1`)
    }, {
      code: 51,
      name: t(`${coronaryContraindication}2`)
    }, {
      code: 52,
      name: t(`${coronaryContraindication}3`)
    }, {
      code: 53,
      name: t(`${coronaryContraindication}4`)
    }, {
      code: 54,
      name: t(`${coronaryContraindication}5`)
    }, {
      code: 55,
      name: t(`${coronaryContraindication}6`)
    }, {
      code: 56,
      name: t(`${coronaryContraindication}7`)
    }, {
      code: 57,
      name: t(`${coronaryContraindication}8`)
    }, {
      code: 58,
      name: t(`${coronaryContraindication}9`)
    }, {
      code: 59,
      name: t(`${coronaryContraindication}10`)
    }, {
      code: 60,
      name: t(`${coronaryContraindication}11`)
    }, {
      code: 61,
      name: t(`${coronaryContraindication}12`)
    }, {
      code: 62,
      name: t(`${coronaryContraindication}13`)
    }, {
      code: 63,
      name: t(`${coronaryContraindication}14`)
    }, {
      code: 64,
      name: t(`${coronaryContraindication}15`)
    }, {
      code: 65,
      name: t(`${coronaryContraindication}16`)
    }, {
      code: 66,
      name: t(`${coronaryContraindication}17`)
    }, {
      code: 67,
      name: t(`${coronaryContraindication}18`)
    }, {
      code: 68,
      name: t(`${coronaryContraindication}19`)
    }],
    additionalInformation: {
      transportation: [{
        code: "FROM_BORDER_TO_PHC",
        name: t("options.transportation.neighbouringCountry.border2phc"),
        favourite: 1
      }, {
        code: "FROM_PHC_TO_BORDER",
        name: t("options.transportation.neighbouringCountry.phc2border"),
        favourite: 2
      }]
    },
    stationTeamIds: {
      "10": [{
        code: "1001",
        name: "1001"
      }, {
        code: "1002",
        name: "1002"
      }, {
        code: "1003",
        name: "1003"
      }, {
        code: "1004",
        name: "1004"
      }, {
        code: "1005",
        name: "1005"
      }, {
        code: "1006",
        name: "1006"
      }],
      "13": [{
        code: "1301",
        name: "1301"
      }, {
        code: "1302",
        name: "1302"
      }, {
        code: "1303",
        name: "1303"
      }, {
        code: "1304",
        name: "1304"
      }, {
        code: "1305",
        name: "1305"
      }, {
        code: "1306",
        name: "1306"
      }],
      "15": [{
        code: "1501",
        name: "1501"
      }, {
        code: "1502",
        name: "1502"
      }, {
        code: "1503",
        name: "1503"
      }, {
        code: "1504",
        name: "1504"
      }, {
        code: "1505",
        name: "1505"
      }, {
        code: "1506",
        name: "1506"
      }, {
        code: "1507",
        name: "1507"
      }, {
        code: "1508",
        name: "1508"
      }, {
        code: "1509",
        name: "1509"
      }, {
        code: "1510",
        name: "1510"
      }, {
        code: "1511",
        name: "1511"
      }, {
        code: "1512",
        name: "1512"
      }, {
        code: "1513",
        name: "1513"
      }, {
        code: "1514",
        name: "1514"
      }, {
        code: "1515",
        name: "1515"
      }, {
        code: "1516",
        name: "1516"
      }, {
        code: "1517",
        name: "1517"
      }, {
        code: "1518",
        name: "1518"
      }, {
        code: "1519",
        name: "1519"
      }, {
        code: "1520",
        name: "1520"
      }, {
        code: "1521",
        name: "1521"
      }, {
        code: "1522",
        name: "1522"
      }, {
        code: "1523",
        name: "1523"
      }, {
        code: "1524",
        name: "1524"
      }, {
        code: "1525",
        name: "1525"
      }, {
        code: "1526",
        name: "1526"
      }, {
        code: "1527",
        name: "1527"
      }, {
        code: "1528",
        name: "1528"
      }, {
        code: "1529",
        name: "1529"
      }],
      "16": [{
        code: "1601",
        name: "1601"
      }, {
        code: "1602",
        name: "1602"
      }, {
        code: "1603",
        name: "1603"
      }, {
        code: "1604",
        name: "1604"
      }, {
        code: "1605",
        name: "1605"
      }, {
        code: "1606",
        name: "1606"
      }, {
        code: "1607",
        name: "1607"
      }, {
        code: "1608",
        name: "1608"
      }, {
        code: "1609",
        name: "1609"
      }, {
        code: "1610",
        name: "1610"
      }, {
        code: "1611",
        name: "1611"
      }, {
        code: "1612",
        name: "1612"
      }, {
        code: "1613",
        name: "1613"
      }, {
        code: "1614",
        name: "1614"
      }, {
        code: "1615",
        name: "1615"
      }],
      "18": [{
        code: "1801",
        name: "1801"
      }, {
        code: "1802",
        name: "1802"
      }, {
        code: "1803",
        name: "1803"
      }, {
        code: "1804",
        name: "1804"
      }, {
        code: "1805",
        name: "1805"
      }, {
        code: "1806",
        name: "1806"
      }, {
        code: "1807",
        name: "1807"
      }, {
        code: "1808",
        name: "1808"
      }],
      "35": [{
        code: "3501",
        name: "3501"
      }, {
        code: "3502",
        name: "3502"
      }, {
        code: "3503",
        name: "3503"
      }, {
        code: "3504",
        name: "3504"
      }, {
        code: "3505",
        name: "3505"
      }, {
        code: "3506",
        name: "3506"
      }, {
        code: "3507",
        name: "3507"
      }, {
        code: "3508",
        name: "3508"
      }, {
        code: "3509",
        name: "3509"
      }],
      "37": [{
        code: "3701",
        name: "3701"
      }, {
        code: "3702",
        name: "3702"
      }, {
        code: "3703",
        name: "3703"
      }, {
        code: "3704",
        name: "3704"
      }, {
        code: "3705",
        name: "3705"
      }],
      "42": [{
        code: "4201",
        name: "4201"
      }, {
        code: "4202",
        name: "4202"
      }, {
        code: "4203",
        name: "4203"
      }, {
        code: "4204",
        name: "4204"
      }, {
        code: "4205",
        name: "4205"
      }, {
        code: "4206",
        name: "4206"
      }],
      "49": [{
        code: "4901",
        name: "4901"
      }, {
        code: "4902",
        name: "4902"
      }, {
        code: "4903",
        name: "4903"
      }, {
        code: "4904",
        name: "4904"
      }, {
        code: "4905",
        name: "4905"
      }, {
        code: "4906",
        name: "4906"
      }, {
        code: "4907",
        name: "4907"
      }, {
        code: "4908",
        name: "4908"
      }],
      "55": [{
        code: "5501",
        name: "5501"
      }, {
        code: "5502",
        name: "5502"
      }, {
        code: "5503",
        name: "5503"
      }, {
        code: "5504",
        name: "5504"
      }, {
        code: "5505",
        name: "5505"
      }, {
        code: "5506",
        name: "5506"
      }],
      "58": [{
        code: "5801",
        name: "5801"
      }, {
        code: "5802",
        name: "5802"
      }, {
        code: "5803",
        name: "5803"
      }, {
        code: "5804",
        name: "5804"
      }],
      "12": [{
        code: "1201",
        name: "1201"
      }, {
        code: "1202",
        name: "1202"
      }, {
        code: "1203",
        name: "1203"
      }],
      "44": [{
        code: "4401",
        name: "4401"
      }, {
        code: "4402",
        name: "4402"
      }, {
        code: "4403",
        name: "4403"
      }, {
        code: "4404",
        name: "4404"
      }, {
        code: "4405",
        name: "4405"
      }, {
        code: "4406",
        name: "4406"
      }, {
        code: "4407",
        name: "4407"
      }, {
        code: "4408",
        name: "4408"
      }, {
        code: "4409",
        name: "4409"
      }, {
        code: "4410",
        name: "4410"
      }, {
        code: "4411",
        name: "4411"
      }, {
        code: "4412",
        name: "4412"
      }, {
        code: "4413",
        name: "4413"
      }, {
        code: "4414",
        name: "4414"
      }, {
        code: "4415",
        name: "4415"
      }, {
        code: "4416",
        name: "4416"
      }, {
        code: "4417",
        name: "4417"
      }, {
        code: "4418",
        name: "4418"
      }, {
        code: "4419",
        name: "4419"
      }],
      "45": [{
        code: "4501",
        name: "4501"
      }, {
        code: "4502",
        name: "4502"
      }, {
        code: "4503",
        name: "4503"
      }, {
        code: "4504",
        name: "4504"
      }, {
        code: "4505",
        name: "4505"
      }, {
        code: "4506",
        name: "4506"
      }],
      "19": [{
        code: "1901",
        name: "1901"
      }, {
        code: "1902",
        name: "1902"
      }, {
        code: "1903",
        name: "1903"
      }, {
        code: "1904",
        name: "1904"
      }, {
        code: "1905",
        name: "1905"
      }],
      "2": [{
        code: "0201",
        name: "0201"
      }, {
        code: "0202",
        name: "0202"
      }, {
        code: "0203",
        name: "0203"
      }, {
        code: "0204",
        name: "0204"
      }, {
        code: "0205",
        name: "0205"
      }, {
        code: "0206",
        name: "0206"
      }],
      "11": [{
        code: "1101",
        name: "1101"
      }, {
        code: "1102",
        name: "1102"
      }, {
        code: "1103",
        name: "1103"
      }, {
        code: "1104",
        name: "1104"
      }, {
        code: "1105",
        name: "1105"
      }, {
        code: "1106",
        name: "1106"
      }],
      "34": [{
        code: "3401",
        name: "3401"
      }, {
        code: "3402",
        name: "3402"
      }, {
        code: "3403",
        name: "3403"
      }, {
        code: "3404",
        name: "3404"
      }, {
        code: "3405",
        name: "3405"
      }, {
        code: "3406",
        name: "3406"
      }],
      "36": [{
        code: "3601",
        name: "3601"
      }, {
        code: "3602",
        name: "3602"
      }, {
        code: "3603",
        name: "3603"
      }, {
        code: "3604",
        name: "3604"
      }, {
        code: "3605",
        name: "3605"
      }, {
        code: "3606",
        name: "3606"
      }],
      "29": [{
        code: "2901",
        name: "2901"
      }, {
        code: "2902",
        name: "2902"
      }, {
        code: "2903",
        name: "2903"
      }, {
        code: "2904",
        name: "2904"
      }],
      "25": [{
        code: "2501",
        name: "2501"
      }, {
        code: "2502",
        name: "2502"
      }, {
        code: "2503",
        name: "2503"
      }, {
        code: "2504",
        name: "2504"
      }, {
        code: "2505",
        name: "2505"
      }, {
        code: "2506",
        name: "2506"
      }, {
        code: "2507",
        name: "2507"
      }],
      "50": [{
        code: "5001",
        name: "5001"
      }, {
        code: "5002",
        name: "5002"
      }, {
        code: "5003",
        name: "5003"
      }, {
        code: "5004",
        name: "5004"
      }, {
        code: "5005",
        name: "5005"
      }, {
        code: "5006",
        name: "5006"
      }],
      "1": [{
        code: "0101",
        name: "0101"
      }, {
        code: "0102",
        name: "0102"
      }, {
        code: "0103",
        name: "0103"
      }, {
        code: "0104",
        name: "0104"
      }, {
        code: "0105",
        name: "0105"
      }, {
        code: "0106",
        name: "0106"
      }, {
        code: "0107",
        name: "0107"
      }, {
        code: "0108",
        name: "0108"
      }, {
        code: "0109",
        name: "0109"
      }, {
        code: "0110",
        name: "0110"
      }, {
        code: "0111",
        name: "0111"
      }, {
        code: "0112",
        name: "0112"
      }, {
        code: "0113",
        name: "0113"
      }],
      "5": [{
        code: "0501",
        name: "0501"
      }, {
        code: "0502",
        name: "0502"
      }, {
        code: "0503",
        name: "0503"
      }],
      "23": [{
        code: "2301",
        name: "2301"
      }, {
        code: "2302",
        name: "2302"
      }, {
        code: "2303",
        name: "2303"
      }],
      "54": [{
        code: "5401",
        name: "5401"
      }, {
        code: "5402",
        name: "5402"
      }, {
        code: "5403",
        name: "5403"
      }, {
        code: "5404",
        name: "5404"
      }],
      "20": [{
        code: "2001",
        name: "2001"
      }, {
        code: "2002",
        name: "2002"
      }, {
        code: "2003",
        name: "2003"
      }, {
        code: "2004",
        name: "2004"
      }, {
        code: "2005",
        name: "2005"
      }, {
        code: "2006",
        name: "2006"
      }, {
        code: "2007",
        name: "2007"
      }, {
        code: "2008",
        name: "2008"
      }, {
        code: "2009",
        name: "2009"
      }, {
        code: "2010",
        name: "2010"
      }, {
        code: "2011",
        name: "2011"
      }, {
        code: "2012",
        name: "2012"
      }, {
        code: "2013",
        name: "2013"
      }, {
        code: "2014",
        name: "2014"
      }, {
        code: "2015",
        name: "2015"
      }, {
        code: "2016",
        name: "2016"
      }, {
        code: "2017",
        name: "2017"
      }],
      "21": [{
        code: "2101",
        name: "2101"
      }, {
        code: "2102",
        name: "2102"
      }, {
        code: "2103",
        name: "2103"
      }, {
        code: "2104",
        name: "2104"
      }, {
        code: "2105",
        name: "2105"
      }, {
        code: "2106",
        name: "2106"
      }, {
        code: "2107",
        name: "2107"
      }],
      "27": [{
        code: "2701",
        name: "2701"
      }, {
        code: "2702",
        name: "2702"
      }, {
        code: "2703",
        name: "2703"
      }, {
        code: "2704",
        name: "2704"
      }],
      "41": [{
        code: "4101",
        name: "4101"
      }, {
        code: "4102",
        name: "4102"
      }, {
        code: "4103",
        name: "4103"
      }, {
        code: "4104",
        name: "4104"
      }],
      "7": [{
        code: "0701",
        name: "0701"
      }, {
        code: "0702",
        name: "0702"
      }, {
        code: "0703",
        name: "0703"
      }, {
        code: "0704",
        name: "0704"
      }, {
        code: "0705",
        name: "0705"
      }, {
        code: "0706",
        name: "0706"
      }],
      "46": [{
        code: "4601",
        name: "4601"
      }, {
        code: "4602",
        name: "4602"
      }, {
        code: "4603",
        name: "4603"
      }, {
        code: "4604",
        name: "4604"
      }, {
        code: "4605",
        name: "4605"
      }],
      "60": [{
        code: "6001",
        name: "6001"
      }, {
        code: "6002",
        name: "6002"
      }, {
        code: "6003",
        name: "6003"
      }],
      "6": [{
        code: "0601",
        name: "0601"
      }, {
        code: "0602",
        name: "0602"
      }, {
        code: "0603",
        name: "0603"
      }, {
        code: "0604",
        name: "0604"
      }, {
        code: "0605",
        name: "0605"
      }, {
        code: "0606",
        name: "0606"
      }],
      "8": [{
        code: "0801",
        name: "0801"
      }, {
        code: "0802",
        name: "0802"
      }, {
        code: "0803",
        name: "0803"
      }, {
        code: "0804",
        name: "0804"
      }, {
        code: "0805",
        name: "0805"
      }, {
        code: "0806",
        name: "0806"
      }, {
        code: "0807",
        name: "0807"
      }, {
        code: "0808",
        name: "0808"
      }, {
        code: "0809",
        name: "0809"
      }, {
        code: "0810",
        name: "0810"
      }, {
        code: "0811",
        name: "0811"
      }, {
        code: "0812",
        name: "0812"
      }, {
        code: "0813",
        name: "0813"
      }, {
        code: "0814",
        name: "0814"
      }, {
        code: "0815",
        name: "0815"
      }, {
        code: "0816",
        name: "0816"
      }, {
        code: "0817",
        name: "0817"
      }, {
        code: "0818",
        name: "0818"
      }, {
        code: "0819",
        name: "0819"
      }, {
        code: "0820",
        name: "0820"
      }, {
        code: "0821",
        name: "0821"
      }, {
        code: "0822",
        name: "0822"
      }, {
        code: "0823",
        name: "0823"
      }, {
        code: "0824",
        name: "0824"
      }, {
        code: "0825",
        name: "0825"
      }, {
        code: "0826",
        name: "0826"
      }, {
        code: "0827",
        name: "0827"
      }, {
        code: "0828",
        name: "0828"
      }, {
        code: "0829",
        name: "0829"
      }, {
        code: "0830",
        name: "0830"
      }, {
        code: "0832",
        name: "0832"
      }, {
        code: "0833",
        name: "0833"
      }, {
        code: "0840",
        name: "0840"
      }, {
        code: "0841",
        name: "0841"
      }, {
        code: "0842",
        name: "0842"
      }, {
        code: "0843",
        name: "0843"
      }, {
        code: "0844",
        name: "0844"
      }, {
        code: "0845",
        name: "0845"
      }, {
        code: "0851",
        name: "0851"
      }, {
        code: "0852",
        name: "0852"
      }, {
        code: "0853",
        name: "0853"
      }, {
        code: "0871",
        name: "0871"
      }, {
        code: "0872",
        name: "0872"
      }, {
        code: "0873",
        name: "0873"
      }, {
        code: "0880",
        name: "0880"
      }, {
        code: "0881",
        name: "0881"
      }, {
        code: "0882",
        name: "0882"
      }, {
        code: "0883",
        name: "0883"
      }, {
        code: "0884",
        name: "0884"
      }, {
        code: "0885",
        name: "0885"
      }, {
        code: "0892",
        name: "0892"
      }, {
        code: "0893",
        name: "0893"
      }, {
        code: "0894",
        name: "0894"
      }, {
        code: "0895",
        name: "0895"
      }, {
        code: "0899",
        name: "0899"
      }],
      "26": [{
        code: "2601",
        name: "2601"
      }, {
        code: "2602",
        name: "2602"
      }, {
        code: "2603",
        name: "2603"
      }, {
        code: "2604",
        name: "2604"
      }],
      "43": [{
        code: "4301",
        name: "4301"
      }, {
        code: "4302",
        name: "4302"
      }, {
        code: "4303",
        name: "4303"
      }, {
        code: "4304",
        name: "4304"
      }, {
        code: "4305",
        name: "4305"
      }, {
        code: "4306",
        name: "4306"
      }],
      "51": [{
        code: "5101",
        name: "5101"
      }, {
        code: "5102",
        name: "5102"
      }, {
        code: "5103",
        name: "5103"
      }, {
        code: "5104",
        name: "5104"
      }, {
        code: "5105",
        name: "5105"
      }],
      "52": [{
        code: "5201",
        name: "5201"
      }, {
        code: "5202",
        name: "5202"
      }, {
        code: "5203",
        name: "5203"
      }, {
        code: "5204",
        name: "5204"
      }, {
        code: "5205",
        name: "5205"
      }],
      "56": [{
        code: "5601",
        name: "5601"
      }, {
        code: "5602",
        name: "5602"
      }, {
        code: "5603",
        name: "5603"
      }, {
        code: "5604",
        name: "5604"
      }, {
        code: "5605",
        name: "5605"
      }, {
        code: "5606",
        name: "5606"
      }, {
        code: "5607",
        name: "5607"
      }, {
        code: "5608",
        name: "5608"
      }, {
        code: "5609",
        name: "5609"
      }, {
        code: "5610",
        name: "5610"
      }],
      "57": [{
        code: "5701",
        name: "5701"
      }, {
        code: "5702",
        name: "5702"
      }, {
        code: "5703",
        name: "5703"
      }],
      "59": [{
        code: "5901",
        name: "5901"
      }, {
        code: "5902",
        name: "5902"
      }, {
        code: "5903",
        name: "5903"
      }],
      "53": [{
        code: "5301",
        name: "5301"
      }, {
        code: "5302",
        name: "5302"
      }, {
        code: "5303",
        name: "5303"
      }, {
        code: "5304",
        name: "5304"
      }, {
        code: "5305",
        name: "5305"
      }],
      "9": [{
        code: "0901",
        name: "0901"
      }, {
        code: "0902",
        name: "0902"
      }, {
        code: "0903",
        name: "0903"
      }, {
        code: "0904",
        name: "0904"
      }],
      "31": [{
        code: "3101",
        name: "3101"
      }, {
        code: "3102",
        name: "3102"
      }, {
        code: "3103",
        name: "3103"
      }, {
        code: "3104",
        name: "3104"
      }, {
        code: "3105",
        name: "3105"
      }, {
        code: "3106",
        name: "3106"
      }, {
        code: "3107",
        name: "3107"
      }, {
        code: "3108",
        name: "3108"
      }, {
        code: "3109",
        name: "3109"
      }, {
        code: "3110",
        name: "3110"
      }],
      "32": [{
        code: "3201",
        name: "3201"
      }, {
        code: "3202",
        name: "3202"
      }, {
        code: "3203",
        name: "3203"
      }, {
        code: "3204",
        name: "3204"
      }, {
        code: "3205",
        name: "3205"
      }],
      "33": [{
        code: "3301",
        name: "3301"
      }, {
        code: "3302",
        name: "3302"
      }, {
        code: "3303",
        name: "3303"
      }, {
        code: "3304",
        name: "3304"
      }],
      "4": [{
        code: "0401",
        name: "0401"
      }, {
        code: "0402",
        name: "0402"
      }, {
        code: "0403",
        name: "0403"
      }, {
        code: "0404",
        name: "0404"
      }],
      "22": [{
        code: "2201",
        name: "2201"
      }, {
        code: "2202",
        name: "2202"
      }, {
        code: "2203",
        name: "2203"
      }, {
        code: "2204",
        name: "2204"
      }],
      "39": [{
        code: "3901",
        name: "3901"
      }, {
        code: "3902",
        name: "3902"
      }, {
        code: "3903",
        name: "3903"
      }, {
        code: "3904",
        name: "3904"
      }],
      "3": [{
        code: "0301",
        name: "0301"
      }, {
        code: "0302",
        name: "0302"
      }, {
        code: "0303",
        name: "0303"
      }],
      "24": [{
        code: "2401",
        name: "2401"
      }, {
        code: "2402",
        name: "2402"
      }, {
        code: "2403",
        name: "2403"
      }, {
        code: "2404",
        name: "2404"
      }, {
        code: "2405",
        name: "2405"
      }, {
        code: "2406",
        name: "2406"
      }, {
        code: "2407",
        name: "2407"
      }, {
        code: "2408",
        name: "2408"
      }, {
        code: "2409",
        name: "2409"
      }, {
        code: "2410",
        name: "2410"
      }, {
        code: "2411",
        name: "2411"
      }],
      "99": [{
        code: "9990",
        name: "9990"
      }, {
        code: "9991",
        name: "9991"
      }, {
        code: "9992",
        name: "9992"
      }, {
        code: "9993",
        name: "9993"
      }, {
        code: "9994",
        name: "9994"
      }, {
        code: "9995",
        name: "9995"
      }, {
        code: "9996",
        name: "9996"
      }, {
        code: "9997",
        name: "9997"
      }, {
        code: "9998",
        name: "9998"
      }, {
        code: "9999",
        name: "9999"
      }]
    },
    coordinatesByStationId: [{
      code: "1",
      longitude: 24.039149,
      latitude: 54.398480
    }, {
      code: "2",
      longitude: 22.869267,
      latitude: 56.320085
    }, {
      code: "3",
      longitude: 25.100529,
      latitude: 55.522441
    }, {
      code: "4",
      longitude: 24.759224,
      latitude: 56.188896
    }, {
      code: "5",
      longitude: 23.987020,
      latitude: 54.013266
    }, {
      code: "6",
      longitude: 24.666007,
      latitude: 54.786560
    }, {
      code: "7",
      longitude: 21.402028,
      latitude: 55.710029
    }, {
      code: "8",
      longitude: 25.2796514,
      latitude: 54.6871555
    }, {
      code: "9",
      longitude: 26.151786,
      latitude: 55.341623
    }, {
      code: "10",
      longitude: 24.271080,
      latitude: 55.081587
    }, {
      code: "11",
      longitude: 23.628163,
      latitude: 56.232926
    }, {
      code: "12",
      longitude: 22.750679,
      latitude: 55.084778
    }, {
      code: "13",
      longitude: 24.429419,
      latitude: 54.854072
    }, {
      code: "15",
      longitude: 23.984344,
      latitude: 54.914921
    }, {
      code: "16",
      longitude: 23.957825,
      latitude: 54.923778
    }, {
      code: "18",
      longitude: 23.984586,
      latitude: 55.308879
    }, {
      code: "19",
      longitude: 22.946985,
      latitude: 55.631056
    }, {
      code: "20",
      longitude: 21.1442795,
      latitude: 55.7032947
    }, {
      code: "21",
      longitude: 21.254064,
      latitude: 55.896246
    }, {
      code: "22",
      longitude: 24.991887,
      latitude: 55.836787
    }, {
      code: "24",
      longitude: 23.346532,
      latitude: 54.554492
    }, {
      code: "25",
      longitude: 22.339451,
      latitude: 56.305854
    }, {
      code: "26",
      longitude: 25.410686,
      latitude: 55.227303
    }, {
      code: "27",
      longitude: 21.003991,
      latitude: 55.302759
    }, {
      code: "29",
      longitude: 23.843567,
      latitude: 55.977648
    }, {
      code: "31",
      longitude: 24.3574773,
      latitude: 55.7347915
    }, {
      code: "32",
      longitude: 24.363953,
      latitude: 55.736626
    }, {
      code: "33",
      longitude: 24.406773,
      latitude: 56.068024
    }, {
      code: "34",
      longitude: 21.825274,
      latitude: 55.899419
    }, {
      code: "35",
      longitude: 23.961477,
      latitude: 54.631656
    }, {
      code: "36",
      longitude: 23.543144,
      latitude: 55.811380
    }, {
      code: "37",
      longitude: 23.121185,
      latitude: 55.386012
    }, {
      code: "39",
      longitude: 25.582042,
      latitude: 55.968879
    }, {
      code: "41",
      longitude: 21.528772,
      latitude: 56.274154
    }, {
      code: "42",
      longitude: 23.031169,
      latitude: 54.955388
    }, {
      code: "43",
      longitude: 25.380675,
      latitude: 54.306157
    }, {
      code: "44",
      longitude: 23.313268,
      latitude: 55.928623
    }, {
      code: "45",
      longitude: 22.935772,
      latitude: 56.003966
    }, {
      code: "46",
      longitude: 21.458865,
      latitude: 55.338732
    }, {
      code: "49",
      longitude: 22.282268,
      latitude: 55.253070
    }, {
      code: "50",
      longitude: 22.233644,
      latitude: 55.983768
    }, {
      code: "51",
      longitude: 24.938741,
      latitude: 54.637681
    }, {
      code: "52",
      longitude: 24.787446,
      latitude: 55.258359
    }, {
      code: "53",
      longitude: 25.590698,
      latitude: 55.509044
    }, {
      code: "54",
      longitude: 24.568497,
      latitude: 54.218243
    }, {
      code: "55",
      longitude: 23.032180,
      latitude: 54.650905
    }, {
      code: "56",
      longitude: 25.237527,
      latitude: 54.722617
    }, {
      code: "57",
      longitude: 26.256272,
      latitude: 55.733038
    }, {
      code: "59",
      longitude: 26.434345,
      latitude: 55.606928
    }, {
      code: "99",
      longitude: 24.3574773,
      latitude: 55.7347915
    }],
    sex: [{
      code: "MALE",
      name: t("options.patientDetails.sex.male")
    }, {
      code: "FEMALE",
      name: t("options.patientDetails.sex.female")
    }],

    findElementByCode(element, code) {
      return (0, _getClassificatorElementByCode.getClassificatorElementByCode)(element, code, this);
    },

    createQuantityArray(from, to, postFix = null) {
      const emptyArray = [];

      while (from <= to) {
        emptyArray.push({
          code: from,
          name: postFix ? this.intl.t(postFix, {
            count: from
          }) : from
        });
        from++;
      }

      return emptyArray;
    },

    getActivationProtocolByDispatch(dispatch) {
      return this.activationProtocols[dispatch] || this.activationProtocols.default;
    }

  });

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    login: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.translate(this.intl);
    },

    totalPatients: Ember.computed(function () {
      const totalPatients = this.createQuantityArray(1, 9, "options.transportation.patient");
      totalPatients[0].favourite = 1;
      totalPatients[1].favourite = 2;
      return totalPatients;
    }),
    totalDoses: Ember.computed(function () {
      const totalQuantities = this.createQuantityArray(1, 20, "options.treatment.totalDoses");
      totalQuantities[0].favourite = 1;
      totalQuantities[1].favourite = 2;
      return totalQuantities;
    }),
    totalProcedures: Ember.computed(function () {
      const totalQuantities = this.createQuantityArray(1, 20, "options.treatment.totalProcedures");
      totalQuantities[0].favourite = 1;
      totalQuantities[1].favourite = 2;
      return totalQuantities;
    }),
    totalQuantity: Ember.computed(function () {
      const totalQuantities = this.createQuantityArray(1, 20, "options.treatment.totalDoses");
      totalQuantities[0].favourite = 1;
      totalQuantities[1].favourite = 2;
      return totalQuantities;
    }),

    translate(intl) {
      Ember.setProperties(this, classificatorData(intl.t.bind(intl)));
    },

    medicaments: Ember.computed(function () {
      return (0, _getMedicamentsData.getMedicamentsData)(this.intl.t.bind(this.intl), this.login.stationId);
    }),
    allMedicamentsUseTypes: Ember.computed(function () {
      return (0, _getMedicamentsData.getAllMedicamentUseTypes)(this.intl.t.bind(this.intl));
    }),
    medicamentWithProcedures: Ember.computed(function () {
      const clonedProcedures = JSON.parse(JSON.stringify(this.flowchart.procedures));

      const proc = code => clonedProcedures.find(proc => proc.code === code);

      let proc13 = proc(13);
      let proc17 = proc(17);
      proc13.favourite = 1;
      proc17.favourite = 1;
      return {
        7: [proc13, proc(14), proc(10), proc(12), proc(8), proc(9), proc(11), proc(18)],
        18: [proc17],
        19: [proc17],
        32: [proc17],
        33: [proc17],
        245: [proc17],
        265: [proc17]
      };
    })
  });

  _exports.default = _default;
});