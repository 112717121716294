define("epcr-uis/authenticators/jwt-custom", ["exports", "ember-simple-auth-token/authenticators/jwt", "epcr-uis/config/environment"], function (_exports, _jwt, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jwt.default.extend({
    login: Ember.inject.service(),

    authenticate({
      domain
    }) {
      if (domain) this.serverTokenEndpoint += `?domain=${domain}`;
      return this._super(...arguments);
    },

    onNetworkError(token) {
      const interval = Ember.run.later(() => this.refreshAccessToken(token), _environment.default.APP.authRetryTime || 60000);
      Ember.set(this, "_refreshTokenTimeout", interval);
    },

    onForbidden() {
      return this.login.invalidateSession().then(() => {
        throw new Error("REAUTH FAILED");
      });
    },

    onRefreshAccessTokenError(status, token) {
      if (status === undefined || status === 500 || status === 0) return this.onNetworkError(token);

      if (status === 401 || status === 403) {
        return this.onForbidden();
      }

      return null;
    },

    refreshAccessToken(token) {
      const promise = this._super(...arguments);

      promise.catch(({
        status
      }) => this.onRefreshAccessTokenError(status, token));
      return promise;
    },

    handleAccessTokenExpiration() {
      const promise = this._super(...arguments);

      return promise.then(() => this.login.invalidateSession());
    }

  });

  _exports.default = _default;
});