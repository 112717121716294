define("epcr-uis/helpers/go-to-next-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.goToNextInput = void 0;

  const goToNextInput = features => {
    Ember.get(features, "rapidInputMode") && Ember.run.next(() => {
      const nextPopupButton = document.querySelector(".sidebar:not(.centered) .tab-button.right");
      nextPopupButton && nextPopupButton.click();
    });
  };

  _exports.goToNextInput = goToNextInput;
});