define("epcr-uis/components/medicaments-list/body/type", ["exports", "ember-components/components/medicaments-list/body/type"], function (_exports, _type) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _type.default;
    }
  });
});