define("epcr-uis/pods/pcr/patient-details/physician/adapter", ["exports", "epcr-uis/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    urlForQuery(query, modelPath) {
      const models = modelPath.split("/");
      const model = `${models[models.length - 1]}s`;
      return `${this.mainPath(model)}`;
    }

  });

  _exports.default = _default;
});