define("epcr-uis/pods/components/drill-down-wrapper/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    "data-test-id": "drill-down-wrapper",
    classNames: ["drill-down-wrapper", "content"],
    actions: {
      onReload(task) {
        task.perform(...task.last.args);
      }

    }
  });

  _exports.default = _default;
});