define("epcr-uis/pods/pcr/patient-assessment/vitals/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    bpSystolic: attr("number"),
    bpDiastolic: attr("number"),
    cp: attr("boolean"),
    pulse: attr("number"),
    respiratoryRate: attr("number"),
    spO2: attr("number"),
    temperature: attr("number"),
    glucose: attr("number"),
    pain: (0, _attributes.fragment)("pcr-classificator")
  });

  _exports.default = _default;
});