define("epcr-uis/mirage/factories/phc", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const englishPHC = () => ({
    code: i => `${i + 1} Hospital`,
    name: i => `${i + 1} Hospital`
  });

  const lithuanianPHC = () => ({
    code: i => `${i + 1} Ligoninė`,
    name: i => `${i + 1} Ligoninė`
  });

  var _default = _emberCliMirage.Factory.extend((0, _isEnglish.isEnglish)() ? englishPHC() : lithuanianPHC());

  _exports.default = _default;
});