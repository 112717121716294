define("epcr-uis/pods/components/procedure-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RFQQ0Ho5",
    "block": "{\"symbols\":[\"filter\",\"@procedureFilters\"],\"statements\":[[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"filterItems\",[23,1,[\"code\"]]],null]],[8],[0,\"\\n    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[7,\"div\",true],[10,\"class\",\"ui button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"filteredProcedures\"]]],null],[24,[\"items\"]]],null]],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"options.treatment.procedures.all\"],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/procedure-filter/template.hbs"
    }
  });

  _exports.default = _default;
});