define("epcr-uis/pods/components/pcr-document/narrative/component", ["exports", "epcr-uis/helpers/promise-converter"], function (_exports, _promiseConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultProtocols = {
    1: [1, 26],
    2: [2, 26],
    3: [3, 30],
    4: [4, 30],
    5: [5, 26],
    6: [6, 26],
    7: [7, 26],
    8: [8, 26],
    9: [9],
    10: [10, 19],
    11: [11, 26],
    12: [12, 26],
    13: [13, 26],
    14: [14, 9],
    15: [15],
    16: [16, 30],
    17: [17, 30],
    18: [18, 26],
    19: [19, 26],
    20: [20, 26],
    21: [21, 30],
    22: [22],
    23: [23, 26],
    24: [24],
    25: [25, 26],
    26: [26, 1],
    27: [27, 4],
    28: [28, 26],
    29: [29, 30],
    30: [30],
    31: [31, 26],
    32: [32, 26],
    33: [33]
  };
  const panProtocols = {
    11: [9, 26],
    12: [29, 30],
    13: [7, 26],
    14: [7, 26],
    15: [30],
    16: [17, 30],
    17: [15],
    18: [7],
    19: [20],
    20: [7],
    21: [9],
    22: [14],
    23: [4, 30],
    24: [11, 26],
    25: [2, 26],
    26: [22, 26],
    27: [3, 30],
    28: [3, 30],
    29: [23, 26],
    30: [30, 16],
    31: [30, 26],
    32: [18, 20],
    33: [20],
    34: [9],
    35: [31, 9],
    36: [31, 26],
    37: [21, 26],
    38: [21, 1],
    39: [12, 26],
    40: [19, 10],
    41: [19, 10],
    42: [6, 26],
    43: [25],
    44: [25, 26],
    45: [1, 26],
    46: [1, 26],
    47: [1, 26],
    48: [20, 26],
    49: [28, 26],
    50: [16, 26],
    51: [6, 1],
    52: [24, 1],
    53: [24],
    54: [24],
    55: [1, 20],
    56: [19, 30],
    57: [33, 26],
    58: [30, 32],
    59: [9],
    60: [33],
    61: [33],
    62: [33],
    63: [30, 26]
  };

  const favouriteHelpProtocolsCodes = (dispatchId, activationProtocol) => (dispatchId === "31" ? panProtocols[activationProtocol] : defaultProtocols[activationProtocol]) || [];

  var _default = Ember.Component.extend({
    pcrClassificator: Ember.inject.service(),
    icd: Ember.inject.service(),
    login: Ember.inject.service(),

    initHelpProtocols() {
      Ember.set(this, "helpProtocols", JSON.parse(JSON.stringify(this.pcrClassificator.helpProtocol)));
      const {
        dispatchId
      } = this.login;
      const activationProtocol = Ember.get(this, "pcr.teamActivation.activationProtocol.code");
      if (activationProtocol && dispatchId) favouriteHelpProtocolsCodes(dispatchId, activationProtocol).forEach((code, index) => {
        Ember.get(this, "helpProtocols").filter(protocol => protocol.code === code).forEach(protocol => {
          protocol.favourite = index + 1;
          protocol.favouriteKey = `${protocol.code} ${protocol.shortName}`;
        });
      });
    },

    init() {
      this._super(...arguments);

      this.initHelpProtocols();
    },

    icdSource: Ember.computed(function () {
      return query => (0, _promiseConverter.promiseToObjectsConverter)(this.dataSource(query), ["code", "name"]);
    }),

    dataSource(query) {
      const teamLeaderType = this.get("pcr.teamActivation.team.leaderType.code");
      const station = this.get("pcr.station");
      return this.icd.queryIcd(query, station, teamLeaderType);
    }

  });

  _exports.default = _default;
});