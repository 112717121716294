define("epcr-uis/pods/pcr/patient-treatment/model", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    helpProtocol: (0, _attributes.fragment)("pcr-classificator"),
    medicamentTreatment: (0, _attributes.fragmentArray)("pcr/patient-treatment/medicament-treatment"),
    procedureTreatment: (0, _attributes.fragmentArray)("pcr/patient-treatment/procedure-treatment"),
    inventory: (0, _attributes.fragmentArray)("pcr/patient-treatment/inventory")
  });

  _exports.default = _default;
});