define("epcr-uis/helpers/count-mean-arterial-pressure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countMeanArterialPressure = countMeanArterialPressure;
  _exports.default = void 0;

  function countMeanArterialPressure([SP, DP]) {
    if (Ember.isPresent(SP) && Ember.isPresent(DP)) {
      DP = parseInt(DP);
      SP = parseInt(SP);
      return `${Math.floor(DP + (SP - DP) / 3)}`;
    }

    return "";
  }

  var _default = Ember.Helper.helper(countMeanArterialPressure);

  _exports.default = _default;
});