define("epcr-uis/pods/components/filter-composer/representation/active-pcrs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3nIbJeEg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui basic label\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"filter-wrapper\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"filter-title\"],[8],[1,[28,\"t\",[\"headings.filter.team\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"filter-value\"],[8],[0,\"\\n        \"],[1,[22,\"value\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"locked\"]]],null,{\"statements\":[[0,\"          \"],[7,\"i\",true],[10,\"class\",\"lock icon white\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/filter-composer/representation/active-pcrs/template.hbs"
    }
  });

  _exports.default = _default;
});