define("epcr-uis/pods/pcr/clinical-death/cpr-before-arrival-by/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    cprStarted: attr("boolean"),
    cprStartedBy: (0, _attributes.fragment)("pcr-classificator"),
    minsFromSuddenDeath: attr("number"),
    minsFromCprStart: attr("number"),
    minsFromCprEnd: attr("number"),
    usedAed: attr("boolean"),
    aedUsedBy: (0, _attributes.fragment)("pcr-classificator"),
    minsFromFirstAed: attr("number")
  });

  _exports.default = _default;
});