define("epcr-uis/mirage/assets/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAuthToken = getAuthToken;
  const userAuthTokenWithStation99 = {
    token: "eyJhbGciOiJIUzUxMiJ9" + ".eyJzdWIiOiJ1c2VyIiwidW4iOiJyb290IiwiZm4iOiJGaXJzdC1OYW1lIiwibG4iOiJMYXN0LU5hbWUiLCJwb3MiOlsiU0xBVUciXSwiZUlkIjo" + "iNWIxYThjODUwZWVhY2ExOTVjYzc2NDRiIiwiZGlzIjoiOTkiLCJzdGF0IjoiOTkiLCJ0IjoicnB5dm5uY2RhdXhrMWowaHd2Z2J0djB6MyIsInN" + "lcnYiOiJJR01QIiwiZXhwIjo0MjMyNDQxNjMyfQ" + ".0hwCK99JCDOC_msf7WXUTGzyyQ6r-jRVDbp3a8j6-bShTKbQmeloBIQwVDBz5FHopPAjwfOBFO-zblcyoHbhDw"
  };
  const userAuthTokenWithStation55 = {
    token: "eyJhbGciOiJIUzUxMiJ9" + ".eyJzdWIiOiJ1c2VyIiwidW4iOiJyb290IiwiZm4iOiJGaXN0LU5hbWUiLCJsbiI6Ikxhc3QtTmFtZSIsInBvcyI6WyJTTEFVRyJdLCJlSWQiOiI" + "1YjFhOGM4NTBlZWFjYTE5NWNjNzY0NGIiLCJkaXMiOiI5OSIsInN0YXQiOiI1NSIsInQiOiJycHl2bm5jZGF1eGsxajBod3ZnYnR2MHozIiwic2V" + "ydiI6IklHTVAiLCJleHAiOjQyMzI0NDE2MzJ9" + ".Fvok4_UYCJz7MFFgAVK_ht68McsOoxggLl_JQPgkvw8ir9pnDrm87JC9X4xVcbOQGk46TlMMQ9dwKpvb_nIKMw"
  };
  const adminAuthToken = {
    token: "eyJhbGciOiJIUzUxMiJ9" + ".eyJzdWIiOiJ1c2VyIiwidW4iOiJyb290IiwiZm4iOiJGaXJzdC1OYW1lIiwibG4iOiJMYXN0LU5hbWUiLCJwb3MiOlsiU0xBVUciXSwiZUlkIjo" + "iNWIxYThjODUwZWVhY2ExOTVjYzc2NDRiIiwiZGlzIjoiOTkiLCJzdGF0IjoiOTkiLCJ0IjoicnB5dm5uY2RhdXhrMWowaHd2Z2J0djB6MyIsInB" + "lcm0iOlsiZXBjci5hZG1pbiIsImVwY3IudHJhaW5pbmciXSwic2VydiI6IklHTVAiLCJleHAiOjQyMzI0NDE2MzJ9" + ".lmGZWd41zoG7VUb3ov5Jw4p6reyDgmf56rFlUVSULTEgMlkLjqD_HhmyC9mC_Q7oq7ai730MRuHCwBOBohTyQg"
  };
  const simpleAuthToken = {
    token: "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ1c2VyIiwidW4iOiJyb290IiwiZm4iOiJGaXJzdC1OYW1lIiwibG4iOiJMYXN0LU5hbWUiLCJ" + "wb3MiOlsiU0xBVUciXSwiZUlkIjoiNWIxYThjODUwZWVhY2ExOTVjYzc2NDRiIiwiZGlzIjoiOTkiLCJzdGF0IjoiOTkiLCJ0IjoicnB5dm5u" + "Y2RhdXhrMWowaHd2Z2J0djB6MyIsInBlcm0iOlsiZXBjci50cmFpbmluZyJdLCJzZXJ2IjoiSUdNUCIsImV4cCI6NDIzMjQ0MTYzMn0.WLv2TR" + "dJB_V7JmQs6Co5vuOPNHCDoSmQF7W1rmWq8XVQnLybU0Xg5of7T0A_kKTEzQz32lKbLWPKCXyUKqJb0g"
  };
  const tokenForAcceptanceTest = adminAuthToken;

  function getAuthToken() {
    return {
      userAuthTokenWithStation99,
      userAuthTokenWithStation55,
      adminAuthToken,
      tokenForAcceptanceTest,
      simpleAuthToken
    };
  }
});