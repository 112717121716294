define("epcr-uis/helpers/pcr-route-tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    pcrRouteTracker: Ember.inject.service(),
    onRouteChange: Ember.observer("pcrRouteTracker.currentRoute", function () {
      this.recompute();
    }),

    compute() {
      return this.get("pcrRouteTracker.currentRoute");
    }

  });

  _exports.default = _default;
});