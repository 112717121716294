define("epcr-uis/pods/pcr/agreements-signatures/hospital-doctor-signature/model", ["exports", "ember-data", "epcr-uis/pods/pcr/agreements-signatures/signature/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _model.default.extend({
    refused: attr("boolean"),
    isFilled: Ember.computed("signatureImage", "refused", function () {
      return !!(this.signatureImage || this.refused);
    })
  });

  _exports.default = _default;
});