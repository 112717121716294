define("epcr-uis/pods/phc/serializer", ["exports", "epcr-uis/pods/lazy-fragment/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend();

  _exports.default = _default;
});