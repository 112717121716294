define("epcr-uis/pods/pcr/agreements-signatures/info-share-agreement/model", ["exports", "ember-data", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _attributes, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _fragment.default.extend({
    confirmation: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    shareWithPerson: attr("upper-case-string"),
    signatureType: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    signature: (0, _attributes.fragment)("pcr/agreements-signatures/signature"),
    isFilled: Ember.computed("signature.signatureImage", "signatureType.code", "confirmation", function () {
      const signatureType = this.get("signatureType.code");

      if ((signatureType === "REFUSES_TO_SIGN" || signatureType === "UNABLE_TO_SIGN") && this.confirmation) {
        return true;
      }

      return !!this.get("signature.signatureImage");
    })
  });

  _exports.default = _default;
});