define("epcr-uis/pods/components/pcr-document/transportation/disposition/transportation-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FjqfRkIR",
    "block": "{\"symbols\":[\"@text\"],\"statements\":[[7,\"i\",true],[10,\"class\",\"icons\"],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"ambulance icon \",[28,\"if\",[[24,[\"isTransported\"]],\"black\",\"grey\"],null]]]],[8],[9],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"top right corner icon \",[28,\"if\",[[24,[\"isTransported\"]],\"green check\",\"red dont\"],null]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[23,1,[]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/transportation/disposition/transportation-type/template.hbs"
    }
  });

  _exports.default = _default;
});