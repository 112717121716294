define("epcr-uis/mirage/factories/user-preferences", ["exports", "ember-cli-mirage", "epcr-uis/constants"], function (_exports, _emberCliMirage, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    [_constants.FEAT_SOUND_ON_CLICK]: true,
    [_constants.FEAT_RAPID_INPUT_MODE]: true,
    [_constants.FEAT_FLASHLIGHT]: false,
    [_constants.READ_NEWS]: false,
    [_constants.FEAT_TIMELINES]: true,
    [_constants.FEAT_NIGHT_MODE]: false,
    [_constants.FEAT_RELATED_PROCEDURES]: true,
    [_constants.TEAM_ACTIVATION]: null,
    [_constants.TEAM_LEADER_TYPE]: null,
    [_constants.SELECTED_TEAM_CODE]: "9994",
    [_constants.SELECTED_SHIFT_DURATION]: 12,
    [_constants.SURVEYED]: false
  });

  _exports.default = _default;
});