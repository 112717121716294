define("epcr-uis/mirage/models/active-pcr", ["exports", "epcr-uis/mirage/models/my-pcr"], function (_exports, _myPcr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _myPcr.default.extend();

  _exports.default = _default;
});