define("epcr-uis/pods/team/ability", ["exports", "ember-can", "epcr-uis/mixins/is-admin"], function (_exports, _emberCan, _isAdmin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IS_AUTHENTICATED_PATH = "session.isAuthenticated";

  var _default = _emberCan.Ability.extend(_isAdmin.default, {
    team: Ember.inject.service(),
    canChangeTeam: Ember.computed(IS_AUTHENTICATED_PATH, "team.changeCount", function () {
      return this.isAdmin() || this.team.changeCount === 0;
    })
  });

  _exports.default = _default;
});