define("epcr-uis/helpers/get-assessment-by-type", ["exports", "ember-components/mixins/helper-observer"], function (_exports, _helperObserver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.getAssessmentByType = void 0;

  const getAssessmentByType = (assessments, type) => assessments && assessments.length ? assessments.find(a => Ember.get(a, "type.code") === type) : null;

  _exports.getAssessmentByType = getAssessmentByType;

  var _default = Ember.Helper.extend(_helperObserver.default, {
    compute([assessments, type]) {
      if (assessments && assessments.addObserver) this.setupRecompute(assessments, "length");
      return getAssessmentByType(assessments, type);
    }

  });

  _exports.default = _default;
});