define("epcr-uis/helpers/is-browser-unsupported", ["exports", "browser-update", "epcr-uis/mixins/metrics-tracker"], function (_exports, _browserUpdate, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isBrowserUnsupported = isBrowserUnsupported;
  _exports.default = void 0;

  const config = callbackOnShow => ({
    nomessage: true,
    reminder: 0,
    // kad nesetintu cookies
    unsupported: true,

    onshow() {
      callbackOnShow();
    }

  });

  function isBrowserUnsupported(context) {
    let hasUpdates = false;

    const callbackOnShowUnsupported = function () {
      hasUpdates = true;
      context.trackEvent("Browser", "Unsupported", navigator.userAgent);
    };

    (0, _browserUpdate.default)(config(callbackOnShowUnsupported));
    return hasUpdates;
  }

  var _default = Ember.Helper.extend(_metricsTracker.default, {
    compute() {
      return isBrowserUnsupported(this);
    }

  });

  _exports.default = _default;
});