define("epcr-uis/pods/components/pcr-document/transportation/destination/component", ["exports", "epcr-uis/helpers/promise-converter", "epcr-uis/helpers/is-number", "epcr-uis/mixins/coordinates-from-event-site-or-station", "epcr-uis/helpers/go-to-next-input"], function (_exports, _promiseConverter, _isNumber, _coordinatesFromEventSiteOrStation, _goToNextInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_coordinatesFromEventSiteOrStation.default, {
    "data-test-id": "destination-block",
    pcrClassificator: Ember.inject.service(),
    features: Ember.inject.service(),
    login: Ember.inject.service(),
    department: Ember.inject.service("pcr/transportation/department"),
    hospitalMinQueryLength: query => !isNaN(query) ? 1 : null,
    hospitalSource: Ember.computed({
      get() {
        return query => (0, _promiseConverter.promiseToObjectsConverter)(this.dataSource(query), ["code", "hospitalName", "hospitalCode", "departmentName", "departmentCode"]);
      },

      set: (key, value) => value
    }),

    extractHospitalDepartmentCode(query) {
      const words = query.split(" ");
      return words.length === 2 && (0, _isNumber.isNumber)(words[0]) && (0, _isNumber.isNumber)(words[1]) ? words.join("-") : null;
    },

    init() {
      this._super(...arguments);

      this.department.getDepartmentsByStationIdTask.perform();
    },

    favouriteDepartmentsSource: Ember.computed(function () {
      return () => this.department.getDepartmentsByStationIdTask.perform();
    }),

    extractHospitalCode(query) {
      return (0, _isNumber.isNumber)(query) ? query : null;
    },

    trimQuery: query => query ? query.trim().replace(/\s\s+/g, " ") : "",

    dataSource(query) {
      query = this.trimQuery(query);
      const hospitalDepartmentCode = this.extractHospitalDepartmentCode(query);
      if (hospitalDepartmentCode) return this.department.getDepartment(hospitalDepartmentCode).then(result => Ember.A([result]));
      const hospitalCodeQuery = this.extractHospitalCode(query);
      if (hospitalCodeQuery) return this.department.queryDepartmentsByHospitalCode(hospitalCodeQuery);
      const {
        longitude,
        latitude
      } = this.getCoordinates();
      return this.department.queryDepartment(query, longitude, latitude);
    },

    actions: {
      goToNextInput() {
        (0, _goToNextInput.goToNextInput)(this.features);
      }

    }
  });

  _exports.default = _default;
});