define("epcr-uis/pods/pcr-classificator/medicaments/varena", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VARENA_MEDICAMENTS = void 0;

  const VARENA_MEDICAMENTS = t => ({
    stations: ["54"],
    medicaments: [(0, _medicamentsList.MEDICAMENTS)(t)[201], (0, _medicamentsList.MEDICAMENTS)(t)[203], (0, _medicamentsList.MEDICAMENTS)(t)[204], (0, _medicamentsList.MEDICAMENTS)(t)[208], // 208 eina pirmiau už 207, kad būtų gražiai išdėliota
    (0, _medicamentsList.MEDICAMENTS)(t)[207], (0, _medicamentsList.MEDICAMENTS)(t)[209], (0, _medicamentsList.MEDICAMENTS)(t)[211], (0, _medicamentsList.MEDICAMENTS)(t)[212], (0, _medicamentsList.MEDICAMENTS)(t)[213], (0, _medicamentsList.MEDICAMENTS)(t)[214], (0, _medicamentsList.MEDICAMENTS)(t)[220], (0, _medicamentsList.MEDICAMENTS)(t)[226], (0, _medicamentsList.MEDICAMENTS)(t)[230], (0, _medicamentsList.MEDICAMENTS)(t)[236], (0, _medicamentsList.MEDICAMENTS)(t)[242], (0, _medicamentsList.MEDICAMENTS)(t)[245], (0, _medicamentsList.MEDICAMENTS)(t)[246], (0, _medicamentsList.MEDICAMENTS)(t)[249], (0, _medicamentsList.MEDICAMENTS)(t)[260], (0, _medicamentsList.MEDICAMENTS)(t)[265], (0, _medicamentsList.MEDICAMENTS)(t)[266], (0, _medicamentsList.MEDICAMENTS)(t)[273], (0, _medicamentsList.MEDICAMENTS)(t)[275], (0, _medicamentsList.MEDICAMENTS)(t)[276], (0, _medicamentsList.MEDICAMENTS)(t)[277], (0, _medicamentsList.MEDICAMENTS)(t)[278], (0, _medicamentsList.MEDICAMENTS)(t)[279], (0, _medicamentsList.MEDICAMENTS)(t)[280], (0, _medicamentsList.MEDICAMENTS)(t)[285], (0, _medicamentsList.MEDICAMENTS)(t)[294]]
  });

  _exports.VARENA_MEDICAMENTS = VARENA_MEDICAMENTS;
});