define("epcr-uis/helpers/medicament-use-type-has-size", ["exports", "ember-components/mixins/helper-observer"], function (_exports, _helperObserver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.medicamentUseTypeHasSize = void 0;
  const useTypesWithSize = ["43", "40_51", "40_52", "40_54"];

  const isUseTypeWithSize = useTypeCode => useTypeCode && useTypesWithSize.find(code => code === useTypeCode);

  const medicamentUseTypeHasSize = ([useType]) => !!isUseTypeWithSize(useType && Ember.get(useType, "code"));

  _exports.medicamentUseTypeHasSize = medicamentUseTypeHasSize;

  var _default = Ember.Helper.extend(_helperObserver.default, {
    compute([useType]) {
      if (useType && useType.addObserver) this.setupRecompute(useType, "code");
      return medicamentUseTypeHasSize([useType]);
    }

  });

  _exports.default = _default;
});