define("epcr-uis/pods/components/pcr-document/patient/family-doctor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4edSF+Jn",
    "block": "{\"symbols\":[\"SingleInput\",\"@patient\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"eight wide computer eight wide tablet column upper-case\"],[8],[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"side-bar/single-input\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[[12,\"id\",\"patient-family-doctor\"]],[[\"@title\",\"@validation\",\"@data\",\"@source\",\"@debounce\",\"@onSearchListVisible\",\"@readOnly\"],[[28,\"t\",[\"pcr.patientDetails.familyDoctor\"],null],[24,[\"pcrValidation\",\"errors\",\"patient-family-doctor\"]],[23,2,[\"familyDoctor\",\"fullName\"]],[22,\"physicianSource\"],500,[28,\"action\",[[23,0,[]],\"scrollToField\",\"#patient-family-doctor\"],null],[22,\"isFamilyDoctorUnknown\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[5,\"checkbox-true-null\",[[12,\"class\",\"four wide computer four wide tablet column\"]],[[\"@title\",\"@checked\",\"@onValueChange\"],[[28,\"t\",[\"actions.selectUnknown\"],null],[22,\"isFamilyDoctorUnknown\"],[28,\"action\",[[23,0,[]],\"onFamilyDoctorUnknown\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/patient/family-doctor/template.hbs"
    }
  });

  _exports.default = _default;
});