define("epcr-uis/pods/components/pcr-footer/component", ["exports", "epcr-uis/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["pcr-footer"],
    appVersion: _environment.default.APP.epcrVersion,
    buildTime: _environment.default.APP.buildTime,
    features: Ember.inject.service(),
    android: Ember.inject.service(),
    separator: "•",
    isAndroidDataVisible: Ember.computed(function () {
      return this.android.isAndroid();
    }),

    getAndroidData() {
      if (this.isAndroidDataVisible) {
        const imei = this.android.getImei();
        const isOfflineEnabled = this.android.isAppVersionHigher(14699) ? this.android.isOfflineEnabled() : true;
        return ` ${this.separator} IMEI:${imei}${isOfflineEnabled ? "" : `${this.separator} No offline`}`;
      }

      return "";
    },

    version: Ember.computed(function () {
      const buildTime = (0, _moment.default)(this.buildTime).format("MMDD");
      return `${this.separator} v${this.appVersion}-${buildTime}${this.getAndroidData()}`;
    })
  });

  _exports.default = _default;
});