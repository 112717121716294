define("epcr-uis/helpers/initial-printer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialPrinter = initialPrinter;
  _exports.default = void 0;

  const getFirstLetterToUpperCase = word => word ? `${word.substring(0, 1).toUpperCase()}` : "";

  function initialPrinter(users) {
    if (!users) return "";
    if (users.length === undefined) return "";
    const [user] = users;
    const firstName = user.get("firstName") || "";
    const lastName = user.get("lastName") || "";
    const firstLetterOfFirstName = getFirstLetterToUpperCase(firstName);
    const firstLetterOfLastName = getFirstLetterToUpperCase(lastName);
    return `${firstLetterOfFirstName}${firstLetterOfLastName}`.trim();
  }

  var _default = Ember.Helper.helper(initialPrinter);

  _exports.default = _default;
});