define("epcr-uis/mirage/factories/event-site", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    address: () => (0, _isEnglish.isEnglish)() ? "TAIKOS ST. 1, VILNIUS city, Vilnius mun." : "TAIKOS G. 1, VILNIAUS M., Vilniaus m. sav.",
    teamType: "BLS",
    policeInformed: true,
    fireDepInformed: true
  });

  _exports.default = _default;
});