define("epcr-uis/helpers/date-interval-to-string", ["exports", "ember-components/helpers/date-interval-to-string"], function (_exports, _dateIntervalToString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dateIntervalToString.default;
    }
  });
  Object.defineProperty(_exports, "getFormattedDateInterval", {
    enumerable: true,
    get: function () {
      return _dateIntervalToString.getFormattedDateInterval;
    }
  });
});