define("epcr-uis/pods/components/active-pcrs/component", ["exports", "ember-concurrency", "epcr-uis/mixins/teams-by-station", "epcr-uis/mixins/is-station-allowed-to-create-new-pcr", "moment", "epcr-uis/mixins/on-pcr-list-item-click", "epcr-uis/config/environment"], function (_exports, _emberConcurrency, _teamsByStation, _isStationAllowedToCreateNewPcr, _moment, _onPcrListItemClick, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_onPcrListItemClick.default, _teamsByStation.default, _isStationAllowedToCreateNewPcr.default, {
    "data-test-id": "active-pcrs",
    classNames: ["active-pcrs list"],
    classNameBindings: ["filterTask.last.isError:error"],
    pcrReloaderActivePcrs: Ember.inject.service("pcr-reloader/active-pcrs"),
    features: Ember.inject.service(),
    router: Ember.inject.service(),
    team: Ember.inject.service(),
    activePcrs: Ember.inject.service("list/active-pcrs"),
    isStationAllowedToCreateNewEpcr: Ember.computed({
      get() {
        return this.isStationAllowedToCreateNewPcr();
      }

    }),

    init() {
      this._super(...arguments);

      this.selectedTeam && this.filterTask.perform();
      return this.pcrReloaderActivePcrs.watch(this.reloadActivePcrs.bind(this));
    },

    selectedTeam: Ember.computed("team.activeTeam", {
      get() {
        const {
          teams,
          team: {
            activeTeam
          }
        } = this;
        return activeTeam && teams && teams.findBy("code", activeTeam);
      },

      set: (key, value) => value
    }),
    filterTask: (0, _emberConcurrency.task)(function* () {
      const {
        selectedTeam
      } = this;
      const categories = [1, 2];
      const completedPcr = true;

      if (selectedTeam) {
        yield Ember.set(this, "lastUpdateTime", (0, _moment.default)());
        yield this.activePcrs.queryActivePcrsTask.linked().perform(selectedTeam, categories, completedPcr);
      }
    }),

    reloadActivePcrs() {
      return this.filterTask.perform();
    },

    didDestroyElement() {
      this.pcrReloaderActivePcrs.stopWatch();
    },

    actions: {
      transitionToRoute(route) {
        this.router.transitionTo(route);
      },

      createNewPcr() {
        Ember.set(this, "isTransitingToPcr", true);
        this.onPcrClicked(_environment.default.APP.unsavedPcrId).finally(() => {
          Ember.set(this, "isTransitingToPcr", false);
        });
      }

    }
  });

  _exports.default = _default;
});