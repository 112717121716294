define("epcr-uis/mirage/models/birth-criteria", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    apgar1Min: (0, _emberCliMirage.belongsTo)("pcr-classificator"),
    apgar5Min: (0, _emberCliMirage.belongsTo)("pcr-classificator")
  });

  _exports.default = _default;
});