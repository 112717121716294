define("epcr-uis/pods/pcr/flowchart/recommendations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9z0uV7Le",
    "block": "{\"symbols\":[],\"statements\":[[5,\"pcr-document/flowchart/recommendation-input\",[],[[\"@recommendations\",\"@patientTreatment\",\"@isRightPopupOpen\",\"@pcr\",\"@transitionToFlowchart\"],[[24,[\"model\",\"recommendations\"]],[24,[\"model\",\"pcr\",\"patientTreatment\"]],[24,[\"model\",\"isRightPopupOpen\"]],[24,[\"model\",\"pcr\"]],[28,\"route-action\",[\"transitionToFlowchart\",[24,[\"model\",\"pcr\"]]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/pcr/flowchart/recommendations/template.hbs"
    }
  });

  _exports.default = _default;
});