define("epcr-uis/mirage/serializers/phc", ["exports", "epcr-uis/mirage/serializers/lazy-fragment"], function (_exports, _lazyFragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _lazyFragment.default.extend();

  _exports.default = _default;
});