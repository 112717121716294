define("epcr-uis/pods/components/top-navigation/component", ["exports", "epcr-uis/helpers/window-wrapper"], function (_exports, _windowWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    login: Ember.inject.service(),
    flashlight: Ember.inject.service(),
    news: Ember.inject.service(),
    android: Ember.inject.service(),
    myPcrs: Ember.inject.service("list/my-pcrs"),
    updatesService: Ember.inject.service("updates"),
    historyService: Ember.inject.service("history"),
    applicationVersion: Ember.inject.service(),
    topNavigation: Ember.inject.service(),
    support: Ember.inject.service(),
    tagName: "",
    showSurvey: Ember.computed(function () {
      // TODO enable survey
      // return !this.features.isEnabled("surveyed") && this.android.isAndroid();
      return false;
    }),
    showTools: Ember.computed(function () {
      return this.android.isAndroid();
    }),
    actions: {
      transitionToRoute(route) {
        return this.router.transitionTo(route);
      },

      toggle() {
        this.toggleProperty("isUserMenuVisible");
      },

      logout() {
        this.login.invalidateSession().then(() => {
          this.updatesService.updateIfHasUpdates();
          this.historyService.clearBrowserHistory();
          this.applicationVersion.updateVersionAndReload();
          this.flashlight.turnOffFlashlightIfOn();
        });
      },

      onBack() {
        (0, _windowWrapper.callMethod)("window.history.back");
      }

    }
  });

  _exports.default = _default;
});