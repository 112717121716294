define("epcr-uis/pods/components/pcr-document/patient/phc/component", ["exports", "epcr-uis/helpers/promise-converter", "epcr-uis/constants"], function (_exports, _promiseConverter, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const phcCodePath = "patient.phcFacility.code";

  var _default = Ember.Component.extend({
    tagName: "",
    phc: Ember.inject.service(),
    scrollToField: Ember.inject.service(),
    lastSelectedPhc: null,
    isPhcUnknown: Ember.computed(phcCodePath, {
      set: (key, value) => value,

      get() {
        const phc = Ember.get(this, phcCodePath);
        return `${phc}`.toUpperCase() === _constants.UNKNOWN_INPUT;
      }

    }),
    phcSource: Ember.computed(function () {
      return query => (0, _promiseConverter.promiseToObjectsConverter)(this.dataSource(query), ["code", "name"]);
    }),

    dataSource(query) {
      return this.phc.queryPhc(query, this.station);
    },

    actions: {
      scrollToField(elementSelector) {
        return this.scrollToField.animate($(elementSelector), "start");
      },

      onPhcSelected({
        code
      }) {
        Ember.set(this, phcCodePath, code);
      },

      onPhcUnknown() {
        const phcValue = Ember.get(this, phcCodePath);
        if (`${phcValue}`.toUpperCase() === _constants.UNKNOWN_INPUT && this.lastSelectedPhc !== _constants.UNKNOWN_INPUT) Ember.set(this, phcCodePath, this.lastSelectedPhc);else {
          Ember.set(this, "lastSelectedPhc", phcValue);
          Ember.set(this, phcCodePath, _constants.UNKNOWN_INPUT);
        }
      }

    }
  });

  _exports.default = _default;
});