define("epcr-uis/helpers/android", ["exports", "epcr-uis/helpers/window-wrapper", "ember-window-mock"], function (_exports, _windowWrapper, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isAppVersionHigher = _exports.getImei = _exports.isOfflineEnabled = _exports.getAppVersion = _exports.isAndroid = void 0;

  const isAndroid = () => Boolean(_emberWindowMock.default.epcr);

  _exports.isAndroid = isAndroid;

  const getAppVersion = () => isAndroid() && (0, _windowWrapper.callMethod)("window.appData.getAppVersion");

  _exports.getAppVersion = getAppVersion;

  const isOfflineEnabled = () => isAndroid() && (0, _windowWrapper.callMethod)("window.appData.isOfflineEnabled");

  _exports.isOfflineEnabled = isOfflineEnabled;

  const getImei = () => isAndroid() && (0, _windowWrapper.callMethod)("window.appData.getImei");

  _exports.getImei = getImei;

  const isAppVersionHigher = version => {
    const appVersion = getAppVersion();
    return appVersion ? appVersion > version : false;
  };

  _exports.isAppVersionHigher = isAppVersionHigher;
});