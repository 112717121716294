define("epcr-uis/pods/components/pcr-document/tools/pediatric-calculator/results/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    createRow: (name, value, measure, dataTestId) => ({
      name,
      value,
      measure,
      dataTestId
    }),
    values: Ember.computed("result", function () {
      const {
        createRow,
        result: {
          weight,
          systolicBp,
          pulse,
          respiratoryRate,
          etTubeDiameter,
          etTubeLength,
          laryngoscopeBlade,
          intubationPresenter,
          ambuBagVolume,
          larynxMask,
          respiratoryVolumeDPV,
          suctionCatheter,
          intravenousCatheter,
          oneDefibrillation,
          twoDefibrillation,
          cardioversion,
          resuscitation
        }
      } = this;
      return [createRow("Svoris", weight, "kg*", "weight"), createRow("Sistolinis kraujo spaudimas", systolicBp, "mmHg", "systolicBp"), createRow("Pulsas", pulse, "k/min", "pulse"), createRow("Kvėpavimo dažnis", respiratoryRate, "k/min", "respiratoryRate"), createRow("E. T. vamzdelio skersmuo", etTubeDiameter, "mm", "etTubeDiameter"), createRow("E. T. vamzdelio ilgis", etTubeLength, "cm", "etTubeLength"), createRow("Laringoskopo mentelė", laryngoscopeBlade, null, "laryngoscopeBlade"), createRow("Intubacinis pravedėjas", intubationPresenter, "F", "intubationPresenter"), createRow("AMBU maišo tūris", ambuBagVolume, "ml", "ambuBagVolume"), createRow("Laringinė kaukė", larynxMask, null, "larynxMask"), createRow("Respiracinis tūris DPV", respiratoryVolumeDPV, "ml", "respiratoryVolumeDPV"), createRow("Atsiurbimo kateteris", suctionCatheter, null, "suctionCatheter"), createRow("Intraveninis kateteris", intravenousCatheter, "G", "intravenousCatheter"), createRow("Defibriliacija (1 impulsas)", oneDefibrillation, "J", "oneDefibrillation"), createRow("Defibriliacija (2+ impulsas)", twoDefibrillation, "J", "twoDefibrillation"), createRow("Kardioversija", cardioversion, "J", " cardioversion"), createRow("Gaivinimo standartas", resuscitation, null, "resuscitation")];
    })
  });

  _exports.default = _default;
});