define("epcr-uis/pods/pcr/birth-criteria/model", ["exports", "ember-data", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment", "epcr-uis/helpers/is-object-filled"], function (_exports, _emberData, _attributes, _fragment, _isObjectFilled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    pregnancyWeeks: attr("number"),
    laborPainsRepeatMins: attr("number"),
    fetusHeartBeat: attr("number"),
    watersBroken: attr("boolean"),
    heavyBleeding: attr("boolean"),
    eclampsia: attr("boolean"),
    babyBorn: attr("boolean"),
    bornTime: attr("date"),
    apgar1Min: (0, _attributes.fragment)("pcr-classificator"),
    apgar5Min: (0, _attributes.fragment)("pcr-classificator"),
    afterBirthPathology: attr("boolean"),
    erNotified: attr("boolean"),
    placentaBorn: attr("boolean"),
    isFilled: Ember.computed("pregnancyWeeks", "laborPainsRepeatMins", "fetusHeartBeat", "watersBroken", "heavyBleeding", "eclampsia", "babyBorn", "erNotified", "afterBirthPathology", function () {
      const keys = ["pregnancyWeeks", "laborPainsRepeatMins", "fetusHeartBeat", "watersBroken", "heavyBleeding", "eclampsia", "erNotified", "babyBorn", "afterBirthPathology"];
      return (0, _isObjectFilled.isObjectFilled)([keys, this]);
    })
  });

  _exports.default = _default;
});