define("epcr-uis/mirage/models/medical-help-agreement", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _emberCliMirage.Model.extend({
    signatureType: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR),
    confirmationToReceiveMedicalHelp: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR),
    confirmationToHospital: (0, _emberCliMirage.belongsTo)(PCR_CLASSIFICATOR),
    signature: (0, _emberCliMirage.belongsTo)()
  });

  _exports.default = _default;
});