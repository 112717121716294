define("epcr-uis/pods/pcr/flowchart/route", ["exports", "epcr-uis/mixins/route-tracker", "epcr-uis/mixins/treatment-observer"], function (_exports, _routeTracker, _treatmentObserver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeTracker.default, _treatmentObserver.default, {
    recommendation: Ember.inject.service("pcr/flowchart/recommendation"),

    activate() {
      const pcr = this.modelFor("pcr").pcrDocument;
      this.recommendation.reloadRecommendations(pcr);
      this.addObserverForTreatments(pcr.patientTreatment);
    },

    deactivate() {
      this.removeObserverForTreatments(this.modelFor("pcr").pcrDocument.patientTreatment);
    }

  });

  _exports.default = _default;
});