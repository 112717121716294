define("epcr-uis/mirage/serializers/used-medicament-medicament", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.RestSerializer.extend({
    serialize() {
      const json = _emberCliMirage.RestSerializer.prototype.serialize.apply(this, arguments);

      return json.usedMedicamentMedicaments;
    }

  });

  _exports.default = _default;
});