define("epcr-uis/mixins/accepting-token-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    login: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    publicRoutes: ["index", "login"],
    queryParams: {
      token: {
        refreshModel: false
      }
    },

    handleTokenFromURL(token, domain, transition) {
      return this.session.isAuthenticated ? this.session.invalidate().then(() => this.authenticate(token, domain, transition)) : this.authenticate(token, domain, transition);
    },

    beforeModel(transition) {
      this._super(...arguments);

      const {
        token,
        domain
      } = transition.to.queryParams;
      if (token) return this.handleTokenFromURL(token, domain, transition);else if (!this.session.isAuthenticated && !this.publicRoutes.includes(transition.targetName)) this.transitionTo("not-authenticated", {
        queryParams: {
          token: null
        }
      });
      return null;
    },

    authenticate(token, domain, transition) {
      return this.login.authenticateByToken(token, domain).then(() => {
        transition.abort();
        this.transitionTo(this.getCurrentUrlWithoutQueryParams(transition.intent.url));
      }).catch(() => {
        this.transitionTo("not-authenticated", {
          queryParams: {
            token: null
          }
        });
      });
    },

    getCurrentUrlWithoutQueryParams(url) {
      const queryParamsPos = url.indexOf("?");
      return queryParamsPos ? url.slice(0, queryParamsPos) : url;
    }

  });

  _exports.default = _default;
});