define("epcr-uis/pods/pcr-state/serializer", ["exports", "epcr-uis/pods/application/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    primaryKey: "id",

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload.pcrState) payload = {
        "pcrState": payload
      };
      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});