define("epcr-uis/pods/pcr-reloader/active-pcrs/by-signal/service", ["exports", "epcr-uis/pods/pcr-reloader/active-pcrs/by-period/service", "raven", "epcr-uis/config/environment"], function (_exports, _service, _raven, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    androidSignal: Ember.inject.service(),
    period: _environment.default.APP.activePcrsReloadSignal,
    delay: 3000,
    session: Ember.inject.service(),
    pcrReloaderActivePcrs: Ember.inject.service("pcr-reloader/active-pcrs"),

    watch(callback) {
      this._super(...arguments);

      Ember.set(this, "callback", callback);
      this.androidSignal.on("onNewPcr", this, "_callCallbackAfterDelay");
    },

    _callCallbackAfterDelay() {
      if (!this.session.isAuthenticated) _raven.default.captureMessage("Calling active pcrs signal callback when unauthenticated");else {
        Ember.run.later(() => !this.session.isAuthenticated ? _raven.default.captureMessage("calling active pcrs callback signal after delay became unauthenticated") : this.callback && this.callback(), this.delay);
      }
    },

    stopWatch() {
      this._super(...arguments);

      Ember.set(this, "callback", null);
      this.androidSignal.off("onNewPcr", this, "_callCallbackAfterDelay");
    }

  });

  _exports.default = _default;
});