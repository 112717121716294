define("epcr-uis/pods/object/transform", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: serialized => serialized,
    serialize: deserialized => deserialized
  });

  _exports.default = _default;
});