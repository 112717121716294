define("epcr-uis/mixins/treatment-observer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    pcrService: Ember.inject.service("pcr"),

    _addObserverForSpecificTreatment(treatment, addObserverFunction) {
      if (treatment) {
        treatment.forEach ? treatment.length && treatment.forEach(t => this.pcrService[addObserverFunction](t)) : this.pcrService[addObserverFunction](treatment);
      }
    },

    addObserverForMedicament(medicamentTreatment) {
      this._addObserverForSpecificTreatment(medicamentTreatment, "addObserverForPcrMedicament");
    },

    addObserverForProcedure(procedureTreatment) {
      this._addObserverForSpecificTreatment(procedureTreatment, "addObserverForPcrProcedure");
    },

    addObserverForInventory(inventory) {
      this._addObserverForSpecificTreatment(inventory, "addObserverForPcrInventory");
    },

    addObserverForTreatments({
      medicamentTreatment,
      procedureTreatment,
      inventory
    }) {
      this.addObserverForMedicament(medicamentTreatment);
      this.addObserverForProcedure(procedureTreatment);
      this.addObserverForInventory(inventory);
    },

    removeObserverForTreatments({
      medicamentTreatment,
      procedureTreatment,
      inventory
    }) {
      this.pcrService.removeObserverForPcrMedicament(medicamentTreatment);
      this.pcrService.removeObserverForPcrProcedure(procedureTreatment);
      this.pcrService.removeObserverForPcrInventory(inventory);
    }

  });

  _exports.default = _default;
});