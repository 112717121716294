define("epcr-uis/pods/components/pcr-document/flowchart/inventory-input/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sh1NE0Xt",
    "block": "{\"symbols\":[\"RightPopup\",\"SingleSelect\",\"TextArea\",\"Popup\",\"@parentClass\",\"@title\",\"@isRightPopupOpen\",\"@data\"],\"statements\":[[4,\"if\",[[23,7,[]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/right-popup\"],null],[28,\"component\",[\"side-bar/single-select\"],null],[28,\"component\",[\"side-bar/textarea-popup/textarea\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[],[[\"@parentClass\",\"@title\",\"@task\",\"@data\",\"@isRightPopupOpen\"],[[23,5,[]],[23,6,[]],[22,\"onEditPopupClose\"],[22,\"value\"],[23,7,[]]]],{\"statements\":[[0,\"\\n      \"],[6,[23,4,[\"header\"]],[],[[],[]]],[0,\"\\n\\n      \"],[6,[23,4,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n          \"],[6,[23,2,[]],[[12,\"class\",\"twelve wide column\"]],[[\"@header\",\"@isClearable\",\"@items\",\"@selectedItem\",\"@key\",\"@fadeBackground\",\"@required\"],[[28,\"t\",[\"pcr.treatment.type\"],null],false,[24,[\"pcrClassificator\",\"flowchart\",\"inventoryTypes\"]],[23,8,[\"type\"]],\"name\",false,true]]],[0,\"\\n\\n          \"],[6,[23,2,[]],[[12,\"class\",\"twelve wide column\"],[12,\"id\",\"inventory-item-total\"]],[[\"@header\",\"@items\",\"@selectedItem\",\"@key\",\"@onSelectedItem\",\"@fadeBackground\"],[[28,\"t\",[\"pcr.treatment.used\"],null],[24,[\"pcrClassificator\",\"totalQuantity\"]],[23,8,[\"total\"]],\"name\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,8,[\"total\"]]],null],[23,8,[\"total\",\"code\"]]],null],false]]],[0,\"\\n\\n          \"],[6,[23,3,[]],[[12,\"class\",\"twelve wide column\"]],[[\"@title\",\"@value\",\"@fadeBackground\"],[[28,\"t\",[\"pcr.treatment.comments\"],null],[23,8,[\"notes\"]],false]]],[0,\"        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[4]}],[0,\"\\n\"]],\"parameters\":[1,2,3]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/flowchart/inventory-input/edit/template.hbs"
    }
  });

  _exports.default = _default;
});