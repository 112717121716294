define("epcr-uis/pods/components/pcr-document/incident/team/component", ["exports", "epcr-uis/mixins/member-source", "ember-concurrency", "epcr-uis/mixins/metrics-tracker", "epcr-uis/constants", "epcr-uis/mixins/is-station-allowed-to-create-new-pcr"], function (_exports, _memberSource, _emberConcurrency, _metricsTracker, _constants, _isStationAllowedToCreateNewPcr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const leaderTypePath = "teamActivation.team.leaderType";
  const teamIdPath = "teamActivation.team.code";

  var _default = Ember.Component.extend(_isStationAllowedToCreateNewPcr.default, _memberSource.default, _metricsTracker.default, {
    tagName: "",
    pcrClassificator: Ember.inject.service(),
    login: Ember.inject.service(),
    team: Ember.inject.service(),
    pcr: Ember.inject.service(),
    features: Ember.inject.service(),
    userPreferencesService: Ember.inject.service("user-preferences"),
    FEAT_SHOW_CLUSTER_TEAM: _constants.FEAT_SHOW_CLUSTER_TEAM,
    memberFavourites: null,
    translationKeys: {
      "member-0": "pcr.teamActivation.team.members.0",
      "member-1": "pcr.teamActivation.team.members.1",
      "member-2": "pcr.teamActivation.team.members.2",
      "member-3": "pcr.teamActivation.team.members.3"
    },
    isStationAllowedToCreateNewEpcr: Ember.computed({
      get() {
        return this.isStationAllowedToCreateNewPcr();
      }

    }),

    setMemberFavourites() {
      const loggedMember = this.login.employeeData;

      if (loggedMember) {
        loggedMember.favourite = 1;
        Ember.set(this, "memberFavourites", [loggedMember]);
      }
    },

    leaderType: Ember.computed(`${leaderTypePath}.code`, {
      set(key, value) {
        return Ember.set(this, leaderTypePath, value);
      },

      get() {
        return this.teamActivation.team.leaderType;
      }

    }),
    filteredMembers: Ember.computed.filterBy("teamActivation.team.members", "externalId"),
    isTeamDataEmpty: Ember.computed("filteredMembers.length", function () {
      return !Ember.get(this, "filteredMembers.length");
    }),

    didRender() {
      this._super(...arguments);

      const positions = Ember.get(this, "teamActivation.team.members.firstObject.positions");
      if ((!Ember.get(this, teamIdPath) || Ember.get(this, teamIdPath).length < 4) && this.team.activeTeam) Ember.set(this, teamIdPath, this.team.activeTeam);
      if (!Ember.get(this, leaderTypePath) && positions) this.setLeaderTypeByPosition(positions);
    },

    setPositions() {
      const {
        positions
      } = this.pcrClassificator;
      const fragmentPositions = [];
      positions.forEach(({
        code
      }) => fragmentPositions.push(this.createPosition(code)));
      Ember.set(this, "positions", fragmentPositions);
    },

    init() {
      this._super(...arguments);

      this.setPositions();
      this.setMemberFavourites();
    },

    showTeamFillButton: Ember.computed("isTeamDataEmpty", "userPreferencesService.userPreferences.teamActivation", function () {
      const {
        teamActivation
      } = Ember.getProperties(this.userPreferencesService.userPreferences, _constants.TEAM_ACTIVATION);
      return this.isTeamDataEmpty && teamActivation;
    }),
    onTeamDataChange: Ember.observer("filteredMembers", function () {
      Ember.set(this, "saveTeamDataTask.last", null);
    }),
    hasPosition: (positions, expected) => positions.find && positions.find(({
      code
    }) => expected(code)),

    setLeaderType(leaderType) {
      Ember.set(this, leaderTypePath, leaderType);
    },

    setLeaderTypeByPosition(positions) {
      if (positions) {
        const nurseValue = this.createPosition("SLAUG");
        const doctorValue = this.createPosition("GYD");
        const paramedicValue = this.createPosition("PARAM");
        const isDoctor = this.hasPosition(positions, code => code === "GYD" || code === "VYRGYD" || code === "REZ");
        const isParamedic = this.hasPosition(positions, code => code === "PARAM" || code === "PARVAIR");
        if (isDoctor) this.setLeaderType(doctorValue);else if (isParamedic) this.setLeaderType(paramedicValue);else this.setLeaderType(nurseValue);
      }
    },

    setMember(idx, data) {
      const member = Ember.get(this, "teamActivation.team.members").objectAt(idx);
      Ember.set(member, "externalId", data.externalId);
      data.favourite ? member.resetFragment() : Ember.setProperties(member, data);
    },

    saveTeamDataTask: (0, _emberConcurrency.task)(function* (teamActivation) {
      const {
        members,
        leaderType
      } = teamActivation.team;
      const membersIds = this.getMembersIds(members);
      const code = leaderType ? leaderType.code : null;
      yield this.userPreferencesService.updateKeyValue(_constants.TEAM_ACTIVATION, membersIds);
      yield this.userPreferencesService.updateKeyValue(_constants.TEAM_LEADER_TYPE, code);
      yield this.userPreferencesService.updateKeyValue(_constants.TEAM_TYPE, teamActivation.teamType);
      yield this.userPreferencesService.updateKeyValue(_constants.CLUSTER_TEAM, Ember.get(teamActivation, "team.cluster"));
      this.trackEvent("Team", "Save team members", `Team leader type - ${code}`, membersIds.length);
    }).restartable(),

    getMembersIds(members) {
      const result = [];
      members.forEach(member => result.push(Ember.get(member, "externalId")));
      return result;
    },

    loadTeamMembers(membersIds, membersModel) {
      membersIds.forEach((externalId, idx) => {
        if (externalId) {
          membersModel.objectAt(idx).set("externalId", externalId);
          membersModel.objectAt(idx).resetFragment();
        }
      });
    },

    updateTeamActivationModel(memberIds, leaderType, teamType, clusterTeam) {
      const teamActivationModel = this.teamActivation;
      this.loadTeamMembers(memberIds, teamActivationModel.get("team.members"));
      Ember.set(teamActivationModel, "team.leaderType", this.createPosition(leaderType));
      Ember.set(teamActivationModel, "teamType", teamType);
      Ember.set(teamActivationModel, "team.cluster", clusterTeam);
    },

    createPosition(leaderType) {
      return this.pcrClassificator.findElementByCode("positions", leaderType);
    },

    autoFillTeamTask: (0, _emberConcurrency.task)(function* () {
      const membersIds = this.userPreferencesService.userPreferences[_constants.TEAM_ACTIVATION];
      const leaderType = this.userPreferencesService.userPreferences[_constants.TEAM_LEADER_TYPE];
      const teamType = this.userPreferencesService.userPreferences[_constants.TEAM_TYPE];
      const clusterTeam = this.userPreferencesService.userPreferences[_constants.CLUSTER_TEAM];
      yield this.updateTeamActivationModel(membersIds, leaderType, teamType, clusterTeam);
      this.trackEvent("Team", "Load team members");
    }).restartable(),
    actions: {
      onDeletedMember(idx) {
        this.setMember(idx, {
          code: null,
          externalId: null
        });
      },

      onSelectedMember(idx, member) {
        idx === 0 && this.setLeaderTypeByPosition(Ember.get(member, "positions"));
        return this.setMember(idx, member);
      },

      autoFillTeam() {
        this.autoFillTeamTask.perform();
      },

      onFavouriteTeamLeaderPopupOpen() {
        Ember.set(this, "memberFavourites", null);
      }

    }
  });

  _exports.default = _default;
});