define("epcr-uis/mirage/factories/time", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ten = 10;
  const eleven = 11;

  const time = (h, min, sec = ten) => `2018-08-01 ${h}:${min}:${sec}`;

  var _default = _emberCliMirage.Factory.extend({
    callTime: () => time(ten, eleven),
    registrationTime: () => time(ten, 12),
    assignTime: () => time(ten, 12),
    onRouteTime: () => time(ten, 14),
    arrivedTime: () => time(ten, 29),
    transportationTime: () => time(ten, 58),
    atHospitalTime: () => time(eleven, 15),
    availableTime: () => time(eleven, 22),
    returnedTime: () => time(eleven, 24)
  });

  _exports.default = _default;
});