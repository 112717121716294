define("epcr-uis/mirage/factories/call-information", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    callTakerCode: "555",
    phoneNumber1: "+37065184894",
    phoneNumber2: "+37064654518",
    phoneNumber3: 112,
    emergencyCallDetails: () => (0, _isEnglish.isEnglish)() ? "Stomach pain" : "Raižo pilvą"
  });

  _exports.default = _default;
});