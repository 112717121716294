define("epcr-uis/pods/pcr/vitals/new/route", ["exports", "epcr-uis/helpers/get-classificator-element-by-code", "epcr-uis/helpers/is-paramedic", "epcr-uis/mixins/route-tracker"], function (_exports, _getClassificatorElementByCode, _isParamedic, _routeTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeTracker.default, {
    queryParams: {
      type: {}
    },
    store: Ember.inject.service(),
    pcr: Ember.inject.service(),
    pcrTime: Ember.inject.service("pcr/time"),
    pcrClassificator: Ember.inject.service(),

    model({
      type
    }) {
      return {
        pcr: this.modelFor("pcr").pcrDocument,
        type
      };
    },

    createConsciousnessType(pcr) {
      const leaderType = Ember.get(pcr, "teamActivation.team.leaderType.code");
      return (0, _isParamedic.isParamedic)([leaderType]) ? "AVPU" : "GKS";
    },

    afterModel({
      pcr,
      type
    }) {
      const time = this.pcrTime.newTime(pcr).forAssessment(type);
      const consciousness = {
        gcs: {},
        type: this.createConsciousnessType(pcr)
      };
      const typeFragment = (0, _getClassificatorElementByCode.getClassificatorElementByCode)("pcrDocumentVitalParams.assessmentTypes", type, this.pcrClassificator);
      pcr.get("patientAssessments").pushObject({
        time,
        vitals: {},
        breathing: {},
        consciousness,
        circulation: {},
        ecg: {},
        type: typeFragment
      });
      this.transitionTo("pcr.vitals.edit", typeFragment.order - 1);
    }

  });

  _exports.default = _default;
});