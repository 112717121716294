define("epcr-uis/pods/pcr/patient-assessment/breathing/model", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _fragment.default.extend({
    leftLung: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    rightLung: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    quality: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    isNotFilled: Ember.computed("leftLung.@each.code", "rightLung.@each.code", "quality.code", function () {
      const objects = ["leftLung", "rightLung", "quality"];

      for (let i = 0; i < objects.length; i++) {
        const key = objects[i];

        if (this.isFieldFilled(key)) {
          return false;
        }
      }

      return true;
    }),

    isFieldFilled(key) {
      const arrayElementsCount = this.get(`${key}.length`);
      const isArray = arrayElementsCount !== undefined;
      return isArray ? arrayElementsCount > 0 : Boolean(this.get(key));
    }

  });

  _exports.default = _default;
});