define("epcr-uis/helpers/can-edit-pcr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.canEditPcr = void 0;

  const canEditPcr = state => {
    state = state && state.toUpperCase();
    return state === "IMPORTED" || state === "RESTORED" || state === "FILLED" || state === "SCANNED";
  };

  _exports.canEditPcr = canEditPcr;

  var _default = Ember.Helper.helper(([state]) => canEditPcr(state));

  _exports.default = _default;
});