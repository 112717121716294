define("epcr-uis/components/side-bar/single-select/not-selected-item", ["exports", "ember-components/components/side-bar/single-select/not-selected-item"], function (_exports, _notSelectedItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _notSelectedItem.default;
    }
  });
});