define("epcr-uis/mirage/factories/event-notifications", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    notifiedPolice: false,
    notifiedFireDep: false,
    notifiedOther: true,
    additionalInformation: () => (0, _isEnglish.isEnglish)() ? "Gas services should be informed, gas odor on event location" : "Nėra namų be dūmų - kvieskit gaisrinę"
  });

  _exports.default = _default;
});