define("epcr-uis/pods/list/active-pcrs/route", ["exports", "epcr-uis/mixins/on-pcr-list-item-click"], function (_exports, _onPcrListItemClick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_onPcrListItemClick.default, {
    activePcr: Ember.inject.service("list/active-pcrs"),

    model() {
      return this.activePcr.queryActivePcrsTask;
    }

  });

  _exports.default = _default;
});