define("epcr-uis/pods/components/android-signal-modal/component", ["exports", "epcr-uis/mixins/on-pcr-list-item-click", "epcr-uis/mixins/metrics-tracker", "epcr-uis/helpers/duration-count"], function (_exports, _onPcrListItemClick, _metricsTracker, _durationCount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_onPcrListItemClick.default, _metricsTracker.default, {
    tagName: "",
    androidSignal: Ember.inject.service(),
    pcrRouteTracker: Ember.inject.service("pcr-route-tracker"),
    androidSignalModal: "Android signal modal",
    isSoundMuted: false,
    pcrHeader: Ember.inject.service(),
    newPcrsStack: [],
    countUpTimerLoop: null,
    formattedTimeDuration: null,

    init() {
      this._super(...arguments);

      this.androidSignal.on("onNewPcr", this, "onNewPcr");
    },

    didDestroyElement() {
      this.androidSignal.off("onNewPcr", this, "onNewPcr");
    },

    onNewPcr(pcrId) {
      this.androidSignal.startSound();
      Ember.set(this, "isSoundMuted", false);
      const self = this;
      this.pcrHeader.getPcrHeader.perform(pcrId).then(data => {
        self.trackEvent(this.androidSignalModal, "show modal");
        self.updateStackWithData(data, pcrId);
      }).catch(e => {
        self.trackEvent(this.androidSignalModal, "show modal", "failed to load pcr header", e.message);
        self.updateStackWithData(null, pcrId);
      });
    },

    updateStackWithData(data, pcrId) {
      let convertedData = {
        pcrId
      };

      if (data) {
        convertedData = {
          category: Ember.get(data, "category.code"),
          callTime: Ember.get(data, "times.callTime"),
          registrationTime: Ember.get(data, "times.registrationTime"),
          address: Ember.get(data, "callInformation.eventSite.address"),
          pcrId
        };
        this.startDurationCounter(convertedData);
      }

      this.newPcrsStack.pushObject(convertedData);
    },

    showRedirectButton: Ember.computed("router.currentRouteName", function () {
      return !this.router.currentRouteName.startsWith("pcr.");
    }),
    _getDurationCountOnlyMinAndSec: duration => (0, _durationCount.durationCountOnlyMinAndSec)(duration),

    setTimeDiffFromRegistrationOrCallTime({
      registrationTime,
      callTime
    }) {
      const time = registrationTime || callTime;
      time ? Ember.set(this, "formattedTimeDuration", this._getDurationCountOnlyMinAndSec(time.diff() * -1)) : this.formattedTimeDuration && Ember.set(this, "formattedTimeDuration", "");
    },

    _startCountUpTimer(signal) {
      Ember.set(this, "countUpTimerLoop", Ember.run.later(() => {
        this.setTimeDiffFromRegistrationOrCallTime(signal);

        this._startCountUpTimer(signal);
      }, 1000));
    },

    startDurationCounter(signal) {
      this.stopDurationCounter();

      if (signal) {
        this.setTimeDiffFromRegistrationOrCallTime(signal);

        this._startCountUpTimer(signal);
      }
    },

    stopDurationCounter() {
      return this.countUpTimerLoop && Ember.run.cancel(this.countUpTimerLoop);
    },

    stopSoundAndSetSoundMuted() {
      this.androidSignal.stopSound();
      Ember.set(this, "isSoundMuted", true);
    },

    actions: {
      onSoundStop() {
        this.stopSoundAndSetSoundMuted();
        this.trackEvent(this.androidSignalModal, "stop sound");
      },

      onClose() {
        this.stopDurationCounter();
        this.trackEvent(this.androidSignalModal, "close modal");
        this.stopSoundAndSetSoundMuted();
        this.newPcrsStack.popObject();
        this.newPcrsStack.length && this.startDurationCounter(this.newPcrsStack.lastObject);
        Ember.set(this, "isSoundMuted", true);
      },

      onPcrClicked() {
        this.trackEvent(this.androidSignalModal, "redirect to pcr");
        this.stopSoundAndSetSoundMuted();
        Ember.set(this, "isTransitingToPcr", true);

        this._super(...arguments).finally(() => {
          this.stopDurationCounter();
          Ember.set(this, "isTransitingToPcr", false);
          this.newPcrsStack.popObject();
        });
      }

    }
  });

  _exports.default = _default;
});