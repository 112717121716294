define("epcr-uis/pods/pcr-classificator/medicaments/mazeikiai", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MAZEIKIAI_MEDICAMENTS = void 0;

  const MAZEIKIAI_MEDICAMENTS = t => ({
    stations: ["25"],
    medicaments: [(0, _medicamentsList.MEDICAMENTS)(t)[206], (0, _medicamentsList.MEDICAMENTS)(t)[208], // 208 eina pirmiau už 207, kad būtų gražiai išdėliota
    (0, _medicamentsList.MEDICAMENTS)(t)[207], (0, _medicamentsList.MEDICAMENTS)(t)[212], (0, _medicamentsList.MEDICAMENTS)(t)[215], (0, _medicamentsList.MEDICAMENTS)(t)[216], (0, _medicamentsList.MEDICAMENTS)(t)[220], (0, _medicamentsList.MEDICAMENTS)(t)[224], (0, _medicamentsList.MEDICAMENTS)(t)[226], (0, _medicamentsList.MEDICAMENTS)(t)[228], (0, _medicamentsList.MEDICAMENTS)(t)[230], (0, _medicamentsList.MEDICAMENTS)(t)[232], (0, _medicamentsList.MEDICAMENTS)(t)[236], (0, _medicamentsList.MEDICAMENTS)(t)[237], (0, _medicamentsList.MEDICAMENTS)(t)[242], (0, _medicamentsList.MEDICAMENTS)(t)[243], (0, _medicamentsList.MEDICAMENTS)(t)[245], (0, _medicamentsList.MEDICAMENTS)(t)[246], (0, _medicamentsList.MEDICAMENTS)(t)[251], (0, _medicamentsList.MEDICAMENTS)(t)[275], (0, _medicamentsList.MEDICAMENTS)(t)[276], (0, _medicamentsList.MEDICAMENTS)(t)[277], (0, _medicamentsList.MEDICAMENTS)(t)[290], (0, _medicamentsList.MEDICAMENTS)(t)[291], (0, _medicamentsList.MEDICAMENTS)(t)[292], (0, _medicamentsList.MEDICAMENTS)(t)[293], (0, _medicamentsList.MEDICAMENTS)(t)[294]]
  });

  _exports.MAZEIKIAI_MEDICAMENTS = MAZEIKIAI_MEDICAMENTS;
});