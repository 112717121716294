define("epcr-uis/pods/pcr/trauma-criteria/model", ["exports", "ember-data", "ember-data-model-fragments/attributes", "ember-data-model-fragments/fragment", "epcr-uis/helpers/is-object-filled"], function (_exports, _emberData, _attributes, _fragment, _isObjectFilled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _fragment.default.extend({
    abcdEval: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    injuries: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    background: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    safetyMeasures: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    erNotified: attr("boolean"),
    isFilled: Ember.computed("abcdEval.@each.code", "injuries.@each.code", "background.@each.code", "erNotified", "safetyMeasures.@each.code", function () {
      const keys = ["abcdEval", "injuries", "background", "safetyMeasures", "erNotified"];
      return (0, _isObjectFilled.isObjectFilled)([keys, this]);
    })
  });

  _exports.default = _default;
});