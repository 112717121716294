define("epcr-uis/pods/pcr-classificator/medicaments/medicaments-list", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/get-medicaments-data"], function (_exports, _getMedicamentsData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MEDICAMENTS = void 0;
  const PILLS = "options.treatment.medicamentTypes.pills";
  const SOLUTION_INFUSION = "options.treatment.medicamentTypes.solutionInfusion";
  const SOLUTION_INJECTION = "options.treatment.medicamentTypes.solutionInjection";
  const SOLUTION_INJECTION_INFUSION = "options.treatment.medicamentTypes.solutionInjectionInfusion";

  const TYPE_1MG_ML = t => `1mg/ml, ${t(SOLUTION_INJECTION)}`;

  const BURNT_IMPREGNATED = "Nudegiminis, impregnuotas";

  const MEDICAMENTS = t => ({
    1: {
      name: t(`${_getMedicamentsData.medicament}aspirin`),
      // Acetilsalicilo rūgštis
      types: [{
        code: 1,
        name: `500mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    2: {
      name: t(`${_getMedicamentsData.medicament}adenocard`),
      // Adenozinas
      types: [{
        code: 2,
        name: `3mg/ml, ${t(SOLUTION_INJECTION_INFUSION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)]
      }]
    },
    4: {
      name: t(`${_getMedicamentsData.medicament}amiodarone`),
      // Amjodaronas
      types: [{
        code: 4,
        name: `150mg/3ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    5: {
      name: t(`${_getMedicamentsData.medicament}atropine`),
      // Atropino sulfatas
      types: [{
        code: 5,
        name: TYPE_1MG_ML(t),
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    8: {
      name: t(`${_getMedicamentsData.medicament}dexamethasone`),
      // Deksametazonas
      types: [{
        code: 8,
        name: `4mg/ml, ${t(SOLUTION_INJECTION_INFUSION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    9: {
      name: t(`${_getMedicamentsData.medicament}diazepam`),
      // Diazepamas
      types: [{
        code: 9,
        name: `5mg/ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.THROUGH_MOUTH)(t), (0, _getMedicamentsData.UNDER_TONGUE)(t)]
      }]
    },
    10: {
      name: t(`${_getMedicamentsData.medicament}dopamine`),
      // Dopaminas
      types: [{
        code: 10,
        name: `40mg/ml, ${t("options.treatment.medicamentTypes.concentrateForSolutionInfusion")}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)]
      }]
    },
    11: {
      name: t(`${_getMedicamentsData.medicament}epinephrine`),
      // Epinefrinas
      types: [{
        code: 11,
        name: TYPE_1MG_ML(t),
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.UNDER_SKIN)(t), (0, _getMedicamentsData.THROUGH_MOUTH)(t), (0, _getMedicamentsData.CURRENT_NOZZLE)(t), (0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    13: {
      name: t(`${_getMedicamentsData.medicament}etomidate`),
      // Etomidatas
      types: [{
        code: 13,
        name: `2mg/ml, ${t("options.treatment.medicamentTypes.injectionEmulsion")}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)]
      }]
    },
    14: {
      name: t(`${_getMedicamentsData.medicament}furosemide`),
      // Furozemidas
      types: [{
        code: 14,
        name: `20mg/2ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    15: {
      name: t(`${_getMedicamentsData.medicament}nitroglycerin`),
      // Glicerolio trinitratas
      types: [{
        code: 15,
        name: `500μg, ${t("options.treatment.medicamentTypes.underTonguePills")}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t), (0, _getMedicamentsData.UNDER_TONGUE)(t)]
      }]
    },
    16: {
      name: t(`${_getMedicamentsData.medicament}nitroglycerin`),
      // Glicerolio trinitratas
      types: [{
        code: 16,
        name: `1mg/ml, ${t(SOLUTION_INFUSION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)]
      }]
    },
    17: {
      name: t(`${_getMedicamentsData.medicament}glucagon`),
      types: [{
        code: 17,
        name: `1mg, ${t("options.treatment.medicamentTypes.powderAndSolventForSolutionInjection")}`,
        useType: [(0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.UNDER_SKIN)(t)]
      }]
    },
    18: {
      name: t(`${_getMedicamentsData.medicament}dextrose`),
      // Gliukozė
      types: [{
        code: 18,
        name: `${t("options.treatment.medicamentTypes.anhydrous")}, 200mg/ml, ${t(SOLUTION_INFUSION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.THROUGH_MOUTH)(t), (0, _getMedicamentsData.UNDER_TONGUE)(t)]
      }]
    },
    19: {
      name: t(`${_getMedicamentsData.medicament}dextrose`),
      // Gliukozė
      types: [{
        code: 19,
        name: `${t("options.treatment.medicamentTypes.monohydrate")}, 50mg/ml, ${t(SOLUTION_INFUSION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)]
      }]
    },
    26: {
      name: t(`${_getMedicamentsData.medicament}magSulfate`),
      // Magnio sulfatas heptahidratas
      types: [{
        code: 26,
        name: `250mg/ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    27: {
      name: t(`${_getMedicamentsData.medicament}methylprednisone`),
      // Metilprednizolonas
      types: [{
        code: 27,
        name: `40mg, ${t("options.treatment.medicamentTypes.powderAndSolventForSolutionInjection")}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    29: {
      name: t(`${_getMedicamentsData.medicament}midazolam`),
      // Midazolamas
      types: [{
        code: 29,
        name: `5mg/ml, ${t(SOLUTION_INJECTION_INFUSION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)]
      }]
    },
    30: {
      name: t(`${_getMedicamentsData.medicament}morphine`),
      // Morfino hidrochloridas
      types: [{
        code: 30,
        name: `10mg/ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.UNDER_SKIN)(t)]
      }]
    },
    34: {
      name: t(`${_getMedicamentsData.medicament}narcan`),
      // Naloksonas
      types: [{
        code: 34,
        name: `0,4mg/ml, ${t(SOLUTION_INJECTION_INFUSION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.UNDER_SKIN)(t)]
      }]
    },
    36: {
      name: t(`${_getMedicamentsData.medicament}oxytocin`),
      // Oksitocinas
      types: [{
        code: 36,
        name: `5TV/ml, ${t(SOLUTION_INJECTION_INFUSION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    38: {
      name: t(`${_getMedicamentsData.medicament}paracetamol`),
      // Paracetamolis
      types: [{
        code: 38,
        name: `500mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    39: {
      name: t(`${_getMedicamentsData.medicament}paracetamol`),
      // Paracetamolis
      types: [{
        code: 39,
        name: `250mg, ${t("options.treatment.medicamentTypes.candles")}`,
        useType: [(0, _getMedicamentsData.THROUGH_RECTUM)(t)]
      }]
    },
    40: {
      name: t(`${_getMedicamentsData.medicament}paracetamol`),
      // Paracetamolis
      types: [{
        code: 40,
        name: `150mg, ${t("options.treatment.medicamentTypes.candles")}`,
        useType: [(0, _getMedicamentsData.THROUGH_RECTUM)(t)]
      }]
    },
    41: {
      name: t(`${_getMedicamentsData.medicament}albuterol`),
      // Salbutamolis
      types: [{
        code: 41,
        name: `100μg/${t("options.treatment.medicamentTypes.inDose")}, ${t("options.treatment.medicamentTypes.compressedInhalationSuspension")}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    99: {
      name: t(`${_getMedicamentsData.medicament}other`),
      types: [{
        code: 99,
        name: t("options.treatment.medicamentTypes.other"),
        useType: (0, _getMedicamentsData.getAllMedicamentUseTypes)(t)
      }]
    },
    201: {
      name: t(`${_getMedicamentsData.medicament}aspirin`),
      // Acetilsalicilo rūgštis
      types: [{
        code: 201,
        name: `300mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    202: {
      name: t(`${_getMedicamentsData.medicament}aspirin`),
      // Acetilsalicilo rūgštis
      types: [{
        code: 202,
        name: `250mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    203: {
      name: t(`${_getMedicamentsData.medicament}atropine`),
      // Atropino sulfatas
      types: [{
        code: 203,
        name: `0,5mg/ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    204: {
      name: t(`${_getMedicamentsData.medicament}5`),
      // Deguonis
      types: [{
        code: 204,
        name: "45% suskyst. med. dujos (nosies kateteris)",
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    205: {
      name: t(`${_getMedicamentsData.medicament}dexamethasone`),
      // Deksametazonas
      types: [{
        code: 205,
        name: `0,5mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    206: {
      name: t(`${_getMedicamentsData.medicament}diazepam`),
      // Diazepamas
      types: [{
        code: 206,
        name: `5mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    207: {
      name: t(`${_getMedicamentsData.medicament}diazepam`),
      // Diazepamas
      types: [{
        code: 207,
        name: "10mg/2,5ml, rekt. tub.",
        useType: [(0, _getMedicamentsData.THROUGH_RECTUM)(t)]
      }]
    },
    208: {
      name: t(`${_getMedicamentsData.medicament}diazepam`),
      // Diazepamas
      types: [{
        code: 208,
        name: "5mg/2,5ml, rekt. tub.",
        useType: [(0, _getMedicamentsData.THROUGH_RECTUM)(t)]
      }]
    },
    209: {
      name: t(`${_getMedicamentsData.medicament}nitroglycerin`),
      // Glicerolio trinitratas
      types: [{
        code: 209,
        name: "400μg, poliežuvinis purškalas",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t), (0, _getMedicamentsData.UNDER_TONGUE)(t)]
      }]
    },
    211: {
      name: t(`${_getMedicamentsData.medicament}8`),
      // Ibuprofenas
      types: [{
        code: 211,
        name: `200mg, plėvele dengtos ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    212: {
      name: t(`${_getMedicamentsData.medicament}12`),
      // Klemastinas
      types: [{
        code: 212,
        name: `1mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    213: {
      name: t(`${_getMedicamentsData.medicament}16`),
      // Ondansetronas
      types: [{
        code: 213,
        name: `8mg/4ml, ${t(SOLUTION_INJECTION_INFUSION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    214: {
      name: "Ketoprofenas",
      types: [{
        code: 214,
        name: `100mg/2ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    215: {
      name: "Alcaine",
      types: [{
        code: 215,
        name: "5mg/ml 15ml, lašai",
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    216: {
      name: "Amoniakas",
      types: [{
        code: 216,
        name: "10%/40ml, odos tirpalas",
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    218: {
      name: t(`${_getMedicamentsData.medicament}1`),
      // Analginas
      types: [{
        code: 218,
        name: `500mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    220: {
      name: "Dicynone",
      types: [{
        code: 220,
        name: `250mg/2ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    221: {
      name: t(`${_getMedicamentsData.medicament}8`),
      // Ibuprofenas
      types: [{
        code: 221,
        name: `600mg, plėvele dengtos ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    223: {
      name: "Digoksinas",
      types: [{
        code: 223,
        name: `0,25mg/ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    224: {
      name: "Petidinas",
      types: [{
        code: 224,
        name: `50mg/ml, ${t(SOLUTION_INJECTION)}`,
        useType: [(0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.UNDER_SKIN)(t)]
      }]
    },
    226: {
      name: "Flamaderm",
      types: [{
        code: 226,
        name: "50ml, purškalas",
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    228: {
      name: "Šaldantis purškalas",
      types: [{
        code: 228,
        name: "purškalas",
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    230: {
      name: "Drotaverino hidrochloridas",
      types: [{
        code: 230,
        name: `40mg/2ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    231: {
      name: "Drotaverino hidrochloridas",
      types: [{
        code: 231,
        name: `40mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    232: {
      name: "Širdies lašai",
      types: [{
        code: 232,
        name: "30ml, geriamasis skystis",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    233: {
      name: "Vandenilio peroksidas",
      types: [{
        code: 233,
        name: "3%/100ml, odos tirpalas",
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    234: {
      name: "Verapamilis",
      types: [{
        code: 234,
        name: `80mg, plėvele dengtos ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    235: {
      name: "Vidurių lašai",
      types: [{
        code: 235,
        name: "25ml, geriamieji lašai",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    236: {
      name: "Šaldantis paketas",
      types: [{
        code: 236,
        name: "vienkartinis",
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    237: {
      name: t(`${_getMedicamentsData.medicament}1`),
      // Analginas
      types: [{
        code: 237,
        name: `1g/2ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    238: {
      name: "Atenololis",
      types: [{
        code: 238,
        name: `50mg, plėvele dengtos ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    239: {
      name: "Ciprinolis",
      types: [{
        code: 239,
        name: `500mg, plėvele dengtos ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    240: {
      name: "Diklofenakas",
      types: [{
        code: 240,
        name: `75mg/3ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_FAST_SLOW_DROPS_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    242: {
      name: "Eufilinas",
      types: [{
        code: 242,
        name: `120mg/5ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    243: {
      name: t(`${_getMedicamentsData.medicament}furosemide`),
      // Furozemidas
      types: [{
        code: 243,
        name: `40mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    244: {
      name: t(`${_getMedicamentsData.medicament}2`),
      // Heparinas
      types: [{
        code: 244,
        name: `25000TV/5ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.UNDER_SKIN)(t)]
      }]
    },
    245: {
      name: t(`${_getMedicamentsData.medicament}dextrose`),
      // gliukozė
      types: [{
        code: 245,
        name: `40%/10ml, ${t(SOLUTION_INFUSION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    246: {
      name: t(`${_getMedicamentsData.medicament}10`),
      // Kaptoprilis
      types: [{
        code: 246,
        name: `50mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t), (0, _getMedicamentsData.UNDER_TONGUE)(t)]
      }]
    },
    247: {
      name: t(`${_getMedicamentsData.medicament}lidocaine`),
      // Lidokainas
      types: [{
        code: 247,
        name: `20mg/ml, ${t(SOLUTION_INJECTION)}`,
        useType: [(0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.UNDER_SKIN)(t)]
      }]
    },
    249: {
      name: "Oktiseptas",
      types: [{
        code: 249,
        name: "1mg/20mg/ml, odos tirpalas su puršk. pompa",
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    250: {
      name: "Tramadolis",
      types: [{
        code: 250,
        name: `50mg/ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    251: {
      name: "Verapamilis",
      types: [{
        code: 251,
        name: `40mg, plėvele dengtos ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    252: {
      name: "Diklofenakas",
      types: [{
        code: 252,
        name: `150mg, pailginto atpalaidavimo ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    253: {
      name: "Akių lašai",
      types: [{
        code: 253,
        name: "5mg/ml 5ml, tirpalas",
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    254: {
      name: "Ausų lašai",
      types: [{
        code: 254,
        name: "5mg/ml 5ml, tirpalas",
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    255: {
      name: "Betadinas",
      types: [{
        code: 255,
        name: "100mg/ml, odos tirpalas",
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    256: {
      name: "Biseptolis",
      types: [{
        code: 256,
        name: `480mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    257: {
      name: "Furadoninas",
      types: [{
        code: 257,
        name: `100mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    259: {
      name: "Maalox",
      types: [{
        code: 259,
        name: `400mg, kramtomosios ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    260: {
      name: "Metoprololis",
      types: [{
        code: 260,
        name: `50mg, ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    261: {
      name: "Mezymas",
      types: [{
        code: 261,
        name: `20000V, skrandyje neirios ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    262: {
      name: "Microlax",
      types: [{
        code: 262,
        name: "1vnt, mikroklizma",
        useType: [(0, _getMedicamentsData.THROUGH_RECTUM)(t)]
      }]
    },
    263: {
      name: "Amoksicilinas",
      types: [{
        code: 263,
        name: `1000mg, plėvele dengtos ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    264: {
      name: "Rehydronas",
      types: [{
        code: 264,
        name: "10,7g, milteliai geriamajam tirpalui",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    265: {
      name: "Ringeris",
      types: [{
        code: 265,
        name: `500ml, ${t(SOLUTION_INFUSION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.CURRENT_NOZZLE)(t)]
      }]
    },
    266: {
      name: "Salbutamolis",
      types: [{
        code: 266,
        name: "2,5mg/2,5ml, purškiamasis įkvepiamasis tirpalas",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    267: {
      name: "Smecta",
      types: [{
        code: 267,
        name: "3g, milteliai geriamajai suspensijai",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    270: {
      name: "Tiapridalis",
      types: [{
        code: 270,
        name: `100mg/2ml, ${t(SOLUTION_INJECTION)}`,
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    271: {
      name: "Validolis",
      types: [{
        code: 271,
        name: `60mg, poliežuvinės ${t(PILLS)}`,
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t), (0, _getMedicamentsData.UNDER_TONGUE)(t)]
      }]
    },
    272: {
      name: "Hidrokortizonas",
      types: [{
        code: 272,
        name: "125mg/5ml, injekcinė suspencija",
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    273: {
      name: "Metoprololis",
      types: [{
        code: 273,
        name: "25mg, tabletės",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    274: {
      name: "Tikagreloras",
      types: [{
        code: 274,
        name: "90mg, plėvele dengtos tabletės",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    275: {
      name: "Flamaderm",
      types: [{
        code: 275,
        name: `${BURNT_IMPREGNATED} 10x10`,
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    276: {
      name: "Flamaderm",
      types: [{
        code: 276,
        name: `${BURNT_IMPREGNATED} 20x20`,
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    277: {
      name: "Flamaderm",
      types: [{
        code: 277,
        name: `${BURNT_IMPREGNATED} 40x20`,
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    278: {
      name: "Flamaderm",
      types: [{
        code: 278,
        name: `${BURNT_IMPREGNATED} 40x40`,
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    279: {
      name: "Flamaderm",
      types: [{
        code: 279,
        name: `${BURNT_IMPREGNATED} 40x60`,
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    280: {
      name: "Flamaderm",
      types: [{
        code: 280,
        name: `${BURNT_IMPREGNATED} 60x80`,
        useType: [(0, _getMedicamentsData.EXTERNALLY)(t)]
      }]
    },
    281: {
      name: "Eufilinas",
      types: [{
        code: 281,
        name: "150mg, tabletės",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    282: {
      name: "Furozemidas",
      types: [{
        code: 282,
        name: "20mg/2ml, injekcinis tirpalas",
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    283: {
      name: "Karbamazepinas",
      types: [{
        code: 283,
        name: "400mg, pailginto atpalaidavimo tabletės",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    284: {
      name: "Amitriptilinas",
      types: [{
        code: 284,
        name: "25mg, plėvele dengtos tabletės",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    285: {
      name: "Adenozinas",
      types: [{
        code: 285,
        name: "5mg/ml 2ml, injekcinis/infuzinis tirpalas",
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)]
      }]
    },
    286: {
      name: "Propofolis",
      types: [{
        code: 286,
        name: "10mg/ml 20ml, injekcinis/infuzinis tirpalas",
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)]
      }]
    },
    287: {
      name: "Fentanilis",
      types: [{
        code: 287,
        name: "50μg/ml 2ml, injekcinis/infuzinis tirpalas",
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    288: {
      name: "Paracetamolis",
      types: [{
        code: 288,
        name: "500mg, žvakutės",
        useType: [(0, _getMedicamentsData.THROUGH_RECTUM)(t)]
      }]
    },
    289: {
      name: "Askorbo rūgštis",
      types: [{
        code: 289,
        name: "50mg/ml 2ml, injekcinis tirpalas",
        useType: [...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)]
      }]
    },
    290: {
      name: "Loperamidas",
      types: [{
        code: 290,
        name: "2mg, tabletės",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    291: {
      name: "Amoksicilinas",
      types: [{
        code: 291,
        name: "500mg, plėvele dengtos tabletės",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    292: {
      name: "Acetilsalicilo rūgštis",
      types: [{
        code: 292,
        name: "150mg, tabletės",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    293: {
      name: "Paracetamolis",
      types: [{
        code: 293,
        name: "125mg, žvakutės",
        useType: [(0, _getMedicamentsData.THROUGH_RECTUM)(t)]
      }]
    },
    294: {
      name: "Aktyvintoji anglis",
      types: [{
        code: 294,
        name: "280mg, tabletės",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    },
    295: {
      name: "Acetilsalicilo rūgštis",
      types: [{
        code: 295,
        name: "100mg, tabletės",
        useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
      }]
    }
  });

  _exports.MEDICAMENTS = MEDICAMENTS;
});