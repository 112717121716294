define("epcr-uis/mixins/signer-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isFormSignedBySomeone(object) {
      const signedBy = Ember.get(object, "signatureType.code");
      const signerName = Ember.get(object, "signature.signerName");
      return signedBy === "SIGNED_BY_PATIENT" || signerName && signedBy === "SIGNED_BY_REPRESENTATIVE";
    }

  });

  _exports.default = _default;
});