define("epcr-uis/pods/pcr/error/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    historyService: Ember.inject.service("history"),
    queryParams: {
      errorCode: {}
    },

    renderTemplate() {
      this.render("pcr.error", {
        into: "application"
      });
    },

    beforeModel() {
      this.historyService.clearBrowserHistory();
    },

    model({
      errorCode
    }) {
      return errorCode;
    },

    actions: {
      goBackToHome() {
        return this.transitionTo("home");
      }

    }
  });

  _exports.default = _default;
});