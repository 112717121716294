define("epcr-uis/pods/components/pcr-document/print/component", ["exports", "epcr-uis/mixins/metrics-tracker"], function (_exports, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    print: Ember.inject.service(),
    android: Ember.inject.service(),
    isViewSelected: false,
    isEnabledPreview: true,
    isAndroid: false,
    zoomed: false,

    init() {
      this._super(...arguments);

      this.clearErrors();
      this.isAndroid = this.android.isAndroid();
    },

    clearErrors() {
      Ember.get(this, "print.fetchTask.error") && Ember.set(this, "print.fetchTask.error", null);
      Ember.get(this, "print.printingTask.last.error") && Ember.set(this, "print.printingTask.last.error", null);
    },

    actions: {
      generateImage(source) {
        if (this.isViewSelected) {
          this.print.generatePNG(source).catch(() => Ember.set(this, "isViewSelected", null));
          this.trackEvent("Print", "Generate png preview");
        }
      },

      printPdf(source) {
        this.print.printPDF(source);
        this.trackEvent("Print", "Print pdf");
      },

      toggleZoom() {
        this.toggleProperty("zoomed");
        this.trackEvent("Print", "Toggle zoom");
      }

    }
  });

  _exports.default = _default;
});