define("epcr-uis/mixins/later-loop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    runLoop(method, period) {
      this.cancelLoop();
      method();
      const id = Ember.run.later(() => {
        this.runLoop.apply(this, arguments);
      }, period);
      Ember.set(this, "loopId", id);
    },

    cancelLoop() {
      this.loopId && Ember.run.cancel(this.loopId);
    }

  });

  _exports.default = _default;
});