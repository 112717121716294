define("epcr-uis/pods/print/empty/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uxx+ant8",
    "block": "{\"symbols\":[\"Print\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"pcr-document/print\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@source\",\"@isEnabledPreview\",\"@title\"],[[24,[\"model\",\"source\"]],false,[28,\"t\",[\"headings.print.emptyForm\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/print/empty/template.hbs"
    }
  });

  _exports.default = _default;
});