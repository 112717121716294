define("epcr-uis/pods/components/pcr-document/tools/pediatric-calculator/component", ["exports", "epcr-uis/helpers/count-age-in-months"], function (_exports, _countAgeInMonths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["pediatric-calculator"],
    time: Ember.inject.service(),
    pediatricClassificator: Ember.inject.service("pcr-classificator/calculator/pediatric"),
    isAgeWithinGivenRange: (min, max, age) => min <= age && max >= age,

    findCellAccordingToAge(ageInMonths) {
      for (const row of this.rows) for (const cell of row) if (cell.range && this.isAgeWithinGivenRange(cell.range.min, cell.range.max, ageInMonths)) return cell;
    },

    getCellAccordingToAge(ageInMonths) {
      return Ember.isPresent(ageInMonths) && this.findCellAccordingToAge(ageInMonths);
    },

    age: Ember.computed("pcr.patientDetails.birthDate", "pcr.patientDetails.age.{approximate,age,measure.code}", {
      get() {
        const ageInMonths = (0, _countAgeInMonths.countAgeInMonths)([this.time, Ember.get(this, "pcr.patientDetails"), Ember.get(this, "pcr.times.callTime")]);
        return this.getCellAccordingToAge(ageInMonths);
      },

      set: (key, value) => value
    }),
    createCell: (title, valueInMonths, values, range) => ({
      title,
      value: valueInMonths,
      values,
      range
    }),
    createRange: (min, max) => ({
      min,
      max
    }),
    rows: Ember.computed({
      get() {
        const {
          createCell,
          createRange,
          pediatricClassificator: {
            cells
          }
        } = this;
        return [[createCell("Ankstukas", null, cells.precocious, null), createCell("Naujagimis", 1, cells.newBorn, createRange(0, 2)), createCell("3 mėn.", 3, cells.threeMonths, createRange(3, 5))], [createCell("6 mėn.", 6, cells.sixMonths, createRange(6, 8)), createCell("9 mėn.", 9, cells.nineMonths, createRange(9, 11)), createCell("12 mėn.", 12, cells.twelveMonths, createRange(12, 14)), createCell("15 mėn.", 15, cells.fifteenMonths, createRange(15, 17)), createCell("18 mėn.", 18, cells.eighteenMonths, createRange(18, 23))], [createCell("2 m.", 24, cells.twoYears, createRange(24, 35)), createCell("3 m.", 36, cells.threeYears, createRange(36, 47)), createCell("4 m.", 48, cells.fourYears, createRange(48, 59)), createCell("5 m.", 60, cells.fiveYears, createRange(60, 71)), createCell("6 m.", 72, cells.sixYears, createRange(72, 95)), createCell("8 m.", 96, cells.eightYears, createRange(96, 119))], [createCell("10 m.", 120, cells.tenYears, createRange(120, 143)), createCell("12 m.", 144, cells.twelveYears, createRange(144, 167)), createCell("14 m.", 168, cells.fourteenYears, createRange(168, 191)), createCell("16 m.", 192, cells.sixteenYears, createRange(192, 215))]];
      },

      set: (key, value) => value
    })
  });

  _exports.default = _default;
});