define("epcr-uis/pods/components/pcr-document/vitals-summary/empty-assessment/component", ["exports", "epcr-uis/helpers/get-classificator-element-by-code", "epcr-uis/mixins/copy-assessment-values"], function (_exports, _getClassificatorElementByCode, _copyAssessmentValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_copyAssessmentValues.default, {
    tagName: "",
    pcrClassificator: Ember.inject.service(),

    createEmptyAssessment(time, type) {
      return {
        time,
        vitals: {},
        breathing: {},
        consciousness: type.code !== "AT_ER" ? {
          gcs: {}
        } : undefined,
        circulation: {},
        ecg: type.code !== "AT_ER" ? {} : undefined,
        type
      };
    },

    getAssessmentTypeFromClassificator(assessmentType) {
      return (0, _getClassificatorElementByCode.getClassificatorElementByCode)("pcrDocumentVitalParams.assessmentTypes", assessmentType, this.pcrClassificator);
    },

    createAssessment(assessmentType) {
      const time = this.pcrTime.newTime(this.pcr).forAssessment(assessmentType);
      const type = this.getAssessmentTypeFromClassificator(assessmentType);
      return this.createEmptyAssessment(time, type);
    },

    actions: {
      transitionToNewPage(code, assessments, index) {
        return this.onNew(index, code);
      },

      copyABToAAAssessment(abAssessment) {
        const assessmentType = "AFTER_HELP";
        const assessmentAA = this.createAssessment(assessmentType);
        this.assessments.pushObject(assessmentAA);
        Ember.set(this, "assessmentType", assessmentType);
        Ember.set(this, "assessment", assessmentAA);
        Ember.set(this, "previousAssessment", abAssessment);
        this.copyAssessment();
      },

      copyToAtErAssessment(abAssessment, aaAssessment) {
        const assessmentType = "AT_ER";
        const assessmentAtEr = this.createAssessment(assessmentType);
        this.assessments.pushObject(assessmentAtEr);
        Ember.set(this, "assessmentType", assessmentType);
        Ember.set(this, "assessment", assessmentAtEr);
        Ember.set(this, "previousAssessment", aaAssessment || abAssessment);
        this.copyAssessment();
      }

    }
  });

  _exports.default = _default;
});