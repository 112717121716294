define("epcr-uis/pods/android-signal/service", ["exports", "ember-window-mock", "epcr-uis/helpers/window-wrapper"], function (_exports, _emberWindowMock, _windowWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    android: Ember.inject.service(),
    session: Ember.inject.service(),

    init() {
      this._super(...arguments);

      if (this.android.isAndroid()) {
        this.initOnPcrUpdateCallback();
        this.initOnNewPcrCallback();
        this.session.on("invalidationSucceeded", this, "unsubscribe");
      }
    },

    callSignalMethod: (method, param) => (0, _windowWrapper.callMethod)(`window.signal.${method}`, param),

    setTeamId(team) {
      this.callSignalMethod("setTeamId", team);
    },

    watchPcrChangesOnBackend(pcrId) {
      this.callSignalMethod("setPcrId", pcrId);
    },

    stopWatchPcrChangesOnBackend() {
      this.watchPcrChangesOnBackend(null);
    },

    initOnPcrUpdateCallback() {
      const method = "setPcrUpdateCallback";
      const callbackFunction = "triggerPcrChange";
      const self = this;
      _emberWindowMock.default.epcr[callbackFunction] = self.onPcrUpdate.bind(self);
      this.callSignalMethod(method, callbackFunction);
    },

    unsubscribe() {
      this.setTeamId(null);
      this.stopSound();
    },

    startSound(method = "start") {
      this.callSignalMethod(method);
    },

    stopSound(method = "stop") {
      this.callSignalMethod(method);
    },

    initOnNewPcrCallback() {
      const method = "onNewPcr";

      _emberWindowMock.default.epcr[method] = pcrId => this[method](pcrId);

      this.callSignalMethod("setTeamPcrsUpdateCallback", method);
    },

    onNewPcr(pcrId) {
      this.trigger("onNewPcr", pcrId);
    },

    onPcrUpdate() {
      this.trigger("onPcrUpdate");
    }

  });

  _exports.default = _default;
});