define("epcr-uis/pods/components/pcr-document/specialty-patient-forms/death/popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HiwwYOiM",
    "block": "{\"symbols\":[\"TimePicker\",\"death\",\"Cpr\",\"MultiSelect\",\"@pcr\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/time-picker\"],null],[23,5,[\"deathStatement\"]],[28,\"component\",[\"pcr-document/specialty-patient-forms/cpr\"],null],[28,\"component\",[\"side-bar/multi-select\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[[12,\"class\",\"twelve wide column\"],[12,\"id\",\"death-time\"]],[[\"@time\",\"@header\",\"@isFavouriteOptionsFeatureEnabled\",\"@validation\"],[[23,2,[\"deathTime\"]],[28,\"t\",[\"options.forms.deathStatement.time\"],null],false,[24,[\"pcrValidation\",\"errors\",\"death-time\"]]]]],[0,\"\\n\\n  \"],[6,[23,4,[]],[[12,\"class\",\"twelve wide column\"],[12,\"id\",\"death-signs\"]],[[\"@header\",\"@items\",\"@selectedItems\",\"@validation\",\"@fadeBackground\"],[[28,\"t\",[\"options.forms.deathStatement.signs\"],null],[24,[\"pcrClassificator\",\"deathSigns\"]],[23,2,[\"deathSigns\"]],[24,[\"pcrValidation\",\"errors\",\"death-signs\"]],false]]],[0,\"\\n\\n  \"],[6,[23,3,[]],[],[[\"@death\",\"@isVisible\"],[[23,5,[\"clinicalDeath\"]],[23,2,[\"deathTime\"]]]]],[0,\"\\n\"]],\"parameters\":[1,2,3,4]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/specialty-patient-forms/death/popup/template.hbs"
    }
  });

  _exports.default = _default;
});