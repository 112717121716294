define("epcr-uis/pods/address/model", ["exports", "ember-data", "epcr-uis/pods/address/street/model", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _model.default.extend({
    coordinate: (0, _attributes.fragment)("address/coordinate"),
    house: attr("string"),
    flat: attr("string"),
    additionalInformation: attr("string"),
    // todo nenaudojamas laukelis
    crossingStreet: attr("string")
  });

  _exports.default = _default;
});