define("epcr-uis/pods/pcr-state/adapter", ["exports", "epcr-uis/pods/application/adapter", "epcr-uis/config/environment"], function (_exports, _adapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    urlForQueryRecord(queryParams) {
      const queryUrl = _environment.default.APP.pcrVerifyUrl.replace(":pcrId", queryParams.pcrId);

      delete queryParams.pcrId;
      return queryUrl;
    }

  });

  _exports.default = _default;
});