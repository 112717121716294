define("epcr-uis/pods/address/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    getAddressesByStreet(street, lon, lat, county) {
      return this.store.query("address/street", {
        query: street,
        lon,
        lat,
        county
      });
    },

    getAddressesByCity(city) {
      return this.store.query("address/city", {
        query: city
      });
    }

  });

  _exports.default = _default;
});