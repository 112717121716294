define("epcr-uis/pods/components/pcr-document/tools/dose-calculator/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["dose-calculator"],
    createCell: (weight, min, max) => ({
      title: `${weight} kg`,
      value: weight,
      range: {
        min,
        max
      }
    }),
    isWeightWithinGivenRange: (min, max, weight) => min <= weight && max >= weight,

    findCellAccordingToWeight(weight) {
      for (const row of this.rows) for (const cell of row) if (cell.range && this.isWeightWithinGivenRange(cell.range.min, cell.range.max, weight)) return cell;

      return null;
    },

    getCellAccordingToAge(weight) {
      return weight && this.findCellAccordingToWeight(weight);
    },

    weight: Ember.computed("pcr.patientDetails.weight", {
      get() {
        return this.getCellAccordingToAge(Ember.get(this, "pcr.patientDetails.weight"));
      },

      set: (key, value) => value
    }),
    rows: Ember.computed(function () {
      const {
        createCell
      } = this;
      return [[createCell(2, 0, 2), createCell(3, 3, 3), createCell(4, 4, 4), createCell(5, 5, 6)], [createCell(7, 7, 8), createCell(9, 9, 10), createCell(11, 11, 12)], [createCell(13, 13, 14), createCell(15, 15, 19), createCell(20, 20, 24), createCell(25, 25, 29), createCell(30, 30, 34)], [createCell(35, 35, 39), createCell(40, 40, 44), createCell(45, 45, 49), createCell(50, 50, 54), createCell(55, 55, 59), createCell(60, 60, 64), createCell(65, 65, 999)]];
    })
  });

  _exports.default = _default;
});