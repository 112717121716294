define("epcr-uis/pods/components/pcr-login/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["login"],
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    passwordType: "password",

    stayFocusedWhenClickedOnEye() {
      $(document).ready(function () {
        $("#password-input").click(function () {
          $(this).focus();
        });
        $(".eye").click(() => {
          $("#password-input").trigger("click");
        });
      });
    },

    init() {
      this._super(...arguments);

      this.stayFocusedWhenClickedOnEye();
      const defaultCredentials = this.features.isEnabled("demo") ? "demo" : "";
      Ember.setProperties(this, {
        username: defaultCredentials,
        password: defaultCredentials
      });
    },

    actions: {
      onLogin(username, password) {
        Ember.set(this, "error", null);
        Ember.set(this, "password", null);
        Ember.set(this, "isLoading", true);
        this.onLogin(username, password).catch(e => {
          Ember.set(this, "error", e.message === "Network request failed" ? "NO_INTERNET_CONNECTION" : e.json || e);
        }).finally(() => Ember.set(this, "isLoading", false));
        return false;
      },

      transitionToRoute(route) {
        return this.router.transitionTo(route, {
          queryParams: {
            nextRoute: "login"
          }
        });
      },

      showOrHidePassword() {
        Ember.set(this, "passwordType", this.passwordType === "text" ? "password" : "text");
      }

    }
  });

  _exports.default = _default;
});