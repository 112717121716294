define("epcr-uis/pods/components/pcr-document/printer-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    "data-test-id": "printer-status",
    classNames: ["printer-status"],
    print: Ember.inject.service(),
    messageMap: {
      OK: {
        title: "Aktyvus",
        message: "Pasiruošęs",
        iconColor: "green"
      },
      GENERIC_ERROR: {
        title: "Klaida",
        message: "Spausdintuvo klaida",
        iconColor: "red"
      },
      PRINTER_NOT_FOUND: {
        title: "Klaida",
        message: "Spausdintuvas neprijungtas",
        iconColor: "red"
      },
      PAPER_EMPTY: {
        title: "Klaida",
        message: "Nėra popieriaus",
        iconColor: "red"
      },
      OVERHEAT: {
        title: "Klaida",
        message: "Perkaitimas",
        iconColor: "red"
      },
      PAPER_JAM: {
        title: "Klaida",
        message: "Užstrigo popierius",
        iconColor: "red"
      },
      FILE_NOT_SUPPORTED: {
        title: "Klaida",
        message: "Netinkamas failas",
        iconColor: "red"
      },
      FILE_NOT_FOUND: {
        title: "Klaida",
        message: "Failas nerastas",
        iconColor: "red"
      },
      BUSY: {
        title: "Aktyvus",
        message: "Spausdinama (užimta)",
        iconColor: "yellow"
      },
      PRINTER_OFF: {
        title: "Klaida",
        message: "Spausdintuvas išjungtas",
        iconColor: "red"
      },
      PRINT_COMPLETE: {
        title: "Aktyvus",
        message: "Atspausdinta",
        iconColor: "green"
      },
      LOADING: {
        title: "Kraunasi",
        message: "Sujungiama/jungiama",
        iconColor: "yellow"
      }
    },

    getFieldValueFromMap(message) {
      const {
        messageMap
      } = this;
      return message ? messageMap[message] : messageMap.LOADING;
    },

    printerStatusData: Ember.computed("print.printerStatus", function () {
      return this.getFieldValueFromMap(this.print.printerStatus);
    })
  });

  _exports.default = _default;
});