define("epcr-uis/pods/pcr-header/serializer", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertPayload = convertPayload;
  _exports.default = void 0;
  var RESTSerializer = _emberData.default.RESTSerializer;

  const convertTimesToMoment = times => {
    if (times) {
      const keys = Object.keys(times);
      keys.forEach(key => Ember.set(times, key, (0, _moment.default)(Ember.get(times, key))));
    }
  };

  const findActivationProtocol = (classificator, dispatch, protocol) => {
    const protocolsByDispatch = classificator.getActivationProtocolByDispatch(dispatch);
    const foundProtocol = protocolsByDispatch.find(p => p.code === protocol);
    return foundProtocol || {
      code: protocol,
      name: protocol
    };
  };

  const convertValueToPcrClassificator = (classificator, key, value) => {
    return value && classificator.findElementByCode(key, value);
  };

  function convertPayload(classificator, payload, id) {
    const {
      teamActivation,
      times,
      category,
      dispatch,
      metadata
    } = payload;
    convertTimesToMoment(times);
    if (category) payload.category = convertValueToPcrClassificator(classificator, "categories", category);
    if (teamActivation) teamActivation.activationProtocol = findActivationProtocol(classificator, dispatch, teamActivation.activationProtocol);
    if (metadata) metadata.owner = metadata.owner && {
      externalId: metadata.owner
    };
    payload.id = id;
    return payload;
  }

  var _default = RESTSerializer.extend({
    pcrClassificator: Ember.inject.service(),

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload = convertPayload(this.pcrClassificator, payload, id);
      return this._super(store, primaryModelClass, {
        "pcr-header": payload
      }, id, requestType);
    }

  });

  _exports.default = _default;
});