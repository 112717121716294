define("epcr-uis/pods/employee/model", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    code: attr("string"),
    externalId: attr("string"),
    firstName: attr("string"),
    lastName: attr("string"),
    positions: (0, _attributes.fragmentArray)("pcr-classificator")
  });

  _exports.default = _default;
});