define("epcr-uis/pods/pcr/flowchart/inventory/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7fbKacYc",
    "block": "{\"symbols\":[\"pcr\",\"NewInventoryInput\"],\"statements\":[[4,\"with\",[[24,[\"model\",\"pcr\"]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"pcr-document/flowchart/inventory-input/new\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,2,[]],[],[[\"@inventory\",\"@isRightPopupOpen\",\"@transitionTo\"],[[23,1,[\"patientTreatment\",\"inventory\"]],[24,[\"model\",\"isRightPopupOpen\"]],[28,\"route-action\",[\"transitionTo\"],null]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/pcr/flowchart/inventory/new/template.hbs"
    }
  });

  _exports.default = _default;
});