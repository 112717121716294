define("epcr-uis/pods/pcr/stroke-criteria/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "epcr-uis/helpers/is-object-filled"], function (_exports, _emberData, _fragment, _attributes, _isObjectFilled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    faceParalysis: attr("boolean"),
    speechDisorder: attr("boolean"),
    armsWeakened: attr("boolean"),
    durationLte3h: attr("boolean"),
    startTime: attr("date"),
    erNotified: attr("boolean"),
    contraindication: (0, _attributes.fragment)("pcr-classificator"),
    isFilled: Ember.computed("faceParalysis", "contraindication.code", "speechDisorder", "armsWeakened", "durationLte3h", "startTime", "erNotified", function () {
      const keys = ["faceParalysis", "contraindication", "speechDisorder", "armsWeakened", "durationLte3h", "startTime", "erNotified"];
      return (0, _isObjectFilled.isObjectFilled)([keys, this]);
    })
  });

  _exports.default = _default;
});