define("epcr-uis/mixins/team-member-copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    pcr: Ember.inject.service(),
    sortedTeamMembers: Ember.computed.filter("teamMembers.@each.externalId", teamMember => Ember.get(teamMember, "externalId")),
    teamMembersWithTeamLeaderFavourite: Ember.computed("sortedTeamMembers", function () {
      const copyOfTeamMembers = Ember.A([]);
      this.sortedTeamMembers.forEach(({
        externalId
      }) => copyOfTeamMembers.push(this.pcr.createFragment("pcr/team-activation/team/member", {
        externalId
      })));
      if (copyOfTeamMembers.firstObject) Ember.set(copyOfTeamMembers, "firstObject.favourite", 1);
      return copyOfTeamMembers;
    })
  });

  _exports.default = _default;
});