define("epcr-uis/pods/components/pcr-document/patient/used-medicine/component", ["exports", "epcr-uis/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const usedMedsPath = "patient.medicineConsumed";
  const medsUnknownPath = "patient.medicineUnknown";

  var _default = Ember.Component.extend({
    tagName: "",
    lastFilledUsedMeds: null,
    isUsedMedsUnknown: Ember.computed(usedMedsPath, {
      set: (key, value) => value,

      get() {
        return `${Ember.get(this, usedMedsPath)}`.toUpperCase() === _constants.UNKNOWN_INPUT;
      }

    }),
    actions: {
      onUsedMedicineFilled({
        usedMedicine
      }) {
        Ember.set(this, usedMedsPath, usedMedicine);
        this.lastFilledUsedMeds = usedMedicine;
      },

      onUsedMedicineUnknown() {
        const usedMeds = Ember.get(this, usedMedsPath);

        if (`${usedMeds}`.toUpperCase() === _constants.UNKNOWN_INPUT && this.lastFilledUsedMeds !== _constants.UNKNOWN_INPUT) {
          Ember.set(this, usedMedsPath, this.lastFilledUsedMeds);
          Ember.set(this, medsUnknownPath, false);
        } else {
          Ember.set(this, "lastFilledUsedMeds", usedMeds);
          Ember.set(this, usedMedsPath, _constants.UNKNOWN_INPUT);
          Ember.set(this, medsUnknownPath, true);
        }
      }

    }
  });

  _exports.default = _default;
});