define("epcr-uis/helpers/medicament-per-event", ["exports", "ember-components/helpers/medicament-per-event"], function (_exports, _medicamentPerEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _medicamentPerEvent.default;
    }
  });
  Object.defineProperty(_exports, "medicamentPerEvent", {
    enumerable: true,
    get: function () {
      return _medicamentPerEvent.medicamentPerEvent;
    }
  });
});