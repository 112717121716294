define("epcr-uis/pods/pcr/flowchart/recommendations/route", ["exports", "epcr-uis/mixins/right-popup-manager", "epcr-uis/mixins/route-tracker"], function (_exports, _rightPopupManager, _routeTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_rightPopupManager.default, _routeTracker.default, {
    model() {
      return {
        isRightPopupOpen: this.isRightPopupOpen,
        pcr: this.modelFor("pcr").pcrDocument
      };
    },

    renderTemplate() {
      this.render({
        into: "pcr"
      });
    },

    actions: {
      transitionToFlowchart() {
        this.transitionTo("pcr.flowchart");
      }

    }
  });

  _exports.default = _default;
});