define("epcr-uis/pods/components/pcr-routes/tools/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nqnW6rbd",
    "block": "{\"symbols\":[],\"statements\":[[5,\"route-content-loader\",[],[[\"@promise\"],[[22,\"toolsLoadPromise\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"section-header\",[],[[\"@title\",\"@icon\"],[[28,\"t\",[\"headings.tools.title\"],null],\"wrench icon big\"]]],[0,\"\\n  \"],[5,\"ui-container\",[[12,\"class\",\"tools\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui stackable three column grid block\"],[8],[0,\"\\n      \"],[5,\"side-bar/block-input\",[[12,\"class\",\"column\"],[12,\"onclick\",[28,\"route-action\",[\"transitionToRoute\",\"pcr.tools.pediatric-calculator\"],null]]],[[\"@title\"],[[28,\"t\",[\"headings.tools.pediCalc.title\"],null]]]],[0,\"\\n\\n      \"],[5,\"side-bar/block-input\",[[12,\"class\",\"column\"],[12,\"onclick\",[28,\"route-action\",[\"transitionToRoute\",\"pcr.tools.apgar-calculator\"],null]]],[[\"@title\"],[[28,\"t\",[\"headings.tools.apgarCalc.title\"],null]]]],[0,\"\\n\\n      \"],[5,\"side-bar/block-input\",[[12,\"class\",\"column\"],[12,\"onclick\",[28,\"route-action\",[\"transitionToRoute\",\"pcr.tools.dose-calculator\"],null]]],[[\"@title\"],[[28,\"t\",[\"headings.tools.doseCalc.title\"],null]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-routes/tools/template.hbs"
    }
  });

  _exports.default = _default;
});