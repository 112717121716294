define("epcr-uis/pods/components/pcr-document/transportation/additional-info/component", ["exports", "epcr-uis/helpers/go-to-next-input"], function (_exports, _goToNextInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    pcrClassificator: Ember.inject.service(),
    features: Ember.inject.service(),
    actions: {
      goToNextInput() {
        (0, _goToNextInput.goToNextInput)(this.features);
      }

    }
  });

  _exports.default = _default;
});