define("epcr-uis/pods/pcr/clinical-death/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "epcr-uis/helpers/is-object-filled"], function (_exports, _emberData, _fragment, _attributes, _isObjectFilled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    time: attr("date"),
    witnesses: (0, _attributes.fragmentArray)("pcr-classificator"),
    restoredBloodCirculationBy: (0, _attributes.fragmentArray)("pcr-classificator"),
    cprBeforeArrivalBy: (0, _attributes.fragment)("pcr/clinical-death/cpr-before-arrival-by"),
    isFilled: Ember.computed("time", "witnesses.@each.code", "restoredBloodCirculationBy.@each.code", function () {
      const keys = ["time", "witnesses", "restoredBloodCirculationBy"];
      return (0, _isObjectFilled.isObjectFilled)([keys, this]);
    })
  });

  _exports.default = _default;
});