define("epcr-uis/pods/components/pcr-document/signatures/team-leader-signature/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y1yqnb5n",
    "block": "{\"symbols\":[\"ConfirmationText\",\"SingleInput\",\"@teamLeaderSignature\",\"@width\",\"@title\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"twelve wide column upper-case\"],[8],[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"side-bar/single-input\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,2,[]],[[12,\"id\",\"team-leader-signature-name\"]],[[\"@title\",\"@data\",\"@validation\",\"@readOnly\"],[[28,\"t\",[\"pcr.signatures.name\"],null],[23,3,[\"signerName\"]],[24,[\"pcrValidation\",\"errors\",\"team-leader-signature-name\"]],[23,3,[\"signatureImage\"]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"twelve wide column signature-container\"],[8],[0,\"\\n  \"],[5,\"signature-pad\",[[12,\"id\",\"team-leader-signature-signature\"]],[[\"@data\",\"@width\",\"@validation\",\"@canvasId\",\"@readOnly\",\"@popupDestination\",\"@title\"],[[23,3,[\"signatureImage\"]],[23,4,[]],[24,[\"pcrValidation\",\"errors\",\"team-leader-signature-signature\"]],\"team-leader-canvas\",[28,\"not\",[[23,3,[\"signerName\"]]],null],\"signature-popup-wormhole\",[23,5,[]]]],{\"statements\":[[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[\"pcr-document/signatures/confirmation-text\"],null]],null,{\"statements\":[[0,\"      \"],[6,[23,1,[]],[[12,\"class\",\"upper-case\"]],[[\"@text\",\"@confirmation\"],[[28,\"t\",[\"headings.signatures.teamLeader\"],null],[23,3,[\"signerName\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/signatures/team-leader-signature/template.hbs"
    }
  });

  _exports.default = _default;
});