define("epcr-uis/pods/brightness/service", ["exports", "epcr-uis/helpers/window-wrapper"], function (_exports, _windowWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    android: Ember.inject.service(),
    isEnabled: window.control,
    showScreenBrightnessController: () => (0, _windowWrapper.callMethod)("window.control.onScreenBrightnessAdjustmentClick")
  });

  _exports.default = _default;
});