define("epcr-uis/pods/pcr-classificator/medicaments/salcininkai", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SALCININKAI_MEDICAMENTS = void 0;

  const SALCININKAI_MEDICAMENTS = t => ({
    stations: ["43"],
    medicaments: [(0, _medicamentsList.MEDICAMENTS)(t)[203], (0, _medicamentsList.MEDICAMENTS)(t)[206], (0, _medicamentsList.MEDICAMENTS)(t)[208], (0, _medicamentsList.MEDICAMENTS)(t)[220], (0, _medicamentsList.MEDICAMENTS)(t)[224], (0, _medicamentsList.MEDICAMENTS)(t)[226], (0, _medicamentsList.MEDICAMENTS)(t)[230], (0, _medicamentsList.MEDICAMENTS)(t)[231], (0, _medicamentsList.MEDICAMENTS)(t)[232], (0, _medicamentsList.MEDICAMENTS)(t)[233], (0, _medicamentsList.MEDICAMENTS)(t)[237], (0, _medicamentsList.MEDICAMENTS)(t)[242], (0, _medicamentsList.MEDICAMENTS)(t)[245], (0, _medicamentsList.MEDICAMENTS)(t)[250], (0, _medicamentsList.MEDICAMENTS)(t)[251], (0, _medicamentsList.MEDICAMENTS)(t)[255], (0, _medicamentsList.MEDICAMENTS)(t)[275], (0, _medicamentsList.MEDICAMENTS)(t)[276], (0, _medicamentsList.MEDICAMENTS)(t)[277], (0, _medicamentsList.MEDICAMENTS)(t)[278], (0, _medicamentsList.MEDICAMENTS)(t)[279], (0, _medicamentsList.MEDICAMENTS)(t)[280], (0, _medicamentsList.MEDICAMENTS)(t)[288], (0, _medicamentsList.MEDICAMENTS)(t)[289]]
  });

  _exports.SALCININKAI_MEDICAMENTS = SALCININKAI_MEDICAMENTS;
});