define("epcr-uis/pods/patient/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    firstName: attr("string"),
    lastName: attr("string"),
    personalId: attr("string"),
    birthDate: attr("date-ymd"),
    sex: attr("string"),
    age: attr("object"),
    weight: attr("number"),
    address: attr("object"),
    sideDiseases: attr("object"),
    familyDoctor: attr("object"),
    phcFacility: attr("object"),
    specialCircumstances: attr("string"),
    medicineConsumed: attr("string"),
    lastUpdateDate: attr("date"),
    icdCode: attr("object"),
    transported: attr("boolean")
  });

  _exports.default = _default;
});