define("epcr-uis/helpers/generate-print-url", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generatePrintUrl = generatePrintUrl;

  function generatePrintUrl(pcrId, format) {
    return pcrId && format ? _environment.default.APP.pcrImageUrl.replace(":pcrId", pcrId).replace(":format", format) : null;
  }
});