define("epcr-uis/components/deferred-content/fulfilled-content", ["exports", "ember-deferred-content/components/deferred-content/fulfilled-content"], function (_exports, _fulfilledContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fulfilledContent.default;
    }
  });
});