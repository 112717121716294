define("epcr-uis/pods/pcr/vitals/edit/route", ["exports", "ember-concurrency", "epcr-uis/helpers/deferred-content-promise", "epcr-uis/mixins/route-tracker", "epcr-uis/mixins/right-popup-manager", "epcr-uis/helpers/get-assessment-by-type"], function (_exports, _emberConcurrency, _deferredContentPromise, _routeTracker, _rightPopupManager, _getAssessmentByType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeTracker.default, _rightPopupManager.default, {
    pcrClassificator: Ember.inject.service(),
    pcrValidation: Ember.inject.service("validation"),
    pcrService: Ember.inject.service("pcr"),
    onRightPopupClose: (0, _emberConcurrency.task)(function* () {
      this.set("isRightPopupOpen", false);
      yield this.transitionTo("pcr.vitals");
    }),

    getCurrentAssessment(assessments, vitalsId) {
      const {
        assessmentTypes
      } = this.pcrClassificator.pcrDocumentVitalParams;
      const assessmentType = assessmentTypes.find(a => a.order - 1 === parseInt(vitalsId));
      return (0, _getAssessmentByType.getAssessmentByType)(assessments, assessmentType.code);
    },

    model({
      vitalsId
    }) {
      const pcr = this.modelFor("pcr").pcrDocument;
      const currentAssessment = this.getCurrentAssessment(Ember.get(pcr, "patientAssessments"), vitalsId);
      this.pcrService.addObserverForPcrAssessment(currentAssessment);
      return {
        pcr,
        currentAssessment,
        isRightPopupOpen: this.isRightPopupOpen,
        onRightPopupClose: this.onRightPopupClose,
        deferredContentPromise: (0, _deferredContentPromise.deferredContentPromise)(),
        pcrValidation: this.pcrValidation
      };
    },

    renderTemplate() {
      this.render("pcr.vitals.edit", {
        into: "pcr"
      });
    },

    deactivate() {
      const assessment = this.currentModel.currentAssessment;
      this.pcrService.removeObserverForPcrAssessment(assessment);
    }

  });

  _exports.default = _default;
});