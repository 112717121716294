define("epcr-uis/pods/components/pcr-document/flowchart/recommendation-input/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bepII+9+",
    "block": "{\"symbols\":[\"mediums\",\"procedure\",\"@groupElement\",\"@classificatorPath\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[\"type\"]],\"P\"],null]],null,{\"statements\":[[4,\"let\",[[28,\"get-classificator-element-by-code\",[[23,4,[]],[23,3,[\"code\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"treatment grey icon\"],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"name\"],[8],[1,[23,2,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,3,[\"type\"]],\"M\"],null]],null,{\"statements\":[[4,\"with\",[[28,\"get-medicament-mediums-by-code\",[[23,3,[\"code\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"pills grey icon\"],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"name\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"type\"],[8],[0,\"\\n      \"],[1,[28,\"get\",[[28,\"find-by\",[\"code\",[23,3,[\"code\"]],[23,1,[\"types\"]]],null],\"name\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/flowchart/recommendation-input/input/template.hbs"
    }
  });

  _exports.default = _default;
});