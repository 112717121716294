define("epcr-uis/pods/components/progress-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["ui", "progress"],
    classNameBindings: ["taskInstance.isRunning:green", "taskInstance.isRunning:active", "taskInstance.isError:error"],
    "data-test-id": "progress-bar",
    stateMap: {
      running: "progress.running",
      retry: "progress.retry",
      idle: "progress.idle",
      error: "progress.error"
    }
  });

  _exports.default = _default;
});