define("epcr-uis/pods/locked-pcr/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    locks: {}
  });

  _exports.default = _default;
});