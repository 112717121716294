define("epcr-uis/helpers/is-object-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isObjectFilled = void 0;

  const isFieldFilled = (key, model) => {
    const arrayElementsCount = model.get(`${key}.length`);
    const isArray = arrayElementsCount !== undefined;
    return isArray ? arrayElementsCount > 0 : Boolean(model.get(key));
  };

  const isKeysFilled = (keys, model) => {
    for (let i = 0; i < keys.length; i++) {
      if (isFieldFilled(keys[i], model)) return true;
    }

    return false;
  };

  const isObjectFilled = ([keys, model]) => isKeysFilled(keys, model);

  _exports.isObjectFilled = isObjectFilled;
});