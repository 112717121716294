define("epcr-uis/pods/pcr/route", ["exports", "ember-concurrency", "epcr-uis/mixins/leave-confimation", "epcr-uis/mixins/metrics-tracker"], function (_exports, _emberConcurrency, _leaveConfimation, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_metricsTracker.default, _leaveConfimation.default, {
    componentsStatus: Ember.inject.service("components-status"),
    pcr: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    "pcr-error-mapper": Ember.inject.service(),
    validation: Ember.inject.service(),
    device: Ember.inject.service(),
    scrollToField: Ember.inject.service(),
    pcrDataChange: Ember.inject.service(),
    simpleAuthFetch: Ember.inject.service(),
    androidSimpleAuthFetch: Ember.inject.service("simple-auth-fetch/android"),
    android: Ember.inject.service(),
    pcrHeaderService: Ember.inject.service("pcr-header"),
    pcrReloaderPcrHeader: Ember.inject.service("pcr-reloader/pcr-header"),

    model({
      pcrId
    }) {
      this.addOrRemoveNoScrollClass();
      return new Ember.RSVP.Promise((resolve, reject) => {
        const pcrDocumentTaskInstance = this.pcrDocumentRetrieveTask.perform(pcrId);
        pcrDocumentTaskInstance.then(pcrDocument => {
          pcrDocument && this.pcr.addObserverForPcr(pcrDocument);
          return resolve({
            pcrDocument
          });
        }).catch(e => reject(e));
      });
    },

    afterModel({
      pcrDocument
    }) {
      if (pcrDocument) {
        this["pcr-error-mapper"].createErrorMapper(pcrDocument);
        const simpleAuthFetch = this.android.isAndroid() ? this.androidSimpleAuthFetch : this.simpleAuthFetch;
        const isDesktop = this.device.isDesktop();
        const validateDelay = isDesktop ? 1000 : 3000;
        const autoSaveDelay = isDesktop ? 10000 : 30000;
        this.pcrDataChange.startCheckingPcrDataChanges(pcrDocument, simpleAuthFetch, validateDelay, autoSaveDelay);
        this.pcrReloaderPcrHeader.watch(this.pcrHeaderService.runPcrHeaderSynchronizer.bind(this.pcrHeaderService), pcrDocument);
      }
    },

    isTransitioningToExternalRoute: ({
      targetName
    }) => !(targetName.indexOf("pcr") >= 0 || targetName === "not-authenticated"),

    getPcrIdFromTransition(transition) {
      const transitionPcrRoute = transition.find(t => t.name === "pcr");
      return transitionPcrRoute ? transitionPcrRoute.params.pcrId : null;
    },

    isTransitioningToOtherPcr({
      to,
      from
    }) {
      return to.name === "pcr.event" && this.getPcrIdFromTransition(from) !== this.getPcrIdFromTransition(to);
    },

    addRemoveClass(selector, addClass, removeClass) {
      selector.add(addClass);
      selector.remove(removeClass);
    },

    enableScrollOnLastSidebar() {
      const {
        body
      } = document;
      const miniSidebars = body.querySelectorAll(".sidebar.mini");
      miniSidebars.forEach((sidebar, index) => {
        this.addRemoveClass(sidebar.classList, "no-scroll", "scroll");
        if (miniSidebars.length - 1 === index) this.addRemoveClass(sidebar.classList, "scroll", "no-scroll");
      });
    },

    addOrRemoveNoScrollClass() {
      Ember.run.next(() => {
        const noScrollClass = "no-scroll";
        const modalCount = Ember.get(this, "componentsStatus.modalCount");
        const pcrBody = document.getElementById("pcr");

        if (modalCount) {
          pcrBody && !pcrBody.classList.contains(noScrollClass) && pcrBody.classList.add(noScrollClass);
          this.enableScrollOnLastSidebar(pcrBody);
        } else if (modalCount < 1) {
          pcrBody && pcrBody.classList.remove(noScrollClass);
        }
      });
    },

    modalStatus: Ember.observer("componentsStatus.modalCount", function () {
      this.addOrRemoveNoScrollClass();
    }),
    pcrDocumentRetrieveTask: (0, _emberConcurrency.task)(function* (pcrId) {
      return yield this.pcr.getPCRDocument(pcrId).catch(e => {
        const errorCode = Ember.get(e, "errors.firstObject.code");
        this.transitionTo("pcr.error", {
          queryParams: {
            errorCode: errorCode || e
          }
        });
      });
    }),
    getPcr: context => Ember.get(context, "currentModel.pcrDocument"),

    deactivate() {
      this._super(...arguments);

      this.pcrDataChange.stopCheckingPcrDataChanges();
      this.pcrReloaderPcrHeader.stopWatch();
      this.validation.removeErrors();
      const pcrDocument = this.getPcr(this);

      if (pcrDocument) {
        this.pcr.removeObserverForPcr(pcrDocument);
        this.pcr.deletePcrTemporary.perform(pcrDocument);
      }
    },

    actions: {
      transitionToVitalEdit(index) {
        return this.transitionTo("pcr.vitals.edit", index);
      },

      transitionToVitalNew(index, type) {
        return this.transitionTo("pcr.vitals.new", {
          queryParams: {
            type
          }
        });
      },

      transitionToRoute(route) {
        return this.transitionTo(route);
      },

      saveAndContinueTransition() {
        const self = this;
        const pcr = this.getPcr(this);
        this.trackEvent("Save", "Save and leave pcr", pcr.eventId);
        this.pcr.savePcrTask.perform(pcr).then(() => this.continueTransition()).catch(() => {
          if (self.router.currentRouteName.includes("pcr.")) return self.router.transitionTo("pcr.summary.save");
        });
      },

      willTransition(transition) {
        transition.promise.then(() => this.scrollToField.scrollTop());

        this._super(...arguments);
      }

    }
  });

  _exports.default = _default;
});