define("epcr-uis/pods/components/pcr-document/tools/dose-calculator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iilXy7mX",
    "block": "{\"symbols\":[\"Notification\",\"Medicaments\",\"Options\",\"@pcr\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/notification\"],null],[28,\"component\",[\"pcr-document/tools/dose-calculator/medicaments\"],null],[28,\"component\",[\"pcr-document/tools/options\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"section-header\",[],[[\"@title\",\"@icon\"],[[28,\"t\",[\"headings.tools.doseCalc.title\"],null],\"calculator icon big\"]]],[0,\"\\n  \"],[5,\"ui-container\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,3,[]],[],[[\"@selected\",\"@rows\"],[[22,\"weight\"],[22,\"rows\"]]]],[0,\"\\n\"],[4,\"if\",[[24,[\"weight\"]]],null,{\"statements\":[[0,\"      \"],[6,[23,2,[]],[],[[\"@weight\",\"@pcr\"],[[22,\"weight\"],[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,[23,1,[]],[],[[\"@title\"],[[28,\"t\",[\"headings.tools.doseCalc.selectWeight\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2,3]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/tools/dose-calculator/template.hbs"
    }
  });

  _exports.default = _default;
});