define("epcr-uis/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PCR_CLASSIFICATOR = _exports.UNKNOWN_INPUT = _exports.SURVEYED = _exports.READ_NEWS = _exports.SELECTED_SHIFT_DURATION = _exports.SELECTED_TEAM_CODE = _exports.CLUSTER_TEAM = _exports.TEAM_TYPE = _exports.TEAM_LEADER_TYPE = _exports.TEAM_ACTIVATION = _exports.FEAT_SHOW_CLUSTER_TEAM = _exports.FEAT_FLASHLIGHT = _exports.FEAT_RELATED_PROCEDURES = _exports.FEAT_NIGHT_MODE = _exports.FEAT_TIMELINES = _exports.FEAT_RAPID_INPUT_MODE = _exports.FEAT_SOUND_ON_CLICK = void 0;
  const FEAT_SOUND_ON_CLICK = "soundOnClick";
  _exports.FEAT_SOUND_ON_CLICK = FEAT_SOUND_ON_CLICK;
  const FEAT_RAPID_INPUT_MODE = "rapidInputMode";
  _exports.FEAT_RAPID_INPUT_MODE = FEAT_RAPID_INPUT_MODE;
  const FEAT_TIMELINES = "timelines";
  _exports.FEAT_TIMELINES = FEAT_TIMELINES;
  const FEAT_NIGHT_MODE = "nightMode";
  _exports.FEAT_NIGHT_MODE = FEAT_NIGHT_MODE;
  const FEAT_RELATED_PROCEDURES = "relatedProcedures";
  _exports.FEAT_RELATED_PROCEDURES = FEAT_RELATED_PROCEDURES;
  const FEAT_FLASHLIGHT = "flashlight";
  _exports.FEAT_FLASHLIGHT = FEAT_FLASHLIGHT;
  const FEAT_SHOW_CLUSTER_TEAM = "showClusterTeam";
  _exports.FEAT_SHOW_CLUSTER_TEAM = FEAT_SHOW_CLUSTER_TEAM;
  const TEAM_ACTIVATION = "teamActivation";
  _exports.TEAM_ACTIVATION = TEAM_ACTIVATION;
  const TEAM_LEADER_TYPE = "leaderType";
  _exports.TEAM_LEADER_TYPE = TEAM_LEADER_TYPE;
  const TEAM_TYPE = "teamType";
  _exports.TEAM_TYPE = TEAM_TYPE;
  const CLUSTER_TEAM = "clusterTeam";
  _exports.CLUSTER_TEAM = CLUSTER_TEAM;
  const SELECTED_TEAM_CODE = "selectedTeamCode";
  _exports.SELECTED_TEAM_CODE = SELECTED_TEAM_CODE;
  const SELECTED_SHIFT_DURATION = "selectedShiftDuration";
  _exports.SELECTED_SHIFT_DURATION = SELECTED_SHIFT_DURATION;
  const READ_NEWS = "readNews";
  _exports.READ_NEWS = READ_NEWS;
  const SURVEYED = "surveyed";
  _exports.SURVEYED = SURVEYED;
  const UNKNOWN_INPUT = "NEŽINOMA";
  _exports.UNKNOWN_INPUT = UNKNOWN_INPUT;
  const PCR_CLASSIFICATOR = "pcr-classificator";
  _exports.PCR_CLASSIFICATOR = PCR_CLASSIFICATOR;
});