define("epcr-uis/mixins/new-flowchart", ["exports", "epcr-uis/mixins/right-popup-manager", "epcr-uis/mixins/route-tracker"], function (_exports, _rightPopupManager, _routeTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_rightPopupManager.default, _routeTracker.default, {
    renderTemplate() {
      this.render({
        into: "pcr"
      });
    },

    model() {
      return {
        isRightPopupOpen: this.isRightPopupOpen,
        pcr: this.modelFor("pcr").pcrDocument
      };
    },

    actions: {
      transitionTo(destination) {
        this.transitionTo(destination);
      }

    }
  });

  _exports.default = _default;
});