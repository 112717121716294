define("epcr-uis/pods/components/pcr-document/team-member/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    comparatorKey: "externalId",
    fadeBackground: true,
    scrollToField: Ember.inject.service(),
    key: "firstName",
    actions: {
      onSelectedMember(member) {
        return this.onSelectedMember ? this.onSelectedMember(member) : null;
      },

      onDeletedMember(member) {
        return this.onDeletedMember ? this.onDeletedMember(member) : null;
      },

      transitionToTeamMembers() {
        return this.scrollToField.scrollToField("", "team-leader", ["pcr.event"]);
      }

    }
  });

  _exports.default = _default;
});