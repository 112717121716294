define("epcr-uis/pods/components/pcr-error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zaP98SvM",
    "block": "{\"symbols\":[\"ErrorMessage\",\"ui\",\"@buttonName\",\"@message\",\"@onNavigationButtonClicked\",\"@icon\"],\"statements\":[[4,\"if\",[[24,[\"showTopNavigation\"]]],null,{\"statements\":[[0,\"  \"],[5,\"navigation-menu\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"item\"]],[[12,\"class\",\"item\"]],[[\"@click\"],[[28,\"or\",[[23,5,[]],[28,\"action\",[[23,0,[]],\"transitionToRoute\",\"home\"],null]],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"home white icon\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"i\",true],[11,\"class\",[23,6,[]]],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"message\"],[8],[1,[28,\"translate-error\",[[23,4,[]]],null],false],[9],[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"contacts/error-message\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@error\"],[[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"ui primary basic button\"],[11,\"onclick\",[22,\"onButtonClicked\"]],[8],[0,\"\\n    \"],[1,[23,3,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-error/template.hbs"
    }
  });

  _exports.default = _default;
});