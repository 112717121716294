define("epcr-uis/helpers/is-doctor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isDoctor = void 0;

  const isDoctor = ([teamLeaderType]) => teamLeaderType === "GYD";

  _exports.isDoctor = isDoctor;

  var _default = Ember.Helper.helper(isDoctor);

  _exports.default = _default;
});