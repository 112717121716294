define("epcr-uis/instance-initializers/register-printer", ["exports", "ember-window-mock", "epcr-uis/helpers/window-wrapper"], function (_exports, _emberWindowMock, _windowWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  const registerPrinterListener = "window.epcr.registerPrinterListener";

  const bindFunction = (printerFunction, printService) => printerFunction.bind(printService);

  function bindPrinterFunctions(printService, onPrinterStatusUpdate) {
    const boundOnPrinterStatusUpdate = bindFunction(onPrinterStatusUpdate, printService);
    return {
      boundOnPrinterStatusUpdate
    };
  }

  const turnOffTopNavigationIfTablet = features => {
    if (_emberWindowMock.default.epcr && features) features.disable("show-top-navigation");
  };

  function initialize(appInstance) {
    const featuresService = appInstance.lookup("service:features");

    if ((0, _windowWrapper.hasMethod)(registerPrinterListener)) {
      const printService = appInstance.lookup("service:print/android");
      const {
        onPrinterStatusUpdate
      } = printService;
      const {
        boundOnPrinterStatusUpdate
      } = bindPrinterFunctions(printService, onPrinterStatusUpdate);
      _emberWindowMock.default.epcr.printer = {
        onPrinterStatusUpdate: status => boundOnPrinterStatusUpdate(status)
      };
      (0, _windowWrapper.callMethod)(registerPrinterListener, "printer.onPrinterStatusUpdate");
    }

    _emberWindowMock.default.addEventListener("unload", function () {
      if ((0, _windowWrapper.hasMethod)(registerPrinterListener)) (0, _windowWrapper.callMethod)(registerPrinterListener, null);
    });

    turnOffTopNavigationIfTablet(featuresService);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});