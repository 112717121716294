define("epcr-uis/pods/load-config/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    userPreferences: Ember.inject.service(),
    backendConfig: Ember.inject.service(),
    previousTransition: null,

    loadConfigs() {
      const preferencesPromise = this.userPreferences.loadUserPreferences();
      const backendConfigPromise = this.backendConfig.loadConfig();
      return Ember.RSVP.Promise.all([preferencesPromise, backendConfigPromise]);
    }

  });

  _exports.default = _default;
});