define("epcr-uis/pods/components/pcr-document/specialty-patient-forms/death/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G9rBrzu5",
    "block": "{\"symbols\":[\"BlockInput\",\"DeathPopup\",\"Ui\",\"filtered\",\"@pcr\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/block-input\"],null],[28,\"component\",[\"pcr-document/specialty-patient-forms/death/popup\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[[12,\"id\",\"death-form\"],[12,\"class\",\"column\"]],[[\"@title\",\"@validations\"],[[28,\"t\",[\"headings.forms.deathStatement\"],null],[24,[\"pcrValidation\",\"groups\",\"forms\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"with\",[[28,\"hash\",null,[[\"validation\"],[[28,\"filter-validations-by-type\",[[24,[\"pcrValidation\",\"groups\",\"forms\"]],\"#death-form\"],null]]]]],null,{\"statements\":[[0,\"      \"],[6,[23,3,[\"corner-label\"]],[],[[\"@type\",\"@count\",\"@value\"],[[23,4,[\"validation\",\"cssClass\"]],[23,4,[\"validation\",\"count\"]],[23,5,[\"deathStatement\",\"isFilled\"]]]]],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"    \"],[6,[23,3,[\"popup\"]],[[12,\"class\",\"popup\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[]],[],[[\"@pcr\"],[[23,5,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[3]}],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/specialty-patient-forms/death/template.hbs"
    }
  });

  _exports.default = _default;
});