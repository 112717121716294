define("epcr-uis/pods/pcr/flowchart/procedure/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J5MtcgPr",
    "block": "{\"symbols\":[\"pcr\",\"NewProcedureInput\"],\"statements\":[[4,\"with\",[[24,[\"model\",\"pcr\"]]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[\"pcr-document/flowchart/procedure-input/new\"],null]],null,{\"statements\":[[0,\"    \"],[6,[23,2,[]],[],[[\"@transitionTo\",\"@pcr\",\"@isRightPopupOpen\",\"@teamMembers\"],[[28,\"route-action\",[\"transitionTo\"],null],[23,1,[]],[24,[\"model\",\"isRightPopupOpen\"]],[23,1,[\"teamActivation\",\"team\",\"members\"]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/pcr/flowchart/procedure/new/template.hbs"
    }
  });

  _exports.default = _default;
});