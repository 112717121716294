define("epcr-uis/mirage/factories/street", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    city: () => _emberCliMirage.faker.address.city(),
    street: () => _emberCliMirage.faker.address.streetName(),
    municipality: () => _emberCliMirage.faker.address.state()
  });

  _exports.default = _default;
});