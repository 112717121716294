define("epcr-uis/mirage/factories/pcr", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    eventId: 1799113863,
    missionId: 1,
    patientId: 1,
    station: "99",
    dispatch: "1",
    circumstances: () => (0, _isEnglish.isEnglish)() ? "Detailed event description should be here" : "Čia tūrėtų būti detalus įvykio aprašymas"
  });

  _exports.default = _default;
});