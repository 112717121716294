define("epcr-uis/pods/pcr/patient-diagnosis/icd10-fragment/model", ["exports", "epcr-uis/pods/lazy-fragment/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    icd: Ember.inject.service(),
    name: (0, _model.lazyAttr)("name"),

    dataSource() {
      return this.icd.getIcd(this.code);
    },

    onDataReadSuccess(icd10) {
      return {
        name: icd10.get("name")
      };
    },

    onDataReadError() {
      return {
        name: this.code
      };
    }

  });

  _exports.default = _default;
});