define("epcr-uis/mixins/right-popup-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isRightPopupOpen: false,

    beforeModel() {
      Ember.set(this, "isRightPopupOpen", true);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});