define("epcr-uis/pods/pcr-error-mapper/service", ["exports", "raven"], function (_exports, _raven) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BP_FIELD = "bp-systolic-diastolic";
  const RESPIRATORY_RATE_FIELD = "respiratory-rate";
  const SPO2_FIELD = "spo2";
  const HEART_RHYTHM_FIELD = "heart-rhythm";
  const TIME_FIELD = "time";
  const PULSE_FIELD = "pulse";
  const EYE_RESPONSE = "eye-response";
  const MOTOR_RESPONSE = "motor-response";
  const VERBAL_RESPONSE = "verbal-response";
  const TEMP_DEGREE_FIELD = "temp-degree";
  const GLUCOSE_FIELD = "glucose";
  const ST_SEGMENT = "st-segment";

  var _default = Ember.Service.extend({
    validation: Ember.inject.service(),
    intl: Ember.inject.service(),
    errorMapping: {},
    translations: Ember.computed(function () {
      return {
        REQUIRED_FIELD: "Būtinas laukelis!",
        AB_PULSE: "Iki MP neįvestas pulsas",
        AB_PULSE_INVALID: "Iki MP neteisingai įvestas pulsas",
        AB_RESPIRATORY_RATE: "Iki MP neįvestas kvėpavimo dažnis",
        AB_RESPIRATORY_RATE_INVALID: "Iki MP neteisingai įvestas kvėpavimo dažnis",
        AB_LEFT_PUPIL: "Iki MP nepasirinkta kairiojo vyzdžio būsena",
        AB_RIGHT_PUPIL: "Iki MP nepasirinkta dešiniojo vyzdžio būsena",
        AB_INVALID_BP: "Iki MP neteisingai įvestas KS",
        AB_BP_REQUIRED: "Iki MP neįvestas KS",
        AB_TEMPERATURE_INVALID: "Iki MP neteisingai įvesta temperatūra",
        AB_GLUCOSE_INVALID: "Iki MP neteisingai įvestas gliukozės kiekis",
        AB_CAPILLARY_FILLING_UP_TIME: "Iki MP nepasirinktas kapiliarų prisipildymo laikas",
        AB_PAIN_REQUIRED: "Iki MP nepasirinktas skausmo lygis",
        AB_RADIAL_ARTERY_PULSE_REQUIRED: "Iki MP nepasirinktas stipininės a. pulsas",
        AB_SKIN_TEMPERATURE_REQUIRED: "Iki MP neįvesta odos temperatūra",
        AB_SKIN_COLOR_REQUIRED: "Iki MP nepasirinkta odos spalva",
        AB_SKIN_CONDITION_REQUIRED: "Iki MP nepasirinkta odos būklė",
        AB_CONSCIOUSNESS: "Iki MP neįvestas AVPU arba GKS",
        AB_ST_SEGMENT_RAISE_AA_DOWN: "Po MP pakeistas ST segmento vertinimas",
        AB_ST_SEGMENT_DOWN_AA_RAISE: "Po MP pakeistas ST segmento vertinimas",
        MINS_FROM_SUDDEN_DEATH_REQUIRED: "Nepasirinktos minutės nuo staigios mirties",
        MINS_FROM_CPR_START_REQUIRED: "Nepasirinktos minutės nuo PG pradžios",
        MINS_FROM_CPR_END_REQUIRED: "Nepasirinktos minutės nuo PG pabaigos",
        CPR_STARTED_BY_REQUIRED: "Nepasirinkta, kas pradėjo PG",
        AED_USED_BY_REQUIRED: "Nepasirinkta, kas panaudojo AID",
        MINS_SINCE_FIRST_AED_REQUIRED: "Nepasirinktos minutės nuo pirmo AID pradžios",
        EXACT_STROKE_TIME_REQUIRED: "Neįvestas tikslus insulto laikas",
        TRAUMA_ER_INFORMED_REQUIRED: "Nepasirinkta, kad apie traumą pranešta SPS",
        HEART_ATTACK_ER_NOTIFIED_REQUIRED: "Nepasirinkta, kad apie ŪKS pranešta SPS",
        EXACT_HEART_ATTACK_TIME_REQUIRED: "Neįvestas tikslus ŪKS laikas",
        HEART_ATTACK_PAIN_STARTED_LTE12H_REQUIRED: "Nepasirinktas ŪKS skausmo pradžios laikas",
        HEART_ATTACK_RAISED_ST_SEG_REQUIRED: "Nepasirinktas ST pakilimas",
        BIRTH_ER_INFORMED_REQUIRED: "Nepasirinkta, kad apie gimdymą pranešta SPS",
        APGAR_1MIN_REQUIRED: "Nepasirinktas APGAR pradinis",
        APGAR_5MIN_REQUIRED: "Nepasirinktas APGAR po 5min",
        AB_GLUCOSE_REQUIRED: "Iki MP neįvestas gliukozės kiekis",
        AB_LEFT_LUNG_BREATHING_QUALITY_REQUIRED: "Iki MP nepasirinkta auskultacija kairėje",
        AB_RIGHT_LUNG_BREATHING_QUALITY_REQUIRED: "Iki MP nepasirinkta auskultacija dešinėje",
        AB_EYE_RESPONSE_REQUIRED: "Iki MP nepasirinkta akių atmerkimo reakcija",
        AB_VERBAL_RESPONSE_REQUIRED: "Iki MP nepasirinkta žodinio kontakto reakcija",
        AB_MOTOR_RESPONSE_REQUIRED: "Iki MP nepasirinkta kūno judesių reakcija",
        AB_HEART_RHYTHM_REQUIRED: "Iki MP nepasirinktas širdies ritmas",
        AB_TIME_REQUIRED: "Iki MP neįvestas laikas",
        AB_TIME_INVALID: "Iki MP neteisingai įvestas laikas",
        AB_SPO2_INVALID: "Iki MP neteisingai įvestas SpO₂",
        AB_REQUIRED: "Neužpildytas iki MP",
        PATIENT_ADDRESS_REQUIRED: "Neužpildytas paciento adresas",
        AB_DESELECT_AVPU: "Iki MP gydytojui arba slaugytojui nereikia pildyti AVPU",
        AB_DESELECT_GCS: "Iki MP paramedikui nereikia pildyti GKS",
        AB_ST_SEGMENT_REQUIRED: "Iki MP nepasirinktas ST segmentas",
        AB_TIME_AFTER_AA_TIME: "Po MP įvertintas anskčiau nei iki MP",
        AB_TIME_IS_AFTER_AT_ER: "SPS įvertintas anksčiau nei iki MP",
        AB_TIME_IS_EARLIER_THAN_ARRIVED: "Iki MP įvertintas anksčiau nei atvykta į vietą",
        AB_TIME_IS_LATER_THAN_AT_HOSPITAL: "Iki MP įvertintas vėliau nei atvykta į ligoninę",
        AB_TIME_IS_LATER_THAN_AVAILABLE_OR_RETURNED: "Iki MP įvertintas vėliau nei baigtas įvykis",
        AA_TIME_REQUIRED: "Po MP neįvestas laikas",
        AA_LEFT_LUNG_BREATHING_QUALITY_REQUIRED: "Po MP nepasirinkta auskultacija kairėje",
        AA_RIGHT_LUNG_BREATHING_QUALITY_REQUIRED: "Po MP nepasirinkta auskultacija dešinėje",
        AA_PULSE_REQUIRED_SKIN_COLOR: "Po MP nepasirinkta odos spalva",
        AA_PULSE_REQUIRED_SKIN_CONDITION: "Po MP nepasirinkta odos būklė",
        AA_DESELECT_AVPU: "Po MP gydytojui arba slaugytojui nereikia pildyti AVPU",
        AA_DESELECT_GCS: "Po MP paramedikui nereikia pildyti GKS",
        AA_INVALID_BP: "Po MP neteisingai įvestas KS",
        AA_BP_REQUIRED: "Po MP neįvestas KS",
        AA_RESPIRATORY_RATE_INVALID: "Po MP neteisingai įvestas kvėpavimo dažnis",
        AA_TIME_INVALID: "Po MP neteisingai įvestas laikas",
        AA_TIME_AFTER_AT_ER_TIME: "SPS įvertintas anksčiau nei po MP",
        AA_TIME_IS_EARLIER_THAN_ARRIVED: "Po MP įvertintas anksčiau nei atvykta į vietą",
        AA_TIME_IS_LATER_THAN_AT_HOSPITAL: "Po MP įvertintas vėliau nei atvykta į ligoninę",
        AA_TIME_IS_LATER_THAN_AVAILABLE_OR_RETURNED: "Po MP įvertintas vėliau nei baigtas įvykis",
        AA_PULSE_REQUIRED_SKIN_TEMPERATURE: "Po MP neįvesta odos temperatūra",
        AA_PULSE_REQUIRED_RADIAL_ARTERY_PULSE: "Po MP nepasirinktas stipininės a. pulsas",
        AA_EYE_RESPONSE_REQUIRED: "Po MP nepasirinkta akių reakcija",
        AA_VERBAL_RESPONSE_REQUIRED: "Po MP nepasirinkta žodinio kontakto reakcija",
        AA_MOTOR_RESPONSE_REQUIRED: "Po MP nepasirinkta kūno judesių reakcija",
        AA_REQUIRED_CAPILLARY_FILLING_UP_TIME: "Po MP nepasirinktas kapiliarų prisipildymo laikas",
        AA_REQUIRED_RESPIRATORY_RATE: "Po MP neįvestas kvėpavimo dažnis",
        AA_REQUIRED_LEFT_PUPIL: "Po MP nepasirinkta kairiojo vyzdžio būsena",
        AA_REQUIRED_RIGHT_PUPIL: "Po MP nepasirinkta dešiniojo vyzdžio būsena",
        AA_TEMPERATURE_INVALID: "Po MP neteisingai įvesta temperatūra",
        AA_GLUCOSE_INVALID: "Po MP neteisingai įvestas gliukozės kiekis",
        AA_REQUIRED_PULSE: "Po MP neįvestas pulsas",
        AA_SPO2_INVALID: "Po MP neteisingai įvestas SpO₂",
        AA_PULSE_INVALID: "Po MP neteisingai įvestas pulsas",
        AA_HEART_RHYTHM_REQUIRED: "Po MP nepasirinktas širdies ritmas",
        AA_REQUIRED: "Neužpildytas po MP",
        AT_ER_TIME_REQUIRED: "SPS neįvestas laikas",
        AT_ER_PULSE_REQUIRED: "SPS neįvestas pulsas",
        AT_ER_TIME_INVALID: "SPS neteisingai įvestas laikas",
        AT_ER_SPO2_INVALID: "SPS neteisingai įvestas SpO₂",
        AT_ER_RESPIRATORY_RATE_REQUIRED: "SPS neįvestas kvėpavimo dažnis",
        AT_ER_RESPIRATORY_RATE_INVALID: "SPS neteisingai įvestas kvėpavimo dažnis",
        AT_ER_REQUIRED: "Neužpildytas SPS",
        AT_ER_TIME_IS_LATER_THAN_AVAILABLE: "SPS įvertintas vėliau nei baigtas įvykis",
        AT_ER_INVALID_BP: "SPS neteisingai įvestas KS",
        AT_ER_BP_REQUIRED: "SPS neįvestas KS",
        AT_ER_PULSE_INVALID: "SPS neteisingai įvestas pulsas",
        PATIENT_FIRST_NAME_REQUIRED: this.intl.t("errors.PATIENT_FIRST_NAME_REQUIRED"),
        INVALID_PATIENT_FIRST_NAME: "Varde įvesti nekorektiški simboliai",
        PATIENT_LAST_NAME_REQUIRED: this.intl.t("errors.PATIENT_LAST_NAME_REQUIRED"),
        INVALID_PATIENT_LAST_NAME: "Pavardėje įvesti nekorektiški simboliai",
        INVALID_FAMILY_DOCTOR_NAME: "Šeimos gydytojo varde įvesti nekorektiški simboliai",
        PATIENT_WEIGHT_REQUIRED: this.intl.t("errors.PATIENT_WEIGHT_REQUIRED"),
        INVALID_PATIENT_WEIGHT: "Blogai įvestas paciento svoris",
        CLINICAL_DEATH_TIME_REQUIRED: "Neįvestas klinikinės mirties laikas",
        STROKE_ER_NOTIFIED_REQUIRED: "Nepasirinkta, kad apie insultą pranešta SPS",
        STROKE_START_TIME_INVALID: "Neteisingai įvestas insulto laikas",
        AGREEMENT_TO_RECEIVE_HELP_REQUIRED: "Nepasirinkta, ar sutinka gauti MP",
        AGREEMENT_TO_HOSPITAL_REQUIRED: "Nepasirinkta, ar sutinka vykti į ligoninę",
        PATIENT_SIGNATURE_REQUIRED: "Paciento formoje trūksta parašo",
        SIGNATURE_TYPE_REQUIRED: "Nepasirinktas pasirašantis asmuo",
        AGREEMENT_TO_SHARE_DATA_CONFIRMATION_REQUIRED: "Nepasirinktas patvirtinimas",
        INVALID_MEDICAL_HELP_SIGNATURE_SIGNER_NAME: "Atstovo varde, pavardėje įvesti nekorektiški simboliai",
        INVALID_INFO_SHARE_SIGNATURE_SHARE_WITH_PERSON: "Asmens varde, pavardėje įvesti nekorektiški simboliai",
        TEAM_LEADER_SIGNATURE_REQUIRED: "Trūksta parašo",
        TEAM_LEADER_SIGNATURE_NAME_REQUIRED: "Trūksta vardo, pavardės",
        INVALID_TEAM_LEADER_SIGNATURE_SIGNER_NAME: "Varde, pavardėje įvesti nekorektiški simboliai",
        DOCTOR_SIGNATURE_REQUIRED: "Trūksta priėmusio į ligoninę asmens parašo",
        DOCTOR_SIGNATURE_NAME_REQUIRED: "Neįvestas priėmusio į ligoninę asmens vardas, pavardė",
        INVALID_DOCTOR_SIGNATURE_SIGNER_NAME: "Asmens varde, pavardėje įvesti nekorektiški simboliai",
        PAIN_LOCATION_REQUIRED: "Nepasirinkta skausmo vieta",
        INJURY_LOCATION_REQUIRED: "Nepasirinkta sužalojimų/skausmo vieta",
        SAFETY_MEASURES_REQUIRED: "Nepasirinktos naudotos saugumo priemonės",
        ABCD_EVAL_REQUIRED: "Nepasirinktas ABCD vertinimas",
        INJURY_REQUIRED: "Nepasirinkti sužalojimai",
        BACKGROUND_REQUIRED: "Nepasirinktos traumos aplinkybės",
        SEAT_BELT_REQUIRED: "Nepasirinkti saugos diržai",
        SITTING_PLACE_REQUIRED: "Nepasirinkta paciento sėdėjimo vieta",
        AIRBAGS_DEPLOYED_REQUIRED: "Nepasirinktos išskleidusios oro pagalvės",
        TRANSPORTATION_OR_NO_TRANSPORTATION_REQUIRED: "Nepasirinkta, ar pacientas buvo transportuotas",
        TRANSPORTATION_REQUIRED: "Įvestas transportavimo laikas, todėl pacientas turi būti transportuotas",
        DISTANCE_REQUIRED: "Neįvestas transportavimo kilometražas",
        DISORDER_TYPE_REQUIRED: "Nepasirinktas sutrikimo tipas",
        AGE_REQUIRED: "Neįvestas amžius",
        BIRTHDATE_REQUIRED: "Neįvesta gimimo data",
        AGE_TYPE_REQUIRED: "Nepasirinkti amžiaus vienetai",
        SEX_REQUIRED: this.intl.t("errors.SEX_REQUIRED"),
        HELP_PROTOCOL_REQUIRED: "Nepasirinktas pagalbos teikimo protokolas",
        REASON_TO_EVENT_SITE_REQUIRED: "Nepasirinktos vėlavimo priežastys į įvykio vietą",
        PROTOCOL_24_REQUIRED: "Būtina pasirinkti nėštumo pagalbos teikimo protokolą",
        STROKE_DURATION_REQUIRED: "Nepasirinkta insulto trukmė",
        PREGNANCY_DURATION_REQUIRED: "Neįvesta nėštumo trukmė",
        DEPARTMENT_REQUIRED: "Nepasirinkta transportavimo vieta",
        PATIENT_TO_VEHICLE_REQUIRED: "Nepasirinkta, kaip nugabentas iki GMP",
        ROUTE_REQUIRED: "Nepasirinkta, iš kur pervežta",
        NUMBER_OF_PATIENTS_REQUIRED: "Nepasirinktas pacientų kiekis",
        HOSPITAL_SELECTION_CRITERIA_REQUIRED: "Nepasirinktas ligoninės pasirinkimo kriterijus",
        DESELECT_PHC_NOTIFIED: "Informacija neturėjo būti perduota PASPĮ",
        PHC_NOTIFIED_REQUIRED: "Nepažymėta, kad informacija perduota PASPĮ",
        TIME_ON_ROUTE_REQUIRED: "Neįvestas išvykimo laikas",
        TIME_ARRIVED_REQUIRED: "Neįvestas atvykimo laikas",
        TIME_AVAILABLE_REQUIRED: "Neįvestas įvykdymo laikas",
        TIME_RETURNED_REQUIRED: "Neįvestas grįžimo laikas",
        ON_ROUTE_TIME_EDIT_REQUEST_TIME_TO_REQUIRED: "Neužpildytas išvykimo keitimo prašymo laikas",
        ARRIVED_TIME_EDIT_REQUEST_TIME_TO_REQUIRED: "Neužpildytas atvykimo keitimo prašymo laikas",
        TRANSPORTATION_TIME_INVALID: "Neteisingai įvestas transportavimo laikas",
        AT_HOSPITAL_TIME_INVALID: "Neteisingai įvestas ligoninėje laikas",
        AVAILABLE_TIME_INVALID: "Neteisingai įvestas įvykdymo laikas",
        RETURNED_TIME_INVALID: "Neteisingai įvestas grįžimo laikas",
        TIMES_TRANSPORTATION_MISSING: "Neįvestas transportavimo laikas",
        TRAUMA_CRITERIA_CONTENT_REQUIRED: "Neužpildyta traumos forma",
        INFARCT_START_TIME_INVALID: "Neteisingai įvestas ŪKS laikas",
        TEAM_LEADER_REQUIRED: "Nepasirinktas komandos vadovas",
        TRANSPORTATION_NOTES_REQUIRED: "Neužpildyta netransportavimo priežastis",
        AGREEMENT_TO_SHARE_DATA_WITH_PERSON_REQUIRED: "Neįvestas asmens vardas, pavardė, kuriam galima perduoti informaciją",
        REPRESENTATIVE_NAME_REQUIRED: "Neįvestas atstovo vardas, pavardė",
        PROCEDURE_TIME_REQUIRED: "Procedūroje neįvestas laikas",
        PROCEDURE_SIZE_REQUIRED: "Procedūroje nepasirinktas dydis",
        PROCEDURE_CONDITION_CHANGE_REQUIRED: "Procedūroje nepasirinkta būklė",
        PROCEDURE_TOTAL_REQUIRED: "Procedūroje neįvestas kiekis",
        PROCEDURE_MEMBER_REQUIRED: "Procedūroje nepasirinktas narys",
        PROCEDURE_TIME_EARLIER_THAN_ARRIVAL: "Procedūra įvesta anksčiau nei atvykta į vietą",
        PROCEDURE_TIME_LATER_THAN_HOSPITAL: "Procedūra įvesta vėliau nei atvykta į ligoninę",
        PROCEDURE_TIME_LATER_THAN_RETURNED: "Procedūra įvesta vėliau nei baigtas įvykis",
        PROCEDURE_TIME_INVALID: "Procedūroje neteisingai įvestas laikas",
        MEDICAMENT_TIME_REQUIRED: "Vaiste neįvestas laikas",
        MEDICAMENT_TIME_INVALID: "Vaiste neteisingai įvestas laikas",
        MEDICAMENT_CONDITION_CHANGE_REQUIRED: "Vaiste nepasirinkta būklė",
        MEDICAMENT_TOTAL_REQUIRED: "Vaiste neįvestas kiekis",
        MEDICAMENT_MEMBER_REQUIRED: "Vaiste nepasirinktas narys",
        MEDICAMENT_USE_TYPE_REQUIRED: "Vaiste nepasirinktas vartojimo būdas",
        MEDICAMENT_SIZE_INVALID: "Vaiste neteisingai įvestas spindis/dydis",
        MEDICAMENT_TIME_EARLIER_THAN_ARRIVAL: "Vaistas įvestas anksčiau nei atvykta į vietą",
        MEDICAMENT_TIME_LATER_THAN_HOSPITAL: "Vaistas įvestas vėliau nei atvykta į ligoninę",
        MEDICAMENT_TIME_LATER_THAN_RETURNED: "Vaistas įvestas vėliau nei baigtas įvykis",
        MEDICAMENT_COMMENT_REQUIRED: "Vaiste neįvestas komentaras",
        TRANSPORTATION_DISTANCE_INVALID: "Neteisingai įvesti transportavimo km",
        ASSIGN_IS_EARLIER_THAN_PREVIOUS_TIMES: "Įvestas nekorektiškas perdavimo laikas",
        ON_ROUTE_IS_EARLIER_THAN_PREVIOUS_TIMES: "Įvestas nekorektiškas išvykimo laikas",
        ARRIVED_IS_EARLIER_THAN_PREVIOUS_TIMES: "Įvestas nekorektiškas atvykimo laikas",
        TRANSPORTATION_IS_EARLIER_THAN_PREVIOUS_TIMES: "Įvestas nekorektiškas transportavimo laikas",
        AT_HOSPITAL_IS_EARLIER_THAN_PREVIOUS_TIMES: "Įvestas nekorektiškas atvykimo į ligoninę laikas",
        AVAILABLE_IS_EARLIER_THAN_PREVIOUS_TIMES: "Įvestas nekorektiškas įvykdymo laikas",
        RETURNED_IS_EARLIER_THAN_PREVIOUS_TIMES: "Įvestas nekorektiškas grįžimo laikas",
        DEATH_TIME_REQUIRED: "Neįvestas paciento mirties laikas",
        DEATH_TIME_INVALID: "Neteisingai įvestas paciento mirties laikas",
        DEATH_SIGNS_REQUIRED: "Nepasirinkti mirties požymiai",
        NO_TRANSPORTATION_REASON_DEAD_REQUIRED: "Pacientas miręs, todėl negali būti transportuotas",
        TRANSPORTATION_REFUSED_BY_PATIENT: "Pacientas atsisako vykti į ligoninę",
        NO_TRANSPORTATION_WITH_TEAM_ID_REQUIRED: "Neįvestas komandos nr., kuriai perduota arba padėta",
        ALV_APPLIED_REQUIRED: "Nepažymėta, kad pacientas, kuriam buvo taikytas DPV, yra pervežtas",
        TRANSPORTED_FROM_NOTES_REQUIRED: "Neužpildyta transportavimo vieta",
        PATIENT_TREATMENT_REQUIRED: "Neįvestas gydymas, nors vertinta būklė po MP",
        PATIENT_POSITION_REQUIRED: "Nepasirinkta paciento padėtis",
        UNIQUE_TEAM_MEMBERS_REQUIRED: "Tas pats komandos narys negali kartotis",
        DELIVERY_TYPE_REQUIRED: "Neįvestas perdavimo būdas",
        PREGNANCY_DURATION_INVALID: "Neteisingai įvesta nėštumo trukmė",
        CONTRACTIONS_PERIOD_INVALID: "Neteisingai įvestas sąrėmių kartojimosi laikas",
        FETUS_HEART_BEAT_INVALID: "Neteisingai įvesti vaisiaus širdies tonai",
        CLINICAL_DEATH_TIME_INVALID: "Neteisingai įvestas klinikinės mirties laikas",
        AGE_VALUE_INVALID: "Neteisingai įvestas amžius",
        BIRTHDATE_INVALID: "Neteisingai įvesta paciento gimimo data",
        BABY_BORN_TIME_REQUIRED: "Neįvestas naujagimio gimimo laikas",
        PLACENTA_BORN_REQUIRED: "Nepažymėta, kad gimusi placenta",
        BABY_BIRTH_TIME_INVALID: "Neteisingai įvestas naujagimio gimimo laikas",
        CPR_STARTED_REQUIRED: "Nepasirinkta, ar PG atliktas",
        AED_USED_REQUIRED: "Nepasirinkta, ar AID panaudotas",
        MINS_SINCE_CPR_START_INVALID: "Neteisingai įvestos min. nuo PG pradžios",
        MINS_SINCE_CPR_END_INVALID: "Neteisingai įvestos min. nuo PG pabaigos ",
        MINS_SINCE_SUDDEN_DEATH_INVALID: "Neteisingai įvestos min. nuo staigios mirties",
        MINS_SINCE_AED_INVALID: "Neteisingai įvestos min. nuo pirmojo AID imp.",
        DISEASES_ALLERGY_NOTES_REQUIRED: "Neužpildytas žinomos alergijos",
        DISEASES_OTHER_NOTES_REQUIRED: "Neužpildyti kiti žinomi susirgimai",
        TRANSPORTATION_TIME_REQUIRED: "Neįvestas transportavimo laikas",
        AT_HOSPITAL_TIME_REQUIRED: "Neįvestas atvykimo į ligoninę laikas",
        TRAUMATOLOGY_DEPARTMENT_REQUIRED: "Pasirinktas ne traumatologijos skyrius",
        HELP_REFUSED_FILLED: "Pagalbos atsisakymas neturėtų būti pažymėtas",
        NARCOTIC_DRUGS_VERIFICATION_SIGNATURE_REQUIRED: "Trūksta asmens, patvirtinusio narkotinių medžiagų skyrimą, parašo",
        NARCOTIC_DRUGS_VERIFICATION_SIGNER_NAME_REQUIRED: "Trūksta asmens, patvirtinusio narkotinių medžiagų skyrimą, vardo ir pavardės",
        NARCOTIC_DRUGS_VERIFICATION_SIGNATURE_FILLED: "Užpildytas asmens, patvirtinusio narkotinių medžiagų skyrimą, parašas, tačiau narkotiniai vaistai nebuvo skirti",
        NARCOTIC_DRUGS_VERIFICATION_SIGNER_NAME_FILLED: "Užpildytas asmens, patvirtinusio narkotinių medžiagų skyrimą, vardas ir pavardė, tačiau narkotiniai vaistai nebuvo skirti",
        INVALID_TRANSPORTATION_OPTION: "SPS įvertintas, pasirinktas netinkamas transp. būdas",
        DEAD_PATIENT_CANNOT_SIGN_OR_REFUSE: "Miręs pacientas negali (atsisakyti) pasirašyti",
        TREATMENT_CONDITION_CHANGED_WHILE_AB_AND_AA_IDENTICAL: "Paciento būklė nepasikeitė po skirto gydymo",
        TEAM_TYPE_REQUIRED: "Nepasirinktas komandos tipas",
        ICD_REQUIRED: "Nepasirinktas TLK kodas",
        PHYSICIAN_REQUIRED: "Neįvestas paciento šeimos gydytojas",
        PHC_REQUIRED: "Neįvesta paciento PASPĮ",
        EVENT_ID_REQUIRED: "Neįvestas kvietimo id",
        MISSION_ID_REQUIRED: "Neįvestas misijos nr",
        PATIENT_ID_REQUIRED: "Neįvestas paciento nr",
        TEAM_ID_REQUIRED: "Neįvestas komandos id",
        CALL_TIME_REQUIRED: "Neįvestas priėmimo (skambučio) laikas",
        REGISTRATION_TIME_REQUIRED: "Neįvestas užregistravimo laikas",
        ASSIGN_TIME_REQUIRED: "Neįvestas priskyrimo laikas",
        CATEGORY_REQUIRED: "Neįvesta kategorija",
        EVENT_SITE_ADDRESS_REQUIRED: "Neįvestas įvykio vietos adresas",
        CALL_DETAILS_REQUIRED: "Neužpildytas iškvietimo protokolas (priežastis)",
        ACTIVATION_PROTOCOL_REQUIRED: "Neįvestas iškvietimo protokolo kodas",
        CALLER_PHONE_NUMBER_REQUIRED: "Neįvestas kvietėjo telefono numeris"
      };
    }),
    groupToRoute: {
      event: "pcr.event",
      patient: "pcr.patient",
      vitals: "pcr.vitals",
      narrative: "pcr.narrative",
      flowchart: "pcr.flowchart",
      forms: "pcr.forms",
      signatures: "pcr.signatures",
      transportation: "pcr.transportation"
    },

    createErrorMapper(pcr) {
      const {
        deathStatement,
        patientTreatment: {
          medicamentTreatment,
          procedureTreatment
        }
      } = pcr;
      const vitalsGroup = "vitals";
      const formsGroup = "forms";
      const signaturesGroup = "signatures";
      const flowchartGroup = "flowchart";
      this.eventPageValidations();
      this.patientPageValidations();
      this.narrativePageValidations();
      this.transportationPageValidations();
      this.beforeHelpTreatmentValidations(vitalsGroup);
      this.afterHelpTreatmentValidations(vitalsGroup);
      this.atErTreatmentValidations(vitalsGroup);
      this.clinicalDeathFormValidation(formsGroup);
      this.strokeFormValidations(formsGroup);
      this.deathFormValidation(formsGroup);
      this.traumaFormValidation(formsGroup);
      this.incidentFormValidation(formsGroup);
      this.heartAttackFormValidation(formsGroup);
      this.birthFormValidation(formsGroup);
      this.infoShareFormValidation(formsGroup);
      this.medicalHelpSignaturesValidations(signaturesGroup);
      this.teamLeaderSignatureValidations(signaturesGroup);
      this.erPersonSignatureValidations(signaturesGroup);
      this.narcoticDrugsVerificationValidations(signaturesGroup);
      this.flowchartValidations(flowchartGroup);
      this.medicamentsValidations(medicamentTreatment, flowchartGroup);
      this.proceduresValidation(procedureTreatment, flowchartGroup);
      this.cprValidation(deathStatement, formsGroup);
    },

    addMappingWithContainer(errorCode, group, elementContainer, ...fields) {
      this.errorMapping[errorCode] = {
        elementContainer: elementContainer ? `#${elementContainer}` : elementContainer,
        group: Ember.isArray(group) ? group : [group],
        fields: this.createFields(errorCode, ...fields)
      };
    },

    addMappingWithContainerAndMatch(errorCode, group, elementContainer, match, ...fields) {
      this.errorMapping[`${errorCode}_${match}`] = {
        elementContainer,
        group: Ember.isArray(group) ? group : [group],
        fields: this.createFields(errorCode, ...fields)
      };
    },

    createFields(errorCode, ...fields) {
      return fields.map(field => ({
        id: field,
        message: this.translations[errorCode] || ""
      }));
    },

    addMapping(errorCode, group, ...fields) {
      this.addMappingWithContainer(errorCode, group, "", ...fields);
    },

    createGroupError: (type, message, focusField, elementContainer, routeWithParams, code) => ({
      type,
      message,
      focusField,
      elementContainer,
      routeWithParams,
      code
    }),

    addGroup(errors, {
      group,
      fields,
      elementContainer,
      code
    }, type) {
      const groupName = Ember.isArray(group) ? group[0] : group;
      const groupParams = group.length > 1 ? group[1] : null;
      let error = {};
      if (!errors[groupName]) errors[groupName] = [];
      if (!fields || !fields.length) error = this.createGroupError(type, "", "", "", "", code);else {
        const [{
          message,
          id
        }] = fields;
        error = this.createGroupError(type, message, id, elementContainer, this.getRoute(groupName, groupParams), code);
      }
      errors[groupName].pushObject(error);
    },

    getRoute(groupName, groupParams) {
      const route = this.groupToRoute[groupName];
      if (!route) throw new Error(`Route by group: ${groupName} not found`);
      const routeWithParams = [route];
      if (groupParams !== null) routeWithParams.push(groupParams);
      return routeWithParams;
    },

    getErrorMapping(code, match) {
      const modifiedCode = match ? `${code}_${match}` : code;
      return this.errorMapping[modifiedCode] || this.unknownErrorMapping(modifiedCode);
    },

    unknownErrorMapping: code => {
      _raven.default.captureMessage(`unknown validation code = ${code}`);

      return {
        group: "unknown",
        type: "error",
        code
      };
    },

    addError(errors, {
      fields
    }, type, match) {
      fields && fields.forEach(({
        id,
        message
      }) => {
        const uniqId = match ? `${id}-${match}` : id;
        errors[uniqId] = {
          message,
          type,
          match
        };
      });
      return errors;
    },

    fieldWithContainer: (container, field) => !field ? container : `${container}-${field}`,

    eventPageValidations(group = "event") {
      const transportationTime = "transportation-time";
      const arrivedTime = "arrived-time";
      const onRouteTime = "on-route-time";
      const availableTime = "available-time";
      const returnedTime = "returned-time";
      const atHospitalTime = "at-hospital-time";

      const createFieldWithContainer = (container, field) => this.fieldWithContainer(container, field);

      const addMapping = (rule, field) => this.addMapping(rule, group, field);

      const addMappingWithContainer = (rule, container, field) => this.addMappingWithContainer(rule, group, container, createFieldWithContainer(container, field));

      addMapping("REASON_TO_EVENT_SITE_REQUIRED", "delay-reasons-to-event-site");
      addMapping("TIME_ON_ROUTE_REQUIRED", onRouteTime);
      addMapping("TIME_ARRIVED_REQUIRED", arrivedTime);
      addMapping("TIME_AVAILABLE_REQUIRED", availableTime);
      addMapping("TIME_RETURNED_REQUIRED", returnedTime);
      addMapping("TRANSPORTATION_TIME_INVALID", transportationTime);
      addMapping("TRANSPORTATION_TIME_REQUIRED", transportationTime);
      addMapping("AT_HOSPITAL_TIME_INVALID", atHospitalTime);
      addMapping("AT_HOSPITAL_TIME_REQUIRED", atHospitalTime);
      addMapping("AVAILABLE_TIME_INVALID", availableTime);
      addMapping("RETURNED_TIME_INVALID", returnedTime);
      addMapping("TIMES_TRANSPORTATION_MISSING", transportationTime);
      addMapping("TEAM_LEADER_REQUIRED", "team-leader");
      addMapping("ASSIGN_IS_EARLIER_THAN_PREVIOUS_TIMES", "assign-time");
      addMapping("ON_ROUTE_IS_EARLIER_THAN_PREVIOUS_TIMES", onRouteTime);
      addMapping("ARRIVED_IS_EARLIER_THAN_PREVIOUS_TIMES", arrivedTime);
      addMapping("TRANSPORTATION_IS_EARLIER_THAN_PREVIOUS_TIMES", transportationTime);
      addMapping("AT_HOSPITAL_IS_EARLIER_THAN_PREVIOUS_TIMES", atHospitalTime);
      addMapping("AVAILABLE_IS_EARLIER_THAN_PREVIOUS_TIMES", availableTime);
      addMapping("RETURNED_IS_EARLIER_THAN_PREVIOUS_TIMES", returnedTime);
      addMapping("UNIQUE_TEAM_MEMBERS_REQUIRED", "unique-team-member");
      addMapping("DELIVERY_TYPE_REQUIRED", "delivery-type");
      addMapping("TEAM_TYPE_REQUIRED", "team-type");
      addMappingWithContainer("ON_ROUTE_TIME_EDIT_REQUEST_TIME_TO_REQUIRED", onRouteTime, "edit-request");
      addMappingWithContainer("ARRIVED_TIME_EDIT_REQUEST_TIME_TO_REQUIRED", arrivedTime, "edit-request"); // Rules required when creating pcr

      addMapping("REGISTRATION_TIME_REQUIRED", "registration-time");
      addMapping("ASSIGN_TIME_REQUIRED", "assign-time");
      addMapping("TEAM_ID_REQUIRED", "teamId");
      addMapping("EVENT_ID_REQUIRED", "eventId");
      addMapping("CATEGORY_REQUIRED", "category");
      addMapping("CALL_TIME_REQUIRED", "call-time");
      addMapping("EVENT_SITE_ADDRESS_REQUIRED", "eventSiteAddress");
      addMapping("CALL_DETAILS_REQUIRED", "callDetails");
      addMapping("ACTIVATION_PROTOCOL_REQUIRED", "activationProtocol");
      addMapping("CALLER_PHONE_NUMBER_REQUIRED", "phoneNumber1");
      addMapping("MISSION_ID_REQUIRED", "missionId");
      addMapping("PATIENT_ID_REQUIRED", "patientId"); // todo įhardcodintos taisyklės

      addMapping("eventPhoneNumber1Empty", "phoneNumber1");
      addMapping("eventPhoneNumber2Empty", "phoneNumber2");
      addMapping("eventPhoneNumber3Empty", "phoneNumber3");
      addMapping("eventCategoryEmpty", "category");
      addMapping("dispatcherCodeEmpty", "dispatcherCode");
      addMapping("callTakerCodeEmpty", "callTakerCode");
      addMapping("callTakerCodeEmpty", "callTakerCode");
      addMapping("activationProtocolEmpty", "activationProtocol");
      addMapping("addressEmpty", "address");
      addMapping("outcomeEmpty", "outcome");
      addMapping("sirensToSiteEmpty", "sirensToSite");
      addMapping("policeInformedEmpty", "policeInformed");
      addMapping("fireDepInformedEmpty", "fireDepInformed");
      addMapping("teamIdEmpty", "teamId");
      addMapping("eventDelayReasonToPatientEmpty", "delayReasonsToPatient");
      addMapping("eventDelayReasonToHospitalEmpty", "delayReasonsToHospital");
    },

    beforeHelpTreatmentValidations(group, beforeHelp = "BEFORE_HELP") {
      const container = container => this.fieldWithContainer(beforeHelp, container);

      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, group, beforeHelp, container(field));

      const addMappingWithFewFields = (rule, ...fields) => this.addMappingWithContainer(rule, group, beforeHelp, ...fields);

      addMappingWithContainer("AB_INVALID_BP", BP_FIELD);
      addMappingWithContainer("AB_BP_REQUIRED", BP_FIELD);
      addMappingWithContainer("PAIN_LOCATION_REQUIRED", "pain-location");
      addMappingWithContainer("INJURY_LOCATION_REQUIRED", "pain-site");
      addMappingWithContainer("AB_PULSE", PULSE_FIELD);
      addMappingWithContainer("AB_PULSE_INVALID", PULSE_FIELD);
      addMappingWithContainer("AB_RESPIRATORY_RATE", RESPIRATORY_RATE_FIELD);
      addMappingWithContainer("AB_RESPIRATORY_RATE_INVALID", RESPIRATORY_RATE_FIELD);
      addMappingWithContainer("AB_LEFT_PUPIL", "left-pupil");
      addMappingWithContainer("AB_RIGHT_PUPIL", "right-pupil");
      addMappingWithContainer("AB_SPO2_INVALID", SPO2_FIELD);
      addMappingWithContainer("AB_CAPILLARY_FILLING_UP_TIME", "capillary-filling-up-time");
      addMappingWithContainer("AB_RADIAL_ARTERY_PULSE_REQUIRED", "radial-artery-pulse");
      addMappingWithContainer("AB_SKIN_TEMPERATURE_REQUIRED", "skin-temperature");
      addMappingWithContainer("AB_SKIN_COLOR_REQUIRED", "skin-color");
      addMappingWithContainer("AB_SKIN_CONDITION_REQUIRED", "skin-condition");
      addMappingWithFewFields("AB_CONSCIOUSNESS", container("avpu"), container(EYE_RESPONSE), container(MOTOR_RESPONSE), container(VERBAL_RESPONSE));
      addMappingWithContainer("AB_GLUCOSE_REQUIRED", GLUCOSE_FIELD);
      addMappingWithContainer("AB_LEFT_LUNG_BREATHING_QUALITY_REQUIRED", "left-lung");
      addMappingWithContainer("AB_RIGHT_LUNG_BREATHING_QUALITY_REQUIRED", "right-lung");
      addMappingWithContainer("AB_EYE_RESPONSE_REQUIRED", EYE_RESPONSE);
      addMappingWithContainer("AB_VERBAL_RESPONSE_REQUIRED", VERBAL_RESPONSE);
      addMappingWithContainer("AB_MOTOR_RESPONSE_REQUIRED", MOTOR_RESPONSE);
      addMappingWithContainer("AB_HEART_RHYTHM_REQUIRED", HEART_RHYTHM_FIELD);
      addMappingWithContainer("AB_TIME_REQUIRED", TIME_FIELD);
      addMappingWithContainer("AB_TIME_INVALID", TIME_FIELD);
      addMappingWithContainer("AB_TIME_IS_EARLIER_THAN_ARRIVED", TIME_FIELD);
      addMappingWithContainer("AB_TIME_IS_LATER_THAN_AT_HOSPITAL", TIME_FIELD);
      addMappingWithContainer("AB_TIME_IS_LATER_THAN_AVAILABLE_OR_RETURNED", TIME_FIELD);
      addMappingWithContainer("AB_DESELECT_AVPU", "avpu");
      addMappingWithFewFields("AB_DESELECT_GCS", container(EYE_RESPONSE), container(MOTOR_RESPONSE), container(VERBAL_RESPONSE));
      addMappingWithContainer("AB_HEART_RHYTHM_REQUIRED", HEART_RHYTHM_FIELD);
      addMappingWithContainer("AB_ST_SEGMENT_REQUIRED", ST_SEGMENT);
      addMappingWithContainer("AB_PAIN_REQUIRED", "vitals-pain");
      addMappingWithContainer("AB_TEMPERATURE_INVALID", TEMP_DEGREE_FIELD);
      addMappingWithContainer("AB_GLUCOSE_INVALID", GLUCOSE_FIELD);
      addMappingWithContainer("AB_REQUIRED", "");
    },

    afterHelpTreatmentValidations(group, afterHelp = "AFTER_HELP") {
      const container = container => this.fieldWithContainer(afterHelp, container);

      const addMappingWithFewFields = (rule, ...fields) => this.addMappingWithContainer(rule, group, afterHelp, ...fields);

      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, group, afterHelp, container(field));

      addMappingWithContainer("TREATMENT_CONDITION_CHANGED_WHILE_AB_AND_AA_IDENTICAL", "");
      addMappingWithContainer("AA_INVALID_BP", BP_FIELD);
      addMappingWithContainer("AA_PULSE_INVALID", PULSE_FIELD);
      addMappingWithContainer("AA_BP_REQUIRED", BP_FIELD);
      addMappingWithContainer("AA_RESPIRATORY_RATE_INVALID", RESPIRATORY_RATE_FIELD);
      addMappingWithContainer("AA_LEFT_LUNG_BREATHING_QUALITY_REQUIRED", "left-lung");
      addMappingWithContainer("AA_RIGHT_LUNG_BREATHING_QUALITY_REQUIRED", "right-lung");
      addMappingWithContainer("AA_PULSE_REQUIRED_SKIN_COLOR", "skin-color");
      addMappingWithContainer("AA_PULSE_REQUIRED_SKIN_CONDITION", "skin-condition");
      addMappingWithContainer("AA_PULSE_REQUIRED_SKIN_TEMPERATURE", "skin-temperature");
      addMappingWithContainer("AA_PULSE_REQUIRED_RADIAL_ARTERY_PULSE", "radial-artery-pulse");
      addMappingWithContainer("AA_EYE_RESPONSE_REQUIRED", EYE_RESPONSE);
      addMappingWithContainer("AA_VERBAL_RESPONSE_REQUIRED", VERBAL_RESPONSE);
      addMappingWithContainer("AA_MOTOR_RESPONSE_REQUIRED", MOTOR_RESPONSE);
      addMappingWithContainer("AA_REQUIRED_CAPILLARY_FILLING_UP_TIME", "capillary-filling-up-time");
      addMappingWithContainer("AA_REQUIRED_RESPIRATORY_RATE", RESPIRATORY_RATE_FIELD);
      addMappingWithContainer("AA_REQUIRED_LEFT_PUPIL", "left-pupil");
      addMappingWithContainer("AA_REQUIRED_RIGHT_PUPIL", "right-pupil");
      addMappingWithContainer("AA_REQUIRED_PULSE", PULSE_FIELD);
      addMappingWithContainer("AA_TIME_REQUIRED", TIME_FIELD);
      addMappingWithContainer("AA_HEART_RHYTHM_REQUIRED", HEART_RHYTHM_FIELD);
      addMappingWithContainer("AA_SPO2_INVALID", SPO2_FIELD);
      addMappingWithContainer("AA_DESELECT_AVPU", "avpu");
      addMappingWithFewFields("AA_DESELECT_GCS", container(EYE_RESPONSE), container(MOTOR_RESPONSE), container(VERBAL_RESPONSE));
      addMappingWithContainer("AA_REQUIRED", "");
      addMappingWithContainer("AB_TIME_AFTER_AA_TIME", TIME_FIELD);
      addMappingWithContainer("AA_TIME_INVALID", TIME_FIELD);
      addMappingWithContainer("AA_TIME_IS_EARLIER_THAN_ARRIVED", TIME_FIELD);
      addMappingWithContainer("AA_TIME_IS_LATER_THAN_AT_HOSPITAL", TIME_FIELD);
      addMappingWithContainer("AA_TIME_IS_LATER_THAN_AVAILABLE_OR_RETURNED", TIME_FIELD);
      addMappingWithContainer("AA_TEMPERATURE_INVALID", TEMP_DEGREE_FIELD);
      addMappingWithContainer("AA_GLUCOSE_INVALID", GLUCOSE_FIELD);
      addMappingWithContainer("AB_ST_SEGMENT_RAISE_AA_DOWN", ST_SEGMENT);
      addMappingWithContainer("AB_ST_SEGMENT_DOWN_AA_RAISE", ST_SEGMENT);
    },

    atErTreatmentValidations(group, atEr = "AT_ER") {
      const container = container => this.fieldWithContainer(atEr, container);

      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, group, atEr, container(field));

      addMappingWithContainer("AT_ER_INVALID_BP", BP_FIELD);
      addMappingWithContainer("AT_ER_BP_REQUIRED", BP_FIELD);
      addMappingWithContainer("AT_ER_PULSE_REQUIRED", PULSE_FIELD);
      addMappingWithContainer("AT_ER_PULSE_INVALID", PULSE_FIELD);
      addMappingWithContainer("AT_ER_RESPIRATORY_RATE_REQUIRED", RESPIRATORY_RATE_FIELD);
      addMappingWithContainer("AT_ER_RESPIRATORY_RATE_INVALID", RESPIRATORY_RATE_FIELD);
      addMappingWithContainer("AT_ER_SPO2_INVALID", SPO2_FIELD);
      addMappingWithContainer("AT_ER_TIME_REQUIRED", TIME_FIELD);
      addMappingWithContainer("AT_ER_REQUIRED", "");
      addMappingWithContainer("AA_TIME_AFTER_AT_ER_TIME", TIME_FIELD);
      addMappingWithContainer("AT_ER_TIME_IS_LATER_THAN_AVAILABLE", TIME_FIELD);
      addMappingWithContainer("AB_TIME_IS_AFTER_AT_ER", TIME_FIELD);
      addMappingWithContainer("AT_ER_TIME_INVALID", TIME_FIELD);
    },

    strokeFormValidations(group, container = "stroke-form") {
      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, group, container, field);

      addMappingWithContainer("STROKE_ER_NOTIFIED_REQUIRED", "strokeErNotified");
      addMappingWithContainer("EXACT_STROKE_TIME_REQUIRED", "exact-stroke-time");
      addMappingWithContainer("STROKE_START_TIME_INVALID", "exact-stroke-time");
      addMappingWithContainer("STROKE_DURATION_REQUIRED", "stroke-duration"); // todo įhardcodintos taisyklės

      addMappingWithContainer("armsWeakenedError", "armsWeakened");
      addMappingWithContainer("faceParalysisError", "faceParalysis");
      addMappingWithContainer("speechDisorderError", "speechDisorder");
    },

    clinicalDeathFormValidation(group, container = "clinical-death-form") {
      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, group, container, field);

      addMappingWithContainer("CLINICAL_DEATH_TIME_REQUIRED", "clinical-death-time");
      addMappingWithContainer("CLINICAL_DEATH_TIME_INVALID", "clinical-death-time");
    },

    cprValidation(deathStatement, group) {
      const container = deathStatement && deathStatement.deathTime ? "death-form" : "clinical-death-form";

      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, group, container, field);

      addMappingWithContainer("CPR_STARTED_REQUIRED", "cpr-started");
      addMappingWithContainer("AED_USED_REQUIRED", "aed-used");
      addMappingWithContainer("MINS_FROM_SUDDEN_DEATH_REQUIRED", "mins-from-sudden-death");
      addMappingWithContainer("MINS_SINCE_SUDDEN_DEATH_INVALID", "mins-from-sudden-death");
      addMappingWithContainer("MINS_FROM_CPR_START_REQUIRED", "mins-since-cpr-started");
      addMappingWithContainer("MINS_SINCE_CPR_START_INVALID", "mins-since-cpr-started");
      addMappingWithContainer("MINS_FROM_CPR_END_REQUIRED", "mins-since-cpr-end");
      addMappingWithContainer("MINS_SINCE_CPR_END_INVALID", "mins-since-cpr-end");
      addMappingWithContainer("CPR_STARTED_BY_REQUIRED", "cpr-started-by");
      addMappingWithContainer("AED_USED_BY_REQUIRED", "aed-used-by");
      addMappingWithContainer("MINS_SINCE_FIRST_AED_REQUIRED", "mins-since-first-aed");
      addMappingWithContainer("MINS_SINCE_AED_INVALID", "mins-since-first-aed");
    },

    deathFormValidation(group, container = "death-form") {
      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, group, container, field);

      addMappingWithContainer("DEATH_SIGNS_REQUIRED", "death-signs");
      addMappingWithContainer("DEATH_TIME_REQUIRED", "death-time");
      addMappingWithContainer("DEATH_TIME_INVALID", "death-time");
    },

    incidentFormValidation(group, container = "incident-form") {
      const fieldWithContainer = field => this.fieldWithContainer(container, field);

      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, group, container, fieldWithContainer(field));

      addMappingWithContainer("SEAT_BELT_REQUIRED", "seat-belt");
      addMappingWithContainer("SITTING_PLACE_REQUIRED", "sitting-place");
      addMappingWithContainer("AIRBAGS_DEPLOYED_REQUIRED", "airbags-deployed");
    },

    traumaFormValidation(group, container = "trauma-form") {
      const fieldWithContainer = field => this.fieldWithContainer(container, field);

      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, group, container, fieldWithContainer(field));

      addMappingWithContainer("TRAUMA_ER_INFORMED_REQUIRED", "er-notified");
      addMappingWithContainer("ABCD_EVAL_REQUIRED", "abcd-eval");
      addMappingWithContainer("INJURY_REQUIRED", "injuries");
      addMappingWithContainer("BACKGROUND_REQUIRED", "background");
      addMappingWithContainer("SAFETY_MEASURES_REQUIRED", "safety-measures");
      this.addMappingWithContainer("TRAUMA_CRITERIA_CONTENT_REQUIRED", group, container, "");
    },

    heartAttackFormValidation(group, container = "heart-attack-form") {
      const fieldWithContainer = field => this.fieldWithContainer("heart-attack", field);

      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, group, container, fieldWithContainer(field));

      addMappingWithContainer("HEART_ATTACK_ER_NOTIFIED_REQUIRED", "er-informed");
      addMappingWithContainer("HEART_ATTACK_RAISED_ST_SEG_REQUIRED", ST_SEGMENT);
      addMappingWithContainer("EXACT_HEART_ATTACK_TIME_REQUIRED", TIME_FIELD);
      addMappingWithContainer("INFARCT_START_TIME_INVALID", TIME_FIELD);
      addMappingWithContainer("HEART_ATTACK_PAIN_STARTED_LTE12H_REQUIRED", "started-lte12h");
    },

    birthFormValidation(group, container = "birth-form") {
      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, group, container, field);

      addMappingWithContainer("BIRTH_ER_INFORMED_REQUIRED", "birth-er-informed");
      addMappingWithContainer("APGAR_1MIN_REQUIRED", "apgar-1min");
      addMappingWithContainer("APGAR_5MIN_REQUIRED", "apgar-5min");
      addMappingWithContainer("PREGNANCY_DURATION_REQUIRED", "pregnancy-duration");
      addMappingWithContainer("PREGNANCY_DURATION_INVALID", "pregnancy-duration");
      addMappingWithContainer("CONTRACTIONS_PERIOD_INVALID", "pregnancy-contractions");
      addMappingWithContainer("FETUS_HEART_BEAT_INVALID", "fetus-heart-beat");
      addMappingWithContainer("BABY_BORN_TIME_REQUIRED", "baby-born-time");
      addMappingWithContainer("BABY_BIRTH_TIME_INVALID", "baby-born-time");
      addMappingWithContainer("PLACENTA_BORN_REQUIRED", "placenta-born");
    },

    infoShareFormValidation(group, container = "info-transfer-form") {
      const fieldWithContainer = field => this.fieldWithContainer(container, field);

      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, group, container, fieldWithContainer(field));

      addMappingWithContainer("DESELECT_PHC_NOTIFIED", "phc-notified");
      addMappingWithContainer("PHC_NOTIFIED_REQUIRED", "phc-notified");
    },

    narrativePageValidations(group = "narrative") {
      const addMapping = (rule, field) => this.addMapping(rule, group, field);

      addMapping("DISORDER_TYPE_REQUIRED", "disorder-type");
      addMapping("HELP_PROTOCOL_REQUIRED", "help-protocol");
      addMapping("PROTOCOL_24_REQUIRED", "help-protocol");
      addMapping("ICD_OR_NO_TRANSPORTATION_REASON_OTHER", "icd10code");
      addMapping("ICD_REQUIRED", "icd10code"); // todo įhardcodintos taisyklės

      addMapping("diagnosisEmpty", "diagnosis");
      addMapping("narrativeNotesEmpty", "narrativeNotes");
    },

    transportationPageValidations(group = "transportation") {
      const addMapping = (rule, field) => this.addMapping(rule, group, field);

      const DISTANCE_FIELD = "transportation-distance";
      const transportation = "transportation";
      addMapping("TRANSPORTATION_OR_NO_TRANSPORTATION_REQUIRED", transportation);
      addMapping("TRANSPORTATION_REQUIRED", transportation);
      addMapping("NO_TRANSPORTATION_REASON_DEAD_REQUIRED", transportation);
      addMapping("TRANSPORTATION_REFUSED_BY_PATIENT", transportation);
      addMapping("INVALID_TRANSPORTATION_OPTION", transportation);
      addMapping("NO_TRANSPORTATION_WITH_TEAM_ID_REQUIRED", "transmitted-with-team");
      addMapping("DISTANCE_REQUIRED", DISTANCE_FIELD);
      addMapping("DEPARTMENT_REQUIRED", "hospital-department");
      addMapping("TRAUMATOLOGY_DEPARTMENT_REQUIRED", "hospital-department");
      addMapping("PATIENT_TO_VEHICLE_REQUIRED", "patient-to-vehicle");
      addMapping("ROUTE_REQUIRED", "transported-from");
      addMapping("NUMBER_OF_PATIENTS_REQUIRED", "number-of-patients-transported");
      addMapping("HOSPITAL_SELECTION_CRITERIA_REQUIRED", "hospital-selection-criteria");
      addMapping("TRANSPORTATION_DISTANCE_INVALID", DISTANCE_FIELD);
      addMapping("ALV_APPLIED_REQUIRED", "alv-applied");
      addMapping("TRANSPORTED_FROM_NOTES_REQUIRED", "transported-from-notes");
      addMapping("TRANSPORTATION_NOTES_REQUIRED", "transportation-notes");
      addMapping("PATIENT_POSITION_REQUIRED", "patient-position"); // todo įhardcodintos taisyklės

      addMapping("transportationPositionEmpty", "transportationPosition");
      addMapping("transportationTeamEmpty", "dispositionTransmittedWithTeamId");
    },

    patientPageValidations(group = "patient") {
      const patientWeightField = "patient-weight";
      const patientAgeField = "patient-age";

      const addMapping = (rule, ...fields) => this.addMapping(rule, group, ...fields);

      addMapping("PATIENT_FIRST_NAME_REQUIRED", "patient-first-name");
      addMapping("INVALID_PATIENT_FIRST_NAME", "patient-first-name");
      addMapping("PATIENT_LAST_NAME_REQUIRED", "patient-last-name");
      addMapping("INVALID_PATIENT_LAST_NAME", "patient-last-name");
      addMapping("AGE_REQUIRED", patientAgeField);
      addMapping("PATIENT_ADDRESS_REQUIRED", "patient-address");
      addMapping("AGE_VALUE_INVALID", patientAgeField);
      addMapping("AGE_TYPE_REQUIRED", "patient-age-unit");
      addMapping("BIRTHDATE_INVALID", "patient-birth-date");
      addMapping("BIRTHDATE_REQUIRED", "patient-birth-date", patientAgeField);
      addMapping("SEX_REQUIRED", "patient-sex");
      addMapping("PATIENT_WEIGHT_REQUIRED", patientWeightField);
      addMapping("INVALID_PATIENT_WEIGHT", patientWeightField);
      addMapping("DISEASES_ALLERGY_NOTES_REQUIRED", "diseases-allergy-notes");
      addMapping("DISEASES_OTHER_NOTES_REQUIRED", "diseases-other-notes");
      addMapping("INVALID_FAMILY_DOCTOR_NAME", "patient-family-doctor");
      addMapping("PHYSICIAN_REQUIRED", "patient-family-doctor");
      addMapping("PHC_REQUIRED", "PHC");
      addMapping("HELP_REFUSED_FILLED", "patient-help-refused"); // todo įhardcodintos taisyklės

      addMapping("PhcEmpty", "PHC");
      addMapping("usedMedicineEmpty", "medicineConsumedByPatient");
      addMapping("specialCircumstancesEmpty", "patientSpecialCircumstances");
      addMapping("sideDiseasesEmpty", "patientSideDiseases");
    },

    medicalHelpSignaturesValidations(GROUP, MEDICAL_HELP_CONTAINER = "medical-help-agreement") {
      const patientSignatureName = "representative-signer-name";
      const representativeName = "representative-name";
      const signatureType = "signature-type";

      const fieldWithContainer = field => this.fieldWithContainer(MEDICAL_HELP_CONTAINER, field);

      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, GROUP, MEDICAL_HELP_CONTAINER, fieldWithContainer(field));

      addMappingWithContainer("SIGNATURE_TYPE_REQUIRED", signatureType);
      addMappingWithContainer("DEAD_PATIENT_CANNOT_SIGN_OR_REFUSE", signatureType);
      addMappingWithContainer("AGREEMENT_TO_RECEIVE_HELP_REQUIRED", "confirmation-to-receive-medical-help");
      addMappingWithContainer("AGREEMENT_TO_HOSPITAL_REQUIRED", "confirmation-to-hospital");
      addMappingWithContainer("PATIENT_SIGNATURE_REQUIRED", "signature");
      addMappingWithContainer("REPRESENTATIVE_NAME_REQUIRED", patientSignatureName);
      addMappingWithContainer("AGREEMENT_TO_SHARE_DATA_WITH_PERSON_REQUIRED", representativeName);
      addMappingWithContainer("AGREEMENT_TO_SHARE_DATA_CONFIRMATION_REQUIRED", "confirmation");
      addMappingWithContainer("INVALID_INFO_SHARE_SIGNATURE_SHARE_WITH_PERSON", representativeName);
      addMappingWithContainer("INVALID_MEDICAL_HELP_SIGNATURE_SIGNER_NAME", patientSignatureName);
    },

    teamLeaderSignatureValidations(GROUP, TEAM_LEADER_CONTAINER = "team-leader-signature") {
      const fieldWithContainer = field => this.fieldWithContainer(TEAM_LEADER_CONTAINER, field);

      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, GROUP, TEAM_LEADER_CONTAINER, fieldWithContainer(field));

      addMappingWithContainer("TEAM_LEADER_SIGNATURE_REQUIRED", "signature");
      addMappingWithContainer("TEAM_LEADER_SIGNATURE_NAME_REQUIRED", "name");
      addMappingWithContainer("INVALID_TEAM_LEADER_SIGNATURE_SIGNER_NAME", "name");
    },

    erPersonSignatureValidations(GROUP, ER_PERSON_CONTAINER = "er-person-signature") {
      const fieldWithContainer = field => this.fieldWithContainer(ER_PERSON_CONTAINER, field);

      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, GROUP, ER_PERSON_CONTAINER, fieldWithContainer(field));

      addMappingWithContainer("DOCTOR_SIGNATURE_REQUIRED", "signature");
      addMappingWithContainer("DOCTOR_SIGNATURE_NAME_REQUIRED", "name");
      addMappingWithContainer("INVALID_DOCTOR_SIGNATURE_SIGNER_NAME", "name");
    },

    narcoticDrugsVerificationValidations(GROUP, NARCOTIC_DRUGS_VERIFICATION_CONTAINER = "narcotic-drugs-verification") {
      const fieldWithContainer = field => this.fieldWithContainer(NARCOTIC_DRUGS_VERIFICATION_CONTAINER, field);

      const addMappingWithContainer = (rule, field) => this.addMappingWithContainer(rule, GROUP, NARCOTIC_DRUGS_VERIFICATION_CONTAINER, fieldWithContainer(field));

      addMappingWithContainer("NARCOTIC_DRUGS_VERIFICATION_SIGNATURE_REQUIRED", "signature");
      addMappingWithContainer("NARCOTIC_DRUGS_VERIFICATION_SIGNER_NAME_REQUIRED", "name");
      addMappingWithContainer("NARCOTIC_DRUGS_VERIFICATION_SIGNATURE_FILLED", "signature");
      addMappingWithContainer("NARCOTIC_DRUGS_VERIFICATION_SIGNER_NAME_FILLED", "name");
    },

    flowchartValidations(GROUP) {
      this.addMapping("PATIENT_TREATMENT_REQUIRED", GROUP, "treatment");
    },

    medicamentsValidations(medicamentTreatment, GROUP, container = "medicament") {
      const fieldWithContainer = field => this.fieldWithContainer(container, field);

      const addMappingWithContainer = (rule, field, container, match) => this.addMappingWithContainerAndMatch(rule, GROUP, container, match, fieldWithContainer(field));

      medicamentTreatment.forEach(({
        match
      }) => {
        const matchSelector = `[flowchart-id="medicament-${match}"]`;
        addMappingWithContainer("MEDICAMENT_TIME_REQUIRED", TIME_FIELD, matchSelector, match);
        addMappingWithContainer("MEDICAMENT_TIME_INVALID", TIME_FIELD, matchSelector, match);
        addMappingWithContainer("MEDICAMENT_CONDITION_CHANGE_REQUIRED", "condition-change", matchSelector, match);
        addMappingWithContainer("MEDICAMENT_TOTAL_REQUIRED", "total", matchSelector, match);
        addMappingWithContainer("MEDICAMENT_MEMBER_REQUIRED", "member", matchSelector, match);
        addMappingWithContainer("MEDICAMENT_USE_TYPE_REQUIRED", "use-type", matchSelector, match);
        addMappingWithContainer("MEDICAMENT_TIME_EARLIER_THAN_ARRIVAL", TIME_FIELD, matchSelector, match);
        addMappingWithContainer("MEDICAMENT_TIME_LATER_THAN_HOSPITAL", TIME_FIELD, matchSelector, match);
        addMappingWithContainer("MEDICAMENT_TIME_LATER_THAN_RETURNED", TIME_FIELD, matchSelector, match);
        addMappingWithContainer("MEDICAMENT_SIZE_INVALID", "size", matchSelector, match);
        addMappingWithContainer("MEDICAMENT_COMMENT_REQUIRED", "note", matchSelector, match);
      });
    },

    proceduresValidation(procedureTreatment, GROUP, container = "procedure") {
      const fieldWithContainer = field => this.fieldWithContainer(container, field);

      const addMappingWithContainer = (rule, field, container, match) => this.addMappingWithContainerAndMatch(rule, GROUP, container, match, fieldWithContainer(field));

      procedureTreatment.forEach(({
        match
      }) => {
        const matchSelector = `[flowchart-id="procedure-${match}"]`;
        addMappingWithContainer("PROCEDURE_TIME_REQUIRED", TIME_FIELD, matchSelector, match);
        addMappingWithContainer("PROCEDURE_TIME_INVALID", TIME_FIELD, matchSelector, match);
        addMappingWithContainer("PROCEDURE_SIZE_REQUIRED", "size", matchSelector, match);
        addMappingWithContainer("PROCEDURE_CONDITION_CHANGE_REQUIRED", "condition-change", matchSelector, match);
        addMappingWithContainer("PROCEDURE_TOTAL_REQUIRED", "total", matchSelector, match);
        addMappingWithContainer("PROCEDURE_MEMBER_REQUIRED", "member", matchSelector, match);
        addMappingWithContainer("PROCEDURE_TIME_EARLIER_THAN_ARRIVAL", TIME_FIELD, matchSelector, match);
        addMappingWithContainer("PROCEDURE_TIME_LATER_THAN_RETURNED", TIME_FIELD, matchSelector, match);
        addMappingWithContainer("PROCEDURE_TIME_LATER_THAN_HOSPITAL", TIME_FIELD, matchSelector, match);
      });
    },

    overridePatientTreatmentErrorMapper({
      medicamentTreatment,
      procedureTreatment
    }, flowchartGroup = "flowchart") {
      this.medicamentsValidations(medicamentTreatment, flowchartGroup);
      this.proceduresValidation(procedureTreatment, flowchartGroup);
    },

    overrideCprFormErrorMapper(deathStatement, group = "forms") {
      this.cprValidation(deathStatement, group);
    }

  });

  _exports.default = _default;
});