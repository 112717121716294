define("epcr-uis/helpers/duration-count", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.durationCount = durationCount;
  _exports.durationCountOnlyMinAndSec = durationCountOnlyMinAndSec;
  _exports.default = void 0;

  const dateFormatter = (timestamp, format, unit) => {
    const duration = _moment.default.utc(timestamp).format(format);

    return {
      duration,
      unit
    };
  };

  function durationCount([timestamp]) {
    const seconds = timestamp / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    if (hours >= 24) return {
      duration: `${hours}:${dateFormatter(timestamp, "mm:ss").duration}`,
      unit: "val."
    };else if (hours >= 1) return dateFormatter(timestamp, "HH:mm:ss", "val.");else if (minutes >= 1) return dateFormatter(timestamp, "mm:ss", "min.");else if (seconds >= 1) return dateFormatter(timestamp, "s", "s.");
    return {
      duration: null,
      unit: null
    };
  }

  function durationCountOnlyMinAndSec(timestamp) {
    if (timestamp) {
      const seconds = timestamp / 1000;
      const minutes = Math.floor(seconds / 60);

      if (minutes > 59) {
        const secondsWithoutHours = dateFormatter((seconds - minutes * 60) * 1000, "ss").duration;
        return `${minutes}:${secondsWithoutHours}`;
      }

      return dateFormatter(timestamp, "mm:ss").duration;
    }

    return null;
  }

  var _default = Ember.Helper.helper(durationCount);

  _exports.default = _default;
});