define("epcr-uis/pods/pcr/serializer", ["exports", "epcr-uis/config/environment", "epcr-uis/pods/pcr/serializer-backend", "epcr-uis/pods/pcr/serializer-mirage"], function (_exports, _environment, _serializerBackend, _serializerMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PcrSerializerImplementation = () => {
    return _environment.default["ember-cli-mirage"].enabled ? _serializerMirage.default : _serializerBackend.default;
  };

  var _default = PcrSerializerImplementation().extend();

  _exports.default = _default;
});