define("epcr-uis/pods/pcr-route-tracker/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    lastVisitedPcrList: null,
    currentRoute: null,
    routeArguments: null,
    onRouteChange: Ember.observer("currentRoute", function () {
      this.trigger("routeChanged");
      this.currentRoute.startsWith("pcr.") && this.closeOpenedSidebar();
    }),

    closeOpenedSidebar() {
      const elementsToClose = [".pcr-routes .visible .sidebar-background", ".center-modal:visible .cancel"];
      elementsToClose.forEach(element => $(document).find(element).click());
    }

  });

  _exports.default = _default;
});