define("epcr-uis/pods/components/day-night-switcher/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jYI9/aNF",
    "block": "{\"symbols\":[\"ControlToggle\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"config/control-toggle\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@title\",\"@featureName\",\"@iconOn\",\"@iconOff\",\"@onChange\"],[[28,\"t\",[\"config.nightMode\"],null],\"nightMode\",\"icon moon\",\"icon ban\",[28,\"action\",[[23,0,[]],\"switcher\"],null]]],{\"statements\":[[0,\"\\n    \"],[14,2],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/day-night-switcher/template.hbs"
    }
  });

  _exports.default = _default;
});