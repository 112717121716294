define("epcr-uis/translations/lt-lt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "actions": {
      "add": "Pridėti",
      "addAttachment": "Pridėti nuotrauką",
      "attachments": "Kortelės nuotraukos",
      "backToNews": "Grįžti atgal",
      "cancel": "Atšaukti",
      "change": "Keisti",
      "choosePainLocation": "Sužalojimų/Skausmo vietą",
      "clear": "Išvalyti",
      "close": "Uždaryti",
      "copy": "Kopijuoti",
      "delete": "Ištrinti",
      "fill": "Pildyti",
      "fillTeam": "Užpildyti komandą",
      "filter": "Filtruoti",
      "importData": "Įkelti duomenis",
      "installUpdates": "Atnaujinti",
      "leavePcr": "Uždaryti kortelę",
      "login": "Prisijungti",
      "logout": "Atsijungti",
      "map": "Žemėlapis",
      "muteSound": "Nutildyti",
      "no": "Ne",
      "ok": "Gerai",
      "printEmptyPcrPdf": "Neužpildyta kortelė",
      "reload": "Perkrauti",
      "remove": "Panaikinti",
      "retry": "Bandyti dar kartą",
      "return": "Grįžti atgal",
      "save": "Išsaugoti",
      "saveAndExit": "Išsaugoti ir uždaryti",
      "saveAndFinish": "Išsaugoti ir baigti",
      "saveComment": "Išsaugoti komentarą",
      "saveTeam": "Atsiminti sudėtį",
      "searchInWholeLithuania": "Ieškoti visoje Lietuvoje",
      "selectUnknown": "Nežinoma",
      "setLungsNormal": "Normalus",
      "setReacts": "Reaguoja",
      "setSkinNormal": "Normali",
      "settings": "Nustatymai",
      "showNews": "Naujienos",
      "support": "Pagalba",
      "tryAgain": "Pabandykite dar kartą",
      "validate": "Tikrinti",
      "view": "Peržiūrėti",
      "yes": "Taip"
    },
    "activationProtocol": {
      "default": {
        "2": "Alerginės reakcijos"
      }
    },
    "components": {
      "copySection": "Kopijuoti",
      "dateFormat": "MMMM-MM-DD",
      "enterSearchQuery": "Įveskite paieškos užklausą",
      "general": {
        "now": "Dabar",
        "years": "m."
      },
      "letterFilter": {
        "all": "Visi"
      },
      "loading": "Kraunama",
      "medicaments": {
        "other": "Kita",
        "unknown": "Nežinoma"
      },
      "notChosen": "Nepasirinkta",
      "signature": {
        "actions": {
          "clear": "Išvalyti",
          "sign": "Pasirašyti",
          "signNew": "Pasirašysiu iš naujo"
        },
        "confirmDelete": "Panaikinti",
        "confirmationToDelete": "Ar tikrai norite panaikinti parašą?",
        "declineDelete": "Atšaukti"
      },
      "warnings": {
        "emptyValue": "Tuščia reikšmė",
        "invalidDate": "Neteisinga data",
        "invalidNumber": "Neteisingas skaičius",
        "invalidPhoneNumber": "Neteisingas telefono numeris",
        "invalidTime": "Neteisingas laikas",
        "numberTooHigh": "Skaičius yra per didelis",
        "numberWithComma": "Neteisingas skaičius, skirtukui naudokite tašką"
      }
    },
    "config": {
      "brightness": "Ekrano ryškumas",
      "brightnessExplanation": "Keisti ekrano apšvietimo intensyvumą",
      "flashlight": "Galimybė įjungti žibintuvėlį",
      "nightMode": "Nakties režimas",
      "nightModeExplanation": "Keisti režimus tarp dieninio ir naktinio",
      "print": "Spausdinimo nustatymai",
      "rapidInputMode": "Automatinis perėjimas tarp laukų",
      "rapidInputModeExplanation": "Užpildžius būklės vertinimo arba transportavimo lauką, įvyks automatinis perėjimas į sekantį lauką",
      "relatedProcedures": "Vaiste siūlomos susijusios procedūros",
      "relatedProceduresExplanation": "Pridedant vaistą kartu galima pasirinkti ir su juo susijusią procedūrą",
      "soundOnClick": "Paspaudimo garsas",
      "soundOnClickExplanation": "Skleisti garsą, kai paliečiamas planšetės ekranas",
      "timelines": "Laikų juostos",
      "timelinesExplanation": "Gydymo skiltyje rodyti laikus"
    },
    "errors": {
      "ABSENT_CALL_TIME": "Užpildykite kvietimo laiką",
      "ABSENT_CATEGORY": "Pasirinkite kategoriją",
      "ABSENT_EVENT_ID": "Užpildykite įvykio id",
      "ABSENT_MISSION_ID": "Užpildykite misijos numerį",
      "ABSENT_PATIENT_ID": "Užpildykite paciento numerį",
      "ACCESS_DENIED": "Prieiga prie šios kortelės yra ribojama",
      "EMPTY_QUERY": "Įveskite paieškos frazę",
      "FILE_TOO_LARGE": "Per didelis failas",
      "FILE_TYPE_IS_NOT_SUPPORTED": "Netinkamas failo tipas. Įkelti galima tik nuotraukas.",
      "INCORRECT_CREDENTIALS": "Neteisingi prisijungimo duomenys",
      "INSUFFICIENT_PERMISSIONS": "Jūs neturite prieigos prie šios sistemos",
      "INVALID_EVENT_ID": "Netinkamas kvietimo id",
      "NOT_FOUND": "Įrašas nerastas",
      "NO_INTERNET_CONNECTION": "Nėra interneto",
      "NO_RESULTS": "Rezultatai nerasti pagal pateiktą paieškos frazę",
      "NO_STREET_IN_COUNTY": "{county} tokios gatvės neturi",
      "PATIENT_FIRST_NAME_REQUIRED": "Neįvestas paciento vardas",
      "PATIENT_LAST_NAME_REQUIRED": "Neįvesta paciento pavardė",
      "PATIENT_WEIGHT_REQUIRED": "Neįvestas paciento svoris",
      "PCR_DATA_LIMIT_EXCEEDED": "Pasiektas kortelės failų duomenų limitas",
      "PCR_STATE_DOES_NOT_MATCH": "Nepavyko išsaugoti, nes kortelė buvo užbaigta kito vartotojo",
      "QUERY_IS_TOO_SHORT": "Įveskite ilgesnę paieškos frazę",
      "RESOURCE_ALREADY_EXISTS": "Negalima sukurti kortelės su šiuo id, nes ji jau egzistuoja",
      "SEX_REQUIRED": "Nepasirinkta lytis",
      "TOO_MANY_RESULTS": "Rezultatų rasta daugiau nei galima atvaizduoti. Patikslinkite paieškos frazę.",
      "UNSAVED_PCR": "Kortelė turi būti išsaugota prieš spausdinimą",
      "failedToLoadConfig": "Nepavyko užkrauti nustatymų",
      "general": "Įvyko klaida",
      "loadingUnsuccessful": "Nepavyko užkrauti duomenų",
      "loginError": "Prisijungimo klaida",
      "null": "Įvyko klaida",
      "oldBrowser": "Jūs naudojatės sena naršyklės versija. Atsinaujinkite naršyklę, kad sistema veiktų be sutrikimų.",
      "patientAssessment": {
        "empty": "Paciento būklė neužpildyta"
      },
      "unableToLogin": "Nepavyko prisijungti"
    },
    "general": {
      "floatingPartSeperator": ",",
      "hours": "val.",
      "kg": "kg.",
      "lessThan": "iki",
      "minutes": "min",
      "months": "mėn.",
      "moreThan": "nuo",
      "seconds": "s.",
      "timesPerMin": "k/min",
      "units": "vnt.",
      "weeks": "sav.",
      "years": "m."
    },
    "headings": {
      "activePcrs": {
        "changeTeam": {
          "error": {
            "message": "Komandą keisti leidžiama tik vieną kartą. Prisijunkite iš naujo, jei norite pasirinkti kitą komandą.",
            "title": "Komandos keitimas negalimas"
          }
        },
        "chooseTeam": "Pasirinkite komandą",
        "createNewPcr": "Sukurti naują kortelę",
        "submit": "Rinktis"
      },
      "attachment": {
        "description": "Prie kortelės galite pridėti naujų nuotraukų",
        "title": "Kortelės nuotraukos"
      },
      "comments": "Komentarai",
      "diagnosis": {
        "diagnosisTitle": "Diagnozė"
      },
      "event": {
        "additionalInfo": "Papildoma informacija",
        "changeTimeTo": "Keisti į",
        "comment": "Komentaras",
        "delayReasons": "Vėlavimo priežastys",
        "pcr": "Kvietimo kortelė",
        "team": "Komanda",
        "times": "Laikai"
      },
      "filter": {
        "filterTitle": "Rinktis komandą",
        "search": "Ieškoti",
        "shiftDuration": "Pamainos trukmė",
        "team": "Komanda"
      },
      "flowchart": {
        "relatedProcedure": "Susijusi procedūra"
      },
      "forms": {
        "acuteCoronarySyndrome": "Ūmus koronarinis sindromas",
        "autoAccident": "Eismo įvykis",
        "childbirth": "Gimdymas",
        "clinicalDeath": "Klinikinė mirtis",
        "deathStatement": "Mirties konstatavimas",
        "eventDescription": "Įvykio aprašymas",
        "informationTransfer": "Informacijos perdavimas",
        "stroke": "Insultas",
        "trauma": "Trauma"
      },
      "listMenu": {
        "active": "Aktyvios",
        "forms": "kortelės",
        "header": "Pasirinkimas",
        "unfinished": "Nebaigtos",
        "usedMedicaments": "Sunaudoti vaistai",
        "verified": "Užbaigtos"
      },
      "login": {
        "password": "Slaptažodis",
        "username": "Vartotojo vardas"
      },
      "news": "Naujienos",
      "patientAssessments": {
        "afterTreatment": "Po MP",
        "atER": "SPS",
        "avpu": "AVPU",
        "beforeTreatment": "Iki MP",
        "breathing": "Kvėpavimas",
        "circulation": "Kraujotaka",
        "consciousness": "Sąmonė",
        "deleteConfirmation": "Ar tikrai norite panaikinti būklės vertinimą?",
        "ecg": "EKG",
        "eyes": "Vyzdžiai",
        "gcs": "GKS",
        "glucose": "Gliukozė",
        "pain": "Skausmas",
        "painLocation": "Sužalojimų/Skausmo vieta",
        "secondaryDeleteTitle": "Ar tikrai norite ištrinti?",
        "skin": "Oda",
        "stSegment": "ST segmentas",
        "temperature": "Temperatūra"
      },
      "patientDetails": {
        "absent": "Nebuvo",
        "additionalInformation": "Papildoma informacija",
        "helpRejected": "Atsisakė pagalbos",
        "invalidPid": "Neteisingas asmens kodas",
        "known": "Žinomas",
        "notFound": "Nerastas",
        "patientData": "Pacientas",
        "unknown": "Nežinomas"
      },
      "print": {
        "emptyForm": "Neužpildytos kortelės spausdinimas",
        "form": "Kortelės spausdinimas ({pcrId})"
      },
      "signatures": {
        "hospitalDoctor": "Priėmęs į ligoninę asmuo",
        "narcoticDrugs": "Narkotinių medž. skyrimo patvirtinimas",
        "patient": "Pacientas",
        "teamLeader": "Komandos vadovas"
      },
      "summary": {
        "diagnosis": "Diagnozė",
        "event": "Įvykis",
        "forms": "Formos",
        "injuries": "Sužeidimai",
        "patient": "Pacientas",
        "signatures": "Parašai",
        "title": "Suvestinė",
        "transportation": "Transportavimas",
        "treatment": "Gydymas",
        "unknown": "Nežinoma",
        "vitals": "Būklė"
      },
      "tools": {
        "apgarCalc": {
          "title": "APGAR skaičiuoklė"
        },
        "doseCalc": {
          "selectWeight": "Pasirinkite svorį",
          "title": "Vaistų dozavimas vaikams"
        },
        "pediCalc": {
          "fillAge": "Pasirinkite amžių",
          "title": "Pediatrinė skaičiuoklė"
        },
        "title": "Įrankiai"
      },
      "training": {
        "goToTraining": "Pridėti mokymų kortelę",
        "scenarios": {
          "1": "Nerastas pacientas",
          "2": "Nebendradarbiaujantis pacientas",
          "3": "Mirties konstatavimas",
          "4": "Pervežimas",
          "5": "Ūmus koronarinis sindromas",
          "6": "Insultas",
          "7": "Gimdymas",
          "8": "Autoįvykis. Mirtis",
          "9": "Nelaimingas atsitikimas"
        },
        "title": "Treniruočių režimas. Pasirinkite scenarijų norėdami išbandyti sistemą."
      },
      "trainingMode": "MOKYMOSI REŽIMAS",
      "transportation": {
        "additionalInfo": "Papildoma informacija",
        "toHospital": "Į ligoninę",
        "transfer": "Perduotas",
        "transportationTitle": "Transportavimas",
        "transportedTo": "Transportavimo vieta"
      },
      "usedMedicaments": {
        "chooseRange": "Pasirinkite pamainos trukmę",
        "submit": "Rinktis"
      },
      "warnings": {
        "unsavedPcr": "Kortelė yra neišsaugota. Ar tikrai norite uždaryti šią kortelę?"
      }
    },
    "message": {
      "abp": {
        "optimal": "Optimalus",
        "title": "Sistolinio AKS rėžiai"
      },
      "addTreatment": "Pridėkite naują vaistą ar procedūrą",
      "cannotSavePcr": "Negalima išsaugoti kortelės",
      "cantChangePcrLater": "Išsaugojus ir pabaigus kortelės pildymą šios kortelės nebus galima keisti. Ar tikrai norite baigti kortelės pildymą ir perduoti patikrinimui?",
      "cantFinishOnlySave": "Kortelės pildymą galima užbaigti tik ištaisius klaidas. Dabar galima tik išsaugoti duomenis.",
      "chooseFieldsToFill": "Pasirinkite, kurios kortelės duomenis įkelti",
      "currentState": "Kortelės būsena yra",
      "endOfList": "Sąrašo pabaiga",
      "finishedPcrs": "Visos jūsų kortelės baigtos pildyti",
      "glucose": {
        "diabetes": "Sergančiam CD",
        "norm": "Gliukozės norma",
        "normal": "Normali"
      },
      "lastTimeUpdated": "Duomenys paskutinį kartą atnaujinti",
      "loading": "Kraunama",
      "newsArticleNotFound": "Naujienų nerasta",
      "newsNotFound": "Naujienų nerasta",
      "noActivePcrs": "Nėra aktyvių kortelių",
      "noErrors": "Klaidų nėra",
      "noNeedToFill": "Šios skilties pildyti nereikia",
      "noPcrsFound": "Kortelių nerasta",
      "noRecommendations": "Pagal suvestus duomenis gydymo protokolų nerasta",
      "noTeamMembers": "Nėra komandos narių",
      "noUpdatesFound": "Atnaujinimų nėra",
      "noVerifiedPcrs": "Nėra užbaigtų kortelių",
      "normalBodyTemp": "Normali kūno temp.",
      "patient": {
        "ABSENT": "Paciento nebuvo",
        "NOT_FOUND": "Pacientas nerastas"
      },
      "pcrContainsErrors": "Kortelėje yra pildymo klaidų",
      "pcrSaved": "Kortelės duomenys išsaugoti",
      "pcrsUpdated": "Kortelių sąrašas atnaujintas",
      "photoRemovalConfirmation": "Ar tikrai norite ištrinti nuotrauką?",
      "popularSelections": "Populiarūs pasirinkimai",
      "pulseRanges": "Pulso rėžiai",
      "recommendations": "Gydymo protokolai",
      "removalConfirmation": "Ar tikrai norite panaikinti įrašą?",
      "respiratoryRateRanges": "Kvėpavimo dažnio rėžiai",
      "savePcrBeforeClose": "Yra neišsaugotų pakeitimų. Ar norite juos išsaugoti?",
      "spO2": {
        "critical": "Kritinis",
        "low": "Žemas",
        "normal": "Normalus",
        "title": "SpO₂ rėžiai"
      },
      "temperatureRanges": "Temperatūros rėžiai",
      "unReadNewsDescription": "Jūs turite {numUnreadNews} {numUnreadNews, plural, =1 {neperskaitytą naujieną} few {neperskaitytas naujienas} other {neperskaitytų naujienų}}.",
      "updateWarning": "Diegiant naujinimus Jūs negalėsite naudotis programa. Atnaujinimas gali užtrukti iki 5 minučių.",
      "weightRanges": "Svorio rėžiai"
    },
    "moment": {
      "relativeTime": {
        "M": "1 mėn.",
        "MM": "%d mėn.",
        "d": "1 d.",
        "dd": "%d d.",
        "future": "po %s",
        "h": "1 val.",
        "hh": "%d val.",
        "m": "1 min.",
        "mm": "%d min.",
        "past": "%s prieš",
        "s": "1 s.",
        "ss": "%d 1s.",
        "y": "1 m.",
        "yy": "%d m."
      }
    },
    "navigation": {
      "event": "Įvykis",
      "flowchart": "Gydymas",
      "forms": "Formos",
      "narrative": "Diagnozė",
      "patient": "Pacientas",
      "signatures": "Parašai",
      "summary": {
        "errors": "Klaidos",
        "print": "Spausdinimas",
        "save": "Išsaugojimas"
      },
      "top": {
        "patient": "Pacientas",
        "unknown": "Nežinomas"
      },
      "transportation": "Transportas",
      "unknown": "Nežinoma",
      "vitals": "Būklė"
    },
    "notifications": {
      "description": {
        "notTransported": "Pasirinkite kitokį transportavimo tipą, jei norite įvesti transportavimo vietą"
      },
      "pcrSavedSuccessfully": "Kortelės duomenys sėkmingai išsaugoti!",
      "title": {
        "notTransported": "Pacientas nebuvo transportuotas"
      }
    },
    "options": {
      "counties": {
        "alytus": "Alytaus apskritis",
        "kaunas": "Kauno apskritis",
        "klaipeda": "Klaipėdos apskritis",
        "marijampole": "Marijampolės apskritis",
        "panevezys": "Panevėžio apskritis",
        "siauliai": "Šiaulių apskritis",
        "taurage": "Tauragės apskritis",
        "telsiai": "Telšių apskritis",
        "utena": "Utenos apskritis",
        "vilnius": "Vilniaus apskritis"
      },
      "deathSigns": {
        "bodyFrozen": "Lavoninis sustingimas",
        "deadlyInjuries": "Su gyvybe nesuderinamas sužalojimas",
        "deathMarks": "Lavondėmės",
        "decomposition": "Irimas"
      },
      "delayReasons": {
        "brokenVehicle": "Transporto gedimas",
        "crowd": "Minia",
        "dangerousSiteArea": "Nesaugi įvykio vieta",
        "difficultAccessToBuilding": "Sud. priėj. prie pastato",
        "difficultAccessToSite": "Sud. priėj. prie įv. vietos",
        "dispatcher": "Dispečerinė",
        "distanceToPatient": "Atstumas iki paciento",
        "elevator": "Liftas",
        "helpToTeam": "Pagalba komandai",
        "lockedDoor": "Durų užraktas",
        "rescuing": "Išlaisvinimas",
        "traffic": "Eismas",
        "waitingForEscort": "Palydos laukimas",
        "weather": "Oro sąlygos",
        "wrongAddress": "Neteisingas adresas"
      },
      "diagnosis": {
        "disorderType": {
          "accident": "Nelaimingas atsitikimas",
          "acuteIllness": "Ūmi liga"
        },
        "helpProtocol": {
          "abdominal": "Pilvo skausmas",
          "aicd": "Širdies veiklos sutrikimai / IKD",
          "allergies": "Alerginės reakcijos",
          "animal": "Gyvūnų įkandimai / Užpuolimai",
          "assault": "Užpuolimas / Seksualinė prievarta",
          "back": "Nugaros skausmas (netrauminis arba sena trauma)",
          "bleeding": "Kraujavimas / Plėštinės žaizdos",
          "breathing": "Kvėpavimo problemos",
          "burn": "Nudegimai (nu(si)plikymai) / Sprogimai",
          "chest": "Krūtinės skausmas (netrauminis)",
          "choking": "Springimas",
          "death": "Širdies ar kvėpavimo sustojimas / Staigi mirtis",
          "diabetic": "Diabeto komplikacijos",
          "drowning": "Skendimas (panirimas) / Nėrimas",
          "eye": "Akių sužalojimai",
          "fall": "Kritimas iš aukščio",
          "fluPandemic": "Pandemija / Epidemija / Protrūkis",
          "headache": "Galvos skausmas",
          "heatOrCold": "Karščio / Šalčio poveikis",
          "inhalation": "Inhaliacinis apsinuodijimas",
          "lightning": "Elektros trauma / Žaibas",
          "overdose": "Perdozavimas / Apsinuodijimas (peroralinis)",
          "penetrating": "Durtiniai / Šautiniai / Kiauryminiai sužalojimai",
          "pregnancy": "Nėštumas / Gimdymas / Persileidimas",
          "psychiatric": "Psichikos ir elgesio sutrikimai / Bandymas nusižudyti",
          "seizures": "Traukuliai",
          "shortAbdominal": "Pilvo skausmas",
          "shortAicd": "Širdies veiklos sutrikimai",
          "shortAllergies": "Alerginės reakcijos",
          "shortAnimal": "Gyvūnų įkandimai",
          "shortAssault": "Užpuolimas",
          "shortBack": "Nugaros skausmas",
          "shortBleeding": "Kraujavimas",
          "shortBreathing": "Kvėpavimo problemos",
          "shortBurn": "Nudegimai",
          "shortChest": "Krūtinės skausmas",
          "shortChoking": "Springimas",
          "shortDeath": "Širdies sustojimas",
          "shortDiabetic": "Diabeto komplikacijos",
          "shortDrowning": "Skendimas",
          "shortEye": "Akių sužalojimai",
          "shortFall": "Kritimas iš aukščio",
          "shortFluPandemic": "Pandemija",
          "shortHeadache": "Galvos skausmas",
          "shortHeatOrCold": "Karštis šaltis",
          "shortInhalation": "Inhaliacinis apsinuodijimas",
          "shortLightning": "Elektros trauma",
          "shortOverdose": "Perdozavimas",
          "shortPenetrating": "Durtiniai sužalojimai",
          "shortPregnancy": "Nėštumas",
          "shortPsychiatric": "Psichikos sutrikimai",
          "shortSeizures": "Traukuliai",
          "shortSick": "Negaluojantis žmogus",
          "shortStroke": "Insultas",
          "shortTransfer": "Pervežimas",
          "shortTransportation": "Eismo nelaimės",
          "shortTrapped": "Įstrigimas",
          "shortTraumatic": "Trauminiai sužalojimai",
          "shortUnconscious": "Sąmonės netekimas",
          "shortUnknown": "Neaiški problema",
          "sick": "Negaluojantis žmogus (žinomos diagnozės)",
          "stroke": "Insultas",
          "transfer": "Pervežimas",
          "transportation": "Eismo / Transporto nelaimės",
          "trapped": "Nelaimingas atsitikimas neprieinamoje vietoje/Įstrigimas",
          "traumatic": "Trauminiai sužalojimai (specifiniai)",
          "unconscious": "Sąmonės netekimas / (Pre)sinkopė",
          "unknown": "Neaiški problema (nukritęs žmogus)"
        }
      },
      "event": {
        "cancelReason": {
          "declinedByDispatcher": "Atsakyta (neišvykta)",
          "missionAborted": "Kvietimas atšauktas",
          "patientNotFound": "Pacientas nerastas"
        },
        "category": {
          "1": "1",
          "2": "2",
          "3": "3",
          "4": "4"
        },
        "deliveryType": {
          "direct": "Tiesiogiai",
          "viaPhone": "Per telefoną",
          "viaRadio": "Per radiją"
        }
      },
      "forms": {
        "accident": {
          "airbags": {
            "driver": "Vairuotojo durelių",
            "other": "Kitos",
            "passenger": "Keleivio",
            "passengerDoor": "Keleivio durelių",
            "title": "Išsiskleidusios oro pagalvės",
            "wheel": "Vairo"
          },
          "seatBelts": {
            "shoulder": "Diržas per petį",
            "stomach": "Pilvinė dalis",
            "title": "Saugos diržai"
          },
          "sittingPlace": {
            "back": "Galinėje sėdynėje",
            "driver": "Vairuotojo sėdynėje",
            "passenger": "Keleivio sėdynėje",
            "title": "Sėdėjimo vieta"
          }
        },
        "childbirth": {
          "apgar1": "APGAR pradinis",
          "apgar5": "APGAR po 5 min",
          "baby": "Gimęs naujagimis",
          "birthTime": "Naujagimio gimimo laikas",
          "bleeding": "Stiprus kraujavimas",
          "contractions": "Sąrėmiai kartojasi kas",
          "duration": "Nėštumo trukmė",
          "eclampsia": "Eklampsija",
          "pathology": "Pogimdyvinė patologija",
          "placenta": "Gimusi placenta",
          "tones": "Vaisiaus širdies tonai",
          "watersBroken": "Nubėgę vaisiaus vandenys"
        },
        "clinicalDeath": {
          "aedUsed": "Panaudotas AID iki GMP atvykimo",
          "aedUsedBy": "AID iki GMP atvykimo panaudojo",
          "ambulance": "GMP komanda",
          "bloodFlowRestored": "Spontaninę kraujotaką atkūrė",
          "cprPerformed": "Prad. gaivinimas iki GMP atvykimo atliktas",
          "cprPerformedBy": "Pradinį gaivinimą iki GMP atvykimo atliko",
          "family": "Šeimos nariai",
          "fireService": "PGT",
          "medPersonnel": "Med. person.",
          "minsSinceCprEnd": "Min. nuo PG pabaigos iki GMP atvykimo",
          "minsSinceCprStart": "Min. nuo PG pradžios iki GMP atvykimo",
          "minsSinceDeath": "Min. nuo staigios mirties iki GMP atvykimo",
          "minsSinceInitAed": "Min. nuo pirmojo AID imp. iki GMP atvykimo",
          "no": "Ne",
          "officer": "Policijos pareigūnas",
          "other": "Kiti",
          "time": "Klinikinės mirties laikas",
          "witness": "Liudininkas",
          "witnesses": "Liudininkai",
          "yes": "Taip"
        },
        "coronary": {
          "contraindication": {
            "1": "1. Išeminis insultas pastarųjų 6 mėnesių laikotarpiu",
            "10": "10. Rezistentiškas dAKS > 110 mmHg",
            "11": "11. Užsitęsęs arba traumatinis gaivinimas",
            "12": "12. Nėštumas ar 1 savaitė po gimdymo",
            "13": "13. Peroralinė antikoaguliantų terapija",
            "14": "14. Tranzitorinė išeminė ataka pastarųjų 6 mėnesių laikotarpiu",
            "15": "15. Infekcinis endokarditas",
            "16": "16. Aktyvi peptinė opa",
            "17": "17. Pažengusi kepenų liga",
            "18": "18. Kontraindikacijų trombolizei nėra",
            "19": "19. Nežinoma (negalima surinkti anamnezės dėl paciento būklės)",
            "2": "2. Hemoraginis insultas arba nežinomos etiologijos insultas",
            "3": "3. Nesena masyvi trauma / operacija / galvos sužeidimas per pastarąsias 3 savaites",
            "4": "4. Žinomi krešumo sutrikimai",
            "5": "5. Centrinės nervų sistemos trauma/ navikas/ atrioventrikulinė malformacija",
            "6": "6. Gastrointestinalinis kraujavimas pastarojo mėnesio laikotarpiu",
            "7": "7. Įtariama aortos disekacija",
            "8": "8. Spaudimo būdu nestabdoma galimo kraujavimo vieta po punkcijos per paskutines 24 val. (pvz. kepenų biopsija, lumbalinė punkcija)",
            "9": "9. Rezistentiškas sAKS > 180 mmHg",
            "title": "Kontraindikacija"
          },
          "exactTime": "Tikslus skausmo pradžios laikas",
          "lbbb": "KHPKB nauja",
          "st": "ST pakilimas",
          "time": "Skausmo pradžios laikas"
        },
        "deathStatement": {
          "signs": "Neabejotini mirties požymiai",
          "time": "Mirties laikas"
        },
        "erNotified": "Pranešta SPS",
        "informationTransfer": {
          "additional": "Papildoma informacija",
          "other": "Kitai tarnybai",
          "phc": "PASPĮ",
          "police": "Policijai"
        },
        "stroke": {
          "armWeakness": "Rankos(-ų) silpnumas",
          "contraindication": {
            "1": "1. Simptomų pradžios laikas iki trombolizės pradžios viršija 4,5 val.",
            "2": "2. Iki insulto pradžios ligonis buvo nesavarankiškas (slaugomas)",
            "3": "3. Kraujavimas iš virškinamojo trakto per pastaruosius 3 mėn.",
            "4": "4. Kraujavimas iš šlapimo takų per pastaruosius 3 mėn.",
            "5": "5. Didelės apimties chirurginė operacija per pastaruosius 3 mėn.",
            "6": "6. Intracerebrinė kraujosrūva ar sunki galvos trauma per pastaruosius 3 mėn.",
            "7": "7. Išplitęs onkologinis procesas su padidinta kraujavimo rizika",
            "8": "8. Kepenų liga su funkcijos nepakankamumu (HB / HC)",
            "9": "9. Nėštumas arba gimdymas per paskutines 10 parų",
            "title": "Kontraindikacija"
          },
          "exactTime": "Tikslus simptomų pradžios laikas",
          "faceParalysis": "Veido paralyžius",
          "speechDisorder": "Kalbos sutrikimas",
          "time": "Laikas"
        },
        "trauma": {
          "abcd": {
            "bpLt90": "Sistolinis AKS < 90 mmHg",
            "breathingFreqGt30OrLt8": "KD > 30 arba < 8 k/min.",
            "gcsLt9": "Koma pagal GKS ≤ 9 balai",
            "heartRateGt120": "ŠSD > 120 k/min.",
            "spo2Lt90": "SpO₂ < 90% tiekiant deguonį",
            "title": "ABCD vertinimas"
          },
          "circumstance": {
            "deadPplInCar": "Toje pačioje mašinoje yra žuvusių žmonių",
            "explosionOrFiring": "Sprogimas ar susišaudymas",
            "fallOrDiving": "Kritimas daugiau nei iš 3 m. aukščio ar nardymas",
            "pedestrianOrDriver": "Nukentėjo kaip pėstysis, dviratininkas, motocikl., važnyčiotojas",
            "rescuersAssistance": "Vaduojant nukentėjusįjį reikėjo gelbėtojų pagalbos",
            "title": "Traumos aplinkybės"
          },
          "injury": {
            "amputation": "Galūnių amputacija aukščiau plaštakų ar pėdų",
            "burn": "Kvėpavimo takų ar kūno nudegimas > 18%",
            "chest": "Nestabili krūtinės ląsta",
            "fracture": "Mažiausiai dviejų ilgųjų kaulų lūžiai",
            "neurological": "Akivaizdi neurologinė simptomatika",
            "pelvis": "Nestabilus dubuo",
            "puncture": "Kiauriniai kaklo, krūtinės, juosmens",
            "title": "Sužalojimai"
          },
          "safetyMeasures": {
            "clothing": "Apsauginė apranga",
            "equipment": "Apsauginė įranga",
            "eye": "Akių apsauga",
            "helmet": "Šalmas",
            "title": "Saugumo priemonės"
          }
        }
      },
      "knownDiseases": {
        "allergy": "Alergija",
        "asthma": "Astma",
        "ca": "Ca",
        "copd": "LOPL",
        "diabetes": "CD",
        "hypertension": "Hipertenzija",
        "ihd": "IŠL (MI)",
        "other": "Kita",
        "rhythmDisorder": "Lėt. ritmo sutr.",
        "tbc": "TBC"
      },
      "patientAssessments": {
        "avpu": {
          "a": "Budrus",
          "p": "Reaguoja į skausmą",
          "u": "Nereaguoja",
          "v": "Reaguoja į balsą"
        },
        "breathing": {
          "irregular": "Nereguliarus",
          "normal": "Normalus",
          "shortness": "Pasunkėjęs",
          "superficial": "Paviršutiniškas"
        },
        "ecg": {
          "rhythm": {
            "artifact": "Artefaktai EKG",
            "asystole": "Asistolija",
            "atrialFibrillation": "Prieširdžių virpėjimas",
            "avBlock": "AV blokada",
            "emd": "EMD",
            "nsr": "Normalus sinusinis ritmas",
            "pacemakerFalse": "Neefektyvi stimuliatoriaus veikla",
            "pacemakerTrue": "Efektyvi stimuliatoriaus veikla",
            "pvc": "Ekstrasistolės",
            "sinusBradycardia": "Sinusinė bradikardija",
            "sinusDysrhythmia": "Sinusinė aritmija",
            "sinusTachycardia": "Sinusinė tachikardija",
            "svt": "Supraventrikulinė tachikardija",
            "vFib": "Skilvelių virpėjimas",
            "ventricularTachycardia": "Skilvelinė tachikardija"
          }
        },
        "eyesCondition": {
          "dilated": "Išsiplėtę",
          "doesNotRespond": "Nereaguoja",
          "inert": "Inertiški",
          "responds": "Reaguoja",
          "shriveled": "Susitraukę"
        },
        "gcs": {
          "eye": {
            "none": "Neatsimerkia",
            "spontaneous": "Savaime",
            "toPain": "Sukeliant skausmą",
            "toVoice": "Kalbinant"
          },
          "motor": {
            "extensionPain": "Į skausmą reaguoja galūnės ištiesimu",
            "flexionPain": "Į skausmą reaguoja galūnės sulenkimu",
            "localizesPain": "Nurodo, kur skauda/Atsitraukia nuo prisilietimo",
            "none": "Nejuda",
            "obeysCommands": "Vykdo paliepimus/Normalūs spontaniniai judesiai",
            "withdrawPain": "Atsitraukia nuo skausminio dirgilio"
          },
          "verbal": {
            "confused": "Sutrikęs/Sudirgęs, verkia",
            "inappropriate": "Nerišlūs žodžiai/Verkia sukeliant skausmą",
            "incomprehensible": "Nesuprantami garsai/Dejuoja sukeliant skausmą",
            "none": "Nekalba/Garsinės išraiškos nėra",
            "oriented": "Orientuotas/Guguoja"
          }
        },
        "injury": {
          "abdomen": "Pilvas",
          "back": "Nugara",
          "chest": "Krūtinė",
          "face": "Veidas",
          "head": "Galva",
          "leftArm": "Kairė ranka",
          "leftLeg": "Kairė koja",
          "neck": "Kaklas",
          "pelvis": "Dubuo",
          "rightArm": "Dešinė ranka",
          "rightLeg": "Dešinė koja"
        },
        "lung": {
          "bronchial": "Bronchinis",
          "dry": "Sausas",
          "impaired": "Susilpnėjęs",
          "normal": "Normalus",
          "unhearable": "Neišklausomas",
          "wet": "Drėgnas"
        },
        "meningealSignals": {
          "no": "Ne",
          "yes": "Taip"
        },
        "radialArteryPulse": {
          "absent": "Neapčiuopiamas",
          "present": "Yra"
        },
        "skinColor": {
          "cyanotic": "Cianozė",
          "jaundice": "Gelta",
          "normal": "Normali",
          "pale": "Blyški",
          "redness": "Paraudusi"
        },
        "skinCond": {
          "clammy": "Prakaituota",
          "dry": "Sausa",
          "normal": "Normali"
        },
        "skinTemp": {
          "cold": "Šalta",
          "cool": "Vėsi",
          "hot": "Karšta",
          "normal": "Normali"
        },
        "stSegment": {
          "drop": "Nusileidimas",
          "normal": "Normalus (izolinijoje)",
          "rise": "Pakilimas"
        },
        "vitals": {
          "pain": {
            "barelyIgnorable": "Negali būti nepaisomas ilgiau nei 30 minučių",
            "distraction": "Pakankamai stiprus, kad blaškytų dėmesį",
            "hard2concentrate": "Dėl jo tampa sunku susikaupti",
            "ignorable": "Gali būti nepaisomas, jei pacientas labai užimtas, tačiau blaško dėmesį",
            "limitedActivity": "Fizinis aktyvumas labai ribotas",
            "minor": "Nežymiai erzinantis skausmas",
            "painless": "Neskauda",
            "speechless": "Negali kalbėti, dejuoja ar šaukia",
            "unconscious": "Nesąmoningas; dėl skausmo netenkama sąmonės",
            "unignorable": "Visai negali būti nepaisomas",
            "veryMinor": "Labai nežymiai erzinantis skausmas"
          }
        }
      },
      "patientDetails": {
        "ageUnit": {
          "days": "Dienos",
          "daysShort": "d.",
          "months": "Mėnesiai",
          "monthsShort": "mėn.",
          "newborn": "Naujagimis",
          "years": "Metai",
          "yearsShort": "m."
        },
        "sex": {
          "female": "Moteris",
          "male": "Vyras"
        }
      },
      "positions": {
        "doctor": "Gydytojas",
        "nurse": "Slaugytojas",
        "paramedic": "Paramedikas"
      },
      "signatures": {
        "confirmation": {
          "no": "Nesutinku",
          "yes": "Sutinku"
        },
        "signatureTypes": {
          "byPatient": "Pacientas",
          "refusesToSign": "Pacientas atsisako pasirašyti",
          "representative": "Atstovas",
          "unableToSign": "Pacientas negali pasirašyti"
        }
      },
      "teamTypes": {
        "als": "PŽ",
        "bls": "PR"
      },
      "transportation": {
        "additionalInfo": {
          "alvApplied": "Pacientas, kuriam namuose buvo taikoma DPV, pervežtas iš stacionarinės ASPĮ į namus arba iš namų į stacionarinę ASPĮ toliau tirti ir (ar) gydyti",
          "alvAppliedShort": "Pervežtas po DPV taikymo",
          "eventCharged": "Kvietimas mokamas"
        },
        "from": {
          "betweenHospitals": "Tarp ligoninių",
          "fromHospital2residence": "Iš ligoninės į namus",
          "hospice": "Iš slaugos ligoninės",
          "incidentLocation": "Iš įvykio vietos",
          "other": "Kita",
          "phc": "Iš PASPĮ",
          "residence": "Iš gyvenamosios vietos"
        },
        "hospitalSelectionCriteria": {
          "nearest": "Artimiausia ligoninė",
          "patientOrFamilyPreference": "Paciento/šeimos narių pasirinkimas",
          "specialized": "Specializuota ligoninė"
        },
        "neighbouringCountry": {
          "border2phc": "Nuo LR sienos į PASPĮ",
          "phc2border": "Iš PASPĮ iki LR sienos"
        },
        "patient": "{count} pacient{count, plural, =1 {as} few {ai} other {ų}}",
        "patientPosition": {
          "fixedOnBack": "Ant nugaros (fiksuota)",
          "fixedOnLeftSide": "Ant kairiojo šono (fiksuota)",
          "fixedSemiSitting": "Pusiau sėdima (fiksuota)",
          "fixedSitting": "Sėdima (fiksuota)",
          "onBack": "Ant nugaros",
          "onLeftSide": "Ant kairiojo šono",
          "semiSitting": "Pusiau sėdima",
          "sitting": "Sėdima"
        },
        "position": {
          "fixed": "Fiksuota"
        },
        "toVehicle": {
          "alreadyInSite": "Jau buvo prie GMP auto.",
          "carried": "Nuneštas",
          "himself": "Atėjo pats",
          "inChair": "Kėdėje",
          "litter": "Neštuvais",
          "lying": "Gulintis",
          "stretchers": "Susiskleidžiančiais neštuvais"
        },
        "type": {
          "dead": "Ne, miręs",
          "help": "Ne, pagalba komandai",
          "other": "Ne, kita",
          "refused": "Ne, atsisakė",
          "transferred": "Ne, perduotas komandai",
          "withSirens": "Su garsiniais signalais",
          "withoutSirens": "Be garsinių signalų"
        }
      },
      "treatment": {
        "applyTimeEditToOther": "Keisti vėlesnius",
        "condition": {
          "deteriorated": "Pablogėjo",
          "improved": "Pagerėjo",
          "refused": "Atsisakė",
          "unchanged": "Nepakito"
        },
        "inventory": {
          "disposable": "Vienkartinės",
          "gloves": "Pirštinės",
          "latex": "Lateksinės",
          "scissors": "Žirklės",
          "title": "Sunaudotas inventorius"
        },
        "medicamentTypes": {
          "anhydrous": "Bevandenė",
          "candles": "žvakutės",
          "compressedInhalationSuspension": "suslėgta inhaliacinė suspensija",
          "concentrateForSolutionInfusion": "koncentratas infuziniam tirpalui",
          "inDose": "dozėje",
          "injectionEmulsion": "injekcinė emulsija",
          "internationalUnitsShort": "TV",
          "monohydrate": "Monohidratas",
          "other": "Kita",
          "pills": "tabletės",
          "powderAndSolventForSolutionInjection": "milteliai ir tirpiklis injekciniam tirpalui",
          "solutionInfusion": "infuzinis tirpalas",
          "solutionInjection": "injekcinis tirpalas",
          "solutionInjectionInfusion": "injekcinis/infuzinis tirpalas",
          "underTonguePills": "poliežuvinės tabletės"
        },
        "medicaments": {
          "1": "Analginas",
          "10": "Kaptoprilis",
          "11": "Ketorolakas",
          "12": "Klemastinas",
          "13": "Metoklopramidas",
          "14": "Natrio chloridas",
          "15": "Nifedipinas",
          "16": "Ondansetronas",
          "17": "Suksametonio chloridas",
          "2": "Heparinas",
          "3": "Aktyvintoji anglis",
          "4": "Benzilpenicilinas",
          "5": "Deguonis",
          "6": "Etilo alkoholis",
          "7": "Haloperidolis",
          "8": "Ibuprofenas",
          "9": "Kalcio gliukonatas",
          "adenocard": "Adenozinas",
          "albuterol": "Salbutamolis",
          "amiodarone": "Amjodaronas",
          "aspirin": "Acetilsalicilo rūgštis",
          "atropine": "Atropino sulfatas",
          "dexamethasone": "Deksametazonas",
          "dextrose": "Gliukozė",
          "diazepam": "Diazepamas",
          "dopamine": "Dopaminas",
          "epinephrine": "Epinefrinas",
          "etomidate": "Etomidatas",
          "furosemide": "Furozemidas",
          "glucagon": "Gliukagonas",
          "lidocaine": "Lidokainas",
          "magSulfate": "Magnio sulfatas heptahidratas",
          "methylprednisone": "Metilprednizolonas",
          "midazolam": "Midazolamas",
          "morphine": "Morfino hidrochloridas",
          "narcan": "Naloksonas",
          "nitroglycerin": "Glicerolio trinitratas",
          "other": "Kita",
          "oxytocin": "Oksitocinas",
          "paracetamol": "Paracetamolis",
          "restricted": {
            "confirmModalMessage": "Nežinomas (Kita) vaistas skiriamas tik išskirtiniais atvejais. Ar pacientui skiriamas vaistas tikrai nerastas sąrašuose?",
            "confirmModalTitle": "Ar tikrai norite pridėti nežinomą (Kita) vaistą?"
          },
          "title": "Vaistai"
        },
        "procedures": {
          "1": "Pradinis gaivinimas",
          "10": "Gerklų kaukė",
          "11": "Kombinuotas vamzdelis",
          "12": "Intubacija",
          "13": "Ventiliacija Ambu maišu",
          "14": "DPV",
          "15": "Magill žnyplės",
          "16": "Heimlicho metodas",
          "17": "Infuzoterapija",
          "18": "Adatinė krikotiroidotomija",
          "19": "Pleuros dekompresija",
          "2": "AID taikymas",
          "20": "Kraujavimo stabdymas (tiesioginis spaudimas)",
          "21": "Spaudžiantis tvarstis",
          "22": "Turniketo uždėjimas",
          "23": "Kaklo įtvaras",
          "24": "Ilga SIL",
          "25": "Trumpa SIL",
          "26": "Galūnių imobilizacija",
          "27": "Galūnių įtvarai",
          "28": "Greitas paciento ištraukimas iš automobilio",
          "29": "Žaizdos tvarstis",
          "3": "Defibriliacija",
          "30": "Žaizdos plovimas",
          "31": "Akių plovimas",
          "32": "Fizinė paciento fiksacija",
          "33": "Medikamentinė paciento fiksacija",
          "34": "N. vagus dirginimo mėginiai",
          "35": "Paciento šildymas",
          "36": "Šalčio paketo naudojimas",
          "37": "Skrandžio plovimas zondu",
          "38": "Šlapimo pūslės kateterizavimas",
          "39": "Gimdymo priežiūra",
          "4": "Išorinė kardiostimuliacija",
          "40": "Naujagimio priežiūra",
          "41": "Gimdos masažavimas per pilvo sieną",
          "42": "Pilvinės Ao spaudimas",
          "5": "Kardioversija",
          "6": "KT atvėrimas rankomis",
          "7": "Siurbimas iš kvėpavimo takų",
          "8": "Orofaringinis vamzdelis",
          "9": "Nazofaringinis vamzdelis",
          "all": "Visos",
          "breathing": "Kvėpavimas",
          "infuzotherapy": "Infuzoterapija",
          "injury": "Trauma",
          "newborn": "Naujagimis",
          "other": "Kita",
          "resuscitation": "Gaivinimas",
          "title": "Procedūros"
        },
        "totalDoses": "{count} doz{count, plural, =1 {ė} few {ės} other {ių}}",
        "totalProcedures": "{count} procedūr{count, plural, =1 {a} few {os} other {ų}}",
        "useType": {
          "automaticPump": "automatine pompa",
          "currentNozzle": "Sroviniu purkštuvu",
          "dropsToVein": "Lašais į veną",
          "externally": "Išoriškai",
          "fastInjection": "greita srove",
          "impactDose": "smūgine doze",
          "intraboneInjection": "Intrakaulinė injekcija",
          "slowInjection": "lėta srove",
          "throughMouth": "Per burną",
          "throughNose": "Per nosį",
          "throughRectum": "Per tiesiąją žarną",
          "toEndotrachealTube": "Į endotrachėjinį vamzdelį",
          "toMuscles": "Į raumenis",
          "toVein": "Į veną",
          "underSkin": "Po oda",
          "underTongue": "Po liežuviu"
        }
      }
    },
    "pcr": {
      "callInformation": {
        "callTakerCode": "Dispečerio id",
        "eventSite": {
          "address": "Adresas",
          "fireDepInformed": "Pranešta PGT",
          "policeInformed": "Pranešta policijai"
        },
        "phoneNumber": "Telefonas"
      },
      "category": "Skubumo kategorija",
      "delays": {
        "toEventSite": "Vykimo į įvykio vietą",
        "toHospital": "Vykimo į ligoninę",
        "toPatient": "Atvykimo pas pacientą"
      },
      "diagnosis": {
        "helpProtocol": "Pagalbos teikimo protokolas",
        "icd10Code": "TLK-10-AM",
        "other": "Kita",
        "preliminaryDiagnosis": "Preliminari diagnozė"
      },
      "eventCancelReason": "Kvietimo nutraukimo priežastis",
      "eventId": "Kvietimo id",
      "injuryLocation": {
        "amputation": "Amputacija",
        "bleeding": "Kraujavimas",
        "burn": "Nudegimas",
        "dislocation": "Dislokacija",
        "fracture": "Lūžis",
        "pain": "Skausmas",
        "paralysis": "Paralyžius/parezė",
        "softTissue": "Minkštųjų audinių sužalojimai",
        "swelling": "Sutinimas"
      },
      "metadata": {
        "owner": "Kortelės savininkas (autorius)"
      },
      "missionId": "Misijos nr",
      "patientAssessments": {
        "auscultationLeft": "Auskultacija kairėje",
        "auscultationRight": "Auskultacija dešinėje",
        "centralPulse": "Centrinis pulsas",
        "color": "Spalva",
        "condition": "Būklė",
        "crtLte2Sec": "Kapiliarų prisipildymo laikas",
        "degrees": "Laipsniai",
        "diabetes": "Diabetas",
        "diastolicBP": "Diastolinis KS",
        "eyeResponse": "Atsimerkimas",
        "glucose": "Gliukozės k.",
        "heartRhythm": "Širdies ritmas",
        "left": "Kairė",
        "meningealSymptoms": "Meninginiai simptomai",
        "motorResponse": "Judesiai",
        "pain": "Skausmas",
        "pulse": "Pulsas",
        "quality": "Kokybė",
        "radialPulse": "Stipininės a. pulsas (A)",
        "rash": "Išberta",
        "respiratoryRate": "Kvėpavimo dažnis",
        "right": "Dešinė",
        "stSegment": "ST segmentas",
        "systolicBP": "Sistolinis KS",
        "systolicDiastolicBP": "Sistolinis / Diastolinis KS",
        "temperature": "Temperatūra",
        "time": "Laikas",
        "verbalResponse": "Kalbėjimas"
      },
      "patientDetails": {
        "additionalInfo": "Papildoma informacija",
        "address": "Paciento adresas",
        "addressMatchesEventSite": "Adresas sutampa su įvykio vieta",
        "age": "Amžius",
        "allergy": "Alergija",
        "birthDate": "Gimimo data",
        "city": "Miestas",
        "county": "Apskritis",
        "familyDoctor": "Šeimos gydytojas",
        "firstName": "Vardas",
        "flatNumber": "Buto nr.",
        "helpRejected": "Atsisakė pagalbos",
        "homeless": "Benamis",
        "houseNumber": "Namo nr.",
        "inaccurateAddress": "Įvesti laisva forma",
        "inaccurateStreet": "Įvesti gatvę laisva forma",
        "knownDiseases": "Žinomi susirgimai",
        "knownDiseasesAllergy": "Žinomos alergijos",
        "knownDiseasesOther": "Žinomi kiti susirgimai",
        "lastName": "Pavardė",
        "notWalking": "Nevaikšto",
        "other": "Kita",
        "phcFacility": "PASPĮ",
        "pid": "Asmens kodas",
        "specialCircumstances": "Ypatingos aplinkybės",
        "street": "Gatvė",
        "units": "Vienetai",
        "usedMedicaments": "Naudojami vaistai",
        "weight": "Svoris"
      },
      "patientId": "Paciento nr",
      "signatures": {
        "agreementToSharePersonalData": "Informacija apie mano (mano atstovaujamo asmens) sveikatos būklę gali būti suteikta kitiems asmenims",
        "agreementToSharePersonalDataExtra": "Paciento atstovas - atstovas pagal įstatymą (tėvai, įtėviai, globėjai, rūpintojai) ar atstovas pagal pavedimą. Atstovą pagal pavedimą pasirenka pacientas",
        "agreementToSharePersonalData_SHORT": "Informacijos teikimas kitiems asmenims",
        "confirmationToHospital": "Sutinku vykti į ligoninę",
        "confirmationToHospitalExtra": "Man suprantama forma esu supažindintas su savo (mano atstovaujamo asmens) teisėmis, pareigomis ir su pasekmėmis, atsisakius siūlomos pagalbos. Dėl man (mano atstovaujamam asmeniui) siūlomų tyrimų, gydymo, atliekamų procedūrų ir teikiamos pagalbos",
        "medicalHelpAgreement": "Supažindinimas su teisėmis ir pareigomis",
        "medicalHelpAgreement_SHORT": "Susipažinau su teisėmis",
        "name": "Vardas, pavardė",
        "patient": "Pacientas",
        "personCredentials": "Informacijos gavėjo vardas, pavardė",
        "refused": "Atsisakė pasirašyti",
        "representativeCredentials": "Atstovo vardas, pavardė",
        "signer": "Pasirašantis asmuo",
        "verificationPersonCredentials": "Tvirtinančio asmens vardas, pavardė"
      },
      "state": {
        "accounted": "Atsiskaityta",
        "approved": "Patvirtinta",
        "archived": "Archyvuota",
        "closed": "Uždaryta",
        "filled": "Užpildyta",
        "imported": "Importuota",
        "restored": "Atkurta",
        "scanned": "Nuskanuota",
        "verified": "Patikrinta"
      },
      "teamActivation": {
        "activationProtocol": "Iškvietimo protokolas",
        "deliveryType": "Perdavimo būdas",
        "dispatcherCode": "Pajėgų valdytojo id",
        "signalsToSite": "Signalai į įvykį",
        "team": {
          "clusterTeam": "Klasterinė",
          "members": {
            "0": "Komandos vadovas",
            "1": "Vairuotojas",
            "2": "Narys #3",
            "3": "Narys #4"
          },
          "teamId": "Komandos id",
          "teamType": "Komandos tipas"
        }
      },
      "times": {
        "arrivedTime": "Atvyko",
        "arrivedTimeTitle": "Atvykimo laikas",
        "assignTime": "Perdavė",
        "atHospitalTime": "Ligoninėje",
        "availableTime": "Įvykdė",
        "callTime": "Priėmė",
        "notPresent": "Nėra",
        "onRouteTime": "Išvyko",
        "onRouteTimeTitle": "Išvykimo laikas",
        "registrationTime": "Užregistravo",
        "returnedTime": "Grįžo",
        "timeChangeRequest": "Laiko keitimo prašymas",
        "transportationTime": "Transportavo"
      },
      "transportation": {
        "fromLocation": "Pervežimo vieta",
        "hospital": "Ligoninė",
        "hospitalSelectionCriteria": "Ligoninės pasirinkimas",
        "neighbouringCountry": "Pervežimas",
        "notTransported": "Netransportuotas",
        "notTransportedReason": "Netransportavimo priežastis",
        "patientCount": "Pacientų kiekis",
        "patientPosition": "Paciento padėtis",
        "patientToVehicle": "Iki GMP",
        "teamNumber": "Komandos nr.",
        "totalDistance": "Viso km",
        "transportationLocation": "Transportavimo vieta",
        "transported": "Transportuotas",
        "transportedFrom": "Pervežtas"
      },
      "treatment": {
        "amount": "Iš viso skirta",
        "comments": "Komentarai",
        "condition": "Būklė",
        "measure": "Vienetai",
        "member": "Narys",
        "size": "Spindis/Dydis",
        "time": "Laikas",
        "type": "Tipas",
        "useType": "Vartojimo būdas",
        "used": "Iš viso sunaudota"
      }
    },
    "progress": {
      "error": "Klaida",
      "idle": "Laukiama",
      "retry": "Bandoma įkelti dar kartą",
      "running": "Įkeliama"
    },
    "recommendation": {
      "airbagVentilation": "Lėtas kvėpavimo dažnis",
      "allergicReaction": "Alerginė reakcija",
      "anaphylacticReaction": "Anafilaksinė reakcija",
      "glucose": "Žemas gliukozės lygis",
      "glycerol": "Krūtinės skausmas",
      "heartAttack": "Miokardo infarktas",
      "hypotension": "Žemas kraujo spaudimas",
      "painReliever": "Skausmas",
      "polytrauma": "Politrauma",
      "resuscitationAsystole": "Gaivinimas „asistolija”",
      "resuscitationVentricularFibrillation": "Gaivinimas „skilvelių virpėjimas”",
      "softTissue": "Minkštųjų audinių sužalojimas",
      "spO2": "Žemas SpO₂",
      "stroke": "Insultas",
      "swelling": "Sutinimas",
      "thinBlood": "Kraujo skystinimas"
    },
    "summaryInfo": {
      "comment": "Rašyti komentarą",
      "errors": "Klaida(-os)",
      "footer": "Paskutinį kartą kortelė išsaugota",
      "print": "Spausdinti",
      "printPreview": "Peržiūra",
      "warnings": "Įspėjimas(-ai)"
    },
    "support": {
      "message": {
        "callUs": "Susisiekite",
        "helpCenter": "IT pagalba",
        "weWantToHearFromYou": "Mes norime Jus išgirsti!"
      },
      "questions": {
        "needHelp": "Reikia pagalbos?"
      }
    },
    "time": {
      "ago": "prieš"
    },
    "timelines": {
      "AFTER_HELP": "Po MP būklės vertinimas",
      "BEFORE_HELP": "Iki MP būklės vertinimas",
      "arrivedTime": "Atvyko pas pacientą",
      "atHospitalTime": "Ligoninėje",
      "transportationTime": "Transportuojamas į ligoninę"
    },
    "updates": {
      "classificatorsVersion": "Klasifikatorių rinkinys",
      "rulesVersion": "Taisyklių rinkinys",
      "title": "Atnaujinimai",
      "unknownVersion": "nežinoma",
      "version": "Versija"
    },
    "usedMedicaments": {
      "comments": "Komentarai",
      "count": "Kiekis",
      "event": "Įvykis",
      "medicamentName": "Vaistas",
      "time": "Laikas",
      "total": "Viso"
    }
  };
  _exports.default = _default;
});