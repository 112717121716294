define("epcr-uis/initializers/model-fragments", ["exports", "ember-data-model-fragments"], function (_exports, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Import the full module to ensure monkey-patches are applied before any store
  // instances are created. Sad face for side-effects :(
  var _default = {
    name: 'fragmentTransform',
    before: 'ember-data',

    initialize(application) {
      application.inject('transform', 'store', 'service:store');
    }

  };
  _exports.default = _default;
});