define("epcr-uis/pods/components/pcr-document/flowchart/delete/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    actions: {
      onDelete(data) {
        this.set("isDeleteConfirmationOpen", false);
        return this.onDelete(data);
      }

    }
  });

  _exports.default = _default;
});