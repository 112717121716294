define("epcr-uis/helpers/count-age-between", ["exports", "epcr-uis/helpers/count-age-in-months"], function (_exports, _countAgeInMonths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isAgeBetween = void 0;

  const isNotYounger = (ageInMonths, fromMonths) => ageInMonths >= fromMonths;

  const isYounger = (ageInMonths, toMonths) => Ember.isEmpty(toMonths) || ageInMonths < toMonths;

  const isAgeBetween = (timeService, patientDetails, callTime, fromMonths, toMonths) => {
    const ageInMonths = (0, _countAgeInMonths.countAgeInMonths)([timeService, patientDetails, callTime]);
    return Ember.isPresent(ageInMonths) && isNotYounger(ageInMonths, fromMonths) && isYounger(ageInMonths, toMonths);
  };

  _exports.isAgeBetween = isAgeBetween;
});