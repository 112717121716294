define("epcr-uis/mirage/factories/comment", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    userId: i => i + 1,
    content: () => _emberCliMirage.faker.lorem.words(10),
    date: _emberCliMirage.faker.date.past()
  });

  _exports.default = _default;
});