define("epcr-uis/pods/components/filter-composer/representation/shift-duration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "knk6jZts",
    "block": "{\"symbols\":[\"@value\",\"@shiftDurationInterval\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui basic label\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"filter-wrapper\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"filter-title\"],[8],[1,[28,\"t\",[\"headings.filter.shiftDuration\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"filter-value\"],[8],[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"interval\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/filter-composer/representation/shift-duration/template.hbs"
    }
  });

  _exports.default = _default;
});