define("epcr-uis/pods/pcr-classificator/medicaments/default", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list", "epcr-uis/pods/pcr-classificator/medicaments/get-medicaments-data"], function (_exports, _medicamentsList, _getMedicamentsData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEFAULT_MEDICAMENTS = void 0;
  const PILLS = "options.treatment.medicamentTypes.pills";
  const SOLUTION_INFUSION = "options.treatment.medicamentTypes.solutionInfusion";
  const SOLUTION_INJECTION = "options.treatment.medicamentTypes.solutionInjection";
  const SOLUTION_INJECTION_INFUSION = "options.treatment.medicamentTypes.solutionInjectionInfusion";
  const INTERNATIONAL_UNITS_SHORT = "options.treatment.medicamentTypes.internationalUnitsShort";

  const DEFAULT_MEDICAMENTS = t => [(0, _medicamentsList.MEDICAMENTS)(t)[1], (0, _medicamentsList.MEDICAMENTS)(t)[2], {
    name: t(`${_getMedicamentsData.medicament}3`),
    // Aktyvintoji anglis
    types: [{
      code: 3,
      name: "Susp. 50g, granulės geriamajai suspensijai",
      useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
    }]
  }, (0, _medicamentsList.MEDICAMENTS)(t)[4], (0, _medicamentsList.MEDICAMENTS)(t)[5], {
    name: t(`${_getMedicamentsData.medicament}4`),
    // Benzilpenicilinas
    types: [{
      code: 6,
      name: `1mln. ${t(INTERNATIONAL_UNITS_SHORT)}, milteliai injekciniam ar infuziniam tirpalui`,
      useType: [(0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)]
    }]
  }, {
    name: t(`${_getMedicamentsData.medicament}5`),
    // Deguonis
    types: [{
      code: 7,
      name: "100% suskyst. med. dujos",
      useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t), (0, _getMedicamentsData.EXTERNALLY)(t)]
    }]
  }, (0, _medicamentsList.MEDICAMENTS)(t)[8], (0, _medicamentsList.MEDICAMENTS)(t)[9], (0, _medicamentsList.MEDICAMENTS)(t)[10], (0, _medicamentsList.MEDICAMENTS)(t)[11], {
    name: t(`${_getMedicamentsData.medicament}6`),
    // Etilo alkoholis
    types: [{
      code: 12,
      name: "70°, gaminamas ekstemporaliai",
      useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
    }]
  }, (0, _medicamentsList.MEDICAMENTS)(t)[13], (0, _medicamentsList.MEDICAMENTS)(t)[14], (0, _medicamentsList.MEDICAMENTS)(t)[15], (0, _medicamentsList.MEDICAMENTS)(t)[16], (0, _medicamentsList.MEDICAMENTS)(t)[17], (0, _medicamentsList.MEDICAMENTS)(t)[18], (0, _medicamentsList.MEDICAMENTS)(t)[19], {
    name: t(`${_getMedicamentsData.medicament}7`),
    // Haloperidolis
    types: [{
      code: 20,
      name: `5mg/ml, ${t(SOLUTION_INJECTION)}`,
      useType: [(0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)].concat((0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t))
    }]
  }, {
    name: t(`${_getMedicamentsData.medicament}8`),
    // Ibuprofenas
    types: [{
      code: 21,
      name: `400mg, plėvele dengtos ${t(PILLS)}`,
      useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
    }]
  }, {
    name: t(`${_getMedicamentsData.medicament}9`),
    // Kalcio gliukonatas
    types: [{
      code: 22,
      name: `100mg/ml, ${t(SOLUTION_INJECTION)} (vardinis preparatas)`,
      useType: [(0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)].concat((0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t))
    }]
  }, {
    name: t(`${_getMedicamentsData.medicament}10`),
    // Kaptoprilis
    types: [{
      code: 23,
      name: `25mg, ${t(PILLS)}`,
      useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t), (0, _getMedicamentsData.UNDER_TONGUE)(t)]
    }]
  }, {
    name: t(`${_getMedicamentsData.medicament}11`),
    // Ketorolakas
    types: [{
      code: 24,
      name: `30mg/ml, ${t(SOLUTION_INJECTION)}`,
      useType: [(0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)].concat((0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t))
    }]
  }, {
    name: t(`${_getMedicamentsData.medicament}12`),
    // Klemastinas
    types: [{
      code: 25,
      name: `1mg/ml, ${t(SOLUTION_INJECTION)}`,
      useType: [(0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)].concat((0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t))
    }]
  }, (0, _medicamentsList.MEDICAMENTS)(t)[26], (0, _medicamentsList.MEDICAMENTS)(t)[27], {
    name: t(`${_getMedicamentsData.medicament}13`),
    // Metoklopramidas
    types: [{
      code: 28,
      name: `10mg/2ml, ${t(SOLUTION_INJECTION)}`,
      useType: [(0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)].concat((0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t))
    }]
  }, (0, _medicamentsList.MEDICAMENTS)(t)[29], (0, _medicamentsList.MEDICAMENTS)(t)[30], {
    name: t(`${_getMedicamentsData.medicament}14`),
    // Natrio chloridas
    types: [{
      code: 31,
      name: `9mg/ml 5ml, ${t(SOLUTION_INJECTION)}`,
      useType: [(0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.CURRENT_NOZZLE)(t), ...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.TO_MUSCLES)(t), (0, _getMedicamentsData.EXTERNALLY)(t)]
    }, {
      code: 32,
      name: `9mg/ml 250ml, ${t(SOLUTION_INFUSION)}`,
      useType: [(0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.CURRENT_NOZZLE)(t), ...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.EXTERNALLY)(t)]
    }, {
      code: 33,
      name: `9mg/ml 500ml, ${t(SOLUTION_INFUSION)}`,
      useType: [(0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.CURRENT_NOZZLE)(t), ...(0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t), (0, _getMedicamentsData.EXTERNALLY)(t)]
    }]
  }, (0, _medicamentsList.MEDICAMENTS)(t)[34], {
    name: t(`${_getMedicamentsData.medicament}15`),
    // Nifedipinas
    causeAllergies: true,
    types: [{
      code: 35,
      name: `20mg, pailginto atpalaidavimo ${t(PILLS)}`,
      useType: [(0, _getMedicamentsData.THROUGH_MOUTH)(t)]
    }]
  }, (0, _medicamentsList.MEDICAMENTS)(t)[36], {
    name: t(`${_getMedicamentsData.medicament}16`),
    // Ondansetronas
    types: [{
      code: 37,
      name: `2mg/ml, ${t(SOLUTION_INJECTION_INFUSION)}`,
      useType: [(0, _getMedicamentsData.INTRA_BONE_INJECTION)(t), (0, _getMedicamentsData.TO_MUSCLES)(t)].concat((0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t))
    }]
  }, (0, _medicamentsList.MEDICAMENTS)(t)[38], (0, _medicamentsList.MEDICAMENTS)(t)[39], (0, _medicamentsList.MEDICAMENTS)(t)[40], (0, _medicamentsList.MEDICAMENTS)(t)[41], {
    name: t(`${_getMedicamentsData.medicament}17`),
    // Suksametonio chloridas
    types: [{
      code: 42,
      name: `20mg/ml, ${t(SOLUTION_INJECTION)}`,
      useType: [(0, _getMedicamentsData.INTRA_BONE_INJECTION)(t)].concat((0, _getMedicamentsData.TO_VEIN_ALL_USE_TYPES)(t))
    }]
  }];

  _exports.DEFAULT_MEDICAMENTS = DEFAULT_MEDICAMENTS;
});