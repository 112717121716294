define("epcr-uis/helpers/filter-to-string", ["exports", "ember-components/helpers/filter-to-string"], function (_exports, _filterToString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _filterToString.default;
    }
  });
  Object.defineProperty(_exports, "filterToString", {
    enumerable: true,
    get: function () {
      return _filterToString.filterToString;
    }
  });
});