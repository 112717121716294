define("epcr-uis/helpers/insert-text-to-textarea", ["exports", "ember-components/helpers/insert-text-to-textarea"], function (_exports, _insertTextToTextarea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "insertTextToTextarea", {
    enumerable: true,
    get: function () {
      return _insertTextToTextarea.insertTextToTextarea;
    }
  });
});