define("epcr-uis/pods/pcr/backend-mirage-normalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.remakeInjuryLocationStructure = _exports.getNormalizedInventory = _exports.makeSelectedMembersArraySize = _exports.getNormalizedOwner = _exports.getNormalizedTreatmentMembers = _exports.getNormalizedMedicaments = void 0;

  const getNormalizedMedicaments = (medicaments, classificator) => {
    const classificatorMedicaments = classificator.get("medicaments");
    medicaments.map(med => {
      const modifiedMedicament = {};
      classificatorMedicaments.forEach(classMed => {
        classMed.types.find(type => {
          if (med.medicament === type.code) {
            modifiedMedicament.ingredient = classMed.name;
            modifiedMedicament.medium = {
              code: type.code,
              name: type.name
            };
            modifiedMedicament.code = type.code;
          }
        });
      });

      if (Object.keys(modifiedMedicament).length) {
        med.medicament = modifiedMedicament;
      } else {
        med.medicament = {
          ingredient: `Nežinoma (${med.medicament})`,
          medium: {
            code: med.medicament,
            name: "Nežinomas"
          },
          code: med.medicament
        };
      }
    });
    return medicaments;
  };

  _exports.getNormalizedMedicaments = getNormalizedMedicaments;

  const getNormalizedTreatmentMembers = treatments => {
    treatments.map(treatment => {
      if (!treatment.member) {
        treatment.member = {};
      } else {
        const id = treatment.member;
        treatment.member = {};
        treatment.member.externalId = id;
      }
    });
    return treatments;
  };

  _exports.getNormalizedTreatmentMembers = getNormalizedTreatmentMembers;

  const getNormalizedOwner = member => member ? {
    externalId: member
  } : {};

  _exports.getNormalizedOwner = getNormalizedOwner;

  const makeSelectedMembersArraySize = members => {
    const emptyMember = {
      externalId: null
    };

    if (!members || !members.length) {
      for (let i = 0; i < 4; i++) members.pushObject(emptyMember);

      return members;
    }

    if (members.length === 4) return members.map(member => member || {
      externalId: null
    }); // TODO backendas visada turetu arba nesiusti nariu arba 4 siusti, bet apsidraudimui kolkas palieku

    const maxMembers = 4;
    const additionalMembersCount = maxMembers - members.length;

    for (let n = 0; n < additionalMembersCount; n++) members.pushObject(emptyMember);

    return members;
  };

  _exports.makeSelectedMembersArraySize = makeSelectedMembersArraySize;

  const getNormalizedInventory = (inventory, classificator) => {
    const classificatorInventoryItems = classificator.get("flowchart.inventoryList");
    const classificatorInventoryItemTypes = classificator.get("flowchart.inventoryTypes");
    inventory.map(item => {
      classificatorInventoryItems.forEach(classItem => {
        if (classItem.code === item.code) item.code = classItem;
      });
      classificatorInventoryItemTypes.forEach(classType => {
        if (classType.code === item.type) item.type = classType;
      });
    });
    return inventory;
  };

  _exports.getNormalizedInventory = getNormalizedInventory;

  const convertCamelCaseToUnderscore = string => string.split(/(?=[A-Z])/).join("_").toUpperCase();

  const remakeInjuryLocationStructure = injuries => {
    const newInjuries = {};
    const keys = ["pain", "bleeding", "amputation", "dislocation", "fracture", "softTissue", "burn", "paralysis", "swelling"];
    keys.forEach(key => {
      newInjuries[key] = [];
      injuries && Object.keys(injuries).forEach(injury => {
        injuries[injury] && injuries[injury].forEach && injuries[injury].forEach(injuryKey => {
          if (injuryKey === convertCamelCaseToUnderscore(key)) newInjuries[key].push(convertCamelCaseToUnderscore(injury));
        });
      });
    });
    return newInjuries;
  };

  _exports.remakeInjuryLocationStructure = remakeInjuryLocationStructure;
});