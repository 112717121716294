define("epcr-uis/pods/pcr/injuries/model", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _fragment.default.extend({
    pain: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    bleeding: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    amputation: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    dislocation: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    fracture: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    softTissue: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    burn: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    paralysis: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    swelling: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR)
  });

  _exports.default = _default;
});