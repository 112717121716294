define("epcr-uis/mixins/metrics-tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    metrics: Ember.inject.service(),

    trackEvent(eventCategory, eventAction, eventLabel, eventValue) {
      this.metrics.trackEvent({
        eventCategory,
        eventAction,
        eventLabel,
        eventValue
      });
    }

  });

  _exports.default = _default;
});