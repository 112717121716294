define("epcr-uis/pods/components/pcr-document/vitals/help/pulse-ranges/component", ["exports", "epcr-uis/pods/components/pcr-document/abstract/help-block/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    intl: Ember.inject.service(),
    time: Ember.inject.service(),
    blocks: Ember.computed(function () {
      const {
        timesPerMin,
        ranges,
        time,
        pcr
      } = this;
      const {
        between2And5Year,
        between5And10Year,
        between10And12Year,
        moreThan12Year,
        newBorn,
        lessThan1Year,
        between1And2Year
      } = ranges;

      const createBlock = (range, value) => this.createHelpBlock(time, pcr, timesPerMin, range, value);

      return [createBlock(newBorn, "90-145"), createBlock(lessThan1Year, "110-160"), createBlock(between1And2Year, "100-150"), createBlock(between2And5Year, "95-140"), createBlock(between5And10Year, "80-120"), createBlock(between10And12Year, "60-110"), createBlock(moreThan12Year, "60-100")];
    })
  });

  _exports.default = _default;
});