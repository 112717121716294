define("epcr-uis/mirage/factories/side-diseases", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    allergyNotes: "allergy",
    otherIllnessDescription: () => (0, _isEnglish.isEnglish)() ? "Unknown" : "Nežinau"
  });

  _exports.default = _default;
});