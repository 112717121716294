define("epcr-uis/pods/pcr/time/model", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    callTime: attr("date"),
    assignTime: attr("date"),
    onRouteTime: attr("date"),
    arrivedTime: attr("date"),
    transportationTime: attr("date"),
    atHospitalTime: attr("date"),
    availableTime: attr("date"),
    returnedTime: attr("date"),
    registrationTime: attr("date"),
    transportationTimeDeleted: attr("boolean"),
    atHospitalTimeDeleted: attr("boolean"),
    availableTimeDeleted: attr("boolean"),
    returnedTimeDeleted: attr("boolean")
  });

  _exports.default = _default;
});