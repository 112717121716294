define("epcr-uis/pods/components/pcr-document/vitals-quick-scroll/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scrollToField: Ember.inject.service(),
    classNames: ["vitals-quick-scroll-buttons"],
    quickButtons: [{
      iconClass: "stethoscope icon",
      headerContainer: "breathing"
    }, {
      iconClass: "theme icon",
      headerContainer: "blood-flow"
    }, {
      iconClass: "comment outline icon",
      headerContainer: "consciousness"
    }, {
      iconClass: "eye icon",
      headerContainer: "eyes"
    }, {
      iconClass: "bolt icon",
      headerContainer: "pain"
    }, {
      iconClass: "universal access icon",
      headerContainer: "skin"
    }, {
      iconClass: "cubes icon",
      headerContainer: "glucose-header"
    }, {
      iconClass: "heartbeat icon",
      headerContainer: "ecg"
    }],
    quickButtonsAtEr: [{
      iconClass: "stethoscope icon",
      headerContainer: "breathing"
    }, {
      iconClass: "theme icon",
      headerContainer: "blood-flow"
    }, {
      iconClass: "bolt icon",
      headerContainer: "pain"
    }],
    actions: {
      scrollToHeader(container) {
        const fieldId = `#${this.assessmentType}-${container}`;
        this.scrollToField.animate($(fieldId), "start", "smooth");
      }

    }
  });

  _exports.default = _default;
});