define("epcr-uis/pods/pcr/summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E9xtl1ij",
    "block": "{\"symbols\":[\"Menu\"],\"statements\":[[5,\"route-content-loader\",[],[[\"@promise\"],[[24,[\"model\",\"deferredContentPromise\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"ui-container\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"tabular-menu\",[[12,\"class\",\"action-summary-navigation\"]],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,1,[\"left\"]],[],[[\"@tabs\"],[[24,[\"model\",\"summaryTabs\"]]]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/pcr/summary/template.hbs"
    }
  });

  _exports.default = _default;
});