define("epcr-uis/pods/pcr/transportation/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _fragment.default.extend({
    patientToVehicle: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    route: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    routeNotes: attr("string"),
    position: (0, _attributes.fragment)("pcr/transportation/position"),
    hospitalSelectionCriteria: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    neighboringCountry: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    hospitalDepartment: (0, _attributes.fragment)("pcr/transportation/department"),
    totalPatients: attr("number"),
    alvApplied: attr("boolean"),
    transportedToPickUpSite: attr("boolean", {
      defaultValue: false
    })
  });

  _exports.default = _default;
});