define("epcr-uis/pods/print/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MOZngiFz",
    "block": "{\"symbols\":[\"Print\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"pcr-document/print\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[[12,\"class\",\"scrollable-content\"]],[[\"@source\",\"@title\"],[[24,[\"model\",\"source\"]],[28,\"t\",[\"headings.print.form\"],[[\"pcrId\"],[[24,[\"model\",\"pcrId\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/print/form/template.hbs"
    }
  });

  _exports.default = _default;
});