define("epcr-uis/pods/components/pcr-document/patient/address/component", ["exports", "ember-concurrency", "epcr-uis/helpers/address-formatter", "epcr-uis/helpers/letter-capitalizer", "epcr-uis/helpers/promise-converter", "epcr-uis/mixins/coordinates-from-event-site-or-station", "epcr-uis/mixins/metrics-tracker"], function (_exports, _emberConcurrency, _addressFormatter, _letterCapitalizer, _promiseConverter, _coordinatesFromEventSiteOrStation, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_coordinatesFromEventSiteOrStation.default, _metricsTracker.default, {
    addressService: Ember.inject.service("address"),
    pcrClassificator: Ember.inject.service(),
    fastComments: Ember.inject.service("pcr-classificator/fast-comments"),
    login: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ["address"],
    submit: (0, _emberConcurrency.task)(function* () {
      yield this.set("isAddressPopupOpen", false);
    }),
    county: Ember.computed("login.tokenData", {
      get() {
        return this.findCounty(this.pcrClassificator.counties, this.login.getStationId());
      },

      set: (key, value) => value
    }),

    findCounty(counties, stationId) {
      return counties.find(({
        station
      }) => station ? station.find(station => station === stationId) : false);
    },

    addressStreetOrNull: Ember.computed("address.{street,city,municipality,eldership}", function () {
      const {
        address
      } = this;
      return address && (Ember.get(address, "street") || Ember.get(address, "city") || Ember.get(address, "municipality") || Ember.get(address, "eldership")) ? address : null;
    }),
    addressCityOrNull: Ember.computed("address.{city,municipality,eldership}", function () {
      const {
        address
      } = this;
      return address && (Ember.get(address, "city") || Ember.get(address, "municipality") || Ember.get(address, "eldership")) ? address : null;
    }),
    formattedAddress: Ember.computed("address.{city,municipality,street,house,eldership,flat,additionalInformation}", function () {
      return (0, _addressFormatter.addressFormatter)([this.address]);
    }),
    streetsSource: Ember.computed(function () {
      return query => (0, _promiseConverter.promiseToObjectsConverter)(this.streetDataSource(query), ["code", "city", "eldership", "municipality", "street"]);
    }),
    citiesSource: Ember.computed(function () {
      return query => (0, _promiseConverter.promiseToObjectsConverter)(this.cityDataSource(query), ["code", "city", "eldership", "municipality"]);
    }),

    streetDataSource(query) {
      const {
        longitude,
        latitude
      } = this.getCoordinates();
      const county = Ember.get(this, "county.code");
      return this.addressService.getAddressesByStreet(query, longitude, latitude, county);
    },

    cityDataSource(query) {
      return this.addressService.getAddressesByCity(query);
    },

    setAddressWithCityData(address, {
      eldership,
      municipality,
      city
    }) {
      Ember.setProperties(address, {
        eldership,
        municipality,
        city
      });
    },

    actions: {
      onStreetChange(address, street) {
        Ember.set(address, "street", Ember.get(street, "street"));
        this.setAddressWithCityData(address, Ember.getProperties(street, ["eldership", "municipality", "city"]));
      },

      onStreetRemove(address) {
        Ember.set(address, "street", null);
        this.setAddressWithCityData(address, {
          eldership: null,
          municipality: null,
          city: null
        });
      },

      onCityChange(address, city) {
        this.setAddressWithCityData(address, Ember.getProperties(city, ["eldership", "municipality", "city"]));
      },

      onCityClear(address) {
        this.setAddressWithCityData(address, {
          city: null,
          eldership: null,
          municipality: null
        });
      },

      onCountySelect(queryTask) {
        queryTask.perform(queryTask.context.query);
      },

      redirectToFreestyleAndFillStreet(streetQuery) {
        Ember.set(this, "isStreetPopupOpen", false);
        Ember.set(this, "isInaccurateAddress", true);
        Ember.set(this, "address.street", (0, _letterCapitalizer.letterCapitalizer)([streetQuery]));
        this.trackEvent("Checkbox", "Inaccurate address checked");
      },

      searchInWholeLithuania(queryTask, query) {
        Ember.set(this, "county", null);
        queryTask.perform(query);
      },

      onOpenMapModal() {
        this.trackEvent("Map", "Open patient address in map");
      },

      onQueryError(county, singleSelectPopupContext, error) {
        Ember.set(singleSelectPopupContext, "customErrorMessage", null);
        if (error === "NO_RESULTS" && county) Ember.set(singleSelectPopupContext, "customErrorMessage", this.intl.t("errors.NO_STREET_IN_COUNTY", {
          county: county.name
        }));
      }

    }
  });

  _exports.default = _default;
});