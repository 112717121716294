define("epcr-uis/pods/components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xzh1C5zC",
    "block": "{\"symbols\":[\"@taskInstance\",\"@name\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"bar\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"bar-text\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"isRetrying\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[[24,[\"stateMap\",\"retry\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[[24,[\"stateMap\",\"running\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"isError\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[[24,[\"stateMap\",\"error\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"t\",[[24,[\"stateMap\",\"idle\"]]],null],false],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[23,2,[]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});