define("epcr-uis/pods/list/active-pcrs/service", ["exports", "ember-concurrency", "epcr-uis/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    queryActivePcrsTask: (0, _emberConcurrency.task)(function* (teamId, categories, completedPcr) {
      yield (0, _emberConcurrency.timeout)(_environment.default.APP.defaultDebounceMs);
      return yield this.store.query("active-pcr", {
        teamId: teamId.code,
        categories,
        completedPcr
      });
    }).drop()
  });

  _exports.default = _default;
});