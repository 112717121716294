define("epcr-uis/instance-initializers/features-loader", ["exports", "epcr-uis/constants", "epcr-uis/config/environment"], function (_exports, _constants, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  const setFeatures = (service, mode, features) => features.forEach(feature => service[mode](feature));

  const updateFeatures = (appInstance, features, {
    isAuthenticated
  }, {
    preferencesUpdated,
    userPreferences
  }) => {
    if (isAuthenticated && preferencesUpdated) {
      const device = appInstance.lookup("service:device");
      const flashlight = appInstance.lookup("service:flashlight");
      const login = appInstance.lookup("service:login");
      if (userPreferences[_constants.FEAT_SOUND_ON_CLICK] || !device.isDesktop()) setFeatures(features, "enable", [_constants.FEAT_SOUND_ON_CLICK]);
      if (_environment.default.environment !== "test" && userPreferences[_constants.FEAT_RAPID_INPUT_MODE]) setFeatures(features, "enable", [_constants.FEAT_RAPID_INPUT_MODE]);
      if (userPreferences[_constants.FEAT_TIMELINES]) setFeatures(features, "enable", [_constants.FEAT_TIMELINES]);
      if (userPreferences[_constants.FEAT_RELATED_PROCEDURES]) setFeatures(features, "enable", [_constants.FEAT_RELATED_PROCEDURES]);
      if (userPreferences[_constants.SURVEYED]) setFeatures(features, "enable", [_constants.SURVEYED]);

      if (userPreferences[_constants.FEAT_NIGHT_MODE]) {
        setFeatures(features, "enable", [_constants.FEAT_NIGHT_MODE]);
        document.body.classList.add("night-mode");
      }

      if (flashlight.isEnabled && userPreferences[_constants.FEAT_FLASHLIGHT]) setFeatures(features, "enable", [_constants.FEAT_FLASHLIGHT]);
      if (_environment.default.APP.showClusterTeamForStations.includes(login.stationId)) setFeatures(features, "enable", [_constants.FEAT_SHOW_CLUSTER_TEAM]);
    } else {
      setFeatures(features, "disable", [_constants.FEAT_SOUND_ON_CLICK, _constants.FEAT_RAPID_INPUT_MODE, _constants.FEAT_TIMELINES, _constants.FEAT_NIGHT_MODE, _constants.FEAT_RELATED_PROCEDURES, _constants.FEAT_SHOW_CLUSTER_TEAM]);
    }
  };

  function initialize(appInstance) {
    const features = appInstance.lookup("service:features");
    const session = appInstance.lookup("service:session");
    const userPreferencesService = appInstance.lookup("service:user-preferences");
    setFeatures(features, "disable", [_constants.FEAT_SOUND_ON_CLICK]);
    userPreferencesService.addObserver("preferencesUpdated", () => {
      updateFeatures(appInstance, features, session, userPreferencesService);
    });
  }

  var _default = {
    name: "features-loader",
    initialize
  };
  _exports.default = _default;
});