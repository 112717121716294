define("epcr-uis/helpers/promise-converter", ["exports", "ember-components/helpers/promise-converter"], function (_exports, _promiseConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "promiseToObjectsConverter", {
    enumerable: true,
    get: function () {
      return _promiseConverter.promiseToObjectsConverter;
    }
  });
  Object.defineProperty(_exports, "promiseToValueConverter", {
    enumerable: true,
    get: function () {
      return _promiseConverter.promiseToValueConverter;
    }
  });
  Object.defineProperty(_exports, "promiseToPlainValueConverter", {
    enumerable: true,
    get: function () {
      return _promiseConverter.promiseToPlainValueConverter;
    }
  });
});