define("epcr-uis/pods/pcr-classificator/fast-comments/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    medicaments: {
      11: ["Kas 3-5min kartojama"],
      // Epinefrinas
      7: ["15l/min", "l/min", "5l/min"],
      // Deguonis
      204: ["15l/min", "l/min", "5l/min"],
      // Deguonis
      244: ["1ml"],
      // Heparinas
      30: ["1ml", "1ml skiedžiant 3ml NaCl", "1ml skiedžiant 5ml NaCl", "1ml skiedžiant 7ml NaCl", "1ml skiedžiant 9ml NaCl"] // Morfino hidrochloridas

    },
    procedures: {
      1: ["30:2 minimalios pertraukos", "15:2 minimalios pertraukos", "3:1 minimalios pertraukos", "tęsiamas viso gaivinimo metu"],
      // Pradinis gaivinimas
      3: ["120J", "150J", "200J", "360J"] // Defibriliacija

    },
    patientAddress: ["Nežinomas adresas", "Latvija", "Lenkija", "Baltarusija", "Rusija", "Ukraina", "Vokietija", "Norvegija", "Jungtinė Karalystė", "Airija"]
  });

  _exports.default = _default;
});