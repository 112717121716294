define("epcr-uis/helpers/is-item-equal", ["exports", "ember-components/helpers/is-item-equal"], function (_exports, _isItemEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isItemEqual.default;
    }
  });
  Object.defineProperty(_exports, "isItemEqual", {
    enumerable: true,
    get: function () {
      return _isItemEqual.isItemEqual;
    }
  });
});