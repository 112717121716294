define("epcr-uis/pods/pcr-reloader/active-pcrs/by-period/service", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    period: _environment.default.APP.activePcrsReloadPeriod,

    watch(callback) {
      this._watchByPeriod(callback);
    },

    stopWatch() {
      this._stopWatchByPeriod();
    },

    _stopWatchByPeriod() {
      Ember.run.cancel(this.watchInterval);
    },

    _watchByPeriod(callback) {
      const interval = Ember.run.later(this, () => {
        callback && callback();
        this.watch(callback);
      }, this.period);
      Ember.set(this, "watchInterval", interval);
    }

  });

  _exports.default = _default;
});