define("epcr-uis/mirage/factories/patient", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const englishPatient = () => ({
    firstName: "PEN",
    lastName: "PETER",
    sex: "MALE",
    age: {
      age: 22,
      measure: "YEARS"
    },
    personalId: "39512150602",
    birthDate: () => _emberCliMirage.faker.date.past(),
    weight: () => _emberCliMirage.faker.random.number(79),
    specialCircumstances: "None",
    medicineConsumed: "Paracetamol",
    familyDoctor: "Family Doctor",
    lastUpdateDate: () => _emberCliMirage.faker.date.past(),
    address: {
      additionalInformation: "Angry dog at entrance",
      city: "Kaunas",
      coordinate: {
        latitude: 54.714305,
        longitude: 25.211904
      },
      crossingStreet: "not implemented",
      eldership: "Kaunas city eldership",
      flat: "44",
      house: "151A",
      municipality: "Kaunas mun.",
      street: "Griunvaldo st."
    },
    phcFacility: "phc facility",
    sideDiseases: {
      types: ["ASTHMA"]
    }
  });

  const lithuanianPatient = () => ({
    firstName: "MOKYMAI",
    lastName: "MOKYM",
    sex: "FEMALE",
    age: {
      age: 21,
      measure: "YEARS"
    },
    personalId: "39512150601",
    birthDate: () => _emberCliMirage.faker.date.past(),
    weight: () => _emberCliMirage.faker.random.number(80),
    specialCircumstances: "Nėra tokių",
    medicineConsumed: "Paratacemol",
    familyDoctor: "Šeimos gydytojukas",
    lastUpdateDate: () => _emberCliMirage.faker.date.past(),
    address: {
      additionalInformation: "Yra piktas suo",
      city: "Kauno m.",
      coordinate: {
        latitude: 54.714305,
        longitude: 25.211904
      },
      crossingStreet: "not implemented",
      eldership: "Kauno m. sen.",
      flat: "44",
      house: "151A",
      municipality: "Kauno m. sav.",
      street: "Griunvaldo g."
    },
    phcFacility: "ligoninė",
    sideDiseases: {
      types: ["ASTHMA"]
    },
    transported: true,
    icdCode: {
      code: "T33.0",
      name: "Galvos skausmas"
    }
  });

  var _default = _emberCliMirage.Factory.extend((0, _isEnglish.isEnglish)() ? englishPatient() : lithuanianPatient());

  _exports.default = _default;
});