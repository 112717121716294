define("epcr-uis/pods/components/pcr-routes/narrative/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+XsdOiwt",
    "block": "{\"symbols\":[\"Narrative\",\"@pcr\"],\"statements\":[[5,\"route-content-loader\",[],[[\"@promise\"],[[22,\"narrativeLoadPromise\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"show-block\",[],[[\"@pcr\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"let\",[[28,\"component\",[\"pcr-document/narrative\"],null]],null,{\"statements\":[[0,\"      \"],[6,[23,1,[]],[],[[\"@pcr\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-routes/narrative/template.hbs"
    }
  });

  _exports.default = _default;
});