define("epcr-uis/pods/components/pcr-document/injuries/popup/component", ["exports", "ember-concurrency", "ember-components/mixins/sidebar-switcher"], function (_exports, _emberConcurrency, _sidebarSwitcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sidebarSwitcher.default, {
    currentSidebarIndex: "20",
    closeCenterModal: (0, _emberConcurrency.task)(function* () {
      yield Ember.set(this, "isRightPopupOpen", false);
    })
  });

  _exports.default = _default;
});