define("epcr-uis/helpers/get-color-from-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getColorFromRange = getColorFromRange;
  _exports.default = void 0;

  const doesNotHaveRanges = ([negativeMin, positiveMin, positiveMax, negativeMax]) => Ember.isNone(negativeMin) && Ember.isNone(positiveMin) && Ember.isNone(positiveMax) && Ember.isNone(negativeMax);

  function getColorFromRange([negativeMin, positiveMin, positiveMax, negativeMax, value]) {
    const positiveColor = "green";
    const negativeColor = "red";
    const inBetweenColor = "orange";
    if (Ember.isNone(value) || doesNotHaveRanges(arguments[0])) return "";else if (value >= positiveMin && value <= positiveMax) return positiveColor;else if (value < negativeMin || value > negativeMax) return negativeColor;else return inBetweenColor;
  }

  var _default = Ember.Helper.helper(getColorFromRange);

  _exports.default = _default;
});