define("epcr-uis/pods/components/pcr-document/pcr-map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CFa5Hbr7",
    "block": "{\"symbols\":[\"context\",\"context\",\"@address\",\"@zoom\",\"@longitude\",\"@latitude\"],\"statements\":[[4,\"if\",[[24,[\"isMapJSLoaded\"]]],null,{\"statements\":[[4,\"if\",[[28,\"and\",[[23,6,[]],[23,5,[]]],null]],null,{\"statements\":[[4,\"g-map\",null,[[\"lat\",\"lng\",\"zoom\"],[[23,6,[]],[23,5,[]],[23,4,[]]]],{\"statements\":[[0,\"      \"],[1,[28,\"g-map-marker\",[[23,2,[]]],[[\"lat\",\"lng\"],[[23,6,[]],[23,5,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"g-map\",null,[[\"lng\",\"lat\",\"zoom\"],[[24,[\"defaultLng\"]],[24,[\"defaultLat\"]],[23,4,[]]]],{\"statements\":[[0,\"      \"],[1,[28,\"g-map-address-marker\",[[23,1,[]]],[[\"address\",\"onLocationChange\"],[[23,3,[]],[28,\"action\",[[23,0,[]],\"onLocationChangeHandler\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/pcr-map/template.hbs"
    }
  });

  _exports.default = _default;
});