define("epcr-uis/pods/components/my-pcrs/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["my-pcrs list"],
    router: Ember.inject.service(),
    myPcr: Ember.inject.service("list/my-pcrs"),

    init() {
      this.loadPcrs.perform();
      return this._super(...arguments);
    },

    loadPcrs: (0, _emberConcurrency.task)(function* () {
      yield this.getSearchId().then(pcrs => this.set("pcrs", pcrs));
    }),
    infinityLoad: (0, _emberConcurrency.task)(function* (pcrs) {
      const {
        onInfinityLoad
      } = this;

      try {
        if (pcrs && !pcrs.reachedInfinity && onInfinityLoad) return yield onInfinityLoad(pcrs);
      } catch (e) {
        throw e;
      }
    })
  });

  _exports.default = _default;
});