define("epcr-uis/pods/pcr/summary/save/route", ["exports", "epcr-uis/mixins/route-tracker"], function (_exports, _routeTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeTracker.default, {
    pcr: Ember.inject.service(),
    pcrStateService: Ember.inject.service("pcr-state"),
    comment: Ember.inject.service("pcr/comment"),
    pcrRouteTracker: Ember.inject.service(),

    model() {
      const pcr = this.modelFor("pcr").pcrDocument;

      if (pcr) {
        this.comment.getComments(pcr.id);
        this.pcrStateService.getPcrState(pcr.id);
      }

      return pcr;
    },

    actions: {
      onCommentSave(commentValue) {
        const {
          pcrId
        } = this.paramsFor("pcr");
        const {
          comment
        } = this;
        return comment.saveComment.perform(pcrId, commentValue).then(() => comment.getComments(pcrId));
      }

    }
  });

  _exports.default = _default;
});