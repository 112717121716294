define("epcr-uis/pods/components/config-loader/component", ["exports", "ember-concurrency", "epcr-uis/config/environment", "epcr-uis/constants"], function (_exports, _emberConcurrency, _environment, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    login: Ember.inject.service(),
    configsService: Ember.inject.service("load-config"),
    userPreferencesService: Ember.inject.service("user-preferences"),
    team: Ember.inject.service(),
    failedToLoadConfig: false,
    tagName: "",
    retryLoadConfig: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(_environment.default.APP.retryLoadConfigDebounceMs);
      this.loadConfig();
    }).drop(),

    loadTeamFromPreferences() {
      this.team.changeTeam(this.userPreferencesService.userPreferences[_constants.SELECTED_TEAM_CODE]);
    },

    loadConfig() {
      Ember.set(this, "failedToLoadConfig", false);
      return this.configsService.loadConfigs().then(() => {
        this.loadTeamFromPreferences();
        const {
          previousTransition
        } = this.configsService;

        if (previousTransition) {
          previousTransition.retry();
          this.configsService.previousTransition = null;
        } else {
          this.router.transitionTo("home");
        }
      }).catch(() => {
        Ember.set(this, "failedToLoadConfig", true);
      });
    },

    init() {
      this._super(...arguments);

      this.loadConfig();
    },

    actions: {
      onLogout() {
        this.login.invalidateSession();
      }

    }
  });

  _exports.default = _default;
});