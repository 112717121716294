define("epcr-uis/mixins/member-source", ["exports", "epcr-uis/helpers/promise-converter"], function (_exports, _promiseConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    employee: Ember.inject.service(),
    membersSource: Ember.computed(function () {
      return query => (0, _promiseConverter.promiseToObjectsConverter)(this.dataSource(query), ["code", "externalId", "firstName", "lastName", "positions"]);
    }),

    dataSource(query) {
      return this.employee.queryEmployees(query, this.station);
    }

  });

  _exports.default = _default;
});