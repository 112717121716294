define("epcr-uis/pods/list/route", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    updates: Ember.inject.service(),
    news: Ember.inject.service(),
    myPcrs: Ember.inject.service("list/my-pcrs"),

    model() {
      const {
        androidUpdatesTimeInterval,
        myPcrsCountTimeInterval,
        newsTimeInterval
      } = _environment.default.APP;
      this.updates.watchAvailableUpdates(androidUpdatesTimeInterval);
      this.myPcrs.totalHitsWatcher(myPcrsCountTimeInterval);
      this.news.newsWatcher(newsTimeInterval);
    },

    deactivate() {
      this.myPcrs.stopTotalHitsWatcher();
      this.news.stopNewsWatcher();
      this.updates.stopUpdatesWatcher();
    },

    actions: {
      navigateToRoute(route) {
        this.router.transitionTo(route);
      }

    }
  });

  _exports.default = _default;
});