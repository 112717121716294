define("epcr-uis/mirage/scenarios/default", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  const getDataFromClassificator = data => {
    const intlService = window.Ember.Application.byName("epcr-uis").__container__.lookup("service:intl");

    intlService.locale[0] = _environment.default.APP.defaultLocale;

    const PCR_CLASSIFICATOR = window.Ember.Application.byName("epcr-uis").__container__.lookup("service:pcrClassificator");

    return PCR_CLASSIFICATOR.get(data);
  };

  const pcrClassificator = "pcr-classificator";

  const createClassificatorWithData = (server, data) => server.create(pcrClassificator, getDataFromClassificator(data));

  const createClassificatorListWithData = (server, count, data) => server.createList(pcrClassificator, count, getDataFromClassificator(data));

  const ageBackend = "age-backend";
  const SIGNATURE_TYPE_1 = "signatures.confirmation.1";
  const DELAY_REASONS_1 = "delayReasons.1";
  const DELAY_REASONS_2 = "delayReasons.2";

  const createHeartAttackCriteria = server => server.create("heart-attack-criteria", {
    contraindication: createClassificatorWithData(server, "heartAttackContraindication.1")
  });

  const createPosition = server => server.create("position", {
    type: createClassificatorWithData(server, "patientPositions.0")
  });

  const createBilling = server => server.create("billing", {
    withCharge: createClassificatorWithData(server, "additionalInformation.billing.0")
  });

  const createTransportation = server => server.create("transportation", {
    patientToVehicle: createClassificatorListWithData(server, 1, "patientToVehicle.0"),
    route: createClassificatorWithData(server, "fromDestination.0"),
    position: createPosition(server),
    hospitalSelectionCriteria: createClassificatorWithData(server, "transportToHospitalDueTo.0"),
    hospitalDepartment: server.create("department")
  });

  const createStrokeCriteria = server => server.create("stroke-criteria", {
    contraindication: createClassificatorWithData(server, "strokeContraindication.0")
  });

  const createCallInformation = server => server.create("call-information", {
    eventSite: server.create("event-site", {
      addressDetails: server.create("address", {
        coordinate: server.create("coordinate")
      })
    })
  });

  const createSideDiseases = server => server.create("side-diseases", {
    types: createClassificatorListWithData(server, 1, "sideDiseases.0")
  });

  const createAge = server => server.create("age", {
    measure: createClassificatorWithData(server, "ageMeasurement.0")
  });

  const createPatientDetails = server => server.create("patient-details", {
    address: server.create("address", {
      coordinate: server.create("coordinate")
    }),
    sideDiseases: createSideDiseases(server),
    phcFacility: server.create("phc-facility"),
    age: createAge(server),
    familyDoctor: server.create("physician")
  });

  const createTraumaCriteria = server => server.create("trauma-criteria", {
    abcdEval: createClassificatorListWithData(server, 1, "forms.trauma.abcdEval.1"),
    injuries: createClassificatorListWithData(server, 1, "forms.trauma.injuries.1"),
    background: createClassificatorListWithData(server, 1, "forms.trauma.background.1"),
    safetyMeasures: createClassificatorListWithData(server, 1, "forms.trauma.safetyMeasures.1"),
    erNotified: true
  });

  const createPatientSafetyEquipment = server => server.create("patient-safety-equipment", {
    airbagsDeployed: createClassificatorListWithData(server, 1, "forms.accident.airBags.1"),
    sittingPlace: createClassificatorWithData(server, "forms.accident.sittingPlaces.1"),
    seatBelt: createClassificatorWithData(server, "forms.accident.seatBelts.1")
  });

  const createCprBeforeArrivalBy = server => server.create("cpr-before-arrival-by", {
    aedUsedBy: createClassificatorWithData(server, "death.cprUsedAed.1"),
    cprStartedBy: createClassificatorWithData(server, "death.cprStartedBy.1")
  });

  const createBirthCriteria = server => server.create("birth-criteria", {
    apgar5Min: createClassificatorWithData(server, "forms.birth.apgar.1"),
    apgar1Min: createClassificatorWithData(server, "forms.birth.apgar.2")
  });

  const createClinicalDeath = server => server.create("clinical-death", {
    witnesses: createClassificatorListWithData(server, 1, "death.witnesses.0"),
    restoredBloodCirculationBy: createClassificatorListWithData(server, 1, "death.restoredBloodCirculationBy.0"),
    cprBeforeArrivalBy: createCprBeforeArrivalBy(server)
  });

  const createDeathStatement = server => server.create("death-statement", {
    deathSigns: createClassificatorListWithData(server, 1, "deathSigns.0")
  });

  const createProcedureTreatment = (server, count) => server.createList("procedure-treatment", count, {
    procedure: createClassificatorWithData(server, "flowchart.procedures.0"),
    conditionChange: createClassificatorWithData(server, "flowchart.conditionChange.0")
  });

  const createPatientTreatment = server => server.create("patient-treatment", {
    helpProtocol: createClassificatorWithData(server, "helpProtocol.0"),
    medicamentTreatment: [],
    procedureTreatment: createProcedureTreatment(server, 1),
    inventory: server.createList("inventory", 1)
  });

  const createPatientDiagnosis = server => server.create("patient-diagnosis", {
    disorderType: createClassificatorWithData(server, "narrative.type.1")
  });

  const createInfoShareAgreement = server => server.create("info-share-agreement", {
    signature: server.create("signature"),
    signatureType: createClassificatorWithData(server, "signatures.signatureTypes.1"),
    confirmation: createClassificatorWithData(server, SIGNATURE_TYPE_1)
  });

  const createMedicalHelpAgreement = server => server.create("medical-help-agreement", {
    signature: server.create("signature"),
    signatureType: createClassificatorWithData(server, "signatures.signatureTypes.0"),
    confirmationToReceiveMedicalHelp: createClassificatorWithData(server, SIGNATURE_TYPE_1),
    confirmationToHospital: createClassificatorWithData(server, SIGNATURE_TYPE_1)
  });

  const createAgreementsSignatures = server => server.create("agreements-signatures", {
    hospitalDoctorSignature: server.create("hospital-doctor-signature"),
    teamLeaderSignature: server.create("team-leader-signature"),
    medicalHelpAgreement: createMedicalHelpAgreement(server),
    infoShareAgreement: createInfoShareAgreement(server),
    narcoticDrugsVerification: server.create("narcotic-drugs-verification")
  });

  const createTeamActivation = server => server.create("team-activation", {
    team: server.create("team"),
    deliveryType: createClassificatorWithData(server, "deliveryType.0"),
    activationProtocol: createClassificatorWithData(server, "activationProtocols.default.1")
  });

  const createDelays = server => server.create("delays", {
    toPatient: [createClassificatorWithData(server, DELAY_REASONS_1), createClassificatorWithData(server, DELAY_REASONS_2)],
    toEventSite: [createClassificatorWithData(server, DELAY_REASONS_1), createClassificatorWithData(server, DELAY_REASONS_2)],
    toHospital: [createClassificatorWithData(server, DELAY_REASONS_1), createClassificatorWithData(server, DELAY_REASONS_2)]
  });

  const createBreathing = server => server.create("breathing", {
    rightLung: createClassificatorListWithData(server, 1, "pcrDocumentVitalParams.condition.lung.0"),
    leftLung: createClassificatorListWithData(server, 1, "pcrDocumentVitalParams.condition.lung.1"),
    quality: createClassificatorWithData(server, "pcrDocumentVitalParams.condition.breathing.1")
  });

  const createCirculation = server => server.create("circulation", {
    crtLte2Sec: createClassificatorWithData(server, "pcrDocumentVitalParams.condition.circulation.crtLte2Sec.1"),
    radialArteryPulse: createClassificatorWithData(server, "pcrDocumentVitalParams.condition.circulation.radialArteryPulse.1"),
    skinCondition: createClassificatorWithData(server, "pcrDocumentVitalParams.condition.circulation.skinCondition.1"),
    skinTemperature: createClassificatorWithData(server, "pcrDocumentVitalParams.condition.circulation.skinTemp.1"),
    skinColor: createClassificatorWithData(server, "pcrDocumentVitalParams.condition.circulation.skinColor.1")
  });

  const createGcs = server => server.create("gcs", {
    motorResponse: createClassificatorWithData(server, "pcrDocumentVitalParams.gcs.motorResponse.1"),
    verbalResponse: createClassificatorWithData(server, "pcrDocumentVitalParams.gcs.verbalResponse.1"),
    eyeResponse: createClassificatorWithData(server, "pcrDocumentVitalParams.gcs.eyeResponse.1")
  });

  const createConsciousness = server => server.create("consciousness", {
    rightEye: createClassificatorListWithData(server, 1, "pcrDocumentVitalParams.condition.consciousness.eyesCondition.0"),
    leftEye: createClassificatorListWithData(server, 1, "pcrDocumentVitalParams.condition.consciousness.eyesCondition.1"),
    avpu: createClassificatorWithData(server, "pcrDocumentVitalParams.condition.consciousAVPU.1"),
    gcs: createGcs(server)
  });

  const createEcg = server => server.create("ecg", {
    heartRhythm: createClassificatorWithData(server, "pcrDocumentVitalParams.ECG.heartRhythm.0"),
    stSegment: createClassificatorWithData(server, "pcrDocumentVitalParams.ECG.stSegment.0")
  });

  const createPatientAssessments = (server, type) => server.create("patient-assessment", {
    vitals: server.create("vitals", {
      pain: createClassificatorWithData(server, "pcrDocumentVitalParams.pain.0")
    }),
    breathing: createBreathing(server),
    circulation: createCirculation(server),
    consciousness: createConsciousness(server),
    ecg: createEcg(server),
    type: createClassificatorWithData(server, `pcrDocumentVitalParams.assessmentTypes.${type}`)
  });

  const createMetaData = server => server.create("metadata", {
    editTimesRequests: server.create("edit-times-requests", {
      onRouteTime: server.create("time-change-request"),
      arrivedTime: server.create("time-change-request")
    })
  });

  const usedMedicamentScenario = server => {
    const model = "used-medicament-medicament";
    server.createList(model, 2);
    server.create(model, {
      eventId: 123,
      medicaments: [{
        code: 666,
        notes: "bla",
        amount: 5
      }, {
        code: 666,
        notes: "",
        amount: 7
      }, {
        code: 666,
        notes: "wow",
        amount: 9
      }]
    });
    server.create(model);
  };

  function _default(server) {
    server.create("patient");
    server.create("pcr-state");
    server.create("user-preferences");
    server.createList("pcr-attachment", 2);
    server.createList("icd", 30);
    server.createList("phc", 10);
    server.createList("physician", 10);
    server.createList("street", 30);
    server.createList("employee", 10);
    server.createList("department", 3);
    server.createList("recommendation", 2);
    server.createList("comment", 5);
    server.create("pcr", {
      times: server.create("time"),
      category: createClassificatorWithData(server, "categories.0"),
      callInformation: createCallInformation(server),
      teamActivation: createTeamActivation(server),
      delays: createDelays(server),
      patientDetails: createPatientDetails(server),
      patientDiagnosis: createPatientDiagnosis(server),
      patientTreatment: createPatientTreatment(server),
      agreementsSignatures: createAgreementsSignatures(server),
      injuryLocation: server.create("injury-location"),
      eventCancelReason: null,
      patientAssessments: [createPatientAssessments(server, 0)],
      strokeCriteria: createStrokeCriteria(server),
      traumaCriteria: createTraumaCriteria(server),
      patientSafetyEquipment: createPatientSafetyEquipment(server),
      heartAttackCriteria: createHeartAttackCriteria(server),
      birthCriteria: createBirthCriteria(server),
      eventNotifications: server.create("event-notifications"),
      deathStatement: createDeathStatement(server),
      clinicalDeath: createClinicalDeath(server),
      transportation: createTransportation(server),
      noTransportation: server.create("no-transportation"),
      billing: createBilling(server),
      transportationType: createClassificatorWithData(server, "dispositions.0"),
      metadata: createMetaData(server),
      trip: server.create("trip")
    });
    usedMedicamentScenario(server);
    server.createList("my-pcr", 1, {
      age: server.create(ageBackend)
    });
    server.createList("active-pcr", 1, {
      age: server.create(ageBackend)
    });
    server.createList("verified-pcr", 10, {
      age: server.create(ageBackend)
    });
    server.createList("used-medicament", 3);
    server.create("pcr-header");
  }
});