define("epcr-uis/pods/components/pcr-document/vitals-summary/component", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pcrClassificator: Ember.inject.service(),
    pcrTime: Ember.inject.service("pcr/time"),
    classNames: ["vitals-table"],
    assessmentsLimit: _environment.default.APP.assessmentsLimit,
    allAssessments: Ember.computed(function () {
      return this.pcrClassificator.pcrDocumentVitalParams.assessmentTypes;
    }),

    willDestroyElement() {
      this._super(...arguments);

      const animation = $(".animating");
      if (animation.length) animation.remove();
    }

  });

  _exports.default = _default;
});