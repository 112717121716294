define("epcr-uis/helpers/get-gcs-sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getGcsSum = getGcsSum;
  _exports.default = void 0;

  function getGcsSum([eyeResponse, verbalResponse, motorResponse]) {
    return eyeResponse && verbalResponse && motorResponse ? eyeResponse + verbalResponse + motorResponse : null;
  }

  var _default = Ember.Helper.extend({
    compute([eyeResponse, verbalResponse, motorResponse]) {
      return getGcsSum([eyeResponse, verbalResponse, motorResponse]);
    }

  });

  _exports.default = _default;
});