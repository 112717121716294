define("epcr-uis/pods/components/single-select-with-code/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uBzfGvY/",
    "block": "{\"symbols\":[\"List\",\"@ui\"],\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[24,[\"ui\",\"template\"]],\"expected `ui.template` to be a contextual component but found a string. Did you mean `(component ui.template)`? ('epcr-uis/pods/components/single-select-with-code/template.hbs' @ L1:C3) \"],null]],null,{\"statements\":[[4,\"if\",[[23,2,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,2,[\"model\",\"code\"]],false],[0,\"\\n\"],[4,\"if\",[[23,2,[\"model\",\"name\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"comment\"],[8],[0,\"- \"],[1,[23,2,[\"model\",\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[24,[\"ui\",\"options-list-template\"]],\"expected `ui.options-list-template` to be a contextual component but found a string. Did you mean `(component ui.options-list-template)`? ('epcr-uis/pods/components/single-select-with-code/template.hbs' @ L10:C3) \"],null]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option-template\"]],\"expected `List.option-template` to be a contextual component but found a string. Did you mean `(component List.option-template)`? ('epcr-uis/pods/components/single-select-with-code/template.hbs' @ L11:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"b\",true],[8],[1,[23,1,[\"option-model\",\"code\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"option-model\",\"name\"]]],null,{\"statements\":[[0,\"      - \"],[1,[23,1,[\"option-model\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/single-select-with-code/template.hbs"
    }
  });

  _exports.default = _default;
});