define("epcr-uis/pods/components/pcr-document/tools/pediatric-calculator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kWWWdROC",
    "block": "{\"symbols\":[\"Notification\",\"Options\",\"results\"],\"statements\":[[4,\"let\",[[28,\"component\",[\"side-bar/notification\"],null],[28,\"component\",[\"pcr-document/tools/options\"],null],[28,\"component\",[\"pcr-document/tools/pediatric-calculator/results\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"section-header\",[],[[\"@title\",\"@icon\"],[[28,\"t\",[\"headings.tools.pediCalc.title\"],null],\"calculator icon big\"]]],[0,\"\\n  \"],[5,\"ui-container\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[]],[],[[\"@selected\",\"@rows\"],[[22,\"age\"],[22,\"rows\"]]]],[0,\"\\n\"],[4,\"if\",[[24,[\"age\"]]],null,{\"statements\":[[0,\"      \"],[6,[23,3,[]],[],[[\"@result\"],[[24,[\"age\",\"values\"]]]]],[0,\"\\n      \"],[7,\"small\",true],[10,\"class\",\"grey\"],[8],[0,\"\\n        * Pateikiamos trys svorio reikšmės – 25-50-75 procentilės. Tai reiškia, kad 25% vaikų sveria mažiau už pirmąją\\n        svorio reikšmę, 50% – mažiau už antrąją, 75% – mažiau už trečiąją.\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,[23,1,[]],[],[[\"@title\"],[[28,\"t\",[\"headings.tools.pediCalc.fillAge\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2,3]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/tools/pediatric-calculator/template.hbs"
    }
  });

  _exports.default = _default;
});