define("epcr-uis/pods/used-medicament/medicament/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTSerializer = _emberData.default.RESTSerializer;

  var _default = RESTSerializer.extend({
    normalizeResponse(store, primaryModelClass, payload, id, type) {
      payload.map((el, index) => el.id = index);
      return this._super(store, primaryModelClass, {
        "usedMedicament/medicament": payload
      }, id, type);
    }

  });

  _exports.default = _default;
});