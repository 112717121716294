define("epcr-uis/helpers/count-array-elements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countArrayElements = countArrayElements;
  _exports.default = void 0;

  function countArrayElements([array, key, expectedValue]) {
    if (!array || !array.reduce || !expectedValue) return 0;
    return array.reduce((accumulator, value) => {
      const arrayValue = Ember.get(value, key);
      return accumulator + (arrayValue === expectedValue ? 1 : 0);
    }, 0);
  }

  var _default = Ember.Helper.helper(countArrayElements);

  _exports.default = _default;
});