define("epcr-uis/pods/pcr/patient-assessment/circulation/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _fragment.default.extend({
    skinColor: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    skinTemperature: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    skinCondition: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    radialArteryPulse: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    crtLte2Sec: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    rash: attr("boolean"),
    isNotFilled: Ember.computed("skinColor.code", "skinTemperature.code", "skinCondition.code", function () {
      const keys = ["skinColor", "skinTemperature", "skinCondition"];

      for (let i = 0; i < keys.length; i++) {
        if (this.isFieldFilled(keys[i])) {
          return false;
        }
      }

      return true;
    }),

    isFieldFilled(key) {
      return Boolean(this.get(key));
    }

  });

  _exports.default = _default;
});