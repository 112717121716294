define("epcr-uis/pods/barcode/service", ["exports", "epcr-uis/helpers/window-wrapper"], function (_exports, _windowWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isScanningPossible: Ember.computed(function () {
      return (0, _windowWrapper.hasMethod)("window.barcode.scanPersonalId");
    }),

    scanPID(callback) {
      if (this.isScanningPossible) {
        Ember.set(this, "callback", callback);
        (0, _windowWrapper.callMethod)("window.barcode.scanPersonalId", "barcode.setPID");
      }
    },

    setPID(id) {
      return this.callback(id);
    }

  });

  _exports.default = _default;
});