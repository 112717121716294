define("epcr-uis/pods/list/used-medicaments/service", ["exports", "ember-concurrency", "epcr-uis/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    formatDate: date => date.toISOString(false),
    queryUsedMedicamentsTask: (0, _emberConcurrency.task)(function* ({
      from,
      to
    }) {
      yield (0, _emberConcurrency.timeout)(_environment.default.APP.defaultDebounceMs);
      return yield this.store.query("used-medicament", {
        from: this.formatDate(from),
        to: this.formatDate(to)
      });
    }).restartable(),
    queryMedicamentEvents: (0, _emberConcurrency.task)(function* (medicament, fromDate, toDate) {
      return yield this.store.query("used-medicament/medicament", {
        medicament,
        from: this.formatDate(fromDate),
        to: this.formatDate(toDate)
      });
    })
  });

  _exports.default = _default;
});