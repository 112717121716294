define("epcr-uis/pods/pcr/patient-safety-equipment/model", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "epcr-uis/helpers/is-object-filled"], function (_exports, _fragment, _attributes, _isObjectFilled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = _fragment.default.extend({
    seatBelt: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    sittingPlace: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    airbagsDeployed: (0, _attributes.fragmentArray)(PCR_CLASSIFICATOR),
    isFilled: Ember.computed("seatBelt.code", "sittingPlace.code", "airbagsDeployed.@each.code", function () {
      const keys = ["seatBelt", "sittingPlace", "airbagsDeployed"];
      return (0, _isObjectFilled.isObjectFilled)([keys, this]);
    })
  });

  _exports.default = _default;
});