define("epcr-uis/pods/active-pcr/adapter", ["exports", "epcr-uis/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    model: "active-pcr-list",

    query(store, type, query) {
      const queryParams = `?${$.param(query)}`;
      const url = this.mainPath(this.model) + queryParams;
      return this.ajax(url, "POST", {
        data: query
      }); // sitas paimtas is ember-data, kad "GET" pakeistumem i "POST".
    }

  });

  _exports.default = _default;
});