define("epcr-uis/pods/team/service", ["exports", "epcr-uis/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    can: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    changeCount: 0,

    isNewTeamCode(teamCode) {
      return this.activeTeam !== teamCode;
    },

    changeTeam(teamCode) {
      if (this.isNewTeamCode(teamCode)) {
        this.userPreferences.updateKeyValue(_constants.SELECTED_TEAM_CODE, teamCode);
        Ember.set(this, "activeTeam", teamCode);
      }
    },

    changeTeamAndIncrementOnChange(teamCode) {
      teamCode && this.isNewTeamCode(teamCode) && Ember.set(this, "changeCount", this.changeCount + 1);
      this.changeTeam(teamCode);
    }

  });

  _exports.default = _default;
});