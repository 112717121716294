define("epcr-uis/mirage/factories/verified-pcr", ["exports", "epcr-uis/mirage/factories/my-pcr"], function (_exports, _myPcr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _myPcr.default.extend();

  _exports.default = _default;
});