define("epcr-uis/mirage/factories/address", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const englishAddress = () => ({
    municipality: "Kaunas city mun.",
    house: "151A",
    flat: "44",
    additionalInformation: "Angry dog",
    street: "Griunvaldo st.",
    city: "Kaunas",
    eldership: "Kaunas city eldership",
    crossingStreet: "not implemented"
  });

  const lithuanianAddress = () => ({
    municipality: "Kauno m. sav.",
    house: "151A",
    flat: "44",
    additionalInformation: "Yra piktas suo",
    street: "Griunvaldo g.",
    city: "Kauno m.",
    eldership: "Kauno m. sen.",
    crossingStreet: "not implemented"
  });

  var _default = _emberCliMirage.Factory.extend((0, _isEnglish.isEnglish)() ? englishAddress() : lithuanianAddress());

  _exports.default = _default;
});