define("epcr-uis/pods/components/pcr-routes/tools/apgar-calculator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pKI/qANm",
    "block": "{\"symbols\":[\"@pcr\"],\"statements\":[[5,\"route-content-loader\",[],[[\"@promise\"],[[22,\"apgarCalcLoadPromise\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"pcr-document/tools/apgar-calculator\",[],[[\"@pcr\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-routes/tools/apgar-calculator/template.hbs"
    }
  });

  _exports.default = _default;
});