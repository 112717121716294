define("epcr-uis/pods/pcr/summary/validations/route", ["exports", "epcr-uis/mixins/route-tracker", "epcr-uis/mixins/metrics-tracker"], function (_exports, _routeTracker, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_metricsTracker.default, _routeTracker.default, {
    validation: Ember.inject.service(),
    simpleAuthFetch: Ember.inject.service(),
    androidSimpleAuthFetch: Ember.inject.service("simple-auth-fetch/android"),
    android: Ember.inject.service(),

    model() {
      return this.modelFor("pcr");
    },

    actions: {
      onValidatePcr(pcr) {
        const simpleAuthFetch = this.android.isAndroid() ? this.androidSimpleAuthFetch : this.simpleAuthFetch;
        this.trackEvent("Validate", "Manual pcr validation");
        return this.validation.validatePcr.perform(pcr, simpleAuthFetch);
      }

    }
  });

  _exports.default = _default;
});