define("epcr-uis/pods/components/config/control-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    features: Ember.inject.service(),

    toggleFeature(featureName) {
      const {
        features
      } = this;

      if (features.isEnabled(featureName)) {
        features.disable(featureName);
        return false;
      } else {
        features.enable(featureName);
        return true;
      }
    },

    actions: {
      toggle(featureName) {
        const enabled = this.toggleFeature(featureName);
        this.onChange && this.onChange(featureName, enabled);
      }

    }
  });

  _exports.default = _default;
});