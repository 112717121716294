define("epcr-uis/pods/pcr/edit-times-requests/model", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    onRouteTime: (0, _attributes.fragment)("pcr/edit-times-requests/request"),
    arrivedTime: (0, _attributes.fragment)("pcr/edit-times-requests/request")
  });

  _exports.default = _default;
});