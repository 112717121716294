define("epcr-uis/pods/components/comments-list/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yb7D0evM",
    "block": "{\"symbols\":[\"@commentValue\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n  \"],[1,[28,\"textarea\",null,[[\"value\",\"placeholder\"],[[23,1,[]],[28,\"t\",[\"summaryInfo.comment\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/comments-list/input/template.hbs"
    }
  });

  _exports.default = _default;
});