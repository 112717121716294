define("epcr-uis/pods/components/pcr-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["pcr-list"],
    lockedPcr: Ember.inject.service(),
    noPcrsFoundMessage: "message.noPcrsFound",
    actions: {
      onPcrClicked(pcrId, isLocked) {
        if (!isLocked && this.onPcrClicked) {
          Ember.set(this, "isTransitingToPcr", true);
          this.onPcrClicked(pcrId).finally(() => Ember.set(this, "isTransitingToPcr", false));
        }
      }

    }
  });

  _exports.default = _default;
});