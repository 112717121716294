define("epcr-uis/pods/pcr/transportation/department/service", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const favouriteDepartments = {
    99: ["397-1", "397-4", "397-20"],
    31: ["424-1", "424-4", "424-8", "424-9", "4740-0"],
    // Panevėžys
    32: ["424-1", "424-4", "424-8", "424-9"],
    24: ["430-1", "426-1", "427-1", "26-1", "430-2"],
    3: ["469-1", "424-1", "421-0", "339-1"],
    39: ["420-1", "424-1", "421-0"],
    56: ["99-1", "363-1", "364-1", "355-1", "371-1", "356-1"],
    // Vilniaus rajonas
    26: ["287-1", "339-1", "99-1", "355-1"],
    43: ["490-1", "363-1", "99-1", "355-1"],
    // Šalčininkai
    51: ["160-1", "363-1", "99-1", "356-1", "355-1"],
    52: ["489-1", "99-1", "355-1", "363-1", "371-1", "26-1"],
    // Ukmergė
    53: ["339-1", "355-1", "99-1", "363-1"],
    57: ["468-1", "339-1", "377-1", "421-0"],
    59: ["377-1", "339-1", "99-1", "355-1"],
    6: ["103-1", "363-1", "99-1", "355-1"],
    8: ["363-1", "99-1", "364-1", "362-1", "371-1", "356-1", "613-0", "355-1"],
    9: ["467-1", "377-1", "339-1", "613-0", "355-1"],
    1: ["510-1", "573-1", "554-7", "26-1", "510-2"],
    10: ["413-1", "26-1", "26-2", "554-7"],
    11: ["388-1", "391-2", "391-2", "391-7"],
    12: ["460-1", "26-1", "400-7", "401-8", "410-1"],
    13: ["411-1", "26-1", "400-1", "26-2", "554-7"],
    15: ["400-1", "26-1", "10314-1", "397-1", "401-8", "26-2", "397-4"],
    16: ["400-1", "26-1", "26-2", "401-8", "10314-1", "397-4", "554-7"],
    18: ["409-1", "26-1", "554-7", "401-8", "26-2"],
    19: ["389-1", "391-1", "391-7", "391-2"],
    2: ["385-1", "391-1", "442-1", "391-7"],
    20: ["450-1", "451-1", "452-1", "453-1", "6427-1", "450-8", "451-7"],
    21: ["447-1", "450-1", "452-1", "451-1", "453-1"],
    25: ["442-1", "450-1", "391-2", "452-1", "391-1", "391-9", "451-1", "138-0"],
    // Mažeikiai
    27: ["450-1", "451-1", "243-0", "453-1", "452-1", "450-8"],
    // Neringa
    29: ["391-1", "387-1", "391-7", "391-2"],
    34: ["441-1", "450-1", "452-1", "453-1", "450-9", "452-7"],
    // Plungė
    35: ["408-1", "26-1", "400-1", "400-7", "26-2"],
    // Prienai
    36: ["386-1", "391-1", "391-2", "391-7"],
    37: ["410-1", "26-1", "554-7", "26-2", "401-8"],
    42: ["429-1", "26-1", "400-7", "460-1", "428-1"],
    44: ["391-1", "391-2", "391-7", "390-1", "392-0"],
    46: ["449-1", "450-1", "452-1", "450-9"],
    49: ["259-1", "452-1", "450-1", "26-1", "453-1"],
    5: ["483-1", "510-1", "355-1", "99-1"],
    50: ["443-1", "450-1", "452-1", "453-1", "451-1"],
    54: ["513-1", "510-1", "363-1", "99-1", "355-1", "359-1", "371-1", "365-8"],
    // Varėna
    55: ["428-1", "26-1", "26-2", "401-8", "404-0", "430-1", "429-1", "554-7"],
    // Vilkaviškis
    58: ["459-1", "450-1", "259-1", "452-1", "453-1"],
    7: ["450-1", "451-1", "452-1", "448-1", "453-1"]
  };

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    login: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.getFavouriteDepartmentsMapTask.perform();
    },

    departmentsCache: Ember.computed("getDepartmentsByStationIdTask.{last,isRunning}", {
      get() {
        const result = this.getDepartmentsByStationIdTask;
        return Ember.get(result, "last.value.length") ? result.last.value : [];
      },

      set: (key, value) => value
    }),

    getDepartment(code) {
      return this.store.findRecord("department", code);
    },

    queryDepartment(query, longitude, latitude) {
      return this.store.query("department", {
        query,
        longitude,
        latitude
      });
    },

    queryDepartmentsByHospitalCode(hospitalCode) {
      return this.store.query("department", {
        hospitalCode
      });
    },

    getDepartmentCodeTask: (0, _emberConcurrency.task)(function* (code) {
      return yield this.getDepartment(code);
    }),
    getDepartmentsByStationIdTask: (0, _emberConcurrency.task)(function* () {
      if (Ember.get(this, "departmentsCache.length")) return yield this.departmentsCache;
      yield this.getFavouriteDepartmentsMapTask.last;
      const {
        stationId
      } = this.login;
      const departmentsCodes = this.favouriteDepartmentsMap[stationId] || [];
      const taskInstances = [];
      const departments = [];
      departmentsCodes.forEach(code => taskInstances.push(this.getDepartmentCodeTask.perform(code)));
      const resolvedInstances = yield (0, _emberConcurrency.all)(taskInstances);
      resolvedInstances.forEach((item, index) => {
        const department = item.toJSON();
        department.favourite = index + 1;
        departments.push(Object.assign({}, department));
      });
      return departments;
    }),
    getFavouriteDepartmentsMapTask: (0, _emberConcurrency.task)(function* () {
      return yield favouriteDepartments;
    }),
    favouriteDepartmentsMap: Ember.computed("getFavouriteDepartmentsMapTask.last.value", function () {
      return Ember.get(this.getFavouriteDepartmentsMapTask, "last.value");
    })
  });

  _exports.default = _default;
});