define("epcr-uis/pods/pcr/attachment/service", ["exports", "ember-concurrency", "epcr-uis/helpers/generate-attachment-url", "epcr-uis/helpers/window-wrapper"], function (_exports, _emberConcurrency, _generateAttachmentUrl, _windowWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MINUTE_IN_MILLIS = 60000;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    android: Ember.inject.service(),
    simpleAuthFetch: Ember.inject.service(),
    uploadingAttachmentsQueue: [],
    retryIntervals: [MINUTE_IN_MILLIS, MINUTE_IN_MILLIS * 2, MINUTE_IN_MILLIS * 3],

    addUploadingAttachmentToQueue(uploadInstance) {
      this.uploadingAttachmentsQueue.pushObject(uploadInstance);
    },

    uploadedAttachmentObserver: Ember.observer("uploadingAttachmentsQueue.@each.{isRunning,isCanceling}", "lastUploadError", async function () {
      this.uploadingAttachmentsQueue.forEach(element => (!element.isRunning || element.isCanceling || !this.isRetriableError(this.lastUploadError)) && this.uploadingAttachmentsQueue.removeObject(element) && !this.lastUploadError && this.retrieveAttachments.perform(this.pcrId));
    }),
    retrieveAttachments: (0, _emberConcurrency.task)(function* () {
      const url = (0, _generateAttachmentUrl.generateAttachmentUrl)([this.pcrId]);
      return yield this.simpleAuthFetch.fetchRequest(url, "GET").then(response => response.json());
    }).restartable(),
    removeAttachmentTask: (0, _emberConcurrency.task)(function* (attachmentId) {
      Ember.set(this, "lastUploadError", null);
      const url = (0, _generateAttachmentUrl.generateAttachmentUrl)([this.pcrId, attachmentId]);
      return yield this.simpleAuthFetch.fetchRequest(url, "DELETE");
    }),

    async uploadImage(file, pcrId) {
      const {
        name: title
      } = file;
      await file.readAsArrayBuffer().then(arrayBuffer => {
        const url = `${(0, _generateAttachmentUrl.generateAttachmentUrl)([pcrId])}?fileName=${title}`;
        return this.simpleAuthFetch.fetchRequest(url, "POST", arrayBuffer, "application/octet-stream", false).then(response => response.text());
      });
    },

    async onServerAttachmentUploadError(file, pcrId, retryCount, retryIntervals) {
      if (retryCount < retryIntervals.length) {
        Ember.set(this, "uploadAttachment.last.isRetrying", true);
        await (0, _emberConcurrency.timeout)(retryIntervals[retryCount]);
        return await this.uploadAttachment.perform(file, pcrId, retryCount + 1);
      } else {
        return Ember.set(this, "uploadAttachment.last.isRetrying", false);
      }
    },

    isRetriableError: e => e !== "Request Entity Too Large" && e !== "PCR_DATA_LIMIT_EXCEEDED" && e !== "FILE_TOO_LARGE",

    async onUploadError(e, file, pcrId, retryCount) {
      this.isRetriableError(e) ? await this.onServerAttachmentUploadError(file, pcrId, retryCount, this.retryIntervals) : await this.uploadAttachment.last.cancel();
    },

    clearRemoveAttachmentError() {
      const errorKey = "removeAttachmentTask.last.error";
      Ember.get(this, errorKey) && Ember.set(this, errorKey, null);
    },

    uploadAttachment: (0, _emberConcurrency.task)(function* (file, pcrId, retryCount = 0) {
      Ember.set(this, "lastUploadError", null);
      yield this.clearRemoveAttachmentError();
      return yield this.uploadImage(file, pcrId).then(() => this.android.isAndroid() && (0, _windowWrapper.callMethod)("window.attachment.onAttachmentUploaded", file.blob.name)).catch(async error => {
        Ember.set(this, "lastUploadError", error);
        await this.onUploadError(error, file, pcrId, retryCount);
      });
    })
  });

  _exports.default = _default;
});