define("epcr-uis/components/task-reloader", ["exports", "ember-components/components/task-reloader"], function (_exports, _taskReloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _taskReloader.default;
    }
  });
});