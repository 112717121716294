define("epcr-uis/pods/components/pcr-document/signatures/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    signaturePadWidth: 310,
    tagName: "",
    features: Ember.inject.service()
  });

  _exports.default = _default;
});