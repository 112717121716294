define("epcr-uis/pods/pcr/patient-details/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    firstName: attr("upper-case-string"),
    lastName: attr("upper-case-string"),
    personalId: attr("string"),
    birthDate: attr("date-ymd"),
    sex: attr("string"),
    unknown: attr("boolean"),
    age: (0, _attributes.fragment)("pcr/patient-details/age"),
    weight: attr("number"),
    address: (0, _attributes.fragment)("address"),
    patientHomeless: attr("boolean"),
    specialCircumstances: attr("string"),
    notWalking: attr("boolean"),
    medicineConsumed: attr("string"),
    medicineUnknown: attr("boolean"),
    familyDoctor: (0, _attributes.fragment)("pcr/patient-details/physician"),
    phcFacility: (0, _attributes.fragment)("pcr/patient-details/phc-facility"),
    sideDiseases: (0, _attributes.fragment)("pcr/patient-details/side-diseases"),
    helpRefused: attr("boolean"),
    addressMatchesEventSite: attr("boolean") // todo. kolkas niekur nenaudojame
    // notes: attr("string")

  });

  _exports.default = _default;
});