define("epcr-uis/pods/pcr/flowchart/medicament/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6rwQMnsC",
    "block": "{\"symbols\":[\"pcr\"],\"statements\":[[4,\"with\",[[24,[\"model\",\"pcr\"]]],null,{\"statements\":[[0,\"  \"],[5,\"pcr-document/flowchart/medicament-input/new\",[],[[\"@pcr\",\"@teamMembers\",\"@isRightPopupOpen\",\"@transitionTo\"],[[23,1,[]],[23,1,[\"teamActivation\",\"team\",\"members\"]],[24,[\"model\",\"isRightPopupOpen\"]],[28,\"route-action\",[\"transitionTo\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/pcr/flowchart/medicament/new/template.hbs"
    }
  });

  _exports.default = _default;
});