define("epcr-uis/mixins/teams-by-station", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    login: Ember.inject.service(),
    pcrClassificator: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.setTeamsByCurrentStation();
    },

    setTeamsByCurrentStation() {
      const userStation = this.login.getStationId();
      Ember.set(this, "teams", Ember.get(this.pcrClassificator, "stationTeamIds")[userStation]);
    }

  });

  _exports.default = _default;
});