define("epcr-uis/pods/lazy-fragment/model", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lazyAttr = lazyAttr;
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  function lazyAttr(name) {
    const propName = `_lazyData.${name}`;
    return Ember.computed(propName, {
      get() {
        this._readLazyData();

        return this.get(propName);
      },

      set: (key, value) => value
    });
  }

  const LazyFragment = _fragment.default.extend({
    code: attr("string"),
    isPromiseLoading: false,
    isPromiseRejected: false,

    dataSource() {
      this._throwErrorNotImplemented();
    },

    onDataReadSuccess() {
      this._throwErrorNotImplemented();
    },

    onDataReadError() {
      this._throwErrorNotImplemented();
    },

    _throwErrorNotImplemented() {
      throw Error("Not implemented method");
    }

  });

  var _default = LazyFragment.extend({
    _promise: null,
    _lazyData: null,

    _readLazyData() {
      const promise = this._promise;

      if (!promise) {
        Ember.set(this, "_promise", this._readDataFromSource(this.dataSource));
      }
    },

    _readDataFromSource(dataSource) {
      return new Ember.RSVP.Promise(async resolve => {
        try {
          this._safeSet("isPromiseLoading", true);

          this._safeSet("isPromiseRejected", false);

          const data = await dataSource.call(this);
          !Ember.isNone(data) && this._safeSet("_lazyData", this.onDataReadSuccess(data));
        } catch (e) {
          this._safeSet("_lazyData", this.onDataReadError(e));

          this._safeSet("isPromiseRejected", true);
        } finally {
          resolve();

          this._safeSet("isPromiseLoading", false);
        }
      });
    },

    _safeSet(property, value) {
      if (!this.isDestroyed) {
        Ember.set(this, property, value);
      }
    },

    resetFragment() {
      this._safeSet("_promise", null);

      this._safeSet("_lazyData", null);
    }

  });

  _exports.default = _default;
});