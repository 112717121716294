define("epcr-uis/components/click-outside-disabler", ["exports", "ember-components/components/click-outside-disabler"], function (_exports, _clickOutsideDisabler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _clickOutsideDisabler.default;
    }
  });
});