define("epcr-uis/pods/components/pcr-document/patient/family-doctor/component", ["exports", "epcr-uis/helpers/promise-converter", "epcr-uis/constants"], function (_exports, _promiseConverter, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const familyDoctorFullNamePath = "patient.familyDoctor.fullName";

  var _default = Ember.Component.extend({
    tagName: "",
    physician: Ember.inject.service("pcr/patient-details/physician"),
    scrollToField: Ember.inject.service(),
    lastSelectedFamilyDoctor: null,
    isFamilyDoctorUnknown: Ember.computed(familyDoctorFullNamePath, {
      set: (key, value) => value,

      get() {
        const familyDoctorFullName = Ember.get(this, familyDoctorFullNamePath);
        return `${familyDoctorFullName}`.toUpperCase() === _constants.UNKNOWN_INPUT;
      }

    }),
    physicianSource: Ember.computed(function () {
      return query => (0, _promiseConverter.promiseToValueConverter)(this.dataSource(query), "fullName");
    }),

    dataSource(query) {
      return this.physician.queryPhysician(query, this.station);
    },

    actions: {
      scrollToField(elementSelector) {
        return this.scrollToField.animate($(elementSelector), "start");
      },

      onFamilyDoctorUnknown() {
        const familyDoctor = Ember.get(this, familyDoctorFullNamePath);
        if (`${familyDoctor}`.toUpperCase() === _constants.UNKNOWN_INPUT && this.lastSelectedFamilyDoctor !== _constants.UNKNOWN_INPUT) Ember.set(this, familyDoctorFullNamePath, this.lastSelectedFamilyDoctor);else {
          Ember.set(this, "lastSelectedFamilyDoctor", familyDoctor);
          Ember.set(this, familyDoctorFullNamePath, _constants.UNKNOWN_INPUT);
        }
      }

    }
  });

  _exports.default = _default;
});