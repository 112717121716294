define("epcr-uis/mirage/factories/patient-assessment", ["exports", "ember-cli-mirage", "moment"], function (_exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    time: (0, _moment.default)("2017-03-14T16:21:00Z")
  });

  _exports.default = _default;
});