define("epcr-uis/mirage/factories/pcr-attachment", ["exports", "ember-cli-mirage", "moment"], function (_exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    id: i => i > 0 ? "Chuck" : "Norris",
    size: 151,
    createdTime: (0, _moment.default)(),
    token: "77AEAF26B4E718FDD956FDBFC0C178F6"
  });

  _exports.default = _default;
});