define("epcr-uis/mirage/factories/injury-location", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    head: ["AMPUTATION"],
    face: ["AMPUTATION"],
    neck: ["FRACTURE"],
    chest: ["FRACTURE"],
    back: ["AMPUTATION"],
    leftArm: ["PAIN"],
    rightArm: ["PAIN"],
    abdomen: ["PAIN"],
    pelvis: ["SWELLING"],
    leftLeg: ["SWELLING"],
    rightLeg: ["SWELLING"]
  });

  _exports.default = _default;
});