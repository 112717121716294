define("epcr-uis/helpers/get-medicament-mediums-by-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.getMedicamentMediumsByCode = void 0;

  const getMedicamentMediumsByCode = (code, classificator) => Ember.get(classificator, "medicaments").find(medicament => medicament.types ? medicament.types.find(type => type.code === code) : false);

  _exports.getMedicamentMediumsByCode = getMedicamentMediumsByCode;

  var _default = Ember.Helper.extend({
    pcrClassificator: Ember.inject.service(),

    compute([code]) {
      return getMedicamentMediumsByCode(code, this.pcrClassificator);
    }

  });

  _exports.default = _default;
});