define("epcr-uis/pods/news/route", ["exports", "epcr-uis/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    news: Ember.inject.service(),
    userPreferences: Ember.inject.service(),

    model() {
      this.news.getNews.perform();
    },

    setArticleRead(articleId) {
      Ember.set(this.news, "clickedArticleId", articleId);
      this.userPreferences.updateKeyValue(_constants.READ_NEWS, this.news.readNews);
    },

    actions: {
      redirectToNewsArticle(articleId) {
        this.setArticleRead(articleId);
        this.transitionTo("news.article", articleId);
      }

    }
  });

  _exports.default = _default;
});