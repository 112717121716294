define("epcr-uis/pods/show-training/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    features: Ember.inject.service(),
    login: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.login.addObserver("tokenData");
    },

    observeStation: Ember.observer("login.tokenData", function () {
      const permissions = this.get("login.tokenData.perm");
      if (permissions && permissions.includes("epcr.training") && !this.features.isEnabled("demo")) this.features.enable("show-training");else this.features.disable("show-training");
    })
  });

  _exports.default = _default;
});