define("epcr-uis/pods/components/pcr-document/flowchart/procedure-input/component", ["exports", "epcr-uis/mixins/flowchart-validation"], function (_exports, _flowchartValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_flowchartValidation.default, {
    "data-test-id": "procedure-input",
    classNames: ["procedure-input", "input-view"],
    attributeBindings: ["flowchart-id"]
  });

  _exports.default = _default;
});