define("epcr-uis/pods/components/pcr-document/tools/options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "izqIPPCy",
    "block": "{\"symbols\":[\"row\",\"cell\",\"i\"],\"statements\":[[4,\"each\",[[24,[\"rows\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"equal width row\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[\"column\",[28,\"if\",[[28,\"eq\",[[23,2,[\"title\"]],[24,[\"selected\",\"title\"]]],null],\" selected\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onCellClick\",[23,2,[]]],null]],[8],[1,[23,2,[\"title\"]],false],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[7,\"div\",true],[10,\"class\",\"information\"],[8],[0,\"\\n  Visi pateikti duomenys yra rekomenduojamojo pobūdžio ir galutinį sprendimą turi priimti kvalifikuotas\\n  specialistas.\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/tools/options/template.hbs"
    }
  });

  _exports.default = _default;
});