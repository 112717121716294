define("epcr-uis/pods/components/flash-message/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(Ember.Evented, {
    tagName: "",
    notificationManager: Ember.inject.service(),
    message: null,

    init() {
      this._super(...arguments);

      this.notificationManager.on("onNotification", this, "showMessage");
    },

    hideMessage() {
      Ember.set(this, "message", null);
    },

    setVisibility(duration) {
      Ember.run.next(() => document.getElementById("flash-message").style.animationDuration = `${duration}s`);
    },

    showEnqueuedMessages: (0, _emberConcurrency.task)(function* () {
      const notification = this.notificationManager.peekNotification();
      Ember.set(this, "message", notification);
      const duration = notification.durationInSec * 1;
      this.setVisibility(duration);
      yield (0, _emberConcurrency.timeout)(duration * 1000);
      yield this.hideMessage();
    }).enqueue(),

    showMessage() {
      this.showEnqueuedMessages.perform();
    },

    didDestroyElement() {
      this.notificationManager.off("onNotification", this, "showMessage");
    }

  });

  _exports.default = _default;
});