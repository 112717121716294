define("epcr-uis/pods/components/info-news/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["info-news"],
    newsService: Ember.inject.service("news"),
    actions: {
      onTitleClick(id) {
        this.onTitleClick(id);
      }

    }
  });

  _exports.default = _default;
});