define("epcr-uis/helpers/get-classificator-element-by-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getClassificatorElementByCode = getClassificatorElementByCode;
  _exports.default = void 0;

  function getClassificatorElementByCode(key, code, pcrClassificator) {
    if (!pcrClassificator) throw "Pcr classificator not found";
    if (Ember.isNone(code)) return undefined;
    const object = pcrClassificator.get(key);
    if (!object) return {
      code,
      name: code
    };
    const foundObj = object.find(object => object.code === code);
    return foundObj || {
      code,
      name: code
    };
  }

  var _default = Ember.Helper.extend({
    pcrClassificator: Ember.inject.service(),

    compute([key, code]) {
      return getClassificatorElementByCode(key, code, this.pcrClassificator);
    }

  });

  _exports.default = _default;
});