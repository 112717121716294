define("epcr-uis/instance-initializers/touch-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    const deviceService = appInstance.lookup("service:device");

    if (!deviceService.isDesktop()) {
      $("html").addClass("touch-device");
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});