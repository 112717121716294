define("epcr-uis/pods/components/pcr-document/patient/component", ["exports", "ember-concurrency", "epcr-uis/helpers/is-pid-correct", "epcr-uis/mixins/metrics-tracker"], function (_exports, _emberConcurrency, _isPidCorrect, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PERSONAL_ID_PATH = "pcr.patientDetails.personalId";

  var _default = Ember.Component.extend(_metricsTracker.default, {
    pcrClassificator: Ember.inject.service(),
    intl: Ember.inject.service(),
    patientImporter: Ember.inject.service("patient"),
    debounceTimeMS: 1000,

    init() {
      this._super(...arguments);

      if (!this.pcr.patientDetails.unknown) Ember.set(this, "pcr.patientDetails.unknown", false);
    },

    invalidPIDWarning: Ember.computed(function () {
      return {
        type: "warning",
        message: this.intl.t("headings.patientDetails.invalidPid")
      };
    }),
    scanPersonalId: (0, _emberConcurrency.task)(function* () {
      const scanCallback = function (scannedPID) {
        if (scannedPID !== null) {
          Ember.set(this, PERSONAL_ID_PATH, scannedPID);
          this.setBirthDateAndSexFomPID(scannedPID);
        }
      };

      yield this.barcode.scanPID(scanCallback.bind(this));
      this.trackEvent("Scan", "Scanned nin code from barcode reader");
    }),
    isPidNotEmptyAndNotCorrect: Ember.computed(PERSONAL_ID_PATH, function () {
      const personalId = Ember.get(this, PERSONAL_ID_PATH);
      return personalId && !(0, _isPidCorrect.isPidCorrect)([personalId]);
    }),
    containsAllergy: Ember.computed("pcr.patientDetails.sideDiseases.types.[]", function () {
      return this.isTypeSelected("ALLERGY");
    }),
    containsOther: Ember.computed("pcr.patientDetails.sideDiseases.types.[]", function () {
      return this.isTypeSelected("OTHER");
    }),

    isTypeSelected(sideDiseasesType) {
      const types = Ember.get(this, "pcr.patientDetails.sideDiseases.types");
      return types && types.find(type => sideDiseasesType === Ember.get(type, "code"));
    },

    setPatientHomelessValue(value) {
      Ember.set(this, "pcr.patientDetails.patientHomeless", value);
    },

    copyEventSiteAddressToPatientAddress() {
      const attributesToCopy = ["house", "flat", "crossingStreet", "street", "city", "eldership", "municipality", "coordinate.longitude", "coordinate.latitude"];
      const patientAddress = Ember.get(this, "pcr.patientDetails.address");
      const eventSiteAddressDetails = Ember.get(this, "pcr.callInformation.eventSite.addressDetails");
      attributesToCopy.forEach(attribute => Ember.set(patientAddress, attribute, Ember.get(eventSiteAddressDetails, attribute)));
      this.trackEvent("Copy", "Event site address to patient address");
    },

    setPatientBirthDate(birthDate) {
      const approximateAgePath = "pcr.patientDetails.age.approximate";
      if (Ember.get(this, approximateAgePath)) Ember.set(this, approximateAgePath, false);
      Ember.set(this, "pcr.patientDetails.birthDate", birthDate);
    },

    setPatientSex(patientSex) {
      if (!Ember.get(this, "pcr.patientDetails.sex")) Ember.set(this, "pcr.patientDetails.sex", patientSex);
    },

    setBirthDateAndSexFomPID(pid) {
      const patientBirthDate = (0, _isPidCorrect.extractBirthDateFromPID)([pid]);
      const patientSex = (0, _isPidCorrect.extractSexFromPID)([pid]);
      if (patientBirthDate) this.setPatientBirthDate(patientBirthDate);
      if (patientSex) this.setPatientSex(patientSex);
    },

    importPatientTask: (0, _emberConcurrency.task)(function* (query) {
      yield (0, _emberConcurrency.timeout)(this.debounceTimeMS);
      return this.patientImporter.importPatient(query);
    }).restartable(),
    actions: {
      onPidChanged(value) {
        this.setBirthDateAndSexFomPID(value);
      },

      onAddressMatchesSelect() {
        this.copyEventSiteAddressToPatientAddress();
        this.setPatientHomelessValue(null);
        this.trackEvent("Checkbox", "Address matched event site checked");
      }

    }
  });

  _exports.default = _default;
});