define("epcr-uis/pods/top-navigation/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    hasUnfinishedPcrs: false,
    hasUnreadNews: false,
    hasUpdates: false,
    notificationCount: Ember.computed("hasUnfinishedPcrs", "hasUnreadNews", "hasUpdates", function () {
      let count = 0;
      const keys = ["hasUnfinishedPcrs", "hasUnreadNews", "hasUpdates"];
      keys.forEach(key => {
        if (Ember.get(this, key)) count++;
      });
      return count;
    })
  });

  _exports.default = _default;
});