define("epcr-uis/pods/components/pcr-document/pcr-map/component", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const addParam = (params, param, key) => {
    if (param) params.push(`${key}=${encodeURIComponent(param)}`);
  };

  const getParams = () => {
    const params = [];
    const properties = _environment.default["g-map"];
    addParam(params, properties.key, "key");
    addParam(params, properties.version, "v");
    addParam(params, properties.client, "client");
    addParam(params, properties.channel, "channel");
    addParam(params, properties.libraries, "libraries");
    addParam(params, properties.language, "language");
    addParam(params, properties.protocol, "protocol");
    return params;
  };

  const loadScriptResource = (uniqueName, path) => new Ember.RSVP.Promise((resolve, reject) => {
    if (document.getElementById(uniqueName)) return resolve();
    let element = document.createElement("script");
    element.id = uniqueName;
    element.async = true;
    element.defer = true;
    element.src = path;
    element.addEventListener("load", function () {
      resolve(element);
    });
    element.addEventListener("error", () => reject(`Failed to load ${uniqueName} (${path})`));
    const [s] = document.getElementsByTagName("script");
    s.parentNode.insertBefore(element, s);
    return true;
  }, `Loading external script resource ${uniqueName} (${path})`);

  var _default = Ember.Component.extend({
    defaultLat: 55.1700287,
    defaultLng: 22.7624249,
    isMapJSLoaded: false,

    init() {
      this._super(...arguments);

      let source = "//maps.googleapis.com/maps/api/js";
      source += `?${getParams().join("&")}`;
      loadScriptResource("g-map", source).then(() => {
        if (!this.isDestroyed) this.set("isMapJSLoaded", true);
      });
    },

    actions: {
      onLocationChangeHandler(mapContext, markerLat, markerLng) {
        mapContext.set("lat", markerLat);
        mapContext.set("lng", markerLng);
      }

    }
  });

  _exports.default = _default;
});