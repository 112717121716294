define("epcr-uis/helpers/patient-transported-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.patientTransportedTo = patientTransportedTo;
  _exports.default = void 0;

  function patientTransportedTo([item]) {
    if (item) {
      item = item.toString();
      if (item.startsWith("TO_HOSPITAL")) item = "HOSPITAL";else if (item === "TRANSFERRED_TO_TEAM" || item === "HELP_TO_TEAM") item = "TEAM";
    }

    return item;
  }

  var _default = Ember.Helper.helper(patientTransportedTo);

  _exports.default = _default;
});