define("epcr-uis/pods/list/my-pcrs/service", ["exports", "epcr-uis/pods/infinity-params/service", "epcr-uis/config/environment", "epcr-uis/mixins/later-loop"], function (_exports, _service, _environment, _laterLoop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend(_laterLoop.default, {
    topNavigation: Ember.inject.service(),
    totalHits: null,
    hasUnfinishedPcrs: Ember.observer("totalHits", function () {
      Ember.run.schedule("afterRender", () => Ember.set(this, "topNavigation.hasUnfinishedPcrs", !!this.totalHits));
    }),

    getMyPcrs(perPage, action) {
      const url = _environment.default.APP.myPcrUrl;
      return this.getSearchRequest(url).then(response => {
        const json = JSON.parse(response);
        Ember.set(this, "totalHits", json.totalHits);
        Ember.set(this, "perPage", perPage);
        return action && action(json.id, perPage).catch(e => {
          throw e;
        });
      });
    },

    totalHitsWatcher(period) {
      this.runLoop(this.getMyPcrs.bind(this), period);
    },

    stopTotalHitsWatcher() {
      this.cancelLoop();
    }

  });

  _exports.default = _default;
});