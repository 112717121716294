define("epcr-uis/mirage/factories/team-activation", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    dispatcherCode: "6666",
    signalsToSite: true,
    teamType: "BLS"
  });

  _exports.default = _default;
});