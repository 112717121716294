define("epcr-uis/pods/pcr/backend-serialize", ["exports", "epcr-uis/helpers/medicament-use-type-has-size", "epcr-uis/pods/pcr/backend-vars"], function (_exports, _medicamentUseTypeHasSize, _backendVars) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSerializedPcr = _exports.remakeInjuryLocationStructure = void 0;
  const OBJECTS_LIST = (0, _backendVars.OBJECTS)(undefined, undefined, []);
  const LAZY_FRAGMENTS_LIST = (0, _backendVars.LAZY_FRAGMENTS)();

  const convertMember = array => array.map(object => object.member = object.member ? object.member.externalId : undefined);

  const convertMedicaments = meds => meds.map(med => med.medicament = med.medicament.code);

  const convertFragmentToString = (payload, objects = OBJECTS_LIST) => {
    objects.forEach(({
      classificatorE,
      path
    }) => {
      if (classificatorE) {
        const elementPath = path.join(".");
        const fragments = Ember.get(payload, elementPath);

        if (fragments) {
          let updatedFragments = undefined;

          if (Ember.isArray(fragments)) {
            updatedFragments = [];
            fragments.forEach(({
              code
            }) => {
              updatedFragments.push(code);
            });
          } else if (Ember.isPresent(fragments)) {
            updatedFragments = fragments.code;
          }

          Ember.set(payload, elementPath, updatedFragments);
        }
      }
    });
  };

  const convertArray = (array, objects) => {
    array && array.map(object => convertFragmentToString(object, objects()));
  };

  const dealWithTeamActivation = payload => {
    if (Ember.get(payload, "teamActivation.activationProtocol")) payload.teamActivation.activationProtocol = Ember.get(payload, "teamActivation.activationProtocol.code");
  };

  const dealWithMedicaments = payload => {
    if (payload.patientTreatment.medicamentTreatment) {
      convertArray(payload.patientTreatment.medicamentTreatment, _backendVars.MEDICAMENTS_OBJECTS);
      convertMember(payload.patientTreatment.medicamentTreatment);
      convertMedicaments(payload.patientTreatment.medicamentTreatment);
    }
  };

  const dealWithProcedures = payload => {
    if (payload.patientTreatment.procedureTreatment) {
      convertArray(payload.patientTreatment.procedureTreatment, _backendVars.PROCEDURES_OBJECTS);
      convertMember(payload.patientTreatment.procedureTreatment);
    }
  };

  const dealWithInventory = payload => {
    if (payload.patientTreatment.inventory) convertArray(payload.patientTreatment.inventory, _backendVars.INVENTORY_OBJECTS);
  };

  const dealWithPatientTreatment = payload => {
    if (payload.patientTreatment) {
      dealWithMedicaments(payload);
      dealWithProcedures(payload);
      dealWithInventory(payload);
    }
  };

  const convertLazyFragments = payload => {
    LAZY_FRAGMENTS_LIST.forEach(({
      key,
      path,
      modelType
    }) => {
      let value = Ember.get(payload, path);

      if (value) {
        if (modelType === "array") {
          value = value.length ? value.map(object => object[key]) : [];
        } else {
          value = value[key];
        }

        Ember.set(payload, path, value);
      }
    });
  };

  const removeAssessmentUnnecessaryConsciousness = assessments => {
    assessments && assessments.forEach(assessment => {
      if (assessment.consciousness) {
        const {
          type
        } = assessment.consciousness;
        if (type === "GKS") delete assessment.consciousness.avpu;else if (type === "AVPU") delete assessment.consciousness.gcs;
        delete assessment.consciousness.type;
      }
    });
  };

  const removeDeletedTimesFlags = times => times && Object.keys(times).forEach(key => {
    if (key.includes("Deleted")) delete times[key];
  });

  const clearMedicamentSizeIfNotNeeded = patientTreatment => {
    if (patientTreatment && Ember.get(patientTreatment, "medicamentTreatment.length")) {
      patientTreatment.medicamentTreatment.forEach(medicament => {
        if (!(0, _medicamentUseTypeHasSize.medicamentUseTypeHasSize)([medicament.useType])) medicament.size && delete medicament.size;
      });
    }
  };

  const convertCamelCaseToUnderscore = string => string.split(/(?=[A-Z])/).join("_").toUpperCase();

  const removeShareWithPerson = payload => {
    const infoShareAgreementPath = "agreementsSignatures.infoShareAgreement";
    const confirmation = Ember.get(payload, `${infoShareAgreementPath}.confirmation`);
    if (confirmation === false || Ember.isEmpty(confirmation) && Ember.get(payload, infoShareAgreementPath)) Ember.set(payload, `${infoShareAgreementPath}.shareWithPerson`, undefined);
  };

  const removeSignaturesRepresentative = payload => {
    const medicalHelpAgreementPath = "agreementsSignatures.medicalHelpAgreement";
    const signatureType = Ember.get(payload, `${medicalHelpAgreementPath}.signatureType`);
    const signerName = Ember.get(payload, `${medicalHelpAgreementPath}.signature.signerName`);

    if (signatureType && signerName && signatureType !== "SIGNED_BY_REPRESENTATIVE") {
      Ember.set(payload, `${medicalHelpAgreementPath}.signature.signerName`, undefined);
      Ember.set(payload, "agreementsSignatures.infoShareAgreement.signature.signerName", undefined);
    }
  };

  const remakeInjuryLocationStructure = injuries => {
    const newInjuries = {};
    const keys = ["head", "face", "neck", "chest", "back", "leftArm", "rightArm", "abdomen", "pelvis", "leftLeg", "rightLeg"];
    keys.forEach(key => {
      newInjuries[key] = [];
      Object.keys(injuries).forEach(injury => {
        injuries[injury] && injuries[injury].forEach && injuries[injury].forEach(injuryKey => {
          if (injuryKey === convertCamelCaseToUnderscore(key)) newInjuries[key].push(convertCamelCaseToUnderscore(injury));
        });
      });
    });
    return newInjuries;
  };

  _exports.remakeInjuryLocationStructure = remakeInjuryLocationStructure;

  const dealWithPatientAge = patientDetails => {
    if (Ember.get(patientDetails, "age.approximate")) delete patientDetails.birthDate;else delete patientDetails.age;
  };

  const changeTransportationFixedPosition = position => {
    if (position && position.type && position.type.includes("FIXED")) Ember.setProperties(position, {
      type: position.type.replace("FIXED_", ""),
      fixedPosition: true
    });
  };

  const getSerializedPcr = payload => {
    clearMedicamentSizeIfNotNeeded(payload.patientTreatment);
    convertFragmentToString(payload);
    convertArray(payload.patientAssessments, _backendVars.ASSESSMENTS_OBJECTS);
    convertLazyFragments(payload);
    dealWithPatientTreatment(payload);
    dealWithTeamActivation(payload);
    removeAssessmentUnnecessaryConsciousness(payload.patientAssessments);
    removeDeletedTimesFlags(payload.times);
    removeShareWithPerson(payload);
    removeSignaturesRepresentative(payload);
    changeTransportationFixedPosition(Ember.get(payload, "transportation.position"));
    payload.patientDetails && dealWithPatientAge(payload.patientDetails);
    if (payload.injuryLocation && Object.keys(payload.injuryLocation).length) payload.injuryLocation = remakeInjuryLocationStructure(payload.injuryLocation);
  };

  _exports.getSerializedPcr = getSerializedPcr;
});