define("epcr-uis/pods/components/pcr-document/vitals-summary/copy-assessment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i4VQSvjF",
    "block": "{\"symbols\":[\"@onClick\"],\"statements\":[[7,\"button\",true],[11,\"class\",[29,[\"ui \",[28,\"if\",[[24,[\"validationError\"]],[28,\"if\",[[28,\"eq\",[[24,[\"validationError\",\"type\"]],\"error\"],null],\"negative\",\"orange\"],null],\"positive\"],null],\" basic button\"]]],[11,\"onclick\",[28,\"disable-bubbling\",[[23,1,[]]],null]],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"copy outline icon\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"actions.copy\"],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/vitals-summary/copy-assessment/template.hbs"
    }
  });

  _exports.default = _default;
});