define("epcr-uis/pods/pcr-classificator/medicaments/get-medicaments-data", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/panevezys", "epcr-uis/pods/pcr-classificator/medicaments/vilnius", "epcr-uis/pods/pcr-classificator/medicaments/default", "epcr-uis/pods/pcr-classificator/medicaments/mirage", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list", "epcr-uis/pods/pcr-classificator/medicaments/neringa", "epcr-uis/pods/pcr-classificator/medicaments/prienai", "epcr-uis/pods/pcr-classificator/medicaments/varena", "epcr-uis/pods/pcr-classificator/medicaments/ukmerge", "epcr-uis/pods/pcr-classificator/medicaments/salcininkai", "epcr-uis/pods/pcr-classificator/medicaments/vilkaviskis", "epcr-uis/pods/pcr-classificator/medicaments/mazeikiai", "epcr-uis/pods/pcr-classificator/medicaments/rokiskis", "epcr-uis/pods/pcr-classificator/medicaments/telsiai", "epcr-uis/pods/pcr-classificator/medicaments/siauliai"], function (_exports, _panevezys, _vilnius, _default, _mirage, _medicamentsList, _neringa, _prienai, _varena, _ukmerge, _salcininkai, _vilkaviskis, _mazeikiai, _rokiskis, _telsiai, _siauliai) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAllMedicamentUseTypes = getAllMedicamentUseTypes;
  _exports.getMedicamentsData = _exports.medicament = _exports.TO_VEIN_FAST_SLOW_DROPS_TYPES = _exports.TO_VEIN_ALL_USE_TYPES = _exports.EXTERNALLY = _exports.THROUGH_RECTUM = _exports.CURRENT_NOZZLE = _exports.UNDER_TONGUE = _exports.THROUGH_MOUTH = _exports.UNDER_SKIN = _exports.TO_MUSCLES = _exports.TO_ENDOTRACHEAL_TUBE = _exports.INTRA_BONE_INJECTION = void 0;

  const convertFirstChartToUpperCase = string => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

  const useType = "options.treatment.useType.";

  const getFastSlowAndDropsUseTypes = (t, route) => [{
    code: `${route.code}_51`,
    name: `${route.name} ${t(`${useType}fastInjection`)}`
  }, {
    code: `${route.code}_52`,
    name: `${route.name} ${t(`${useType}slowInjection`)}`
  }, {
    code: `${route.code}_54`,
    name: convertFirstChartToUpperCase(t(`${useType}dropsToVein`))
  }];

  const getFastSlowDropsAndDoseUseTypes = (t, route) => [{
    code: `${route.code}_50`,
    name: `${route.name} ${t(`${useType}impactDose`)}`
  }, getFastSlowAndDropsUseTypes(t, route)[0], getFastSlowAndDropsUseTypes(t, route)[1], getFastSlowAndDropsUseTypes(t, route)[2]];

  const getAllUseTypesToVein = (t, route) => [getFastSlowDropsAndDoseUseTypes(t, route)[0], getFastSlowDropsAndDoseUseTypes(t, route)[1], getFastSlowDropsAndDoseUseTypes(t, route)[2], {
    code: `${route.code}_53`,
    name: `${route.name} ${t(`${useType}automaticPump`)}`
  }, getFastSlowDropsAndDoseUseTypes(t, route)[3]];

  const TO_VEIN = t => ({
    code: "40",
    name: t(`${useType}toVein`)
  });

  const INTRA_BONE_INJECTION = t => ({
    code: "41",
    name: t(`${useType}intraboneInjection`)
  });

  _exports.INTRA_BONE_INJECTION = INTRA_BONE_INJECTION;

  const TO_ENDOTRACHEAL_TUBE = t => ({
    code: "42",
    name: t(`${useType}toEndotrachealTube`)
  });

  _exports.TO_ENDOTRACHEAL_TUBE = TO_ENDOTRACHEAL_TUBE;

  const TO_MUSCLES = t => ({
    code: "43",
    name: t(`${useType}toMuscles`)
  });

  _exports.TO_MUSCLES = TO_MUSCLES;

  const UNDER_SKIN = t => ({
    code: "44",
    name: t(`${useType}underSkin`)
  });

  _exports.UNDER_SKIN = UNDER_SKIN;

  const THROUGH_MOUTH = t => ({
    code: "45",
    name: t(`${useType}throughMouth`)
  });

  _exports.THROUGH_MOUTH = THROUGH_MOUTH;

  const UNDER_TONGUE = t => ({
    code: "46",
    name: t(`${useType}underTongue`)
  });

  _exports.UNDER_TONGUE = UNDER_TONGUE;

  const CURRENT_NOZZLE = t => ({
    code: "47",
    name: t(`${useType}currentNozzle`)
  });

  _exports.CURRENT_NOZZLE = CURRENT_NOZZLE;

  const THROUGH_RECTUM = t => ({
    code: "48",
    name: t(`${useType}throughRectum`)
  });

  _exports.THROUGH_RECTUM = THROUGH_RECTUM;

  const EXTERNALLY = t => ({
    code: "49",
    name: t(`${useType}externally`)
  }); // per nosi naudojam sita


  _exports.EXTERNALLY = EXTERNALLY;

  const TO_VEIN_ALL_USE_TYPES = t => getAllUseTypesToVein(t, {
    code: "40",
    name: t(`${useType}toVein`)
  });

  _exports.TO_VEIN_ALL_USE_TYPES = TO_VEIN_ALL_USE_TYPES;

  const TO_VEIN_FAST_SLOW_DROPS_TYPES = t => getFastSlowAndDropsUseTypes(t, TO_VEIN(t));

  _exports.TO_VEIN_FAST_SLOW_DROPS_TYPES = TO_VEIN_FAST_SLOW_DROPS_TYPES;

  function getAllMedicamentUseTypes(t) {
    return [INTRA_BONE_INJECTION(t), TO_MUSCLES(t), TO_ENDOTRACHEAL_TUBE(t), UNDER_SKIN(t), THROUGH_MOUTH(t), UNDER_TONGUE(t), CURRENT_NOZZLE(t), THROUGH_RECTUM(t), EXTERNALLY(t)].concat(TO_VEIN_ALL_USE_TYPES(t));
  }

  const medicament = "options.treatment.medicaments.";
  _exports.medicament = medicament;

  const findMedicament = (medicaments, name) => medicaments.find(m => m.name === name);

  const insertMedicaments = (toMedicaments, fromMedicaments) => {
    fromMedicaments.forEach(from => {
      const to = findMedicament(toMedicaments, from.name);
      if (to) to.types = to.types.concat(from.types);else toMedicaments.push(from);
    });
    return toMedicaments;
  };

  const realMedicaments = (t, stationId) => {
    const medicaments = insertMedicaments([], (0, _default.DEFAULT_MEDICAMENTS)(t));
    [(0, _panevezys.PAN_MEDICAMENTS)(t), (0, _vilnius.VLN_MEDICAMENTS)(t), (0, _neringa.NERINGA_MEDICAMENTS)(t), (0, _prienai.PRIENAI_MEDICAMENTS)(t), (0, _varena.VARENA_MEDICAMENTS)(t), (0, _ukmerge.UKMERGE_MEDICAMENTS)(t), (0, _salcininkai.SALCININKAI_MEDICAMENTS)(t), (0, _vilkaviskis.VILKAVISKIS_MEDICAMENTS)(t), (0, _mazeikiai.MAZEIKIAI_MEDICAMENTS)(t), (0, _rokiskis.ROKISKIS_MEDICAMENTS)(t), (0, _telsiai.TELSIAI_MEDICAMENTS)(t), (0, _siauliai.SIAULIAI_MEDICAMENTS)(t)].filter(stationMeds => stationMeds.stations.includes(String(stationId))).map(stationMeds => stationMeds.medicaments).forEach(meds => insertMedicaments(medicaments, meds));
    return medicaments;
  };

  const medicamentsData = (t, stationId) => !stationId ? insertMedicaments([], (0, _mirage.MIRAGE_MEDICAMENTS)(t)) : realMedicaments(t, stationId);

  const getMedicamentsData = (t, stationId) => {
    const medicaments = medicamentsData(t, stationId).sort((med1, med2) => med1.name.localeCompare(med2.name));
    medicaments.push((0, _medicamentsList.MEDICAMENTS)(t)[99]);
    return medicaments;
  };

  _exports.getMedicamentsData = getMedicamentsData;
});