define("epcr-uis/pods/components/used-medicaments/component", ["exports", "ember-concurrency", "epcr-uis/constants", "moment", "epcr-uis/helpers/count-shift-interval-by-shift-type", "epcr-uis/helpers/date-interval-to-string"], function (_exports, _emberConcurrency, _constants, _moment, _countShiftIntervalByShiftType, _dateIntervalToString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    "data-test-id": "used-medicaments",
    classNames: ["used-medicaments list"],
    usedMedicaments: Ember.inject.service("list/used-medicaments"),
    userPreferencesService: Ember.inject.service("user-preferences"),
    intl: Ember.inject.service(),
    shiftDurations: [{
      code: 12,
      name: "12h"
    }, {
      code: 24,
      name: "24h"
    }],

    init() {
      this._super(...arguments);

      this.selectedShiftDuration && this.filterTask.perform();
    },

    selectedShiftDuration: Ember.computed(`userPreferencesService.userPreferences.${_constants.SELECTED_SHIFT_DURATION}`, {
      get() {
        const selectedShiftDuration = this.userPreferencesService.userPreferences[_constants.SELECTED_SHIFT_DURATION];
        return selectedShiftDuration && this.shiftDurations.findBy("code", selectedShiftDuration);
      },

      set: (key, value) => value
    }),
    formatDate: date => date.format("MM/DD HH:mm"),
    filterTask: (0, _emberConcurrency.task)(function* () {
      const {
        selectedShiftDuration,
        formatDate
      } = this;
      const {
        from,
        to
      } = (0, _countShiftIntervalByShiftType.countShiftIntervalByShiftType)([(0, _moment.default)(), selectedShiftDuration.code]);
      Ember.set(this, "shiftDurationInterval", (0, _dateIntervalToString.getFormattedDateInterval)([formatDate(from), formatDate(to)]));
      yield this.usedMedicaments.queryUsedMedicamentsTask.perform({
        from,
        to
      });
    })
  });

  _exports.default = _default;
});