define("epcr-uis/helpers/filter-validations-by-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterValidationsByType = filterValidationsByType;
  _exports.default = void 0;

  function filterValidationsByType([validations, type]) {
    if (!validations || !type) return null;
    let errorCount = 0;
    let warningCount = 0;
    validations.forEach(validation => {
      if (validation.elementContainer.includes(type)) {
        if (validation.type === "error") errorCount++;else if (validation.type === "warning") warningCount++;
      }
    });

    if (errorCount || warningCount) {
      const cssClass = errorCount ? "error" : "warning";
      const count = errorCount ? errorCount : warningCount;
      return {
        cssClass,
        count
      };
    }

    return null;
  }

  var _default = Ember.Helper.helper(filterValidationsByType);

  _exports.default = _default;
});