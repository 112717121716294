define("epcr-uis/mixins/coordinates-from-event-site-or-station", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getCoordinates() {
      return this.getCoordinatesFromEventSite() || this.getCoordinatesByStationId();
    },

    getCoordinatesByStationId() {
      const stationId = this.login.getStationId();
      const coordinates = this.pcrClassificator.findElementByCode("coordinatesByStationId", stationId);
      return coordinates || {
        longitude: null,
        latitude: null
      };
    },

    getCoordinatesFromEventSite() {
      const coordinatesPath = "pcr.callInformation.eventSite.addressDetails.coordinate";
      const longitude = Ember.get(this, `${coordinatesPath}.longitude`);
      const latitude = Ember.get(this, `${coordinatesPath}.latitude`);
      return longitude && latitude ? {
        longitude,
        latitude
      } : null;
    }

  });

  _exports.default = _default;
});