define("epcr-uis/pods/pcr/summary/route", ["exports", "epcr-uis/helpers/deferred-content-promise"], function (_exports, _deferredContentPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    model() {
      const {
        intl
      } = this;
      const navSummary = "navigation.summary.";
      const pcrSummary = "pcr.summary.";
      return {
        summaryTabs: [{
          name: intl.t(`${navSummary}errors`),
          path: `${pcrSummary}validations`,
          icon: "exclamation triangle icon"
        }, {
          name: intl.t(`${navSummary}print`),
          path: `${pcrSummary}print`,
          icon: "print icon"
        }, {
          name: intl.t(`${navSummary}save`),
          path: `${pcrSummary}save`,
          icon: "save icon"
        }],
        deferredContentPromise: (0, _deferredContentPromise.deferredContentPromise)()
      };
    }

  });

  _exports.default = _default;
});