define("epcr-uis/pods/application/adapter", ["exports", "ember-data", "epcr-uis/config/environment", "ember-simple-auth-token/mixins/token-authorizer"], function (_exports, _emberData, _environment, _tokenAuthorizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RESTAdapter
  } = _emberData.default;

  var _default = RESTAdapter.extend(_tokenAuthorizer.default, {
    host: _environment.default.host,
    namespace: _environment.default.namespace,
    metrics: Ember.inject.service(),

    mainPath(model) {
      return `${this.host}/${this.namespace}/${model}`;
    },

    saveErrorToMetrics(status, request, headers) {
      this.metrics.trackEvent({
        status,
        request,
        headers
      });
    },

    handleResponse(status, headers, payload, request) {
      if (!this.isSuccess(status, headers, payload)) {
        this.saveErrorToMetrics(status, request, headers);
        if (payload.code) payload.errors = [payload];
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});