define("epcr-uis/pods/pcr-state/model", ["exports", "ember-data", "ember-data-model-fragments/fragment"], function (_exports, _emberData, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    pcrId: attr("string"),
    state: attr("string"),
    createdTime: attr("date"),
    lastUpdateTime: attr("date")
  });

  _exports.default = _default;
});