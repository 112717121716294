define("epcr-uis/pods/components/pcr-document/flowchart/delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t4V372v0",
    "block": "{\"symbols\":[\"@data\"],\"statements\":[[7,\"i\",true],[10,\"class\",\"trash alternate outline grey large icon\"],[11,\"onclick\",[28,\"disable-bubbling\",[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isDeleteConfirmationOpen\"]]],null],true],null]],null]],[8],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isDeleteConfirmationOpen\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"pcr\"]],{\"statements\":[[0,\"    \"],[5,\"confirm-modal\",[],[[\"@title\",\"@confirmKey\",\"@centerModalOpen\",\"@confirm\",\"@cancel\"],[[28,\"t\",[\"message.removalConfirmation\"],null],\"actions.remove\",[22,\"isDeleteConfirmationOpen\"],[28,\"action\",[[23,0,[]],\"onDelete\",[23,1,[]]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isDeleteConfirmationOpen\"]]],null],false],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/flowchart/delete/template.hbs"
    }
  });

  _exports.default = _default;
});