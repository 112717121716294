define("epcr-uis/instance-initializers/initialize-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    appInstance.lookup("service:metrics-context");
    appInstance.lookup("service:show-training");
    appInstance.lookup("service:android-signal");
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});