define("epcr-uis/mirage/factories/used-medicament-medicament", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    callTime: _emberCliMirage.faker.date.recent(),
    eventId: i => `123456789${i}`,
    medicaments: i => [{
      amount: (i + 1) * 2 - 1,
      notes: "bla"
    }]
  });

  _exports.default = _default;
});