define("epcr-uis/pods/components/confirm-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "afZsu170",
    "block": "{\"symbols\":[\"Modal\",\"@confirm\",\"@cancel\",\"&default\",\"@title\"],\"statements\":[[5,\"center-modal\",[[12,\"class\",\"confirmation-modal\"]],[[\"@centerModalOpen\",\"@dimmerCloseTask\",\"@onClose\"],[[22,\"centerModalOpen\"],[22,\"closeModal\"],[28,\"perform\",[[24,[\"closeModal\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[\"@title\"],[[23,5,[]]]]],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,4,[[28,\"hash\",null,[[\"message\"],[[28,\"component\",[\"confirm-modal/message\"],[[\"text\"],[[24,[\"text\"]]]]]]]]]],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"confirmation\"],[8],[0,\"\\n\"],[4,\"if\",[[25,4]],null,{\"statements\":[[0,\"        \"],[14,4,[[28,\"hash\",null,[[\"confirm\",\"cancel\"],[[28,\"component\",[\"confirm-modal/button\"],[[\"className\",\"action\",\"textKey\",\"dataTestId\"],[[24,[\"confirmBtnClass\"]],[24,[\"confirm\"]],[24,[\"confirmKey\"]],[24,[\"confirmDataTestId\"]]]]],[28,\"component\",[\"confirm-modal/button\"],[[\"className\",\"action\",\"textKey\",\"dataTestId\"],[[28,\"concat\",[\"cancel \",[24,[\"cancelBtnClass\"]]],null],[24,[\"cancel\"]],[24,[\"cancelKey\"]],[24,[\"cancelDataTestId\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[5,\"confirm-modal/button\",[],[[\"@className\",\"@action\",\"@textKey\",\"@dataTestId\"],[[22,\"confirmBtnClass\"],[23,2,[]],[22,\"confirmKey\"],[22,\"confirmDataTestId\"]]]],[0,\"\\n\\n        \"],[5,\"confirm-modal/button\",[],[[\"@className\",\"@action\",\"@textKey\",\"@dataTestId\"],[[28,\"concat\",[\"cancel \",[24,[\"cancelBtnClass\"]]],null],[23,3,[]],[22,\"cancelKey\"],[22,\"cancelDataTestId\"]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/confirm-modal/template.hbs"
    }
  });

  _exports.default = _default;
});