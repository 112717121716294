define("epcr-uis/pods/components/pcr-document/patient/import-patient/component", ["exports", "ember-concurrency", "epcr-uis/helpers/address-formatter", "epcr-uis/helpers/is-pid-correct", "epcr-uis/helpers/array-to-string", "epcr-uis/mixins/metrics-tracker"], function (_exports, _emberConcurrency, _addressFormatter, _isPidCorrect, _arrayToString, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IMPORTED_PATIENT_VALUE_PATH = "importedPatient.value";

  var _default = Ember.Component.extend(_metricsTracker.default, {
    intl: Ember.inject.service(),
    tagName: "",
    selectedItems: [],
    selectedPatientDataIndex: 0,

    init() {
      this._super(...arguments);

      this.importPatient();
    },

    isButtonDisabled: Ember.computed("importedPatient.{isRunning,value}", "patient.{personalId,firstName,lastName}", {
      get() {
        const {
          importedPatient,
          patient: {
            personalId,
            firstName,
            lastName
          }
        } = this;
        return importedPatient ? importedPatient.isRunning || !Ember.get(importedPatient, "value.length") || !personalId && (!firstName || !lastName) : true;
      },

      set: (key, value) => value
    }),
    closeCenterModal: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, "selectedPatientDataIndex", 0);
      yield Ember.set(this, "isPatientInformationImportModalOpen", false);
    }),
    onPcrDetailsChange: Ember.observer("patient.{personalId,firstName,lastName}", function () {
      return this.importPatient();
    }),
    onImportedPatient: Ember.observer(IMPORTED_PATIENT_VALUE_PATH, function () {
      const value = Ember.get(this, IMPORTED_PATIENT_VALUE_PATH);
      value && value.length && this.createItemsArray(value.objectAt(this.selectedPatientDataIndex));
    }),

    createFCAndPhcTitle(familyDoctor, phcFacility) {
      const familyDoc = this.intl.t("pcr.patientDetails.familyDoctor");
      const phc = this.intl.t("pcr.patientDetails.phcFacility");
      if (familyDoctor) return phcFacility ? `${familyDoc}, ${phc}` : familyDoc;
      return phcFacility ? phc : "";
    },

    pushInArrayIfHasValue: (array, value) => Ember.isPresent(value) && array.push(value),

    createItemsArray(value) {
      const {
        addImportedPatientDataSegment: addPatientDataPart,
        hasPatientMainData
      } = this;
      const {
        address,
        familyDoctor,
        phcFacility,
        medicineConsumed,
        specialCircumstances,
        sideDiseases
      } = value;
      const items = [];
      addPatientDataPart(hasPatientMainData(value), items, "copyPatientMainData", this.intl.t("pcr.signatures.patient"), this.createPatientMainDataDescription(value));
      addPatientDataPart(address, items, "copyAddress", this.intl.t("pcr.callInformation.eventSite.address"), (0, _addressFormatter.addressFormatter)([address]));
      addPatientDataPart(medicineConsumed, items, "copyMedicine", this.intl.t("pcr.patientDetails.usedMedicaments"), medicineConsumed);
      addPatientDataPart(specialCircumstances, items, "copySpecialCircumstances", this.intl.t("pcr.patientDetails.specialCircumstances"), specialCircumstances);
      addPatientDataPart(sideDiseases, items, "copySideDiseases", this.intl.t("pcr.patientDetails.knownDiseases"), this.createSideDiseasesDescription(sideDiseases));
      addPatientDataPart(familyDoctor || phcFacility, items, "copyPhysicianAndPhc", this.createFCAndPhcTitle(familyDoctor, phcFacility), this.createFamilyDoctorAndPhcDescription(familyDoctor, phcFacility));
      Ember.set(this, "items", items);
      Ember.set(this, "selectedItems", items.slice());
      Ember.set(this, "selectedPatient", value);
    },

    hasPatientMainData: ({
      firstName,
      lastName,
      birthDate,
      weight,
      personalId,
      sex
    }) => firstName || lastName || birthDate || weight || personalId || sex,

    createFamilyDoctorAndPhcDescription(familyDoctor, phcFacility) {
      const {
        pushInArrayIfHasValue
      } = this;
      const array = [];
      pushInArrayIfHasValue(array, familyDoctor && familyDoctor.code);
      pushInArrayIfHasValue(array, phcFacility && phcFacility.code);
      return (0, _arrayToString.arrayToString)(array);
    },

    createPatientMainDataDescription({
      firstName,
      lastName,
      birthDate,
      weight,
      personalId,
      sex
    }) {
      const {
        pushInArrayIfHasValue
      } = this;
      const array = [];
      pushInArrayIfHasValue(array, (0, _arrayToString.arrayToString)([firstName, lastName], null, " "));
      pushInArrayIfHasValue(array, personalId || "");
      pushInArrayIfHasValue(array, sex && sex.name);
      pushInArrayIfHasValue(array, weight && `${weight}kg`);
      pushInArrayIfHasValue(array, birthDate && birthDate.format("YYYY-MM-DD"));
      return (0, _arrayToString.arrayToString)(array);
    },

    createSideDiseasesDescription(sideDiseases) {
      if (sideDiseases) {
        const {
          otherIllnessDescription,
          allergyNotes
        } = sideDiseases;
        const sideDiseasesWithoutAllergyAndOther = Ember.get(sideDiseases, "types.length") && sideDiseases.types.filter(({
          code
        }) => code !== "ALLERGY" && code !== "OTHER");
        let description = `${(0, _arrayToString.arrayToString)(sideDiseasesWithoutAllergyAndOther, "name")}`;
        const other = otherIllnessDescription && `${this.intl.t("pcr.patientDetails.other")}(${otherIllnessDescription})`;
        const allergy = allergyNotes && `${this.intl.t("pcr.patientDetails.allergy")}(${allergyNotes})`;
        if (allergy) description += description ? `, ${allergy}` : allergy;
        if (other) description += description ? `, ${other}` : other;
        return description;
      }

      return "";
    },

    addImportedPatientDataSegment: (condition, items, code, name, description) => condition && items.push({
      code,
      name,
      description
    }),

    importPatient() {
      const {
        callTime,
        patient: {
          personalId,
          firstName,
          lastName
        }
      } = this;

      if (personalId && (0, _isPidCorrect.isPidCorrect)([personalId]) || firstName && lastName) {
        const importedPatient = this.importPatientTask.perform({
          personalId,
          callTime: callTime && callTime.toISOString(false),
          firstName,
          lastName
        });
        Ember.set(this, "importedPatient", importedPatient);
      }
    },

    setValue(value, key) {
      Ember.isPresent(value) && Ember.isPresent(value[key]) && Ember.set(this.patient, key, value[key]);
    },

    copyPatientMainData(importedPatient) {
      this.setValue(importedPatient, "firstName");
      this.setValue(importedPatient, "lastName");
      this.setValue(importedPatient, "sex");
      this.setValue(importedPatient, "birthDate");
      this.setValue(importedPatient, "weight");
      !Ember.get(this, "patient.personalId") && this.setValue(importedPatient, "personalId");
    },

    copyAddress(importedPatient) {
      this.setValue(importedPatient, "address");
    },

    copyPhysicianAndPhc(importedPatient) {
      this.setValue(importedPatient, "familyDoctor");
      this.setValue(importedPatient, "phcFacility");
    },

    copyMedicine(importedPatient) {
      this.setValue(importedPatient, "medicineConsumed");
    },

    copySpecialCircumstances(importedPatient) {
      this.setValue(importedPatient, "specialCircumstances");
    },

    copySideDiseases(importedPatient) {
      this.setValue(importedPatient, "sideDiseases");
    },

    actions: {
      onOpenCenterModal() {
        Ember.set(this, "isPatientInformationImportModalOpen", true);
        this.trackEvent("Import", "Imported patient data is viewed");
      },

      onPatientSelect(index) {
        Ember.set(this, "selectedPatientDataIndex", index);
        this.createItemsArray(Ember.get(this, IMPORTED_PATIENT_VALUE_PATH).objectAt(index));
      },

      setPatientValues() {
        const importedPatient = Ember.get(this, IMPORTED_PATIENT_VALUE_PATH).objectAt(this.selectedPatientDataIndex);
        this.selectedItems.forEach(item => {
          this[item.code](importedPatient);
        });
        this.closeCenterModal.perform();
        this.trackEvent("Import", "Set patient data from storage");
      }

    }
  });

  _exports.default = _default;
});