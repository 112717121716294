define("epcr-uis/pods/updates/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    updates: Ember.inject.service(),

    ifUpdatesIsNotEnabledReturnToHome() {
      !this.updates.isEnabled && this.transitionTo("home");
    },

    model() {
      this.ifUpdatesIsNotEnabledReturnToHome();
    }

  });

  _exports.default = _default;
});