define("epcr-uis/pods/pcr/patient-treatment/treatment-line/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    time: attr("date"),
    member: (0, _attributes.fragment)("pcr/team-activation/team/member"),
    rejected: attr("boolean"),
    conditionChange: (0, _attributes.fragment)("pcr-classificator"),
    notes: attr("string"),
    total: (0, _attributes.fragment)("pcr-classificator"),
    size: attr("number"),

    addParameterToMatchId(matchId, parameter) {
      if (parameter) matchId = this.ifNotEmptyAddUnderscore(matchId) + parameter;
      return matchId;
    },

    ifNotEmptyAddUnderscore(matchId) {
      return matchId ? `${matchId}_` : matchId;
    },

    getFormattedTime(time) {
      return time ? time.valueOf() : "";
    }

  });

  _exports.default = _default;
});