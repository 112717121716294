define("epcr-uis/pods/verified-pcr/serializer", ["exports", "ember-data", "epcr-uis/pods/my-pcr/serializer"], function (_exports, _emberData, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTSerializer = _emberData.default.RESTSerializer;

  var _default = RESTSerializer.extend({
    pcrClassificator: Ember.inject.service(),

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload = (0, _serializer.changeDataToEmber)(this.pcrClassificator, payload, "verifiedPcr");
      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});