define("epcr-uis/pods/components/pcr-document/flowchart/input/time/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iOAjaqBO",
    "block": "{\"symbols\":[\"@timeEditDiff\",\"@time\",\"@showTimeEditDiff\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"wrapper\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"pencil alternate icon time-edit-icon grey\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\",true],[8],[1,[28,\"moment-format\",[[23,2,[]],\"HH:mm\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[23,1,[]],[23,3,[]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"time-edit-diff\"],[8],[0,\"\\n    \"],[1,[28,\"ms-to-mins\",[[23,1,[]]],null],false],[0,\" \"],[1,[28,\"t\",[\"general.minutes\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/flowchart/input/time/template.hbs"
    }
  });

  _exports.default = _default;
});