define("epcr-uis/initializers/api-address", ["exports", "epcr-uis/config/environment", "ember-window-mock", "epcr-uis/pods/application/adapter"], function (_exports, _environment, _emberWindowMock, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  const replaceHost = (host, url) => url.replace(_environment.default.host, host);

  const overrideConfig = newConfig => Object.keys(newConfig).forEach(key => _environment.default.APP[key] = newConfig[key]);

  const reopenApplicationAdapter = host => _adapter.default.reopen({
    host
  });

  function configureSimpleAuth(apiHost) {
    const emberSimpleAuthToken = _environment.default["ember-simple-auth-token"];
    emberSimpleAuthToken.serverTokenEndpoint = replaceHost(apiHost, emberSimpleAuthToken.serverTokenEndpoint);
    emberSimpleAuthToken.serverTokenRefreshEndpoint = replaceHost(apiHost, emberSimpleAuthToken.serverTokenRefreshEndpoint);
  }

  const configureApplication = apiHost => {
    const appConfig = _environment.default.APP;
    return {
      validateUrl: replaceHost(apiHost, appConfig.validateUrl),
      updatePcrUrl: replaceHost(apiHost, appConfig.updatePcrUrl),
      trainingURL: replaceHost(apiHost, appConfig.trainingURL),
      pcrImageUrl: replaceHost(apiHost, appConfig.pcrImageUrl),
      recommendationsUrl: replaceHost(apiHost, appConfig.recommendationsUrl),
      pcrTemporaryUrl: replaceHost(apiHost, appConfig.pcrTemporaryUrl),
      pcrVerifyUrl: replaceHost(apiHost, appConfig.pcrVerifyUrl),
      eventSearchIdUrl: replaceHost(apiHost, appConfig.eventSearchIdUrl),
      myPcrUrl: replaceHost(apiHost, appConfig.myPcrUrl),
      activePcrUrl: replaceHost(apiHost, appConfig.activePcrUrl),
      commentPostUrl: replaceHost(apiHost, appConfig.commentPostUrl),
      pcrAttachmentsUrl: replaceHost(apiHost, appConfig.pcrAttachmentsUrl),
      pcrAttachmentUrl: replaceHost(apiHost, appConfig.pcrAttachmentUrl),
      backendConfigUrl: replaceHost(apiHost, appConfig.backendConfigUrl)
    };
  };

  const applyConfig = (context, apiHost) => {
    apiHost = apiHost.startsWith("http") ? apiHost : `http://${apiHost}`;
    const newConfig = configureApplication(apiHost);
    context.reopenApplicationAdapter(apiHost);
    context.overrideConfig(newConfig);
    configureSimpleAuth(apiHost);
  };

  function initialize() {
    if (_environment.default.environment !== "production" || _environment.default.deployTarget === "staging") {
      const url = new URL(_emberWindowMock.default.location.href);
      let apiHost = url.searchParams.get("apiHost");

      if (apiHost === "") {
        _environment.default["ember-cli-mirage"].enabled = true;
        apiHost = "http://localhost:4200";
      }

      if (apiHost) applyConfig(this, apiHost);
    }
  }

  var _default = {
    name: "api-host-from-params",
    after: "ember-cli-mirage",
    initialize,
    reopenApplicationAdapter,
    configureApplication,
    overrideConfig
  };
  _exports.default = _default;
});