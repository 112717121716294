define("epcr-uis/pods/phc/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    getPhcFacility(code) {
      return this.store.findRecord("phc", code, {
        backgroundReload: false
      });
    },

    queryPhc(query, stationId) {
      return this.store.query("phc", {
        query,
        stationId
      });
    }

  });

  _exports.default = _default;
});