define("epcr-uis/components/tabular-menu/tab", ["exports", "ember-components/components/tabular-menu/tab"], function (_exports, _tab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _tab.default;
    }
  });
});