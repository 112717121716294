define("epcr-uis/mixins/flowchart-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNameBindings: ["validationType"],

    countValidationTypes(validations) {
      let errors = 0;
      let warnings = 0;
      validations && Object.keys(validations).forEach(key => {
        if (validations[key].type === "error") errors++;else warnings++;
      });
      return {
        errors,
        warnings
      };
    },

    validationType: Ember.computed("validationErrors", function () {
      const {
        errors,
        warnings
      } = this.countValidationTypes(this.validationErrors);
      if (errors) return "error";
      if (warnings) return "warning";
      return "";
    })
  });

  _exports.default = _default;
});