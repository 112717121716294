define("epcr-uis/pods/components/pcr-document/incident/times/time-change-request/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    closeCenterModal: (0, _emberConcurrency.task)(function* () {
      yield Ember.set(this, "isRightPopupOpen", false);
    })
  });

  _exports.default = _default;
});