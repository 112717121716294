define("epcr-uis/pods/pcr/summary/print/serializer", ["exports", "epcr-uis/pods/pcr/serializer"], function (_exports, _serializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serializer.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const tmpId = 0;
      const p = {
        "pcr/summary/print": {
          id: id || tmpId,
          content: payload
        }
      };
      return this._super(store, primaryModelClass, p, id, requestType);
    }

  });

  _exports.default = _default;
});