define("epcr-uis/pods/string/transform", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: serialized => serialized,
    serialize: deserialized => !deserialized || deserialized === "" ? undefined : deserialized
  });

  _exports.default = _default;
});