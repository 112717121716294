define("epcr-uis/pods/components/pcr-document/section-copy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z5GSGA5U",
    "block": "{\"symbols\":[\"@previousValue\",\"@isVisible\"],\"statements\":[[4,\"if\",[[28,\"and\",[[23,2,[]],[23,1,[]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"assessment-insert-button ui primary basic button\"],[11,\"onclick\",[22,\"onCopy\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"icon copy outline blue\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/section-copy/template.hbs"
    }
  });

  _exports.default = _default;
});