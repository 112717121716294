define("epcr-uis/mirage/factories/patient-details", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const englishDetails = () => ({
    firstName: "",
    lastName: "",
    sex: "",
    personalId: "39512150602",
    unknown: false,
    birthDate: () => _emberCliMirage.faker.date.past(),
    weight: null,
    patientHomeless: false,
    specialCircumstances: "None",
    notWalking: false,
    medicineConsumed: "Paracetamol",
    medicineUnknown: false,
    familyDoctor: "Jack Underwood",
    notes: "Unknown PHC facility",
    helpRefused: true,
    addressMatchesEventSite: false
  });

  const lithuanianDetails = () => ({
    firstName: "Lina",
    lastName: "Geliutytė",
    sex: "FEMALE",
    personalId: "48101071253",
    unknown: false,
    birthDate: () => _emberCliMirage.faker.date.past(),
    weight: () => _emberCliMirage.faker.random.number(79),
    patientHomeless: false,
    specialCircumstances: "Nėra tokių",
    notWalking: false,
    medicineConsumed: "Paracetamol",
    medicineUnknown: false,
    familyDoctor: "Daktaras Bukelis",
    notes: "Nežinomas PASPĮ",
    helpRefused: true,
    addressMatchesEventSite: false
  });

  var _default = _emberCliMirage.Factory.extend((0, _isEnglish.isEnglish)() ? englishDetails() : lithuanianDetails());

  _exports.default = _default;
});