define("epcr-uis/pods/used-medicament/serializer", ["exports", "ember-data", "epcr-uis/helpers/get-medicament-mediums-by-code"], function (_exports, _emberData, _getMedicamentMediumsByCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.normalizeStructure = void 0;
  var RESTSerializer = _emberData.default.RESTSerializer;

  const normalizeStructure = (classificator, medicaments) => {
    const newStructure = [];
    medicaments.forEach(medicament => {
      const {
        medicamentCode,
        totalAmount
      } = medicament;
      const foundMedicament = (0, _getMedicamentMediumsByCode.getMedicamentMediumsByCode)(medicamentCode, classificator);
      let mappedMedicament;

      if (foundMedicament) {
        mappedMedicament = newStructure.find(medicament => medicament.name === foundMedicament.name);
        if (!mappedMedicament) mappedMedicament = {
          name: foundMedicament.name,
          types: [],
          id: medicamentCode
        };
      }

      if (!mappedMedicament) mappedMedicament = {
        name: `Nežinoma(${medicamentCode})`,
        types: [],
        id: medicamentCode
      };
      mappedMedicament.types.push({
        medicamentCode,
        totalAmount,
        name: foundMedicament ? foundMedicament.types.find(type => type.code === medicamentCode).name : "Nežinoma"
      });
      !newStructure.find(med => med.name === mappedMedicament.name) && newStructure.push(mappedMedicament);
    });
    return newStructure;
  };

  _exports.normalizeStructure = normalizeStructure;

  var _default = RESTSerializer.extend({
    pcrClassificator: Ember.inject.service(),

    normalizeResponse(store, primaryModelClass, payload, id, type) {
      const newPayload = normalizeStructure(this.pcrClassificator, payload);
      return this._super(store, primaryModelClass, {
        usedMedicaments: newPayload
      }, id, type);
    }

  });

  _exports.default = _default;
});