define("epcr-uis/helpers/apgar-color-by-score", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.apgarColorByScore = apgarColorByScore;
  _exports.default = void 0;

  function apgarColorByScore([score]) {
    if (score === 8 || score === 9) return "orange";
    if (score === 10) return "green";
    return "red";
  }

  var _default = Ember.Helper.helper(apgarColorByScore);

  _exports.default = _default;
});