define("epcr-uis/pods/components/drill-down-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TC4v2EKT",
    "block": "{\"symbols\":[\"Wrapper\",\"@task\",\"&default\"],\"statements\":[[5,\"task-wrapper\",[],[[\"@task\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"running\"]],[],[[],[]]],[0,\"\\n\\n  \"],[6,[23,1,[\"success\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,3],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[23,1,[\"error\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"pcr-error\",[],[[\"@message\",\"@buttonName\",\"@showTopNavigation\",\"@onButtonClicked\"],[[23,2,[\"last\",\"error\"]],[28,\"t\",[\"actions.retry\"],null],false,[28,\"action\",[[23,0,[]],\"onReload\",[23,2,[]]],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/drill-down-wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});