define("epcr-uis/helpers/ms-to-mins", ["exports", "ember-components/helpers/ms-to-mins"], function (_exports, _msToMins) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _msToMins.default;
    }
  });
  Object.defineProperty(_exports, "msToMins", {
    enumerable: true,
    get: function () {
      return _msToMins.msToMins;
    }
  });
});