define("epcr-uis/pods/pcr/backend-normalize", ["exports", "epcr-uis/helpers/ensure-object-structure", "epcr-uis/config/environment", "epcr-uis/pods/pcr/backend-vars", "epcr-uis/pods/pcr/backend-mirage-normalize"], function (_exports, _ensureObjectStructure, _environment, _backendVars, _backendMirageNormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getNormalizedPcr = getNormalizedPcr;
  _exports.updateMissingStructure = void 0;
  const LAZY_FRAGMENTS_LIST = (0, _backendVars.LAZY_FRAGMENTS)();
  const OBJECTS_LIST = (0, _backendVars.OBJECTS)();

  const findClassificatorElementByCode = (classificator, element, code) => classificator.findElementByCode(element, code);

  const getNormalizedActivationProtocol = (payload, classificator) => {
    const activationProtocols = classificator.getActivationProtocolByDispatch(payload.dispatch);
    const activationProtocol = Ember.get(payload, "teamActivation.activationProtocol");
    const foundElement = activationProtocols.find(AP => AP.code === activationProtocol);
    return foundElement || {
      code: activationProtocol,
      name: activationProtocol
    };
  };

  const normalizeFragment = (code, key, defaultFragmentValue) => {
    if (code) return {
      [key]: code
    };else return defaultFragmentValue ? null : undefined;
  };

  const updateMissingStructure = (payload, objects) => {
    objects.forEach(({
      path,
      defaultValue
    }) => (0, _ensureObjectStructure.ensureObjectStructure)(payload, path, defaultValue));
  };

  _exports.updateMissingStructure = updateMissingStructure;

  const replacePayloadWithFragments = (payload, path, value) => Ember.set(payload, path, value); // todo parasyti savo setus getus del performance


  const getElementPath = path => path.join(".");

  const getFragmentsCode = (payload, path) => Ember.get(payload, path);

  const createObjectFromClassificatorWithCodeAndName = (classificator, classificatorE, code) => {
    const classificatorElement = findClassificatorElementByCode(classificator, classificatorE, code);

    if (classificatorElement) {
      const classificatorElementKeys = Object.keys(classificatorElement);

      if (classificatorElementKeys.length === 2) {
        return classificatorElement;
      } else if (classificatorElement.favourite) {
        const clone = Object.assign({}, classificatorElement);
        delete clone.favourite;
        return clone;
      }
    }

    return classificatorElement;
  };

  const replaceObjectToFragment = (classificator, payload, objects, defaultFragmentValue) => {
    objects.forEach(({
      path,
      classificatorE
    }) => {
      if (classificatorE) {
        const objectPath = getElementPath(path);
        const fragments = getFragmentsCode(payload, objectPath);
        let updatedFragment = null;

        if (Ember.isArray(fragments)) {
          updatedFragment = [];
          fragments.forEach(code => {
            updatedFragment.push(createObjectFromClassificatorWithCodeAndName(classificator, classificatorE, code));
          });
        } else {
          updatedFragment = createObjectFromClassificatorWithCodeAndName(classificator, classificatorE, fragments);
        }

        if (!updatedFragment) updatedFragment = defaultFragmentValue ? null : undefined;
        replacePayloadWithFragments(payload, objectPath, updatedFragment);
      }
    });
  };

  const ifNoTeamLeaderAndHisTypeGivenMakeDefaultValue = (payload, classificator) => {
    const {
      leaderType,
      members
    } = payload.teamActivation.team;

    if (!leaderType && (!members || !members.length)) {
      payload.teamActivation.team.leaderType = classificator.findElementByCode("positions", "SLAUG");
    }
  };

  const normalizeLazyFragments = (payload, defaultFragmentValue) => {
    LAZY_FRAGMENTS_LIST.forEach(({
      key,
      path,
      modelType
    }) => {
      let payloadCode = Ember.get(payload, path);

      if (modelType === "array") {
        payloadCode = payloadCode.map(element => normalizeFragment(element, key, defaultFragmentValue));
      } else {
        payloadCode = normalizeFragment(payloadCode, key, defaultFragmentValue);
      }

      Ember.set(payload, path, payloadCode);
    });
  };

  const replaceTreatment = (classificator, treatments, treatmentFields, defaultFragmentValue) => {
    treatments.forEach(treatment => {
      const fields = treatmentFields(defaultFragmentValue);
      updateMissingStructure(treatment, fields);
      replaceObjectToFragment(classificator, treatment, fields);
    });
  };

  const createConsciousnessType = assessment => {
    const hasAVPU = Ember.get(assessment, "consciousness.avpu.code");
    const gcs = Ember.get(assessment, "consciousness.gcs");
    const hasGCS = gcs && gcs.eyeResponse || gcs.motorResponse || gcs.verbalResponse;
    assessment.consciousness.type = hasAVPU && !hasGCS ? "AVPU" : "GKS";
  };

  const replacePatientAssessments = (classificator, assessments, defaultFragmentValue = undefined) => {
    const ASSESSMENTS_OBJECTS_LIST = (0, _backendVars.ASSESSMENTS_OBJECTS)(defaultFragmentValue);
    assessments.forEach(assessment => {
      updateMissingStructure(assessment, ASSESSMENTS_OBJECTS_LIST);
      replaceObjectToFragment(classificator, assessment, ASSESSMENTS_OBJECTS_LIST);
      createConsciousnessType(assessment);
    });
  };

  const selectInfoShareWithPersonConfirmation = payload => {
    const infoShareAgreementPath = "agreementsSignatures.infoShareAgreement";
    const medicalHelpAgreementPath = "agreementsSignatures.medicalHelpAgreement";
    const confirmation = Ember.get(payload, `${infoShareAgreementPath}.confirmation.code`);
    const signatureType = Ember.get(payload, `${medicalHelpAgreementPath}.signatureType.code`);
    const signatureImage = Ember.get(payload, `${medicalHelpAgreementPath}.signature.signatureImage`);
    if (signatureType && Ember.isEmpty(confirmation) && Ember.isPresent(signatureImage)) Ember.set(payload, `${infoShareAgreementPath}.confirmation`, {
      code: false
    });
  };

  const replacePatientTransportationPositionFixed = position => {
    if (position && position.type && position.fixedPosition) {
      Ember.set(position, "type", `FIXED_${position.type}`);
      delete position.fixedPosition;
    }
  };

  const getDeliveryType = (deliveryType, station) => {
    if (!deliveryType) {
      const defaultValues = _environment.default.APP.defaultDeliveryTypeByStation;
      const deliveryTypes = Object.keys(defaultValues);
      let defaultValue = null;
      deliveryTypes.forEach(delType => {
        const found = defaultValues[delType].find(stationCode => stationCode === station);
        if (found) defaultValue = delType;
      });
      return defaultValue || "VIA_RADIO";
    }

    return deliveryType;
  };

  const getDefaultTeamType = station => {
    return Object.entries(_environment.default.APP.defaultTeamTypeByStation).filter(entry => entry[1].includes(station)).map(entry => entry[0])[0];
  };

  function getNormalizedPcr(payload, pcrClassificator, station, teamId, defaultFragmentValue) {
    const objects = defaultFragmentValue ? (0, _backendVars.OBJECTS)(defaultFragmentValue) : OBJECTS_LIST;
    if (payload.eventCancelReason === "PATIENT_NOT_FOUND") // TODO kai backendas istrins palaikyma senam FE istrinti sita if'a
      delete payload.eventCancelReason;
    replacePatientTransportationPositionFixed(Ember.get(payload, "transportation.position"));
    payload.injuryLocation = (0, _backendMirageNormalize.remakeInjuryLocationStructure)(payload.injuryLocation, pcrClassificator);
    updateMissingStructure(payload, objects);
    payload.teamActivation.deliveryType = getDeliveryType(payload.teamActivation.deliveryType, station);

    if (!payload.teamActivation.teamType) {
      const defaultTeamType = getDefaultTeamType(station);
      if (defaultTeamType) payload.teamActivation.teamType = defaultTeamType;
    }

    replacePatientAssessments(pcrClassificator, payload.patientAssessments, defaultFragmentValue);
    replaceObjectToFragment(pcrClassificator, payload, objects, defaultFragmentValue);
    replaceTreatment(pcrClassificator, payload.patientTreatment.medicamentTreatment, _backendVars.MEDICAMENTS_OBJECTS, defaultFragmentValue);
    replaceTreatment(pcrClassificator, payload.patientTreatment.procedureTreatment, _backendVars.PROCEDURES_OBJECTS, defaultFragmentValue);
    replaceTreatment(pcrClassificator, payload.patientTreatment.inventory, _backendVars.INVENTORY_OBJECTS, defaultFragmentValue);
    normalizeLazyFragments(payload, defaultFragmentValue);
    payload.patientTreatment.medicamentTreatment = (0, _backendMirageNormalize.getNormalizedTreatmentMembers)(payload.patientTreatment.medicamentTreatment);
    payload.patientTreatment.procedureTreatment = (0, _backendMirageNormalize.getNormalizedTreatmentMembers)(payload.patientTreatment.procedureTreatment);
    payload.patientTreatment.medicamentTreatment = (0, _backendMirageNormalize.getNormalizedMedicaments)(payload.patientTreatment.medicamentTreatment, pcrClassificator, station);
    payload.teamActivation.activationProtocol = getNormalizedActivationProtocol(payload, pcrClassificator);
    ifNoTeamLeaderAndHisTypeGivenMakeDefaultValue(payload, pcrClassificator);
    payload.teamActivation.team.members = (0, _backendMirageNormalize.makeSelectedMembersArraySize)(payload.teamActivation.team.members);
    if (!payload.teamActivation.team.code && !Ember.isEmpty(teamId)) payload.teamActivation.team = {
      code: teamId,
      name: teamId
    };
    selectInfoShareWithPersonConfirmation(payload);
  }
});