define("epcr-uis/pods/pcr-classificator/medicaments/neringa", ["exports", "epcr-uis/pods/pcr-classificator/medicaments/medicaments-list"], function (_exports, _medicamentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NERINGA_MEDICAMENTS = void 0;

  const NERINGA_MEDICAMENTS = t => ({
    stations: ["27"],
    medicaments: [(0, _medicamentsList.MEDICAMENTS)(t)[206], (0, _medicamentsList.MEDICAMENTS)(t)[208], // 208 eina pirmiau už 207, kad būtų gražiai išdėliota
    (0, _medicamentsList.MEDICAMENTS)(t)[207], (0, _medicamentsList.MEDICAMENTS)(t)[212], (0, _medicamentsList.MEDICAMENTS)(t)[214], (0, _medicamentsList.MEDICAMENTS)(t)[221], (0, _medicamentsList.MEDICAMENTS)(t)[216], (0, _medicamentsList.MEDICAMENTS)(t)[228], (0, _medicamentsList.MEDICAMENTS)(t)[230], (0, _medicamentsList.MEDICAMENTS)(t)[236], (0, _medicamentsList.MEDICAMENTS)(t)[237], (0, _medicamentsList.MEDICAMENTS)(t)[240], (0, _medicamentsList.MEDICAMENTS)(t)[242], (0, _medicamentsList.MEDICAMENTS)(t)[244], (0, _medicamentsList.MEDICAMENTS)(t)[246], (0, _medicamentsList.MEDICAMENTS)(t)[251], (0, _medicamentsList.MEDICAMENTS)(t)[252], (0, _medicamentsList.MEDICAMENTS)(t)[253], (0, _medicamentsList.MEDICAMENTS)(t)[254], (0, _medicamentsList.MEDICAMENTS)(t)[255], (0, _medicamentsList.MEDICAMENTS)(t)[256], (0, _medicamentsList.MEDICAMENTS)(t)[257], (0, _medicamentsList.MEDICAMENTS)(t)[259], (0, _medicamentsList.MEDICAMENTS)(t)[260], (0, _medicamentsList.MEDICAMENTS)(t)[261], (0, _medicamentsList.MEDICAMENTS)(t)[262], (0, _medicamentsList.MEDICAMENTS)(t)[263], (0, _medicamentsList.MEDICAMENTS)(t)[264], (0, _medicamentsList.MEDICAMENTS)(t)[265], (0, _medicamentsList.MEDICAMENTS)(t)[266], (0, _medicamentsList.MEDICAMENTS)(t)[267], (0, _medicamentsList.MEDICAMENTS)(t)[270], (0, _medicamentsList.MEDICAMENTS)(t)[271]]
  });

  _exports.NERINGA_MEDICAMENTS = NERINGA_MEDICAMENTS;
});