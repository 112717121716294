define("epcr-uis/pods/components/usage-survey/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    surveyRoute: false,
    iframeVisible: false,
    classNames: ["survey"],
    classNameBindings: ["surveyRoute:visible:hidden"],
    router: Ember.inject.service(),
    login: Ember.inject.service(),
    features: Ember.inject.service(),
    session: Ember.inject.service(),
    iframeUrl: Ember.computed(function () {
      const formUrl = "https://docs.google.com/forms/d/e/1FAIpQLSd9q08iNcIgtYnTtIvHj7mwMhL7RqP5NDeZQYBlIioMjidmbw/viewform?usp=pp_url";
      const dateParam = "&entry.673681201";
      const whiteSpaceToHideStationAndUsername = " ".repeat(100);
      const date = (0, _moment.default)().format("YYYY-MM-DD");
      const {
        username
      } = this.login.employeeData;
      const station = this.login.getStationId();
      const encodedString = btoa(`${station}:${username}`);
      return `${formUrl}${dateParam}=${date}${whiteSpaceToHideStationAndUsername}${encodedString}`;
    }),

    isSurveyRoute() {
      Ember.set(this, "surveyRoute", this.router.currentRouteName === "survey");
    },

    init() {
      this._super(...arguments);

      this.router.addObserver("currentRouteName", this, "isSurveyRoute");
      this.session.on("invalidationSucceeded", this, "removeIframe");
    },

    removeIframe() {
      Ember.set(this, "iframeVisible", false);
    }

  });

  _exports.default = _default;
});