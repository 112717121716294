define("epcr-uis/pods/address/street/model", ["exports", "ember-data", "epcr-uis/pods/address/city/model", "epcr-uis/helpers/street-formatter"], function (_exports, _emberData, _model, _streetFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _model.default.extend({
    code: Ember.computed("street", "city", "municipality", "eldership", function () {
      const properties = this.getProperties("street", "city", "municipality", "eldership");
      return (0, _streetFormatter.streetFormatter)([properties]);
    }),
    street: attr("string")
  });

  _exports.default = _default;
});