define("epcr-uis/pods/components/pcr-document/flowchart/medicament-input/new/component", ["exports", "ember-concurrency", "epcr-uis/helpers/create-treatment", "epcr-uis/config/environment", "epcr-uis/mixins/treatment-observer"], function (_exports, _emberConcurrency, _createTreatment, _environment, _treatmentObserver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_treatmentObserver.default, {
    pcrClassificator: Ember.inject.service(),
    store: Ember.inject.service(),
    login: Ember.inject.service(),
    validation: Ember.inject.service(),
    pcrTime: Ember.inject.service("pcr/time"),
    usedMedicaments: Ember.computed.alias("pcr.patientTreatment.medicamentTreatment.[]"),
    restrict99MedicamentForStations: _environment.default.APP.restrict99MedicamentForStations,
    filteredItems: Ember.computed("filteredItems", function () {
      return this.pcrClassificator.get("medicaments");
    }),
    onMedicamentEditPopupClose: (0, _emberConcurrency.task)(function* () {
      yield this.set("showMedicamentPopup", false);
    }),
    onMedicamentsPopupClose: (0, _emberConcurrency.task)(function* () {
      yield this.transitionTo("pcr.flowchart");
    }),

    setMediumPopup(medicamentTypesLength) {
      this.set("isMediumPopupOpen", medicamentTypesLength > 1);
    },

    showPopupAndSetTitle(title) {
      this.set("showMedicamentPopup", true);
      this.set("popupTitle", title);
    },

    createMedicament(medicament) {
      const time = this.pcrTime.newTime(this.pcr).forTreatment();
      const medicamentFragment = (0, _createTreatment.createMedicament)([medicament, this.store, time]);
      this.pcr.patientTreatment.medicamentTreatment.pushObject(medicamentFragment);
      this.set("medicament", medicamentFragment);
      this.addObserverForMedicament(medicamentFragment);
    },

    is99MedicamentRestricted: Ember.computed("login.stationId", "is99MedicamentSelected", function () {
      const {
        login: {
          stationId
        },
        restrict99MedicamentForStations,
        is99MedicamentSelected
      } = this;
      return is99MedicamentSelected && restrict99MedicamentForStations.includes(stationId);
    }),

    handleNewMedicament(medicament) {
      this.showPopupAndSetTitle(medicament.name);
      this.createMedicament(medicament);
      this.setMediumPopup(medicament.types.length);
    },

    actions: {
      onMedicamentSelect(medicament) {
        const is99MedicamentSelected = Ember.get(medicament, "types.firstObject.code") === 99;
        Ember.set(this, "is99MedicamentSelected", is99MedicamentSelected);

        if (this.is99MedicamentRestricted) {
          Ember.set(this, "medicament99", medicament);
          Ember.set(this, "showConfirmationModal", true);
        } else {
          this.handleNewMedicament(medicament);
        }
      },

      onDelete(data) {
        this.pcr.patientTreatment.medicamentTreatment.removeObject(data);
      },

      onRestrictionConfirmed() {
        this.handleNewMedicament(Ember.get(this, "medicament99"));
        Ember.set(this, "showConfirmationModal", false);
      }

    }
  });

  _exports.default = _default;
});