define("epcr-uis/pods/address/city/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "epcr-uis/helpers/city-formatter"], function (_exports, _emberData, _fragment, _cityFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    code: Ember.computed("city", "municipality", "eldership", function () {
      const properties = this.getProperties("city", "municipality", "eldership");
      return (0, _cityFormatter.cityFormatter)([properties]);
    }),
    city: attr("string"),
    eldership: attr("string"),
    municipality: attr("string")
  });

  _exports.default = _default;
});