define("epcr-uis/pods/components/pcr-document/flowchart/inventory-input/edit/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pcrClassificator: Ember.inject.service(),
    onEditPopupClose: (0, _emberConcurrency.task)(function* () {
      yield this.set("isRightPopupOpen", false);
    }),
    actions: {
      onDelete(data) {
        return this.onDelete(data);
      }

    }
  });

  _exports.default = _default;
});