define("epcr-uis/pods/history/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    clearBrowserHistory() {
      Ember.run.next(() => {
        for (let i = 0; i < 50; i++) history.pushState(history.state, "");
      });
    }

  });

  _exports.default = _default;
});