define("epcr-uis/pods/pcr/time/service", ["exports", "moment", "epcr-uis/helpers/get-assessment-by-type"], function (_exports, _moment, _getAssessmentByType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultTimeOffsetMin = 1;

  const cloneTimeAndAddMins = (time, extraMinutes) => time && time.clone().add(extraMinutes, "m").set({
    second: 0,
    millisecond: 0
  });

  const getMaxTreatmentTime = pcr => {
    let maxTime = null;

    const comparator = (treatment1, treatment2) => {
      const time1 = treatment1 && Ember.get(treatment1, "time");
      const time2 = treatment2 && Ember.get(treatment2, "time");
      return !time2 || time1 >= time2 ? treatment1 : treatment2;
    };

    ["patientTreatment.medicamentTreatment", "patientTreatment.procedureTreatment"].forEach(key => {
      const treatment = Ember.get(pcr, key);
      maxTime = treatment.reduce(comparator, maxTime);
    });
    return maxTime;
  };

  const getAATime = pcr => {
    const transportationTime = Ember.get(pcr, "times.transportationTime");
    if (transportationTime) return cloneTimeAndAddMins(transportationTime, -2);
    const availableTime = Ember.get(pcr, "times.availableTime");
    if (availableTime) return cloneTimeAndAddMins(availableTime, -2);
    const treatment = getMaxTreatmentTime(pcr);
    return treatment && cloneTimeAndAddMins(Ember.get(treatment, "time"), +10);
  };

  const latestArrivedTime = pcr => {
    const timeFromEditRequest = Ember.get(pcr, "metadata.editTimesRequests.arrivedTime.to");
    return timeFromEditRequest ? timeFromEditRequest : Ember.get(pcr, "times.arrivedTime");
  };

  const newAssessment = (type, pcr) => {
    const assessment = {
      BEFORE_HELP: () => cloneTimeAndAddMins(latestArrivedTime(pcr), +2),
      AT_ER: () => cloneTimeAndAddMins(Ember.get(pcr, "times.atHospitalTime"), +1),
      AFTER_HELP: () => getAATime(pcr)
    };
    return {
      getTime: () => assessment[type] && assessment[type]()
    };
  };

  const newTreatment = (pcr, timeOffsetMin) => {
    let time;
    const treatment = getMaxTreatmentTime(pcr);

    if (treatment) {
      timeOffsetMin = Ember.isEmpty(timeOffsetMin) ? defaultTimeOffsetMin : timeOffsetMin;
      time = cloneTimeAndAddMins(Ember.get(treatment, "time"), timeOffsetMin);
    }

    if (!time) {
      const assessment = (0, _getAssessmentByType.getAssessmentByType)(Ember.get(pcr, "patientAssessments"), "BEFORE_HELP");
      if (assessment) time = cloneTimeAndAddMins(Ember.get(assessment, "time"), +2);
      if (!time) time = cloneTimeAndAddMins(latestArrivedTime(pcr), +4);
    }

    return time;
  };

  var _default = Ember.Service.extend({
    timeService: Ember.inject.service("time"),

    toCurrentTimeIfFuture(time) {
      return !time || time > (0, _moment.default)() ? this.timeService.getTime() : time;
    },

    newTime(pcr) {
      const self = this;
      return {
        forAssessment(type) {
          const time = newAssessment(type, pcr).getTime();
          return self.toCurrentTimeIfFuture(time);
        },

        forTreatment(timeOffsetMin) {
          const time = newTreatment(pcr, timeOffsetMin);
          return self.toCurrentTimeIfFuture(time);
        }

      };
    }

  });

  _exports.default = _default;
});