define("epcr-uis/mirage/factories/pcr-header", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getRandomNum = (min, max) => _emberCliMirage.faker.random.number({
    min,
    max
  });

  var _default = _emberCliMirage.Factory.extend({
    metadata: {
      owner: 3,
      state: "VERIFIED"
    },
    category: getRandomNum(1, 4),
    dispatch: "55",
    callInformation: {
      phoneNumber1: `+370${getRandomNum(11111111, 99999999)}`,
      phoneNumber2: `+370${getRandomNum(11111111, 99999999)}`,
      phoneNumber3: `+370${getRandomNum(11111111, 99999999)}`,
      callTakerCode: getRandomNum(111, 999),
      eventSite: {
        address: _emberCliMirage.faker.address.streetAddress()
      }
    },
    teamActivation: {
      dispatcherCode: getRandomNum(111, 999),
      activationProtocol: 2,
      team: {
        code: getRandomNum(111, 999)
      }
    },
    times: {
      callTime: () => "2018-01-01 10:11",
      registrationTime: () => "2018-01-01 10:11",
      assignTime: () => "2018-01-01 10:12",
      onRouteTime: () => "2018-01-01 10:13",
      arrivedTime: () => "2018-01-01 10:14",
      transportationTime: () => "2018-01-01 10:15",
      atHospitalTime: () => "2018-01-01 10:16",
      availableTime: () => "2018-01-01 10:17",
      returnedTime: () => "2018-01-01 10:18"
    }
  });

  _exports.default = _default;
});