define("epcr-uis/pods/components/pcr-document/signatures/confirmation-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NEHhuKpG",
    "block": "{\"symbols\":[\"@text\",\"@confirmation\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"text\"],[8],[1,[23,1,[]],false],[9],[0,\":\\n\"],[7,\"strong\",true],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/signatures/confirmation-text/template.hbs"
    }
  });

  _exports.default = _default;
});