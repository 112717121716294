define("epcr-uis/helpers/address-formatter", ["exports", "epcr-uis/helpers/city-formatter"], function (_exports, _cityFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addressFormatter = addressFormatter;
  _exports.default = void 0;

  const addSpace = value => value ? " " : "";

  const addComma = value => value ? ", " : "";

  const valueWithComma = (format, value) => {
    if (value) format += addComma(format) + value;
    return format;
  };

  const valueWithSpace = (format, value) => {
    if (value) format += addSpace(format) + value;
    return format;
  };

  const valueBetweenBrackets = (format, value) => {
    if (value) format += format ? ` (${value})` : `(${value})`;
    return format;
  };

  function addressFormatter([address]) {
    let formattedAddress = "";
    if (!address) return formattedAddress;
    const {
      street,
      house,
      flat,
      additionalInformation
    } = Ember.getProperties(address, "street", "house", "flat", "additionalInformation");
    formattedAddress = valueWithComma(formattedAddress, street);
    formattedAddress = valueWithSpace(formattedAddress, house);
    if (house && flat) formattedAddress += `-${flat}`;
    formattedAddress = valueWithComma(formattedAddress, (0, _cityFormatter.cityFormatter)([address]));
    formattedAddress = valueBetweenBrackets(formattedAddress, additionalInformation);
    return formattedAddress;
  }

  var _default = Ember.Helper.helper(addressFormatter);

  _exports.default = _default;
});