define("epcr-uis/pods/pcr-reloader/pcr-header/by-period/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    period: 60000,
    debounce: 500,
    pcrHeader: Ember.inject.service(),

    watch(callback, pcr) {
      this._watchByPeriod(callback, pcr);
    },

    stopWatch() {
      this._stopWatchByPeriod();
    },

    _stopWatchByPeriod() {
      this.pcrHeader.cancelPcrHeaderSynchronizer();
    },

    _watchByPeriod(callback, pcr) {
      callback && callback(pcr, this.debounce, this.period);
    }

  });

  _exports.default = _default;
});