define("epcr-uis/mirage/factories/stroke-criteria", ["exports", "ember-cli-mirage", "moment"], function (_exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    faceParalysis: false,
    speechDisorder: true,
    armsWeakened: false,
    durationLte3h: true,
    startTime: (0, _moment.default)(),
    erNotified: false
  });

  _exports.default = _default;
});