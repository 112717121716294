define("epcr-uis/pods/pcr/patient-details/age/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    measure: (0, _attributes.fragment)("pcr-classificator"),
    age: attr("number"),
    approximate: attr("boolean")
  });

  _exports.default = _default;
});