define("epcr-uis/helpers/deferred-content-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deferredContentPromise = deferredContentPromise;

  function deferredContentPromise() {
    return new Ember.RSVP.Promise(resolve => Ember.run.next(this, () => resolve()));
  }
});