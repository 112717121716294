define("epcr-uis/pods/user-preferences/service", ["exports", "epcr-uis/config/environment", "ember-concurrency", "epcr-uis/constants"], function (_exports, _environment, _emberConcurrency, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    simpleAuthFetch: Ember.inject.service(),
    isLoadSuccessful: null,
    defaultPreferences: Ember.computed({
      get() {
        const defaultPreferences = {};
        defaultPreferences[_constants.FEAT_SOUND_ON_CLICK] = true;
        defaultPreferences[_constants.FEAT_RAPID_INPUT_MODE] = true;
        defaultPreferences[_constants.FEAT_NIGHT_MODE] = false;
        defaultPreferences[_constants.FEAT_TIMELINES] = true;
        defaultPreferences[_constants.FEAT_RELATED_PROCEDURES] = true;
        defaultPreferences[_constants.FEAT_FLASHLIGHT] = false;
        defaultPreferences[_constants.TEAM_ACTIVATION] = null;
        defaultPreferences[_constants.SELECTED_TEAM_CODE] = null;
        defaultPreferences[_constants.SELECTED_SHIFT_DURATION] = 24;
        defaultPreferences[_constants.TEAM_LEADER_TYPE] = null;
        defaultPreferences[_constants.READ_NEWS] = null;
        defaultPreferences[_constants.TEAM_TYPE] = null;
        return defaultPreferences;
      },

      set: (key, value) => value
    }),

    updateKeyValue(key, value) {
      Ember.set(this.userPreferences, key, value);
      if (key in this.defaultPreferences) this.isLoadSuccessful && this.saveUserPreferences.perform();
    },

    preferencesKeys: Ember.computed({
      get() {
        return Object.keys(this.defaultPreferences);
      },

      set: (key, value) => value
    }),
    userPreferences: Ember.computed(_constants.FEAT_SOUND_ON_CLICK, _constants.FEAT_RAPID_INPUT_MODE, _constants.TEAM_ACTIVATION, _constants.SELECTED_TEAM_CODE, _constants.FEAT_TIMELINES, _constants.TEAM_LEADER_TYPE, _constants.FEAT_NIGHT_MODE, _constants.READ_NEWS, _constants.FEAT_FLASHLIGHT, _constants.FEAT_RELATED_PROCEDURES, _constants.SELECTED_SHIFT_DURATION, _constants.TEAM_TYPE, function () {
      const preferences = {};
      this.preferencesKeys.forEach(key => Ember.set(preferences, key, Ember.get(this, key)));
      return preferences;
    }),
    userId: Ember.computed("session.isAuthenticated", function () {
      return Ember.get(this.session, "data.authenticated.tokenData.eId");
    }),

    async onPreferencesSaveError(e, retryCount) {
      console.error(e); // eslint-disable-line

      const {
        saveErrorRetryIntervals
      } = _environment.default.APP;

      if (retryCount < saveErrorRetryIntervals.length) {
        await (0, _emberConcurrency.timeout)(saveErrorRetryIntervals[retryCount]);
        this.saveUserPreferences.perform(retryCount + 1);
      }
    },

    saveUserPreferences: (0, _emberConcurrency.task)(function* (retryCount = 0) {
      Ember.set(this, "saveError", null);
      const {
        userPreferencesSaveRequestDebounce,
        userPreferencesUrl
      } = _environment.default.APP;
      const url = userPreferencesUrl.replace(":id", this.userId);
      yield (0, _emberConcurrency.timeout)(userPreferencesSaveRequestDebounce);
      return yield this.simpleAuthFetch.fetchRequest(url, "PUT", this.userPreferences).catch(async e => {
        Ember.set(this, "saveError", e);
        await this.onPreferencesSaveError(e, retryCount);
      });
    }).restartable(),
    retrieveUserPreferencesTask: (0, _emberConcurrency.task)(function* () {
      if (this.userId) {
        const url = _environment.default.APP.userPreferencesUrl.replace(":id", this.userId);

        return yield this.simpleAuthFetch.fetchRequest(url, "GET").then(response => {
          Ember.set(this, "isLoadSuccessful", true);
          return response.json();
        }).catch(e => {
          console.error(e); // eslint-disable-line

          Ember.set(this, "isLoadSuccessful", false);
          return null;
        });
      }

      return null;
    }).restartable(),

    updatePreferences(source) {
      this.preferencesKeys.forEach(key => {
        const value = Ember.get(source, key);
        Ember.isPresent(value) && Ember.set(this, key, value);
      });
    },

    async loadFromServer() {
      const userPreferences = await this.retrieveUserPreferencesTask.perform();
      userPreferences && this.updatePreferences(userPreferences);
    },

    loadDefault() {
      this.updatePreferences(this.defaultPreferences);
    },

    async loadUserPreferences() {
      this.loadDefault();
      await this.loadFromServer();
      Ember.set(this, "preferencesUpdated", true);
    }

  });

  _exports.default = _default;
});