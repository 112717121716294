define("epcr-uis/pods/components/pcr-document/flowchart/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l7SdjSmk",
    "block": "{\"symbols\":[\"InputEdit\",\"@title\",\"@data\",\"@parentId\",\"@teamMembers\",\"@index\",\"@isRightPopupOpen\",\"@flowcharts\",\"&default\"],\"statements\":[[14,9,[[28,\"hash\",null,[[\"time\",\"condition\",\"member\",\"note\"],[[28,\"component\",[\"pcr-document/flowchart/input/time\"],[[\"time\"],[[24,[\"time\"]]]]],[28,\"component\",[\"pcr-document/flowchart/input/condition\"],[[\"conditionChange\"],[[24,[\"conditionChange\"]]]]],[28,\"component\",[\"pcr-document/flowchart/input/member\"],[[\"member\"],[[24,[\"member\"]]]]],[28,\"component\",[\"pcr-document/flowchart/input/note\"],[[\"note\"],[[24,[\"note\"]]]]]]]]]],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[[28,\"concat\",[\"pcr-document/flowchart/\",[23,3,[\"model\"]],\"-input/edit\"],null]],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[],[[\"@title\",\"@data\",\"@parentClass\",\"@teamMembers\",\"@index\",\"@isRightPopupOpen\",\"@onDelete\",\"@flowcharts\"],[[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[28,\"action\",[[23,0,[]],\"onDelete\"],null],[23,8,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/flowchart/input/template.hbs"
    }
  });

  _exports.default = _default;
});