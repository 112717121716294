define("epcr-uis/pods/components/pcr-document/patient/no-patient-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LRfKDkhW",
    "block": "{\"symbols\":[\"absent\",\"notFound\"],\"statements\":[[4,\"let\",[[28,\"eq\",[[24,[\"noPatientReason\"]],[24,[\"absent\"]]],null],[28,\"eq\",[[24,[\"noPatientReason\"]],[24,[\"notFound\"]]],null]],null,{\"statements\":[[0,\"\\n  \"],[5,\"checkbox-true-null\",[],[[\"@title\",\"@checked\",\"@locked\",\"@onValueChange\"],[[28,\"t\",[\"headings.patientDetails.notFound\"],null],[23,2,[]],[23,1,[]],[28,\"action\",[[23,0,[]],\"onNotFound\"],null]]]],[0,\"\\n\\n  \"],[5,\"checkbox-true-null\",[],[[\"@title\",\"@checked\",\"@locked\",\"@onValueChange\"],[[28,\"t\",[\"headings.patientDetails.absent\"],null],[23,1,[]],[23,2,[]],[28,\"action\",[[23,0,[]],\"onAbsent\"],null]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/patient/no-patient-block/template.hbs"
    }
  });

  _exports.default = _default;
});