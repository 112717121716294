define("epcr-uis/pods/components/used-medicaments/list/component", ["exports", "epcr-uis/mixins/metrics-tracker"], function (_exports, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    "data-test-id": "used-medicaments-data",
    userPreferencesService: Ember.inject.service("user-preferences"),
    actions: {
      onMedicamentTypeClick({
        medicamentCode
      }, isSelected) {
        this.trackEvent("Used Medicament", "DrillDown", `Medicament code=[${medicamentCode}] Action [${isSelected ? "close" : "open"}]]`);
        const [{
          from,
          to
        }] = this.getMedicaments.last.args;
        !isSelected && this.getMedicamentEvents.perform(medicamentCode, from, to);
        Ember.set(this, "selectedMedicamentCode", isSelected ? null : medicamentCode);
      }

    }
  });

  _exports.default = _default;
});