define("epcr-uis/pods/pcr/team-activation/model", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _fragment.default.extend({
    deliveryType: (0, _attributes.fragment)("pcr-classificator"),
    activationProtocol: (0, _attributes.fragment)("pcr-classificator"),
    dispatcherCode: attr("string"),
    signalsToSite: attr("boolean"),
    teamType: attr("string"),
    team: (0, _attributes.fragment)("pcr/team-activation/team")
  });

  _exports.default = _default;
});