define("epcr-uis/router", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.on("routeDidChange", () => {
        this._trackPage();
      });
    },

    _trackPage() {
      Ember.run.scheduleOnce("afterRender", this, () => {
        const page = this.url;
        const title = this.getWithDefault("currentRouteName", "unknown");
        this.metrics.trackPage({
          page,
          title
        });
      });
    }

  });
  Router.map(function () {
    this.route("pcr", {
      path: "pcr/:pcrId"
    }, function () {
      this.route("index", {
        path: "/"
      });
      this.route("event");
      this.route("patient");
      this.route("vitals", function () {
        this.route("edit", {
          path: "/:vitalsId"
        });
        this.route("new");
      });
      this.route("transportation");
      this.route("forms");
      this.route("signatures");
      this.route("narrative");
      this.route("flowchart", function () {
        this.route("medicament", function () {
          this.route("new");
        });
        this.route("procedure", function () {
          this.route("new");
        });
        this.route("inventory", function () {
          this.route("new");
        });
        this.route("recommendations");
      });
      this.route("summary", function () {
        this.route("print");
        this.route("validations");
        this.route("save");
      });
      this.route("error");
      this.route("config");
      this.route("attachments");
      this.route("tools", function () {
        this.route("pediatric-calculator");
        this.route("apgar-calculator");
        this.route("dose-calculator");
      });
    });
    this.route("not-authenticated");
    this.route("login");
    this.route("training");
    this.route("home");
    this.route("list", function () {
      this.route("my-pcrs");
      this.route("active-pcrs");
      this.route("verified-pcrs");
      this.route("used-medicaments");
    });
    this.route("config");
    this.route("print", function () {
      this.route("form", {
        path: "/form/:pcrId"
      });
      this.route("empty");
    });
    this.route("news", function () {
      this.route("article", {
        path: "/:articleId"
      });
    });
    this.route("updates");
    this.route("load-config");
    this.route("survey");
    this.route("tools", function () {
      this.route("apgar-calculator");
      this.route("dose-calculator");
      this.route("pediatric-calculator");
    });
  });
  var _default = Router;
  _exports.default = _default;
});