define("epcr-uis/pods/custom-inflector-rules/model", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inflector
  } = _emberInflector.default;
  inflector.uncountable("patient-personal-data");
  inflector.uncountable("events");
  var _default = {};
  _exports.default = _default;
});