define("epcr-uis/pods/components/pcr-document/incident/times/component", ["exports", "epcr-uis/mixins/is-station-allowed-to-create-new-pcr"], function (_exports, _isStationAllowedToCreateNewPcr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_isStationAllowedToCreateNewPcr.default, {
    features: Ember.inject.service(),
    tagName: "",
    isStationAllowedToCreateNewEpcr: Ember.computed({
      get() {
        return this.isStationAllowedToCreateNewPcr();
      }

    }),

    getPcrTime(key) {
      return Ember.get(this, `pcr.times.${key}`);
    },

    times: Ember.computed(function () {
      const showTimeChangeRequests = Ember.get(this.features, "showTimeChangeRequests");
      const callTime = "callTime";
      const registrationTime = "registrationTime";
      const assignTime = "assignTime";
      const onRouteTime = "onRouteTime";
      const arrivedTime = "arrivedTime";
      const transportationTime = "transportationTime";
      const atHospitalTime = "atHospitalTime";
      const availableTime = "availableTime";
      const returnedTime = "returnedTime";
      return [{
        value: callTime,
        translationKey: `pcr.times.${callTime}`,
        id: "call-time",
        readonly: !this.isStationAllowedToCreateNewEpcr,
        time: this.getPcrTime(callTime)
      }, {
        value: registrationTime,
        translationKey: `pcr.times.${registrationTime}`,
        id: "registration-time",
        readonly: !this.isStationAllowedToCreateNewEpcr,
        time: this.getPcrTime(registrationTime)
      }, {
        value: assignTime,
        translationKey: `pcr.times.${assignTime}`,
        id: "assign-time",
        readonly: !this.isStationAllowedToCreateNewEpcr,
        time: this.getPcrTime(assignTime)
      }, {
        value: onRouteTime,
        translationKey: `pcr.times.${onRouteTime}`,
        id: "on-route-time",
        readonly: !showTimeChangeRequests,
        canRequestChange: showTimeChangeRequests,
        time: this.getPcrTime(onRouteTime)
      }, {
        value: arrivedTime,
        translationKey: `pcr.times.${arrivedTime}`,
        id: "arrived-time",
        readonly: !showTimeChangeRequests,
        canRequestChange: showTimeChangeRequests,
        time: this.getPcrTime(arrivedTime),
        breakLine: true
      }, {
        value: transportationTime,
        translationKey: `pcr.times.${transportationTime}`,
        id: "transportation-time",
        readonly: false,
        time: this.getPcrTime(transportationTime)
      }, {
        value: atHospitalTime,
        translationKey: `pcr.times.${atHospitalTime}`,
        id: "at-hospital-time",
        readonly: false,
        time: this.getPcrTime(atHospitalTime)
      }, {
        value: availableTime,
        translationKey: `pcr.times.${availableTime}`,
        id: "available-time",
        readonly: false,
        time: this.getPcrTime(availableTime)
      }, {
        value: returnedTime,
        translationKey: `pcr.times.${returnedTime}`,
        id: "returned-time",
        readonly: false,
        time: this.getPcrTime(returnedTime),
        sidebarFavouriteOption: {}
      }];
    })
  });

  _exports.default = _default;
});