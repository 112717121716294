define("epcr-uis/pods/pcr/flowchart/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    conditionMap: {
      "NO_CHANGE": {
        className: "genderless orange icon",
        dataTestId: "condition-did-not-change"
      },
      "POSITIVE_EFFECT": {
        className: "angle up green icon",
        dataTestId: "condition-better"
      },
      "NEGATIVE_EFFECT": {
        className: "angle down red icon",
        dataTestId: "condition-worse"
      },
      "HELP_REJECTED": {
        className: "ban grey icon",
        dataTestId: "condition-refused"
      },
      empty: {
        className: "minus icon none",
        dataTestId: "no-condition"
      }
    }
  });

  _exports.default = _default;
});