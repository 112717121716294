define("epcr-uis/pods/print/empty/route", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    simpleAuthFetch: Ember.inject.service(),

    model() {
      const source = () => {
        const url = _environment.default.APP.pdfsByIdUrl.replace(":pdfId", "empty-pcr-lt-v5");

        return this.simpleAuthFetch.fetchRequest(url, "GET");
      };

      return {
        source
      };
    }

  });

  _exports.default = _default;
});