define("epcr-uis/pods/pcr/comment/adapter", ["exports", "epcr-uis/pods/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    createUrl(pcrId) {
      return `${this.mainPath(`pcrs/${pcrId}/comments`)}`;
    },

    urlForQuery(queryParams) {
      const queryUrl = this.createUrl(queryParams.pcrId);
      delete queryParams.pcrId;
      return queryUrl;
    },

    urlForCreateRecord(modelName, {
      record
    }) {
      return this.createUrl(record.pcrId);
    }

  });

  _exports.default = _default;
});