define("epcr-uis/pods/support/service", ["exports", "epcr-uis/helpers/window-wrapper", "ember-window-mock"], function (_exports, _windowWrapper, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    android: Ember.inject.service(),
    login: Ember.inject.service(),

    redirectToSupportPage() {
      const {
        un: username,
        stat: stationId
      } = this.login.tokenData;
      const imei = this.android.getImei();
      const imeiUrlParam = imei ? `&imei=${imei}` : "";
      const url = `https://pagalba.033.lt/servicedesk?un=${username}&sid=${stationId}${imeiUrlParam}`;
      this.android.isAndroid() ? (0, _windowWrapper.callMethod)("window.control.loadUrl", url) : _emberWindowMock.default.open(url);
    }

  });

  _exports.default = _default;
});