define("epcr-uis/pods/pcr/metadata/model", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    owner: (0, _attributes.fragment)("lazy-user"),
    state: (0, _attributes.fragment)("pcr-classificator"),
    editTimesRequests: (0, _attributes.fragment)("pcr/edit-times-requests")
  });

  _exports.default = _default;
});