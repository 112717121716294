define("epcr-uis/pods/components/pcr-timelines/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    "data-test-id": "timelines",
    scrollToField: Ember.inject.service(),
    actions: {
      redirectTo(timeline) {
        let route, container;

        if (timeline.isPcrTime) {
          route = ["pcr.event"];
          container = timeline.name.split(/(?=[A-Z])/).join("-").toLowerCase();
        }

        if (timeline.isAssessment) {
          route = ["pcr.vitals"];
          container = timeline.name.replace("-", "_");
        }

        return this.scrollToField.scrollToField("", container, route);
      }

    }
  });

  _exports.default = _default;
});