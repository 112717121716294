define("epcr-uis/pods/pcr/summary/save/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+C4H1R0e",
    "block": "{\"symbols\":[],\"statements\":[[5,\"ui-container\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"save-summary\",[],[[\"@pcr\"],[[22,\"model\"]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"section-header\",[],[[\"@title\",\"@icon\"],[[28,\"t\",[\"headings.comments\"],null],\"comment outline icon big\"]]],[0,\"\\n\\n\"],[5,\"ui-container\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"comments-list\",[],[[\"@pcrId\",\"@onCommentSave\"],[[24,[\"model\",\"id\"]],[28,\"route-action\",[\"onCommentSave\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/pcr/summary/save/template.hbs"
    }
  });

  _exports.default = _default;
});