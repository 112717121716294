define("epcr-uis/pods/components/pcr-document/signatures/team-leader-signature/component", ["exports", "epcr-uis/helpers/name-formatter"], function (_exports, _nameFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["ui", "grid"],

    didReceiveAttrs() {
      this._super(...arguments);

      const teamLeaderName = (0, _nameFormatter.nameFormatter)([Ember.get(this, "teamLeader.firstName"), Ember.get(this, "teamLeader.lastName")]);
      if (teamLeaderName) Ember.set(this, "teamLeaderSignature.signerName", teamLeaderName);
    }

  });

  _exports.default = _default;
});