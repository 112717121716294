define("epcr-uis/pods/components/error-summary/tab/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scrollToField: Ember.inject.service(),
    errorCount: Ember.computed("errorGroup", function () {
      return this.errorGroup.filter(this.filterFunction, "error").length;
    }),
    warningCount: Ember.computed("errorGroup", function () {
      return this.errorGroup.filter(this.filterFunction, "warning").length;
    }),

    filterFunction(error) {
      return error.type === this;
    },

    includesMedicamentOrProcedure: string => string.includes("medicament") || string.includes("procedure"),

    getScrollOptions(focusField) {
      const options = {};
      if (focusField && this.includesMedicamentOrProcedure(focusField)) Ember.set(options, "doNotOpen", true);
      return options;
    },

    actions: {
      transitionToElement(error) {
        const options = this.getScrollOptions(error.focusField);
        this.scrollToField.scrollToField(error.elementContainer, error.focusField, error.routeWithParams, options);
      }

    }
  });

  _exports.default = _default;
});