define("epcr-uis/pods/components/pcr-document/flowchart/input/member/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cSj/p5xi",
    "block": "{\"symbols\":[\"@member\"],\"statements\":[[4,\"if\",[[28,\"or\",[[23,1,[\"firstName\"]],[24,[\"member\",\"lastName\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"member\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"user outline icon\"],[8],[9],[0,\"\\n    \"],[1,[28,\"initial-printer\",[[23,1,[]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/flowchart/input/member/template.hbs"
    }
  });

  _exports.default = _default;
});