define("epcr-uis/pods/components/pcr-document/specialty-patient-forms/information-transfer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eTd3dvqN",
    "block": "{\"symbols\":[\"TextArea\",\"@eventNotifications\"],\"statements\":[[5,\"checkbox-true-null\",[[12,\"class\",\"twelve wide column\"]],[[\"@title\",\"@checked\"],[[28,\"t\",[\"options.forms.informationTransfer.police\"],null],[23,2,[\"notifiedPolice\"]]]]],[0,\"\\n\\n\"],[5,\"checkbox-true-null\",[[12,\"class\",\"twelve wide column\"],[12,\"id\",\"info-transfer-form-phc-notified\"]],[[\"@title\",\"@validation\",\"@checked\"],[[28,\"t\",[\"options.forms.informationTransfer.phc\"],null],[24,[\"pcrValidation\",\"errors\",\"info-transfer-form-phc-notified\"]],[23,2,[\"notifiedPhcFacility\"]]]]],[0,\"\\n\\n\"],[5,\"checkbox-true-null\",[[12,\"class\",\"twelve wide column\"]],[[\"@title\",\"@checked\"],[[28,\"t\",[\"options.forms.informationTransfer.other\"],null],[23,2,[\"notifiedOther\"]]]]],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[\"side-bar/textarea-popup/textarea\"],null]],null,{\"statements\":[[0,\"  \"],[6,[23,1,[]],[[12,\"class\",\"twelve wide column\"],[12,\"id\",\"additional-information\"]],[[\"@value\",\"@title\"],[[23,2,[\"additionalInformation\"]],[28,\"t\",[\"options.forms.informationTransfer.additional\"],null]]]],[0,\"\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/pcr-document/specialty-patient-forms/information-transfer/template.hbs"
    }
  });

  _exports.default = _default;
});