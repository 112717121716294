define("epcr-uis/pods/components/pcr-login/users-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    users: [{
      username: "Mokymai",
      password: "Mokymai@2"
    }, {
      username: "admin",
      password: "Administratorius123",
      name: "Administratorius"
    }, {
      username: "stotis52",
      password: "DemoStotis123",
      name: "Ukmergė"
    }, {
      username: "stotis31",
      password: "DemoStotis123",
      name: "Panevėžys"
    }, {
      username: "stotis56",
      password: "DemoStotis123",
      name: "Vilniaus r."
    }, {
      username: "stotis27",
      password: "DemoStotis123",
      name: "Neringa"
    }, {
      username: "stotis35",
      password: "DemoStotis123",
      name: "Prienai"
    }, {
      username: "stotis54",
      password: "DemoStotis123",
      name: "Varėna"
    }],
    actions: {
      loginWithUser({
        username,
        password
      }) {
        return this.onLogin(username, password);
      }

    }
  });

  _exports.default = _default;
});