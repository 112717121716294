define("epcr-uis/mixins/on-pcr-list-item-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    pcrRouteTracker: Ember.inject.service(),

    setLastRouteAndVisit(pcrId) {
      this.pcrRouteTracker.set("lastVisitedPcrList", this.router.currentRouteName);
      return this.router.transitionTo("pcr", pcrId);
    },

    actions: {
      onPcrClicked(pcrId) {
        return this.setLastRouteAndVisit(pcrId);
      }

    }
  });

  _exports.default = _default;
});