define("epcr-uis/pods/employee/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    getEmployee(externalId) {
      return this.store.findRecord("employee", externalId, {
        backgroundReload: false
      });
    },

    queryEmployees(query, station) {
      return this.store.query("employee", {
        query,
        station,
        onlyWithCode: true
      });
    }

  });

  _exports.default = _default;
});