define("epcr-uis/pods/not-authenticated/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    actions: {
      transitionToRoute(route, nextRoute) {
        return this.router.transitionTo(route, {
          queryParams: {
            nextRoute
          }
        });
      }

    }
  });

  _exports.default = _default;
});