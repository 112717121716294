define("epcr-uis/pods/transition-pause/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    previousTransition: null,
    showConfirmationDialog: Ember.computed.alias("previousTransition"),

    onRouteLeave(pcrDocument, transition) {
      const isModelDirty = Ember.get(pcrDocument, "hasDirtyAttributes");

      if (isModelDirty && this.session.isAuthenticated) {
        transition.abort();
        Ember.set(this, "previousTransition", transition);
      }
    },

    continueTransition() {
      const previousTransition = Ember.get(this, "previousTransition");
      Ember.set(this, "previousTransition", null);
      previousTransition.retry();
    },

    cancelTransition() {
      Ember.set(this, "previousTransition", null);
    }

  });

  _exports.default = _default;
});