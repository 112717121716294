define("epcr-uis/pods/scroll-to-field/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    // TODO isnaikinti [0], kai nuo visur atsikabins jquery
    animate: async (el, scrollToBlock, behavior) => {
      await el[0].scrollIntoView({
        block: scrollToBlock || "center",
        inline: "start",
        behavior
      });
    },

    redirectToRoute(route) {
      const [routeName] = route;
      const routeParams = route.length > 1 ? route[1] : null;
      routeParams !== null ? this.router.transitionTo(routeName, routeParams) : this.router.transitionTo(routeName);
    },

    async clickOnElement(field) {
      if (!this.isCheckbox(field)) {
        const fieldElement = $(field);
        const elements = fieldElement.find("div, a");
        const elementWithIcon = elements.find(".icon:visible").not(".copy");
        if (elementWithIcon.length) await this.clickOnElements(elementWithIcon);else if (fieldElement.is("button")) await this.clickOnElements(fieldElement);else await this.clickOnElements(elements);
      }
    },

    isCheckbox: field => $(field).hasClass("checkbox-true-null") || $(field).hasClass("single-radiobox"),

    clickOnElements(elements) {
      let clicked = false;
      elements.toArray().forEach(element => {
        if ($(element).is(":visible") && !clicked) {
          element.click();
          clicked = true;
        }
      });
    },

    focusInputOrTextArea(field) {
      const elInput = $(`${field} input`);
      const elTextArea = $(`${field} textarea`);
      return elInput.$ ? this.clickOnElement(field) : elTextArea.focus();
    },

    animateWithFocusOrClick(field) {
      Ember.run.next(async () => {
        let el = $(field);
        !el.length && (await this.waitForFieldToAppear(field));
        el = $(field);
        el.length && (await this.animate(el));
        if (el.has("input").length || el.has("textarea").length) this.focusInputOrTextArea(field);
        !el.length && (await this.waitForFieldToAppear(field));
        await this.clickOnElement(field);
      });
    },

    async waitForFieldToAppear(field) {
      const maxLoopCount = 100;
      let counter = 0;
      return new Ember.RSVP.Promise(resolve => {
        const loop = setInterval(() => {
          counter++;
          if (counter > maxLoopCount || $(field).length) resolve(clearInterval(loop));
        }, 50);
      });
    },

    scrollToField(elementContainer, elementId, route, options = {}) {
      this.redirectToRoute(route);
      elementContainer = elementContainer ? elementContainer.split(".") : [];
      Ember.run.next(() => {
        elementContainer.forEach(element => this.animateWithFocusOrClick(element));
        if (elementId && !Ember.get(options, "doNotOpen")) Ember.run.next(async () => await this.animateWithFocusOrClick(`#${elementId}`));
      });
    },

    scrollTop: () => {
      const element = document.getElementById("pcr");
      element && element.scrollIntoView({
        block: "start"
      });
    }
  });

  _exports.default = _default;
});