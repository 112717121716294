define("epcr-uis/pods/components/used-medicaments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iqVtLlr1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"filterTask\",\"last\",\"isError\"]]],null,{\"statements\":[[0,\"  \"],[5,\"pcr-error\",[],[[\"@message\",\"@buttonName\",\"@showTopNavigation\",\"@onButtonClicked\"],[[24,[\"filterTask\",\"last\",\"error\"]],[28,\"t\",[\"actions.retry\"],null],false,[28,\"perform\",[[24,[\"filterTask\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"selectedShiftDuration\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ui-container\",[],[[],[]],{\"statements\":[[0,\"\\n\\n\"],[4,\"if\",[[24,[\"usedMedicaments\",\"queryUsedMedicamentsTask\",\"last\",\"value\"]]],null,{\"statements\":[[0,\"      \"],[5,\"used-medicaments/list\",[],[[\"@getMedicaments\",\"@getMedicamentEvents\"],[[24,[\"usedMedicaments\",\"queryUsedMedicamentsTask\"]],[24,[\"usedMedicaments\",\"queryMedicamentEvents\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"usedMedicaments\",\"queryUsedMedicamentsTask\",\"last\",\"value\",\"length\"]],0],null]],null,{\"statements\":[[0,\"      \"],[5,\"side-bar/notification\",[],[[\"@title\"],[\"Nėra sunaudotų vaistų\"]]],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[5,\"used-medicaments/filter\",[],[[\"@selectedShiftDuration\",\"@filterTask\",\"@shiftDurationInterval\",\"@renderInPlace\",\"@shiftDurations\"],[[22,\"selectedShiftDuration\"],[22,\"filterTask\"],[22,\"shiftDurationInterval\"],[22,\"renderInPlace\"],[22,\"shiftDurations\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/used-medicaments/template.hbs"
    }
  });

  _exports.default = _default;
});