define("epcr-uis/mixins/custom-infinity-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    model() {
      return null;
    },

    createInfinityDataParams: (searchId, perPage) => ({
      startingPage: 0,
      perPageParam: "limit",
      pageParam: "offset",
      perPage,
      searchId
    }),
    actions: {
      onInfinityLoad(model) {
        return Ember.get(this, "infinity").infinityLoad(model);
      }

    }
  });

  _exports.default = _default;
});