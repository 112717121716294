define("epcr-uis/mirage/factories/delay-reason", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const lithuanianDelayReasons = () => _emberCliMirage.faker.list.cycle("Eismas", "Oro sąlygos", "Nesaugi įv. vieta", "Atstumas iki paciento");

  const englishDelayReasons = () => _emberCliMirage.faker.list.cycle("Traffic", "Weather", "Dangerous site area", "Distance to patient");

  var _default = _emberCliMirage.Factory.extend({
    id: i => _emberCliMirage.faker.list.cycle("TRAFFIC", "WEATHER", "DANGEROUS_SITE_AREA", "DISTANCE_TO_PATIENT")(i),
    name: i => (0, _isEnglish.isEnglish)() ? englishDelayReasons()(i) : lithuanianDelayReasons()(i)
  });

  _exports.default = _default;
});