define("epcr-uis/pods/pcr/serializer-mirage", ["exports", "ember-data", "epcr-uis/pods/pcr/backend-mirage-normalize"], function (_exports, _emberData, _backendMirageNormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTSerializer = _emberData.default.RESTSerializer;

  const getNormalizedUsers = members => members.map(member => ({
    externalId: member
  }));

  const getNormalizedInjuryLocation = (injuries, classificator) => {
    // TODO kai mirage bus tolygus backendui sita crap reiks ismest
    const mappedInjuries = {};
    Object.keys(injuries).forEach(injury => {
      mappedInjuries[injury] = [];
      injuries[injury].forEach(code => {
        mappedInjuries[injury].push(classificator.findElementByCode("bodyPartsInjuries", code));
      });
    });
    return mappedInjuries;
  };

  var _default = RESTSerializer.extend({
    pcrClassificator: Ember.inject.service(),
    login: Ember.inject.service(),

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload.pcr) return this._super(store, primaryModelClass, payload, id, requestType);
      const {
        pcrClassificator
      } = this;
      payload.pcr.injuryLocation = (0, _backendMirageNormalize.remakeInjuryLocationStructure)(payload.pcr.injuryLocation, pcrClassificator);
      payload.pcr.teamActivation.team.members = getNormalizedUsers(payload.pcr.teamActivation.team.members);
      payload.pcr.teamActivation.team.members = (0, _backendMirageNormalize.makeSelectedMembersArraySize)(payload.pcr.teamActivation.team.members);
      payload.pcr.teamActivation.team.leaderType = pcrClassificator.findElementByCode("positions", payload.pcr.teamActivation.team.leaderType);
      const {
        icd10Code
      } = payload.pcr.patientDiagnosis;
      payload.pcr.patientDiagnosis.icd10Code = {
        code: icd10Code
      };
      payload.pcr.patientTreatment.medicamentTreatment = (0, _backendMirageNormalize.getNormalizedMedicaments)(payload.pcr.patientTreatment.medicamentTreatment, pcrClassificator, this.login.stationId);
      payload.pcr.patientTreatment.medicamentTreatment = (0, _backendMirageNormalize.getNormalizedTreatmentMembers)(payload.pcr.patientTreatment.medicamentTreatment);
      payload.pcr.patientTreatment.procedureTreatment = (0, _backendMirageNormalize.getNormalizedTreatmentMembers)(payload.pcr.patientTreatment.procedureTreatment);
      payload.pcr.patientTreatment.inventory = (0, _backendMirageNormalize.getNormalizedInventory)(payload.pcr.patientTreatment.inventory, pcrClassificator);
      payload.pcr.metadata.owner = (0, _backendMirageNormalize.getNormalizedOwner)(payload.pcr.metadata.owner);
      payload.pcr.metadata.state = pcrClassificator.findElementByCode("pcrStates", payload.pcr.metadata.state);
      payload.pcr.injuryLocation = getNormalizedInjuryLocation(payload.pcr.injuryLocation, pcrClassificator);
      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});