define("epcr-uis/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Route
  } = Ember; // Ensure the application route exists for ember-simple-auth's `setup-session-restoration` initializer

  var _default = Route.extend();

  _exports.default = _default;
});