define("epcr-uis/mirage/factories/is-english", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEnglish = void 0;

  const isEnglish = () => _environment.default.APP.defaultLocale === "en-us";

  _exports.isEnglish = isEnglish;
});