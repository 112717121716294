define("epcr-uis/pods/components/pcr-document/incident/component", ["exports", "epcr-uis/mixins/member-source", "epcr-uis/mixins/metrics-tracker", "epcr-uis/mixins/is-station-allowed-to-create-new-pcr", "epcr-uis/mixins/coordinates-from-event-site-or-station", "epcr-uis/helpers/promise-converter", "epcr-uis/helpers/address-formatter", "ember-concurrency", "epcr-uis/config/environment"], function (_exports, _memberSource, _metricsTracker, _isStationAllowedToCreateNewPcr, _coordinatesFromEventSiteOrStation, _promiseConverter, _addressFormatter, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const eventSite = "pcr.callInformation.eventSite.";
  const coordinatePath = `${eventSite}addressDetails.coordinate.`;

  const toFixedNumber = value => {
    const number = parseFloat(value);
    return !isNaN(number) ? number.toFixed(3) : value;
  };

  var _default = Ember.Component.extend(_coordinatesFromEventSiteOrStation.default, _isStationAllowedToCreateNewPcr.default, _memberSource.default, _metricsTracker.default, {
    pcrClassificator: Ember.inject.service(),
    addressService: Ember.inject.service("address"),
    station: Ember.computed.alias("pcr.station"),
    pcrHeader: Ember.inject.service(),
    router: Ember.inject.service(),
    historyService: Ember.inject.service("history"),
    fastComments: Ember.inject.service("pcr-classificator/fast-comments"),
    classNames: ["incident-route"],
    isStationAllowedToCreateNewEpcr: Ember.computed({
      get() {
        return this.isStationAllowedToCreateNewPcr();
      }

    }),
    isPcrUnsaved: Ember.computed({
      get() {
        return window.location.href.includes(_environment.default.APP.unsavedPcrId);
      }

    }),
    submit: (0, _emberConcurrency.task)(function* () {
      yield this.set("isAddressPopupOpen", false);
    }),
    mapTitle: Ember.computed(`${eventSite}address`, `${coordinatePath}{longitude,latitude}`, function () {
      const long = toFixedNumber(Ember.get(this, `${coordinatePath}longitude`));
      const lat = toFixedNumber(Ember.get(this, `${coordinatePath}latitude`));
      const address = Ember.get(this, `${eventSite}address`);
      return lat && long ? `${address} (${lat}, ${long})` : address;
    }),
    citiesSource: Ember.computed(function () {
      return query => (0, _promiseConverter.promiseToObjectsConverter)(this.cityDataSource(query), ["code", "city", "eldership", "municipality"]);
    }),
    addressCityOrNull: Ember.computed(`${eventSite}addressDetails.{city,municipality,eldership}`, function () {
      const {
        address
      } = Ember.get(this, `${eventSite}addressDetails`);
      return address && (Ember.get(address, "city") || Ember.get(address, "municipality") || Ember.get(address, "eldership")) ? address : null;
    }),
    formattedAddress: Ember.computed(`${eventSite}addressDetails.{city,municipality,street,house,eldership,flat}`, function () {
      const formattedAddress = (0, _addressFormatter.addressFormatter)([Ember.get(this, `${eventSite}addressDetails`)]);
      Ember.set(this, `${eventSite}address`, formattedAddress);
      return formattedAddress;
    }),

    cityDataSource(query) {
      return this.addressService.getAddressesByCity(query);
    },

    setAddressWithCityData(address, {
      eldership,
      municipality,
      city
    }) {
      Ember.setProperties(address, {
        eldership,
        municipality,
        city
      });
    },

    actions: {
      onOpenMapModal() {
        const address = Ember.get(this, `${eventSite}address`);
        this.trackEvent("Map", "Open incident address in map", address);
      },

      synchronizeAndTrackHeader(pcr) {
        this.pcrHeader.loadHeaderAndUpdatePcrModel(pcr);
        this.trackEvent("Update", "Synchronize header", this.pcr.eventId);
      },

      onCityChange(address, city) {
        this.setAddressWithCityData(address, Ember.getProperties(city, ["eldership", "municipality", "city"]));
      },

      onCityClear(address) {
        this.setAddressWithCityData(address, {
          city: null,
          eldership: null,
          municipality: null
        });
      }

    }
  });

  _exports.default = _default;
});