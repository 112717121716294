define("epcr-uis/pods/news/service", ["exports", "ember-concurrency", "epcr-uis/helpers/is-article-not-read", "epcr-uis/constants", "epcr-uis/mixins/later-loop", "epcr-uis/config/environment"], function (_exports, _emberConcurrency, _isArticleNotRead, _constants, _laterLoop, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_laterLoop.default, {
    topNavigation: Ember.inject.service(),
    simpleAuthFetch: Ember.inject.service(),
    login: Ember.inject.service(),
    userPreferencesService: Ember.inject.service("user-preferences"),
    readNews: Ember.computed("userPreferencesService.userPreferences.readNews", "clickedArticleId", {
      get() {
        const {
          clickedArticleId
        } = this;
        const result = this.userPreferencesService.userPreferences[_constants.READ_NEWS] || [];
        if (clickedArticleId && !result.includes(clickedArticleId)) result.push(clickedArticleId);
        return result;
      },

      set: (key, value) => value
    }),
    unreadNewsCount: Ember.computed("news.[]", "readNews.[]", function () {
      const unreadNews = [];
      const {
        news
      } = this;
      news && news.forEach(article => (0, _isArticleNotRead.isArticleNotRead)(this.readNews, article) && unreadNews.push(article.id));
      const {
        length
      } = unreadNews;
      Ember.run.schedule("afterRender", () => !this.isDestroyed && Ember.set(this, "topNavigation.hasUnreadNews", !!length));
      return length;
    }),

    makeRequest(url) {
      return this.simpleAuthFetch.fetchRequest(url, "GET").then(response => response.json());
    },

    getNewsArticleById: (0, _emberConcurrency.task)(function* (id) {
      const url = _environment.default.APP.articlesByIdUrl.replace(":articleId", id);

      return yield this.makeRequest(url);
    }),
    getNews: (0, _emberConcurrency.task)(function* () {
      const news = yield this.makeRequest(_environment.default.APP.articlesUrl);
      return Ember.set(this, "news", this.filterNewsByStations(news));
    }).restartable(),

    performGetNews() {
      this.getNews.perform();
    },

    newsWatcher(period) {
      this.runLoop(this.performGetNews.bind(this), period);
    },

    stopNewsWatcher() {
      this.cancelLoop();
    },

    filterNewsByStations(news) {
      const stationId = this.login.getStationId();
      return news && news.length && news.filter(article => {
        return !article.stations || !article.stations.length || article.stations.find(station => station === stationId);
      });
    }

  });

  _exports.default = _default;
});