define("epcr-uis/pods/notification-manager/service", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    pcr: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: [],

    init() {
      this._super(...arguments);

      this.pcr.on("onSuccessPcrSave", this, "_onSuccessPcrSave");
    },

    peekNotification() {
      return this.notifications.shift();
    },

    _addNotification(notification) {
      this.notifications.pushObject(notification);
      this.trigger("onNotification");
    },

    _onSuccessPcrSave() {
      this._addNotification({
        type: "success",
        message: this.intl.t("notifications.pcrSavedSuccessfully"),
        durationInSec: _environment.default.APP.successPcrSaveMessageDurationInSec
      });
    }

  });

  _exports.default = _default;
});