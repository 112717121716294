define("epcr-uis/pods/components/pcr-document/print/settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    print: Ember.inject.service(),
    actions: {
      openSettings() {
        return this.androidControls.onOpenPrinterSettingsClick();
      }

    }
  });

  _exports.default = _default;
});