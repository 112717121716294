define("epcr-uis/instance-initializers/register-updates", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  const bindFunction = (service, method) => method.bind(service);

  const bindUpdatesFunctions = (updatesService, onAvailableUpdatesMethod) => {
    const boundOnAvailableUpdates = bindFunction(updatesService, onAvailableUpdatesMethod);
    return {
      boundOnAvailableUpdates
    };
  };

  function initialize(appInstance) {
    const updatesService = appInstance.lookup("service:updates");

    if (_emberWindowMock.default.epcr && updatesService.isEnabled) {
      const {
        boundOnAvailableUpdates
      } = bindUpdatesFunctions(updatesService, updatesService.onAvailableUpdates);

      _emberWindowMock.default.epcr.onAvailableUpdates = updates => boundOnAvailableUpdates(updates);
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});