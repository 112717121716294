define("epcr-uis/pods/components/pcr-document/flowchart/component", ["exports", "moment", "epcr-uis/mixins/metrics-tracker", "epcr-uis/config/environment", "epcr-uis/helpers/android"], function (_exports, _moment, _metricsTracker, _environment, _android) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    features: Ember.inject.service(),
    recommendationService: Ember.inject.service("pcr/flowchart/recommendation"),
    classNames: ["flowchart"],
    flowcharts: Ember.computed.union("patientTreatment.medicamentTreatment", "patientTreatment.procedureTreatment"),
    applyTimeEditToOtherRecords: false,
    router: Ember.inject.service(),
    toolsVisible: Ember.computed(function () {
      const isProduction = _environment.default.environment === "production" && _environment.default.deployTarget === "production";
      return isProduction ? (0, _android.isAndroid)() : this.features.isEnabled("show-tools");
    }),
    sortedFlowcharts: Ember.computed.sort("flowcharts.@each.time", (flowchart1, flowchart2) => {
      const a = Ember.get(flowchart1, "time");
      const aModel = Ember.get(flowchart1, "model");
      const b = Ember.get(flowchart2, "time");
      const bModel = Ember.get(flowchart2, "model");
      if (a == null) return 1;else if (b == null) return -1;else if (_moment.default.compare(a, b) === 0 && aModel === "procedure" && bModel === "medicament") return -1;else return (0, _moment.default)(a).diff(b);
    }),

    getTimeFromRequest(timeKey) {
      return Ember.get(this, `pcr.metadata.editTimesRequests.${timeKey}.to`);
    },

    createPcrHeaderTimesInfo(times) {
      const timesInfo = [];

      if (times) {
        const timesKeys = ["arrivedTime", "transportationTime", "atHospitalTime"];
        timesKeys.forEach(timeKey => {
          const time = this.getTimeFromRequest(timeKey) || Ember.get(times, timeKey);
          time && timesInfo.push({
            isPcrTime: true,
            name: timeKey,
            time
          });
        });
      }

      return timesInfo;
    },

    createAssessmentsInfo(assessments) {
      const assessmentsInfo = [];
      assessments && assessments.forEach(assessment => {
        const assessmentType = Ember.get(assessment, "type.code");

        if (assessmentType === "BEFORE_HELP" || assessmentType === "AFTER_HELP") {
          assessmentsInfo.push({
            isAssessment: true,
            name: assessmentType,
            time: assessment.time
          });
        }
      });
      return assessmentsInfo;
    },

    sortTimelines(times, assessments) {
      const pcrHeaderTimes = this.createPcrHeaderTimesInfo(times);
      const patientAssessments = this.createAssessmentsInfo(assessments);
      const timelines = pcrHeaderTimes.concat(this.sortedFlowcharts).concat(patientAssessments);
      return timelines.sort((a, b) => (0, _moment.default)(a.time) - (0, _moment.default)(b.time));
    },

    sortedTimelines: Ember.computed("flowcharts.@each.time", "pcr.times.{arrivedTime,transportationTime,atHospitalTime}", "pcr.patientAssessments.@each.time", "features.timelines", function () {
      if (Ember.get(this.features, "timelines")) {
        const times = Ember.get(this, "pcr.times");
        const assessments = Ember.get(this, "pcr.patientAssessments");
        return this.sortTimelines(times, assessments);
      }

      return this.sortedFlowcharts;
    }),

    getFullModelName(name) {
      return name === "medicament" || name === "procedure" ? `${name}Treatment` : name;
    },

    editSubsequentTreatmentsTime(currentTime, minutes) {
      this.flowcharts.forEach(flowchart => {
        const time = Ember.get(flowchart, "time");
        if (currentTime <= time) Ember.set(flowchart, "time", (0, _moment.default)(time).add(minutes, "minutes"));
      });
    },

    onTimeEditClose() {
      Ember.set(this, "isTimeEditDrillDownVisible", null);
      Ember.set(this, "originalTime", null);
    },

    actions: {
      onDelete(data) {
        return Ember.get(this.patientTreatment, this.getFullModelName(data.model)).removeObject(data);
      },

      onTimeEditConfirmed(model, newTime, applyTimeEditToOtherRecords) {
        const timeDiff = (0, _moment.default)(newTime).diff(model.time, "minutes");
        const currentTime = Ember.get(model, "time");
        applyTimeEditToOtherRecords ? this.editSubsequentTreatmentsTime(currentTime, timeDiff) : Ember.set(model, "time", newTime);
        this.onTimeEditClose();
      },

      onTimeEditCancel() {
        this.onTimeEditClose();
      },

      onTimeAdded(timeDiff, originalTime) {
        Ember.set(this, "timeEditDiff", timeDiff);
        Ember.set(this, "originalTime", originalTime);
      },

      onDoseCalculatorClick() {
        this.trackEvent("Dose calculator", "Transition from flowchart to dose calculator");
        this.router.transitionTo("pcr.tools.dose-calculator");
      }

    }
  });

  _exports.default = _default;
});