define("epcr-uis/pods/patient/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESTSerializer = _emberData.default.RESTSerializer;

  const getNormalizedSideDiseases = (sideDiseases, classificator) => {
    const classificatorSideDiseases = Ember.get(classificator, "sideDiseases");
    const {
      otherIllnessDescription,
      allergyNotes
    } = sideDiseases;
    const modifiedDiseases = [];
    if (Ember.get(sideDiseases, "types.length")) sideDiseases.types.map(diseases => classificatorSideDiseases.forEach(classDiseases => diseases === classDiseases.code && modifiedDiseases.push(classDiseases)));
    return {
      types: modifiedDiseases,
      otherIllnessDescription,
      allergyNotes
    };
  };

  var _default = RESTSerializer.extend({
    pcrClassificator: Ember.inject.service(),

    convertPatient(patient) {
      if (patient.sideDiseases) patient.sideDiseases = getNormalizedSideDiseases(patient.sideDiseases, this.pcrClassificator);
      if (patient.familyDoctor) patient.familyDoctor = {
        code: patient.familyDoctor,
        fullName: patient.familyDoctor
      };
      if (patient.phcFacility) patient.phcFacility = {
        code: patient.phcFacility,
        name: patient.phcFacility
      };
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload && payload.length && payload.map(patient => this.convertPatient(patient));
      return this._super(store, primaryModelClass, {
        patient: payload
      }, id, requestType);
    }

  });

  _exports.default = _default;
});