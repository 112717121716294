define("epcr-uis/pods/components/pcr-document/tools/apgar-calculator/component", ["exports", "epcr-uis/mixins/metrics-tracker"], function (_exports, _metricsTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const criteria = "appearance,pulse,reflexIrritability,muscleTone,respiratory";
  const criteriaPath = `selectedCriteria.{${criteria}}.code`;

  var _default = Ember.Component.extend(_metricsTracker.default, {
    selectedCriteria: {},
    scrollToField: Ember.inject.service(),
    createCriteria: (letter, key, name, choices) => ({
      letter,
      key,
      name,
      choices
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, "selectedCriteria", {});
    },

    criteriaKeys: () => criteria.split(","),

    buildChoices(choices) {
      const convertedChoices = [];
      choices.forEach((choice, index) => convertedChoices.pushObject({
        code: index,
        name: choice
      }));
      return convertedChoices;
    },

    isAllCriteriaSelected: Ember.computed(criteriaPath, {
      get() {
        const {
          selectedCriteria
        } = this;
        return !this.criteriaKeys().find(key => Ember.isNone(selectedCriteria[key]) || Ember.isNone(selectedCriteria[key].code));
      },

      set: (key, value) => value
    }),
    apgarScale: Ember.computed(function () {
      const {
        createCriteria,
        buildChoices
      } = this;
      return [createCriteria("A", "appearance", "Išvaizda", buildChoices(["Mėlyna ar blyški", "Galūnės mėlynos", "Kūnas rausvas"])), createCriteria("P", "pulse", "Pulsas", buildChoices(["Nėra", "≤100/min", ">100/min"])), createCriteria("G", "reflexIrritability", "Jaudrumas", buildChoices(["Nėra", "Verkia, grimasos", "Aktyviai verkia"])), createCriteria("A", "muscleTone", "Aktyvumas", buildChoices(["Atonija", "Galūnės šiek tiek sulenktos", "Aktyvūs judesiai"])), createCriteria("R", "respiratory", "Kvėpavimas", buildChoices(["Nekvėpuoja", "Lėtas ir nereguliarus", "Normalus, verkia"]))];
    }),
    score: Ember.computed(criteriaPath, {
      get() {
        return this.criteriaKeys().reduce((accumulator, key) => accumulator + (this.selectedCriteria[key] ? this.selectedCriteria[key].code : 0), 0);
      },

      set: (key, value) => value
    }),

    redirectToBirthFormApgar1Min() {
      return this.scrollToField.scrollToField("#birth-form", "apgar-1min", ["pcr.forms"], {
        doNotOpen: true
      });
    },

    setApgarValue(apgar, value) {
      Ember.set(this, "pcr.birthCriteria.babyBorn", true);
      Ember.set(this, `pcr.birthCriteria.apgar${apgar}Min`, {
        code: value,
        name: value
      });
      this.trackEvent("APGAR", "Set value", `${value}min`);
    },

    actions: {
      redirectToBirthForm() {
        this.trackEvent("APGAR", "Transition from apgar to birth form");
        return this.redirectToBirthFormApgar1Min();
      },

      redirectAndSetApgar1Min(score) {
        this.setApgarValue("1", score);
        this.redirectToBirthFormApgar1Min();
      },

      redirectAndSetApgar5Min(score) {
        this.setApgarValue("5", score);
        this.redirectToBirthFormApgar1Min();
      }

    }
  });

  _exports.default = _default;
});