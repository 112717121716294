define("epcr-uis/pods/components/pcr-document/flowchart/inventory-input/new/component", ["exports", "ember-concurrency", "epcr-uis/helpers/create-treatment", "epcr-uis/mixins/treatment-observer"], function (_exports, _emberConcurrency, _createTreatment, _treatmentObserver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_treatmentObserver.default, {
    pcrClassificator: Ember.inject.service(),
    store: Ember.inject.service(),
    timeService: Ember.inject.service("time"),
    usedInventory: Ember.computed.alias("inventory.[]"),
    onInventoryEditPopupClose: (0, _emberConcurrency.task)(function* () {
      yield Ember.set(this, "showInventoryPopup", false);
    }),
    onInventoryPopupClose: (0, _emberConcurrency.task)(function* () {
      yield this.transitionTo("pcr.flowchart");
    }),

    showPopupAndSetTitle(title) {
      Ember.set(this, "showInventoryPopup", true);
      Ember.set(this, "popupTitle", title);
    },

    createInventoryItem(selectedItem) {
      const inventoryItemFragment = (0, _createTreatment.createInventoryItem)([selectedItem, this.store]);
      this.inventory.pushObject(inventoryItemFragment);
      Ember.set(this, "selectedItem", inventoryItemFragment);
      this.addObserverForInventory(inventoryItemFragment);
    },

    actions: {
      onInventorySelect(item) {
        this.showPopupAndSetTitle(item.name);
        this.createInventoryItem(item);
      },

      onDelete(data) {
        this.inventory.removeObject(data);
      }

    }
  });

  _exports.default = _default;
});