define("epcr-uis/mirage/factories/department", ["exports", "ember-cli-mirage", "epcr-uis/mirage/factories/is-english"], function (_exports, _emberCliMirage, _isEnglish) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    id: i => `Hospital-${i}`,
    code: i => `Hospital-${i}`,
    hospitalName: i => (0, _isEnglish.isEnglish)() ? `Bethany Medical Clinic of New York-${i}` : `Kauno klinikos-${i}`,
    hospitalCode: i => i,
    departmentName: () => (0, _isEnglish.isEnglish)() ? "Emergency department" : "Skubios pagalbos skyrius",
    departmentCode: i => i
  });

  _exports.default = _default;
});