define("epcr-uis/pods/pcr-reloader/pcr-header/service", ["exports", "epcr-uis/pods/pcr-reloader/pcr-header/by-signal/service", "epcr-uis/pods/pcr-reloader/pcr-header/by-period/service", "epcr-uis/helpers/android"], function (_exports, _service, _service2, _android) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO when all versions are higher remove checking
  const Service = (0, _android.isAndroid)() && (0, _android.isAppVersionHigher)(12199) ? _service.default : _service2.default;

  var _default = Service.extend();

  _exports.default = _default;
});