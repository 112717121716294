define("epcr-uis/pods/list/my-pcrs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZOr06AcI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"scrollable-content\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"error\"]]],null,{\"statements\":[[0,\"    \"],[5,\"pcr-error\",[],[[\"@message\",\"@buttonName\",\"@showTopNavigation\",\"@onButtonClicked\"],[[24,[\"model\",\"error\"]],[28,\"t\",[\"actions.retry\"],null],false,[28,\"route-action\",[\"reloadMyPcrs\",[24,[\"model\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"my-pcrs\",[],[[\"@pcrs\",\"@getSearchId\",\"@onPcrClicked\",\"@onInfinityLoad\"],[[22,\"model\"],[28,\"route-action\",[\"getSearchId\"],null],[28,\"route-action\",[\"onPcrClicked\"],null],[28,\"route-action\",[\"onInfinityLoad\",[24,[\"model\"]]],null]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"  \"],[5,\"pcr-footer\",[],[[],[]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/list/my-pcrs/template.hbs"
    }
  });

  _exports.default = _default;
});