define("epcr-uis/initializers/android-services-registration", ["exports", "epcr-uis/helpers/window-wrapper", "ember-window-mock"], function (_exports, _windowWrapper, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function injectServices(application) {
    const hasAndroidAPI = _emberWindowMock.default.control;
    const hasBarcodeScannerAPI = (0, _windowWrapper.hasMethod)("window.barcode.scanPersonalId");
    const services = [{
      enabled: hasBarcodeScannerAPI,
      target: "component",
      name: "barcode",
      service: "service:barcode"
    }, {
      enabled: hasAndroidAPI,
      target: "component:pcr-document/print/settings",
      name: "androidControls",
      service: "service:android-control"
    }, {
      enabled: hasAndroidAPI,
      target: "route:home",
      name: "androidControls",
      service: "service:android-control"
    }];
    services.forEach(service => {
      if (service.enabled) application.inject(service.target, service.name, service.service);
    });
  }

  function initialize(application) {
    const hasPrinterCallbacks = (0, _windowWrapper.hasMethod)("window.epcr.registerPrinterListener");
    let service = hasPrinterCallbacks ? "android" : "desktop";
    application.inject("component", "print", `service:print/${service}`);
    if (hasPrinterCallbacks) injectServices(application);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});