define("epcr-uis/pods/components/save-summary/save-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    btnClass: "lightGrey",
    btnIcon: "save"
  });

  _exports.default = _default;
});