define("epcr-uis/helpers/concat-sentences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.concatSentences = concatSentences;
  _exports.default = void 0;

  function concatSentences([sentence, sentence2]) {
    if (!sentence) {
      return "";
    }

    return sentence2 ? `${sentence.trim()}. ${sentence2.trim()}` : sentence;
  }

  var _default = Ember.Helper.helper(concatSentences);

  _exports.default = _default;
});