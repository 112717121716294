define("epcr-uis/mirage/factories/team", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    code: "9995",
    members: ["1", "2", null, null],
    leaderType: "GYD",
    cluster: false
  });

  _exports.default = _default;
});