define("epcr-uis/pods/pcr/patient-treatment/procedure-treatment/model", ["exports", "epcr-uis/pods/pcr/patient-treatment/treatment-line/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    procedure: (0, _attributes.fragment)("pcr-classificator"),
    model: Ember.computed(() => "procedure"),
    match: Ember.computed("procedure.code", "time", "total.code", function () {
      const {
        procedure,
        time
      } = this.getProperties("procedure", "time");
      let matchId = time ? this.getFormattedTime(time) : "";
      matchId = this.addParameterToMatchId(matchId, procedure && procedure.code);
      matchId = this.addParameterToMatchId(matchId, Ember.get(this, "total.code"));
      return matchId;
    })
  });

  _exports.default = _default;
});