define("epcr-uis/pods/simple-auth-fetch/android/service", ["exports", "epcr-uis/pods/simple-auth-fetch/service", "fetch", "epcr-uis/config/environment"], function (_exports, _service, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    _sendRequest(url, method, body, contentType) {
      const stringifiedBody = JSON.stringify(body);
      return (0, _fetch.default)(url, {
        method,
        // todo isnaikinti body kai androidas bus sutvarkytas
        body: stringifiedBody,
        headers: this.setupHeader(contentType, stringifiedBody)
      });
    },

    setupHeader(contentType, body) {
      const {
        token
      } = this.get("session.data.authenticated");
      return {
        [_environment.default["ember-simple-auth-token"].authorizationHeaderName]: `Bearer ${token}`,
        "Content-Type": contentType,
        "request-body": encodeURIComponent(body)
      };
    }

  });

  _exports.default = _default;
});