define("epcr-uis/pods/infinity-params/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    perPageParam: "limit",
    pageParam: "offset",
    simpleAuthFetch: Ember.inject.service(),
    totalPages: Ember.computed("totalHits", "perPage", function () {
      const {
        perPage,
        totalHits
      } = this;

      if (perPage && totalHits) {
        const pages = totalHits / perPage;
        return Math.ceil(pages) - 1;
      }

      return null;
    }),

    getSearchRequest(url, bodyData = {}) {
      return this.simpleAuthFetch.fetchRequest(url, "POST", bodyData, "text/plain").then(response => response.text());
    }

  });

  _exports.default = _default;
});