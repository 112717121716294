define("epcr-uis/pods/list/my-pcrs/route", ["exports", "epcr-uis/mixins/custom-infinity-route", "epcr-uis/mixins/on-pcr-list-item-click"], function (_exports, _customInfinityRoute, _onPcrListItemClick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_customInfinityRoute.default, _onPcrListItemClick.default, {
    myPcr: Ember.inject.service("list/my-pcrs"),
    infinity: Ember.inject.service(),

    loadMyPcrs() {
      const loadInfinityPcrs = (searchId, perPage) => {
        return this.infinity.model("my-pcr", this.createInfinityDataParams(searchId, perPage));
      };

      return this.myPcr.getMyPcrs(10, loadInfinityPcrs).catch(error => ({
        error
      }));
    },

    actions: {
      getSearchId() {
        return this.loadMyPcrs();
      },

      reloadMyPcrs(model) {
        Ember.set(model, "error", null) && this.loadMyPcrs();
      }

    }
  });

  _exports.default = _default;
});