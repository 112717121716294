define("epcr-uis/pods/components/verified-pcrs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AaUhQnav",
    "block": "{\"symbols\":[\"@pcrsTask\",\"@onPcrClicked\"],\"statements\":[[4,\"if\",[[24,[\"pcrsTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[5,\"ui-loader\",[],[[\"@isLoading\",\"@cssClass\"],[[24,[\"pcrsTask\",\"isRunning\"]],\"inline centered\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"pcrsTask\",\"last\",\"isError\"]]],null,{\"statements\":[[0,\"  \"],[5,\"pcr-error\",[],[[\"@message\",\"@buttonName\",\"@showTopNavigation\",\"@onButtonClicked\"],[[24,[\"pcrsTask\",\"last\",\"error\"]],[28,\"t\",[\"actions.retry\"],null],false,[28,\"perform\",[[24,[\"verifiedPcrs\",\"getVerifiedPcrs\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"pcr-list\",[],[[\"@pcrs\",\"@onPcrClicked\",\"@isVerifiedPcrsList\",\"@icon\",\"@isAlwaysOpen\",\"@noPcrsFoundMessage\",\"@isEmpty\"],[[23,1,[\"last\",\"value\"]],[23,2,[]],true,\"file alternate outline big grey icon\",true,\"message.noVerifiedPcrs\",[28,\"eq\",[[23,1,[\"last\",\"value\",\"length\"]],0],null]]]],[0,\"\\n\"],[4,\"if\",[[28,\"not-eq\",[[23,1,[\"last\",\"value\",\"length\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"footer\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"message.endOfList\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/verified-pcrs/template.hbs"
    }
  });

  _exports.default = _default;
});