define("epcr-uis/mirage/factories/birth-criteria", ["exports", "ember-cli-mirage", "moment"], function (_exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    pregnancyWeeks: 1,
    laborPainsRepeatMins: 1,
    fetusHeartBeat: 1,
    watersBroken: false,
    heavyBleeding: false,
    eclampsia: false,
    bornTime: (0, _moment.default)(),
    babyBorn: false,
    afterBirthPathology: false,
    erNotified: false,
    placentaBorn: false
  });

  _exports.default = _default;
});