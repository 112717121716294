define("epcr-uis/pods/components/day-night-switcher/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NIGHT_MODE = "night-mode";

  var _default = Ember.Component.extend({
    tagName: "",
    actions: {
      switcher(featureName, isEnabled) {
        isEnabled ? document.body.classList.add(NIGHT_MODE) : document.body.classList.remove(NIGHT_MODE);
        this.saveToUserPreferences(featureName, isEnabled);
      }

    }
  });

  _exports.default = _default;
});