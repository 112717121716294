define("epcr-uis/pods/pcr/model", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  const PCR_CLASSIFICATOR = "pcr-classificator";

  var _default = Model.extend({
    eventId: attr("number"),
    missionId: attr("number"),
    patientId: attr("number"),
    category: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    patientDetails: (0, _attributes.fragment)("pcr/patient-details"),
    noPatientReason: attr("string"),
    patientAssessments: (0, _attributes.fragmentArray)("pcr/patient-assessment"),
    strokeCriteria: (0, _attributes.fragment)("pcr/stroke-criteria"),
    traumaCriteria: (0, _attributes.fragment)("pcr/trauma-criteria"),
    birthCriteria: (0, _attributes.fragment)("pcr/birth-criteria"),
    deathStatement: (0, _attributes.fragment)("pcr/death-statement"),
    clinicalDeath: (0, _attributes.fragment)("pcr/clinical-death"),
    patientSafetyEquipment: (0, _attributes.fragment)("pcr/patient-safety-equipment"),
    heartAttackCriteria: (0, _attributes.fragment)("pcr/heart-attack-criteria"),
    eventNotifications: (0, _attributes.fragment)("pcr/event-notifications"),
    agreementsSignatures: (0, _attributes.fragment)("pcr/agreements-signatures"),
    patientDiagnosis: (0, _attributes.fragment)("pcr/patient-diagnosis"),
    patientTreatment: (0, _attributes.fragment)("pcr/patient-treatment"),
    injuryLocation: (0, _attributes.fragment)("pcr/injuries"),
    delays: (0, _attributes.fragment)("pcr/delays"),
    callInformation: (0, _attributes.fragment)("pcr/call-information"),
    teamActivation: (0, _attributes.fragment)("pcr/team-activation"),
    eventCancelReason: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    times: (0, _attributes.fragment)("pcr/time"),
    transportation: (0, _attributes.fragment)("pcr/transportation"),
    noTransportation: (0, _attributes.fragment)("pcr/no-transportation"),
    transportationType: (0, _attributes.fragment)(PCR_CLASSIFICATOR),
    billing: (0, _attributes.fragment)("pcr/billing"),
    metadata: (0, _attributes.fragment)("pcr/metadata"),
    trip: (0, _attributes.fragment)("pcr/trip"),
    station: attr("string"),
    circumstances: attr("string"),
    dispatch: attr("string")
  });

  _exports.default = _default;
});