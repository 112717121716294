define("epcr-uis/pods/components/pcr-document/abstract/help-block/component", ["exports", "epcr-uis/helpers/count-age-between"], function (_exports, _countAgeBetween) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    cssClass: "highlighted",
    monthsInYear: 12,
    year: Ember.computed(function () {
      return this.intl.t("general.years");
    }),
    timesPerMin: Ember.computed(function () {
      return this.intl.t("general.timesPerMin");
    }),
    kg: Ember.computed(function () {
      return this.intl.t("general.kg");
    }),
    moreThan: Ember.computed(function () {
      return this.intl.t("general.moreThan");
    }),
    lessThan: Ember.computed(function () {
      return this.intl.t("general.lessThan");
    }),
    ranges: Ember.computed(function () {
      const {
        year,
        monthsInYear,
        lessThan,
        moreThan,
        intl
      } = this;
      const lessThan1Year = `${lessThan} 1 ${year}`;
      const newBorn = intl.t("options.patientDetails.ageUnit.newborn");
      const between1And2Year = `1-2 ${year}`;
      const between2And5Year = `2-5 ${year}`;
      const between5And10Year = `5-10 ${year}`;
      const between10And12Year = `10-12 ${year}`;
      const moreThan12Year = `${moreThan} 12 ${year}`;

      const createRange = (name, from, to) => ({
        name,
        from,
        to
      });

      return {
        newBorn: createRange(newBorn, 0, 1),
        lessThan1Year: createRange(lessThan1Year, 1, monthsInYear),
        between1And2Year: createRange(between1And2Year, monthsInYear, monthsInYear * 2),
        between2And5Year: createRange(between2And5Year, monthsInYear * 2, monthsInYear * 5),
        between5And10Year: createRange(between5And10Year, monthsInYear * 5, monthsInYear * 10),
        between10And12Year: createRange(between10And12Year, monthsInYear * 10, monthsInYear * 12),
        moreThan12Year: createRange(moreThan12Year, monthsInYear * 12)
      };
    }),
    createSimpleHelpBlock: (name, range, measure, className) => ({
      name,
      range,
      measure,
      class: className
    }),

    createHelpBlock(time, pcr, measureValue, block, range) {
      const className = (0, _countAgeBetween.isAgeBetween)(time, pcr.patientDetails, pcr.times.callTime, block.from, block.to) ? this.cssClass : "";
      return this.createSimpleHelpBlock(block.name, range, measureValue, className);
    }

  });

  _exports.default = _default;
});