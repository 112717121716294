define("epcr-uis/mixins/is-station-allowed-to-create-new-pcr", ["exports", "epcr-uis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    login: Ember.inject.service(),

    isStationAllowedToCreateNewPcr() {
      return _environment.default.APP.stationsAllowedToCreateNewEpcrs.includes(this.login.getStationId());
    }

  });

  _exports.default = _default;
});