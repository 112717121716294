define("epcr-uis/pods/my-pcr/model", ["exports", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    pcrId: attr("string"),
    age: (0, _attributes.fragment)("my-pcr/age"),
    sex: (0, _attributes.fragment)("pcr-classificator"),
    weight: attr("number"),
    category: attr("number"),
    firstName: attr("string"),
    lastName: attr("string"),
    teamId: attr("number"),
    address: attr("string"),
    callTime: attr("date"),
    registrationTime: attr("date"),
    owner: (0, _attributes.fragment)("my-pcr/owner"),
    arrivedTime: attr("date"),
    state: attr("string")
  });

  _exports.default = _default;
});