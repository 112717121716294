define("epcr-uis/pods/tools/route", ["exports", "epcr-uis/mixins/route-tracker"], function (_exports, _routeTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeTracker.default, {
    actions: {
      transitionToRoute(path) {
        this.transitionTo(path);
      }

    }
  });

  _exports.default = _default;
});