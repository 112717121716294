define("epcr-uis/pods/components/pcr-document/tools/dose-calculator/medicaments/component", ["exports", "epcr-uis/helpers/create-treatment", "epcr-uis/mixins/metrics-tracker", "ember-concurrency"], function (_exports, _createTreatment, _metricsTracker, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const weightValue = "weight.value";

  var _default = Ember.Component.extend(_metricsTracker.default, {
    tagName: "",
    store: Ember.inject.service(),
    pcrTime: Ember.inject.service("pcr/time"),
    scrollToField: Ember.inject.service(),
    pcrRouteTracker: Ember.inject.service(),
    router: Ember.inject.service(),
    selectedMedicamentCode: null,
    doseClassificator: Ember.inject.service("pcr-classificator/calculator/dose"),

    init() {
      this._super(...arguments);

      this.pcrRouteTracker.on("routeChanged", this, "onRouteChanged");
      this.dataTask.perform();
    },

    onRouteChanged() {
      const isPcrRoute = this.router.currentRouteName.includes("pcr.");
      isPcrRoute && this.selectedMedicamentCode && Ember.set(this, "selectedMedicamentCode", null);
    },

    dataTask: (0, _emberConcurrency.task)(function* () {
      this.selectedMedicamentCode && Ember.set(this, "selectedMedicamentCode", null);
      return yield this.doseClassificator.doses(Ember.get(this, weightValue)).filter(med => med);
    }),
    recountDoses: Ember.observer(weightValue, function () {
      this.dataTask.perform();
    }),
    actions: {
      addMedicament({
        medicament
      }, dose) {
        const {
          match
        } = (0, _createTreatment.addMedicamentToMedicaments)(this.pcr, this.pcrTime, this.store, medicament, {
          notes: dose
        });
        this.trackEvent("Dose calculator", "Added medicament", `Medicament [${medicament.name}] Dose [${dose}]]`);
        this.scrollToField.scrollToField(`[flowchart-id="medicament-${match}"]`, null, ["pcr.flowchart"]);
      },

      onMedicamentClick(isSelected, {
        id,
        code
      }) {
        this.trackEvent("Dose calculator", "DrillDown", `Medicament id=[${id}] code=[${code}] Action [${isSelected ? "close" : "open"}]]`);
        Ember.set(this, "selectedMedicamentCode", isSelected ? null : id);
      }

    }
  });

  _exports.default = _default;
});