define("epcr-uis/pods/components/filter-composer/representation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "brUwruZX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"active-pcrs\",\"shift-duration\"],[[28,\"component\",[\"filter-composer/representation/active-pcrs\"],null],[28,\"component\",[\"filter-composer/representation/shift-duration\"],[[\"tagName\"],[\"\"]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "epcr-uis/pods/components/filter-composer/representation/template.hbs"
    }
  });

  _exports.default = _default;
});