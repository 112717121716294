define("epcr-uis/mirage/factories/pcr-state", ["exports", "ember-cli-mirage", "moment"], function (_exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    pcrId: "1",
    state: "IMPORTED",
    lastUpdateTime: (0, _moment.default)(),
    createdTime: (0, _moment.default)()
  });

  _exports.default = _default;
});